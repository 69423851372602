import React from "react";
import { MDBRow, MDBCol, MDBCard } from "mdbreact";

export const CovidBanner = () => {
  return (
    <MDBRow size="12" center>
      <MDBCol size="12" className="mt-5" center>
        <div className="help-emergency-phone">
          <MDBRow size="12">
            <MDBCol center>
              <p className="my-1 p-3 font-12">
                <b>Covid-19 update</b> - To keep our colleagues safe through the
                current pandemic we’re working remotely where possible. We’re
                still here to deal with all your queries but we are experiencing
                a high number of calls, you can contact us by chat or by email
                at <b classname="help-emergency-link">hello@brightenergy.com</b>
                . Don’t forget you can submit meter readings online or via our
                app. If you need assistance on a particular matter check out our
                FAQs.
              </p>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBCol>
    </MDBRow>
  );
};
