import React from "react";
import { MDBRow, MDBCol } from "mdbreact";


class NILanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
      window.location.href="https://ni.brightenergy.com";
  }

  render() {
    return (
      <div className='account-dashboard-container'>
        <MDBRow size='12' className='account-details'>
          <MDBCol>You're being redirected to our NI page...</MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NILanding;
