import React from "react";
import Form_AdditionalServices from "../../components/Forms/Services/Form_AdditionalServices";
import  Form_AdditionalServicesNI  from "../../components/Forms/Services/Form_AdditionalServicesNI";

class AddAdditionalServices extends React.Component {
  state = {
    jurisdiction: "roi",
  };

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  render() {
    return (
      <div>
        {this.state.jurisdiction == "roi" ?
        <Form_AdditionalServices /> :
        <Form_AdditionalServicesNI/> }
      </div>
    );
  }
}

export default AddAdditionalServices;
