import { blogService } from "../services";
import { blogConstants } from "../constants";

export const blogActions = {
  getAll,
  getBlogPostTitle,
};

// Get All (READ)
function getAll(jurisdiction) {
  return (dispatch) => {
    // Request
    dispatch(getAllRequest());
    blogService.getAllService(jurisdiction).then(
      (response) => {
        dispatch(getAllSuccess(response.data));
      },
      (error) => {
        dispatch(getAllFailure(error));
      }
    );
  };

  function getAllRequest() {
    return { type: blogConstants.GET_ALL_BLOG_REQUEST };
  }

  function getAllSuccess(dbData) {
    return {
      type: blogConstants.GET_ALL_BLOG_SUCCESS,
      payload: dbData,
    };
  }

  function getAllFailure(error) {
    return { type: blogConstants.GET_ALL_BLOG_FAILURE, payload: error };
  }
}

// Get by title
function getBlogPostTitle(title, juris) {
  return (dispatch) => {
    // Request
    dispatch(getBlogPostTitleRequest());

    blogService.getBlogPostTitleService(title, juris).then(
      (response) => {
        dispatch(getBlogPostTitleSuccess(response.data));
      },
      (error) => {
        dispatch(getBlogPostTitleFailure(error));
      }
    );
  };

  function getBlogPostTitleRequest() {
    return { type: blogConstants.GET_BY_TITLE_REQUEST };
  }

  function getBlogPostTitleSuccess(dbData) {
    return {
      type: blogConstants.GET_BY_TITLE_SUCCESS,
      payload: dbData,
    };
  }

  function getBlogPostTitleFailure(error) {
    return { type: blogConstants.GET_BY_TITLE_FAILURE, payload: error };
  }
}
