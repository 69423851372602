import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import NavigationSection from "../../components/CustomerAccount/AccountNavigation";
import { sessionExpired } from "../../redux/helpers/sessionExpired";

class ReferAFriendSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "roi",
      referralLink: "",
      linkCopied: false,
    };
  }

  // Page load
  componentDidMount() {
    sessionExpired();

    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    this.setState({
      jurisdiction: jurisdiction,
    });

    // Parse user object in local storage
    let user = JSON.parse(localStorage.getItem("user"));

    // If user existes set variables from parsed user object
    if (user) {
      let firstName = user.data.authenticateModel.firstName;
      let referralID = user.data.authenticateModel.referralId;
      let referralLink =
        `www.brightenergy.com/${this.state.jurisdiction}/quote?referralId=` +
        referralID;

      this.setState({
        firstName: firstName,
        referralLink: referralLink,
      });
    }
  }

  // Copy referral link
  copyReferralLink = () => {
    const referralLink = this.referralTextArea;
    referralLink.select();
    document.execCommand("copy");
  };

  render() {
    return (
      <div
        className="account-dashboard-container"
        style={{ backgroundColor: "#001c28" }}
      >
        <div className="account-details-container">
          <NavigationSection />
          <div className="dark-background">
            {/* Text copied alert */}
            {this.state.linkCopied ? (
              <div className="modalBG">
                <div className="modalBox">
                  <div className="white-curved-tile text-center">
                    <h4>Referral link copied!</h4>
                    <MDBRow>
                      <MDBCol lg="12" md="12" sm="12">
                        <MDBBtn
                          onClick={() =>
                            this.setState({
                              linkCopied: !this.state.linkCopied,
                            })
                          }
                        >
                          Ok
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            <MDBContainer className="main-content refer-friend">
              <MDBRow>
                {/* Page Title */}
                <MDBCol md="12" className="text-center">
                  <h1 className="white-text">Refer a friend</h1>
                </MDBCol>
              </MDBRow>

              <MDBRow className="justify-content-center">
                <img
                  src={require("../../images/account/refer-a-friend-main.png")}
                  className="img-fluid mb-5 refer-image-border"
                />
                <MDBCol md="8" className="text-center content">
                  {/* Subtitle/text */}
                  <div>
                    <p className="white-text">
                      <b>
                        You can share your referral link with a friend who would
                        like
                        <span className="mt-megan brightGreen-text">
                          {" "}
                          greener energy*.
                        </span>
                      </b>
                      <br /> To say thank you, once they've switched, we will
                      credit your account with €25.
                    </p>
                  </div>

                  {/* €25 Image */}
                  {/* <div>
                    <img
                      src={require("../../images/account/refer-friend.png")}
                    />
                  </div> */}

                  {/* Subtext */}
                  <div>
                    <a href={`/${this.state.jurisdiction}/terms#referafriend`}>
                      <p className="mt-megan brightGreen-text">Terms apply</p>
                    </a>
                  </div>
                </MDBCol>

                <MDBCol md="12">
                  <div className="white-curved-tile text-center">
                    {/* Referral Code Title  */}
                    <p>Your Referral Code</p>
                    {/* Referral Code */}
                    <p className="referral-code">
                      <b>{this.state.referralLink}</b>
                    </p>
                    {/* Button */}
                    <MDBBtn
                      onClick={() => {
                        navigator.clipboard.writeText(this.state.referralLink);
                        this.setState({ linkCopied: true });
                      }}
                    >
                      Copy link
                    </MDBBtn>
                  </div>
                </MDBCol>
              </MDBRow>

              {/* T&C's */}
              {/* <MDBRow className='white-text justify-content-center'>
                <p>
                  View our refer a friend terms and conditions{" "}
                  <a
                    className='brightGreen-text'
                    href={`/${this.state.jurisdiction}/terms#referafriend`}
                  >
                    here
                  </a>
                </p>
              </MDBRow> */}
            </MDBContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default ReferAFriendSection;
