import { customerAccountConstants } from "../constants";

let defaultState = {
  bills: [],
  latestBill:"",
  isLoading: false,
};

const customerAccountReducer = (state = defaultState, action) => {
  switch (action.type) {
    // Get bills
    case customerAccountConstants.GET_BILLS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case customerAccountConstants.GET_BILLS_SUCCESS:
      return {
        ...state,
        bills: action.payload,
        isLoading: false,
      };
    case customerAccountConstants.GET_BILLS_FAILURE:
      return {
        error: action.payload,
      };

    //get bills by id 
    case customerAccountConstants.GET_BILLBYID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case customerAccountConstants.GET_BILLBYID_SUCCESS:
      return {
        ...state,
        latestBill: action.payload,
        isLoading: false,
      };
    case customerAccountConstants.GET_BILLBYID_FAILURE:
      return {
        error: action.payload,
      };

    // Default
    default:
      return state;
  }
};

export default customerAccountReducer;
