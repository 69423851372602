import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import DashboardCarousel from "../../components/CustomerAccount/Dashboard/DashboardCarousel";

import NavigationSection from "../../components/CustomerAccount/AccountNavigation";

// Account Type Sections
import DirectDebit from "../../components/CustomerAccount/Dashboard/DirectDebit";
import { PayOnReceipt } from "../../components/CustomerAccount/Dashboard/PayOnReceipt";
import { customerAccountActions } from "../../redux/actions/customerAccountActions";
import { connect } from "react-redux";

import Loader from "../../components/Shared/Loader";
import { Keypad } from "../../components/CustomerAccount/Dashboard/KeypadWithTopup";
import { sessionExpired } from "../../redux/helpers/sessionExpired";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      accountType: "",
      jurisdiction: "",
      showDownloadBill: false,
      showMultiProp: false,
      keypad: false,
      curJurisdiction: "",
      showLandlordPrompt: false,
    };
  }

  componentDidMount() {
    // Check user token
    sessionExpired();

    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    this.setState({
      jurisdiction: jurisdiction,
    });

    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;
    let properties = user.data.properties;
    let curj = user.data.authenticateModel.currentJurisdiction;

    if (user) {
      // Check users jModel
      if (jModel == null) {
        window.location.href = `/${jurisdiction}/account/signuprequired`;
      }

      if (jModel.billsList.length > 0) {
        this.setState({
          showDownloadBill: true,
        });
      }

      try {
        let firstName = user.data.authenticateModel.firstName;
        let accountType = jModel.electricityProduct.electricity.directDebit;
        let prepay = jModel.electricityProduct.electricity.prepay;

        this.setState({
          firstName: firstName,
          accountType: accountType,
          keypad: prepay,
          curJurisdiction: curj,
        });
      } catch {}

      if (properties.length > 1) {
        this.setState({
          showMultiProp: true,
        });
      } else {
        this.setState({
          showMultiProp: false,
        });
      }

      if (user.data.authenticateModel.agency) {
        this.setState({ showLandlordPrompt: true });
      } else {
        this.setState({ showLandlordPrompt: false });
      }
    }
  }

  // Populate form on back
  async componentWillReceiveProps(nextProps) {
    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  getBill = () => {
    //get latest bill ID
    let user = JSON.parse(localStorage.getItem("user"));
    let bills = user.data.jModel.billsList;
    let billId = 0;

    billId = bills[0].id;

    //get bill PDF
    this.props.getBill(billId, this.state.jurisdiction);
  };

  getBillId = (bills) => {
    var billId = 0;
    for (var x = 0; x < bills.length; x++) {
      if (!bills[x].supersededFl) {
        billId = bills[x].id;
        return billId;
      }
    }
  };

  render() {
    return (
      <div className="account-dashboard-container">
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        <div className="account-details-container">
          <NavigationSection />

          {/* Landlord modal */}
          {this.state.showLandlordPrompt ? (
            <div>
              <div className="modalBG">
                <div className="modalBox">
                  <div className="white-curved-tile text-center">
                    <h4>Looks like you are registered as a letting agency.</h4>
                    <h4>
                      Would you like to redirect to the letting agency
                      dashboard?
                    </h4>
                    <MDBRow className="justify-content-around">
                      {/* No */}
                      <MDBBtn
                        className="cancel-button"
                        onClick={() =>
                          this.setState({
                            showLandlordPrompt: !this.state.showLandlordPrompt,
                          })
                        }
                      >
                        No
                      </MDBBtn>
                      {/* Yes */}
                      <a
                        href={`/${this.state.jurisdiction}/account/landlorddashboard`}
                      >
                        <MDBBtn>Yes</MDBBtn>
                      </a>
                    </MDBRow>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <MDBContainer className="main-content home-dark-background">
            {/* Page Title */}
            <MDBRow>
              <MDBCol md="12" className="text-center">
                <h4 className="white-text mb-5">Home</h4>
              </MDBCol>
            </MDBRow>

            <div>
              <MDBRow>
                {/* Welcome Text */}
                <MDBCol md="12">
                  <h4 className="text-left white-text">
                    <b>Hi {this.state.firstName},</b>
                  </h4>
                  {this.state.keypad ? (
                    <Keypad />
                  ) : (
                    <span>
                      {this.state.accountType ? (
                        <DirectDebit />
                      ) : (
                        <PayOnReceipt />
                      )}
                    </span>
                  )}
                </MDBCol>
              </MDBRow>

              {/* Carousel */}
              <DashboardCarousel />

              {/* Tile Row One */}
              <MDBRow>
                {/* Tile One */}
                <MDBCol md="6">
                  <a href={`/${this.state.jurisdiction}/account/meterreading`}>
                    <div className="white-curved-tile text-center">
                      <img
                        className="tile-icon mb-4"
                        src={require("../../images/account/meterIcon.png")}
                      />
                      <h2 className="tile-text">
                        Submit your Meter <br /> Readings
                      </h2>
                    </div>
                  </a>
                </MDBCol>

                {/* Tile Two */}
                {this.state.showMultiProp ? (
                  <MDBCol md="6">
                    <a
                      href={`/${this.state.jurisdiction}/account/switchaccount`}
                    >
                      <div className="white-curved-tile text-center">
                        <img
                          className="tile-icon"
                          src={require("../../images/account/homeIcon.png")}
                        />
                        <h2 className="tile-text">
                          Manage another
                          <br /> account
                        </h2>
                      </div>
                    </a>
                  </MDBCol>
                ) : (
                  <MDBCol md="6">
                    <a href={`/${this.state.jurisdiction}/account/energyusage`}>
                      <div className="white-curved-tile text-center">
                        <img
                          className="tile-icon"
                          src={require("../../images/account/energyUsage.png")}
                        />
                        <h2 className="tile-text">Your Electricity Usage</h2>
                      </div>
                    </a>
                  </MDBCol>
                )}
              </MDBRow>

              {this.state.curJurisdiction.toLowerCase() ==
              this.state.jurisdiction ? (
                <span>
                  {this.state.showDownloadBill ? (
                    <MDBRow>
                      <MDBCol lg="12">
                        <MDBBtn
                          className="btn-teal w-100"
                          onClick={this.getBill}
                        >
                          Download Latest bill
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  ) : null}
                </span>
              ) : null}
            </div>
          </MDBContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    billData: store.customerAccount.latestBill,
    isLoading: store.customerAccount.isLoading,
  };
};

const actionCreators = {
  getBill: customerAccountActions.getBillById,
};

export default connect(mapStateToProps, actionCreators)(Dashboard);
