import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBAlert } from "mdbreact";
import { PaymentDetailsForm } from "./PaymentDetailsForm";
import axios from "axios";
import Loader from "../../../../Shared/Loader";
const LoadConfig = require("../../../../../config");
const config = LoadConfig.default;

export const DepositDetailsForm = (props) => {
  const [jurisdiction, setJurisdiction] = useState();
  const userEmail = localStorage.getItem("email");
  const [payAmount] = useState(125);
  const [showDepositDetails, setShowDepositDetails] = useState(true);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    setJurisdiction(jurisdiction);
  }, []);

  // Stripe payment intent
  const moveToCardPayment = () => {
    CreatePaymentIntent();
  };
  async function CreatePaymentIntent() {
    setShowLoader(true);

    let details = {
      jurisdiction: jurisdiction,
      amount: payAmount.toString(),
      email: userEmail,
      description: `NI Switch Deposit Payment. Email: ${userEmail}`,
    };

    await axios({
      method: "post",
      url: `${config.apiUrl}/payments/intentDesc`,
      data: details,
    }).then((response) => {
      // console.log(response);
      let secret = response.data.client_secret;
      let id = response.data.id;

      localStorage.setItem("secret", secret);
      localStorage.setItem("paymentIntent", id);

      setShowLoader(false);
      setShowDepositDetails(false);
      setShowPaymentDetails(true);
    });
  }

  const { nextStep } = props;

  return (
    <div className="text-left white-curved-tile">
      {showLoader ? <Loader /> : null}

      {showDepositDetails ? (
        <div>
          <MDBRow className="my-3">
            <h4>Security Deposit</h4>
          </MDBRow>
          <MDBRow className="my-3">
            <MDBCol>
              {/* Deposit message */}
              <MDBAlert className="blue-highlight">
                <p className="black-text">
                  Security Deposit: We want to keep our costs as low as possible
                  so that we can keep your costs low. To reduce the risk of bad
                  debt we require all customers not paying in advance by direct
                  debit to pay <b className="font-weight-bolder">£125</b>{" "}
                  security deposit. Details of our security deposit policy can
                  be found{" "}
                  <a
                    href={`/${jurisdiction}/ourpolicies#security-deposit-policy`}
                    target="_blank"
                  >
                    <b className="font-weight-bolder">here</b>
                  </a>
                </p>
              </MDBAlert>
            </MDBCol>
          </MDBRow>

          {/* Amount */}
          <MDBRow>
            <MDBCol className="text-center cocogoose">
              <label>Security deposit amount</label>
              <p style={{ fontSize: 50 }} className="my-3">
                £ {payAmount.toFixed(2)}
              </p>
            </MDBCol>
          </MDBRow>

          {/* Pay now button */}
          <MDBRow center>
            <MDBCol size="8">
              <MDBBtn className="w-100" onClick={() => moveToCardPayment()}>
                Pay Now
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}

      {showPaymentDetails ? <PaymentDetailsForm nextStep={nextStep} /> : null}
    </div>
  );
};
