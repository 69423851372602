import React from 'react';
import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import $ from "jquery";
// Redux
import { connect } from "react-redux";
import { paymentActions } from "../../../redux/actions";
import { PaymentDetailsForm } from "./PaymentDetailsForm";

const LoadConfig = require("../../../config");
const config = LoadConfig.default;

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(config.niStripeKey);

const VendConfirmationPage = () => {
    const [vendCode, setVendCode] = useState();
    const [amount, setAmount] = useState();
    
    const getParams = () => {
        let urlParams = new URLSearchParams(window.location.search);
        var vendCode = urlParams.get('vendcode');
        vendCode = vendCode.match(/.{1,4}/g);
        vendCode = vendCode.join("-");
        setVendCode(vendCode);
        setAmount(urlParams.get('amount'));
    }

    const help = () => {
        if (window.pageYOffset < 50) {
            $("#custom-nav-expand").addClass("dark-header");
        }
    }

    useEffect(() => {
        getParams();
        help();
        window.addEventListener('scroll', help());
        
        return () => {
            window.removeEventListener("scroll", help());
        };
    }, []);

    return (
        <div className='login-page'>

            <MDBContainer>
                <MDBRow>
                    <MDBCol md='2' lg='2'>
                        {/* {clSec} */}
                    </MDBCol>
                    <MDBCol sm='12' md='8' lg='8' className="text-center">
                        <h2>Your vend code</h2>
                        <div className='make-payment-background'>
                            <MDBContainer className='main-content make-payment'>
                                {/* Page Title */}


                                <div className='white-curved-tile'>
                                    <MDBRow center>
                                        <MDBCol size="10">
                                            <p className="grey-background p-3 vend-code">
                                                {vendCode}
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="mb-5">
                                        <MDBCol>
                                            {/* Success image */}
                                            <img
                                                src={require("../../../images/account/success.png")}
                                                style={{ width: "20%" }}
                                            />
                                            <p >
                                                Your payment of <b>£{amount}.00</b> <br />
                                                has been successful.
                                            </p>
                                        </MDBCol>
                                    </MDBRow>

                                    {/* <MDBContainer>
                                        <MDBRow className='paymentHR'>
                                            <MDBCol size='6'>
                                                <p>Payment method</p>
                                            </MDBCol>
                                            <MDBCol size='6'>
                                                <p className='text-right'>
                                                    <b>Card payment</b>
                                                </p>
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBRow className='mt-3'>
                                            <MDBCol size='6'>
                                                <p>Payment reference</p>
                                            </MDBCol>
                                            <MDBCol size='6'>
                                                <p className='text-right'>
                                                    <b>vend code</b>
                                                </p>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer> */}

                                    {/* View payment history */}
                                    <MDBRow className='justify-content-center'>
                                        <a
                                            href={`/ni/account/bills-statements`}
                                        >
                                            <a href="/ni" className='btn'>Bright Homepage</a>
                                        </a>
                                    </MDBRow>
                                </div>
                            </MDBContainer>
                        </div>
                    </MDBCol>
                    <MDBCol md='2' lg='2'>
                        {/* {clSec} */}
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}


export default VendConfirmationPage;