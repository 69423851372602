import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBRow,
  MDBAlert,
  MDBContainer,
  MDBIcon,
} from "mdbreact";
import Loader from "../../../Shared/Loader";
import LiveChat from "react-livechat";
import $ from "jquery";

// Redux
import { connect } from "react-redux";
import { switchActions } from "../../../../redux/actions";

// Select Data
import {
  NIMedicalEquipmentData,
  NISpecialServiceData,
} from "../../../../helpers/SelectData";
import Select from "react-select";

const LoadConfig = require("../../../../config");
const config = LoadConfig.default;

export class AccountDetails extends Component {
  state = {
    jurisdiction: "ni",
    dateSubmitted: new Date().toISOString().slice(0, 19),
    // Account Holder
    accountHolder: true,
    title: "",
    firstName: "",
    surname: "",
    dob: "",
    email: "",
    phone: "",
    postcode: "",
    addrLine1: "",
    addrLine2: "",
    city: "",
    county: "",
    mprn: "",
    // Account Correspondance
    accountCorrespondence: "",
    accCorrTitle: "",
    accCorrFirstName: "",
    accCorrSurname: "",
    accCorrName: "",
    accCorrPostcode: "",
    accCorrAddLineOne: "",
    accCorrAddLineTwo: "",
    accCorrCity: "",
    accCorrCounty: "",
    accCorrEmail: "",
    accCorrAddPhone: "",
    // Joint Account
    jointPerson: "",
    jointTitle: "",
    jointFirstName: "",
    jointSurname: "",
    jointEmail: "",
    jointPhone: "",
    // Speical services
    serviceCheck: "",
    // PDC: false, //not used in NI so defaulting to false
    specialServices: "",
    requiredSpecialServices: "",
    medicalServices: "",
    requiredMedicalServices: "",
    priorityRegister: "",
    //nom person
    nomPerson: "",
    nomCorrespondance: "true",
    nomTitle: "",
    nomFirstName: "",
    nomSurname: "",
    nomEmail: "",
    nomPhone: "",
    nomPostcode: "",
    nomAddrLine1: "",
    nomAddrLine2: "",
    nomCity: "",
    nomCounty: "",
    medicalEquipment: "",
    // Marketing
    markEmail: false,
    markSMS: false,
    markPost: false,
    markPhone: false,
    // Other
    errorMessage: false,
    tooltip: false,
    accountDetailsRequired: true,
    jointDetailsRequired: false,
    accountCorrespondanceRequired: false,
    switchGot: false,
    mprnTip: false,
    maxDob: "",
    homeCheck: false,
    nomTip: false,

    // Address lookup
    showAddressSelect: false,
    addressSelect: null,
    selectedAddress: "",

    keypad: false,
  };

  // Page Load
  async componentDidMount() {
    let switchID = localStorage.getItem("switchID");

    if (switchID != null) {
      this.props.getSwitch(switchID);
      this.setState({ switchGot: true });
    }

    let keypad = localStorage.getItem("keypad");
    this.setState({ keypad: keypad });
  }

  // If Props.journeyData true populate form
  async componentWillReceiveProps(nextProps) {
    let sectionUpdated = JSON.parse(localStorage.getItem("accountUpdated"));
    let mprn = localStorage.getItem("mprn");

    if (this.state.switchGot && sectionUpdated) {
      await this.setState({
        title: nextProps.journeyData.title,
        firstName: nextProps.journeyData.firstName,
        surname: nextProps.journeyData.surname,
        dob: nextProps.journeyData.dob.toString().slice(0, 10),
        email: nextProps.journeyData.email,
        phone: nextProps.journeyData.phone,
        postcode: nextProps.journeyData.postcode,
        addrLine1: nextProps.journeyData.addrLine1,
        addrLine2: nextProps.journeyData.addrLine2,
        city: nextProps.journeyData.city,
        county: nextProps.journeyData.county,
        mprn: mprn,
        // Account Correspondance
        accountCorrespondence: nextProps.journeyData.accountCorrespondence,
        accCorrTitle: nextProps.journeyData.accCorrTitle,
        accCorrFirstName: nextProps.journeyData.accCorrFirstName,
        accCorrSurname: nextProps.journeyData.accCorrSurname,
        accCorrName: nextProps.journeyData.accCorrName,
        accCorrPostcode: nextProps.journeyData.accCorrPostcode,
        accCorrAddLineOne: nextProps.journeyData.accCorrAddLineOne,
        accCorrAddLineTwo: nextProps.journeyData.accCorrAddLineTwo,
        accCorrCity: nextProps.journeyData.accCorrCity,
        accCorrCounty: nextProps.journeyData.accCorrCounty,
        accCorrEmail: nextProps.journeyData.accCorrEmail,
        accCorrAddPhone: nextProps.journeyData.accCorrAddPhone,
        // Joint Account
        jointPerson: nextProps.journeyData.jointPerson,
        jointTitle: nextProps.journeyData.jointTitle,
        jointFirstName: nextProps.journeyData.jointFirstName,
        jointSurname: nextProps.journeyData.jointSurname,
        jointEmail: nextProps.journeyData.jointEmail,
        jointPhone: nextProps.journeyData.jointPhone,
        // Speical services
        serviceCheck: nextProps.journeyData.serviceCheck,
        // PDC: nextProps.journeyData.PDC,
        specialServices: nextProps.journeyData.specialServices,
        requiredSpecialServices: nextProps.journeyData.requiredSpecialServices,
        medicalServices: nextProps.journeyData.medicalServices,
        requiredMedicalServices: nextProps.journeyData.requiredMedicalServices,
        priorityRegister: nextProps.journeyData.priorityRegister,
        //nom person
        nomPerson: nextProps.journeyData.nomPerson,
        nomCorrespondance: nextProps.journeyData.nomCorrespondance,
        nomTitle: nextProps.journeyData.nomTitle,
        nomFirstName: nextProps.journeyData.nomFirstName,
        nomSurname: nextProps.journeyData.nomSurname,
        nomEmail: nextProps.journeyData.nomEmail,
        nomPhone: nextProps.journeyData.nomPhone,
        nomPostcode: nextProps.journeyData.nomPostcode,
        nomAddrLine1: nextProps.journeyData.nomAddrLine1,
        nomAddrLine2: nextProps.journeyData.nomAddrLine2,
        nomCity: nextProps.journeyData.nomCity,
        nomCounty: nextProps.journeyData.nomCounty,
        medicalEquipment: nextProps.journeyData.medicalEquipment,
        // Marketing
        markEmail: nextProps.journeyData.markEmail,
        markSMS: nextProps.journeyData.markSMS,
        markPost: nextProps.journeyData.markPost,
        markPhone: nextProps.journeyData.markPhone,
      });
    }

    if (nextProps.addressLookupData != null) {
      this.setState({ addressSelect: null });
      let propertyData = [];
      for (var i = 0; i < nextProps.addressLookupData.length; i++) {
        propertyData.push({
          value: nextProps.addressLookupData[i],
          label: nextProps.addressLookupData[i],
        });
      }
      this.setState({
        showAddressSelect: true,
        addressSelect: propertyData,
      });
    }

    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle Field Change
  handleChange = (input) => (e) => {
    // MPRN Check
    if (input == "mprn") {
      localStorage.setItem("mprn", e.target.value);
    }

    var fieldValue = $("#" + input)[0].checkValidity();

    //mprn check
    if (input.name == "mprn") {
      localStorage.setItem("mprn", e.target.value);
    }

    if (fieldValue) {
      $("#" + input)
        .addClass("input-valid")
        .removeClass("input-error");
    } else {
      $("#" + input)
        .addClass("input-error")
        .removeClass("input-valid");
    }

    this.setState({ [input]: e.target.value });
  };

  // If Account Correspondance YES set all fields to required
  accountCorrespondenceRequired = (required) => {
    if (required) {
      this.setState({
        accountCorrespondanceRequired: true,
      });
    } else {
      this.setState({
        accountCorrespondanceRequired: false,
      });
    }
  };

  // If Joint Account YES set all fields to required
  jointDetailsRequired = (required) => {
    if (required) {
      this.setState({
        jointDetailsRequired: true,
      });
    } else {
      this.setState({
        jointDetailsRequired: false,
      });
    }
  };

  // Check if additional services are needed
  serviceCheck = (required) => {
    if (required) {
      this.setState({
        serviceCheck: true,
        // PDC: "",
        specialServices: "",
        requiredSpecialServices: "",
        medicalServices: "",
        requiredMedicalServices: "",
        priorityRegister: "",
        nomPerson: "",
        addServicesRequired: true,
        nomPersonRequired: true,
      });
    } else {
      this.setState({
        serviceCheck: false,
        // PDC: false,
        specialServices: false,
        requiredSpecialServices: false,
        medicalServices: false,
        requiredMedicalServices: false,
        priorityRegister: false,
        nomPerson: false,
        addServicesRequired: false,
        nomPersonRequired: false,
      });
    }
  };

  // Handle speical services change
  SpecialServicesChange = (specialServicesOption) => {
    this.setState({
      requiredSpecialServices: specialServicesOption,
    });

    console.log("Option selected:", specialServicesOption);
  };

  // Handle medical equipment change
  MedicalEquipmentChange = (medicalEquipmentOption) => {
    this.setState({
      requiredMedicalServices: medicalEquipmentOption,
    });

    console.log("Option selected:", medicalEquipmentOption);
  };

  // Speical services required
  specialServicesRequired = (required) => {
    if (required) {
      this.setState({ specialServicesRequired: true });
    } else {
      this.setState({ specialServicesRequired: false });
    }
  };

  // Nom details required
  nomDetailsRequired = (required) => {
    if (required) {
      this.setState({
        nomDetailsRequired: true,
      });
    } else {
      this.setState({
        nomDetailsRequired: false,
      });
    }
  };

  // Continue to next section
  nextStep = (e) => {
    // Prevent from from clearing
    e.preventDefault();

    // Validate Form
    let validation = $("#accountDetails")[0].checkValidity();

    if (validation) {
      // Form Data
      const data = {
        // Step one - (Account details)
        accountHolder: this.state.accountHolder,
        title: this.state.title,
        firstName: this.state.firstName,
        surname: this.state.surname,
        dob: new Date(this.state.dob).toISOString().slice(0, 19),
        email: this.state.email,
        phone: this.state.phone,
        postcode: this.state.postcode,
        addrLine1: this.state.addrLine1,
        addrLine2: this.state.addrLine2,
        city: this.state.city,
        county: this.state.county,
        mprn: this.state.mprn,
        // Account correspondance
        accountCorrespondence: this.state.accountCorrespondence,
        accCorrTitle: this.state.accCorrTitle,
        accCorrFirstName: this.state.accCorrFirstName,
        accCorrSurname: this.state.accCorrSurname,
        accCorrName: this.state.accCorrName,
        accCorrPostcode: this.state.accCorrPostcode,
        accCorrAddLineOne: this.state.accCorrAddLineOne,
        accCorrAddLineTwo: this.state.accCorrAddLineTwo,
        accCorrCity: this.state.accCorrCity,
        accCorrCounty: this.state.accCorrCounty,
        accCorrEmail: this.state.accCorrEmail,
        accCorrAddPhone: this.state.accCorrAddPhone,
        // Joint account
        jointPerson: this.state.jointPerson,
        jointTitle: this.state.jointTitle,
        jointFirstName: this.state.jointFirstName,
        jointSurname: this.state.jointSurname,
        jointEmail: this.state.jointEmail,
        jointPhone: this.state.jointPhone,
        // Speical services
        serviceCheck: this.state.serviceCheck,
        // PDC: this.state.PDC,
        specialServices: this.state.specialServices,
        requiredSpecialServices: JSON.stringify(
          this.state.requiredSpecialServices
        ),
        medicalServices: this.state.medicalServices,
        requiredMedicalServices: JSON.stringify(
          this.state.requiredMedicalServices
        ),
        priorityRegister: this.state.priorityRegister.toString(),
        //Nom person
        nomPerson: this.state.nomPerson,
        nomCorrespondance: this.state.nomCorrespondance,
        nomTitle: this.state.nomTitle,
        nomFirstName: this.state.nomFirstName,
        nomSurname: this.state.nomSurname,
        nomEmail: this.state.nomEmail,
        nomPhone: this.state.nomPhone,
        nomPostcode: this.state.nomPostcode,
        nomAddrLine1: this.state.nomAddrLine1,
        nomAddrLine2: this.state.nomAddrLine2,
        nomCity: this.state.nomCity,
        nomCounty: this.state.nomCounty,
        // Marketing
        markEmail: this.state.markEmail,
        markSMS: this.state.markSMS,
        markPost: this.state.markPost,
        markPhone: this.state.markPost,

        // Step two - (Your electricity)
        newConnection: this.props.journeyData.newConnection,
        meterReading: this.props.journeyData.meterReading,
        nightMeterReading: this.props.journeyData.nightMeterReading,
        annualUsage: this.props.journeyData.annualUsage,
        consumptionOption: this.props.journeyData.electricityUsageSelect,

        // Step three - Payment details
        paymentMethod: this.props.journeyData.paymentMethod,
        accountName: this.props.journeyData.accountName,
        bankAccountNo: this.props.journeyData.bankAccountNo,
        bankSortCode: this.props.journeyData.bankSortCode,
        billingMethod: this.props.journeyData.billingMethod,
        iban: this.props.journeyData.iban,
        DDAmount: this.props.journeyData.DDAmount,
        DDDayOfMonth: this.props.journeyData.DDDayOfMonth,
        bankAddressLineOne: this.props.journeyData.bankAddressLineOne,
        bankAddressLineTwo: this.props.journeyData.bankAddressLineTwo,
        bankAddressPostcode: this.props.journeyData.bankAddressPostcode,
        bankAddressCountry: this.props.journeyData.bankAddressCountry,
        confirmDDAuth: this.props.journeyData.confirmDDAuth,

        // Step four - Switch summary
        agreeToTerms: this.props.journeyData.agreeToTerms,
        agreeToPrivacyPolicy: this.props.journeyData.agreeToPrivacyPolicy,
        switchCompleted: false,
      };

      // Hide Error Message (if visiable)
      this.setState({
        errorMessage: false,
      });

      // Save email for stripe payment
      localStorage.setItem("email", this.state.email);

      // SwitchID
      let switchID = localStorage.getItem("switchID");

      // This Page (updates page status)
      let pageStatus = "accountUpdated";

      // Next Page URL
      let nextPage = "electricity";

      // Payment method - needs passed to take deposit for vdd
      let paymentMethod = "";

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Database Entry
      this.props.updateSwitch(
        switchID,
        data,
        pageStatus,
        nextPage,
        paymentMethod,
        jurisdiction
      );
    } else {
      // For each form input that has the required prop add the input-error class
      $("form#additionalServices")
        .find("input")
        .each(function () {
          if ($(this).prop("required")) {
            $(this).addClass("input-error");
          }
        });

      // Display the error message
      this.setState({
        errorMessage: true,
      });

      // Scroll to error
      window.location.href = "#errorMessage";
    }
  };

  // Address lookup
  addressLookup = () => {
    let postcode = this.state.postcode;
    this.props.addressLookup(postcode);
  };

  corAddressLookup = () => {
    let postcode = this.state.accCorrPostcode;
    this.props.addressLookup(postcode);
  };

  nomAddressLookup = () => {
    let postcode = this.state.nomPostcode;
    this.props.addressLookup(postcode);
  };

  accountAddressSelect = (option) => {
    this.setState({ selectedAddress: option.value });
    this.populateAddressFields("account", option.value);
  };

  nomAddressSelect = (option) => {
    this.setState({ selectedAddress: option.value });
    this.populateAddressFields("nom", option.value);
  };

  corAddressSelect = (option) => {
    this.setState({ selectedAddress: option.value });
    this.populateAddressFields("cor", option.value);
  };

  populateAddressFields = (type, val) => {
    var res = val.split(" , ");

    switch (type) {
      case "account":
        this.setState({
          addrLine1: res[0],
          addrLine2: res[1],
          city: res[2],
          county: res[3],
          postcode: res[4],
        });
        break;

      case "nom":
        this.setState({
          nomAddrLine1: res[0],
          nomAddrLine2: res[1],
          nomCity: res[2],
          nomCounty: res[3],
          nomPostcode: res[4],
        });
        break;

      case "cor":
        this.setState({
          accCorrAddLineOne: res[0],
          accCorrAddLineTwo: res[1],
          accCorrCity: res[2],
          accCorrCounty: res[3],
          accCorrPostcode: res[4],
        });

        break;

      default:
        break;
    }
  };

  render() {
    return (
      <div className='quote-page switch-page'>
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        <form id='accountDetails'>
          {/* Background Image */}
          <div className='device-image' />
          <div className='quote-content-container'>
            {/* Logo */}
            <MDBRow className='text-center'>
              <MDBCol lg='2' sm='12'>
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <img
                    src={require("../../../../images/global/bright-logo-dark.png")}
                    className='quote-logo mx-5 mt-5'
                  />
                </a>
              </MDBCol>
              <MDBCol size='10' />
            </MDBRow>

            {/* Home message */}
            {this.state.homeCheck ? (
              <div className='modalBG'>
                <div className='modalBox'>
                  <div className='white-curved-tile text-center'>
                    <h4 className='mt-5'>
                      All progress will be lost! <br />
                      Are you sure?
                    </h4>
                    <MDBRow>
                      <MDBCol lg='6' md='6' sm='12'>
                        <MDBBtn
                          className='cancel-button'
                          onClick={() =>
                            this.setState({ homeCheck: !this.state.homeCheck })
                          }
                        >
                          Cancel
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol lg='6' md='6' sm='12'>
                        <MDBBtn
                          onClick={() =>
                            (window.location.href = `/${this.state.jurisdiction}/`)
                          }
                        >
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            <MDBContainer className='personal-details-section'>
              <MDBRow>
                {/* Progress Bar */}
                <MDBCol size='12'>
                  <MDBContainer className='personal-details-section'>
                    <MDBRow>
                      <MDBCol md='12' className='switch-navigation-container'>
                        <div className='border-light'>
                          {!this.state.keypad ? (
                            <img
                              className='brand-image show-for-medium'
                              src={require("../../../../images/NI Switch/ThreeStepAccount.png")}
                            />
                          ) : (
                            <img
                              className='brand-image show-for-medium'
                              src={require("../../../../images/NI Switch/TwoStepAccount.png")}
                            />
                          )}
                        </div>
                      </MDBCol>

                      {/* Error Message */}
                      <MDBCol size='12' id='errorMessage'>
                        {this.state.errorMessage == true ? (
                          <MDBAlert color='danger' className='mb-5' dismiss>
                            <strong>Oops!</strong> Please check your fields
                            below.
                          </MDBAlert>
                        ) : null}
                      </MDBCol>

                      <MDBCol md='12' lg='8'>
                        {/* Account Holder  Radio Buttons */}
                        <div className='white-curved-tile text-left'>
                          <div>
                            <h4 className='mt-4'>Account Holder Details</h4>
                            {/* Acc Holder Title */}
                            <MDBRow>
                              <MDBCol lg='12' className='p-0'>
                                <label className='input-label'>Title</label>
                                <select
                                  id='title'
                                  pattern='[A-Za-z]{1,10}'
                                  className='browser-default custom-select mt-3'
                                  onChange={this.handleChange("title")}
                                  required={this.state.accountDetailsRequired}
                                  value={this.state.title}
                                >
                                  <option>Title</option>
                                  <option value='Mr'>Mr</option>
                                  <option value='Mrs'>Mrs</option>
                                  <option value='Miss'>Miss</option>
                                  <option value='Ms'>Ms</option>
                                  <option value='Dr'>Dr</option>
                                  <option value='Prof'>Prof</option>
                                </select>
                              </MDBCol>
                            </MDBRow>

                            <MDBRow className='mb-2'>
                              <MDBCol lg='12' className='text-left p-0'>
                                {/* First Name */}
                                <label className='input-label'>
                                  First Name
                                </label>
                                <MDBInput
                                  id='firstName'
                                  pattern='^[A-Z a-z]{2,32}$'
                                  label='First Name'
                                  outline
                                  required={this.state.accountDetailsRequired}
                                  onChange={this.handleChange("firstName")}
                                  value={this.state.firstName}
                                />

                                {/* Surname */}
                                <label className='input-label'>Surname</label>
                                <MDBInput
                                  id='surname'
                                  pattern='^[A-Z a-z]{1,32}$'
                                  label='Surname'
                                  outline
                                  required={this.state.accountDetailsRequired}
                                  onChange={this.handleChange("surname")}
                                  value={this.state.surname}
                                />
                                {/* DOB */}
                                <label className='input-label'>
                                  Date of Birth
                                </label>
                                <MDBInput
                                  id='dob'
                                  outline
                                  type='date'
                                  required={this.state.accountDetailsRequired}
                                  onChange={this.handleChange("dob")}
                                  value={this.state.dob}
                                  min={"1900-01-01"}
                                  max={
                                    new Date(
                                      new Date().setDate(
                                        new Date().getDate() - 365 * 18
                                      )
                                    )
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                />
                                {/* Email */}
                                <label className='input-label'>
                                  Email Address
                                </label>
                                <MDBInput
                                  id='email'
                                  pattern='^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                                  label='Email Address'
                                  outline
                                  required={this.state.accountDetailsRequired}
                                  onChange={this.handleChange("email")}
                                  value={this.state.email}
                                />
                                {/* Phone Number */}
                                <label className='input-label'>
                                  Phone Number
                                </label>
                                <MDBInput
                                  id='phone'
                                  pattern='^[0-9]{10,15}$'
                                  label='Phone number'
                                  outline
                                  required={this.state.accountDetailsRequired}
                                  onChange={this.handleChange("phone")}
                                  value={this.state.phone}
                                />
                              </MDBCol>
                            </MDBRow>

                            <MDBRow className='mt-5'>
                              <h4>Account Holder Address</h4>
                              <MDBCol size='12' className='text-left p-0'>
                                {/* MPRN  */}
                                <MDBRow>
                                  {this.state.keypad ? (
                                    <label className='input-label'>
                                      MPRN or KPN
                                    </label>
                                  ) : (
                                    <label className='input-label'>MPRN</label>
                                  )}

                                  <MDBBtn className='tooltip-container'>
                                    <MDBIcon
                                      icon='info-circle'
                                      onClick={() =>
                                        this.setState({
                                          mprnTip: !this.state.mprnTip,
                                        })
                                      }
                                    />
                                  </MDBBtn>
                                </MDBRow>

                                {/* MPRN help alert */}
                                <div id='helpAlert'>
                                  {this.state.mprnTip == true ? (
                                    <MDBAlert className='help-alert' dismiss>
                                      <MDBRow>
                                        <MDBCol
                                          size='2'
                                          className='align-self-center'
                                        >
                                          <img
                                            src={require("../../../../images/global/bullet-large.png")}
                                          />
                                        </MDBCol>
                                        <MDBCol size='10'>
                                          <p>
                                            For MPRN please enter the{" "}
                                            <b className='brightGreen-text'>
                                              11
                                            </b>{" "}
                                            digits as they appear on your most
                                            recent bill or statement. <br />
                                            <br /> For KPN please enter the{" "}
                                            <b className='brightGreen-text'>
                                              19
                                            </b>{" "}
                                            digits as they appear on your top-up
                                            card.
                                          </p>
                                        </MDBCol>
                                      </MDBRow>
                                    </MDBAlert>
                                  ) : null}
                                </div>

                                {/* MPRN */}
                                {this.state.keypad ? (
                                  <MDBInput
                                    id='mprn'
                                    pattern='^[8]\d{10}|^[9]\d{18}'
                                    label='MPRN or KPN'
                                    outline
                                    required={this.state.accountDetailsRequired}
                                    onChange={this.handleChange("mprn")}
                                    value={this.state.mprn}
                                  />
                                ) : (
                                  <MDBInput
                                    id='mprn'
                                    pattern='^[8]\d{10}'
                                    label='MPRN'
                                    outline
                                    required={this.state.accountDetailsRequired}
                                    onChange={this.handleChange("mprn")}
                                    value={this.state.mprn}
                                  />
                                )}
                                {/* Postcode Lookup*/}
                                <label className='input-label'>Postcode</label>
                                {/* Lookup error */}
                                {this.props.addressLookupError != "" ? (
                                  <MDBAlert
                                    color='danger'
                                    className='my-3'
                                    dismiss
                                  >
                                    {this.props.addressLookupError}
                                  </MDBAlert>
                                ) : null}
                                <MDBRow>
                                  <MDBCol sm='12' md='8' lg='8' className='p-0'>
                                    <MDBInput
                                      id='postcode'
                                      pattern='^[A-Z a-z 0-9]{6,8}$'
                                      label='Postcode'
                                      outline
                                      required={
                                        this.state.accountDetailsRequired
                                      }
                                      onChange={this.handleChange("postcode")}
                                      value={this.state.postcode}
                                    />
                                  </MDBCol>
                                  <MDBCol sm='12' md='4' lg='4'>
                                    <MDBBtn
                                      className='m-0 find-address-btn'
                                      onClick={() => this.addressLookup()}
                                    >
                                      Find Address
                                    </MDBBtn>
                                  </MDBCol>
                                </MDBRow>
                                {/* Select */}
                                {this.state.showAddressSelect ? (
                                  <Select
                                    options={this.state.addressSelect}
                                    onChange={this.accountAddressSelect}
                                    // value={this.state.selectedAddress}
                                  />
                                ) : null}
                                {/* Address  */}
                                <label className='input-label'>Address</label>
                                {/* Line one */}
                                <MDBInput
                                  id='addrLine1'
                                  pattern='^[A-Z a-z 0-9]{1,100}$'
                                  label='Building Name/Number'
                                  outline
                                  onChange={this.handleChange("addrLine1")}
                                  value={this.state.addrLine1}
                                />
                                {/* Line two */}
                                <MDBInput
                                  id='addrLine2'
                                  pattern='^[A-Z a-z 0-9]{1,100}$'
                                  label='Street'
                                  outline
                                  onChange={this.handleChange("addrLine2")}
                                  value={this.state.addrLine2}
                                />
                                {/* City  */}
                                <MDBInput
                                  id='city'
                                  pattern='^[A-Z a-z 0-9]{1,100}$'
                                  label='Town'
                                  outline
                                  onChange={this.handleChange("city")}
                                  value={this.state.city}
                                />
                                {/* County  */}
                                <MDBInput
                                  id='county'
                                  pattern='^[A-Z a-z 0-9]{1,100}$'
                                  label='County'
                                  outline
                                  onChange={this.handleChange("county")}
                                  value={this.state.county}
                                />
                              </MDBCol>
                            </MDBRow>
                          </div>
                        </div>
                        {/* Acount Correspondence Radio Buttons */}
                        <div className='white-curved-tile text-left'>
                          <div>
                            <h4>Account Correspondence</h4>
                            <p className='input-label'>
                              Would you like to add a different correspondence
                              address?
                            </p>
                            <div className='radio-container'>
                              {/* Yes */}
                              <input
                                id='accountCorrespondenceYes'
                                name='accountCorrespondence'
                                type='radio'
                                onClick={() => {
                                  this.setState({
                                    accountCorrespondence: true,
                                  });
                                  this.accountCorrespondenceRequired(true);
                                }}
                                required
                                className='default-radio'
                                checked={
                                  this.state.accountCorrespondence
                                    ? true
                                    : false
                                }
                              />
                              <label for='accountCorrespondenceYes'>Yes</label>
                              {/* No */}
                              <input
                                id='accountCorrespondenceNo'
                                name='accountCorrespondence'
                                type='radio'
                                onClick={() => {
                                  this.setState({
                                    accountCorrespondence: false,
                                  });
                                  this.accountCorrespondenceRequired(false);
                                }}
                                className='default-radio'
                                checked={
                                  this.state.accountCorrespondence === false
                                    ? true
                                    : false
                                }
                              />
                              <label htmlFor='accountCorrespondenceNo'>
                                No
                              </label>
                            </div>
                          </div>
                          {/* Account Correspondance Information */}
                          {this.state.accountCorrespondence != false ? (
                            <div>
                              <h4>Account Correspondance Information</h4>
                              <MDBRow className='mb-2'>
                                <MDBCol lg='12' className='text-left p-0'>
                                  {/* Acc Holder Title */}
                                  <MDBRow>
                                    <MDBCol lg='12' className='p-0'>
                                      <label className='input-label'>
                                        Title
                                      </label>
                                      <select
                                        id='accCorrTitle'
                                        pattern='[A-Za-z]{1,10}'
                                        className='browser-default custom-select mt-3'
                                        onChange={this.handleChange(
                                          "accCorrTitle"
                                        )}
                                        required={
                                          this.state
                                            .accountCorrespondanceRequired
                                        }
                                        value={this.state.accCorrTitle}
                                      >
                                        <option>Title</option>
                                        <option value='Mr'>Mr</option>
                                        <option value='Mrs'>Mrs</option>
                                        <option value='Miss'>Miss</option>
                                        <option value='Ms'>Ms</option>
                                        <option value='Dr'>Dr</option>
                                        <option value='Prof'>Prof</option>
                                      </select>
                                    </MDBCol>
                                  </MDBRow>
                                  {/* Account Correspondance First Name */}
                                  <label className='input-label'>
                                    First Name
                                  </label>
                                  <MDBInput
                                    id='accCorrFirstName'
                                    pattern='^[A-Z a-z]{1,32}$'
                                    label='First Name'
                                    outline
                                    required={
                                      this.state.accountCorrespondanceRequired
                                    }
                                    onChange={this.handleChange(
                                      "accCorrFirstName"
                                    )}
                                    value={this.state.accCorrFirstName}
                                  />
                                  {/* Account Correspondance Surname */}
                                  <label className='input-label'>Surname</label>
                                  <MDBInput
                                    id='accCorrSurname'
                                    pattern='^[A-Z a-z]{1,32}$'
                                    label='Surname'
                                    outline
                                    required={
                                      this.state.accountCorrespondanceRequired
                                    }
                                    onChange={this.handleChange(
                                      "accCorrSurname"
                                    )}
                                    value={this.state.accCorrSurname}
                                  />

                                  {/* Account Correspondance Email */}
                                  <label className='input-label'>
                                    Email Address
                                  </label>
                                  <MDBInput
                                    id='accCorrEmail'
                                    pattern='^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                                    label='Email Address'
                                    outline
                                    required={
                                      this.state.accountCorrespondanceRequired
                                    }
                                    onChange={this.handleChange("accCorrEmail")}
                                    value={this.state.accCorrEmail}
                                  />
                                  {/* Account Correspondance Phone */}
                                  <label className='input-label'>
                                    Phone Number
                                  </label>
                                  <MDBInput
                                    id='accCorrAddPhone'
                                    pattern='[0-9]{10,15}'
                                    label='Phone number'
                                    outline
                                    required={
                                      this.state.accountCorrespondanceRequired
                                    }
                                    onChange={this.handleChange(
                                      "accCorrAddPhone"
                                    )}
                                    value={this.state.accCorrAddPhone}
                                  />
                                </MDBCol>
                              </MDBRow>
                              {/* Postcode Lookup*/}
                              <label className='input-label'>Postcode</label>
                              <MDBRow>
                                <MDBCol size='8' className='p-0'>
                                  <MDBInput
                                    id='accCorrPostcode'
                                    pattern='^[A-Z a-z 0-9]{6,8}$'
                                    label='Postcode'
                                    outline
                                    required={
                                      this.state.accountCorrespondanceRequired
                                    }
                                    onChange={this.handleChange(
                                      "accCorrPostcode"
                                    )}
                                    value={this.state.accCorrPostcode}
                                  />
                                </MDBCol>
                                <MDBCol size='4'>
                                  <MDBBtn
                                    className='m-0 find-address-btn'
                                    onClick={() => this.corAddressLookup()}
                                  >
                                    Find Address
                                  </MDBBtn>
                                </MDBCol>
                              </MDBRow>

                              {/* Select */}
                              {this.state.showAddressSelect ? (
                                <Select
                                  options={this.state.addressSelect}
                                  onChange={this.corAddressSelect}
                                  // value={this.state.selectedAddress}
                                />
                              ) : null}

                              {/* Address  */}
                              <label className='input-label'>Address</label>
                              {/* Line one */}
                              <MDBInput
                                id='accCorrAddLineOne'
                                pattern='^[A-Z a-z 0-9]{1,100}$'
                                label='Building Name/Number'
                                outline
                                onChange={this.handleChange(
                                  "accCorrAddLineOne"
                                )}
                                value={this.state.accCorrAddLineOne}
                              />
                              {/* Line two */}
                              <MDBInput
                                id='accCorrAddLineTwo'
                                pattern='^[A-Z a-z 0-9]{1,100}$'
                                label='Street'
                                outline
                                onChange={this.handleChange(
                                  "accCorrAddLineTwo"
                                )}
                                value={this.state.accCorrAddLineTwo}
                              />
                              {/* City  */}
                              <MDBInput
                                id='accCorrCity'
                                pattern='^[A-Z a-z 0-9]{1,100}$'
                                label='Town'
                                outline
                                onChange={this.handleChange("accCorrCity")}
                                value={this.state.accCorrCity}
                              />
                              {/* County  */}
                              <MDBInput
                                id='accCorrCounty'
                                pattern='^[A-Z a-z 0-9]{1,100}$'
                                label='County'
                                outline
                                onChange={this.handleChange("accCorrCounty")}
                                value={this.state.accCorrCounty}
                              />
                            </div>
                          ) : null}
                        </div>
                        {/* Joint Application Radio Buttons */}
                        <div className='white-curved-tile text-left'>
                          <div>
                            <h4>Joint Application</h4>
                            <p className='input-label'>
                              Is this a joint application?
                            </p>
                            <div className='radio-container'>
                              {/* Yes */}
                              <input
                                id='jointApplicationYes'
                                name='jointApplication'
                                type='radio'
                                onClick={() => {
                                  this.setState({ jointPerson: true });
                                  this.jointDetailsRequired(true);
                                }}
                                required
                                className='default-radio'
                                checked={this.state.jointPerson ? true : false}
                              />
                              <label for='jointApplicationYes'>Yes</label>
                              {/* No */}
                              <input
                                id='jointApplicationNo'
                                name='jointApplication'
                                type='radio'
                                onClick={() => {
                                  this.setState({ jointPerson: false });
                                  this.jointDetailsRequired(false);
                                }}
                                className='default-radio'
                                checked={
                                  this.state.jointPerson === false
                                    ? true
                                    : false
                                }
                              />
                              <label htmlFor='jointApplicationNo'>No</label>
                            </div>
                          </div>
                          {/* Joint Account Details */}
                          {this.state.jointPerson != false ? (
                            <div>
                              <h4>Joint Applicant Information</h4>
                              <MDBRow>
                                <MDBCol lg='12' className='p-0'>
                                  <label className='input-label'>Title</label>
                                  <select
                                    id='jointTitle'
                                    pattern='[A-Za-z]{1,10}'
                                    className='browser-default custom-select mt-3'
                                    required={this.state.jointDetailsRequired}
                                    onChange={this.handleChange("jointTitle")}
                                    value={this.state.jointTitle}
                                  >
                                    <option>Title</option>
                                    <option value='Mr'>Mr</option>
                                    <option value='Mrs'>Mrs</option>
                                    <option value='Miss'>Miss</option>
                                    <option value='Ms'>Ms</option>
                                    <option value='Dr'>Dr</option>
                                    <option value='Prof'>Prof</option>
                                  </select>
                                </MDBCol>
                              </MDBRow>
                              <MDBRow className='mb-2'>
                                <MDBCol lg='12' className='text-left p-0'>
                                  {/* Joint Account First Name */}
                                  <label className='input-label'>
                                    First Name
                                  </label>
                                  <MDBInput
                                    id='jointFirstName'
                                    pattern='^[A-Z a-z]{1,32}$'
                                    label='FirstName'
                                    outline
                                    required={this.state.jointDetailsRequired}
                                    onChange={this.handleChange(
                                      "jointFirstName"
                                    )}
                                    value={this.state.jointFirstName}
                                  />
                                  {/* Joint Account Surname */}
                                  <label className='input-label'>Surname</label>
                                  <MDBInput
                                    id='jointSurname'
                                    pattern='^[A-Z a-z]{1,32}$'
                                    label='Surname'
                                    outline
                                    required={this.state.jointDetailsRequired}
                                    onChange={this.handleChange("jointSurname")}
                                    value={this.state.jointSurname}
                                  />
                                  {/* Joint Account Email */}
                                  <label className='input-label'>
                                    Email Address
                                  </label>
                                  <MDBInput
                                    id='jointEmail'
                                    pattern='^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                                    label='Email Address'
                                    outline
                                    required={this.state.jointDetailsRequired}
                                    onChange={this.handleChange("jointEmail")}
                                    value={this.state.jointEmail}
                                  />
                                  {/* Joint Account Phone */}
                                  <label className='input-label'>
                                    Phone Number
                                  </label>
                                  <MDBInput
                                    id='jointPhone'
                                    pattern='^[0-9]{10,15}$'
                                    label='Phone number'
                                    outline
                                    required={this.state.jointDetailsRequired}
                                    onChange={this.handleChange("jointPhone")}
                                    value={this.state.jointPhone}
                                  />
                                </MDBCol>
                              </MDBRow>
                            </div>
                          ) : null}
                        </div>
                        {/* Additional services */}
                        <MDBRow className='mb-2'>
                          <MDBCol lg='12' className='text-left p-0'>
                            <div className='text-left white-curved-tile'>
                              <h4>Do you require any additional services?</h4>
                              <div>
                                We offer additional services to those customers
                                who are critically dependent on electrically
                                powered medical equipment or who are of
                                pensionable age, disabled or chronically sick.
                                Do you require additional services?
                              </div>
                              <div className='my-3'>
                                You can contact us at any time if your
                                circumstances change.
                              </div>
                              <div className='radio-container'>
                                {/* Yes */}
                                <input
                                  id='serviceCheckYes'
                                  name='serviceCheck'
                                  className='default-radio'
                                  type='radio'
                                  onClick={() => this.serviceCheck(true)}
                                  required
                                  checked={
                                    this.state.serviceCheck ? true : false
                                  }
                                />
                                <label for='serviceCheckYes'>Yes</label>
                                {/* No */}
                                <input
                                  id='serviceCheckNo'
                                  name='serviceCheck'
                                  className='default-radio'
                                  type='radio'
                                  onClick={() => this.serviceCheck(false)}
                                  checked={
                                    this.state.serviceCheck === false
                                      ? true
                                      : false
                                  }
                                />
                                <label for='serviceCheckNo'>No</label>
                              </div>
                            </div>

                            {/* Additional Services */}
                            {/* Hide unless additional services = true  */}
                            {this.state.serviceCheck ? (
                              <div className='text-left white-curved-tile'>
                                <h4>Additional Services</h4>
                                {/* Medical Services */}
                                <p className='input-label'>
                                  Are you critically dependent on electrically
                                  powered medical equipment?
                                </p>
                                <div className='radio-container'>
                                  {/* Yes */}
                                  <input
                                    id='MedicalServicesYes'
                                    name='MedicalServices'
                                    className='default-radio'
                                    type='radio'
                                    onClick={() =>
                                      this.setState({ medicalServices: true })
                                    }
                                    required={this.state.addServicesRequired}
                                    checked={
                                      this.state.medicalServices ? true : false
                                    }
                                  />
                                  <label for='MedicalServicesYes'>Yes</label>
                                  {/* No */}
                                  <input
                                    id='MedicalServicesNo'
                                    name='MedicalServices'
                                    className='default-radio'
                                    type='radio'
                                    onClick={() =>
                                      this.setState({ medicalServices: false })
                                    }
                                    checked={
                                      this.state.medicalServices === false
                                        ? true
                                        : false
                                    }
                                  />
                                  <label for='MedicalServicesNo'>No</label>
                                </div>
                                {/* Medical Services Select */}
                                {/* only show when medical services is true */}
                                {this.state.medicalServices != false ? (
                                  <div>
                                    <p className='input-label'>
                                      By providing this information you are
                                      giving us permission to contact NIE
                                      Networks in relation to your condition.{" "}
                                    </p>
                                    <p className='input-label'>
                                      Please select the required electrical
                                      equipment
                                    </p>
                                    <MDBRow>
                                      <MDBCol size='12' className='p-0'>
                                        <Select
                                          onChange={this.MedicalEquipmentChange}
                                          options={NIMedicalEquipmentData}
                                          className='multiselect multiselect-width'
                                          placeholder='Please select an option'
                                          value={
                                            this.state.requiredMedicalServices
                                          }
                                        />
                                      </MDBCol>
                                    </MDBRow>

                                    {/* Priority Register */}
                                    <p className='input-label'>
                                      Are you on the medical care register?
                                    </p>
                                    <div className='radio-container'>
                                      {/* Yes */}
                                      <input
                                        id='priorityYes'
                                        name='priorityRegister'
                                        className='default-radio'
                                        type='radio'
                                        onClick={() =>
                                          this.setState({
                                            priorityRegister: true,
                                          })
                                        }
                                        checked={
                                          this.state.priorityRegister
                                            ? true
                                            : false
                                        }
                                      />
                                      <label for='priorityYes'>Yes</label>
                                      {/* No */}
                                      <input
                                        id='priorityNo'
                                        name='priorityRegister'
                                        className='default-radio'
                                        type='radio'
                                        onClick={() =>
                                          this.setState({
                                            priorityRegister: false,
                                          })
                                        }
                                        checked={
                                          this.state.priorityRegister === false
                                            ? true
                                            : false
                                        }
                                      />
                                      <label for='priorityNo'>No</label>
                                    </div>
                                  </div>
                                ) : null}

                                {/* Special Services */}
                                <p className='input-label'>
                                  Are you of pensionable age, disabled or
                                  chronically ill?
                                </p>
                                <div className='radio-container'>
                                  {/* Yes */}
                                  <input
                                    id='SpecialServicesYes'
                                    name='SpecialServices'
                                    className='default-radio'
                                    type='radio'
                                    onClick={() => {
                                      this.setState({ specialServices: true });
                                      this.specialServicesRequired(true);
                                    }}
                                    required={this.state.addServicesRequired}
                                    checked={
                                      this.state.specialServices ? true : false
                                    }
                                  />
                                  <label for='SpecialServicesYes'>Yes</label>
                                  {/* No */}
                                  <input
                                    id='SpecialServicesNo'
                                    name='SpecialServices'
                                    className='default-radio'
                                    type='radio'
                                    onClick={() => {
                                      this.setState({
                                        specialServices: false,
                                      });
                                      this.specialServicesRequired(false);
                                    }}
                                    checked={
                                      this.state.specialServices === false
                                        ? true
                                        : false
                                    }
                                  />
                                  <label for='SpecialServicesNo'>No</label>
                                </div>

                                {/* Special Services Select */}
                                {/* only show when special services is true */}
                                {this.state.specialServices != false ? (
                                  <MDBRow>
                                    <MDBCol size='12' className='p-0'>
                                      <label className='input-label'>
                                        Please select which options apply to you
                                      </label>
                                      <Select
                                        isMulti
                                        onChange={this.SpecialServicesChange}
                                        options={NISpecialServiceData}
                                        className='multiselect multiselect-width'
                                        placeholder='You can have multiple selections'
                                        required={
                                          this.state.specialServicesRequired
                                        }
                                        value={
                                          this.state.requiredSpecialServices
                                        }
                                      />
                                    </MDBCol>
                                  </MDBRow>
                                ) : null}
                              </div>
                            ) : null}
                          </MDBCol>
                        </MDBRow>

                        {/* Nominated Person */}
                        {this.state.serviceCheck ? (
                          <MDBRow className='mb-2'>
                            <MDBCol lg='12' className='text-left p-0'>
                              <div className='text-left white-curved-tile'>
                                <h4>Nominated Person</h4>
                                <p className='input-label'>
                                  Would you like to appoint a nominated person
                                  to your account?
                                  {/* Tooltip */}
                                  <MDBBtn className='tooltip-container'>
                                    <MDBIcon
                                      icon='info-circle'
                                      className='mr-2'
                                      onClick={() =>
                                        this.setState({
                                          nomTip: !this.state.nomTip,
                                        })
                                      }
                                    />
                                  </MDBBtn>
                                </p>
                                {/* Help alert */}
                                <div id='helpAlert'>
                                  {this.state.nomTip != false ? (
                                    <MDBAlert className='help-alert' dismiss>
                                      <MDBRow>
                                        <MDBCol
                                          size='2'
                                          className='align-self-center'
                                        >
                                          <img
                                            src={require("../../../../images/global/bullet-large.png")}
                                          />
                                        </MDBCol>
                                        <MDBCol size='10'>
                                          You can select someone you know to
                                          help manage your account. This means
                                          you give us permission to talk to that
                                          person about your account and send
                                          them copies of your bills and any
                                          correspondence. We'll need to contact
                                          them to get their permission also.
                                        </MDBCol>
                                      </MDBRow>
                                    </MDBAlert>
                                  ) : null}
                                </div>

                                {/* Nonminated Person Radio Buttons */}
                                <div className='radio-container'>
                                  {/* Yes */}
                                  <input
                                    id='nominatedPersonYes'
                                    name='nominatedPerson'
                                    type='radio'
                                    onClick={() => {
                                      this.setState({ nomPerson: true });
                                      this.setState({
                                        nomDetailsRequired: true,
                                      });
                                    }}
                                    required={this.state.nomPersonRequired}
                                    className='default-radio'
                                    checked={
                                      this.state.nomPerson ? true : false
                                    }
                                  />
                                  <label for='nominatedPersonYes'>Yes</label>
                                  {/* No */}
                                  <input
                                    id='nominatedPersonNo'
                                    name='nominatedPerson'
                                    type='radio'
                                    onClick={() => {
                                      this.setState({ nomPerson: false });
                                      this.setState({
                                        nomDetailsRequired: false,
                                      });
                                    }}
                                    className='default-radio'
                                    checked={
                                      this.state.nomPerson === false
                                        ? true
                                        : false
                                    }
                                  />
                                  <label htmlFor='nominatedPersonNo'>No</label>
                                </div>
                              </div>

                              {this.state.nomPerson != false ? (
                                <div className='text-left white-curved-tile'>
                                  <h4>Nominated Person's Information</h4>
                                  <div>
                                    <MDBRow>
                                      <MDBCol lg='12' className='p-0'>
                                        <label className='input-label'>
                                          Title
                                        </label>
                                        <select
                                          id='nomTitle'
                                          pattern='[A-Za-z]{1,32}'
                                          className='browser-default custom-select mt-3'
                                          onChange={this.handleChange(
                                            "nomTitle"
                                          )}
                                          className='browser-default custom-select'
                                          required={
                                            this.state.nomDetailsRequired
                                          }
                                          value={this.state.nomTitle}
                                        >
                                          <option>Title</option>
                                          <option value='Mr'>Mr</option>
                                          <option value='Mrs'>Mrs</option>
                                          <option value='Miss'>Miss</option>
                                          <option value='Ms'>Ms</option>
                                          <option value='Dr'>Dr</option>
                                          <option value='Prof'>Prof</option>
                                        </select>
                                      </MDBCol>
                                    </MDBRow>
                                    <MDBRow className='mb-2'>
                                      <MDBCol lg='12' className='text-left p-0'>
                                        {/* Nom Firstname */}
                                        <label className='input-label'>
                                          First Name
                                        </label>
                                        <MDBInput
                                          id='nomFirstName'
                                          pattern='^[A-Z a-z]{1,32}$'
                                          label='FirstName'
                                          outline
                                          onChange={this.handleChange(
                                            "nomFirstName"
                                          )}
                                          required={
                                            this.state.nomDetailsRequired
                                          }
                                          value={this.state.nomFirstName}
                                        />
                                        {/* Nom Surname */}
                                        <label className='input-label'>
                                          Surname
                                        </label>
                                        <MDBInput
                                          id='nomSurname'
                                          pattern='^[A-Z a-z]{1,32}$'
                                          label='Surname'
                                          outline
                                          onChange={this.handleChange(
                                            "nomSurname"
                                          )}
                                          required={
                                            this.state.nomDetailsRequired
                                          }
                                          value={this.state.nomSurname}
                                        />
                                        {/* Nom Email */}
                                        <label className='input-label'>
                                          Email Address
                                        </label>
                                        <MDBInput
                                          id='nomEmail'
                                          label='Email Address'
                                          pattern='^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                                          outline
                                          onChange={this.handleChange(
                                            "nomEmail"
                                          )}
                                          required={
                                            this.state.nomDetailsRequired
                                          }
                                          value={this.state.nomEmail}
                                        />
                                        {/* Nom Phone */}
                                        <label className='input-label'>
                                          Phone Number
                                        </label>
                                        <MDBInput
                                          id='nomPhone'
                                          pattern='^[0-9]{10,15}$'
                                          label='Phone number'
                                          outline
                                          onChange={this.handleChange(
                                            "nomPhone"
                                          )}
                                          required={
                                            this.state.nomDetailsRequired
                                          }
                                          value={this.state.nomPhone}
                                        />
                                      </MDBCol>
                                    </MDBRow>
                                    {/* Nom Address */}
                                    <h4>Nominated Person's Address</h4>

                                    {/* Postcode Lookup*/}
                                    <label className='input-label'>
                                      Postcode
                                    </label>
                                    <MDBRow>
                                      <MDBCol size='8' className='p-0'>
                                        <MDBInput
                                          id='nomPostcode'
                                          pattern='^[A-Z a-z 0-9]{6,8}$'
                                          label='Postcode'
                                          outline
                                          required={
                                            this.state.nomDetailsRequired
                                          }
                                          onChange={this.handleChange(
                                            "nomPostcode"
                                          )}
                                          value={this.state.nomPostcode}
                                        />
                                      </MDBCol>
                                      <MDBCol size='4'>
                                        <MDBBtn
                                          className='m-0 find-address-btn'
                                          onClick={() =>
                                            this.nomAddressLookup()
                                          }
                                        >
                                          Find Address
                                        </MDBBtn>
                                      </MDBCol>
                                    </MDBRow>

                                    {/* Select */}
                                    {this.state.showAddressSelect ? (
                                      <Select
                                        options={this.state.addressSelect}
                                        onChange={this.nomAddressSelect}
                                        // value={this.state.selectedAddress}
                                      />
                                    ) : null}

                                    {/* Address  */}
                                    <label className='input-label'>
                                      Address
                                    </label>
                                    {/* Line one */}
                                    <MDBInput
                                      id='nomAddrLine1'
                                      pattern='^[A-Z a-z 0-9]{1,100}$'
                                      label='Building Name/Number'
                                      outline
                                      onChange={this.handleChange(
                                        "nomAddrLine1"
                                      )}
                                      value={this.state.nomAddrLine1}
                                    />
                                    {/* Line two */}
                                    <MDBInput
                                      id='nomAddrLine2'
                                      pattern='^[A-Z a-z 0-9]{1,100}$'
                                      label='Street'
                                      outline
                                      onChange={this.handleChange(
                                        "nomAddrLine2"
                                      )}
                                      value={this.state.nomAddrLine2}
                                    />
                                    {/* City  */}
                                    <MDBInput
                                      id='nomCity'
                                      pattern='^[A-Z a-z 0-9]{1,100}$'
                                      label='Town'
                                      outline
                                      onChange={this.handleChange("nomCity")}
                                      value={this.state.nomCity}
                                    />
                                    {/* County  */}
                                    <MDBInput
                                      id='nomCounty'
                                      pattern='^[A-Z a-z 0-9]{1,100}$'
                                      label='County'
                                      outline
                                      onChange={this.handleChange("nomCounty")}
                                      value={this.state.nomCounty}
                                    />
                                  </div>
                                </div>
                              ) : null}
                            </MDBCol>
                          </MDBRow>
                        ) : null}

                        {/* Marketing */}
                        <div className='white-curved-tile text-left'>
                          <h4>Marketing Preferences</h4>
                          <p className='input-label'>
                            We would love to keep you updated with our latest
                            offers and news. If you’re ok with this, please
                            select how you would like us to keep in touch:
                          </p>
                          <MDBRow className='checkbox-container'>
                            {/* Marketing Pref Email */}
                            <MDBCol size='12'>
                              <input
                                id='marketingEmail'
                                name='marketingEmail'
                                type='checkbox'
                                onChange={() =>
                                  this.setState({
                                    markEmail: !this.state.markEmail,
                                  })
                                }
                                checked={this.state.markEmail}
                              />
                              <label for='marketingEmail' className='ml-2'>
                                Email
                              </label>
                            </MDBCol>
                            {/* Marketing Pref SMS */}
                            <MDBCol size='12'>
                              <input
                                id='marketingSMS'
                                name='marketingSMS'
                                type='checkbox'
                                onChange={() =>
                                  this.setState({
                                    markSMS: !this.state.markSMS,
                                  })
                                }
                                checked={this.state.markSMS}
                              />
                              <label for='marketingSMS' className='ml-2'>
                                SMS
                              </label>
                            </MDBCol>
                            {/* Marketing Pref Post */}
                            {/* <MDBCol size='12'>
                              <input
                                id='marketingPost'
                                name='marketingPost'
                                type='checkbox'
                                onChange={() =>
                                  this.setState({
                                    markPost: !this.state.markPost,
                                  })
                                }
                                checked={this.state.markPost}
                              />
                              <label htmlFor='marketingPost' className='ml-2'>
                                Post
                              </label>
                            </MDBCol> */}
                            {/* Marketing Pref Phone */}
                            <MDBCol size='12'>
                              <input
                                id='marketingPhone'
                                name='marketingPhone'
                                type='checkbox'
                                onChange={() =>
                                  this.setState({
                                    markPhone: !this.state.markPhone,
                                  })
                                }
                                checked={this.state.markPhone}
                              />
                              <label htmlFor='marketingPhone' className='ml-2'>
                                Phone
                              </label>
                            </MDBCol>
                          </MDBRow>
                          <p className='input-label'>
                            You can update your marketing preferences at any
                            time in your bright account. For details on how we
                            use your information please view our{" "}
                            <a
                              href={`/${this.state.jurisdiction}/privacystatement`}
                            >
                              Privacy Policy
                            </a>
                          </p>
                        </div>
                        {/* Continue Button */}
                        <MDBRow>
                          <MDBCol size='6'>
                            <h5>
                              Next Step: <span>Your Electricity</span>
                            </h5>
                            <MDBBtn className='btn' onClick={this.nextStep}>
                              Continue
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol size='4' className='show-for-large'>
                        <img
                          className='brand-image'
                          src={require("../../../../images/NI Switch/GreenElectricity.png")}
                        />

                        {/* Chat functionality */}
                        <div className='white-curved-tile text-left mt-3'>
                          <h5 className='mt-4 text-center'>Need some help?</h5>
                          {/* Live chat */}
                          <div className='switch-live-chat'>
                            <div data-id='VVUGGBG3WTc' class='livechat_button'>
                              <a href='https://www.livechat.com/utm-builder/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_11999496'>
                                UTM Builder
                              </a>
                            </div>
                            <LiveChat license={11999496} />
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.switch.isLoading,
    journeyData: store.switch.switchData,
    addressLookupData: store.switch.addressLookupData,
    addressLookupPostcode: store.switch.addressLookupPostcode,
    addressLookupError: store.switch.addressLookupError,
  };
};

const actionCreators = {
  getSwitch: switchActions.getSwitchJourney,
  createSwitch: switchActions.createSwitchJourney,
  updateSwitch: switchActions.updateSwitchJourney,
  addressLookup: switchActions.addressLookupNI,
};

export default connect(mapStateToProps, actionCreators)(AccountDetails);
