import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import LegalTabs from "../../../components/About/LegalTabs";
import cookiePDF from "../../../documents/cookiepolicy.pdf";

import $ from "jquery";

class NIOurPolicies extends Component {
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.help);
  }

  help =() =>{
    if (window.pageYOffset <50)
    {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }


  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark /> */}

        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
              <h1>Policies & Terms</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow>
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <LegalTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5 pb-5'>
            <h3 id="cookie-policy">Our Policies</h3>
            <div className="policy-links py-4">
              <a className="montserrat-Bold" href="#cookie-policy">Cookie Policy</a>
              <a className="montserrat-Bold" href="#security-deposit-policy">Security Deposit</a>
              <a className="montserrat-Bold" href="#complaints-policy">Complaints</a>
            </div>

            <hr/>
            {/* Cookie Policy */}
            <h4 className="my-4">Cookie Policy</h4>
            <div className='links mt-4'>
              <p className="montserrat-Bold">Introduction</p>
              <p>
                To help provide you with a good user experience when you browse our website, we use cookies to distinguish you from other users. These cookies also enable us to improve our site.
              <br /><br />
              Cookies contain information that may be about you, your preferences, or your devices, it does not usually
              directly identify you but can be used to give you a more personalised web experience.
              <br /><br />
                <b>More about cookies....</b>
              </p>

              <p className="montserrat-Bold">How do I disable cookies?</p>
              <p>
                You can choose not to accept cookies when you visit our website. We have laid out clearly how we use
                cookies and why, so you can understand more about this first.
              <br /><br />
              You can disable some or all use of cookies in your browser settings, however it is important to note that if
              you block all cookies you may not be able to access all or parts of our site.
              </p>

              <p className="montserrat-Bold">How does bright use cookies?</p>
              <p>We use cookies to help us recognise users and the information they provide as they navigate around our site. For example, cookies can remember:
              <ul>
                <li>That you are already logged in to your bright account.</li>
                <li>The information you provided, to help us generate a quote.</li>
              </ul></p>

              <p>We want our user’s website and app experience to be easy, so we use cookies to help us do clever things like:
              <ul>
                <li>Identify your location preference to show relevant content.</li>
                <li>Show you a unique version of our website that is different to everyone else’s, so we can test which
                  designs work best.</li>
                <li>Continue an online chat that you have come back to at a later date.</li>
                <li>Website optimisation: we use tools to test which web designs work best by showing a different version to a percentage of website users.</li>
                <li>Website behaviour: we use tools to see how users use our site so we can plan updates to improve the user experience.</li>
              </ul></p>

              <p>We use performance cookies to help us understand things like:
              <ul>
                <li>The number of visits to our sites;</li>
                <li>The number of pages visited;</li>
                <li>Time spent on site;</li>
                <li>What devices where used; and</li>
                <li>Demographics of our visitors.</li>
              </ul></p>

              <p>We use targeting cookies to help deliver our advertising.
              <ul>
                <li>Advertising performance: we use tools to measure the effectiveness of our advertising campaigns and to optimise our adverts so that they are more relevant to you.</li>
                <li>Retargeting: we use retargeting cookies from platforms such as Google, Facebook and LinkedIn so we
                show a different message our ads and to exclude you from other ads that may not be relevant to you.</li>
              </ul></p>

              <p className="montserrat-Bold">Session cookies</p>
              <p>Also known as ‘temporary cookies’ these help us to recognise users and the information provided as you
              navigate through the website. They only retain information about your activities for as long as you remain
                on our website. The cookie is deleted when you close the browser.</p>

              <p className="montserrat-Bold">Permanent cookies</p>
              <p>Also known as ‘persistent’ cookies, these remain in operation even after you close the browser. Their
                  purpose is to remember things like your login details, so when you return to our site you don’t have to reenter every time you visit. </p>

              <p id="security-deposit-policy" className="montserrat-Bold">Third party cookies</p>
              <p>These are installed by third parties and aim to collect certain information from web users to carry out
              research on things like your behaviour and demographics. In advertising we commonly use these to
              ensure that products and services are marketed towards the right audience.</p>

            </div>


            <br /><hr /><br />

            {/* Security Deposit Policy */}
            <h4 className="my-4">Security Deposit Policy</h4>
            <div className='links'>
              <p className="montserrat-Bold">Fair for one, fair for all</p>
              <p>
              At bright we offer one simple tariff for all our customers, and we want to keep this tariff as low as we can.  We want to protect our customers who pay their bills according to our terms and conditions and minimise the risk of bad debt costs. Therefore, bright requires domestic customers who are switching to bright, moving to a new property where bright is the supplier, or changing payment methods to comply with one of the following options.
                <ul>  
               <li>Pay their bill by Direct Debit in advance; or</li> 
                <li>Purchase electricity through a prepayment meter;</li>
                </ul></p>
                <p>A security deposit of £125 will be charged where the customer declines to sign up to either option above.</p> 
                
               <p> We may refuse to supply electricity to you until the security deposit has been paid in full.
                </p>
              <br />
              <p className="montserrat-Bold">Repayment of Security Deposit</p>
              <p>
                Where;
                <br></br>
                <ul>
                  <li>For a period of 12 months you have paid all charges for electricity requested by us in full and on time; or</li>
                  <li>You end your contract with us and have paid all outstanding amounts due to us.</li>
                </ul>
                
                <p>We will repay the security deposit in full by account credit or bank transfer within 28 days of the date on which you became eligible for the security deposit to be repaid.</p> 

                <p id="complaints-policy">We may keep your deposit and use it to offset any debt you owe us where you end your agreement and owe us money.  Where the security deposit is greater than the amount owed, we will refund the difference to you, where the security deposit is less than the amount owed you will be responsible for paying the difference</p>

                <p>Where a customer returns to bright we may waive the deposit in situations where the customer has a minimum of one year’s previous history with all bills paid according to the agreed payment terms.</p> 
              </p>
               
            </div>

            <br /><hr /><br />

            {/* Complaints Policy */}
            <h4 className="my-4">Complaints Policy</h4>
            <div>
              <p className="montserrat-Bold">Our complaints process</p>
              <p>
                At bright we want to get it right first time, every time.  However, sometimes we make mistakes.  So, if you have a complaint or are unhappy with our services we want to know about it.  Our complaints process is free to access and fair for all.  We promise to investigate your complaint fully and work with you to find a resolution.
                <br /><br />
                To find out more about how we handle complaints see our code of practice on complaint handling.
                </p>
              <br />
              <p className="montserrat-Bold">Making a complaint</p>
              <p>You can make a complaint by:
              <ul>
                <li>Calling us on freephone <a href="tel:0808 164 4790">0808 164 4790</a></li>
                <li>Emailing <a href="mailto:complaints@brightenergy.com">complaints@brightenergy.com</a></li>
                <li>Write to us at Bright, 84-95 Great Patrick Street, Belfast, BT1 2LU</li>
              </ul></p>

              <br />
              <p className="montserrat-Bold">Dealing with your complaint </p>
              <p>
              We’ll try to resolve any issues as quickly as possible. If you phone us we will aim to deal with the issue there and then.  If that’s not possible or if you contact us by any other method we will fully investigate and aim to get back to you within 5 working days.  But if we can’t we’ll keep in touch to let you know we’re working on your issue. 
              </p>

              <p className="montserrat-Bold">Escalating your complaint</p>
              <p>If you aren’t satisfied with our response you can ask to have it reviewed by a Team Lead.  The Team Lead will review the complaint and why you are unhappy with our initial response. The Team Lead will provide you with a resolution within 10 working days and if that’s not possible we’ll be in touch to let you know we’re working on your issue. 
            <br /><br />
            If you remain unhappy you can escalate your complaint to our Head of Customer Services who will fully investigate your complaint and provide you with our final resolution. 
            </p>

              <p className="montserrat-Bold">Taking your complaint further</p>
              <p>If we are unable to resolve your complaint to your satisfaction, or if at any time you are unhappy with our response, you can contact the Consumer Council. This is an independent body which has the power to resolve customer disputes. This service is free of charge and accessible to all. If you deem the solution to be unsatisfactory you retain the right to take the matter to court. 
              <ul className="list-style-none pl-0">
                <li className="montserrat-Bold">The Consumer Council</li>
             
                <li>Floor 3, Seatem House</li>
               <li>28-32 Alfred Street</li>
                <li>Belfast</li>
                <li>BT2 8EN</li> 
                </ul>

                <p>In certain circumstances where the Consumer Council has been unable to resolve your complaint you may refer your complaint to the Utility Regulator. </p>          
                <ul className="list-style-none pl-0">  
                <li className="montserrat-Bold">Contact the Utility Regulator at:</li>

                <li>Queens House</li>
                <li>14 Queen Street</li>
                <li> Belfast, BT1 6ED</li>
                <li>Call: 028 9031 1575</li>
                  </ul></p>




            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NIOurPolicies;
