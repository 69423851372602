import { meterReadConstants } from "../constants";

let defaultState = {
  dbData: [],
  previousReading: [],
  meterStructure: [],
  error: "",
  isLoading: false,
  submitReadingSuccess: "",
  landlordReadingSuccess: "",
};

const meterReadReducer = (state = defaultState, action) => {
  switch (action.type) {
    // Submit Reading
    case meterReadConstants.METER_READ_REQUEST:
      return {
        ...state,
        dbData: action.payload,
        isLoading: true,
        submitReadingSuccess: "",
      };

    case meterReadConstants.METER_READ_SUCCESS:
      return {
        ...state,
        dbData: action.payload,
        error: "",
        isLoading: false,
        submitReadingSuccess: true,
      };
    case meterReadConstants.METER_READ_FAILURE:
      return {
        error: action.payload,
        submitReadingSuccess: false,
      };

    // Submit Landlord Reading
    case meterReadConstants.LANDLORD_METER_READ_REQUEST:
      return {
        ...state,
        dbData: action.payload,
        isLoading: true,
        landlordReadingSuccess: false,
      };

    case meterReadConstants.LANDLORD_METER_READ_SUCCESS:
      return {
        ...state,
        dbData: action.payload,
        error: "",
        isLoading: false,
        landlordReadingSuccess: true,
      };
    case meterReadConstants.LANDLORD_METER_READ_FAILURE:
      return {
        error: action.payload,
        landlordReadingSuccess: false,
      };

    // Get Reading
    case meterReadConstants.GET_METER_READ_REQUEST:
      return {
        ...state,
        previousReading: action.payload,
        isLoading: true,
      };

    case meterReadConstants.GET_METER_READ_SUCCESS:
      return {
        ...state,
        previousReading: action.payload,
        isLoading: false,
      };
    case meterReadConstants.GET_METER_READ_FAILURE:
      return {
        error: action.payload,
      };

    // Get Meter Structure
    case meterReadConstants.GET_METER_STRUCTURE_REQUEST:
      return {
        ...state,
        meterStructure: action.payload,
        isLoading: true,
      };

    case meterReadConstants.GET_METER_STRUCTURE_SUCCESS:
      return {
        ...state,
        meterStructure: action.payload,
        isLoading: false,
      };
    case meterReadConstants.GET_METER_STRUCTURE_FAILURE:
      return {
        error: action.payload,
      };

    // Default
    default:
      return state;
  }
};

export default meterReadReducer;
