import axios from "axios";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const additionalServicesService = {
  addAdditionalServices,
};

// Login
function addAdditionalServices(details) {
  return axios({
    method: "post",
    url: `${config.apiUrl}/additionalservices/addadditionalservices`,
    data: details,
  }).then((response) => {
    return response;
  });
}