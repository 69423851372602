export function sessionExpired() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    let sessionExpired = user.data.authenticateModel.sessionExpiry;
    let convertedSessionTime = new Date(sessionExpired);
    let timeNow = new Date();
    let pathArray = window.location.pathname.split("/");
    let juris = pathArray[1];
    let url = `/${juris}/account/login`;

    if (timeNow > convertedSessionTime) {
      localStorage.removeItem("user");
      window.location.href = url;
    }
  }
}
