import FusionCharts from "fusioncharts";

FusionCharts.register("theme", {
  name: "bright",
  theme: {
    base: {
      chart: {
        palette: 2,
        paletteColors: "#00ffff",
        labelDisplay: "ROTATE",
        showBorder: 0,
        bgColor: "#FFFFFF",
        lineColor: "#001D28",
        anchorBgColor: "#001D28",
        usePlotGradientColor: 1,
        plotGradientColor: "#00f41d, #00FFA3",
        plotFillRatio: "20, 100",
        showPlotBorder: 0,
        canvasBorderThickness: 0,
        canvasbgColor: "#FFFFFF",
        alternateHGridColor: "#FFFFFF",
        legendIconScale: 2,
        legendBorderThickness: 0,
        legendShadow: 0,
        legendItemFontSize: 12,
        plotSpacePercent: 50,
      },
    },
  },
});
FusionCharts.options.creditLabel = false;
