import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { sessionExpired } from "../../../../redux/helpers/sessionExpired";
import { Link } from "react-router-dom";

export const AgencyPaymentSuccess = (props) => {
  const [jurisdiction, setJurisdiction] = useState("");
  const [paymentId, setPaymentId] = useState();

  useEffect(() => {
    sessionExpired();

    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    setJurisdiction(jurisdiction);

  }, []);

  return (
    <div>
      <MDBRow center className="text-center">
        <MDBCol>
          <img
            src={require("../../../../images/account/success.png")}
            style={{ width: "30%" }}
          />

          <p className="my-5">Your payment has been successful.</p>
          <p>
            Payment ID: <b>{props.id}</b>
          </p>
        </MDBCol>
      </MDBRow>

      <MDBRow center>
        <MDBCol size="6">
          <Link
            to={{
              pathname: `/${jurisdiction}/account/landlorddashboard`,
            }}
          >
            <MDBBtn className="btn">Continue</MDBBtn>
          </Link>
        </MDBCol>
      </MDBRow>
    </div>
  );
};
