import React from "react";
import ROISwitchSummary from "../../../components/Forms/Switch/ROI/_FormSwitchSummary";

class SwtichSummary extends React.Component {
  state = {
    jurisdiction: "roi",
  };

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  render() {
    return (
      <div>
        <ROISwitchSummary />
      </div>
    );
  }
}

export default SwtichSummary;
