import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBAlert } from "mdbreact";
import { PaymentDetailsForm } from "./MakePayment/PaymentDetailsForm";
import { history } from "../../../redux/helpers";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { move } from "formik";
import axios from "axios";
import Loader from "../../Shared/Loader";
const LoadConfig = require("../../../config");
const config = LoadConfig.default;

const stripePromise = loadStripe(config.stripeKey);

export const PayOnReceipt = (props) => {
  const [userEmail, setUserEmail] = useState();
  const [billAvailable, setBillAvailable] = useState();
  const [jurisdiction, setJurisdiction] = useState();
  const [nextPaymentDate, setNextPaymentDate] = useState();
  const [nextPaymentAmount, setNextPaymentAmount] = useState();
  const [accountBalance, setAccountBalance] = useState();
  const [userToken, setUserToken] = useState();
  const [accountNum, setAccountNum] = useState();

  const [showPayment, setShowPayment] = useState(true);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [currency, setCurrency] = useState();
  const [showMessage, setShowMessage] = useState();
  const [messageText, setMessageText] = useState();

  useEffect(() => {
    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];

    if (jurisdiction == "roi") {
      setCurrency("€");
    } else {
      setCurrency("£");
    }

    // Get information
    let user = JSON.parse(localStorage.getItem("user"));
    let userEmail = user.data.authenticateModel.username;
    let overallPORBalance = user.data.overallAccountBalance;
    setAccountBalance(overallPORBalance);
    setUserEmail(userEmail);

    let jModel = user.data.jModel;
    let accountNum = jModel.number;
    setAccountNum(accountNum);

    try {
      if (jModel.billsList.length > 0) {
        setBillAvailable(true);

        // Payment amount
        let bills = jModel.billsList;
        let latestBill = bills[0];
        let amount = 0;

        amount = overallPORBalance;
        
        // Next payment date
        let nextPaymentDate = latestBill.dueDt
          .toString()
          .slice(0, 10)
          .split("-")
          .reverse()
          .join("/");

        // Check Overall against current balance, set message
        let message = "";
        if(overallPORBalance > latestBill.balance){
          let balanceDifference = overallPORBalance - latestBill.balance;
          var curr = "";
          {(jurisdiction == "roi") ? curr = "€" : curr = "£" }

          message = `This includes: \n Latest bill of ${curr}${latestBill.balance.toFixed(2)}
           \n Previous balance of ${curr}${balanceDifference.toFixed(2)}`;

           let newText = message.split('\n').map(i => {
            return <p>{i}</p>
        });
          setShowMessage(true);
          setMessageText(newText);
        }else {
          message = "";
          setShowMessage(false);
          setMessageText(message);
        }

        // User token for axios request
        let userToken = user.data.authenticateModel.token;

        setJurisdiction(jurisdiction);
        setNextPaymentDate(nextPaymentDate);
        setNextPaymentAmount(amount);
        setUserToken(userToken);
      } else {
        setBillAvailable(false);
      }
    } catch {}
  }, []);

  const getBillPos = (bills) => {
    var billId = 0;
    for (var x = 0; x < bills.length; x++) {
      if (!bills[x].supersededFl) {
        billId = bills[x].id;
        return x;
      }
    }
  };

  const redirectPayment = () => {
    history.push({
      pathname: `/${jurisdiction}/account/payment`,
      state: { jur: jurisdiction }
    })
  }

  // Stripe payment intent
  const moveToCardPayment = () => {
    CreatePaymentIntent();
  };
  async function CreatePaymentIntent() {
    setShowLoader(true);

    let stripeMessage = userEmail;
    let amount = nextPaymentAmount;
    let details = {
      jurisdiction: jurisdiction,
      amount: amount,
      email: stripeMessage,
      description: "Jf Acc Num: " + accountNum + " - Web Payment."
    };

    await axios({
      method: "post",
      url: `${config.apiUrl}/payments/intentDesc`,
      data: details      
    }).then((response) => {
      let secret = response.data.client_secret;
      let id = response.data.id;

      localStorage.setItem("secret", secret);
      localStorage.setItem("paymentIntent", id);

      setShowLoader(false);
      setShowPayment(false);
      setShowPaymentDetails(true);
    });
  }

  return (
    <div>
      {showLoader ? <Loader /> : null}

      {showPayment ? (
        <div>
          {/* Your Payment Section */}
          {(accountBalance >0) ? (
            <div className='white-curved-tile text-center'>
              <h2>Your Payment</h2>
              <h2 className='price-figure'>
                {currency}
                {nextPaymentAmount.toFixed(2)}
              </h2>
              <p>
                Next Payment Due: <b>{nextPaymentDate}</b>
              </p>
              {showMessage ? (
                <div style={{color: "#7f8c8d"}}><p >{messageText}</p></div>
              ) : null }
              <MDBBtn className='btn' 
              onClick={()=> redirectPayment()}
              >
                Make Payment
              </MDBBtn>
            </div>
          ) : (
            <div className='white-curved-tile text-center'>
              <h4>
                Your account payments are up to date.
                <br />
                Check back soon to see when your next payment is due.
              </h4>
            </div>
          )}
        </div>
      ) : null}

      {showPaymentDetails ? (
        <div className='white-curved-tile text-center'>
          <Elements stripe={stripePromise}>
            <PaymentDetailsForm />
          </Elements>
        </div>
      ) : null}
    </div>
  );
};
