import React, { Component } from "react";
import { MDBCol, MDBRow, MDBBtn, MDBAlert } from "mdbreact";
import { connect } from "react-redux";
import { quoteActions } from "../../../../redux/actions/quoteActions";
import Slider from "react-rangeslider";
import "../../../../assets/rangeSlider.css";
import Loader from "../../../Shared/Loader";

import $ from "jquery";

export class _FormMeterType extends Component {
  state = {
    propertyType: "",
    residents: 1,
    errorMessage: false,
    jurisdiction: "roi",
    referralId: "",
    homeCheck: false,
  };

  // Page load
  componentDidMount() {
    // Check for referral ID
    let urlParams = new URLSearchParams(window.location.search);
    let referralId = urlParams.get("referralId");

    if (referralId != null) {
      this.setState({ referralId: referralId });
      localStorage.setItem("referralID", referralId);
    }

    if (localStorage.getItem("quoteID") != null) {
      let quoteID = localStorage.getItem("quoteID");
      this.props.getQuote(quoteID);
    } else {
      // Set quote page status
      localStorage.setItem("propertyUpdated", false);
      localStorage.setItem("meterUpdated", false);
      localStorage.setItem("paymentUpdated", false);
      localStorage.setItem("gasUpdated", false);
      localStorage.setItem("evUpdated", false);
    }
  }

  // Populate form on back
  async componentWillReceiveProps(nextProps) {
    // Get page status from local storage
    let pageStatus = JSON.parse(localStorage.getItem("propertyUpdated"));

    // If page status e.g. pageUpdated = true -> populate the form
    if (pageStatus) {
      await this.setState({
        propertyType: nextProps.quoteData.propertyType,
        residents: nextProps.quoteData.residents,
      });
    }
    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle slider changing
  handleSliderChange = (value) => {
    this.setState({
      residents: value,
    });
  };

  // Handle call type Update/Create
  callType = () => {
    let pageStatus = JSON.parse(localStorage.getItem("propertyUpdated"));

    if (pageStatus) {
      this.updateQuote();
    } else {
      this.createQuote();
    }
  };

  // Create quote
  createQuote = (e) => {
    // Validate Form
    let validation = $("#propertyDetails")[0].checkValidity();

    if (validation) {
      this.setState({ errorMessage: false });

      // Form data
      const data = {
        dateSubmitted: new Date().toISOString().slice(0, 19),
        jurisdiction: "ROI",
        propertyType: this.state.propertyType,
        residents: parseInt(this.state.residents),
        referralId: this.state.referralId,
      };

      // This Page (updates page status)
      let pageStatus = "propertyUpdated";

      // Next Page URL
      let nextPage = "meter";

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Create Quote Journey
      this.props.createQuote(data, pageStatus, nextPage, jurisdiction);
    } else {
      this.setState({ errorMessage: true });
    }
  };

  // Update quote
  updateQuote = (e) => {
    // Validate Form
    let validation = $("#propertyDetails")[0].checkValidity();

    if (validation) {
      this.setState({ errorMessage: false });

      // Form data
      const data = {
        // Step one (this step) - Property
        propertyType: this.state.propertyType,
        residents: this.state.residents,

        // Step two - Meter
        urbanRural: this.props.quoteData.urbanRural,
        meterType: this.props.quoteData.meterType,
        electricityUsage: this.props.quoteData.electricityUsage,

        // Step three - Payment
        paymentMethod: this.props.quoteData.paymentMethod,
        monthlyPayment: this.props.quoteData.monthlyPayment,

        // Step four - Gas
        onGasTariff: this.props.quoteData.onGasTariff,
        interestedInBrightGas: this.props.quoteData.interestedInBrightGas,

        // Step four - Ev
        hasElectricVehicle: this.props.quoteData.hasElectricVehicle,
        vehicleMake: this.props.quoteData.vehicleMake,
      };

      // Quote id from local storage
      let id = localStorage.getItem("quoteID");

      // This Page (updates page status)
      let pageStatus = "propertyUpdated";

      // Next Page URL
      let nextPage = "meter";

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Quote Journey
      this.props.updateQuote(id, data, pageStatus, nextPage, jurisdiction);
    } else {
      this.setState({ errorMessage: true });
    }
  };

  render() {
    // Slider labels
    const horizontalLabels = {
      0: "1",
      1: "2",
      2: "3",
      3: "4",
      4: "5",
      5: "6+",
    };

    return (
      <div className='quote-page'>
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        {/* Background Image */}
        <div className='device-image' />

        <form id='propertyDetails'>
          <MDBCol className='text-center'>
            {/* Logo */}
            <MDBRow>
              <MDBCol lg='2' sm='12'>
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <img
                    src={require("../../../../images/global/bright-logo-dark.png")}
                    className='quote-logo mx-5 mt-5'
                  />
                </a>
              </MDBCol>
              <MDBCol size='10' />
            </MDBRow>

            {/* Home message */}
            {this.state.homeCheck ? (
              <div className='modalBG'>
                <div className='modalBox'>
                  <div className='white-curved-tile text-center'>
                    <h4>
                      All progress will be lost! <br />
                      Are you sure?
                    </h4>
                    <MDBRow>
                      <MDBCol lg='6' md='6' sm='12'>
                        <MDBBtn
                          className='cancel-button'
                          onClick={() =>
                            this.setState({ homeCheck: !this.state.homeCheck })
                          }
                        >
                          Cancel
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol lg='6' md='6' sm='12'>
                        <MDBBtn onClick={() => (window.location.href = "/")}>
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Error Message */}
            <MDBRow className='text-center mt-5'>
              <MDBCol size='4' />
              <MDBCol lg='4' md='4' sm='12'>
                {this.state.errorMessage == true ? (
                  <MDBAlert color='danger'>
                    <strong>Oops!</strong> Please check your fields below.
                  </MDBAlert>
                ) : null}
              </MDBCol>
              <MDBCol size='4' />
            </MDBRow>

            {/* Property type */}
            <MDBRow className='mb-5'>
              <MDBCol size='3' />
              <MDBCol lg='6' md='6' sm='10' className='text-center'>
                {/* Question */}
                <h4 className='mb-4'>Tell us about your property type</h4>
                {/* House */}
                <input
                  onClick={() => this.setState({ propertyType: "House" })}
                  checked={this.state.propertyType === "House" ? true : false}
                  className='form-check-input'
                  id='house'
                  name='propertyType'
                  required
                  type='radio'
                />
                <label for='house' className='radioLabel'>
                  House
                </label>

                {/* Flat */}
                <input
                  onClick={() => this.setState({ propertyType: "Flat" })}
                  checked={this.state.propertyType === "Flat" ? true : false}
                  className='form-check-input'
                  id='flat'
                  name='propertyType'
                  type='radio'
                />
                <label for='flat' className='radioLabel'>
                  Flat
                </label>
              </MDBCol>
              <MDBCol size='3' />
            </MDBRow>

            {/* How many people */}
            <MDBRow className='my-5'>
              <MDBCol size='3' />
              <MDBCol lg='6' md='6' sm='6' className='text-center'>
                {/* Question */}
                <h4 className='mb-4'>How many people live in the property?</h4>
                {/* Range Slider */}
                <Slider
                  min={0}
                  max={5}
                  labels={horizontalLabels}
                  value={this.state.residents}
                  onChange={this.handleSliderChange}
                />
              </MDBCol>
              <MDBCol size='3' />
            </MDBRow>

            {/* Buttons */}
            <MDBRow className='my-5'>
              <MDBCol size='3' />
              <MDBCol lg='6' md='6' sm='10' className='text-center'>
                {/* Next Step */}
                <MDBBtn onClick={this.callType} className='btn'>
                  Continue
                </MDBBtn>
              </MDBCol>
              <MDBCol size='3' />
            </MDBRow>
          </MDBCol>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    quoteData: store.quote.quoteData,
    isLoading: store.quote.isLoading,
  };
};

const actionCreators = {
  createQuote: quoteActions.createQuoteJourney,
  updateQuote: quoteActions.updateQuoteJourney,
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(_FormMeterType);
