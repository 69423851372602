import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBAlert,
  MDBInput,
  MDBIcon,
} from "mdbreact";
import Loader from "../../Shared/Loader";

// Redux
import { connect } from "react-redux";
import { switchActions } from "../../../redux/actions/switchActions";
import { useSelector, useDispatch } from "react-redux";


// Select Data
import {
  SpecialServiceData,
  MedicalEquipmentData,
  RegistrationDetailsData,
} from "../../../helpers/SelectData";
import Select from "react-select";

const Form_AdditionalServicesNI = () => {

  //state
  const [howToTip, sethowToTip] = useState(false);
  const [jurisdiction, setJurisdiction] = useState("roi");
  const [medicalEquipment, setmedicalEquipment] = useState(false);
  const [errorMessage, seterrorMessage] = useState(false);
  const [nomTip, setnomTip] = useState(false);
  const [specialServicesRequired, setspecialServicesRequired] = useState(false);
  const [nomDetailsRequired, setnomDetailsRequired] = useState(false);
  const [switchGot, setswitchGot] = useState(false);
  const [homeCheck, sethomeCheck] = useState(false);
  const [addServicesReqired, setaddServicesRequired] = useState(false);
  const [nomPersonRequired, setnomPersonRequired] = useState(false);
  const [loading, setloading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  //functions
  const setrequiredMedicalEquipmentData = () => {

  }

  const setrequiredRegistrationDetailstData = () => {

  }

  const MedicalEquipmentChange = () => {

  }

  const RegistrationDetailsChange = () => {

  }

  function onSubmit (values) {
    console.log(values)
  }

  return (
    <div className="quote-page switch-page ">
      {/* API Loader */}
      {loading ? <Loader /> : null}

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Background Image */}
        <div className="device-image" />
        {/* Logo */}
        <MDBRow className="text-center">
          <MDBCol lg="2" sm="12">
            <a onClick={() => sethomeCheck(true)}>
              <img
                src={require("../../../images/global/bright-logo-dark.png")}
                className="quote-logo mx-5 mt-5"
              />
            </a>
          </MDBCol>
          <MDBCol size="10" />
        </MDBRow>

        {/* Home message */}
        {homeCheck ? (
          <div className="modalBG">
            <div className="modalBox">
              <div className="white-curved-tile text-center">
                <h4 className="mt-5">
                  All progress will be lost! <br />
                    Are you sure?
                  </h4>
                <MDBRow>
                  <MDBCol lg="6" md="6" sm="12">
                    <MDBBtn
                      className="cancel-button"
                      onClick={() =>
                        sethomeCheck(!homeCheck)
                      }
                    >
                      Cancel
                      </MDBBtn>
                  </MDBCol>

                  <MDBCol lg="6" md="6" sm="12">
                    <MDBBtn onClick={() => (window.location.href = "/")}>
                      Continue
                      </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        ) : null}

        <div className="quote-content-container ">
          <MDBContainer className="personal-details-section">
            <MDBRow>
              <MDBCol size="12">

                {/* Additional Services Check */}
                <MDBRow>
                  <MDBCol md="12" lg="8">
                    <div className="text-left white-curved-tile input-label">
                      <h4>Priority Services Register </h4>
                      <div>
                        At bright we value all our customers and we want to offer you our very best customer service. We recognise that some customers may require additional assistance and all our energy specialists are trained on the services and assistance we can offer.

                         <br /><br />
                         If you, or a member of your household is critically dependent on electricity (eg ventilator, oxygen concentrator, home dialysis etc) please use this form to register on our Priority Services Register. Alternatively you can call us on 01 588 1777 to register or request a paper copy.
                          <br /> <br />
                          Bright will not disconnect any customer on the Priority Service Register unless you request a disconnection, or for safety reasons (fault/maintenance).
                          <br /><br />
                          We’ll provide your details to ESB Networks so they can inform you of planned interruptions and prioritise your reconnection.
                          <br /><br />

                      </div>
                    </div>

                    <div className="text-left white-curved-tile input-label ">
                      <h5>Priority Services</h5>
                      <div>
                        For customers critically dependent on electricity supply
                          <br />
                        <br />

                          Account Number
                          <div class="md-form md-outline">
                          <input type="text" className="form-control browser-default" {...register("PriorityServicesAccNum",{
                                required: true,
                                pattern: { value: /[0-9]*/,
                                           message: "Invalid account number" }
                            })} />
                            {errors?.PriorityServicesAccNum?.message && <span>{errors?.PriorityServicesAccNum?.message}</span>}
                        </div>
                          MPRN
                          <div class="md-form md-outline">
                          <input type="text" className="form-control browser-default"{...register("PriorityServicesMprn", 
                            {
                                required: true,
                                pattern: { value: /^(?=\d{11}$)(10)\d+/,
                                           message: "Invalid MPRN" }
                            })} />
                            {errors?.PriorityServicesMprn?.message && <span>{errors?.PriorityServicesMprn?.message}</span>}
                        </div>

                          Name of account holder
                          <div class="md-form md-outline">
                          <input type="text" className="form-control browser-default"{...register("PriorityServicesAccHolderName", 
                          {
                            required: true,
                            pattern: { value: /^[A-Z a-z]{1,32}$/i,
                                       message: "Invalid name" }
                        })} />
                        {errors?.PriorityServicesAccHolderName?.message && <span>{errors?.PriorityServicesAccHolderName?.message}</span>}
                        </div>

                          Name of person to be registered if different from above
                          <div class="md-form md-outline">
                          <input type="text" className="form-control browser-default" {...register("PriorityServicesNameIfDifferent", 
                          {
                            required: true,
                            pattern: { value: /^[A-Z a-z]{1,32}$/i,
                                       message: "Invalid name" }
                        })} />
                        {errors?.PriorityServicesNameIfDifferent?.message && <span>{errors?.PriorityServicesNameIfDifferent?.message}</span>}
                        </div>

                           Address
                           <div class="md-form md-outline">
                          <input type="text" className="form-control browser-default" {...register("PriorityServicesAddress", 
                          {
                            required: true,
                            pattern: { value: /^[A-Z a-z 0-9]{1,32}$/i,
                                       message: "Invalid address" }
                        })} />
                        {errors?.PriorityServicesAddress?.message && <span>{errors?.PriorityServicesAddress?.message}</span>}
                        </div>

                           Contact number
                           <div class="md-form md-outline">
                          <input type="tel" className="form-control browser-default" {...register("PriorityServicesContactNum", 
                          {
                              required:true,
                              pattern: {
                                value: /[0-9]{10,15}/,
                                message: "Invalid phone number"
                              }
                          })}  />
                        </div>

                           Email
                           <div class="md-form md-outline">
                          <input type="email" className="form-control browser-default" {...register("PriorityServicesEmail", { 
                            pattern: {
                              value: /^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              message: "Invalid email"
                            }
                           })} />
                        </div>

                          Equipment details
                          <div class="md-form md-outline">
                          <select className="form-control browser-default" {...register("PriorityServicesEquipmentDetails", { })}>
                            <option value="Oxygen Concentrator">Oxygen Concentrator</option>
                            <option value=" Personal Suction Pump"> Personal Suction Pump</option>
                            <option value=" Home Dialysis"> Home Dialysis</option>
                            <option value=" Ventilator"> Ventilator</option>
                            <option value=" Electric Hoist"> Electric Hoist</option>
                            <option value=" Nebuliser"> Nebuliser</option>
                            <option value=" Other"> Other</option>
                          </select>
                        </div>




                      </div>
                    </div>



                    <div className="text-left white-curved-tile input-label">
                      <h4>Declaration</h4>
                        In filling this form you agree to share this information with the Electricity Network Operator.  This allows the Electricity Network Operator to provide you with additional services.
                          <br />
                      <br />
                      <b>Please note you may be asked to provide evidence of your use of this equipment from your doctor.</b>
                      <br /><br />

                           Name
                           <div class="md-form md-outline">
                        <input type="text" className="form-control browser-default"{...register("PriorityServicesDeclarationName", 
                        {
                          required: true,
                          pattern: { value: /^[A-Z a-z]{1,32}$/i,
                                       message: "Invalid name" }
                      })} />
                      {errors?.PriorityServicesDeclarationName?.message && <span>{errors?.PriorityServicesDeclarationName?.message}</span>}
                      </div>
                           Date
                           <div class="md-form md-outline">
                        <input type="datetime" className="form-control browser-default"{...register("PriorityServicesDeclarationDate", {  })} />
                      </div>
                       
                      
                    </div>

                    {/* Special Services */}

                    {/* Additional Services Check */}


                    {/* Additional Services */}

                    <div className="text-left white-curved-tile input-label">
                      <h4>Special Services</h4>
                      <div>
                        We recognise that some customers may require additional services due to advanced age, physical or mental health difficulties.  We provide additional communication methods for visually impaired or hearing impaired customers.  You can also set up a nominated person to discuss your account with us.
                          <br /><br />
                          We commit to never requesting disconnection of customers on our Special Services register during the winter months unless you request a disconnection, or for safety reasons (fault/maintenance).
                         <br /> <br />
                         Please use this form to register on our special services register.  Alternatively you can call us on 01 588 1777 to register or request a paper copy.
                          <br /><br />

                      </div>
                    </div>


                    <div className="text-left white-curved-tile input-label">
                      <h5>Special Services</h5>
                      <div>
                        For customers particularly vulnerable during winter months
                          <br />
                        <br />

                        Account Number
                          <div class="md-form md-outline">
                          <input type="text" className="form-control browser-default" {...register("SpecialServicesAccNum",
                          {
                            required: true,
                            pattern: { value: /[0-9]*/,
                              message: "Invalid account number" }
                        })}/>
                        {errors?.SpecialServicesAccNum?.message && <span>{errors?.SpecialServicesAccNum?.message}</span>}
                        </div>

                        MPRN
                          <div class="md-form md-outline">
                          <input type="number" className="form-control browser-default"{...register("SpecialServicesMprn", 
                          {
                            required: true,
                            pattern: { value: /^(?=\d{11}$)(10)\d+/,
                                       message: "Invalid MPRN" }
                        })} />
                        
                        </div>

                        Name of account holder
                          <div class="md-form md-outline">
                          <input type="text" className="form-control browser-default"{...register("SpecialServicesAccHolderName", 
                          {
                            required: true,
                            pattern: { value: /^[A-Z a-z]{1,32}$/i,
                                       message: "Invalid name" }
                        })} />
                        {errors?.SpecialServicesAccHolderName?.message && <span>{errors?.SpecialServicesAccHolderName?.message}</span>}
                        </div>

                         Name of person to be registered if different from above
                          <div class="md-form md-outline">
                          <input type="text" className="form-control browser-default" {...register("SpecialServicesNameIfDifferent", 
                          {
                            required: true,
                            pattern: { value: /^[A-Z a-z]{1,32}$/i,
                                       message: "Invalid name" }
                        })} />
                        {errors?.SpecialServicesNameIfDifferent?.message && <span>{errors?.SpecialServicesNameIfDifferent?.message}</span>}
                        </div>

                           Address
                           <div class="md-form md-outline">
                          <input type="text" className="form-control browser-default" {...register("SpecialServicesAddress", 
                          {
                            required: true,
                            pattern: { value: /^[A-Z a-z 0-9]{1,100}$/,
                                       message: "Invalid address" }
                        })} />
                        {errors?.SpecialServicesAddress?.message && <span>{errors?.SpecialServicesAddress?.message}</span>}
                        </div>

                          Contact number
                           <div class="md-form md-outline">
                          <input type="tel" className="form-control browser-default" {...register("SpecialServicesContactNum", {
                            required:true,
                            pattern: {
                              value: /[0-9]{10,15}/,
                              message: "Invalid phone number"
                            }
                          })} />
                        </div>

                           Email
                           <div class="md-form md-outline">
                          <input type="email" className="form-control browser-default" {...register("SpecialServicesEmail", { 
                            pattern: {
                              value: /^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              message: "Invalid email"
                            }
                           })} />
                        </div>

                          Alternative Contact number
                           <div class="md-form md-outline">
                          <input type="tel" className="form-control browser-default" {...register("SpecialServicesAlternateContactNum", {
                            pattern: {
                              value: /[0-9]{10,15}/,
                              message: "Invalid phone number"
                            }
                          })} />
                        </div>

                          Registration details
                          <div class="md-form md-outline">
                          <select className="form-control browser-default" {...register("SpecialServicesEquipmentDetails", {})}>
                            <option value="Mental Health">Mental Health</option>
                            <option value=" Deaf or hard of hearing"> Deaf or hard of hearing</option>
                            <option value=" Blind or Partially sighted"> Blind or Partially sighted</option>
                            <option value=" Elderly (aged 66 or over)"> Elderly (aged 66 or over)</option>
                            <option value=" Mobility impaired"> Mobility impaired</option>
                            <option value="  Dexterity Impaired">  Dexterity Impaired</option>
                            <option value=" Language difficulty"> Language difficulty</option>
                            <option value=" Speech Difficulty"> Speech Difficulty</option>
                            <option value=" Learning Difficulty"> Learning Difficulty</option>
                            <option value=" Other (please Specify)"> Other (please Specify)</option>
                          </select>
                        </div>


                      </div>
                    </div>

                    <div className="text-left white-curved-tile input-label">
                      <h4>Declaration</h4>
                      <b>Please note you may be asked to provide evidence of your use of this equipment from your doctor.</b>
                      <br />
                      <br />

                      Name
                           <div class="md-form md-outline">
                        <input type="text" className="form-control browser-default"{...register("SpecialServicesDeclarationName", 
                        {
                          required: true,
                          pattern: { value: /^[A-Z a-z]{1,32}$/i,
                                       message: "Invalid name" }
                      })} />
                      {errors?.SpecialServicesDeclarationName?.message && <span>{errors?.SpecialServicesDeclarationName?.message}</span>}
                      </div>
                           Date
                           <div class="md-form md-outline">
                        <input type="datetime" className="form-control browser-default"{...register("SpecialServicesDeclarationDate", { })} />
                      </div>
                     
                      <input type="submit" className="btn" />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBCol size="2" />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </form>
    </div>
  );
}



export default Form_AdditionalServicesNI;
