import React from "react";
import { MDBContainer, MDBBtn } from "mdbreact";

class LocationNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      jurisdiction: "",
      displayJurisModal: true
    };
  }

  componentDidMount() {
    var getDisplayJurisModal = sessionStorage.getItem('displayJurisModal');    

    if (getDisplayJurisModal == "false") {
      this.setState({
        displayJurisModal: getDisplayJurisModal
      });
    }

    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

  }

  jurisModal = (params) => {
    sessionStorage.setItem('displayJurisModal', false);
    this.setState({
      displayJurisModal: false
    })

    if (params) {
      var juris = this.state.jurisdiction;
      if (juris == "ni") {
        window.location.href = "/roi";
      } else {
        window.location.href = "/ni";
      }
    }
  }

  render() {
    return (
      <header>
        <div className='text-center'>
          {this.state.displayJurisModal == true ? 
            <MDBContainer>
              <div>
                <p>It looks like you are viewing this site from Northern Ireland. Would you like to switch to the NI site?</p>
                <MDBBtn
                  className='btn-location'
                  size='sm'
                  onClick={() => this.jurisModal(true)}
                  >Yes
                </MDBBtn>
                &nbsp;
                {/* No */}
                <MDBBtn
                  className='btn-location'
                  size='sm'
                  onClick={() => this.jurisModal(false)}
                  >No
                </MDBBtn>
              </div>
            </MDBContainer>
          : null }
        </div>
      </header>
    );
  }
}

export default LocationNav;
