export const signinConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  SWITCHACCOUNT_REQUEST: "USERS_SWITCHACCOUNT_REQUEST",
  SWITCHACCOUNT_SUCCESS: "USERS_SWITCHACCOUNT_SUCCESS",
  SWITCHACCOUNT_FAILURE: "USERS_SWITCHACCOUNT_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  RESETPASSWORD_REQUEST: "USERS_RESETPASSWORD_REQUEST",
  RESETPASSWORD_SUCCESS: "USERS_RESETPASSWORD_SUCCESS",
  RESETPASSWORD_FAILURE: "USERS_RESETPASSWORD_FAILURE",

  SETNEWPASSWORD_REQUEST: "USERS_SETNEWPASSWORD_REQUEST",
  SETNEWPASSWORD_SUCCESS: "USERS_SETNEWPASSWORD_SUCCESS",
  SETNEWPASSWORD_FAILURE: "USERS_SETNEWPASSWORD_FAILURE",

  GETUSERBYRESETID_REQUEST: "USERS_GETUSERBYRESETID_REQUEST",
  GETUSERBYRESETID_SUCCESS: "USERS_GETUSERBYRESETID_SUCCESS",
  GETUSERBYRESETID_FAILURE: "USERS_GETUSERBYRESETID_FAILURE"
};
