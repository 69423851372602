import { appPromotionsService } from "../services";
import { appPromotionsConstants } from "../constants";

export const appPromotionsActions = {
  getAppPromotions,
};

// GET
function getAppPromotions(jurisdiction) {
  return (dispatch) => {
    // Request
    dispatch(getAppPromotionsRequest());

    appPromotionsService.getAppPromotionsService(jurisdiction).then((response) => {
      dispatch(getAppPromotionsSuccess(response.data));
    });
  };

  function getAppPromotionsRequest(dbData) {
    return {
      type: appPromotionsConstants.GET_APP_PROMOTIONS_REQUEST,
      payload: dbData,
    };
  }

  function getAppPromotionsSuccess(dbData) {
    return {
      type: appPromotionsConstants.GET_APP_PROMOTIONS_SUCCESS,
      payload: dbData,
    };
  }

  function getAppPromotionsFailure(error) {
    return {
      type: appPromotionsConstants.GET_APP_PROMOTIONS_FAILURE,
      payload: error,
    };
  }
}
