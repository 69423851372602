import { bool } from "yup";
import { keypadServiceConstants } from "../constants";

let defaultState = {
    keypadServiceData: true,
    isLoading: false
};

const keypadServiceReducer = (state = defaultState, action) => {
    switch (action.type) {
        case keypadServiceConstants.GET_KEYPADSERVICE_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case keypadServiceConstants.GET_KEYPADSERVICE_SUCCESS:
            return {
                ...state,
                keypadServiceData: action.payload,
                isLoading: false
            };
        case keypadServiceConstants.GET_KEYPADSERVICE_FAILURE:
            return {
                error: action.payload,
                isLoading: false
            };
        default:
            return state
    }
};

export default keypadServiceReducer;
