import React, { Component } from "react";

import {
  MDBTabPane,
  MDBTabContent,
  MDBNavLink,
  MDBNavItem,
  MDBNav,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBInput,
  MDBAlert,
} from "mdbreact";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import LiveChat from 'react-livechat'
import $ from "jquery";
// import Chat from "../../assets/chat.js";

import { connect } from "react-redux";
import { contactActions } from "../../redux/actions";

class ContactTabs extends Component {
  state = {
    activeItem: "2",
    activeItemName: "email",
    existingCustomer: true,
    existingCustomerRequired: false,
    contactApproval: "",
    jurisdiction: "",

    //form fields
    existingCustomer: "",
    firstName: "",
    surname: "",
    email: "",
    phoneNumber: "",
    accountNumber: "",
    mprn: "",
    message: "",
    consent: "",
  };

  componentDidMount() {
    //get jurisdiction
    var pathArray = window.location.pathname.split('/');
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris
    });

  }

  toggle = (tab, name) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
        activeItemName: name,
      });
    }
  };

  // If Existing customer YES set all fields to required
  existingCustomerRequired = (required) => {
    if (required) {
      this.setState({
        existingCustomerRequired: true,
      });
    } else {
      this.setState({
        existingCustomerRequired: false,
      });
    }
  };

  // Handles Input Changes
  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  // Handle selection fields change
  handleSelectionChange = (input, val) => {
    this.setState({ [input]: val });
  };

  handleCheckboxChange = (input) => (e) => {
    this.setState({ [input]: e.target.checked });
  };

  // Handle Submit
  handleSubmit = (e) => {
    e.preventDefault();
    e.target.className += " was-validated";

    var valid = $("#contact-email")[0].checkValidity();

    console.log("Form valid: " + valid);

    if (valid) {
      const details = {
        existingCustomer: this.state.existingCustomerRequired,
        firstName: this.state.firstName,
        surname: this.state.surname,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        accountNumber: this.state.accountNumber,
        mprn: this.state.mprn,
        message: this.state.message,
        consent: this.state.contactApproval,
      };

      this.props.submitContactForm(details, this.state.jurisdiction);
    }
  };

  // loadDynamicScript = (callback) => {
  //     const existingScript = document.getElementById('8x8');

  //     if (!existingScript) {
  //       const script = document.createElement('script');
  //       script.src = {Chat}; // URL for the third-party library being loaded.
  //       script.id = '8x8'; // e.g., googleMaps or stripe
  //       document.body.appendChild(script);

  //       script.onload = () => {
  //         if (callback) callback();
  //       };
  //     }

  //     if (existingScript && callback) callback();
  //   };

  render() {
    return (
      <div className='contactTabs'>
        <MDBRow>
          <MDBCol md='12' className='pl-0 pr-0'>
            <MDBNav className='nav-tabs mt-5'>
              {/* <MDBNavItem>
                                <MDBNavLink className={this.state.activeItemName == "faq" ? "activeTabPane cocogoose" : "cocogoose"} link to="#" active={this.state.activeItem === "1" && this.state.activeItemName === "faq"} onClick={this.toggle("1", "faq")} role="tab" >
                                    <img src={require('../../images/contact-us/faq-icon-active.png')}></img>
                                    FAQs
                        </MDBNavLink>
                            </MDBNavItem> */}
              <MDBNavItem>
                <MDBNavLink
                  className={
                    this.state.activeItemName == "webchat"
                      ? "activeTabPane cocogoose"
                      : "cocogoose"
                  }
                  link
                  to='#'
                  active={
                    this.state.activeItem === "1" &&
                    this.state.activeItemName === "webchat"
                  }
                  onClick={this.toggle("1", "webchat")}
                  role='tab'
                >
                  <img
                    src={require("../../images/contact-us/webchat-icon.png")}
                  ></img>
                  Webchat
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  className={
                    this.state.activeItemName == "email"
                      ? "activeTabPane cocogoose"
                      : "cocogoose"
                  }
                  link
                  to='#'
                  active={
                    this.state.activeItem === "2" &&
                    this.state.activeItemName === "email"
                  }
                  onClick={this.toggle("2", "email")}
                  role='tab'
                >
                  <img
                    src={require("../../images/contact-us/email-icon.png")}
                  ></img>
                  Email
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  className={
                    this.state.activeItemName == "phone"
                      ? "activeTabPane cocogoose"
                      : "cocogoose"
                  }
                  link
                  to='#'
                  active={
                    this.state.activeItem === "3" &&
                    this.state.activeItemName === "phone"
                  }
                  onClick={this.toggle("3", "phone")}
                  role='tab'
                >
                  <img
                    src={require("../../images/contact-us/phone-icon.png")}
                  ></img>
                  Phone
                </MDBNavLink>
              </MDBNavItem>
            </MDBNav>
          </MDBCol>
        </MDBRow>
        <MDBTabContent activeItem={this.state.activeItem}>
          {/* <MDBTabPane tabId="1" role="tabpanel">
                        <MDBRow className="tabcontent">
                            <MDBCol md="12" className="content p-5" >
                                <p className="mb-5 mediumFont">
                                    We've compiled a list of our most frequently asked questions to help you
                                    with your query. Alternatively, you can speak to one of our energy
                                    specialists via email, phone or webchat.
                                </p>
                                <AccordionItemButton>
                                    <p className="faq-question"><span className="plus-icon ">+</span>I'm moving house - what do I need to do?</p>
                                </AccordionItemButton>
                                <div >
                                    <MDBRow>
                                        <MDBCol md="12" >
                                            <Accordion>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            <p className="faq-question montserrat-Bold"><span className="plus-icon ">+</span> I'm moving house - what do I need to do?</p>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>
                                                            We've compiled a list of our most frequently asked questions to help you
                                                            with your query. Alternatively, you can speak to one of our energy
                                                            specialists via email, phone or webchat.
                                                        </p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            <p className="faq-question montserrat-Bold"><span className="plus-icon ">+</span> I'm moving house - what do I need to do?</p>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>
                                                            In ad velit in ex nostrud dolore cupidatat consectetur
                                                            ea in ut nostrud velit in irure cillum tempor laboris
                                                            sed adipisicing eu esse duis nulla non.
                                                        </p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            </Accordion>



                                        </MDBCol>

                                    </MDBRow>
                                </div>

                            </MDBCol>
                        </MDBRow>
                    </MDBTabPane> */}
          <MDBTabPane tabId='1' role='tabpanel'>
            <MDBRow className='tabcontent'>
              <MDBCol md='12' className='content p-5'>
                <p className='mb-5 mediumFont'>
                  Our energy specialists are happy to help with any queries you
                  might have and are available Monday to Friday (8am-6pm).
                  Simply click to start webchat.
                </p>
                <div data-id="VVUGGBG3WTc" class="livechat_button"><a href="https://www.livechat.com/utm-builder/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_11999496">UTM Builder</a></div>

                {/* 11999496 */}
                <LiveChat license={11999496} />

              </MDBCol>
            </MDBRow>
          </MDBTabPane>
          <MDBTabPane tabId='2' role='tabpanel'>
            <MDBRow className='tabcontent'>
              <MDBCol md='12' className='content contact-email-form'>
                <p className='mediumFont mb-5'>
                  Our energy specialists are fully trained and happy to assist
                  with any queries you have regarding your account.
                  <br /><br />
                  Complete our online form with your query details. We aim to
                  get back to you within 1 working day, however, please allow up
                  to 72 hours for us to process your response. For urgent
                  queries please call us.
                  <br /><br />
                  Our opening hours are:
                  <br /><br />
                  <b>Mon-Fri:</b>  8am-6pm</p>

                <MDBRow>
                  <MDBCol lg='12' md='12'>


                    <form id='contact-email' onSubmit={this.handleSubmit}>
                      <div className='grey-curved-tile text-left contact-page'>
                        <div>
                          <div>
                            <h5>Are you an existing customer?</h5>
                            <div className='radio-container'>
                              {/* Yes */}
                              <input
                                id='existingCustomerYes'
                                name='existingCustomer'
                                type='radio'
                                onClick={() => {
                                  this.handleSelectionChange(
                                    "existingCustomer",
                                    true
                                  );
                                  this.existingCustomerRequired(true);
                                }}
                                checked={
                                  this.state.existingCustomer ? true : false
                                }
                                required
                                className='default-radio'
                              />
                              <label for='existingCustomerYes'>Yes</label>
                              {/* No */}
                              <input
                                id='existingCustomerNo'
                                name='existingCustomer'
                                type='radio'
                                onClick={() => {
                                  this.handleSelectionChange(
                                    "existingCustomer",
                                    false
                                  );
                                  this.existingCustomerRequired(false);
                                }}
                                checked={
                                  this.state.existingCustomer === false
                                    ? true
                                    : false
                                }
                                className='default-radio'
                              />
                              <label htmlFor='existingCustomerNo'>No</label>
                            </div>
                          </div>

                          <MDBRow className='mb-2'>
                            <MDBCol lg='6' md='12' className='text-left'>
                              {/* First Name */}
                              <label className='input-label'>First Name</label>
                              <MDBInput
                                id='firstName'
                                name='firstName'
                                pattern='[A-Za-z]{1,32}'
                                label=''
                                outline
                                onChange={this.changeHandler}
                                required
                              />
                            </MDBCol>
                            <MDBCol lg='6' md='12' className='text-left'>
                              {/* Surname */}
                              <label className='input-label'>Surname</label>
                              <MDBInput
                                id='surname'
                                name='surname'
                                pattern='[A-Za-z]{1,32}'
                                label=''
                                outline
                                onChange={this.changeHandler}
                                required
                              />
                            </MDBCol>
                          </MDBRow>

                          <MDBRow className='mb-2'>
                            <MDBCol lg='6' md='12' className='text-left'>
                              {/* DOB */}
                              <label className='input-label'>Email Address</label>
                              <MDBInput
                                id='email'
                                name='email'
                                label=''
                                outline
                                onChange={this.changeHandler}
                                required
                              />
                            </MDBCol>
                            <MDBCol lg='6' md='12' className='text-left'>
                              {/* Phone Number */}
                              <label className='input-label'>Phone Number</label>
                              <MDBInput
                                id='phoneNumber'
                                name='phoneNumber'
                                pattern='^[0-9]{11,14}$'
                                label=''
                                outline
                                onChange={this.changeHandler}
                                required
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className='mb-2'>
                            <MDBCol lg='6' md='12' className='text-left'>
                              <label className='input-label'>
                                Account Number
                            </label>
                              <MDBInput
                                id='accountNumber'
                                name='accountNumber'
                                pattern='[0-9]*'
                                label=''
                                outline
                                onChange={this.changeHandler}
                                maxLength='20'
                              />
                            </MDBCol>
                            <MDBCol lg='6' md='12' className='text-left'>
                              {/* Phone Number */}
                              <label className='input-label'>MPRN</label>
                              <MDBInput
                                id='mprn'
                                name='mprn'
                                pattern='[0-9]*'
                                label=''
                                outline
                                onChange={this.changeHandler}
                                maxLength='20'
                              />
                            </MDBCol>
                          </MDBRow>

                          {/* Your message */}
                          <MDBRow className='mb-2'>
                            <MDBCol lg='12' className='text-left '>
                              {/* First Name */}
                              <label className='input-label'>Your message</label>
                              <MDBInput
                                type="textarea"
                                id='message'
                                name='message'
                                pattern='[A-Za-z0-9]+'
                                label='Your message here...'
                                outline
                                onChange={this.changeHandler}
                                required
                                className="text-area"
                              />
                            </MDBCol>
                          </MDBRow>

                          {/* contact approval */}
                          <MDBRow className='checkbox-container mt-3'>
                            {/* Marketing Pref Email */}
                            <MDBCol size='12' className=''>
                              <input
                                id='marketingEmail'
                                name='marketingEmail'
                                type='checkbox'
                                required
                                checked={
                                  this.state.contactApproval ? true : false
                                }
                                onChange={this.handleCheckboxChange(
                                  "contactApproval"
                                )}
                              />
                              <label for='marketingEmail' className='ml-2'>
                                By submitting this form you consent to us
                                contacting you in relation your query
                            </label>
                              <p className='smallFont'>
                                For full details on how we use your information,
                              view our <a href={`/${this.state.jurisdiction}/privacystatement`}>Privacy Policy</a>
                              </p>
                              <button type='submit' className='btn btn-bright'>
                                Submit
                            </button>

                            </MDBCol>
                          </MDBRow>
                        </div>
                      </div>
                    </form>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBTabPane>
          <MDBTabPane tabId='3' role='tabpanel'>
            <MDBRow className='tabcontent'>
              <MDBCol md='12' className='content p-5'>
                {/* Bright contact */}
                <p className='mediumFont'>
                  Our energy specialists are happy to speak with you regarding
                  any queries you have.
                </p>

                <MDBRow>
                  <MDBCol lg='5' className='green-highlight p-5 m-2'>
                    <MDBRow>
                      <MDBCol sm="12" md="6" lg="6">
                        {" "}
                        <p>Contact bright</p>
                      </MDBCol>
                      {this.state.jurisdiction == "roi" ?
                        <MDBCol sm="12" md="6" lg="6">
                          <p className='montserrat-Bold'>
                            <a href='tel:01 588 1777'>01 588 1777 </a> {" "}
                          </p>
                        </MDBCol> :
                        <MDBCol sm="12" md="6" lg="6">
                          <p className='montserrat-Bold'>
                            <a href='tel:028 95442290'>028 95442290 </a> {" "}
                          </p>
                        </MDBCol>}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>

                {/* ESB contact */}
                {this.state.jurisdiction == "roi" ?
                  <p className='mediumFont mt-4'>
                    In the event of a power cut you can report it online with ESB
                    networks or by calling them directly.
                </p>

                :

                  <p className='mediumFont mt-4'>
                    In the event of a power cut you can report it online with NIE
                    networks or by calling them directly.
                                </p> }



                  <MDBRow>
                    <MDBCol lg='5' className='blue-highlight p-5 m-2'>

                      {this.state.jurisdiction == "roi" ?
                        <MDBRow>
                          <MDBCol sm="12" md="6" lg="6">
                            {" "}
                            <p>ESB Networks</p>
                          </MDBCol>

                          <MDBCol sm="12" md="6" lg="6">
                            <p className='montserrat-Bold'>
                              <a href='tel:1800 372 999'>1800 372 999</a>
                            </p>
                          </MDBCol>
                        </MDBRow>
                        :
                        <MDBRow>
                          <MDBCol sm="12" md="6" lg="6">
                            {" "}
                            <p>NIE Networks</p>
                          </MDBCol>

                          <MDBCol sm="12" md="6" lg="6">
                            <p className='montserrat-Bold'>
                              <a href='tel:03457 643 643'>03457 643 643</a>
                            </p>
                          </MDBCol> </MDBRow>}


                    </MDBCol>
                  </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBTabPane>
        </MDBTabContent>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    error: store.contact.error,
    contactResponse: store.contact.contactResponse,
  };
};

const actionCreators = {
  submitContactForm: contactActions.sendMail,
};

export default connect(mapStateToProps, actionCreators)(ContactTabs);
