import React, { Component } from "react";
import { MDBCol, MDBRow, MDBBtn, MDBAlert, MDBIcon } from "mdbreact";
import { connect } from "react-redux";
import { switchActions } from "../../../../redux/actions/switchActions";
import Loader from "../../../Shared/Loader";

import $ from "jquery";

export class MeterInformation extends Component {
  state = {
    keypad: false,
    meterType: "24hr",
    ecoMeterType: "",
    isRequired: false,
    errorMessage: false,
    jurisdiction: "ni",
    homeCheck: false,
    eco7Tip: false,
    loading: false,
  };

  // Page load
  componentDidMount() {
    // Clear local storage
    localStorage.clear();

    // Check for referral ID
    let urlParams = new URLSearchParams(window.location.search);
    let referralId = urlParams.get("referralId");

    if (referralId != null) {
      this.setState({ referralId: referralId });
      localStorage.setItem("referralID", referralId);
    }
    // Set page status
    // Quote
    localStorage.setItem("meterUpdated", false);
    // Switch
    localStorage.setItem("accountUpdated", false);
    localStorage.setItem("electricityUpdated", false);
    localStorage.setItem("paymentUpdated", false);
    localStorage.setItem("summaryUpdated", false);
    localStorage.removeItem("switchID");
  }

  // API loader
  async componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle Field Change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  // Handle continue
  nextStep = (e) => {
    // Validate Form
    let validation = $("#meterDetails")[0].checkValidity();

    if (validation) {
      this.setState({ errorMessage: false });

      // Form data
      const data = {
        // Step one (this step) - Meter
        dateSubmitted: new Date().toISOString().slice(0, 19),
        jurisdiction: this.state.jurisdiction,
        keypad: this.state.keypad,
        meterType: this.state.meterType,
        ecoMeterType: this.state.ecoMeterType,
      };

      console.log(data);

      // This Page (updates page status)
      let pageStatus = "meterUpdated";

      // Next Page URL
      let nextPage = "summary";

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Quote Journey
      this.props.createSwitch(data, pageStatus, nextPage, jurisdiction);
    } else {
      this.setState({ errorMessage: true });
    }
  };

  render() {
    return (
      <div className="quote-page">
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        {/* Background Image */}
        <div className="device-image" />
        <form id="meterDetails">
          <MDBCol className="text-center">
            {/* Logo */}
            <MDBRow>
              <MDBCol lg="2" sm="12">
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <img
                    src={require("../../../../images/global/bright-logo-dark.png")}
                    className="quote-logo mx-5 mt-5"
                  />
                </a>
              </MDBCol>
              <MDBCol size="10" />
            </MDBRow>

            {/* Home message */}
            {this.state.homeCheck ? (
              <div className="modalBG">
                <div className="modalBox">
                  <div className="white-curved-tile text-center">
                    <h4>
                      All progress will be lost! <br />
                      Are you sure?
                    </h4>
                    <MDBRow>
                      <MDBCol lg="6" md="6" sm="12">
                        <MDBBtn
                          className="cancel-button"
                          onClick={() =>
                            this.setState({ homeCheck: !this.state.homeCheck })
                          }
                        >
                          Cancel
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol lg="6" md="6" sm="12">
                        <MDBBtn
                          onClick={() =>
                            (window.location.href = `/${this.state.jurisdiction}/`)
                          }
                        >
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Error Message */}
            <MDBRow className="text-center mt-5">
              <MDBCol size="4" />
              <MDBCol lg="4" md="4" sm="12">
                {this.state.errorMessage == true ? (
                  <MDBAlert color="danger">
                    <strong>Oops!</strong> Please check your fields below.
                  </MDBAlert>
                ) : null}
              </MDBCol>
              <MDBCol size="4" />
            </MDBRow>

            {/* Keypad */}
            <MDBRow className="mb-5">
              <MDBCol size="3" />
              <MDBCol lg="6" md="6" sm="10" className="text-center">
                {/* Title */}
                <h3 className="mb-5">
                  Answer two questions and <br /> switch to bright in minutes!
                </h3>

                {/* Question */}
                <h4 className="mb-4">Do you have a keypad meter?</h4>
                {/* Yes */}
                <input
                  onClick={() => this.setState({ keypad: true })}
                  checked={this.state.keypad ? true : false}
                  className="form-check-input"
                  id="keypadYes"
                  name="keypad"
                  required
                  type="radio"
                />
                <label for="keypadYes" className="radioLabel">
                  Yes
                </label>

                {/* No */}
                <input
                  onClick={() => this.setState({ keypad: false })}
                  checked={this.state.keypad === false ? true : false}
                  className="form-check-input"
                  id="keypadNo"
                  name="keypad"
                  type="radio"
                />
                <label for="keypadNo" className="radioLabel">
                  No
                </label>
              </MDBCol>
              <MDBCol size="3" />
            </MDBRow>

            {/* Meter type */}
            <MDBRow className="mb-4">
              <MDBCol size="3" />
              <MDBCol lg="6" md="6" sm="10" className="text-center">
                {/* Question */}
                <h4 className="mb-4 eco7-text">
                  Do you have an Economy 7 meter?
                  <div className="tooltip-container">
                    <MDBIcon
                      icon="info-circle"
                      className="mr-2"
                      onClick={() =>
                        this.setState({
                          eco7Tip: !this.state.eco7Tip,
                        })
                      }
                    />
                  </div>
                </h4>
                {/* Help alert */}
                <div id="helpAlert">
                  {this.state.eco7Tip == true ? (
                    <MDBAlert className="help-alert" dismiss>
                      <MDBRow>
                        <MDBCol size="2" className="align-self-center">
                          <img
                            src={require("../../../../images/global/bullet-large.png")}
                          />
                        </MDBCol>
                        <MDBCol size="10">
                          <p>What is an Economy 7 meter?</p>
                          <p>
                            Economy 7 is a type of electricity tariff that
                            offers you cheaper electricity at night, but a
                            higher rate during the day.
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </MDBAlert>
                  ) : null}
                </div>
                {/* Eco7 */}
                <input
                  onClick={() =>
                    this.setState({ meterType: "Eco7", isRequired: true })
                  }
                  checked={this.state.meterType === "Eco7" ? true : false}
                  className="form-check-input"
                  id="Eco7Yes"
                  name="meterType"
                  required
                  type="radio"
                />
                <label for="Eco7Yes" className="radioLabel">
                  Yes
                </label>

                {/* 24hr */}
                <input
                  onClick={() =>
                    this.setState({ meterType: "24hr", ecoMeterType: "" })
                  }
                  checked={this.state.meterType === "24hr" ? true : false}
                  className="form-check-input"
                  id="24hr"
                  name="meterType"
                  type="radio"
                />
                <label for="24hr" className="radioLabel">
                  No
                </label>
              </MDBCol>
              <MDBCol size="3" />
            </MDBRow>

            {/* Show if meter type = Eco7 */}
            {this.state.meterType == "Eco7" ? (
              <MDBRow>
                <MDBCol size="3" />
                <MDBCol lg="6" md="6" sm="10" className="text-center">
                  {/* Question */}
                  <h4>Which type of economy 7 meter do you have?</h4>
                  <select
                    id="ecoMeterType"
                    className="browser-default custom-select"
                    onChange={this.handleChange("ecoMeterType")}
                    value={this.state.ecoMeterType}
                    required={this.state.isRequired}
                  >
                    <option></option>
                    <option value="Economy7DayNight">
                      Economy 7 Day/Night
                    </option>
                    <option value="Economy7DayNightHeat">
                      Economy 7 Day/Night/Heat
                    </option>
                    {this.state.keypad ? (
                      <option value="Powershift">Powershift</option>
                    ) : null}
                  </select>
                </MDBCol>
                <MDBCol size="3" />
              </MDBRow>
            ) : null}

            {/* Buttons */}
            <MDBRow className="mb-5">
              <MDBCol size="3" />
              <MDBCol lg="6" md="6" sm="10" className="text-center">
                {/* Next Step */}
                <MDBBtn onClick={this.nextStep} className="btn">
                  Continue
                </MDBBtn>
              </MDBCol>
              <MDBCol size="3" />
            </MDBRow>
          </MDBCol>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.switch.isLoading,
  };
};

const actionCreators = {
  createSwitch: switchActions.createNISwitchJourney,
};

export default connect(mapStateToProps, actionCreators)(MeterInformation);
