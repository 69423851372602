import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import LegalTabs from "../../../components/About/LegalTabs";

import prepaymentMeters from "../../../documents/prepaymentMetersNI.pdf";
import pensionableAge from "../../../documents/pensionableAgeNI.pdf";
import energyEfficiency from "../../../documents/energyEfficiencyNI.pdf";
import paymentOfBills from "../../../documents/paymentOfBillsNI.pdf";
import complaints from "../../../documents/complaintsNI.pdf";
import energyChecklist from "../../../documents/energyChecklistNI.pdf";
import ecc from "../../../documents/eccNI.pdf";

import FixedNavDark from "../../../components/Shared/FixedNavDark";
import $ from "jquery";
import EnergyEfficiency from "../../Help/EnergyEfficiency";

class NICodesOfPractice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split('/');
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris
    });

    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.help);
  }

  help =() =>{
    if (window.pageYOffset <50)
    {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }
  
  render() {
    return (
      <div className='default-page home-page'>
        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
              <h1>Policies & Terms</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
        <MDBRow>
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <LegalTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5 pb-5'>
            <h3>Codes of Practice</h3>
            <p className='mt-4'>
              These codes set out our guarantees to provide you with excellent customer service.
            </p>
            <div className='codes links'>
              <a href={prepaymentMeters}>
                <img src={require("../../../images/legals/pdf.png")}></img>
                Provision of services for prepayment meter customers
              </a>
              <a href={pensionableAge}>
                <img src={require("../../../images/legals/pdf.png")}></img>
                Provision of services for persons who are of pensionable age or disabled or chronically sick
              </a>
              <a href={energyEfficiency}>
                <img src={require("../../../images/legals/pdf.png")}></img>
                Efficient use of electricity
              </a>
              <a href={paymentOfBills}>
                <img src={require("../../../images/legals/pdf.png")}></img>
                Payment of bills
              </a>
              <a href={complaints}>
                <img src={require("../../../images/legals/pdf.png")}></img>
                Complaints handling procedure
              </a>
              <a href={energyChecklist}>
                <img src={require("../../../images/legals/pdf.png")}></img>
                Energy sales checklist
              </a>
              <a href={ecc}>
                <img src={require("../../../images/legals/pdf.png")}></img>
                European Consumer Checklist
              </a>
              
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NICodesOfPractice;
