import React, { Component } from "react";
import {
  MDBMask, MDBIcon, MDBView, MDBBtn, MDBCol, MDBContainer,
  MDBRow, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBAnimation,
  MDBJumbotron
} from "mdbreact";
import wind4 from '../../video/wind4.mp4';

class About extends React.Component {
  render() {
    return (
      <div>
        <MDBAnimation reveal type="fadeInRight">
          <MDBRow className="my-5 px-5 pb-5">
            <MDBCol lg="2" md="3" />
            <MDBCol lg="8" md="6" sm="12">
              {/* Page Title */}
              <h2 className="h1-responsive font-weight-bold text-center">
                About Us
            </h2>
              <p className="text-center w-responsive lead mx-auto mt-2">
                We're a team of people with the bright idea of putting clean energy, cool technology and exceptional
                customer service together to give our customers better energy, better value and a better feeling all round
            </p>
            </MDBCol>
            <MDBCol lg="2" md="3" />
          </MDBRow>
        </MDBAnimation>
        
        <MDBAnimation reveal type="fadeInLeft">
          <MDBRow className="renewableImg white-text">
            <MDBCol sm="12">
              {/*<div class="w-100 p-5 text-center">
                <h1 class="mt-5 ml-3">Affordable, Green Energy</h1>
                <h3 class="font-weight-bold ml-3">Thats Better. Thats <span className="black brightGreen-text">Bright.</span></h3>
                <h5 class="font-weight-bold mb-3 ml-3"></h5>
              </div>*/}
            </MDBCol>
          </MDBRow>

        </MDBAnimation>


        {/* Section One */}
        <MDBRow className="mt-5">
          <MDBCol size="1" />
          <MDBCol size="10" className="mb-5">
            <MDBRow className="text-center">
              {/* Analytics */}
              <MDBCol md="4" sm="6">
                <MDBIcon icon="solar-panel" size="3x" className="brightTeal-text" />
                <h5 className="font-weight-bold my-4">What we do</h5>
                <p className="grey-text mb-md-0 mb-5">
                  We harness the power of technology to deliver exceptional customer service and make it easy
                  for people to choose and use clean energy.
                </p>
              </MDBCol>
              {/* Tutorials */}
              <MDBCol md="4" sm="6">
                <MDBIcon icon="glasses" size="3x" className="brightTeal-text" />
                <h5 className="font-weight-bold my-4">Our Vision</h5>
                <p className="grey-text mb-md-0 mb-5">
                  To create Ireland's largest community of clean energy users
                </p>
              </MDBCol>
              {/* Support */}
              <MDBCol md="4">
                <MDBIcon icon="city" size="3x" className="brightTeal-text" />
                <h5 className="font-weight-bold my-4">Our Purpose</h5>
                <p className="grey-text mb-md-0 mb-5">
                  To connect all our customers with 100% clean energy
                </p>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol size="1" />
        </MDBRow>

        {/* Section Two 
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <MDBJumbotron style={{ padding: 0 }}>
                <MDBCol className="text-white py-5 px-4 mt-5" style={{ backgroundImage: 'url(https://mdbootstrap.com/img/Photos/Others/gradient1.jpg)' }}>
                  <MDBCol className="py-5">
                    <div className="text-left">
                      <MDBCardTitle className="h1-responsive pt-3 m-5 font-bold">
                        Our App
                      </MDBCardTitle>
                      <p className="mx-5 mb-5">
                        You’re in control of your energy on the go!
                      </p>
                    </div>
                    <div className="ml-4 pl-3">
                      <a href="#" className="btn btn-md font-weight-bold btn-brightPink">
                        <i class="fas fa-clone mr-2" />
                        Find Out More
                      </a>
                    </div>
                  </MDBCol>
                </MDBCol>
              </MDBJumbotron>
            </MDBCol>
          </MDBRow>
        </MDBContainer>


        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <MDBJumbotron style={{ padding: 0 }}>
                <MDBCol className="text-white py-5 px-4" style={{ backgroundImage: 'url(https://mdbootstrap.com/img/Photos/Others/gradient1.jpg)' }}>
                  <MDBCol className="py-5">
                    <div className="text-right">
                      <MDBCardTitle className="h1-responsive pt-3 m-5 font-bold">
                        Section 3
                      </MDBCardTitle>
                      <p className="mx-5 mb-5">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat fugiat, laboriosam, voluptatem,
                        optio vero odio nam sit officia accusamus minus error nisi architecto nulla ipsum dignissimos. Odit sed qui, dolorum!
                      </p>
                    </div>
                    <div className="text-right mr-4 pr-4">
                      <a href="#" className="btn btn-md font-weight-bold btn-brightPink">
                        <i class="fas fa-clone mr-2" />
                        Find Out More
                      </a>
                    </div>
                  </MDBCol>
                </MDBCol>
              </MDBJumbotron>
            </MDBCol>
          </MDBRow>
        </MDBContainer>*/}
      </div>
    )
  };
}

export default About;
