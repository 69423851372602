import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBAnimation } from "mdbreact";

class MoveIn extends React.Component {
    render() {
        return (
        <div></div>
        )
    };
}

export default MoveIn;