import { additionalServicesConstants } from "../constants";

let defaultState = {
    additionalServicesResponse: "",
    error: "",
    isLoading: false,
};

const additionalServicesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case additionalServicesConstants.ADD_ADDITIONAL_SERVICES_REQUEST:
            return {
                ...state,
                isLoading: true,
                additionalServicesResponse: ""
            };

        case additionalServicesConstants.ADD_ADDITIONAL_SERVICES_SUCCESS:
            return {
                additionalServicesResponse: action.payload,
                isLoading: false,
            };

        case additionalServicesConstants.ADD_ADDITIONAL_SERVICES_FAILURE:
            return {
                ...state,
                error: action.payload,
                additionalServicesResponse: "",
            };

        // Default
        default:
            return state;
    }
};

export default additionalServicesReducer;