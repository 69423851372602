import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FAQTabs from "../../../components/Help/FAQTabs";

import FixedNavDark from "../../../components/Shared/FixedNavDark";
import $ from "jquery";

class NIFAQSComplaints extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark/> */}
        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>FAQs</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow className="pb-5">
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <FAQTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5'>
            <h3>Complaints</h3>

            <div id='makeacomplaint' className='mt-5 mb-5'>
              <h5 className='policy-title'>How do I make a complaint? </h5>
              <p className='policy-content'>
                <b>Our complaints process</b><br />
                At bright we want to get it right first time, every time.  However, sometimes we make mistakes.  So, if you have a complaint or are unhappy with our services we want to know about it.  Our complaints process is free to access and fair for all.  We promise to investigate your complaint fully and work with you to find a resolution.

               <br />  <br />
               To find out more about how we handle complaints see our codes of practice on complaint handling <a href={`/${this.state.jurisdiction}/codesofpractice`}>here.</a>

                <br /><br />
                <b>Making a complaint</b><br />
                    You can make a complaint by
                    <ul>
                  <li>Calling us on our freephone number <a href='tel:08081644790'>0808 164 4790</a> </li>
                  <li>Emailing <a href="mailto:complaints@brightenergy.com">complaints@brightenergy.com</a></li>
                  <li>Write to Bright at:<br />84 -95 Great Patrick Street, Belfast, BT1 2LU </li>
                </ul>

                <br />
                <b>How long will it take to resolve? </b><br />
                We’ll try to resolve any issues as quickly as possible. If you phone us we will aim to deal with the issue there and then.  If that’s not possible or if you contact us by any other method we will fully investigate and aim to get back to you within 5 working days.  But if we can’t we’ll keep in touch to let you know we’re working on your issue.

                    <br /><br />
                <b>Escalating your complaint</b><br />
                If you aren’t satisfied with our response you can ask to have it reviewed by a Team Lead.  The Team Lead will review the complaint, our response, and why you are unhappy with our initial response. The Team Lead will provide you with a resolution within 10 working days and if that’s not possible we’ll be in touch to let you know we’re working on your issue.
                <br /><br />
                    If you remain unhappy you can escalate your complaint to our Head of Customer Services who will fully investigate your complaint and provide you with our final resolution within 10 working days.  If there's any delay we'll be in touch to let you know we're working on your issue.
                  <br />
                  </p>
            </div>

            <div id='cantresolvecomplaint' className='mb-5'>
              <h5 className='policy-title'>What happens if bright can't resolve my complaint? </h5>
              <p className='policy-content'>

                <p>
                If we are unable to resolve your complaint to your satisfaction, or if at any time you are unhappy with our response, you can contact the Consumer Council. This is an independent body which has the power to resolve customer disputes. This service is free of charge and accessible to all. If you deem the solution to be unsatisfactory you retain the right to take the matter to court. 
                 </p>

                  Contact CRU at:
                  <br /><br/>


                <ul className='list-style-none pl-0'>
                  <li><b>The Consumer Council</b></li>
                  <li>Floor 3, Seatem House,  </li>
                  <li>28-32 Alfred Street, </li>
                  <li>Belfast, </li>
                  <li>BT2 8EN </li>
                </ul>
                In certain circumstances where the Consumer Council has been unable to resolve your complaint you may refer your complaint to the Utility Regulator. 
                <br /><br/>
                <ul className='list-style-none pl-0'>
                  <li><b>Contact the Utility Regulator at: </b></li>
                  <li>Queens House </li>
                  <li>Belfast </li>
                  <li>BT1 6ED </li>
                  <li>Call: <a href='tel:028 9031 1575'> </a>028 9031 1575</li>
                </ul>


              </p>


            </div>



          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NIFAQSComplaints;
