import { quoteConstants } from "../constants";

let defaultState = {
  quoteData: [],
  saveQuoteData: [],
  error: "",
  isLoading: false,
};

const quoteReducer = (state = defaultState, action) => {
  switch (action.type) {
    // Create quote journey
    case quoteConstants.CREATE_QUOTE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case quoteConstants.CREATE_QUOTE_SUCCESS:
      return {
        quoteData: action.payload.values,
        isLoading: false,
      };

    case quoteConstants.CREATE_QUOTE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    // Update quote journey
    case quoteConstants.UPDATE_QUOTEJOURNEY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case quoteConstants.UPDATE_QUOTEJOURNEY_SUCCESS:
      return {
        ...state,
        quoteData: action.payload,
        isLoading: false,
      };

    case quoteConstants.UPDATE_QUOTEJOURNEY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    // Get Quote Journey
    case quoteConstants.GET_QUOTEJOURNEY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case quoteConstants.GET_QUOTEJOURNEY_SUCCESS:
      return {
        quoteData: action.payload,
        isLoading: false,
      };
    case quoteConstants.GET_QUOTEJOURNEY_FAILURE:
      return {
        error: action.payload,
      };

    // Save Quote Journey
    case quoteConstants.SAVE_QUOTEJOURNEY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case quoteConstants.SAVE_QUOTEJOURNEY_SUCCESS:
      return {
        saveQuoteData: action.payload,
        isLoading: false,
      };
    case quoteConstants.SAVE_QUOTEJOURNEY_FAILURE:
      return {
        error: action.payload,
      };

    // Default
    default:
      return state;
  }
};

export default quoteReducer;
