import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import ContactTabs from "../../components/About/ContactTabs";
import { CovidBanner } from "../../components/Help/CovidBanner";

class ContactUs extends Component {
  render() {
    return (
      <div className="default-page home-page">
        {/* Hero with background device */}
        <MDBRow className="small-hero">
          <MDBContainer>
            <MDBRow>
              <MDBCol sm="12" md="8" lg="8">
                <h1>Contact us</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <div className="grey-background p-4">
          <CovidBanner />
        </div>

        <div className="grey-background p-5">
          <ContactTabs />
        </div>
      </div>
    );
  }
}

export default ContactUs;
