import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FAQTabs from "../../components/Help/FAQTabs";

import FixedNavDark from "../../components/Shared/FixedNavDark";
import $ from "jquery";

class FAQsSmartMeters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark/> */}

        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>FAQs</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow className="pb-5">
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <FAQTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5'>
            <h3>Smart Meters</h3>

            <div id='' className='mt-5 mb-5'>
              <h5 className='policy-title'>What is a smart meter? </h5>
              <p className='policy-content'>
                Just like your traditional meter, a smart meter measures the electricity you use you use in your home.  However, as they use digital technology, smart meters can send information on your electricity usage to your supplier and the network company.  This means that you won’t have to submit meter readings , or have someone call to read the meter.  Smart meters put you in control by providing more information about how you use electricity, helping you to make informed choices about your energy usage.
              </p>
            </div>

            <div id='' className='mb-5'>
              <h5 className='policy-title'>Why are all meters being replaced? </h5>
              <p className='policy-content'>
                Smart Meters are the next generation electricity meters and are being rolled out to replace the older, mechanical meters. Smart meters will provide insights to consumers on their energy usage and make available new products and services which will benefit consumers, the economy and the environment.

                Consumers will benefit from greater information on their energy usage, allowing them to change their consumption behaviours and choose products which better match their usage.

                Smart meters communicate remotely with suppliers and the network so no need for estimated bills and providing meter readings.

                The aim of smart metering is to encourage energy efficiency and encourages consumers to use electricity at different times of the day, for example at night.  This helps spread the demand for electricity across the whole day, which allows for a more flexible and sustainable electricity system, and less reliance on fossil fueled generation.
              </p>
            </div>

            <div id='' className='mb-5'>
              <h5 className='policy-title'>Who will carry out the replacement work? </h5>
              <p className='policy-content'>
                The meter replacement work will be carried out by ESB Networks or a company working on behalf of ESB Networks.
              </p>
            </div>

            <div id='' className='mb-5'>
              <h5 className='policy-title'>What should I do if I change my mind about switching to bright? </h5>
              <p className='policy-content'>
                When you sign up there is a cooling off period of 14 days in which to change your mind. At time of sign up we will tell you about the cooling off period and when it starts, to cancel your switch simply get in touch with us within this 14 period and we'll cancel your switch.
              </p>
            </div>

            <div id='' className='mb-5'>
              <h5 className='policy-title'>When will the replacement programme be completed? </h5>
              <p className='policy-content'>
                ESB Networks have been replacing meters since autumn 2019 and is intending to replace all meters by 2024.
              </p>
            </div>

            <div id='' className='mb-5'>
              <h5 className='policy-title'>When will I get a smart meter? </h5>
              <p className='policy-content'>
                Work is being carried out on an area by area basis and you will be contacted by ESB Networks when they are coming to your area.
                Work on the roll out has been paused due to the Covid-19 restrictions but will recommence as soon as restrictions are lifted.
              </p>
            </div>

            <div id='' className='mb-5'>
              <h5 className='policy-title'>What benefit will I get from a smart meter? </h5>
              <p className='policy-content'>
                Smart meters provide you will better information about your electricity usage, putting you in control and helping you to become more energy efficient.  There will be additional tariffs available for smart meter customers which will allow you use electricity when it is cheapest.
                Digital technology means that we can access your meter remotely so no need to provide reads and no more estimated bills.
                Smart meters also help you do your bit for the environment, by moving your electricity away from peak time, you can help increase our use of renewable electricity and decrease our reliance on fossil fuels.
              </p>
            </div>

            <div id='' className='mb-5'>
              <h5 className='policy-title'>Will my data be safe? </h5>
              <p className='policy-content'>
                All data collected from the meter is encrypted and will be securely stored in a central Meter Data Management System (MDMS) operated by ESB Networks. ESB Networks has undertaken rigorous testing to ensure that they are compliant with data protection regulations and that all customer data is safe and secure.
              </p>
            </div>

            <div id='' className='mb-5'>
              <h5 className='policy-title'>What data will be recorded on my smart meter? </h5>
              <p className='policy-content'>
                A standard meter is read four times a year and this reading allows us to calculate the amount of energy used between meter reads. This is known as non interval data.
                A smart meter will take a meter reading at intervals of every half hour and store this data, this is known as interval data. By analysing this data you can understand your electricity usage trends, such as the time of day when you use most electricity and from this you can understand where you may be able to make savings.
                In addition your meter will record details of any electricity export onto the grid, events, alerts and instrumentation data related to the power supply at your premises.
              </p>
            </div>

            <div id='' className='mb-5'>
              <h5 className='policy-title'>How will the meter send data? </h5>
              <p className='policy-content'>
              The smart meter will communicate remotely with ESB Networks over a secure 2G network using technology similar to that used to send text messages. Your meter will create the equivalent of an encrypted text message and send it to an IT system called a Head End System (HES). This data is then stored securely in a central Meter Data Management System (MDMS) managed and operated by ESB Networks.
              </p>
            </div>

            <div id='' className='mb-5'>
              <h5 className='policy-title'>What data will be collected electronically from my meter and when? </h5>
              <p className='policy-content'>
              When you sign up to a smart meter tariff with bright you will be able a choose a tariff where we collect your data half hourly, in order to provide you regular information about your consumption patterns.  Or you can choose a tariff where your interval data is collected every two months. 
              </p>
            </div>

            <div id='' className='mb-5'>
              <h5 className='policy-title'>How do I know if I have a smart meter? </h5>
              <p>
              <ul className="line-height">
                <li>An ESB Networks engineer changed your meter and confirmed this in person.</li>
                <li>You will have received a letter from ESB Networks to let you know that your meter will change.</li>
                <li>An ESB Networks engineer left you a brochure.</li>
              </ul></p>
            </div>

            <div id='' className='mb-5'>
              <h5 className='policy-title'>What is a smart meter tariff? </h5>
              <p className='policy-content'>
              Smart meter, or time of use, tariffs encourage customers to use less electricity in peak periods and spread their use of electricity over the day. Time of use tariffs charge more for electricity at peak periods and during the day and less at night. 
              </p>
            </div>

            
              <h5 className='policy-title'>How do you get meter readings from a smart meter? </h5>
              <p className='policy-content'>
              A new digital display screen on your new smart meter will show your current meter reading in kilowatt-hours (kWh). To read the new smart meter, note the figures on the screen before “kWh” from left to right and include all digits.</p>
              <p>
              By pressing the Left-hand button on your meter you can scroll through the following
  
              <ul className="line-height">
                <li>Time and Date</li>
                <li>Night</li>
                <li>Day</li>
                <li>Peak</li>
                </ul></p>
            

            <div id='' className='mb-5'>
              <h5 className='policy-title'>Does a smart meter use my home WIFI? </h5>
              <p className='policy-content'>
              By pressing the Left-hand button on your meter you can scroll through the following
              </p>
            </div>

            <div id='' className='mb-5'>
              <h5 className='policy-title'>Can I change back to a non-smart tariff after I have moved to smart? </h5>
              <p className='policy-content'>
              Once you have selected a smart tariff it is not possible to return to a non smart tariff, however there are a range of smart tariffs available and we can work to find the right tariff for you.  You can have a smart meter installed and remain on your current non-smart tariff, we’ll continue to contact you to advise you of the benefits of smart meters.
              </p>
            </div>




          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default FAQsSmartMeters;
