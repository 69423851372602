import axios from "axios";
import { Debugger } from "fusioncharts";
import { userToken } from "../helpers";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const blogService = {
  getAllService,
  getBlogPostTitleService,
};

// Get All
function getAllService(jurisdiction) {
  return axios({
    method: "get",
    url: `${config.apiUrl}/blogposts/blog/${jurisdiction}`,
    //headers: userToken(),
  }).then((response) => {
    return response;
  });
}

// Get By Title
function getBlogPostTitleService(title, juris) {
  return axios({
    method: "get",
    url: `${config.apiUrl}/blogposts/title/` + juris + `/` + title,
  }).then((response) => {
    return response;
  });
}
