import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBContainer, MDBListGroup, MDBListGroupItem, MDBBadge, MDBIcon, MDBRow, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBAnimation, MDBInput } from "mdbreact";
import Swiper from 'react-id-swiper';

class TrustPilot extends React.Component {
    render() {

        // Trust Pilot Seciton Swiper https://swiperjs.com/api/
        const trustpilotSwiper = {
            slidesPerView: 3,
            spaceBetween: 0,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            speed: 1000,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
            },

            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                },

                768: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },

                1024: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                }
            }


        }

        return (
            <div className="trustpilot-section grey-background">
                <MDBContainer className="large-padding">
                    <MDBRow>
                        <MDBCol sm="12" className="text-center">
                            <h2>The bright community</h2>
                            <p className="mb-4">Don't just take our word for it, see what our Bright Community has to say</p>
                            <img className="trust-pilot-lovely-bunch mt-1" src={require('../../images/home-page/lovely-bunch.png')} />

                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="text-left swiper-slider-container trust-pilot-container">
                        <Swiper {...trustpilotSwiper}>

                            <MDBCol sm="12" md="6" lg="4">
                                <MDBCard>
                                    <MDBCardBody>

                                        <div className="card-content trust-pilot-card">
                                            <div className="star-container">
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                            </div>
                                            <MDBCardTitle>This is good.</MDBCardTitle>
                                            <MDBCardText>
                                                We're on a mission we can all get behind and be part of and we know its!!
                                    </MDBCardText>
                                            <p className="name">- John Doe</p>
                                        </div>

                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>

                            <MDBCol sm="12" md="6" lg="4">
                                <MDBCard>
                                    <MDBCardBody>

                                        <div className="card-content trust-pilot-card">
                                            <div className="star-container">
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                            </div>
                                            <MDBCardTitle>This is amazing.</MDBCardTitle>
                                            <MDBCardText>
                                                We're on a mission we can all get behind and be part of and we know!!
                                    </MDBCardText>
                                            <p className="name">- John Doe</p>
                                        </div>

                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>

                            <MDBCol sm="12" md="6" lg="4">
                                <MDBCard>
                                    <MDBCardBody>

                                        <div className="card-content trust-pilot-card">
                                            <div className="star-container">
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                            </div>
                                            <MDBCardTitle>Very Good Service.</MDBCardTitle>
                                            <MDBCardText>
                                                We're on a mission we can all get behind and be part!!
                                    </MDBCardText>
                                            <p className="name">- John Doe</p>
                                        </div>

                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>

                            <MDBCol sm="12" md="6" lg="4">
                                <MDBCard>
                                    <MDBCardBody>

                                        <div className="card-content trust-pilot-card">
                                            <div className="star-container">
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                                <img src={require('../../images/global/trust-pilot-star.png')} />
                                            </div>
                                            <MDBCardTitle>Would highly reccomend.</MDBCardTitle>
                                            <MDBCardText>
                                                We're on a mission we can all get behind and be part of and we know!!
                                    </MDBCardText>
                                            <p className="name">- John Doe</p>
                                        </div>

                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>


                        </Swiper>
                    </MDBRow>

                    <MDBRow className="trust-pilot-more-container">
                        <MDBCol sm="12" className="text-center">
                            <p>Rated <b>4.4</b> out of 5 based on <b>1,200</b> reviews on  <img src={require('../../images/global/trust-pilot-logo.png')} /></p>
                            <p><b><a href="">Read more customer reviews...</a> </b></p>
                        </MDBCol>
                    </MDBRow>


                </MDBContainer>
            </div>
        );
    }
}

export default TrustPilot;
