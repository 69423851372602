import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBContainer } from "mdbreact";

export const NITempDashboard = () => {
  const [firstName, setFirstname] = useState();

  useEffect(() => {
    let name = JSON.parse(localStorage.getItem("user"));
    name = name.data.authenticateModel.firstName;
    setFirstname(name);
  }, []);

  return (
    <div
      className='default-page home-page'
      style={{ backgroundColor: "#001d28" }}
    >
      {/* Hero with background device */}
      <MDBRow className='small-hero'>
        <MDBContainer>
          <MDBRow>
            <MDBCol sm='12' md='12' lg='12'>
              <h2 className='white-text ml-5'>Hi {firstName},</h2>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBRow>

      <MDBContainer>
        {/* No Account */}
        <MDBRow
          className='justify-content-center text-center white-text'
          center
        >
          <MDBCol size='6'>
            <h3 className='my-3'>
              We're working hard to get your account ready! Check back soon.
            </h3>
            <h3 className='my-5'>In the meantime you can top-up <a className="brightGreen-text " href="/ni/keypadtopup">here</a></h3>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};
