import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FixedNavDark from "../../components/Shared/FixedNavDark";

// Trustpilot Component
import TrustPilot from "../../components/Shared/Trustpilot";
import EAB from "../../components/Shared/EAB";
import $ from "jquery";

const LoadConfig = require("../../config");
const config = LoadConfig.default;

class SmartMeters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener("scroll", this.help);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  };

  render() {
    return (
      <div
        className='default-page switching-to-bright-page'
        onScroll={this.help()}
      >
        {/* <FixedNavDark /> */}

        {/* Quote Block */}
        <div className='quote-section swirl-bg mt-5 pt-5'>
          <MDBRow className='large-padding'>
            <MDBCol sm='12' md='8' lg='6' className='content mt-5 pt-5 pb-5 no-bg'>
              {/* Title */}

              <h2>
                Get <span className='underline'>smart</span> about your electricity
              </h2>
              <div></div>
            </MDBCol>
          </MDBRow>
        </div>




        {/* Switch info block */}
        <MDBContainer className='text-left-image-right-section'>
          <MDBRow>
            <MDBCol className='text-center pt-5' size='10' className='offset-md-1'>
              {/* <h2>
                Get <span className='underline'>smart</span> about your electricity
              </h2> */}
              <p>It’s time to change how you use electricity.  With benefits for consumers, the economy and the environment smart meters make being in control of your energy simple.  Smart meters provide near real time information about the energy you are using. Through an in-home display you can easily track your energy use and the cost of that energy and understand where you may be able to save money on your energy bills. </p>
            </MDBCol></MDBRow>
          <MDBRow>
            <MDBCol sm='12' md='10' lg='10' className='offset-md-1'>
              <MDBRow className='large-padding'>
                <MDBCol sm='12' md='12' lg='6' className='content'>
                  <h3>Why switch to smart meters?</h3>
                  <br /><br />

                  <p>Smart meters are being installed by ESB as part of a nationwide
                  upgrade to homes across Ireland so you can submit your meter readings
                  automatically. Unlike your old meter, you can see how much energy you’re
                  using – so you can make small changes to stop wasting energy, which
                  can help you save money.
                  </p>
                  <a
                    href='/roi/about/quote'
                    className='btn w-80'
                  >
                    Get a quote
                  </a>
                </MDBCol>
                <MDBCol sm='12' md='12' lg='6' className='text-center'>
                  <img src={require("../../images/smartMeterImg.jpg")} />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        <MDBContainer className='text-left-image-right-section pb-5'>
          <MDBRow>
            <MDBCol sm='12' md='6' lg='6' className='text-center'>
              <img src={require("../../images/smartmetermet.png")} />
            </MDBCol>

            <MDBCol sm='12' md='6' lg='6'>
              <MDBRow>
                <MDBCol sm='12' md='12' lg='12'>
                  <h3>How do smart tariffs work?</h3>
                  <br /><br />

                  <p>Smart tariffs are designed to charge more at peak times, for example at dinner time, and less during the night, when there is less demand for electricity.  By changing how and when you use energy you can make real saving.  Find out about our smart tariffs <a href='/roi/about/ourtariff'>here.</a>  We’ve lots of ways to help you change how you use electricity <a href='/roi/blog/post?title=Bright’s-top-tips-for-energy-saving'>here.</a>
                  </p>

                </MDBCol>

              </MDBRow>
            </MDBCol>
          </MDBRow>

        </MDBContainer>

        <div className='grey-background'>
          <MDBContainer className='text-icons-section large-padding'>
            <MDBRow>
              <MDBCol className='text-center pt-5' size='10' className='offset-md-1'>
                <h4>What are the benefits? </h4>
                <p>Say hello to a smarter way of controlling your energy. There are lots of benefits including:</p>

              </MDBCol></MDBRow>
            {/* </MDBContainer>

          <MDBContainer className='text-icons-section large-padding pt-0 '> */}
            <MDBRow>
              <MDBCol
                sm='6'
                md='3'
                lg='3'
                className='text-center icons-container'
              >
                <img
                  src={require("../../images/home-page/Icon__Metrics.png")}
                ></img>
                <h5 className='sm-heading'>
                  No more <br /> estimated bills
                </h5>
                With readings sent to us automatically, there will be no more estimated bills.
              </MDBCol>
              <MDBCol
                sm='6'
                md='3'
                lg='3'
                className='text-center icons-container'
              >
                <img src={require("../../images/home-page/manage-account-icon.png")}></img>
                <h5 className='sm-heading'>
                  Say goodbye to <br />meter readings
                </h5>
                No more submitting meter readings. Your smart meter will do it for you.
              </MDBCol>

              <MDBCol
                sm='6'
                md='3'
                lg='3'
                className='text-center icons-container'
              >
                <img
                  src={require("../../images/home-page/pound.png")}
                ></img>
                <h5 className='sm-heading'>Save money on your<br />energy costs</h5>
                Spot patterns in your electricity usage, and find out where to make savings.
              </MDBCol>

              <MDBCol
                sm='6'
                md='3'
                lg='3'
                className='text-center icons-container'
              >
                <img
                  src={require("../../images/home-page/Icon__World.png")}
                ></img>
                <h5 className='sm-heading'>
                  Better for <br /> the planet
                </h5>
                Reducing demand for electricity at peak times allows for a more flexible and sustainable electricity system, and less reliance on fossil fuelled generation.
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>

        {/* Switch info block */}
        <MDBContainer className='text-left-image-right-section'>

          <MDBRow>
            <MDBCol sm='12' md='10' lg='10' className='offset-md-1'>
              <MDBRow className='large-padding'>
                <div className='text-center w-100 mb-5'>
                  <h3>Make the most out of your smart meter</h3>
                </div>
                <MDBCol sm='12' md='12' lg='6' className=''>
                  <p>
                    <span className='montserrat-Bold'>Look at your essential energy</span><br /> By finding this out, you can see exactly where you can save energy and then reduce the amount of energy used that is not essential to you on a daily basis.
                    <br /><br/>
                    <span className='montserrat-Bold'>Learn how to budget effectively.</span><br /> Now with accurate billing, you’ll know exactly how much energy you’re using each month. You can then use this to set your budget for your energy usage and help to organise your finances.
                    <br /><br/>
                    
                  </p>
                </MDBCol>
                <MDBCol sm='12' md='12' lg='6' className=''>
                  <p>
                  <span className='montserrat-Bold'>Find your most demanding energy source</span><br /> By analysing each appliance’s energy usage, you can make informed decisions on how you can reduce your energy consumption.
                    <br /><br/>
                    <span className='montserrat-Bold'>Buy energy efficient equipment</span><br /> When buying new appliances or equipment, you’ll be able to make more informed decisions due to understanding your current energy usage. You can then choose energy efficient appliances and see the benefit each month on your smart meter monitor. Check out more of bright’s energy saving tips <a href='/roi/blog/post?title=Bright’s-top-tips-for-energy-saving'>here</a>
                    <br />
                  </p>
                  {/* <a
                    href=''
                    className='btn w-80'
                  >
                    Learn more
                  </a> */}
                </MDBCol>
                {/* <MDBCol sm='12' md='12' lg='6' className='text-center'>
                  <img src={require("../../images/smartmeter3.png")} />
                </MDBCol> */}
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        
        {/* <div className='text-background-image-1'>
          <MDBContainer className='text-background-image-section'>
            <MDBRow className=' large-padding'>
              <MDBCol sm='12' md='9' lg='6' className='content'>
                <h2>
                  Jump on and <br /> belong today
                  </h2>
                <p className='montserrat-Bold'>
                  All you need to complete your switch is:
                  </p>
                <ul className='bullet-list'>
                  <li>Your recent bill</li>
                  <li>A meter reading</li>
                  <li>Your bank details</li>
                </ul>
                <a
                  href={`/${this.state.jurisdiction}/quote`}
                  className='btn w-100'
                >
                  Get a quote
                  </a>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div> */}


      </div>
    );
  }
}

export default SmartMeters;
