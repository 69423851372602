import { locationConstants } from "../constants";

let defaultState = {
    locationData: [],
    isLoading: false
};

const locationReducer = ( state = defaultState, action) => {
    switch (action.type) {
        case locationConstants.GET_JURISFROMIP_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case locationConstants.GET_JURISFROMIP_SUCCESS:
            return {
                ...state,
                locationData: action.payload,
                isLoading: false
            };
        case locationConstants.GET_JURISFROMIP_FAILURE:
            return {
                error: action.payload
            };

        // Default
        default:
            return state;
    }
};

export default locationReducer;