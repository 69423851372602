import React, { Component } from "react";
import { MDBCol, MDBRow } from "mdbreact";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

class SwitchOnline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  render() {
    return (
      <MDBRow className='large-padding quote-section white-background onmission-bg'>
        <MDBCol size='1' />
        <MDBCol size='10'>
          <MDBRow className="mission-component">
            <MDBCol sm='12' md='6' lg='6'>
              <h4>Join online today.</h4>
              <p className='mb-5'>
              Join bright today for 100% green electricity and 100% control with our app.
              </p>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm='12' md='8' lg='6' className='no-padding'>
              <a
                href={`/${this.state.jurisdiction}/quote`}
                className='btn w-50'
              >
                Join today
              </a>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size='1' />
      </MDBRow>
    );
  }
}

export default SwitchOnline;
