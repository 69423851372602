import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import { CovidBanner } from "../../components/Help/CovidBanner";
import HelpTiles from "../../components/Help/HelpTiles";
import EnergySavingTips from "../../components/Help/EnergySavingTips";
import AdditionalHelp from "../../components/Help/AdditionalHelp";

class Help extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="default-page help-tiles-bg">
        {/* Page Title Banner */}
        <MDBRow size="12" className="help-header-background">
          <MDBCol size="3" />
          <MDBCol size="6" className="align-self-center text-center">
            <h2 className="white-text">We're here to help </h2>
          </MDBCol>
          <MDBCol size="3" />
        </MDBRow>

        {/* Covid banner */}
        <MDBRow center>
          <MDBCol size="8">
            <CovidBanner />
          </MDBCol>
        </MDBRow>

        {/* Help Tiles and Emergency Contact Component */}
        <HelpTiles />

        {/* Energy Saving Tips Component */}
        <EnergySavingTips />

        {/* Additional Help Component */}
        {/* <AdditionalHelp /> */}
      </div>
    );
  }
}

export default Help;
