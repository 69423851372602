import React, { useEffect, useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import NavigationSection from "../../../components/CustomerAccount/AccountNavigation";
import Loader from "../../../components/Shared/Loader";
import { sessionExpired } from "../../../redux/helpers/sessionExpired";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { keypadActions } from "../../../redux/actions";

export const TopupHistory = () => {
  const dispatch = useDispatch();
  const redKeypad = useSelector((state) => state.keypad);
  const [jurisdiction] = useState("ni");
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [modalDate, setmodalDate] = useState();
  const [modelAmount, setModalAmount] = useState();
  const [modelVendCode, setModalVendCode] = useState();

  useEffect(() => {
    sessionExpired();

    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      dispatch(keypadActions.getAll());
    }
  }, []);

  // Show Overlay
  function showModal(date, amount, vendCode) {
    setDisplayOverlay(true);
    setmodalDate(date);
    setModalAmount(amount);
    setModalVendCode(vendCode);
  }

  return (
    <div className="account-dashboard-container">
      {/* API Loader */}
      {redKeypad.isLoading ? <Loader /> : null}

      <div className="account-details-container">
        <NavigationSection />
        <div className="make-payment-background">
          <MDBContainer className="main-content payment-history">
            {/* Page Title */}
            <MDBRow>
              <MDBCol size="1">
                <a href={`/${jurisdiction}/account/more`}>
                  <i className="fas fa-chevron-left fa-2x" />
                </a>
              </MDBCol>
              <MDBCol size="10" className="text-center ">
                <h1 className="mt-1">Top-up History</h1>
              </MDBCol>
              <MDBCol size="1"></MDBCol>
            </MDBRow>

            {/* Model box */}
            {displayOverlay ? (
              <div className="modalBG">
                <div className="modalBox">
                  <div className="white-curved-tile">
                    <MDBRow className=" moreHR" center>
                      <h4>Top-up Details</h4>
                    </MDBRow>
                    <MDBRow className="mt-1">
                      <ul className="bullet-list">
                        <li>
                          Top-up Date:
                          {" " + modalDate}
                        </li>
                        <li>Top-up amount: {"£" + modelAmount}</li>
                        <li>Your top-up code: {modelVendCode}</li>
                      </ul>
                    </MDBRow>
                    <MDBRow center>
                      <MDBBtn
                        className="cancel-button"
                        onClick={() => setDisplayOverlay(false)}
                      >
                        Close
                      </MDBBtn>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            {redKeypad.keypadData == 0 ? (
              <MDBRow>
                <MDBCol>
                  <div className="white-curved-tile text-center">
                    <h4>
                      Looks like you don't havn't topped-up yet! Check back soon
                      to view your top-up history.
                    </h4>
                  </div>
                </MDBCol>
              </MDBRow>
            ) : (
              <div className="white-curved-tile">
                <MDBRow className="moreHR">
                  <MDBCol size="6" className="">
                    <h5 className="mt-3">Top-up Date</h5>
                  </MDBCol>
                  <MDBCol size="6" className="text-right">
                    <h5 className="mt-3">
                      Amount
                      <i className="fas fa-chevron-right fa-lg pl-5 white-text" />
                    </h5>
                  </MDBCol>
                </MDBRow>

                {redKeypad.keypadData != null
                  ? redKeypad.keypadData.map((topup) => (
                      <MDBRow className="moreHR">
                        <MDBCol size="6" className="">
                          <h5 className="mt-3">
                            {topup.dateSubmitted
                              .slice(0, 10)
                              .split("-")
                              .reverse()
                              .join("/")}
                          </h5>
                        </MDBCol>
                        <MDBCol size="6" className="text-right">
                          <h5 className="mt-3">
                            {"£" + topup.amount}
                            <span
                              className="ml-4 mr-3"
                              onClick={() =>
                                showModal(
                                  topup.dateSubmitted
                                    .slice(0, 10)
                                    .split("-")
                                    .reverse()
                                    .join("/"),
                                  topup.amount,
                                  topup.vendCode
                                    .toString()
                                    .match(/.{1,4}/g)
                                    .join(" ")
                                )
                              }
                            >
                              View
                            </span>
                          </h5>
                        </MDBCol>
                      </MDBRow>
                    ))
                  : null}
              </div>
            )}
          </MDBContainer>
        </div>
      </div>
    </div>
  );
};

export default TopupHistory;
