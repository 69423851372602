import { signinConstants } from "../constants";

let defaultState = {
  dbData: [],
  jfData: [],
  token: "",
  error: "",
  loggedIn: "",
  isLoading: false,
  user: [],
  registerError: "",
};

const signinReducer = (state = defaultState, action) => {
  switch (action.type) {
    // Login
    case signinConstants.LOGIN_REQUEST:
      return {
        ...state,
        dbData: action.payload.authenticateModel,
        jfData: action.payload.jModel,
        loggedIn: false,
        isLoading: true,
      };

    case signinConstants.LOGIN_SUCCESS:
      return {
        ...state,
        dbData: action.payload.authenticateModel,
        jfData: action.payload.jModel,
        token: action.payload.authenticateModel.token,
        loggedIn: true,
        isLoading: false,
      };
    case signinConstants.LOGIN_FAILURE:
      return {
        error: action.payload,
        loggedIn: false,
      };
    // Logout
    case signinConstants.LOGOUT:
      return {
        ...state,
        loggedIn: false,
      };
    //Register
    case signinConstants.REGISTER_REQUEST:
      return {
        ...state,
        dbData: action.payload,
        isLoading: true,
        registerError: "",
      };
    case signinConstants.REGISTER_SUCCESS:
      return {
        ...state,
        dbData: action.payload,
        isLoading: false,
        registerError: "",
      };
    case signinConstants.REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        registerError: action.payload,
      };
    //Reset password request
    case signinConstants.RESETPASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case signinConstants.RESETPASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case signinConstants.RESETPASSWORD_FAILURE:
      return {};
    //Set new password request
    case signinConstants.GETUSERBYRESETID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case signinConstants.GETUSERBYRESETID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case signinConstants.GETUSERBYRESETID_FAILURE:
      return {};

    // Switch Account
    case signinConstants.SWITCHACCOUNT_REQUEST:
      return {
        ...state,
        dbData: action.payload.authenticateModel,
        jfData: action.payload.jModel,
        loggedIn: false,
        isLoading: true,
      };

    case signinConstants.SWITCHACCOUNT_SUCCESS:
      return {
        ...state,
        dbData: action.payload.authenticateModel,
        jfData: action.payload.jModel,
        token: action.payload.authenticateModel.token,
        loggedIn: true,
        isLoading: false,
      };
    case signinConstants.SWITCHACCOUNT_FAILURE:
      return {
        error: action.payload,
        loggedIn: false,
      };

    // Default
    default:
      return state;
  }
};

export default signinReducer;
