import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBInput, MDBRow, MDBAlert, MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import { connect } from "react-redux";
import { quoteActions } from "../../../../redux/actions/quoteActions";
import Loader from "../../../Shared/Loader";
import $ from "jquery";

// Tariff tables
import Urban24hr from "./TariffTables/Urban24hr";
import UrbanNightSaver from "./TariffTables/UrbanNightSaver";
import Rural24hr from "./TariffTables/Rural24hr";
import RuralNightSaver from "./TariffTables/RuralNightSaver";

//popup tariff tables
import Urban24hrPopup from "./PopupTariffTables/Urban24hrPopup";
import UrbanNightSaverPopup from "./PopupTariffTables/UrbanNightSaverPopup";
import Rural24hrPopup from "./PopupTariffTables/Rural24hrPopup";
import RuralNightSaverPopup from "./PopupTariffTables/RuralNightSaverPopup";

const LoadConfig = require("../../../../config");
const config = LoadConfig.default;

export class _FormQuoteSummary extends Component {
  state = {
    quoteID: "",
    email: "",
    jurisdiction: "roi",
    eab: "",
    errorMessage: false,
    hideSaveQuote: true,
    isLoading: false,
    showCalculation: false,
    homeCheck: false,
  };

  // Page load
  componentDidMount() {
    // Check URL for quoteID
    let urlParams = new URLSearchParams(window.location.search);
    let urlQuoteID = urlParams.get("quoteID");
    let saveQuote = urlParams.get("saveQuote");

    // If quoteID exists clear local storage & use new ID
    // Else use quoteID from local storage
    if (urlQuoteID != null) {
      localStorage.removeItem("quoteID");
      localStorage.setItem("quoteID", urlQuoteID);
      this.props.getQuote(urlQuoteID);
      this.setState({
        hideSaveQuote: false,
      });
    } else {
      let quoteID = localStorage.getItem("quoteID");
      this.setState({ quoteID: quoteID, hideSaveQuote: true });
      this.props.getQuote(quoteID);
    }

    if (saveQuote == "1") {
      this.setState({ hideSaveQuote: false });
    } else {
      this.setState({ hideSaveQuote: true });
    }

    // Remove quote page status
    localStorage.removeItem("propertyUpdated");
    localStorage.removeItem("meterUpdated");
    localStorage.removeItem("paymentUpdated");
    localStorage.removeItem("gasUpdated");
    localStorage.removeItem("evUpdated");

    // EAB calculation
    let costOne = 0.15;
    let annualUsage = 4500;
    let eab = costOne * annualUsage;
    this.setState({ eab: eab });
  }

  // Populate form on back
  async componentWillReceiveProps(nextProps) {
    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle Field Change
  handleChange = (input) => (e) => {
    var fieldValue = $("#" + input)[0].checkValidity();

    if (fieldValue) {
      $("#" + input)
        .addClass("input-valid")
        .removeClass("input-error");
    } else {
      $("#" + input)
        .addClass("input-error")
        .removeClass("input-valid");
    }

    this.setState({ [input]: e.target.value });
  };

  saveQuote = (e) => {
    // Validate Form
    let validation = $("#saveQuote")[0].checkValidity();

    if (validation) {
      // Save quote parameters
      let jurisdiction = this.state.jurisdiction;
      let email = this.state.email;
      let id = this.state.quoteID;

      // Hide Error Message (if visiable)
      this.setState({
        errorMessage: false,
      });

      // Save quote
      this.props.saveQuote(jurisdiction, email, id);
    } else {
      // For each form input that has the required prop add the input-error class
      $("form#accountDetails")
        .find("input")
        .each(function () {
          if ($(this).prop("required")) {
            $(this).addClass("input-error");
          }
        });

      // Display the error message
      this.setState({
        errorMessage: true,
      });

      // Scroll to error
      window.location.href = "#errorMessage";
    }
  };

  // Handle tariff table selection
  handleTariffTable = (supplyRegion, meterType) => {
    // Create case for switch
    let tariffTable = supplyRegion + " " + meterType;

    switch (tariffTable) {
      // Urban
      case "Urban 24hr":
        return <Urban24hr />;
        break;
      case "Urban NightSaver":
        return <UrbanNightSaver />;
        break;
      // Rural
      case "Rural 24hr":
        return <Rural24hr />;
        break;
      case "Rural NightSaver":
        return <RuralNightSaver />;
        break;
      // Default
      default:
        break;
    }
  };

  //handle annual calculation
  handleAnnualCalc = (supplyRegion, meterType) => {
    let tariffTable = supplyRegion + " " + meterType;
    let annualCalc = 0;
    switch (tariffTable) {
      // Urban
      case "Urban 24hr":
        annualCalc = (
          config.urb_tfh_unitrate * this.props.quoteData.electricityUsage +
          config.urb_tfh_standing +
          config.urb_tfh_pso
        ).toFixed(0);
        return annualCalc;
        break;
      case "Urban NightSaver":
        annualCalc = (
          config.urb_ns_dayunitrate *
          (this.props.quoteData.electricityUsage / 2) +
          config.urb_ns_nightunitrate *
          (this.props.quoteData.electricityUsage / 2) +
          config.urb_ns_standing +
          config.urb_ns_pso
        ).toFixed(0);
        return annualCalc;
        break;
      // Rural
      case "Rural 24hr":
        annualCalc = (
          config.rur_tfh_unitrate * this.props.quoteData.electricityUsage +
          config.rur_tfh_standing +
          config.rur_tfh_pso
        ).toFixed(0);
        return annualCalc;
        break;
      case "Rural NightSaver":
        annualCalc = (
          config.rur_ns_dayunitrate *
          (this.props.quoteData.electricityUsage / 2) +
          config.rur_ns_nightunitrate *
          (this.props.quoteData.electricityUsage / 2) +
          config.rur_ns_standing +
          config.rur_ns_pso
        ).toFixed(0);
        return annualCalc;
        break;
      // Default
      default:
        break;
    }
  };

  // Handle tariff table selection
  handlePopupTariffTable = (supplyRegion, meterType) => {
    // Create case for switch
    let tariffTable = supplyRegion + " " + meterType;

    switch (tariffTable) {
      // Urban
      case "Urban 24hr":
        return <Urban24hrPopup />;
        break;
      case "Urban NightSaver":
        return <UrbanNightSaverPopup />;
        break;
      // Rural
      case "Rural 24hr":
        return <Rural24hrPopup />;
        break;
      case "Rural NightSaver":
        return <RuralNightSaverPopup />;
        break;
      // Default
      default:
        break;
    }
  };

  render() {
    return (
      <div className='quote-page'>
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        {/* Background Image */}
        <div className='device-image' />
        <MDBCol className='text-center'>
          {/* Logo */}
          <MDBRow>
            <MDBCol lg='2' sm='12'>
              <a onClick={() => this.setState({ homeCheck: true })}>
                <img
                  src={require("../../../../images/global/bright-logo-dark.png")}
                  className='quote-logo mx-5 mt-5'
                />
              </a>
            </MDBCol>
            <MDBCol size='10' />
          </MDBRow>

          {/* Home message */}
          {this.state.homeCheck ? (
            <div className='modalBG'>
              <div className='modalBox'>
                <div className='white-curved-tile text-center'>
                  <h4>
                    All progress will be lost! <br />
                    Are you sure?
                  </h4>
                  <MDBRow>
                    <MDBCol lg='6' md='6' sm='12'>
                      <MDBBtn
                        className='cancel-button'
                        onClick={() =>
                          this.setState({ homeCheck: !this.state.homeCheck })
                        }
                      >
                        Cancel
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol lg='6' md='6' sm='12'>
                      <MDBBtn onClick={() => (window.location.href = "/")}>
                        Continue
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
          ) : null}

          {/* Switch and save box   */}
          <MDBRow className='mt-5 mb-3'>
            <MDBCol size='2' />
            <MDBCol lg='8' md='8' sm='12' className='text-center'>
              <div className='white-curved-tile'>
                <h4 className='mb-3'>
                  By switching to bright today you get our best possible price
                  of{" "}
                  <span className='underline'>
                    €
                    {this.handleAnnualCalc(
                    this.props.quoteData.urbanRural,
                    this.props.quoteData.meterType
                  )}
                    {/* {parseInt(this.props.quoteData.monthlyPayment * 12).toFixed(0)} */}
                  </span>{" "}
                  per year!
                </h4>
                <h4 className='mt-4'>
                  This works out as{" "}
                  <span className='underline'>
                    €{parseInt(this.props.quoteData.monthlyPayment).toFixed(0)}
                  </span>{" "}
                  per month.{" "}
                  <i
                    className='fas fa-info-circle fa-sm brightGreen-text'
                    onClick={() =>
                      this.setState({
                        showCalculation: !this.state.showCalculation,
                      })
                    }
                  />
                </h4>

                {/* Show hide calculation */}
                {this.state.showCalculation ? (
                  <div className='my-5'>
                    <MDBRow>
                      <MDBCol size='2'></MDBCol>
                      <MDBCol sm='12' md='6' lg='8'>
                        {this.handlePopupTariffTable(
                          this.props.quoteData.urbanRural,
                          this.props.quoteData.meterType
                        )}
                      </MDBCol>
                      <MDBCol size='2'></MDBCol>
                    </MDBRow>
                  </div>
                ) : null}

                <h4>Switch online in minutes!</h4>
                <MDBBtn
                  href={`/${this.state.jurisdiction}/switch`}
                  className='btn'
                >
                  Switch now
                </MDBBtn>
                <div><a className="text-underline" href="#smart">Click here to view our smart tariff rates</a></div>
              </div>
              
            </MDBCol>
            <MDBCol size='2' />
          </MDBRow>

          {/* Legal */}
          <MDBRow className='mb-3 py-5'>
            <MDBCol lg='2' md='2' sm='12' />
            <MDBCol lg='8' md='8' sm='12'>
              <p>
                Your annual cost is a personalised estimate of your energy costs
                over the next year.
                <br />
                It’s based on consumption figures you’ve provided (
                {this.props.quoteData.electricityUsage} kWh of electricity) and
                includes VAT at 13.5%.
                <br />
                Prices valid from {config.ValidFrom} and subject to change
              </p>
            </MDBCol>
            <MDBCol lg='2' md='2' sm='12' />
          </MDBRow>

          {/* Marketing */}
          <h3>Reasons to switch to bright</h3>
          <MDBRow className='mb-5'>
            <MDBCol lg='2' md='2' sm='12' />
            <MDBCol lg='8' md='8' sm='12' className='mb-5 mt-3'>
              <MDBRow className='hero-icons-container'>
                <MDBCol
                  sm='6'
                  md='6'
                  lg='3'
                  className='text-left icons-container'
                >
                  <div className='image-container'>
                    <img
                      src={require("../../../../images/home-page/clean-energy-icon.png")}
                    ></img>
                  </div>
                  <h3>
                    100% green electricity supplier
                  </h3>
                </MDBCol>
                <MDBCol
                  sm='6'
                  md='6'
                  lg='3'
                  className='text-left icons-container'
                >
                  <div className='image-container'>
                    <img
                      src={require("../../../../images/home-page/switch.png")}
                    ></img>
                  </div>
                  <h3>We make it easy to switch</h3>
                </MDBCol>
                <MDBCol
                  sm='6'
                  md='6'
                  lg='3'
                  className='text-left icons-container'
                >
                  <div className='image-container'>
                    <img
                      src={require("../../../../images/home-page/transparent-pricing-icon.png")}
                    ></img>
                  </div>
                  <h3>Transparent pricing for all</h3>
                </MDBCol>
                <MDBCol
                  sm='6'
                  md='6'
                  lg='3'
                  className='text-left icons-container'
                >
                  <div className='image-container'>
                    <img
                      src={require("../../../../images/home-page/manage-account-icon.png")}
                    ></img>
                  </div>
                  <h3>Managing your account is simple</h3>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol lg='2' md='2' sm='12' />
          </MDBRow>

          {/*  Tariff table */}
          <MDBCol>
            {this.handleTariffTable(
              this.props.quoteData.urbanRural,
              this.props.quoteData.meterType
            )}
          </MDBCol>

          {/* Deposit */}
          {this.props.quoteData.paymentMethod == "PayOnReceipt" ||
            this.props.quoteData.paymentMethod == "VariableDirectDebit" ? (
            <MDBRow className='mb-5'>
              <MDBCol size='2' />
              <MDBCol lg='8' md='8' sm='12' className='text-center'>
                <div className='white-curved-tile'>
                  <h4>
                    Your tariff selection requires a deposit of{" "}
                    <span className='underline'>€150</span>. We will contact you
                    to arrange payment.
                  </h4>
                </div>
              </MDBCol>
              <MDBCol size='2' />
            </MDBRow>
          ) : null}

          {/* Legal */}
          <MDBRow className='mb-5'>
            <MDBCol lg='2' md='2' sm='12' />
            <MDBCol lg='8' md='8' sm='12'>
              <p>
                The EAB is a simple indicator for customers to help them make
                decisions when comparing tariffs. It calculates an annual bill
                based on typical consumption figures provided by the CRU (4,200
                kWh, split 50/50 for day/night tariffs) and the charges
                associated with the tariff including the unit rate, standing
                charge, PSO levy and VAT. This allows you to compare one tariff
                against another. Please note that your usage will vary from the
                CRU typical consumption figure. This is not an indication of how
                much your electricity will cost, but a useful comparison across
                tariffs.
              </p>
            </MDBCol>
            <MDBCol lg='2' md='2' sm='12' />
          </MDBRow>

          {/* Smart */}
          <MDBRow className='mb-5'>
            <MDBCol size='2' />
            <MDBCol lg='8' md='8' sm='12' className='text-center'>
              <div id="smart" className='white-curved-tile'>
                <h4>Have a smart meter?</h4>
                <p>There are lots of things that make smart meters so smart! Not only will smart meters
                benefit bright customers, they will also be beneficial to the economy and the
                environment. Using digital technology, smart meters will give you more accurate
                information on your energy usage and you will no longer have to submit readings or have
                  someone read the meter. <br/><br/>Our bright smart tariff rates:</p>
                  <MDBTable bordered>
                    <MDBTableHead>
                      <tr className="montserrat-Bold">
                        <th></th>
                        <th>Excl VAT</th>
                        <th>Incl VAT</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      <tr>
                        <td className="montserrat-Bold">
                          Day unit rate (c per kWh)
                        </td>
                        <td>{config.SMDayExVat.toFixed(3)}</td>
                        <td>{config.SMDay.toFixed(3)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Night unit rate (c per kWh)
                        </td>
                        <td>{config.SMNightExVat.toFixed(3)}</td>
                        <td>{config.SMNight.toFixed(3)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Peak unit rate (c per kWh)
                        </td>
                        <td>{config.SMPeakExVat.toFixed(3)}</td>
                        <td>{config.SMPeak.toFixed(3)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Urban standing charge (€ per year)
                        </td>
                        <td>€{config.SMUrbanStandingChargeExVat.toFixed(2)}</td>
                        <td>€{config.SMStandingCharge.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Rural standing charge (€ per year)
                        </td>
                        <td>€{config.SMRuralStandingChargeExVat.toFixed(2)}</td>
                        <td>€{config.SMRuralStandingCharge.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          PSO levy (€ per year)
                        </td>
                        <td>€{config.psoLevyExVat.toFixed(2)}</td>
                        <td>€{config.psoLevy.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Urban EAB (€ per year)
                        </td>
                        <td></td>
                        <td>€{config.SMUrbanEAB}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Rural EAB (€ per year)
                        </td>
                        <td></td>
                        <td>€{config.SMRuralEAB}</td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>

                <a href={`/${this.state.jurisdiction}/switch`}>
                  <MDBBtn className='btn'>Switch Now</MDBBtn>
                </a>

                <div>
                <a className="text-underline" href={`/${this.state.jurisdiction}/about/smartmeters`} target="_blank">
                  Click here to find out more
                  </a>
                </div>
              </div>
            </MDBCol>
            <MDBCol size='2' />
          </MDBRow>

          {/* Featured in */}
          <h3>As seen in:</h3>
          <MDBRow className='mb-5 pb-5'>
            <MDBCol lg='2' md='2' sm='12' />
            <MDBCol lg='8' md='8' sm='12' className='mb-5 mt-3'>
              <MDBRow className='hero-icons-container featured-in'>
                <MDBCol
                  sm='6'
                  md='6'
                  lg='3'
                  className='text-center feat-icons-container'
                >
                  <img
                    className='rte'
                    src={require("../../../../images/partners/rte.png")}
                  ></img>
                </MDBCol>
                <MDBCol
                  sm='6'
                  md='6'
                  lg='3'
                  className='text-center feat-icons-container'
                >
                  <img
                    className='w-80'
                    src={require("../../../../images/partners/independent.png")}
                  ></img>
                </MDBCol>
                <MDBCol
                  sm='6'
                  md='6'
                  lg='3'
                  className='text-center feat-icons-container'
                >
                  <img
                    className='w-80'
                    src={require("../../../../images/partners/sundaytimes.png")}
                  ></img>
                </MDBCol>
                <MDBCol
                  sm='6'
                  md='6'
                  lg='3'
                  className='text-center feat-icons-container'
                >
                  <img
                    className='w-80'
                    src={require("../../../../images/partners/irishtechnewscolor.jpg")}
                  ></img>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol lg='2' md='2' sm='12' />
          </MDBRow>

          {/* Switch Now */}
          <MDBRow className='mb-5'>
            <MDBCol size='2' />
            <MDBCol lg='8' md='8' sm='12' className='text-center'>
              <div className='white-curved-tile'>
                <h4>Start your switch now!</h4>
                <p>It only takes 2 minutes</p>
                <a href={`/${this.state.jurisdiction}/switch`}>
                  <MDBBtn className='btn'>Switch now</MDBBtn>
                </a>
              </div>
            </MDBCol>
            <MDBCol size='2' />
          </MDBRow>
        </MDBCol>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    quoteData: store.quote.quoteData,
    isLoading: store.quote.isLoading,
  };
};

const actionCreators = {
  getQuote: quoteActions.getQuoteJourney,
  saveQuote: quoteActions.saveQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(_FormQuoteSummary);
