import axios from "axios";
//import { userToken } from "../helpers";

const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const quoteService = {
  createQuote,
  updateQuote,
  getQuote,
  saveQuoteService,
};

// Create new quote
function createQuote(details) {
  return axios({
    method: "post",
    url: `${config.apiUrl}/quotejourneys/create`,
    data: details,
  }).then((response) => {
    //store id in local storage?
    return response;
  });
}

// Edit existing quote
function updateQuote(id, data) {
  return axios({
    method: "put",
    url: `${config.apiUrl}/quotejourneys/` + id,
    data: data,
  }).then((response) => {
    //if (email is true and response.status == 200)
    //mail and redirect
    return response;
  });
}

// Get quote by ID
function getQuote(id) {
  return axios({
    method: "get",
    url: `${config.apiUrl}/quotejourneys/` + id,
  }).then((response) => {
    return response;
  });
}

// Save quote
function saveQuoteService(jurisdiction, email, id) {
  return axios({
    method: "put",
    url:
      `${config.apiUrl}/quotejourneys/savequote/` +
      jurisdiction +
      "/" +
      email +
      "/" +
      id,
  }).then((response) => {
    return response;
  });
}
