import axios from "axios";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const keypadVendService = {
    getKeypadVendService
};

function getKeypadVendService(){
    return axios({
        method: "get",
        url: `${config.apiUrl}/keypadvend/getServiceStatus`
    }).then(response => { 
        return response; 
    });
}