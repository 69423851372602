import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBContainer,
  MDBAlert,
} from "mdbreact";
import $ from "jquery";
import LiveChat from "react-livechat";
import Loader from "../../../Shared/Loader";

// Redux
import { connect } from "react-redux";
import { switchActions } from "../../../../redux/actions/switchActions";
import { quoteActions } from "../../../../redux/actions/quoteActions";
import { Debugger } from "fusioncharts";

export class YourElectricity extends Component {
  state = {
    // Your Electricity
    newConnection: false,
    meterReading: "",
    nightMeterReading: "",
    kpn: "",
    annualUsage: "",
    loading: false,
    // Usage
    electricityUsage: "",
    electricityGuidance: "",
    electricityUsageSelect: "",
    guidanceRequired: false,
    showUsageInput: false,
    showUsageGuidance: false,

    // Show/hide sections
    showError: false,
    knowUsage: false,
    errorMessage: false,
    meterReadingTip: false,
    useageTip: false,
    switchGot: false,
    jurisdiction: "ni",
    homeCheck: false,
    newConnectionTip: false,
    keypad: false,

    // For nightsaver meter
    showDayMeterLabel: false,
    showNightSaver: false,
  };

  // Page Load
  componentDidMount() {
    let switchID = localStorage.getItem("switchID");

    if (switchID != null) {
      this.props.getSwitch(switchID);
      this.setState({ switchGot: true });
    }

    let keypad = localStorage.getItem("keypad");
    this.setState({ keypad: keypad });
  }

  // If Props.SwitchData true populate form
  async componentWillReceiveProps(nextProps) {
    let sectionUpdated = JSON.parse(localStorage.getItem("electricityUpdated"));

    if (this.state.switchGot && sectionUpdated) {
      await this.setState({
        newConnection: nextProps.journeyData.newConnection,
        meterReading: nextProps.journeyData.meterReading,
        kpn: nextProps.journeyData.kpn,
        annualUsage: nextProps.journeyData.annualUsage,
        electricityUsageSelect: nextProps.journeyData.consumptionOption,
        electricityUsage: nextProps.journeyData.annualUsage,
        nightMeterReading: nextProps.journeyData.nightMeterReading,
      });

      // If usage = actual
      if (nextProps.journeyData.consumptionOption == "Actual") {
        this.setState({ showUsageInput: true });
      } else {
        this.setState({ showUsageInput: false });
      }

      // If page status = true and consumptionOption = guidance -> call populateGuidance
      if (nextProps.journeyData.consumptionOption === "Guidance") {
        this.populateGuidance(nextProps.journeyData.annualUsage);
      }
    }
    localStorage.setItem("ddAmount", nextProps.journeyData.ddAmount);

    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Populate guidance on back
  populateGuidance = (usage) => {
    this.setState({
      showUsageGuidance: true,
    });

    switch (usage) {
      case 2400:
        this.setState({
          electricityGuidance: "lowUsage",
        });

      case 4200:
        this.setState({
          electricityGuidance: "mediumUsage",
        });
        break;

      case 7100:
        this.setState({
          electricityGuidance: "highUsage",
        });
        break;

      default:
        break;
    }
  };

  // Handle Field Change
  handleChange = (input) => (e) => {
    var fieldValue = $("#" + input)[0].checkValidity();

    if (fieldValue) {
      $("#" + input)
        .addClass("input-valid")
        .removeClass("input-error");
    } else {
      $("#" + input)
        .addClass("input-error")
        .removeClass("input-valid");
    }

    this.setState({ [input]: e.target.value });
  };

  // Handle nightMeterReading Field Change
  // handleNightMeterReadingChange = (input) => (e) => {   
  //   var size = "5";
  //   var s = e.target.value;
  //   while (s.length < size) {
  //     s = "0" + s;
  //   }
  //   while (s.length > size) {
  //     s = s.slice(1);
  //   }
  //   this.setState({ [input]: s });

  //   var fieldValue = $("#" + input)[0].checkValidity();

  //   if (fieldValue) {
  //     $("#" + input)
  //       .addClass("input-valid")
  //       .removeClass("input-error");
  //   } else {
  //     $("#" + input)
  //       .addClass("input-error")
  //       .removeClass("input-valid");
  //   }

  // };

  // Continue to next section
  nextStep = (e) => {
    // Prevent from from clearing
    e.preventDefault();

    // Validate Form
    let validation = $("#yourElectricity")[0].checkValidity();

    if (validation) {
      // Form Data
      const data = {
        // Step one - (Account details)
        accountHolder: this.props.journeyData.accountHolder,
        title: this.props.journeyData.title,
        firstName: this.props.journeyData.firstName,
        surname: this.props.journeyData.surname,
        dob: new Date(this.props.journeyData.dob).toISOString().slice(0, 19),
        email: this.props.journeyData.email,
        phone: this.props.journeyData.phone,
        postcode: this.props.journeyData.postcode,
        addrLine1: this.props.journeyData.addrLine1,
        addrLine2: this.props.journeyData.addrLine2,
        city: this.props.journeyData.city,
        county: this.props.journeyData.county,
        mprn: this.props.journeyData.mprn,
        // Account correspondance
        accountCorrespondence: this.props.journeyData.accountCorrespondence,
        accCorrTitle: this.props.journeyData.accCorrTitle,
        accCorrFirstName: this.props.journeyData.accCorrFirstName,
        accCorrSurname: this.props.journeyData.accCorrSurname,
        accCorrName: this.props.journeyData.accCorrName,
        accCorrPostcode: this.props.journeyData.accCorrPostcode,
        accCorrAddLineOne: this.props.journeyData.accCorrAddLineOne,
        accCorrAddLineTwo: this.props.journeyData.accCorrAddLineTwo,
        accCorrCity: this.props.journeyData.accCorrCity,
        accCorrCounty: this.props.journeyData.accCorrCounty,
        accCorrEmail: this.props.journeyData.accCorrEmail,
        accCorrAddPhone: this.props.journeyData.accCorrAddPhone,
        // Joint account
        jointPerson: this.props.journeyData.jointPerson,
        jointTitle: this.props.journeyData.jointTitle,
        jointFirstName: this.props.journeyData.jointFirstName,
        jointSurname: this.props.journeyData.jointSurname,
        jointEmail: this.props.journeyData.jointEmail,
        jointPhone: this.props.journeyData.jointPhone,
        // Speical services
        serviceCheck: this.props.journeyData.serviceCheck,
        specialServices: this.props.journeyData.specialServices,
        requiredSpecialServices: this.props.journeyData.requiredSpecialServices,
        medicalServices: this.props.journeyData.medicalServices,
        requiredMedicalServices: this.props.journeyData.requiredMedicalServices,
        priorityRegister: this.props.journeyData.priorityRegister,
        //Nom person
        nomPerson: this.props.journeyData.nomPerson,
        nomCorrespondance: this.props.journeyData.nomCorrespondance,
        nomTitle: this.props.journeyData.nomTitle,
        nomFirstName: this.props.journeyData.nomFirstName,
        nomSurname: this.props.journeyData.nomSurname,
        nomEmail: this.props.journeyData.nomEmail,
        nomPhone: this.props.journeyData.nomPhone,
        nomPostcode: this.props.journeyData.nomPostcode,
        nomAddrLine1: this.props.journeyData.nomAddrLine1,
        nomAddrLine2: this.props.journeyData.nomAddrLine2,
        nomCity: this.props.journeyData.nomCity,
        nomCounty: this.props.journeyData.nomCounty,
        // Marketing
        markEmail: this.props.journeyData.markEmail,
        markSMS: this.props.journeyData.markSMS,
        markPost: this.props.journeyData.markPost,
        markPhone: this.props.journeyData.markPost,

        // Step Two (This Page) - Your Electricity
        newConnection: this.state.newConnection,
        meterReading: this.state.meterReading,
        nightMeterReading: this.state.nightMeterReading,
        annualUsage: parseInt(this.state.electricityUsage),
        consumptionOption: this.state.electricityUsageSelect,

        // Step three - Payment details
        paymentMethod: this.props.journeyData.paymentMethod,
        accountName: this.props.journeyData.accountName,
        bankAccountNo: this.props.journeyData.bankAccountNo,
        bankSortCode: this.props.journeyData.bankSortCode,
        billingMethod: this.props.journeyData.billingMethod,
        iban: this.props.journeyData.iban,
        DDAmount: this.props.journeyData.DDAmount,
        DDDayOfMonth: this.props.journeyData.DDDayOfMonth,
        bankAddressLineOne: this.props.journeyData.bankAddressLineOne,
        bankAddressLineTwo: this.props.journeyData.bankAddressLineTwo,
        bankAddressPostcode: this.props.journeyData.bankAddressPostcode,
        bankAddressCountry: this.props.journeyData.bankAddressCountry,
        confirmDDAuth: this.props.journeyData.confirmDDAuth,

        // Step four - Switch summary
        agreeToTerms: this.props.journeyData.agreeToTerms,
        agreeToPrivacyPolicy: this.props.journeyData.agreeToPrivacyPolicy,
        switchCompleted: false,
      };

      // Hide Error Message (if visiable)
      this.setState({
        errorMessage: false,
      });

      // SwitchID
      let switchID = localStorage.getItem("switchID");

      // This Page (updates page status)
      let pageStatus = "electricityUpdated";

      // Next Page URL
      let nextPage = "";
      if (this.state.keypad) {
        nextPage = "summary";
      } else {
        nextPage = "payment";
      }

      // Payment method - needs passed to take deposit for vdd
      let paymentMethod = "";

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Database Entry
      this.props.updateSwitch(
        switchID,
        data,
        pageStatus,
        nextPage,
        paymentMethod,
        jurisdiction
      );
    } else {
      // For each form input that has the required prop add the input-error class
      $("form#yourElectricity")
        .find("input")
        .each(function () {
          if ($(this).prop("required")) {
            $(this).addClass("input-error");
          }
        });

      // Display the error message
      this.setState({
        errorMessage: true,
      });

      // Scroll to error
      window.location.href = "#errorMessage";
    }
  };

  // Usage Select
  handleUsageChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });

    console.log(e.target.value);

    switch (e.target.value) {
      case "Option":
        this.setState({
          showUsageGuidance: false,
          showUsageInput: false,
          guidanceRequired: false,
          electricityUsage: "",
        });
        break;

      case "Guidance":
        this.setState({
          showUsageGuidance: true,
          showUsageInput: false,
          guidanceRequired: true,
          electricityUsage: "",
        });
        break;

      case "Actual":
        this.setState({
          showUsageInput: true,
          showUsageGuidance: false,
          guidanceRequired: false,
          electricityUsage: "",
        });
        break;

      default:
        this.setState({
          showUsageGuidance: false,
          showUsageInput: false,
          guidanceRequired: false,
          electricityUsage: "",
        });
        break;
    }
  };

  // Handle guidance select
  handleUsageGuidance = (value) => {
    switch (value) {
      case "lowUsage":
        this.setState({
          electricityUsage: 2400,
          electricityGuidance: "lowUsage",
        });
        break;

      case "mediumUsage":
        this.setState({
          electricityUsage: 4200,
          electricityGuidance: "mediumUsage",
        });
        break;

      case "highUsage":
        this.setState({
          electricityUsage: 7100,
          electricityGuidance: "highUsage",
        });
        break;

      default:
        break;
    }
  };

  render() {
    return (
      <div className='quote-page switch-page'>
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        <form id='yourElectricity'>
          {/* Background Image */}
          <div className='device-image' />
          {/* Logo */}
          <MDBRow className='text-center'>
            <MDBCol lg='2' sm='12'>
              <a onClick={() => this.setState({ homeCheck: true })}>
                <img
                  src={require("../../../../images/global/bright-logo-dark.png")}
                  className='quote-logo mx-5 mt-5'
                />
              </a>
            </MDBCol>
            <MDBCol size='10' />
          </MDBRow>

          {/* Home message */}
          {this.state.homeCheck ? (
            <div className='modalBG'>
              <div className='modalBox'>
                <div className='white-curved-tile text-center'>
                  <h4 className='mt-5'>
                    All progress will be lost! <br />
                    Are you sure?
                  </h4>
                  <MDBRow>
                    <MDBCol lg='6' md='6' sm='12'>
                      <MDBBtn
                        className='cancel-button'
                        onClick={() =>
                          this.setState({ homeCheck: !this.state.homeCheck })
                        }
                      >
                        Cancel
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol lg='6' md='6' sm='12'>
                      <MDBBtn
                        onClick={() =>
                          (window.location.href = `/${this.state.jurisdiction}/`)
                        }
                      >
                        Continue
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
          ) : null}

          <MDBContainer className='personal-details-section'>
            <MDBRow>
              <MDBCol size='12'>
                <MDBRow>
                  {/* Progress Bar */}
                  <MDBCol md='12' className='switch-navigation-container'>
                    <div className='border-light'>
                      {!this.state.keypad ? (
                        <img
                          className='brand-image show-for-medium'
                          src={require("../../../../images/NI Switch/ThreeStepElectricity.png")}
                        />
                      ) : (
                        <img
                          className='brand-image show-for-medium'
                          src={require("../../../../images/NI Switch/TwoStepElectricity.png")}
                        />
                      )}
                    </div>
                  </MDBCol>

                  {/* Error Message */}
                  <MDBCol size='12' id='errorMessage'>
                    {this.state.errorMessage == true ? (
                      <MDBAlert color='danger' className='mb-5' dismiss>
                        <strong>Oops!</strong> Please check your fields below.
                      </MDBAlert>
                    ) : null}
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md='12' lg='8'>
                    <div className='text-left white-curved-tile'>
                      <h4 className='mt-4'>Your Electricity</h4>
                      {/* New Connection */}
                      <label className='input-label'>
                        Is this a new connection at a property which hasn't
                        previously had an electricity supply?
                        <MDBBtn className='tooltip-container'>
                          <MDBIcon
                            icon='info-circle'
                            className='mr-2'
                            onClick={() =>
                              this.setState({
                                newConnectionTip: !this.state.newConnectionTip,
                              })
                            }
                          />
                        </MDBBtn>
                      </label>
                      {/* Help */}
                      <div id='helpAlert'>
                        {this.state.newConnectionTip == true ? (
                          <MDBAlert className='help-alert' dismiss>
                            <MDBRow>
                              <MDBCol size='2' className='align-self-center'>
                                <img
                                  src={require("../../../../images/global/bullet-large.png")}
                                />
                              </MDBCol>
                              <MDBCol size='10'>
                                <p>
                                  Only select this if your property is a new
                                  build or has never had an electricity supply
                                  before. If you are not sure please give us a
                                  call on: <br />
                                  <p className='brightGreen-text'>
                                    028 95442290
                                  </p>
                                </p>
                              </MDBCol>
                            </MDBRow>
                          </MDBAlert>
                        ) : null}
                      </div>

                      <div className='radio-container'>
                        {/* Yes */}
                        <input
                          id='newConnectionYes'
                          name='newConnection'
                          type='radio'
                          onClick={() => this.setState({ newConnection: true })}
                          className='default-radio'
                          required
                          checked={this.state.newConnection ? true : false}
                        />
                        <label for='newConnectionYes'>Yes</label>
                        {/* No */}
                        <input
                          id='newConnectionNo'
                          name='newConnection'
                          type='radio'
                          onClick={() =>
                            this.setState({ newConnection: false })
                          }
                          className='default-radio'
                          checked={
                            this.state.newConnection === false ? true : false
                          }
                        />
                        <label for='newConnectionNo'>No</label>
                      </div>

                      {/* Meter reading */}
                      {!this.state.newConnection ? (
                        <div>
                          <MDBRow className='mb-2'>
                            <MDBCol lg='12' className='text-left p-0'>
                              <label className='input-label'>
                                If you have your current meter reading enter it
                                here
                                <MDBBtn className='tooltip-container'>
                                  <MDBIcon
                                    icon='info-circle'
                                    className='mr-2'
                                    onClick={() =>
                                      this.setState({
                                        meterReadingTip: !this.state
                                          .meterReadingTip,
                                      })
                                    }
                                  />
                                </MDBBtn>
                              </label>

                              {/* Help */}
                              <div id='helpAlert'>
                                {(this.state.meterReadingTip == true) ==
                                true ? (
                                  <MDBAlert className='help-alert' dismiss>
                                    <MDBRow>
                                      <MDBCol
                                        size='2'
                                        className='align-self-center'
                                      >
                                        <img
                                          src={require("../../../../images/global/bullet-large.png")}
                                        />
                                      </MDBCol>
                                      <MDBCol size='10'>
                                        <p>
                                          Need help reading you meter? Check out
                                          our helpful meter reading guide{" "}
                                          <a
                                            href='/roi/faqs/meterreadings#submitreading'
                                            className='brightGreen-text'
                                            target='_blank'
                                          >
                                            here
                                          </a>
                                        </p>
                                      </MDBCol>
                                    </MDBRow>
                                  </MDBAlert>
                                ) : null}
                              </div>

                              {/* Meter Reading */}
                              <MDBInput
                                label='Meter Reading'
                                type='text'
                                pattern='^[_0-9]{5}'
                                id='meterReading'
                                name='meterReading'
                                className='form-control'
                                outline
                                onChange={this.handleChange("meterReading")}
                                value={this.state.meterReading}
                              />
                            </MDBCol>
                          </MDBRow>

                          <div>
                            {this.props.journeyData != null &&
                            this.props.journeyData.meterType == "Eco7" ? (
                              <MDBRow className='mb-2'>
                                <MDBCol lg='12' className='text-left p-0'>
                                  <label className='input-label'>
                                    Tell us your current NIGHT meter reading
                                  </label>

                                  {/* Meter Reading */}
                                  <MDBInput
                                    label='Meter Reading'
                                    type='text'
                                    pattern='^[_0-9]{5}'
                                    id='nightMeterReading'
                                    name='nightMeterReading'
                                    className='form-control'
                                    outline
                                    onChange={this.handleChange(
                                      "nightMeterReading"
                                    )}
                                    required
                                    value={this.state.nightMeterReading}
                                  />
                                </MDBCol>
                              </MDBRow>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* Usage */}
                    <div className='text-left white-curved-tile'>
                      <MDBRow>
                        <MDBCol>
                          <h4>How much electricity do you currently use?</h4>
                          <select
                            id='electricityUsageSelect'
                            className='browser-default custom-select'
                            onChange={this.handleUsageChange(
                              "electricityUsageSelect"
                            )}
                            value={this.state.electricityUsageSelect}
                            required
                          >
                            <option value='Option'>
                              Please select an option
                            </option>
                            <option value='Actual'>
                              I know how much I consume (kWh)
                            </option>
                            <option value='Guidance'>
                              Not sure - use guidance
                            </option>
                          </select>

                          {this.state.showUsageInput ? (
                            <div>
                              <label for='electricityUsage'>
                                <h4>Forecast annual electricity usage (kWh)</h4>
                              </label>
                              <MDBInput
                                outline
                                type='text'
                                id='electricityUsage'
                                value={this.state.electricityUsage}
                                onChange={this.handleChange("electricityUsage")}
                                pattern='^[1-9][0-9]*$'
                              />
                            </div>
                          ) : null}
                        </MDBCol>
                      </MDBRow>

                      {this.state.showUsageGuidance ? (
                        <MDBRow className='mt-3 mb-5'>
                          <MDBCol>
                            {/* Title */}
                            <h4 className='mb-4'>Electricity usage guidance</h4>
                            <MDBRow>
                              <MDBCol
                                sm='4'
                                md='4'
                                lg='4'
                                className='paymentOption'
                              >
                                <input
                                  onClick={() =>
                                    this.handleUsageGuidance("lowUsage")
                                  }
                                  checked={
                                    this.state.electricityGuidance ===
                                    "lowUsage"
                                      ? true
                                      : false
                                  }
                                  className='low-usage-check'
                                  id='lowUsage'
                                  name='electricityGuidance'
                                  required={this.state.guidanceRequired}
                                  type='radio'
                                />
                                <label for='lowUsage' className='usageLabel'>
                                  <h5>Low</h5>
                                  <ul className='list-unstyled ml-3 lead'>
                                    <li>1-2 bedrooms</li>
                                    <li>Quiet daytime</li>
                                    <li>Newer (1970+)</li>
                                  </ul>
                                  <p>Approximate usage:</p>
                                  <p>
                                    <b>2400 kWh</b>
                                  </p>
                                </label>
                              </MDBCol>

                              <MDBCol
                                sm='4'
                                md='4'
                                lg='4'
                                className='paymentOption'
                              >
                                <input
                                  onClick={() =>
                                    this.handleUsageGuidance("mediumUsage")
                                  }
                                  checked={
                                    this.state.electricityGuidance ===
                                    "mediumUsage"
                                      ? true
                                      : false
                                  }
                                  className='medium-usage-check'
                                  id='mediumUsage'
                                  name='electricityGuidance'
                                  required={this.state.guidanceRequired}
                                  type='radio'
                                />
                                <label for='mediumUsage' className='usageLabel'>
                                  <h5>Medium</h5>
                                  <ul className='list-unstyled ml-3 lead'>
                                    <li>3 bedrooms</li>
                                    <li>3-5 occupants</li>
                                    <li>Busy daytime</li>
                                  </ul>
                                  <p className='font-weight-bold'>
                                    Approximate usage:
                                  </p>
                                  <p>
                                    <b>4200 kWh</b>
                                  </p>
                                </label>
                              </MDBCol>

                              <MDBCol
                                sm='4'
                                md='4'
                                lg='4'
                                className='paymentOption'
                              >
                                <input
                                  onClick={() =>
                                    this.handleUsageGuidance("highUsage")
                                  }
                                  checked={
                                    this.state.electricityGuidance ===
                                    "highUsage"
                                      ? true
                                      : false
                                  }
                                  className='high-usage-check'
                                  id='highUsage'
                                  name='electricityGuidance'
                                  required={this.state.guidanceRequired}
                                  type='radio'
                                />
                                <label for='highUsage' className='usageLabel'>
                                  <h5>High</h5>
                                  <ul className='list-unstyled ml-3 lead'>
                                    <li>4+ bedrooms</li>
                                    <li>Older (pre 1970s)</li>
                                    <li>Busy all times</li>
                                  </ul>
                                  <p>Approximate usage:</p>
                                  <p>
                                    <b>7100 kWh</b>
                                  </p>
                                </label>
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                      ) : null}
                    </div>

                    {/* Buttons */}
                    <MDBRow size='12'>
                      {/* Back */}
                      <MDBCol size='6'>
                        <h5>
                          Previous Step: <span>Account Details</span>
                        </h5>

                        <a href={`/${this.state.jurisdiction}/switch`}>
                          <MDBBtn className='btn switch-button'>Back</MDBBtn>
                        </a>
                      </MDBCol>
                      {/* Next (if keypad - summary else - payment) */}
                      <MDBCol size='6'>
                        {this.state.keypad ? (
                          <h5>
                            Next Step: <span>Switch Summary</span>
                          </h5>
                        ) : (
                          <h5>
                            Next Step: <span>Payment Details</span>
                          </h5>
                        )}
                        <MDBBtn className='btn' onClick={this.nextStep}>
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol size='4' className='show-for-large'>
                    <img
                      className='brand-image'
                      src={require("../../../../images/NI Switch/GreenElectricity.png")}
                    />

                    {/* Chat functionality */}
                    <div className='white-curved-tile text-left mt-3'>
                      <h5 className='mt-4 text-center'>Need some help?</h5>
                      {/* Live chat */}
                      <div className='switch-live-chat'>
                        <div data-id='VVUGGBG3WTc' class='livechat_button'>
                          <a href='https://www.livechat.com/utm-builder/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_11999496'>
                            UTM Builder
                          </a>
                        </div>
                        <LiveChat license={11999496} />
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBCol size='2' />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.switch.isLoading,
    journeyData: store.switch.switchData,
  };
};

const actionCreators = {
  getSwitch: switchActions.getSwitchJourney,
  updateSwitch: switchActions.updateSwitchJourney,
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(YourElectricity);
