import { blogConstants } from "../constants";

let defaultState = {
  postData: [],
  isLoading: false,
};

const blogReducer = (state = defaultState, action) => {
  switch (action.type) {
    // Get All Posts
    case blogConstants.GET_ALL_BLOG_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case blogConstants.GET_ALL_BLOG_SUCCESS:
      return {
        postData: action.payload,
      };
    case blogConstants.GET_ALL_BLOG_FAILURE:
      return {};

    // Get Post By Title
    case blogConstants.GET_BY_TITLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case blogConstants.GET_BY_TITLE_SUCCESS:
      return {
        postData: action.payload,
        isLoading: false,
      };
    case blogConstants.GET_BY_TITLE_FAILURE:
      return {};

    // Create Post
    case blogConstants.CREATE_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case blogConstants.CREATE_POST_SUCCESS:
      return {
        postData: action.payload,
      };
    case blogConstants.CREATE_POST_FAILURE:
      return {};

    // Edit Post
    case blogConstants.EDIT_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case blogConstants.EDIT_POST_SUCCESS:
      return {
        ...state,
      };
    case blogConstants.EDIT_POST_FAILURE:
      return {};

    // Delete Post
    case blogConstants.DELETE_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case blogConstants.DELETE_POST_SUCCESS:
      return {
        ...state,
      };
    case blogConstants.DELETE_POST_FAILURE:
      return {};

    default:
      return state;
  }
};

export default blogReducer;
