import React from "react";
import {
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBContainer,
} from "mdbreact";

class NITariffComparison extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='grey-background'>
        <MDBContainer>
          <MDBRow className='grey-background py-4'>
            <MDBCol size='2' />
            <MDBCol size='8'>
              <MDBTable>
                <MDBTableBody id='tariffComparison'>
                  <tr>
                    <td />
                    <td colspan='2'>
                      Unit Rate <br /> (pence per kWh)
                    </td>
                    <td colspan='2'>
                      Standing Charge <br /> (pence per day)
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td>Excl VAT</td>
                    <td>Incl VAT</td>
                    <td>Excl VAT</td>
                    <td>Incl VAT</td>
                  </tr>
                  <tr>
                    <td>Bright</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Budget energy</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Click energy</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Electric Ireland</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Power NI</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>SSE Airtricity</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </MDBCol>
            <MDBCol size='2' />
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

export default NITariffComparison;
