import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import LegalTabs from "../../components/About/LegalTabs";

import $ from "jquery";
import MeterReadPrizeDraw from "../../documents/MeterReadPrizeDraw.pdf";
import terms from "../../documents/terms.pdf";
import MaxolIncentiveTsCs from "../../documents/MaxolIncentiveTsCs.pdf";


class TermsAndConditions extends Component {
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark /> */}

        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>Policies & Terms</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow>
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <LegalTabs />
          </MDBCol>

          <MDBCol id='standard' lg='8' md='7' sm='12' className='pt-5 pl-5 pb-5'>
            <h3>Terms & Conditions</h3>
            <div className="policy-links py-4">
              <a className="montserrat-Bold" href="#standard">Standard Ts&Cs</a>
              <a className="montserrat-Bold" href="#referafriend">Refer a friend Ts&Cs</a>
            
            </div>

            <hr />
            <div className='codes links pt-4'>

                <a href={terms}>
                  <img src={require("../../images/legals/pdf.png")}></img>
                Bright Terms & Conditions
              </a>

              <br />

              <a href={MeterReadPrizeDraw}>
                  <img src={require("../../images/legals/pdf.png")}></img>
                Meter Read Prize Terms & Conditions
              </a>

              <br />

              <a href={MaxolIncentiveTsCs}>
                  <img src={require("../../images/legals/pdf.png")}></img>
                Maxol Incentive Terms and Conditions
              </a>


              <hr /><br />

              <div id="referafriend" className="pt-5">
                <p className="montserrat-Bold">Refer a Friend Terms & Conditions</p>
                <p>Terms and Conditions – Refer a Friend
                  <br />
                  The following are the Terms and Conditions of our ‘Refer a Friend’ programme.
                  </p>
                <p className="montserrat-Bold">1. Definitions and interpretation </p>
                <ul className="list-style-none">
                  <li>1.1. Unless otherwise specified, the defined terms used in these ‘Refer a Friend’ Terms and Conditions shall have the same meanings given to them in our Standard Terms and Conditions for Supply for Residential Customers, which are accessible from our website www.brightenergy.com or in hard copy by contacting our energy specialists. </li>
                  <li>1.2. In these Terms and Conditions:
                      <br />
                      “Bright”, “Company”, “we”, “us”, “our” means Ever Energy Supply Ltd Limited registered in Dublin, Ireland with company Number 640097 and registered address at 3 Custom House Plaza, IFSC, Dublin 1, D01 VY76, trading as Bright;
                      <br />
                      “Customer” means any person who has entered into an Agreement with Bright for the supply of electricity;
                      <br />
                      “Programme” means Bright’s ‘Refer a Friend’ referrals programme;
                      <br />
                      “Referrer”, “you”, “your” means an existing Customer of Bright who is eligible to participate in the Programme;
                      <br />
                      “Recruit” means the new customer being referred;
                      <br />
                      “Reward” means the reward amount of €25 per Recruit offered to the Referrer by us in accordance with these Terms and Conditions;
                      <br />
                      “Sign Up Date” means the date on which the Recruit informs Bright, via enrolment on our website, that they want to switch their energy supply to Bright;
                      <br />
                      “Switch Confirmation Date” means the date on which the Recruit receives their first bill from Bright, having successfully switched their energy supply to Bright;
                      <br />
                      “Unique Link” means a URL unique to each Referrer. </li>
                  <li>1.3. The headings used in these Terms and Conditions are for convenience only and will not affect their interpretation. </li>
                  <li>1.4. Reference to a person includes that party’s personal representatives, successors and permitted assigns (where applicable). </li>
                  <li>1.5. Unless otherwise specified, the singular includes the plural and the plural includes the singular. </li>
                </ul>

                <p className="montserrat-Bold">2. Programme eligibility</p>
                <ul className="list-style-none">
                  <li>2.1. The Programme is open to Referrers and Recruits who are over 18 and are residents of the Republic of Ireland. The Referrer and the Recruit must not live at the same address or have the same email address. </li>
                  <li>2.2. Referrers must be existing Bright Customers who have completed their switch, are taking their energy supply from Bright and have set up an online account with us.    </li>
                  <li>2.3. A Recruit must not, under any email address or alias, have already signed up with Bright, or be an existing Customer of Bright, or have been a Customer of Bright in the preceding 12 months. In addition, a Recruit must not be resident at a Supply Address that is already receiving its electricity supply from Bright.   </li>
                  <li>2.4. The Programme will be valid from 1 July 2020 until such time as it is withdrawn by Bright. Bright will provide 30 days’ notice of withdrawal of the Programme.  </li>
                  <li>2.5. The Recruit must use the Referrer's Unique Link to switch energy supply to Bright on our website. Referrals claimed retrospectively are not eligible and will not be rewarded. Bright is not responsible for failure to issue Rewards resulting from an incorrectly quoted Unique Link. </li>
                  <li>2.6. A Referrer will not receive more than one Reward per Supply Address for which electricity supply switches to Bright as the result of a referral. </li>
                  <li>2.7. If the Recruit cancels their Agreement with Bright before the Switch Confirmation Date, the Referrer will not be eligible to receive a Reward. </li>
                  <li>2.8. The Referrer will be notified by email once a Recruit has signed up, both on the Sign Up Date, and again on the Switch Confirmation Date. </li>
                  <li>2.9. The maximum number of Rewards payable to an individual Referrer is five. The Referrer may provide the Unique Link to more than five potential Recruits but will only be rewarded for the first five Recruits that switch to Bright. </li>
                  <li>2.10. A Customer is eligible for the Programme on signing up to Bright for the first time only. If you leave Bright and re-join us at a later date, you will not be eligible to participate in the Programme again. </li>
                  <li>2.11. Once the Recruit has completed their switch, they will be eligible to act as a Referrer. </li>
                </ul>

                <p className="montserrat-Bold">3. Programme delivery and Rewards</p>
                <ul className="list-style-none">
                  <li>3.1. Subject to these Terms and Conditions, the Referrer will become eligible to receive one Reward on the Switch Confirmation Date of a Recruit. The Reward will be credited to the Referrer within 14 days of the Switch Confirmation Date.  </li>
                  <li>3.2. Rewards are provided as credit to the Referrer’s account.  There is no cash alternative.  </li>
                  <li>3.3. You are allowed to redeem a maximum of one Reward (€25) per month against your Electricity Charges.  </li>
                  <li>3.4. If you have an electricity credit meter, any Rewards will be delivered to you as credit into your account.  </li>
                  <li>3.5. Rewards are not transferable and may not be auctioned, traded, bartered or sold.  </li>
                  <li>3.6. If your Electricity Charges for a given month are less than the Reward amount credited to you, the remainder of the Reward amount will be carried over and used towards the next month’s payment.  If your Electricity Charges for a given month are more than the Reward amount, the remainder of the costs will be charged via your chosen payment method. </li>
                  <li>3.7. If you leave Bright for any reason and still have an unused Reward amount credited to you, then, subject to clause 3.3, this will be deducted from any outstanding payments. There is no cash alternative where unredeemed Reward amounts exceed your outstanding payments and any such Reward amounts will be forfeited.   </li>
                  <li>3.8. Upon termination of the Programme or any portion thereof for any reason, or upon cancellation of a Referrer’s account with Bright for any reason, the Referrer’s right to refer Recruits using the Unique Link and to receive future Rewards shall be forfeited.  </li>
                  <li>3.9. Bright reserves the right to: (a) verify whether the Referrer and the Recruit qualify for the Programme; and (b) reject any application from a potential Recruit that appears to contain materially incorrect information or to be fraudulent.   </li>
                  <li>3.10. Any applications to Bright from Recruits that are found to have been made fraudulently will not be eligible for a Reward and may be referred to the appropriate authorities for further investigation. </li>
                  <li>3.11. Bright shall not be required to keep or preserve the original documentation pertaining to or arising out of any agreement (including the Agreement itself) but may keep copies of the same by using any electronic method of storing information and a legible copy or a recorded electronic audio file (as appropriate) of any such information so preserved shall be considered a true copy of it. Bright shall carry out any such storage in compliance with all applicable data protection laws. </li>
                </ul>
              </div>


            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default TermsAndConditions;
