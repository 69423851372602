import axios from "axios";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const agencyService = {
  getPropertyProductRef,
  addNewProperty,
  tenantMoveIn,
  tenantMoveOut
};

function getPropertyProductRef(propertyId, jurisdiction) {
  return axios({
    method: "get",
    url: `${config.apiUrl}/crm/getagencyproductref/${propertyId}/${jurisdiction}`,
  }).then((response) => {
    return response;
  });
}

function addNewProperty(details) {
  return axios({
    method: "post",
    url: `${config.apiUrl}/agency/addnewproperty`,
    data: details,
  }).then((response) => {
    return response;
  });
}

function tenantMoveIn(details) {
  return axios({
    method: "post",
    url: `${config.apiUrl}/agency/tenantmovein`,
    data: details,
  }).then((response) => {
    return response;
  });
}

function tenantMoveOut(details) {
  return axios({
    method: "post",
    url: `${config.apiUrl}/agency/tenantmoveout`,
    data: details,
  }).then((response) => {
    return response;
  });
}
