export const customerAccountConstants = {
  //get all bills
  GET_BILLS_REQUEST: "GET_BILLS_REQUEST",
  GET_BILLS_SUCCESS: "GET_BILLS_SUCCESS",
  GET_BILLS_FAILURE: "GET_BILLS_FAILURE",

  //get bill by id
  GET_BILLBYID_REQUEST: "GET_BILLBYID_REQUEST",
  GET_BILLBYID_SUCCESS: "GET_BILLBYID_SUCCESS",
  GET_BILLBYID_FAILURE: "GET_BILLBYID_FAILURE",
};
