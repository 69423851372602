import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import Loader from "../../../components/Shared/Loader";
import FixedNavDark from "../../../components/Shared/FixedNavDark";

// Redux
import { connect } from "react-redux";

import LoginForm from "./LoginForm";
import $ from "jquery";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener("scroll", this.help);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  };

  async componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  render() {
    return (
      <div className='login-page'>
        {/* <FixedNavDark/> */}
        <MDBContainer className='switch-today-container'>
          <MDBRow className='brightTeal-text'>
            <MDBCol md='3' lg='3'></MDBCol>
            <MDBCol sm='12' md='6' lg='6'>
              <div>
                <h1>Login</h1>
                {this.state.loading ? <Loader /> : null}
                <LoginForm />
              </div>
            </MDBCol>
            <MDBCol size='3'></MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size='4' />
            <MDBCol size='4' className='text-center'>
              <h5>
                <a href={`/${this.state.jurisdiction}/quote`}>
                  Join bright today!
                </a>
              </h5>
              <p>
                Already a customer?{" "}
                <a
                  href={`/${this.state.jurisdiction}/account/register`}
                  className='brightGreen-text'
                >
                  Register now
                </a>
              </p>
            </MDBCol>
            <MDBCol size='4' />
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.signin.isLoading,
  };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Login);
