import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import FixedNavDark from "../../components/Shared/FixedNavDark";

const Page404 = ({ location }) => (
  <div className='page404'>
    <FixedNavDark />
    <div>
      <MDBRow className='brightTeal-text'>
        <MDBCol md='3' lg='3'></MDBCol>
        <MDBCol sm='12' md='6' lg='6'>
          <div className='white-curved-tile'>
            <h1 className='text-center'>404...</h1>
            <br />
            <h2 className='text-center'>Sorry that page cannot be found!</h2>
            <br />
            <div className='text-center'>
              <a href='/'>Click here to return to the home page</a>
            </div>
          </div>
        </MDBCol>
        <MDBCol size='3'></MDBCol>
      </MDBRow>
    </div>
  </div>
);

export default Page404;
