export const quoteConstants = {
  // Create
  CREATE_QUOTE_REQUEST: "CREATE_QUOTE_REQUEST",
  CREATE_QUOTE_SUCCESS: "CREATE_QUOTE_SUCCESS",
  CREATE_QUOTE_FAILURE: "CREATE_QUOTE_FAILURE",

  // UPDATE
  UPDATE_QUOTEJOURNEY_REQUEST: "UPDATE_QUOTEJOURNEY_REQUEST",
  UPDATE_QUOTEJOURNEY_SUCCESS: "UPDATE_QUOTEJOURNEY_SUCCESS",
  UPDATE_QUOTEJOURNEY_FAILURE: "UPDATE_QUOTEJOURNEY_FAILURE",

  // GET
  GET_QUOTEJOURNEY_REQUEST: "GET_QUOTEJOURNEY_REQUEST",
  GET_QUOTEJOURNEY_SUCCESS: "GET_QUOTEJOURNEY_SUCCESS",
  GET_QUOTEJOURNEY_FAILURE: "GET_QUOTEJOURNEY_FAILURE",

  // Save quote
  SAVE_QUOTEJOURNEY_REQUEST: "SAVE_QUOTEJOURNEY_REQUEST",
  SAVE_QUOTEJOURNEY_SUCCESS: "SAVE_QUOTEJOURNEY_SUCCESS",
  SAVE_QUOTEJOURNEY_FAILURE: "SAVE_QUOTEJOURNEY_FAILURE",
};
