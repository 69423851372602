import React, { Component, useState } from "react";
import { MDBBtn, MDBCol, MDBContainer, MDBListGroup, MDBListGroupItem, MDBBadge, MDBIcon, MDBRow, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBAnimation, MDBInput, MDBTable, } from "mdbreact";
import Swiper from 'react-id-swiper';
const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const NISubstantiation = () => {
  const [showTable, setShowTable] = useState(false);

  return (
    <div className="grey-background py-5">
      <MDBContainer>
        <MDBRow className="grey-background py-4 eab-saving ni-substantiation" id="eab-save">
          {/* <MDBCol size="1"></MDBCol> */}
          <MDBCol size="12">
            <p className="mb-4">
              *Bright offers the cheapest standard variable tariff in Northern Ireland compared to standard 24 hour variable tariffs for other domestic suppliers.
              Bright future tariff valid from 26th June 2021. Competitor prices displayed as of 6th July 2021 and subject to change.
              Click <a href="/ni/about/ourtariff">here</a> to view tariff detail for bright, and details of competitors tariffs can be found on their relevant websites. <a href="/ni/terms">Terms and conditions apply</a>.
              <a className="montserrat-Bold" onClick={() => setShowTable(!showTable)}> View Details</a>
            </p>

            {showTable ? (
              <MDBRow>
                <MDBCol className='content p-5'>
                <MDBTable bordered className="ni-subs">
                    <thead><tr><th>Standard Credit</th><th colSpan="2">Unit rate</th><th colSpan="2">Standing Charge (pence per day)</th></tr></thead><tbody>
                      <tr><td>&nbsp;</td><td colSpan="2">(pence per kWh)</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                      <tr><td></td><td>Excl VAT</td><td>Incl VAT</td><td>Excl VAT</td><td>Incl VAT</td></tr>
                      <tr><td>bright</td><td>15.81</td><td>16.60</td><td>9.78</td><td>10.27</td></tr>
                      <tr><td>Budget</td><td>20.30</td><td>21.32</td><td>9.11</td><td>9.56</td></tr>
                      <tr><td>Click</td><td>17.13</td><td>17.99</td><td>8.47</td><td>8.90</td></tr>
                      <tr><td>Electric Ireland</td><td>17.37</td><td>18.24</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                      <tr><td>Power NI</td><td>18.16</td><td>19.07</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                      <tr><td>SSE</td><td>18.74</td><td>19.68</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                    </tbody></MDBTable>


                </MDBCol>
                <MDBCol className='content p-5'>
                <MDBTable bordered className="ni-subs">
                    <tr><th>Keypad Meter</th><th colSpan="2">Unit rate</th><th colSpan="2">Standing Charge (pence per day)</th></tr>
                    <tbody>
                      <tr><td>&nbsp;</td><td colSpan="2">(pence per kWh)</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                      <tr><td>&nbsp;</td><td>Excl VAT</td><td>Incl VAT</td><td>Excl VAT</td><td>Incl VAT</td></tr>
                      <tr><td>bright</td><td>15.92</td><td>16.72</td><td>13.62</td><td>14.30</td></tr>
                      <tr><td>Budget</td><td>19.90</td><td>20.89</td><td>11.41</td><td>11.98</td></tr>
                      <tr><td>Click</td><td>17.13</td><td>17.99</td><td>10.61</td><td>11.14</td></tr>
                      <tr><td>Electric Ireland</td><td>17.37</td><td>18.24</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                      <tr><td>Power NI</td><td>17.71</td><td>18.60</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                      <tr><td>SSE</td><td>18.74</td><td>19.68</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                    </tbody>
                  </MDBTable>
                </MDBCol>
              </MDBRow>
            ) : null}
          </MDBCol>
          {/* <MDBCol size="1"></MDBCol> */}

        </MDBRow>
      </MDBContainer>
    </div>
  );
}

