import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { connect } from "react-redux";
import { signinActions } from "../../redux/actions/signinActions";
import NavigationSection from "../../components/CustomerAccount/AccountNavigation";
import { sessionExpired } from "../../redux/helpers/sessionExpired";
import {Link} from "react-router-dom";

class MoreSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
      logoutModal: false,
      showMultiProp: false,
      accountType: true,
      prepay: false,
    };
  }

  componentDidMount() {
    sessionExpired();

    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    this.setState({
      jurisdiction: jurisdiction,
    });

    // Get user data
    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;
    let properties = user.data.properties;

    if (properties.length > 1) {
      this.setState({
        showMultiProp: true,
      });
    } else {
      this.setState({
        showMultiProp: false,
      });
    }

    try {
      this.setState({
        accountType: jModel.electricityProduct.electricity.directDebit,
        prepay: jModel.electricityProduct.electricity.prepay,
      });
    } catch {}
  }

  // Handle logout
  logout = () => {
    this.props.logout(this.state.jurisdiction);
  };

  render() {
    return (
      <div className="account-dashboard-container">
        <div className="account-details-container">
          <NavigationSection />
          <div>
            {/* Logout modal */}
            {this.state.logoutModal ? (
              <div className="modalBG">
                <div className="modalBox">
                  <div className="white-curved-tile text-center">
                    <h4>Are you sure you want to log out?</h4>
                    <MDBRow>
                      <MDBCol lg="6" md="12" sm="12">
                        <MDBBtn
                          className="cancel-button"
                          onClick={() =>
                            this.setState({
                              logoutModal: !this.state.logoutModal,
                            })
                          }
                        >
                          Cancel
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol lg="6" md="12" sm="12">
                        <MDBBtn onClick={() => this.logout()}>Log Out</MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="make-payment-background">
              <MDBContainer className="main-content electricity-usage">
                {/* Page Title */}
                <MDBCol md="12" className="text-center">
                  <h1>More</h1>
                </MDBCol>

                <div className="white-curved-tile">
                  {/* Payment */}
                  {/* {!this.state.accountType ? ( */}
                    <span>
                      {!this.state.prepay ? (
                        <MDBRow className="moreHR mt-4 mb-4">
                          <MDBCol size="12" className="text-left">
                            <Link
                              to={{
                                pathname: `/${this.state.jurisdiction}/account/payment`,
                                state: { jur: this.state.jurisdiction }
                              }}
                            >
                              <img
                                className="ca-more-img"
                                src={require("../../images/account/paymentsIcon.png")}
                              />
                              Make a payment
                            </Link>
                          </MDBCol>
                        </MDBRow>
                      ) : null}
                    </span>
                  {/* ) : null} */}

                  {this.state.showMultiProp ? (
                    <MDBRow className="moreHR mt-4 mb-4">
                      <MDBCol size="12" className="text-left">
                        <a
                          href={`/${this.state.jurisdiction}/account/switchaccount`}
                        >
                          <img
                            className="ca-more-img"
                            src={require("../../images/account/homeIcon.png")}
                          />
                          Manage Another Account
                        </a>
                      </MDBCol>
                    </MDBRow>
                  ) : null}

                  {/* Energy Usage */}
                  <MDBRow className="moreHR mt-4 mb-4">
                    <MDBCol size="12" className="text-left">
                      <a
                        href={`/${this.state.jurisdiction}/account/energyusage`}
                      >
                        <img
                          className="ca-more-img"
                          src={require("../../images/account/energyUsage.png")}
                        />
                        Energy Usage
                      </a>
                    </MDBCol>
                  </MDBRow>

                  {/* Tariff */}
                  <MDBRow className="moreHR mt-4 mb-4">
                    <MDBCol size="12" className="text-left">
                      <a
                        href={`/${this.state.jurisdiction}/account/yourtariff`}
                      >
                        <img
                          className="ca-more-img"
                          src={require("../../images/account/yourTariff.png")}
                        />
                        Your Tariff
                      </a>
                    </MDBCol>
                  </MDBRow>

                  {/* Top-up history and Bills & statements */}
                  {this.state.prepay ? (
                    <MDBRow className="moreHR mt-4 mb-4">
                      <MDBCol size="12" className="text-left">
                        <a href={`/ni/account/topuphistory`}>
                          <img
                            className="ca-more-img"
                            src={require("../../images/account/billsStatements.png")}
                          />
                          Top-up History
                        </a>
                      </MDBCol>
                    </MDBRow>
                  ) : (
                    <MDBRow className="moreHR mt-4 mb-4">
                      <MDBCol size="12" className="text-left">
                        <a
                          href={`/${this.state.jurisdiction}/account/bills-statements`}
                        >
                          <img
                            className="ca-more-img"
                            src={require("../../images/account/billsStatements.png")}
                          />
                          Bills & Statements
                        </a>
                      </MDBCol>
                    </MDBRow>
                  )}

                  {/* Support */}
                  <MDBRow className="moreHR mt-4 mb-4">
                    <MDBCol size="12" className="text-left">
                      <a href={`/${this.state.jurisdiction}/account/support`}>
                        <img
                          className="ca-more-img"
                          src={require("../../images/account/support.png")}
                        />
                        Support
                      </a>
                    </MDBCol>
                  </MDBRow>

                  {/* Payment History */}
                  {!this.state.prepay ? (
                  <MDBRow className="moreHR mt-4 mb-4">
                    <MDBCol size="12" className="text-left">
                      <a href={`/${this.state.jurisdiction}/account/paymenthistory`}>
                        <img
                          className="ca-more-img"
                          src={require("../../images/account/paymentsIcon.png")}
                        />
                        View Payment History
                      </a>
                    </MDBCol>
                  </MDBRow>) : null }

                  {/* Logout */}
                  <MDBRow className="moreHR mt-4 mb-4">
                    <MDBCol size="12" className="text-left">
                      <a
                        onClick={() =>
                          this.setState({
                            logoutModal: !this.state.logoutModal,
                          })
                        }
                      >
                        <img
                          className="ca-more-img"
                          src={require("../../images/account/logoutIcon.png")}
                        />
                        Logout
                      </a>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const actionCreators = {
  logout: signinActions.logout,
};

export default connect(mapStateToProps, actionCreators)(MoreSection);
