import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import {isBrowser, isMobile} from "react-device-detect";

class NIOurPeople extends Component {
  render() {
    return (
      <div className='default-page home-page'>
        {/* Hero with background device */}
        <MDBRow className='small-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='12' lg='12'>
                <h1>Our people</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        {/* Intro */}
        <div>
          <MDBContainer className='text-left-image-right-section'>
            <MDBRow className='my-5'>
              <MDBCol sm='12' md='12' lg='10' className='content'>
                <h3 className='mb-4'>Making energy better</h3>
                <p>
                  We're on a mission to make the world a brighter place. With
                  energy at the heart of everything we do, we are united in our
                  journey towards green<a href='#cru'></a> electricity for all. Technology is
                  driving much of our world and consumers are used to having
                  everything at their fingertips. From ordering food to booking
                  a taxi, the products and services we need and want can all be
                  accessed through websites and apps, and we believe energy
                  should be no different.
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>

        {/* Bright History section */}
        <div>
          <MDBContainer className='text-left-image-right-section'>
            <MDBRow className='my-5'>
              <MDBCol sm='12' md='12' lg='6'>
                <img src={require("../../../images/our-people/people-min.jpg")} />
                <br/><br/>
              </MDBCol>

              <MDBCol sm='12' md='12' lg='6' className='content'>
                <h3 className='mb-4'>The bright history</h3>
                <p>
                  Brothers Ciaran and Stephen Devine in partnership with the
                  Maxol Group, Irelands largest forecourt operator, have set up
                  bright to simplify the over complicated energy market and make
                  it easier for customers to buy electricity. As customers
                  ourselves it was hard to see where we could get a good deal on
                  our energy so we decided to set up bright to do things
                  differently. Our technology focused approach ensures we can
                  provide the best value at the lowest price.
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>

          <MDBContainer className='text-left-image-right-section'>
            <MDBRow className='my-5'>
            {isMobile &&
                <MDBCol sm='12' md='12' lg='6'>
                  <img
                    src={require("../../../images/our-people/Our-People-Option-1.png")}
                    style={{ width: "100%" }}
                  /><br/><br/>
                </MDBCol>
              }
              <MDBCol sm='12' md='12' lg='6' className='content'>
                <h3 className='mb-4'>Our team</h3>
                <p>
                  Our bright team have come together to form a new kind of
                  energy company, one that focuses on delivering green<a href='#cru'></a>,
                  affordable energy to everyone. Whilst we're technology
                  focused we're still very much a people business and building
                  and growing our team of bright thinkers to lead the way in
                  modern energy sales.
                </p>
              </MDBCol>
              {isBrowser &&
                <MDBCol sm='12' md='12' lg='6'>
                  <img
                    src={require("../../../images/our-people/Our-People-Option-1.png")}
                    style={{ width: "100%" }}
                  />
                </MDBCol>
              }
            </MDBRow>
          </MDBContainer>
        </div>

        {/* Timeline */}

        {/* our team section */}
        {/* <div className='teal-background large-padding text-center'>
          <MDBRow>
            <MDBContainer>
              <MDBRow>
                <MDBCol sm='12' md='12' lg='12'>
                  <h4 className='white-text'>Meet the bright thinkers </h4>
                  <MDBRow className='p-0'>
                    <MDBCol size='4'></MDBCol>
                    <MDBCol size='4'></MDBCol>
                    <MDBCol size='4'>
                      <img
                        src={require("../../images/our-people/talented-team-transparent.gif")}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBRow>
        </div> */}

       
      </div>
    );
  }
}

export default NIOurPeople;
