import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MDBRow, MDBCol, MDBBtn, MDBAlert } from "mdbreact";
import { KeypadPaymentDetailsForm } from "./KeypadTopUp/KeypadPaymentDetailsForm";

// Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import axios from "axios";
import Loader from "../../Shared/Loader";
import { keypadActions } from "../../../redux/actions/keypadActions";
import { keypadVendActions } from "../../../redux/actions";

const LoadConfig = require("../../../config");
const config = LoadConfig.default;
const stripePromise = loadStripe(config.niStripeKey);

export const Keypad = (props) => {
  //redux
  const dispatch = useDispatch();
  const keypadStore = useSelector((state) => state.keypad);
  const keypadService = useSelector((state) => state.keypadVend);

  //variables
  const [jurisdiction, setJurisdiction] = useState("");
  const [userEmail, setUserEmail] = useState();
  const [kpn, setKPN] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [paymentAmount, setPaymentAmount] = useState(5);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showTopup, setShowTopup] = useState(true);
  const [stripeInfo, setStripeInfo] = useState();

  useEffect(() => {
    dispatch(keypadVendActions.getKeypadStatus());

    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    setJurisdiction(jurisdiction);

    // Get information
    let user = JSON.parse(localStorage.getItem("user"));
    setUserEmail(user.data.authenticateModel.username);
    setKPN(user.data.jModel.electricityProduct.electricity.meterPoints[0].keypadNumber);
    setAccountNumber(user.data.jModel.number);

    if (user.data.jModel.electricityProduct.electricity.meterPoints[0].keypadNumber !== "") {
      setShowTopup(false);
    }
  }, []);

  useEffect(() => {}, [keypadService.keypadServiceData]);

  //once keypad data updates then redirect to checkout
  useEffect(() => {
    // adding listeners everytime props changes
    if (keypadStore.secret != "") {
      const stripeInfo = {
        secret: keypadStore.secret,
        id: keypadStore.id,
        intentId: keypadStore.intentId,
        kpn: kpn,
        email: keypadStore.email,
        payAmount: paymentAmount,
      };

      setStripeInfo(stripeInfo);
      setShowLoader(false);
      setShowTopup(false);
      setShowPaymentDetails(true);
    }
    return () => {};
  }, [keypadStore.secret]);

  const increment = () => {
    let amt = paymentAmount + 5;
    setPaymentAmount(amt);
  };

  const decrement = () => {
    let amt = paymentAmount - 5;
    setPaymentAmount(amt);
  };

  // Stripe payment intent
  const moveToCardPayment = () => {
    CreatePaymentIntent();
  };
  async function CreatePaymentIntent() {
    setShowLoader(true);

    const details = {
      amount: parseInt(paymentAmount),
      kpn: kpn,
      email: userEmail,
      description: `Jf Acc Num: ${accountNumber} - KPN: ${kpn} - Keypad Top-up (user logged in - Web).`,
    };

    dispatch(keypadActions.keypadVendIntent(details));
  }

  return (
    <div>
      {showLoader ? <Loader /> : null}
      {/* {keypadService.isLoading ? <Loader /> : null} */}

      {keypadService.keypadServiceData ? (
        <div>
          {!showTopup ? (
            <div className="white-curved-tile text-center">
              <h2>Top Up Amount</h2>

              <MDBRow size="12">
                {/* - */}
                <MDBCol size="3">
                  {paymentAmount != 5 ? (
                    <div>
                      <i
                        class="fas fa-minus fa-2x mt-4"
                        onClick={() => decrement(5)}
                      />
                    </div>
                  ) : null}
                </MDBCol>

                {/* Amount */}
                <MDBCol size="6">
                  <p
                    className="cocogoose"
                    style={{ fontSize: 30, marginTop: 15 }}
                  >
                    £ {paymentAmount}
                  </p>
                </MDBCol>

                {/* + */}
                <MDBCol size="3">
                  {paymentAmount != 100 ? (
                    <div>
                      <i
                        className="fas fa-plus fa-2x mt-4"
                        onClick={() => increment(5)}
                      />
                    </div>
                  ) : null}
                </MDBCol>
              </MDBRow>
              <MDBBtn className="btn" onClick={() => moveToCardPayment()}>
                Make Payment
              </MDBBtn>
            </div>
          ) : (
            <div className="white-curved-tile text-center">
              <MDBBtn className="btn">
                <a href={`/${jurisdiction}/keypadtopup`}>Top-up Now</a>
              </MDBBtn>
            </div>
          )}

          {showPaymentDetails ? (
            <div className="white-curved-tile text-center">
              <Elements stripe={stripePromise}>
                <KeypadPaymentDetailsForm stripeInfo={stripeInfo} />
              </Elements>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="white-curved-tile text-center">
          <h1 className="mb-4">Top-up Service Unavailable</h1>
          <p className="mb-4">
            Sorry our top-up service is temporarily unavailable.
            <br />
            While we're working hard on making this available again, you can
            top-up via:
            <br />
          </p>
          <ul className="bullet-list-keypad text-left m-0">
            <li>
              Phone - <a href="tel:028 95442290">02895442290</a>
              <br />
              Monday - Friday: 8am - 6pm
            </li>
            <li>
              <a
                href="https://www.postoffice.co.uk/branch-finder"
                target="blank"
              >
                Click here for your nearest Post Office Branch
              </a>
            </li>
            <li>
              <a href="https://storelocator.payzone.co.uk/" target="blank">
                Click here for your nearest Payzone Branch
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
