import React from 'react';
import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import { MDBContainer, MDBRow, MDBCol, MDBInput } from 'mdbreact';
import $ from "jquery";
// Redux
import { connect } from "react-redux";
import { paymentActions } from "../../../redux/actions";
import {PaymentDetailsForm} from "./PaymentDetailsForm";

const LoadConfig = require("../../../config");
const config = LoadConfig.default;

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(config.niStripeKey);

const PaymentDetailsPage = () => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentDetailsForm />
        </Elements>
    );
}


export default PaymentDetailsPage;