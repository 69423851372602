import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
const LoadConfig = require("../../config");
const config = LoadConfig.default;


class NIFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  render() {
    return (
      <MDBFooter>
        <div className='white-text teal-background'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='5' lg='3' className='text-left'>
                <img
                  className='footer-logo'
                  src={require("../../images/global/bright-logo.gif")}
                />
                <p className='footer-address'>
                  Registered in Northern Ireland:
                  <br /> No: NI655169
                  <br />
                  <br />
                  Registered Address:
                  <br />84-94 Great Patrick Street,
                  <br /> Belfast, BT1 2LU
                </p>
              </MDBCol>
              <MDBCol sm='12' md='12' lg='6'>
                <MDBRow className='footer-navigation-container'>
                  <MDBCol sm='6' md='3' lg='3' className='footer-navigation'>
                    <p className='footer-navigation-title'>About Us</p>
                    <ul className='text-left'>
                      
                      <li>
                        <a
                          href={`/${this.state.jurisdiction}/about/ourmission`}
                        >
                          Our mission
                        </a>
                      </li>
                      <li>
                        <a
                          href={`/${this.state.jurisdiction}/about/ourelectricity`}
                        >
                          Our electricity
                        </a>
                      </li>
                      <li>
                        <a href={`/${this.state.jurisdiction}/about/ourtariff`}>
                          Our tariff
                        </a>
                      </li>
                      <li>
                        <a href={`/${this.state.jurisdiction}/blog`}>Blog</a>
                      </li>
                    </ul>
                  </MDBCol>
                  <MDBCol sm='6' md='3' lg='3' className='footer-navigation'>
                    <p className='footer-navigation-title'>Help</p>
                    <ul className='text-left'>
                      <li>
                        <a href={`/${this.state.jurisdiction}/help`}>FAQs</a>
                      </li>
                      <li>
                        <a
                          href={`/${this.state.jurisdiction}/faqs/complaints`}
                        >
                          Complaints
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href={`/${this.state.jurisdiction}/help/emergencyinfo`}
                        >
                          Emergency information
                        </a>
                      </li>
                      <li>
                        <a
                          href={`/${this.state.jurisdiction}/help/makeacomplaint`}
                        >
                          Make a complaint
                        </a>
                      </li> */}
                    </ul>
                  </MDBCol>
                  <MDBCol sm='6' md='3' lg='3' className='footer-navigation'>
                    <p className='footer-navigation-title'>Bright Account</p>
                    <ul className='text-left'>
                      <li>
                        <a href={`/${this.state.jurisdiction}/about/contactus`}>
                          Contact us
                        </a>
                      </li>
                      <li>
                        <a href={`/${this.state.jurisdiction}/account/login`}>
                          Sign in
                        </a>
                      </li>
                    </ul>
                  </MDBCol>

                  <MDBCol sm='6' md='3' lg='3' className='footer-navigation'>
                    <p className='footer-navigation-title'>Policies & Terms</p>
                    <ul className='text-left'>
                      <li>
                        <a href={`/${this.state.jurisdiction}/codesofpractice`}>
                          Codes of practice
                        </a>
                      </li>
                      <li>
                        <a href={`/${this.state.jurisdiction}/terms`}>
                          Terms & conditions
                        </a>
                      </li>
                      <li>
                        <a
                          href={`/${this.state.jurisdiction}/privacystatement`}
                        >
                          Privacy statement
                        </a>
                      </li>
                      <li>
                        <a href={`/${this.state.jurisdiction}/ourpolicies`}>
                          Our policies
                        </a>
                      </li>
                      <li>
                        <a href={`/${this.state.jurisdiction}/energytheft`}>
                          Energy Theft
                        </a>
                      </li>
                      <li>
                        <a href={`/${this.state.jurisdiction}/sources`}>
                          Sources
                        </a>
                      </li>
                    </ul>
                  </MDBCol>
                </MDBRow>
              </MDBCol>

              <MDBCol
                sm='12'
                md='12'
                lg='3'
                className='footer-social-media-container'
              >
                <MDBRow className='footer-social-media'>
                  <MDBCol sm='12>'>
                    <p className='social'>
                      <a
                        className='Footer-Link-Facebook'
                        href='https://www.facebook.com/BrightEnergySupplier/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i className='fab fa-facebook fa-3x pr-2' />
                      </a>
                      <a
                        className='Footer-Link-Twitter'
                        href='https://twitter.com/bright_Ireland'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i className='fab fa-twitter fa-3x pr-2' />
                      </a>
                      <a
                        className='Footer-Link-Instagram'
                        href='https://www.instagram.com/brightenergysupplier/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i className='fab fa-instagram fa-3x pr-2' />
                      </a>
                      {/* <a
                        className='Footer-Link-Youtube'
                        href='https://youtube.com'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i className='fab fa-youtube fa-3x pr-2' />
                      </a> */}
                      <a
                        className='Footer-Link-LinkedIn'
                        href='https://www.linkedin.com/company/brightenergysupplier'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i className='fab fa-linkedin fa-3x pr-2' />
                      </a>
                    </p>
                  </MDBCol>
                </MDBRow>

                <MDBRow className='justify-content-center'>
                  {/* FOR BOTH IMAGES - className='footer-download-app-container' */}
                  <a target="_blank" href='https://apps.apple.com/app/id1500409256'>
                    <img
                      src={require("../../images/global/ios.png")}
                      style={{ height: 40 }}
                    />
                  </a>
                  <a className="ml-1" target="_blank" href='https://play.google.com/store/apps/details?id=com.brightapp'>
                    <img
                      src={require("../../images/global/google.png")}
                      style={{ height: 40 }}
                    />
                  </a>
                </MDBRow>
              </MDBCol>
            </MDBRow>

            <hr />
            <MDBRow className='grid-container copyright-container'>
              <MDBCol sm='12' md='12' lg='12'>
                <p className='text-left'>
                  &copy; bright 2020                  
                  <br /><br/>
                  All our customers get 100% renewable electricity at no extra cost provided through the purchase of renewable electricity certificates.  The electricity provided to your home comes from the NI electricity network.
                  <br /><br/>
                  {config.versionNumber}
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </MDBFooter>
    );
  }
}

export default NIFooter;
