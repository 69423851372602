import { contactConstants } from "../constants";

let defaultState = {
  contactResponse: "",
  error: "",
  isLoading: false,
};

const contactReducer = (state = defaultState, action) => {
  switch (action.type) {
    // Create quote journey
    case contactConstants.USERS_SENDMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        contactResponse:""
      };

    case contactConstants.USERS_SENDMAIL_SUCCESS:
      return {
        contactResponse: action.payload,
        isLoading: false,
      };

    case contactConstants.USERS_SENDMAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        contactResponse:"",
      };

    // Default
    default:
      return state;
  }
};

export default contactReducer;
