import { faqConstants } from "../constants";

let defaultState = {
  faqData: [],
  isLoading: false
};

const faqReducer = (state = defaultState, action) => {
  switch (action.type) {
    // Get FAQ's
    case faqConstants.GET_FAQ_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case faqConstants.GET_FAQ_SUCCESS:
      return {
        ...state,
        faqData: action.payload,
        isLoading: false
      };
    case faqConstants.GET_FAQ_FAILURE:
      return {
        error: action.payload
      };

    // Get FAQ's By ID
    case faqConstants.GET_FAQ_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case faqConstants.GET_FAQ_BY_ID_SUCCESS:
      return {
        ...state,
        faqData: action.payload,
        isLoading: false
      };
    case faqConstants.GET_FAQ_BY_ID_FAILURE:
      return {
        error: action.payload
      };

    // Create FAQ
    case faqConstants.CREATE_FAQ_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case faqConstants.CREATE_FAQ_SUCCESS:
      return {
        ...state,
        faqData: action.payload,
        isLoading: false
      };
    case faqConstants.CREATE_FAQ_FAILURE:
      return {
        error: action.payload
      };

    // Update FAQ
    case faqConstants.UPDATE_FAQ_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case faqConstants.UPDATE_FAQ_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case faqConstants.UPDATE_FAQ_FAILURE:
      return {
        error: action.payload
      };

    // Delete
    case faqConstants.DELETE_FAQ_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case faqConstants.DELETE_FAQ_SUCCESS:
      return {
        ...state
      };
    case faqConstants.DELETE_FAQ_FAILURE:
      return {};

    // Default
    default:
      return state;
  }
};

export default faqReducer;
