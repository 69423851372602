import React, { Component } from "react";
import { Router } from "react-router-dom";
import "./index.css";
import Routes from "./routes/Routes.js";
import Footer from "./components/Shared/Footer.js";
import { history } from "./redux/helpers";
import FixedNav from "./components/Shared/FixedNav";
import NIFooter from "./components/Shared/NIFooter";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];

    
      this.setState({
        jurisdiction: juris,
      });
    
  }

  render() {
    return (
      <Router history={history}>
        <div>
          <FixedNav />
          <Routes />
          {/*
              App renders here
            */}
          {this.state.jurisdiction == "roi" ? <Footer /> : <NIFooter />}
        </div>
      </Router>
    );
  }
}

export default App;
