import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FAQTabs from "../../../components/Help/FAQTabs";

import FixedNavDark from "../../../components/Shared/FixedNavDark";
import $ from "jquery";

class NIFAQsGettingStarted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }
  
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.help);
  }

  help =() =>{
    if (window.pageYOffset <50)
    {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }
  
  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark/> */}
        
        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>FAQs</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow className="pb-5">
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <FAQTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5'>
            <h3>Getting Started</h3>

            <div id='howtoswitch' className='mt-5 mb-5'>
              <h5 className='policy-title'>How do I switch to bright? </h5>
              <p className='policy-content'>
              Switching to bright is easy. You can switch today via our website, all you need are your bank details and your latest bill.  If you prefer you can get in touch with our energy specialists.
             </p>
            </div>

            <div id='previoussupplier' className='mb-5'>
              <h5 className='policy-title'>Do I need to contact my previous supplier? </h5>
              <p className='policy-content'>
              We will handle the switch with your existing supplier and we will be in touch with you to advise when the switch has been completed. Your existing supplier will issue you with a final bill.  You are responsible for paying any monies owed to your existing supplier.
              </p>
            </div>

            <div id='firstbill' className='mb-5'>
              <h5 className='policy-title'>When can I expect my first bill from bright? </h5>
              <p className='policy-content'>
              We will bill you on a monthly basis so you should receive your first bill from bright approximately one month after your switch is complete and monthly thereafter. If you haven't received your bill within this time simply get in touch and our energy specialists will look into this for you. 
              </p>
            </div>

            <div id='changemind' className='mb-5'>
              <h5 className='policy-title'>What should I do if I change my mind about switching to bright? </h5>
              <p className='policy-content'>
              When you sign up there is a cooling off period of 10 working days in which to change your mind. When you sign up we will tell you about the cooling off period and when it starts.  If you change your mind simply get in touch with us within this period and we'll cancel your switch.
              </p>
            </div>

            <div id='mprn' className='mb-5'>
              <h5 className='policy-title'>What is my MPRN (Meter Point Reference Number)? </h5>
              <p className='policy-content'>
              A Meter Point Reference Number (or MPRN) is a unique 11 digit number starting with 81 which is used to identify your premises meter point address. You will need your MPRN if you want to switch to bright or are getting a first time electricity connection to your home. You can find your MPRN on the top right of your electricity bill from your current electricity supplier, ask your landlord, agent or previous owner or you can call NIE Networks on <a href='tel:03457 643 643'>03457 643 643</a> to get your MPRN.
              </p>
            </div>

            <div id='ev' className='mb-5'>
            <h5 className='policy-title'>If I have an electric vehicle, what meter is best for me? </h5>
              <p className='policy-content'>
              This depends on when you are likely to charge your car. If you charge overnight, the best meter for you is Economy 7. Our Economy 7 tariff is a dual-rate tariff offering different unit rates for day and night. The night rate is significantly cheaper, allowing you to save money whilst charging overnight. If you plan on charging your car during the day,
              a standard credit meter will be most appropriate for you with a flat-rate tariff both day and night. 
              </p>

            </div>

            

          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NIFAQsGettingStarted;
