import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FAQTabs from "../../../components/Help/FAQTabs";

import FixedNavDark from "../../../components/Shared/FixedNavDark";
import $ from "jquery";

class NIFAQSPrepaymentmeters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark/> */}
        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>FAQs</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow className="pb-5">
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <FAQTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5'>
            <h3>Prepayment Meters</h3>

            <div id='prepaymentmeters' className='mt-5 mb-5'>
              <h5 className='prepaymentmeters'>What are Prepayment Meters?  </h5>
              <p>Prepayment meters, also referred to as Pay as You Go meters or Keypad meters,
              offer you a simple method of paying for your electricity as you go. There are
              various methods for topping up and emergency credit available should you run out
              of credit. With a prepayment meter you are in charge of your budget and do not have
              to worry about building up debt. For more information see our code of practice on
              services from prepayment meter customers.
                </p></div>


                <div id='prepaysuit' className='mb-5'>
              <h5 id='policytitle'>Is a Prepayment Meter suitable for me?</h5>
              <p> Prepayment meters have lots of benefits, however;
                <ul>
                  <li>They are not suitable for customers on the Medical Care Register, as this could interrupt your supply and affect any critical devices you rely on.  </li>
                  <li>May not be suitable for customers with special needs e.g. sight, hearing or physical  difficulties.</li>
                  <li>You need to check your meter regularly to ensure you remain in credit</li>
                  <li>You need to top up your meter to ensure your electricity supply</li>
                </ul>
              </p></div>

              <div id='topup' className='mb-5'>
              <h5 id='policy-title'>How do I top up?</h5>
              <p>Topping up is easy peasy!
                <ul className="list-style-none definitions">
                  <li>1. Press the star (*) button on the keypad. Then a message will appear saying “Key Code”.</li>
                  <li>2. Key in the all the digits from your top up code. Don’t worry if you make a mistake just press the star (*) button to enter again.</li>
                  <li>3. Once you’ve entered all the digits hit the hash (#) button to send the code. You’ll then see one the following messages;
                  <ul>
                      <li>Accepted: The meter will make a “happy” tone. The screen will show the top-up amount   then “Account” followed by the total amount of credit in your meter. </li>
                      <li>Rejected: The meter will make a “sad” tone, then one of the following messages will appear,  you will need to wait until message has cleared and start again from step one.  </li>
                      <li>Duplicate: This Top Up code has been entered before and cannot be used again.  </li>
                      <li>Incorrect: This Top Up code has been entered incorrectly or may be for a different property.</li>
                      <li>Error: You may have entered the code too slowly or incorrectly. </li>
                      <li>Kblock: You have entered the incorrect Top Up code five times in a row.   </li>
                      <li>Wrong Tar: The price of electricity has changed, and you now must enter a 40-digit code.</li>
                      <li>CreditHI: Meters have a maximum amount that can be loaded onto it. This message means you have reached this limit, wait a few days then try again.</li>
                    </ul>
                  </li>
                </ul>
              </p></div>

              <div id='lostcode' className='mb-5'>
              <h5 id='policy-title'>I've lost my top up code what do I do?</h5>
              <p>The easiest way to top up is via our app or online, that way you'll always have access to your top up codes.
              You can register your account with us online at any time.  If you have purchased a top up from a Post Office or
              Payzone terminal you can go back to the vendor and they will reissue the code free of charge.
                    </p></div>

              <div id='topup' className='mb-5'>
              <h5 id='topup'>Do I need my Bright card to top up?</h5>
              <p>
                If you register your account you can top up online or via the app without your bright card.
                You'll need your bright card to top up at a Post Office branche or Payzone store.
                </p></div>

              <div id='lostcard' className='mb-5'>
              <h5 id='policy-title'>Can I order a new top up card? </h5>
              <p>
              If you have lost your card you can contact our energy specialists and they will arrange a replacement card.  You can always top up online or via our App, if you have not registered your account you will need your 19 digit KPN.</p></div>


              <div id='coderejected' className='mb-5'>
              <h5 id='policy-title'>My top up code has been rejected- why is this? </h5>
              <p>
                If your top up code has been rejected the meter will make a “sad” tone, then one of the following 6 messages will appear:
              <ul>
                <li>Duplicate: This Top Up code has been entered before and cannot be used again. </li>
                <li>Incorrect: This Top Up code has been entered incorrectly or may be for a different property. </li>
                <li>Error: You may have entered the code too slowly or incorrectly. </li>
                <li>Kblock: You have entered the incorrect Top Up code five times in a row.</li>
                <li>Wrong Tar: The price of electricity has changed, and you now must enter a 40-digit code. </li>
                <li>CreditHI: Meters have a maximum amount that can be loaded onto it. This message means you have reached this limit, wait a few days then try again.</li>
              </ul></p>



              <p>
                Check that all the digits in the code have been entered correctly. 

              <ul>
                <li>If there has been a recent tariff change the next time you top up after this change you will receive a 40 or 60 digit code.
                If this is the case please ensure that this 40 or 60 digit code is entered correctly.  (Please note: once you type in this code
                      your meter will add your top up and update the tariff details.)</li>
                <li>Check that the 19 digit Premise Number on your card matches the one on your receipt/website/app</li>
                <li>Check that you have topped up using your own card rather than a friends or relatives. Ensure that you are using your own card as it relates only to your meter.</li>
              </ul> </p>


              <p>Should you wish to re-enter your top up code please follow the steps below:
              <ul>
                <li>Press the star (*) button on the keypad. Then a message will appear saying “Key Code”.</li>
                <li>Key in the top up code. </li>
                <li>Once you’ve entered all the digits hit the hash (#) button to send the code.</li>
              </ul></p></div>

              <div id='codelonger' className='mb-5'>
              <h5 id='policy-title'>Why is my top up code longer than normal? </h5>
              <p>Should there be a change to your tariff, the next time you top up your code will contain 40 or 60 digits. Inputting this will add both credit to your meter and update the tariff.
                        We will always provide you with a minimum of 21 days advance notice of a tariff change.</p></div>


              <div id='minimumtopup' className='mb-5'>
                <h5 className='policy-title'>What are the minimum and maximum top up amounts?  </h5>
                <p className='policy-content'>
                Our Minimum top up is £5 on our app and website and £10 via a Post Office Branch / Payzone Store or telephone. After the minimum amount all top ups increase in multiples of £1 allowing you the flexibility to suit your budget.
                The maximum top up is £100. </p>
              </div>

              <div id='meterinstall' className='mb-5'>
                <h5 className='policy-title'>How do I get a keypad meter installed at my property?  </h5>
                <p className='policy-content'>

                  To get a keypad meter installed at your property, contact our energy specialists on <a href="tel:028 9544 2290">028 9544 2290. </a>
                In certain circumstances NIE Networks may charge a fee for this service.  We will arrange an appointment for you with NIE Networks at a suitable date and time for the keypad meter to be installed.
                Providing it is safe and suitable NIE Networks will remove your standard meter and replace it with the new keypad meter. A meter reading will be provided to calculate your final credit meter account balance and bill.
              </p>
              </div>
              


            <div id='wheretotopup' className='mb-5'>
              <h5 className='policy-title'>Where can I top up my keypad? </h5>
              <p className='policy-content'>
                You can purchase top-ups:
              <ul>
                  <li>Through our app </li>
                  <li>Online via our <a href={`/${this.state.jurisdiction}/account/login`}>website</a></li>
                  <li>At any NI Post Office Branch or retail outlet offering Payzone services </li>
                  <li>If you have any difficulties topping up by the above means you can contact us by telephone on <a href="tel:028 9544 2290">028 9544 2290</a></li>
                </ul></p>
              
            </div>
                
            
            <div id='annualstatement' className='mb-5'>
              <h5 className='policy-title'>What is a keypad annual statement?  </h5>
              <p className='policy-content'>
              This is a statement you will receive once a year that will detail how much electricity you have used, the number and value of top ups you have made over the last 12 months.  As well as useful information about the services we offer.  
              </p>
            </div>

            <div id='leavingbright' className='mb-5'>
              <h5 className='policy-title'>I’ve decided to leave Bright. What happens to the money on my keypad meter?  </h5>
              <p className='policy-content'>
                If you switch supplier any outstanding monies will remain on your meter and you will be able to use this with your new supplier.
              </p>
            </div>

               <div id='credit' className='mb-5'>
              <h5 className='policy-title'>What are friendly credit and emergency credit? </h5>
              <p className='policy-content'></p>
                <b>Friendly credit </b>
                <br />
                <p>If you run out of credit during the periods listed below your electricity supply will not stop. However it is important to note that when you next top up you will be charged for the electricity used during that period.
                <ul>
                  <li>Weekdays – if your meter runs out of credit after 4pm on a weekday (Monday to Thursday) the  supply will run out at 11am the next day.</li>
                  <li>Weekends – if you meter runs out of credit after 4pm on a Friday the supply will run out at 11am the following Monday.    </li>
                  <li>Bank Holidays – Your supply will not stop and will still stay on until 11am the following working  day on bank holidays.   </li>
                  <li>All times are stated in GMT. Please add one hour for British Summer Time.</li>
                   </ul></p>
                   
              <b>Emergency credit</b>
              <p>If you are running low on credit an  audible  alarm will sound from the meter to alert you. If you turn this alarm off you will automatically get £1 emergency credit to give you time to top up. 
                If you do not switch the alarm off your electricity supply will stop. In this event press any button and your emergency credit will come on within a few seconds.  
                The emergency credit will be deducted from your next top up.    </p>
                </div>
            

            <div id='functions' className='mb-5'>
              <h5 className='policy-title'>What do the functions mean on the keypad meter? </h5>
              <p className='policy-content'>
                Your keypad meter holds lots of information about your electricity usage and top ups, which  you can view by pressing the relevant key.
                The list below shows the functions your meter can provide.
              <ul>
                  <li>Star Key (*) - Press this before entering Top Up codes  </li>
                  <li>Hash Key (#) -Press this after entering Top Up codes  </li>
                  <li>Hash Key (#) -Press this to see the amount of credit left </li>
                  <li>1 -Credit time left in days</li>
                  <li>2 -Cost of previous day’s, week’s, month’s use</li>
                  <li>3 -Unit rates and numbers of units used. Economy 7 meter display DL domestic, CH central heating and HW hot water</li>
                  <li>4 -The last five Top Up codes entered</li>
                  <li>5 - Total credit entered into meter</li>
                  <li>6 -Electricity currently being used in kilowatts. (This is useful for understanding which appliances use  most electricity) </li>
                  <li>7 -Standing charge rates per day if applicable </li>
                  <li>8 -Highest Consumption in any half hour in the last 24 hours </li>
                  <li>9 -Total units used</li>
                  <li>0 -Display test, time and date</li>
                </ul></p>



              
            </div>

            <div id='meterfault' className='mb-5'>
              <h5 className='policy-title'>I think there is a fault with my meter - what do I do?  </h5>
              <p className='policy-content'>
                If you think there is a fault with your meter, contact NIE Networks on <a href="tel:03457 643 643">03457 643 643. </a>

              </p>
            </div>





          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NIFAQSPrepaymentmeters;
