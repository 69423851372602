import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBAnimation } from "mdbreact";

class Partners extends React.Component {
    render() {
        return (
            <div className="bt codes-of-practice text-center grey-background large-padding latest-jobs-bg">
                
                <MDBRow className='mb-5 pb-5'>
                    <MDBCol lg='1' md='2' sm='12' />
                    <MDBCol lg='10' md='8' sm='12' className='mb-5 mt-3'>
                    <h3 className="mb-4">As seen in:</h3>
                        <MDBRow className='hero-icons-container featured-in'>
                            <MDBCol
                                sm='6'
                                md='6'
                                lg='3'
                                className='text-center feat-icons-container'
                            >
                                <img
                                    className='rte'
                                    src={require("../../images/partners/rte.png")}
                                ></img>
                            </MDBCol>
                            <MDBCol
                                sm='6'
                                md='6'
                                lg='3'
                                className='text-center feat-icons-container'
                            >
                                <img
                                className='w-80'
                                    src={require("../../images/partners/independent.png")}
                                ></img>
                            </MDBCol>
                            <MDBCol
                                sm='6'
                                md='6'
                                lg='3'
                                className='text-center feat-icons-container'
                            >
                                <img
                                     className='w-80'
                                    src={require("../../images/partners/sundaytimes.png")}
                                ></img>
                            </MDBCol>
                            <MDBCol
                                sm='6'
                                md='6'
                                lg='3'
                                className='text-center feat-icons-container'
                            >
                                <img
                                    className='w-80'
                                    src={require("../../images/partners/irishtechnewscolor.jpg")}
                                ></img>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol lg='1' md='2' sm='12' />
                </MDBRow>
                {/* </MDBContainer> */}
            </div>
        )
    };
}

export default Partners;