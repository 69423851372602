import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import $ from "jquery";
import { history } from "../../../redux/helpers";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../../components/Shared/Loader";

const LoadConfig = require("../../../config");
const config = LoadConfig.default;

// Custom styling can be passed to options when creating an Element.
const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#31e51a",
      color: "#001d28",
      // fontWeight: 500,
      fontFamily: "Montserrat, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#757575" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

async function stripeTokenHandler(
  intent,
  kpn,
  payAmount,
  vendLogId,
  jurisdiction
) {
  let vendCode = "";
  const response = await axios
    .post(`${config.apiUrl}/keypadvend/vendandcapture`, {
      intentid: intent,
      kpn: kpn,
      payAmount: payAmount,
      logId: vendLogId,
    })
    .then((response) => {
      vendCode = response.data;
    });
  if (vendCode != "") {
    history.push(
      `/ni/keypadtopupconfirmation?vendcode=${vendCode}&amount=${payAmount}`
    );
  } else {
    history.push(`/ni/keypadtopupunsuccessful`);
  }
}

export const PaymentDetailsForm = () => {
  const [payAmount, setPayAmount] = useState(10);
  const [name, setName] = useState();
  const [kpn, setKpn] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  // const [postcode, setPostcode] = useState();
  const [clSec, setClSec] = useState();
  const [clIntent, setClIntent] = useState();
  const [error, setError] = useState();
  const [vendLogId, setVendLogId] = useState();
  const [jurisdiction, setJurisdiction] = useState();
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  //sset the payment intent client secret
  // var pathArray = window.location.pathname.split("/");
  // setClSec(pathArray[3]);

  const help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  };

  const getParams = () => {
    setClSec(history.location.state.data.secret);
    setKpn(history.location.state.data.kpn);
    setClIntent(history.location.state.data.intentId);
    setPayAmount(history.location.state.data.amount);
    setVendLogId(history.location.state.data.id);
    setEmail(history.location.state.data.email);
  };

  useEffect(() => {
    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    setJurisdiction(jurisdiction);

    getParams();
    help();
    window.addEventListener("scroll", help());

    return () => {
      window.removeEventListener("scroll", help());
    };
  }, []);

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    setLoader(true);
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const billingAddress = {
      line1: address,
      city: city,
      // postal_code: postcode,
    };

    const result = await stripe.confirmCardPayment(clSec, {
      payment_method: {
        card,
        billing_details: {
          name,
          address: billingAddress,
          email: email,
        },
      },
    });
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      const vendCode = await stripeTokenHandler(
        clIntent,
        kpn,
        payAmount.toString(),
        vendLogId,
        jurisdiction
      );
    }
  };

  return (
    <div className="login-page">
      <MDBContainer className="switch-today-container">
        {loader ? <Loader /> : null}
        <MDBRow className="brightTeal-text">
          <MDBCol md="3" lg="3"></MDBCol>
          <MDBCol sm="12" md="6" lg="6" className="text-center">
            <div>
              <h2>Purchase a top-up</h2>
              <MDBRow className="login-form-container white-curved-tile">
                <MDBCol size="12">
                  <form onSubmit={handleSubmit}>
                    <MDBRow center>
                      <MDBCol size="12">
                        <MDBInput
                          label="Name"
                          type="text"
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          className="form-control"
                          required
                        />
                        <MDBInput
                          label="Email"
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                          required
                        />
                        <MDBInput
                          label="Address"
                          type="text"
                          name="address"
                          onChange={(e) => setAddress(e.target.value)}
                          className="form-control"
                          required
                        />
                        <MDBInput
                          label="City"
                          type="text"
                          name="city"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          className="form-control"
                          required
                        />
                        <div class="form-row">
                          <CardElement
                            id="card-element"
                            options={CARD_OPTIONS}
                            onChange={handleChange}
                            className="w-100 card-form-control mt-2"
                          />
                          <div className="card-errors" role="alert">
                            {error}
                          </div>
                        </div>
                        <br />
                        <button type="submit" className="btn w-100">
                          Get Vend Code
                        </button>
                      </MDBCol>
                    </MDBRow>
                  </form>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBCol>
          <MDBCol size="3"></MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};
