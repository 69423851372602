import { agencyService } from "../services";
import { agencyConstants } from "../constants";

export const agencyActions = {
  getElectricityProduct,
  addNewProperty,
  tenantMoveOut,
  tenantMoveIn
};

// Get Electricity Product
function getElectricityProduct(id, jurisdiction) {
  return (dispatch) => {
    dispatch(getElectricityProductRequest());

    agencyService.getPropertyProductRef(id, jurisdiction).then(
      (response) => {
        dispatch(getElectricityProductSuccess(response.data));
      },
      (error) => {
        dispatch(getElectricityProductFailure("Error"));
      }
    );
  };
}

function getElectricityProductRequest() {
  return {
    type: agencyConstants.PRODUCT_REF_REQUEST,
  };
}
function getElectricityProductSuccess(electricityProduct) {
  return {
    type: agencyConstants.PRODUCT_REF_SUCCESS,
    payload: electricityProduct,
  };
}
function getElectricityProductFailure(error) {
  return {
    type: agencyConstants.PRODUCT_REF_FAILURE,
    payload: error,
  };
}

// Add New Property
function addNewProperty(details) {
  return (dispatch) => {
    dispatch(addNewPropertyRequest());

    agencyService.addNewProperty(details).then(
      (response) => {
        dispatch(addNewPropertySuccess(response.data));
      },
      (error) => {
        dispatch(addNewPropertyFailure("Error"));
      }
    );
  };
}

function addNewPropertyRequest() {
  return {
    type: agencyConstants.ADD_PROPERTY_REQUEST,
  };
}
function addNewPropertySuccess(data) {
  return {
    type: agencyConstants.ADD_PROPERTY_SUCCESS,
    payload: data,
  };
}
function addNewPropertyFailure(error) {
  return {
    type: agencyConstants.ADD_PROPERTY_FAILURE,
    payload: error,
  };
}

// Transfer Tenancy
function tenantMoveIn(details) {
  return (dispatch) => {
    dispatch(tenantMoveInRequest());

    agencyService.tenantMoveIn(details).then(
      (response) => {
        dispatch(tenantMoveInSuccess(response.data));
      },
      (error) => {
        dispatch(tenantMoveInFailure("Error"));
      }
    );
  };
}

function tenantMoveInRequest() {
  return {
    type: agencyConstants.TENANT_MOVEIN_REQUEST,
  };
}
function tenantMoveInSuccess(data) {
  return {
    type: agencyConstants.TENANT_MOVEIN_SUCCESS,
    payload: data,
  };
}
function tenantMoveInFailure(error) {
  return {
    type: agencyConstants.TENANT_MOVEIN_FAILURE,
    payload: error,
  };
}

function tenantMoveOut(details) {
  return (dispatch) => {
    dispatch(tenantMoveOutRequest());

    agencyService.tenantMoveOut(details).then(
      (response) => {
        dispatch(tenantMoveOutSuccess(response.data));
      },
      (error) => {
        dispatch(tenantMoveOutFailure("Error"));
      }
    );
  };
}

function tenantMoveOutRequest() {
  return {
    type: agencyConstants.TENANT_MOVEOUT_REQUEST,
  };
}
function tenantMoveOutSuccess(data) {
  return {
    type: agencyConstants.TENANT_MOVEOUT_SUCCESS,
    payload: data,
  };
}
function tenantMoveOutFailure(error) {
  return {
    type: agencyConstants.TENANT_MOVEOUT_FAILURE,
    payload: error,
  };
}
