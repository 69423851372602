import React, { Component } from "react";
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBAlert, MDBIcon } from "mdbreact";
import Loader from "../../../components/Shared/Loader";
import $ from "jquery";

// Redux
import { connect } from "react-redux";
import { signinActions } from "../../../redux/actions";

import { history } from "../../../redux/helpers";

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      jurisdiction: "",
      errorMessage: false,
      loading: false,
      passwordError: false,
    };
  }

  // Page load
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    let user = localStorage.getItem("user");
    if (user != null) {
      var url = `/${juris}/account/home`;
      history.push(url);
    }
  }

  // If Props.journeyData true populate form
  async componentWillReceiveProps(nextProps) {
    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle Field Change
  handleChange = (input) => (e) => {
    var fieldValue = $("#" + input)[0].checkValidity();

    if (fieldValue) {
      $("#" + input)
        .addClass("input-valid")
        .removeClass("input-error");
    } else {
      $("#" + input)
        .addClass("input-error")
        .removeClass("input-valid");
    }

    this.setState({ [input]: e.target.value });
  };

  // Handle Submit
  handleSubmit = (e) => {
    let passwordCheck = $("#password")[0].checkValidity();
    if (!passwordCheck) {
      this.setState({ passwordError: true, passwordTip: true });
      $("#password").addClass("input-error");
    } else {
      this.setState({ passwordError: false, passwordTip: false });
      $("#password").removeClass("input-error");

      var valid = $("#register")[0].checkValidity();

      if (valid) {
        const details = {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          username: this.state.username,
          password: this.state.password,
        };

        // Hide Error Message (if visiable)
        this.setState({
          errorMessage: false,
        });

        this.props.register(details, this.state.jurisdiction);
      } else {
        // For each form input that has the required prop add the input-error class
        $("form#accountDetails")
          .find("input")
          .each(function () {
            if ($(this).prop("required")) {
              $(this).addClass("input-error");
            }
          });

        // Display the error message
        this.setState({
          errorMessage: true,
        });
      }
    }
  };

  render() {
    return (
      <div>
        <MDBRow>
          {/* API Loader */}
          {this.state.loading ? <Loader /> : null}

          {/* Error Message */}
          <MDBCol size='12' id='errorMessage'>
            {this.state.errorMessage == true ? (
              <MDBAlert color='danger' dismiss>
                <strong>Oops!</strong> Please check your fields below.
              </MDBAlert>
            ) : null}
          </MDBCol>
        </MDBRow>

        {/* Email already in use */}
        {this.props.registerError != "" ? (
          <div>
            <MDBAlert color='danger' className='mt-5' dismiss>
              <strong>Oops!</strong> {this.props.registerError}
            </MDBAlert>
          </div>
        ) : null}

        {/* Password incorrect */}
        {this.state.passwordError != false ? (
          <div>
            <MDBAlert color='danger' className='mt-5' id='errorMessage'>
              <strong>Oops!</strong> please ensure your password meets the
              requirements and try again.
            </MDBAlert>
          </div>
        ) : null}

        <MDBRow className='login-form-container white-curved-tile'>
          <MDBCol md='12'>
            <form id='register'>
              <div className='grey-text'>
                <MDBInput
                  label='Firstname'
                  group
                  type='text'
                  validate
                  id='firstName'
                  value={this.state.firstName}
                  onChange={this.handleChange("firstName")}
                  required
                />
                <MDBInput
                  label='Lastname'
                  group
                  type='text'
                  validate
                  id='lastName'
                  value={this.state.lastName}
                  onChange={this.handleChange("lastName")}
                  required
                />
                <MDBInput
                  label='Email'
                  group
                  type='email'
                  validate
                  id='username'
                  value={this.state.username}
                  onChange={this.handleChange("username")}
                  required
                />
                <MDBRow className='align-items-baseline'>
                  {/* Help alert */}
                  <div id='helpAlert'>
                    {this.state.passwordTip == true ? (
                      <MDBAlert className='help-alert' dismiss>
                        <MDBRow>
                          <MDBCol size='2' className='align-self-center'>
                            <img
                              src={require("../../../images/global/bullet-large.png")}
                            />
                          </MDBCol>
                          <MDBCol size='10'>
                            <p>
                              Your password must be:
                              <ul>
                                <li>Between 8-30 characters long.</li>
                                <li>At least 1 uppercase letter.</li>
                                <li>At least 1 lowercase letter.</li>
                                <li>
                                  At least 1 special character (#?!@$%^&*-.).
                                </li>
                                <li>At least 1 number.</li>
                              </ul>
                            </p>
                          </MDBCol>
                        </MDBRow>
                      </MDBAlert>
                    ) : null}
                  </div>
                  <MDBCol size='11'>
                    <MDBInput
                      label='Create password'
                      group
                      type='password'
                      pattern='^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.€]).{8,}$'
                      validate
                      id='password'
                      value={this.state.password}
                      onChange={this.handleChange("password")}
                      required
                    />
                  </MDBCol>
                  <MDBCol size='1'>
                    <MDBBtn className='tooltip-container'>
                      <MDBIcon
                        icon='info-circle'
                        onClick={() =>
                          this.setState({
                            passwordTip: !this.state.passwordTip,
                          })
                        }
                      />
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </div>
              <MDBBtn onClick={() => this.handleSubmit()} className='btn'>
                Register
              </MDBBtn>
              <div>
                <a
                  href={`/${this.state.jurisdiction}/account/login`}
                  className='forgot-password'
                >
                  Already have an account? Sign in
                </a>
              </div>
            </form>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    token: store.signin.token,
    error: store.signin.error,
    isLoading: store.signin.isLoading,
    registerError: store.signin.registerError,
  };
};

const actionCreators = {
  register: signinActions.register,
};

export default connect(mapStateToProps, actionCreators)(RegisterForm);
