import { param } from "jquery";
import { keypadConstants } from "../constants";

let defaultState = {
  keypadData: [],
  isLoading: false,
  secret:'',
  id:'',
  intentId:'',
  kpn:'',
  email:'',
};

const keypadReducer = (state = defaultState, action) => {
  switch (action.type) {
    // Get All Posts
    case keypadConstants.GET_ALL_KEYPADTOPS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case keypadConstants.GET_ALL_KEYPADTOPS_REQUEST_SUCCESS:
      return {
        keypadData: action.payload,
      };
    case keypadConstants.GET_ALL_KEYPADTOPS_REQUEST_FAILURE:
      return {};
    case keypadConstants.KEYPADVEND_INTENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case keypadConstants.KEYPADVEND_INTENT_REQUEST_SUCCESS:
      // var resp = {
      //   secret:action.payload.dbData.intent.clientSecret,
      //   id:action.payload.dbData.id,
      //   intentId:action.payload.dbData.intent.id,
      //   kpn:action.payload.kpn,
      //   email:action.payload.email
      // };

      return {
        ...state,
        secret: action.payload.dbData.intent.clientSecret,
        id: action.payload.dbData.id,
        intentId: action.payload.dbData.intent.id,
        kpn: action.payload.kpn,
        email: action.payload.email
      };
    case keypadConstants.KEYPADVEND_INTENT_REQUEST_FAILURE:
      return {};
    default:
      return state;
  }
};

export default keypadReducer;
