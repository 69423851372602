// import { signinService } from "../services";
import { paymentConstants } from "../constants";
import { history } from "../helpers";
import { paymentService } from "../services";

export const paymentActions = {
  increment,
  decrement,
  createPaymentSession,
  savePayment,
  getPaymentHistory,
};

// Increment Topup Amount (Dashboard)
function increment(num) {
  return {
    type: paymentConstants.INCREMENT_TOPUP,
    payload: num,
  };
}

// Decrement Topup Amount (Dashboard)
function decrement(num) {
  return {
    type: paymentConstants.DECCREMENT_TOPUP,
    payload: num,
  };
}

// Create
function createPaymentSession(details) {
  return (dispatch) => {
    // Request
    dispatch(createPaymentSessionRequest(details));

    // dispatch(success(details));

    paymentService.createPayment(details).then(
      (response) => {
        dispatch(createPaymentSessionSuccess(response.data));
      },
      (error) => {
        dispatch(createPaymentSessionFailure([error.response.data.message]));
      }
    );
  };

  function createPaymentSessionRequest(dbData) {
    return {
      type: paymentConstants.CREATE_PAYMENTSESSION_REQUEST,
      payload: dbData,
    };
  }

  function createPaymentSessionSuccess(responseData) {
    return {
      type: paymentConstants.CREATE_PAYMENTSESSION_SUCCESS,
      payload: responseData,
    };
  }

  function createPaymentSessionFailure(error) {
    return {
      type: paymentConstants.CREATE_PAYMENTSESSION_FAILURE,
      payload: error,
    };
  }
}

// Save payment
function savePayment(details) {
  return (dispatch) => {
    // Request
    dispatch(savePaymentRequest(details));

    // dispatch(success(details));
    paymentService.savePaymentService(details).then(
      (response) => {
        dispatch(savePaymentSuccess(response.data));
      },
      (error) => {
        dispatch(savePaymentFailure([error.response.data.message]));
      }
    );
  };

  function savePaymentRequest(dbData) {
    return {
      type: paymentConstants.SAVE_PAYMENT_REQUEST,
      payload: dbData,
    };
  }

  function savePaymentSuccess(responseData) {
    return {
      type: paymentConstants.SAVE_PAYMENT_SUCCESS,
      payload: responseData,
    };
  }

  function savePaymentFailure(error) {
    return {
      type: paymentConstants.SAVE_PAYMENT_FAILURE,
      payload: error,
    };
  }
}

function getPaymentHistory(id, juris) {
  return (dispatch) => {
    // Request
    dispatch(getPaymentHistoryRequest(id, juris));
    // dispatch(success(details));
    paymentService.getPaymentHistory(id, juris).then(
      (response) => {
        dispatch(getPaymentHistorySuccess(response.data));
      },
      (error) => {
        dispatch(getPaymentHistoryFailure(error));
      }
    );
  };

  function getPaymentHistoryRequest(dbData) {
    return {
      type: paymentConstants.GET_PAYMENTHISTORY_REQUEST,
      payload: dbData,
    };
  }

  function getPaymentHistorySuccess(responseData) {
    return {
      type: paymentConstants.GET_PAYMENTHISTORY_SUCCESS,
      payload: responseData,
    };
  }

  function getPaymentHistoryFailure(error) {
    return {
      type: paymentConstants.GET_PAYMENTHISTORY_FAILURE,
      payload: error,
    };
  }
}
