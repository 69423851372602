import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";

export const PaymentSuccess = (props) => {
  const [vendCode, setVendCode] = useState();
  const [amount, setAmount] = useState();

  useEffect(() => {
    let code = props.vc.match(/.{1,4}/g);
    code = code.join("-");
    setVendCode(code);
    setAmount(props.topupAmount);
  }, []);

  return (
    <div>
      <MDBRow className="text-center">
        <MDBCol>
          <MDBRow center>
            <MDBCol size="10">
              <p className="grey-background p-3 vend-code">{vendCode}</p>
            </MDBCol>
          </MDBRow>
          <img
            src={require("../../../../images/account/success.png")}
            style={{ width: "30%" }}
          />

          <p className="my-5">Your top up of £{amount} has been successful.</p>
          <p>
            We'll send an email to your account email address with the details.
          </p>
        </MDBCol>
      </MDBRow>

      <MDBRow center>
        <MDBCol size="6">
          <MDBBtn
            className="btn"
            onClick={() => {
              window.location.reload();
            }}
          >
            Continue
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </div>
  );
};
