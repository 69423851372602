import { faqService } from "../services";
import { faqConstants } from "../constants";

export const faqActions = {
  getFAQs,
  getFAQById,
  createFAQ,
  updateFAQ,
  deleteFAQ
};

// Get All
function getFAQs(juris) {
  return dispatch => {
    // Request
    dispatch(getFAQsRequest(juris));
    faqService.getFAQsService(juris).then(
      response => {
        dispatch(getFAQsSuccess(response.data));
      },
      error => {
        dispatch(getFAQsFailure([error.response.data.message]));
      }
    );
  };

  function getFAQsRequest(dbData) {
    return {
      type: faqConstants.GET_FAQ_REQUEST,
      payload: dbData
    };
  }

  function getFAQsSuccess(dbData) {
    return {
      type: faqConstants.GET_FAQ_SUCCESS,
      payload: dbData
    };
  }

  function getFAQsFailure(error) {
    return {
      type: faqConstants.GET_FAQ_FAILURE,
      payload: error
    };
  }
}

// Get By Id
function getFAQById(id) {
  return dispatch => {
    // Request
    dispatch(getFAQByIdRequest(id));

    faqService.getFAQByIdService(id).then(
      response => {
        dispatch(getFAQByIdSuccess(response.data));
      },
      error => {
        dispatch(getFAQByIdFailure([error.response.data.message]));
      }
    );
  };

  function getFAQByIdRequest(dbData) {
    return {
      type: faqConstants.GET_FAQ_BY_ID_REQUEST,
      payload: dbData
    };
  }

  function getFAQByIdSuccess(dbData) {
    return {
      type: faqConstants.GET_FAQ_BY_ID_SUCCESS,
      payload: dbData
    };
  }

  function getFAQByIdFailure(error) {
    return {
      type: faqConstants.GET_FAQ_BY_ID_FAILURE,
      payload: error
    };
  }
}

// Create
function createFAQ(formData) {
  return dispatch => {
    // Request
    dispatch(createFAQRequest(formData));

    faqService.createFAQService(formData).then(
      response => {
        dispatch(createFAQSuccess(response.data));
        alert("FAQ Created successfully!");
        window.location.href = "/faq/viewfaq";
      },
      error => {
        dispatch(createFAQFailure([error.response.data.message]));
      }
    );
  };

  function createFAQRequest(dbData) {
    return {
      type: faqConstants.CREATE_FAQ_REQUEST,
      payload: dbData
    };
  }

  function createFAQSuccess(dbData) {
    return {
      type: faqConstants.CREATE_FAQ_SUCCESS,
      payload: dbData
    };
  }

  function createFAQFailure(error) {
    return {
      type: faqConstants.CREATE_FAQ_FAILURE,
      payload: error
    };
  }
}

// Update
function updateFAQ(editData) {
  return dispatch => {
    // Request
    dispatch(updateFAQRequest(editData));

    faqService.updateFAQService(editData).then(
      response => {
        dispatch(updateFAQSuccess(response.data));
        alert("FAQ Updated successfully!");
        window.location.href = "/faq/viewfaq";
      },
      error => {
        dispatch(updateFAQFailure([error.response.data.message]));
      }
    );
  };

  function updateFAQRequest(dbData) {
    return {
      type: faqConstants.UPDATE_FAQ_REQUEST,
      payload: dbData
    };
  }

  function updateFAQSuccess(dbData) {
    return {
      type: faqConstants.UPDATE_FAQ_SUCCESS,
      payload: dbData
    };
  }

  function updateFAQFailure(error) {
    return {
      type: faqConstants.UPDATE_FAQ_FAILURE,
      payload: error
    };
  }
}

// Delete
function deleteFAQ(id) {
  return dispatch => {
    // Request
    dispatch(deleteFAQRequest(id));

    faqService.deleteFAQService(id).then(
      response => {
        dispatch(deleteFAQSuccess(response.data));
        window.location.reload();
      },
      error => {
        dispatch(deleteFAQFailure([error.response.data.message]));
      }
    );
  };

  function deleteFAQRequest(dbData) {
    return {
      type: faqConstants.DELETE_FAQ_REQUEST,
      payload: dbData
    };
  }

  function deleteFAQSuccess(dbData) {
    return {
      type: faqConstants.DELETE_FAQ_SUCCESS,
      payload: dbData
    };
  }

  function deleteFAQFailure(error) {
    return {
      type: faqConstants.DELETE_FAQ_FAILURE,
      payload: error
    };
  }
}
