import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBInput, MDBAlert, MDBBtn } from "mdbreact";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { sessionExpired } from "../../../../redux/helpers/sessionExpired";
import { AgencyPaymentSuccess } from "./AgencyPaymentSuccess";
import { AgencyPaymentFailure } from "./AgencyPaymentFailure";
import Loader from "../../../../components/Shared/Loader";
const LoadConfig = require("../../../../config");
const config = LoadConfig.default;

// Stripe card input
const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#31e51a",
      color: "#001d28",
      // fontWeight: 500,
      fontFamily: "Montserrat, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#757575" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

export const AgencyPaymentDetails = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const stripe = useStripe();
  const elements = useElements();
  const [jurisdiction, setJurisdiction] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(true);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showPaymentFailure, setShowPaymentFailure] = useState(false);

  useEffect(() => {
    sessionExpired();

    setJurisdiction(props.values.jurisdiction);
  }, []);

  async function onSubmit(data) {
    setShowLoader(true);
    const card = elements.getElement(CardElement);
    const billingAddress = {
      line1: data.address,
      postal_code: data.postcode,
    };

    let clSec = props.values.secret;
    let clIntent = props.values.paymentIntent;

    const result = await stripe.confirmCardPayment(clSec, {
      payment_method: {
        card,
        billing_details: {
          name: data.name,
          address: billingAddress,
          email: data.email,
        },
      },
    });

    if (result.error) {
      // Inform the user if there was an error.
      setShowPaymentDetails(false);
      setShowPaymentSuccess(false);
      setShowPaymentFailure(true);
      setShowLoader(false);
    } else {
      await stripeTokenHandler(clIntent);
    }
  }

  // POST the token ID to your backend
  async function stripeTokenHandler(clIntent) {
    const response = await fetch(
      `${config.apiUrl}/payments/capture/${clIntent}/${jurisdiction}`,
      {
        method: "POST",
      }
    );
    if (response.status == 200) {
      setShowPaymentDetails(false);
      setShowPaymentSuccess(true);
      setShowPaymentFailure(false);
      setShowLoader(false);
    }
  }

  return (
    <div className="formInput my-3">
      {showLoader ? <Loader /> : null}

      {showPaymentDetails ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <MDBRow center>
            <MDBCol size="8">
              {/* Name */}
              <input
                placeholder="Name"
                {...register("name", {
                  value: props.values.name,
                })}
              />
              {errors.name ? (
                <p className="errorMessage">Please enter your Full Name.</p>
              ) : null}

              {/* Email */}
              <input
                placeholder="Email"
                {...register("email", {
                  value: props.values.email,
                })}
              />
              {errors.email ? (
                <p className="errorMessage">Please enter an Email Address.</p>
              ) : null}

              {/* Address */}
              <input
                placeholder="Address"
                {...register("address", {
                  required: true,
                })}
              />
              {errors.address ? (
                <p className="errorMessage">Please enter an Address.</p>
              ) : null}

              {/* City */}
              <input
                placeholder="City"
                {...register("city", {
                  required: true,
                })}
              />
              {errors.city ? (
                <p className="errorMessage">Please enter a City.</p>
              ) : null}

              {/* Postcode */}
              <input
                placeholder="Postcode"
                {...register("postcode", {
                  required: true,
                })}
              />
              {errors.postcode ? (
                <p className="errorMessage">
                  Please enter a Postcode or Eircode.
                </p>
              ) : null}

              <CardElement
                id="card-element"
                options={CARD_OPTIONS}
                // onChange={(e) => setStripeDetails(e.target.value)}
                className="w-100 card-form-control mt-4"
              />

              <MDBRow center className="my-3">
                <MDBBtn type="submit">Confirm payment</MDBBtn>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </form>
      ) : null}

      {/* Payment success */}
      {showPaymentSuccess ? <AgencyPaymentSuccess id={props.values.paymentIntent} /> : null}

      {/* Payment failure  */}
      {showPaymentFailure ? <AgencyPaymentFailure /> : null}
    </div>
  );
};
