import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";

export const PaymentSuccess = (props) => {
  const jurisdiction = "ni";
  const [page, setPage] = useState();
  const { nextStep } = props;

  useEffect(() => {
    let url = window.location.href;
    let page = url.split("/");
    page = page[5];
    setPage(page);
  }, []);

  return (
    <div>
      <MDBRow className='my-3'>
        <MDBCol>
          <h4>Payment Success</h4>
        </MDBCol>
      </MDBRow>
      <MDBRow className='text-center'>
        <MDBCol>
          <img
            src={require("../../../../../images/account/success.png")}
            style={{ width: "30%" }}
          />

          <p className='my-5'>Your payment of £125.00 has been successful.</p>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size='3' />
        {/* Next (Switch summary) */}
        <MDBCol size='6'>
          <h5>
            Next Step: <span>Switch Summary</span>
          </h5>
          {page != "securitydeposit" ? (
            <MDBBtn className='btn' onClick={nextStep}>
              Continue
            </MDBBtn>
          ) : (
            <a href={`/${jurisdiction}/switch/summary`}>
              <MDBBtn className='btn'>Continue</MDBBtn>
            </a>
          )}
        </MDBCol>
        <MDBCol size='3' />
      </MDBRow>
    </div>
  );
};
