import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBAlert,
  MDBInput,
  MDBIcon,
} from "mdbreact";
import $ from "jquery";
import LiveChat from "react-livechat";
import Loader from "../../../Shared/Loader";

// Redux
import { connect } from "react-redux";
import { switchActions } from "../../../../redux/actions/switchActions";

// Select Data
import {
  SpecialServiceData,
  MedicalEquipmentData,
} from "../../../../helpers/SelectData";
import Select from "react-select";

export class _FormAdditionalServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceCheck: "",
      PDC: "",
      specialServices: "",
      requiredSpecialServices: "",
      medicalServices: "",
      requiredMedicalServices: "",
      priorityRegister: "",
      //nom person
      nomPerson: "",
      nomCorrespondance: "true",
      nomTitle: "",
      nomFirstName: "",
      nomSurname: "",
      nomEmail: "",
      nomPhone: "",
      nomPostcode: "",
      nomAddrLine1: "",
      nomAddrLine2: "",
      nomCity: "",
      nomCounty: "",
      medicalEquipment: "",
      loading: false,

      // Error Handeling
      errorMessage: false,
      nomTip: false,
      specialServicesRequired: false,
      nomDetailsRequired: false,
      switchGot: false,
      jurisdiction: "roi",
      homeCheck: false,

      // Required fields
      addServicesRequired: false,
      nomPersonRequired: false,
    };
  }

  // Page Load
  componentDidMount() {
    let switchID = localStorage.getItem("switchID");

    if (switchID != null) {
      this.props.getSwitch(switchID);
      this.setState({ switchGot: true });
    }
  }

  // If Props.SwitchData true populate form
  async componentWillReceiveProps(nextProps) {
    let sectionUpdated = JSON.parse(localStorage.getItem("servicesUpdated"));

    if (this.state.switchGot && sectionUpdated) {
      await this.setState({
        serviceCheck: nextProps.journeyData.serviceCheck,
        PDC: nextProps.journeyData.pdc,
        specialServices: nextProps.journeyData.specialServices,
        requiredSpecialServices: JSON.parse(
          nextProps.journeyData.requiredSpecialServices
        ),
        medicalServices: nextProps.journeyData.medicalServices,
        requiredMedicalServices: JSON.parse(
          nextProps.journeyData.requiredMedicalServices
        ),
        priorityRegister: nextProps.journeyData.priorityRegister,
        nomPerson: nextProps.journeyData.nomPerson,
        nomCorrespondance: nextProps.journeyData.nomCorrespondance,
        nomTitle: nextProps.journeyData.nomTitle,
        nomFirstName: nextProps.journeyData.nomFirstName,
        nomSurname: nextProps.journeyData.nomSurname,
        nomEmail: nextProps.journeyData.nomEmail,
        nomPhone: nextProps.journeyData.nomPhone,
        nomPostcode: nextProps.journeyData.nomPostcode,
        nomAddrLine1: nextProps.journeyData.nomAddrLine1,
        nomAddrLine2: nextProps.journeyData.nomAddrLine2,
        nomCity: nextProps.journeyData.nomCity,
        nomCounty: nextProps.journeyData.nomCounty,
      });
    }

    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Check if additional services are needed
  serviceCheck = (required) => {
    if (required) {
      this.setState({
        serviceCheck: true,
        PDC: "",
        specialServices: "",
        requiredSpecialServices: "",
        medicalServices: "",
        requiredMedicalServices: "",
        priorityRegister: "",
        nomPerson: "",
        addServicesRequired: true,
        nomPersonRequired: true,
      });
    } else {
      this.setState({
        serviceCheck: false,
        PDC: false,
        specialServices: false,
        requiredSpecialServices: false,
        medicalServices: false,
        requiredMedicalServices: false,
        priorityRegister: false,
        nomPerson: false,
        addServicesRequired: false,
        nomPersonRequired: false,
      });
    }
  };

  // Handle Field Change
  handleChange = (input) => (e) => {
    var fieldValue = $("#" + input)[0].checkValidity();

    if (fieldValue) {
      $("#" + input)
        .addClass("input-valid")
        .removeClass("input-error");
    } else {
      $("#" + input)
        .addClass("input-error")
        .removeClass("input-valid");
    }

    this.setState({ [input]: e.target.value });
  };

  SpecialServicesChange = (specialServicesOption) => {
    this.setState({
      requiredSpecialServices: specialServicesOption,
    });

    console.log("Option selected:", specialServicesOption);
  };

  MedicalEquipmentChange = (medicalEquipmentOption) => {
    this.setState({
      requiredMedicalServices: medicalEquipmentOption,
    });

    console.log("Option selected:", medicalEquipmentOption);
  };

  specialServicesRequired = (required) => {
    if (required) {
      this.setState({ specialServicesRequired: true });
    } else {
      this.setState({ specialServicesRequired: false });
    }
  };

  nomDetailsRequired = (required) => {
    if (required) {
      this.setState({
        nomDetailsRequired: true,
      });
    } else {
      this.setState({
        nomDetailsRequired: false,
      });
    }
  };

  // Continue to next section
  nextStep = (e) => {
    // Prevent from from clearing
    e.preventDefault();

    // Validate Form
    let validation = $("#additionalServices")[0].checkValidity();

    if (validation) {
      // Form Data
      const data = {
        // Step One - (Account Details)
        accountHolder: this.props.journeyData.accountHolder,
        title: this.props.journeyData.title,
        firstName: this.props.journeyData.firstName,
        surname: this.props.journeyData.surname,
        dob: this.props.journeyData.dob,
        email: this.props.journeyData.email,
        phone: this.props.journeyData.phone,
        postcode: this.props.journeyData.postcode,
        addrLine1: this.props.journeyData.addrLine1,
        addrLine2: this.props.journeyData.addrLine2,
        city: this.props.journeyData.city,
        county: this.props.journeyData.county,
        mprn: this.props.journeyData.mprn,
        // Smart meter
        smartCustomer: this.props.journeyData.smartCustomer,
        halfHourly: this.props.journeyData.halfHourly,
        // Account Correspondance
        accountCorrespondence: this.props.journeyData.accountCorrespondence,
        accCorrTitle: this.props.journeyData.accCorrTitle,
        accCorrFirstName: this.props.journeyData.accCorrFirstName,
        accCorrSurname: this.props.journeyData.accCorrSurname,
        accCorrName: this.props.journeyData.accCorrName,
        accCorrPostcode: this.props.journeyData.accCorrPostcode,
        accCorrAddLineOne: this.props.journeyData.accCorrAddLineOne,
        accCorrAddLineTwo: this.props.journeyData.accCorrAddLineTwo,
        accCorrCity: this.props.journeyData.accCorrCity,
        accCorrCounty: this.props.journeyData.accCorrCounty,
        accCorrEmail: this.props.journeyData.accCorrEmail,
        accCorrAddPhone: this.props.journeyData.accCorrAddPhone,
        // Joint Account
        jointPerson: this.props.journeyData.jointPerson,
        jointTitle: this.props.journeyData.jointTitle,
        jointFirstName: this.props.journeyData.jointFirstName,
        jointSurname: this.props.journeyData.jointSurname,
        jointEmail: this.props.journeyData.jointEmail,
        jointPhone: this.props.journeyData.jointPhone,
        // Marketing
        markEmail: this.props.journeyData.markEmail,
        markSMS: this.props.journeyData.markSMS,
        markPost: this.props.journeyData.markPost,
        markPhone: this.props.journeyData.markPost,

        // Step Two - Your Electricity
        newConnection: this.props.journeyData.newConnection,
        meterReading: this.props.journeyData.meterReading,
        nightMeterReading: this.props.journeyData.nightMeterReading,

        // Step Three (This Page) - Additional Services
        serviceCheck: this.state.serviceCheck,
        PDC: this.state.PDC,
        specialServices: this.state.specialServices,
        requiredSpecialServices: JSON.stringify(
          this.state.requiredSpecialServices
        ),
        medicalServices: this.state.medicalServices,
        requiredMedicalServices: JSON.stringify(
          this.state.requiredMedicalServices
        ),
        priorityRegister: this.state.priorityRegister.toString(),
        //nom person
        nomPerson: this.state.nomPerson,
        nomCorrespondance: this.state.nomCorrespondance,
        nomTitle: this.state.nomTitle,
        nomFirstName: this.state.nomFirstName,
        nomSurname: this.state.nomSurname,
        nomEmail: this.state.nomEmail,
        nomPhone: this.state.nomPhone,
        nomPostcode: this.state.nomPostcode,
        nomAddrLine1: this.state.nomAddrLine1,
        nomAddrLine2: this.state.nomAddrLine2,
        nomCity: this.state.nomCity,
        nomCounty: this.state.nomCounty,

        // Step Four - Payment Details
        accountName: this.props.journeyData.accountName,
        bankAccountNo: this.props.journeyData.bankAccountNo,
        bankSortCode: this.props.journeyData.bankSortCode,
        billingMethod: this.props.journeyData.billingMethod,
        iban: this.props.journeyData.iban,
        DDAmount: this.props.journeyData.DDAmount,
        DDDayOfMonth: this.props.journeyData.DDDayOfMonth,
        bankAddressLineOne: this.props.journeyData.bankAddressLineOne,
        bankAddressLineTwo: this.props.journeyData.bankAddressLineTwo,
        bankAddressPostcode: this.props.journeyData.bankAddressPostcode,
        bankAddressCountry: this.props.journeyData.bankAddressCountry,
        confirmDDAuth: this.props.journeyData.confirmDDAuth,

        // Step Five - Switch Summary
        confirmDDAuth: this.props.journeyData.confirmDDAuth,
        agreeToTerms: this.props.journeyData.agreeToTerms,
        agreeToPrivacyPolicy: this.props.journeyData.agreeToPrivacyPolicy,
        switchCompleted: false,
      };

      // Hide Error Message (if visiable)
      this.setState({
        errorMessage: false,
      });

      // SwitchID
      let switchID = localStorage.getItem("switchID");

      // This Page (updates page status)
      let pageStatus = "servicesUpdated";

      // Next Page URL
      let nextPage = "payment";

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Database Entry
      this.props.updateSwitch(
        switchID,
        data,
        pageStatus,
        nextPage,
        "",
        jurisdiction
      );
    } else {
      // For each form input that has the required prop add the input-error class
      $("form#additionalServices")
        .find("input")
        .each(function () {
          if ($(this).prop("required")) {
            $(this).addClass("input-error");
          }
        });

      // Display the error message
      this.setState({
        errorMessage: true,
      });

      // Scroll to error
      window.location.href = "#errorMessage";
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  checkSmart = () => {
    let smartCustomer = localStorage.getItem("SmartCustomer");

    if (smartCustomer) {
      window.location.href = `/${this.state.jurisdiction}/switch/account`;
    } else {
      window.location.href = `/${this.state.jurisdiction}/switch/electricity/`;
    }
  };

  render() {
    return (
      <div className="quote-page switch-page">
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        <form id="additionalServices">
          {/* Background Image */}
          <div className="device-image" />
          {/* Logo */}
          <MDBRow className="text-center">
            <MDBCol lg="2" sm="12">
              <a onClick={() => this.setState({ homeCheck: true })}>
                <img
                  src={require("../../../../images/global/bright-logo-dark.png")}
                  className="quote-logo mx-5 mt-5"
                />
              </a>
            </MDBCol>
            <MDBCol size="10" />
          </MDBRow>

          {/* Home message */}
          {this.state.homeCheck ? (
            <div className="modalBG">
              <div className="modalBox">
                <div className="white-curved-tile text-center">
                  <h4 className="mt-5">
                    All progress will be lost! <br />
                    Are you sure?
                  </h4>
                  <MDBRow>
                    <MDBCol lg="6" md="6" sm="12">
                      <MDBBtn
                        className="cancel-button"
                        onClick={() =>
                          this.setState({ homeCheck: !this.state.homeCheck })
                        }
                      >
                        Cancel
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol lg="6" md="6" sm="12">
                      <MDBBtn onClick={() => (window.location.href = "/")}>
                        Continue
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
          ) : null}

          <div className="quote-content-container">
            <MDBContainer className="personal-details-section">
              <MDBRow>
                <MDBCol size="12">
                  <MDBRow>
                    {/* Progress Bar */}
                    <MDBCol md="12" className="switch-navigation-container">
                      <div className="border-light">
                        <img
                          className="brand-image show-for-medium"
                          src={require("../../../../images/quote/quote-nav-4.png")}
                        />
                        <img
                          className="brand-image hide-for-medium"
                          src={require("../../../../images/quote/progress-bar-4.png")}
                        />
                      </div>
                    </MDBCol>

                    {/* Error Message */}
                    <MDBCol size="12" id="errorMessage">
                      {this.state.errorMessage == true ? (
                        <MDBAlert color="danger" className="mb-5" dismiss>
                          <strong>Oops!</strong> Please check your fields below.
                        </MDBAlert>
                      ) : null}
                    </MDBCol>
                  </MDBRow>

                  {/* Additional Services Check */}
                  <MDBRow>
                    <MDBCol md="12" lg="8">
                      <div className="text-left white-curved-tile">
                        <h4>Do you require any additional services?</h4>
                        <div>
                          We offer additional services to those customers who
                          are criticially dependent on:
                          <br />
                          <b className="fomnt-wtight-bolder">
                            Electrically powered medical equipment
                          </b>
                          <br />
                          <br />
                          Require additional services due to:
                          <br />
                          <b className="fomnt-wtight-bolder">
                            Advanced age, physical or mental health
                            difficulties.
                          </b>
                          <br />
                          <br />
                        </div>
                        <div className="radio-container">
                          {/* Yes */}
                          <input
                            id="serviceCheckYes"
                            name="serviceCheck"
                            className="default-radio"
                            type="radio"
                            onClick={() => this.serviceCheck(true)}
                            required
                            checked={this.state.serviceCheck ? true : false}
                          />
                          <label for="serviceCheckYes">Yes</label>
                          {/* No */}
                          <input
                            id="serviceCheckNo"
                            name="serviceCheck"
                            className="default-radio"
                            type="radio"
                            onClick={() => this.serviceCheck(false)}
                            checked={
                              this.state.serviceCheck === false ? true : false
                            }
                          />
                          <label for="serviceCheckNo">No</label>
                        </div>
                      </div>

                      {/* Additional Services */}

                      {/* Hide unless additional services = true  */}
                      {this.state.serviceCheck ? (
                        <div className="text-left white-curved-tile">
                          <h4>Additional Services</h4>
                          <p className="input-label">
                            Are you of pensionable age, disabled or chronically
                            ill?
                          </p>
                          <div className="radio-container">
                            {/* Yes */}
                            <input
                              id="PensionYes"
                              name="Pension"
                              className="default-radio"
                              type="radio"
                              onClick={() => this.setState({ PDC: true })}
                              required={this.state.addServicesRequired}
                              checked={this.state.PDC ? true : false}
                            />
                            <label for="PensionYes">Yes</label>
                            {/* No */}
                            <input
                              id="PensionNo"
                              name="Pension"
                              className="default-radio"
                              type="radio"
                              onClick={() => this.setState({ PDC: false })}
                              checked={this.state.PDC === false ? true : false}
                            />
                            <label for="PensionNo">No</label>
                          </div>

                          {/* Special Services */}
                          <p className="input-label">
                            Do you require special services?
                          </p>
                          <div className="radio-container">
                            {/* Yes */}
                            <input
                              id="SpecialServicesYes"
                              name="SpecialServices"
                              className="default-radio"
                              type="radio"
                              onClick={() => {
                                this.setState({ specialServices: true });
                                this.specialServicesRequired(true);
                              }}
                              required={this.state.addServicesRequired}
                              checked={
                                this.state.specialServices ? true : false
                              }
                            />
                            <label for="SpecialServicesYes">Yes</label>
                            {/* No */}
                            <input
                              id="SpecialServicesNo"
                              name="SpecialServices"
                              className="default-radio"
                              type="radio"
                              onClick={() => {
                                this.setState({
                                  specialServices: false,
                                });
                                this.specialServicesRequired(false);
                              }}
                              checked={
                                this.state.specialServices === false
                                  ? true
                                  : false
                              }
                            />
                            <label for="SpecialServicesNo">No</label>
                          </div>

                          {/* Special Services Select */}
                          {/* only show when special services is true */}
                          {this.state.specialServices != false ? (
                            <MDBRow>
                              <MDBCol size="12" className="p-0">
                                <label className="input-label">
                                  Please select the required services
                                </label>

                                <Select
                                  isMulti
                                  onChange={this.SpecialServicesChange}
                                  options={SpecialServiceData}
                                  className="multiselect multiselect-width"
                                  placeholder="You can have multiple selections"
                                  required={this.state.specialServicesRequired}
                                  value={this.state.requiredSpecialServices}
                                />
                              </MDBCol>
                            </MDBRow>
                          ) : null}

                          {/* Medical Services */}
                          <p className="input-label">
                            Do you require any medical services?
                          </p>
                          <div className="radio-container">
                            {/* Yes */}
                            <input
                              id="MedicalServicesYes"
                              name="MedicalServices"
                              className="default-radio"
                              type="radio"
                              onClick={() =>
                                this.setState({ medicalServices: true })
                              }
                              required={this.state.addServicesRequired}
                              checked={
                                this.state.medicalServices ? true : false
                              }
                            />
                            <label for="MedicalServicesYes">Yes</label>
                            {/* No */}
                            <input
                              id="MedicalServicesNo"
                              name="MedicalServices"
                              className="default-radio"
                              type="radio"
                              onClick={() =>
                                this.setState({ medicalServices: false })
                              }
                              checked={
                                this.state.medicalServices === false
                                  ? true
                                  : false
                              }
                            />
                            <label for="MedicalServicesNo">No</label>
                          </div>
                          {/* Medical Services Select */}
                          {/* only show when medical services is true */}
                          {this.state.medicalServices != false ? (
                            <div>
                              <p className="input-label">
                                Please select the required services
                              </p>
                              <MDBRow>
                                <MDBCol size="12" className="p-0">
                                  <Select
                                    onChange={this.MedicalEquipmentChange}
                                    options={MedicalEquipmentData}
                                    className="multiselect multiselect-width"
                                    placeholder="Please select an option"
                                    value={this.state.requiredMedicalServices}
                                  />
                                </MDBCol>
                              </MDBRow>
                            </div>
                          ) : null}

                          {/* Priority Register */}
                          <p className="input-label">
                            Are you on the priority register?
                          </p>
                          <div className="radio-container">
                            {/* Yes */}
                            <input
                              id="priorityYes"
                              name="priorityRegister"
                              className="default-radio"
                              type="radio"
                              onClick={() =>
                                this.setState({ priorityRegister: true })
                              }
                              required={this.state.addServicesRequired}
                              checked={
                                this.state.priorityRegister ? true : false
                              }
                            />
                            <label for="priorityYes">Yes</label>
                            {/* No */}
                            <input
                              id="priorityNo"
                              name="priorityRegister"
                              className="default-radio"
                              type="radio"
                              onClick={() =>
                                this.setState({
                                  priorityRegister: false,
                                })
                              }
                              checked={
                                this.state.priorityRegister === false
                                  ? true
                                  : false
                              }
                            />
                            <label for="priorityNo">No</label>
                          </div>
                        </div>
                      ) : null}

                      {/* Nominated Person */}
                      {this.state.serviceCheck ? (
                        <div className="white-curved-tile text-left">
                          <h4>Nominated Person</h4>
                          <div>
                            <p className="input-label">
                              Would you like to appoint a nominated person to
                              your account?
                              {/* Tooltip */}
                              <MDBBtn className="tooltip-container">
                                <MDBIcon
                                  icon="info-circle"
                                  className="mr-2"
                                  onClick={() =>
                                    this.setState({
                                      nomTip: !this.state.nomTip,
                                    })
                                  }
                                />
                                {this.state.nomTip != false ? (
                                  <span className="tooltip-content">
                                    <img
                                      className="brand-image"
                                      src={require("../../../../images/global/bullet-large.png")}
                                    />
                                    <p>
                                      You can select someone you know to help
                                      manage your account. This means you give
                                      us permission to talk to that person about
                                      your account and send them copies of your
                                      bills and any correspondence. We'll need
                                      to contact them to get their permission
                                      also.
                                    </p>
                                  </span>
                                ) : null}
                              </MDBBtn>
                            </p>
                            {/* Nonminated Person Radio Buttons */}
                            <div className="radio-container">
                              {/* Yes */}
                              <input
                                id="nominatedPersonYes"
                                name="nominatedPerson"
                                type="radio"
                                onClick={() => {
                                  this.setState({ nomPerson: true });
                                  this.setState({
                                    nomDetailsRequired: true,
                                  });
                                }}
                                required={this.state.nomPersonRequired}
                                className="default-radio"
                                checked={this.state.nomPerson ? true : false}
                              />
                              <label for="nominatedPersonYes">Yes</label>
                              {/* No */}
                              <input
                                id="nominatedPersonNo"
                                name="nominatedPerson"
                                type="radio"
                                onClick={() => {
                                  this.setState({ nomPerson: false });
                                  this.setState({
                                    nomDetailsRequired: false,
                                  });
                                }}
                                className="default-radio"
                                checked={
                                  this.state.nomPerson === false ? true : false
                                }
                              />
                              <label htmlFor="nominatedPersonNo">No</label>
                            </div>
                          </div>

                          {this.state.nomPerson != false ? (
                            <div>
                              <h4>Nominated Person's Information</h4>
                              <div>
                                <MDBRow>
                                  <MDBCol lg="12" className="p-0">
                                    <label className="input-label">Title</label>
                                    <select
                                      id="nomTitle"
                                      pattern="[A-Za-z]{1,32}"
                                      className="browser-default custom-select mt-3"
                                      onChange={this.handleChange("nomTitle")}
                                      className="browser-default custom-select"
                                      required={this.state.nomDetailsRequired}
                                      value={this.state.nomTitle}
                                    >
                                      <option>Title</option>
                                      <option value="Mr">Mr</option>
                                      <option value="Mrs">Mrs</option>
                                      <option value="Miss">Miss</option>
                                      <option value="Ms">Ms</option>
                                      <option value="Dr">Dr</option>
                                      <option value="Prof">Prof</option>
                                    </select>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow className="mb-2">
                                  <MDBCol lg="12" className="text-left p-0">
                                    {/* Nom Firstname */}
                                    <label className="input-label">
                                      First Name
                                    </label>
                                    <MDBInput
                                      id="nomFirstName"
                                      pattern="^[A-Z a-z]{1,32}$"
                                      label="FirstName"
                                      outline
                                      onChange={this.handleChange(
                                        "nomFirstName"
                                      )}
                                      required={this.state.nomDetailsRequired}
                                      value={this.state.nomFirstName}
                                    />
                                    {/* Nom Surname */}
                                    <label className="input-label">
                                      Surname
                                    </label>
                                    <MDBInput
                                      id="nomSurname"
                                      pattern="^[A-Z a-z]{1,32}$"
                                      label="Surname"
                                      outline
                                      onChange={this.handleChange("nomSurname")}
                                      required={this.state.nomDetailsRequired}
                                      value={this.state.nomSurname}
                                    />
                                    {/* Nom Email */}
                                    <label className="input-label">
                                      Email Address
                                    </label>
                                    <MDBInput
                                      id="nomEmail"
                                      label="Email Address"
                                      pattern="^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                                      outline
                                      onChange={this.handleChange("nomEmail")}
                                      required={this.state.nomDetailsRequired}
                                      value={this.state.nomEmail}
                                    />
                                    {/* Nom Phone */}
                                    <label className="input-label">
                                      Phone Number
                                    </label>
                                    <MDBInput
                                      id="nomPhone"
                                      pattern="^[0-9]{10,15}$"
                                      label="Phone number"
                                      outline
                                      onChange={this.handleChange("nomPhone")}
                                      required={this.state.nomDetailsRequired}
                                      value={this.state.nomPhone}
                                    />
                                  </MDBCol>
                                </MDBRow>
                                {/* Nom Address */}
                                <h4>Nominated Person's Address</h4>
                                <MDBRow className="mb-2">
                                  <MDBCol lg="12" className="text-left p-0">
                                    {/* Nom Postcode */}
                                    <label className="input-label">
                                      Eircode
                                    </label>
                                    <MDBInput
                                      id="nomPostcode"
                                      label="Postcode"
                                      outline
                                      onChange={this.handleChange(
                                        "nomPostcode"
                                      )}
                                      value={this.state.nomPostcode}
                                      className="field-to-uppercase"
                                      pattern="([AC-FHKNPRTV-Y]\d{2}|D6W)[0-9AC-FHKNPRTV-Y]{4}"
                                    />
                                    {/* Nom Address Select */}
                                    {/* <label className='input-label'>
                                    Select your Property
                                  </label>
                                  <select className='browser-default custom-select'>
                                    <option>Select your address</option>
                                    <option value='1'>1 Bright Street</option>
                                    <option value='2'>2 Bright Street</option>
                                    <option value='3'>3 Bright Street</option>
                                    <option value='4'>4 Bright Street</option>
                                    <option value='5'>5 Bright Street</option>
                                  </select> */}
                                    {/* Nom Address Line One */}
                                    <label className="input-label">
                                      Address
                                    </label>
                                    <MDBInput
                                      id="nomAddrLine1"
                                      pattern="^[A-Z a-z 0-9]{1,32}$"
                                      label="Building Name/Number"
                                      outline
                                      onChange={this.handleChange(
                                        "nomAddrLine1"
                                      )}
                                      // required={this.state.nomDetailsRequired}
                                      value={this.state.nomAddrLine1}
                                    />
                                    {/* Nom Address Line Two */}
                                    <MDBInput
                                      id="nomAddrLine2"
                                      pattern="^[A-Z a-z 0-9]{1,32}$"
                                      label="Street"
                                      outline
                                      onChange={this.handleChange(
                                        "nomAddrLine2"
                                      )}
                                      required={this.state.nomDetailsRequired}
                                      value={this.state.nomAddrLine2}
                                    />
                                    {/* Nom City */}
                                    <MDBInput
                                      id="nomCity"
                                      pattern="^[A-Z a-z 0-9]{1,32}$"
                                      label="Town"
                                      outline
                                      onChange={this.handleChange("nomCity")}
                                      value={this.state.nomCity}
                                    />
                                    {/* Nom County */}
                                    <MDBInput
                                      id="nomCounty"
                                      pattern="^[A-Z a-z 0-9]{1,32}$"
                                      label="County"
                                      outline
                                      onChange={this.handleChange("nomCounty")}
                                      value={this.state.nomCounty}
                                    />
                                  </MDBCol>
                                </MDBRow>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}

                      {/* Buttons */}
                      <MDBRow size="12">
                        {/* Back */}
                        <MDBCol size="6">
                          <h5>
                            Previous Step: <span>Your Electricity</span>
                          </h5>
                          <a>
                            <MDBBtn
                              className="btn switch-button"
                              onClick={() => this.checkSmart()}
                            >
                              Back
                            </MDBBtn>
                          </a>
                        </MDBCol>
                        {/* Next */}
                        <MDBCol size="6">
                          <h5>
                            Next Step: <span>Payment Details</span>
                          </h5>
                          <MDBBtn className="btn" onClick={this.nextStep}>
                            Continue
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol size="4" className="show-for-large">
                      <img
                        className="brand-image"
                        src={require("../../../../images/switch/switch-tile.png")}
                      />

                      {/* Chat functionality */}
                      <div className="white-curved-tile text-left mt-3">
                        <h5 className="mt-4 text-center">Need some help?</h5>
                        {/* Live chat */}
                        <div className="switch-live-chat">
                          <div data-id="VVUGGBG3WTc" class="livechat_button">
                            <a href="https://www.livechat.com/utm-builder/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_11999496">
                              UTM Builder
                            </a>
                          </div>
                          <LiveChat license={11999496} />
                        </div>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBCol size="2" />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.switch.isLoading,
    journeyData: store.switch.switchData,
  };
};

const actionCreators = {
  getSwitch: switchActions.getSwitchJourney,
  updateSwitch: switchActions.updateSwitchJourney,
};

export default connect(
  mapStateToProps,
  actionCreators
)(_FormAdditionalServices);
