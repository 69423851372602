export const faqConstants = {
  // Get All
  GET_FAQ_REQUEST: "GET_FAQ_REQUEST",
  GET_FAQ_SUCCESS: "GET_FAQ_SUCCESS",
  GET_FAQ_FAILURE: "GET_FAQ_FAILURE",

  // Get By Id
  GET_FAQ_BY_ID_REQUEST: "GET_FAQ_BY_ID_REQUEST",
  GET_FAQ_BY_ID_SUCCESS: "GET_FAQ_BY_ID_SUCCESS",
  GET_FAQ_BY_ID_FAILURE: "GET_FAQ_BY_ID_FAILURE",

  // Create
  CREATE_FAQ_REQUEST: "CREATE_FAQ_REQUEST",
  CREATE_FAQ_SUCCESS: "CREATE_FAQ_SUCCESS",
  CREATE_FAQ_FAILURE: "CREATE_FAQ_FAILURE",

  // Update
  UPDATE_FAQ_REQUEST: "UPDATE_FAQ_REQUEST",
  UPDATE_FAQ_SUCCESS: "UPDATE_FAQ_SUCCESS",
  UPDATE_FAQ_FAILURE: "UPDATE_FAQ_FAILURE",

  // Delete
  DELETE_FAQ_REQUEST: "DELETE_FAQ_REQUEST",
  DELETE_FAQ_SUCCESS: "DELETE_FAQ_SUCCESS",
  DELETE_FAQ_FAILURE: "DELETE_FAQ_FAILURE"
};
