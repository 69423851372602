import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { sessionExpired } from "../../../../redux/helpers/sessionExpired";
import { Link } from "react-router-dom";

export const AgencyPaymentFailure = () => {
  const [jurisdiction, setJurisdiction] = useState("");
  const [phoneNo, setPhoneNo] = useState();

  useEffect(() => {
    sessionExpired();

    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    setJurisdiction(jurisdiction);

    if (jurisdiction == "roi") {
      setPhoneNo("01 588 1777");
    } else {
      setPhoneNo("028 95442290");
    }
  }, []);

  return (
    <div>
      <MDBRow center className="my-3">
        <MDBCol>
          <h4>Payment Unsuccessful</h4>
        </MDBCol>
      </MDBRow>
      <MDBRow center className="text-center">
        <MDBCol center>
          <img
            src={require("../../../../images/account/error.png")}
            style={{ width: "30%" }}
          />

          <p className="mt-5">
            <b>Sorry!</b> There has been an issue with your payment.
            <br />
            Please try again or give us a call on:
            <b> {phoneNo}</b>
          </p>
        </MDBCol>
      </MDBRow>

      <MDBRow center>
        <MDBCol size="6">
          <Link
            to={{
              pathname: `${jurisdiction}/account/landlorddashboard`,
            }}
          >
            <MDBBtn className="btn-teal">Try again</MDBBtn>
          </Link>
        </MDBCol>
      </MDBRow>
    </div>
  );
};
