import React, { useState, useEffect } from "react";
import {
    MDBRow,
    MDBCol,
    MDBBtn
} from "mdbreact";
// import Loader from "../../../../components/Shared/Loader";
import { useForm } from "react-hook-form";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { agencyActions } from "../../../redux/actions";

export const TenantMoveIn = () => {
    const dispatch = useDispatch();
    const agencyStore = useSelector((state) => state.agency)
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => {
        var dispatchData = data;
        dispatchData.meterReading = Number(data.meterReading);
        dispatchData.tenancyAgreement = tenancyAgreementVal;
        dispatchData.convenientContactTime = data.convenientTimeDate + " " + data.convenientTime;
        dispatch(agencyActions.tenantMoveIn(dispatchData))

    };
    const tenancyAgreement = watch("tenancyAgreement", "");
    const [tenancyAgreementVal, setTenancyAgreementVal] = useState();
    const [jurisdiction, setJurisdiction] = useState();

    useEffect(() => {
        let pathArray = window.location.pathname.split("/");
        let jurisdiction = pathArray[1];
        setJurisdiction(jurisdiction);
    }, []);

    useEffect(() => {
        if (tenancyAgreement === "Yes") {
            setTenancyAgreementVal(true);
        } else {
            setTenancyAgreementVal(false);
        }
    }, [tenancyAgreement]);

    return (
        <div>
            {agencyStore.tenantInSuccess ? (
                <div className="modalBG">
                    <div className="modalBox">
                        <div className="white-curved-tile text-center">
                            <h4>Your request has been submited successfully!</h4>
                            <MDBRow>
                                <MDBCol lg="12" md="12" sm="12">
                                    <MDBBtn onClick={() => window.location.reload()}>
                                        Ok
                        </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="formInput">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <MDBRow className='mb-6'>
                        <MDBCol lg='12' className='text-left p-0'>
                            <br /><strong><p>Tenant Details</p></strong>
                        </MDBCol>

                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Title</label>
                            <select {...register("title", { required: true })}>
                                <option value=""></option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Miss">Miss</option>
                                <option value="Ms">Ms</option>
                                <option value="Dr">Dr</option>
                                <option value="Prof">Prof</option>
                            </select>
                            {errors.title && <span>This field is required</span>}
                        </MDBCol>
                        <MDBCol lg='1'></MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>First Name</label>
                            <input {...register("firstName",                                
                                {
                                    required: true,
                                    pattern: /^[A-Z a-z]{2,32}$/g
                                })} />
                            {errors.firstName && <span>This field is required</span>}
                        </MDBCol>

                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Surname</label>
                            <input {...register("lastName", {
                                pattern:{ 
                                    value: /^[A-Z a-z]{2,32}$/g, 
                                    message: "Surname should only contain characters"
                                },
                                required: "This field is required"
                            })} />
                            {errors?.lastName?.message && <span>{errors?.lastName?.message}</span>}
                        </MDBCol>
                        <MDBCol lg='1'></MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Email</label>
                            <input
                                type="email"
                                {...register("email",
                                    {
                                        required: true,
                                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    })}
                            />
                            {errors.email && <span>This field is required</span>}
                        </MDBCol>

                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Phone Number</label>
                            <input {...register("phoneNum", {
                                required: "This field is required",
                                pattern: {
                                    value: /^[0-9]{11,14}$/g,
                                message: "Please enter between 11 and 14 digits"}
                            })} />
                            {errors?.phoneNum?.message && <span>{errors?.phoneNum?.message}</span>}
                        </MDBCol>
                        <MDBCol lg='1'></MDBCol>
                        {jurisdiction == "ni" ? (
                            <MDBCol lg='5' className='text-left p-0'>
                                <label>Post Code</label>
                                <input {...register("postCode", {
                                    pattern: {
                                        value: /^[A-Z a-z 0-9]{6,8}$/g, 
                                        message: "Please enter a valid postcode"
                                    },
                                    required: "This field is required"
                                })} />
                            {errors?.postCode?.message && <span>{errors?.postCode?.message}</span>}
                            </MDBCol>
                        ) : null}

                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Address</label>
                            <input {...register("address", {
                                required: true,
                                pattern: /^[A-Z a-z 0-9]{1,100}$/g
                            })} />
                            {errors.address && <span>This field is required</span>}
                        </MDBCol>
                        {jurisdiction == "ni" ? (<MDBCol lg='1'></MDBCol>) : null}
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>City</label>
                            <input {...register("city", {
                                pattern: /^[A-Z a-z 0-9]{1,100}$/g
                            })} />
                            {errors.city && <span>This field is required</span>}
                        </MDBCol>
                        <MDBCol lg='1'></MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>County</label>
                            <input {...register("county", {
                                required: true,
                                pattern: /^[A-Z a-z 0-9]{1,100}$/g
                            })} />
                            {errors.county && <span>This field is required</span>}
                        </MDBCol>
                        <MDBCol lg='1'></MDBCol>
                    </MDBRow>

                    <MDBRow className='mb-6'>
                        <MDBCol lg='12' className='text-left p-0'>
                            <br /><strong><p>Landlord Details</p></strong>
                        </MDBCol>

                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Name</label>
                            <input {...register("landlordName", { 
                                required: true ,
                                pattern: /^[A-Z a-z]{2,32}$/g
                                })} />
                            {errors.landlordName && <span>This field is required</span>}
                        </MDBCol>
                        <MDBCol lg='1'></MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Phone Number</label>
                            <input {...register("landlordPhoneNumber", { 
                                required: true ,
                                pattern: /^[0-9]{10,15}$/g
                                })} />
                            {errors.landlordPhoneNumber && <span>This field is required</span>}
                        </MDBCol>

                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Address</label>
                            <input {...register("landlordAddress", {
                                required: true,
                                pattern: /^[A-Z a-z 0-9]{1,100}$/g
                            })} />
                            {errors.landlordAddress && <span>This field is required</span>}
                        </MDBCol>
                        <MDBCol lg='1'></MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Post Code</label>
                            <input {...register("landlordPostcode", {
                                pattern: {
                                    value: /^[A-Z a-z 0-9]{6,8}$/g, 
                                    message: "Please enter a valid postcode"
                                },
                                required: "This field is required"
                            })} />
                            {errors?.landlordPostcode?.message && <span>{errors?.landlordPostcode?.message}</span>}
                        </MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>County</label>
                            <input {...register("landlordCounty", {
                                required: true,
                                pattern: /^[A-Z a-z 0-9]{1,100}$/g
                            })} />
                            {errors.landlordCounty && <span>This field is required</span>}
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='mb-6'>
                        {/* <MDBCol lg='1'></MDBCol> */}
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Tenancy Agreement Signed?</label>
                            <select
                                type="radio"
                                {...register("tenancyAgreement",
                                    {
                                        required: true
                                    })}
                                type="radio"
                            >
                                <option value=""></option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            {errors.tenancyAgreement && <span>This field is required</span>}
                        </MDBCol>


                        <MDBCol lg='1'></MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Meter Reading</label>
                            <input {...register("meterReading",
                                {
                                    required: true,
                                    maxLength: 5,
                                    pattern: /^[_0-9]{5}/g
                                })}
                            />
                            {errors.meterReading && <span>This field is required with a max length of 5 digits</span>}
                        </MDBCol>
                        <MDBCol lg='1'></MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Convenient Contact Time?</label>
                            <input
                                placeholder="01/01/1990"
                                type="date"
                                min={new Date().toISOString().slice(0, 10)}
                                {...register("convenientTimeDate", { required: true })}
                            />
                            {errors.meterReadingDate && <span>This field is required</span>}
                            <select {...register("convenientTime", { required: true })} >
                                <option value="08:00 - 09:00">08:00 - 09:00</option>
                                <option value="09:00 - 10:00">09:00 - 10:00</option>
                                <option value="10:00 - 11:00">10:00 - 11:00</option>
                                <option value="11:00 - 12:00">11:00 - 12:00</option>
                                <option value="12:00 - 13:00">12:00 - 13:00</option>
                                <option value="13:00 - 14:00">13:00 - 14:00</option>
                                <option value="14:00 - 15:00">14:00 - 15:00</option>
                                <option value="15:00 - 16:00">15:00 - 16:00</option>
                                <option value="16:00 - 17:00">16:00 - 17:00</option>
                                <option value="17:00 - 18:00">17:00 - 18:00</option>
                            </select>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow center className="mb-2">
                        <MDBCol lg="4">
                            <MDBBtn className="my-3" type="submit">
                                Submit
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </form>
            </div>
        </div>
    )
};