export const agencyConstants = {
    //Get product ref
    PRODUCT_REF_REQUEST: "PRODUCT_REF_REQUEST",
    PRODUCT_REF_SUCCESS: "PRODUCT_REF_SUCCESS",
    PRODUCT_REF_FAILURE: "PRODUCT_REF_FAILURE",

    //Add Property
    ADD_PROPERTY_REQUEST: "ADD_PROPERTY_REQUEST",
    ADD_PROPERTY_SUCCESS: "ADD_PROPERTY_SUCCESS",
    ADD_PROPERTY_FAILURE: "ADD_PROPERTY_FAILURE",

    //TENNT MOVE IN
    TENANT_MOVEIN_REQUEST: "TENANT_MOVEIN_REQUEST",
    TENANT_MOVEIN_SUCCESS: "TENANT_MOVEIN_SUCCESS",
    TENANT_MOVEIN_FAILURE: "TENANT_MOVEIN__FAILURE",

    //TENNT MOVE IN
    TENANT_MOVEOUT_REQUEST: "TENANT_MOVEOUT_REQUEST",
    TENANT_MOVEOUT_SUCCESS: "TENANT_MOVEOUT_SUCCESS",
    TENANT_MOVEOUT_FAILURE: "TENANT_MOVEOUT_FAILURE"
};