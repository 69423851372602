import React, { Component } from "react";
import { MDBCol, MDBRow, MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import { connect } from "react-redux";
import { quoteActions } from "../../../../../../redux/actions/quoteActions";
const LoadConfig = require("../../../../../../config");
const config = LoadConfig.default;

export class KeypadEco7 extends Component {
  state = {
    jurisdiction: "ni",
  };

  render() {
    return (
      <div>
        <MDBRow>
          <MDBCol size='12' className='text-center'>
          <MDBTable className='tarriff-table'>
              {/* <MDBTableHead>
                <tr>
                  <th colSpan='5' className='bright'>
                    Bright economy 7
                  </th>
                </tr>
              </MDBTableHead> */}
              <MDBTableBody>
                <tr>
                  <td></td>
                  <td colspan='2' width='189'>
                    <p>Unit Rate (pence per kWh)</p>
                  </td>
                  <td colspan='2' width='195'>
                    <p>Standing Charge (pence per day)</p>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <p>Excl VAT</p>
                  </td>
                  <td>
                    <p>Incl VAT</p>
                  </td>
                  <td>
                    <p>Excl VAT</p>
                  </td>
                  <td>
                    <p>Incl VAT</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Day</p>
                  </td>
                  <td>
                    <p>{config.sc_eco7_unitday_exVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.sc_eco7_unitday_incVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.sc_eco7_standing_exVat.toFixed(2)}</p>
                  </td>
                  <td>
                    <p>{config.sc_eco7_standing_incVat.toFixed(2)}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Night</p>
                  </td>
                  <td>
                    <p>{config.sc_eco7_unitnight_exVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.sc_eco7_unitnight_incVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.sc_eco7_standing_exVat.toFixed(2)}</p>
                  </td>
                  <td>
                    <p>{config.sc_eco7_standing_incVat.toFixed(2)}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Heat</p>
                  </td>
                  <td>
                    <p>{config.sc_eco7_unitheat_exVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.sc_eco7_unitheat_incVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.sc_eco7_standing_exVat.toFixed(2)}</p>
                  </td>
                  <td>
                    <p>{config.sc_eco7_standing_incVat.toFixed(2)}</p>
                  </td>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    quoteData: store.quote.quoteData,
  };
};

const actionCreators = {
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(KeypadEco7);
