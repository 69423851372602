import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import UserLoginDD from "./UserLoginDD";
import MainMenuDark from "./MainMenuDark";
import MainMenu from "./MainMenu";

class FixedNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  render() {
    return (
      <div className="dark-nav">
        <div className='country-select-section'>
          <MDBContainer>
            <div className='row'>
              <div className='col-lg-8 col-sm-0'></div>
              <div className='col-lg-12 col-lg-4 text-right'>
                {/* <UserLoginDD /> */}
              </div>
            </div>
          </MDBContainer>
        </div>

        <div className='header-container'>
          <div className={this.state.value}>
            {/*Sub bar (main menu) */}
            <div className='row'>
              <div className='col-lg-12'>
                <MDBRow className='py-3'>
                  <MDBCol sm='12' md='12' lg='12' className=' text-center'>
                    <MainMenuDark />
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        </div>
        </div>
    );
  }
}

export default FixedNav;
