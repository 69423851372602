import React from "react";
import Select from "react-select";
import { connect } from "react-redux";

import { signinActions } from "../../redux/actions";

import { history } from "../../redux/helpers";
import Loader from "../../components/Shared/Loader";

class NavigationSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
      showMultiProp: false,
      propData: null,
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;
    let properties = user.data.properties;

    this.setState({
      jurisdiction: jurisdiction,
      userId: user.data.authenticateModel.id,
    });

    // if (!properties.length > 1) {
    // let propertyData = []; //empty array
    // for (var i = 0; i < properties.length; i++) {
    //   var cancelledProp = false;
    //   try {cancelledProp = properties[i].cancelled}catch{}

    //   var streetN = "";
    //   if (!(cancelledProp)) {
    //     if (properties[i].billingAddress.address2 != null)
    //      {streetN = properties[i].billingAddress.address2}

    //     var addr = streetN + ' ' + properties[i].billingAddress.address3
    //     if (properties[i].id === jModel.id)
    //     {
    //       addr+=" *";
    //     }

    //     propertyData.push(
    //       { value: properties[i].id, label: addr } //push each property to the dropdown list
    //     )
    //   }
    // }

    // //show dropdown
    // this.setState({
    //   propData: propertyData,
    // });

    // if (propertyData.length >1) {
    //   this.setState({
    //     showMultiProp:true,
    //   });
    // }
    // else
    // {
    //   this.setState({
    //     showMultiProp:false,
    //   });
    // }

    // }
  }

  async componentWillReceiveProps(nextProps) {
    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  PropertyChange = (propertyOption) => {
    this.setState({
      selectedProperty: propertyOption.value,
    });

    this.props.switchAccount(
      this.state.userId,
      propertyOption.value,
      this.state.jurisdiction
    );

    console.log("Option selected:", propertyOption);
  };

  render() {
    return (
      <div>
        {this.state.loading ? <Loader /> : null}
        <nav className='ca-navbar'>
          <ul className='ca-navbar-nav'>
            {/* Logo */}
            <li className='ca-logo'>
              <a href={`/${this.state.jurisdiction}`}>
                <div className='ca-logo-width'>
                  <img src={require("../../images/logo_blank.png")} />
                </div>
              </a>
            </li>

            {/* Dashboard */}
            <li className='ca-nav-item'>
              <a
                href={`/${this.state.jurisdiction}/account/dashboard`}
                className='ca-nav-link'
              >
                <div className='ca-nav-link-width'>
                  <img src={require("../../images/account/homeIcon.png")} />
                </div>
                <span className='ca-link-text'>Dashboard</span>
              </a>
            </li>

            {/* Show refer for ROI and usage for NI */}
            {this.state.jurisdiction == "roi" ? (
              <li className='ca-nav-item'>
                <a
                  href={`/${this.state.jurisdiction}/account/referafriend`}
                  className='ca-nav-link'
                >
                  <div className='ca-nav-link-width'>
                    <img
                      src={require("../../images/account/Refer-A-Friend-Icon.png")}
                    />
                  </div>
                  <span className='ca-link-text'>Refer</span>
                </a>
              </li>
            ) : (
              <li className='ca-nav-item'>
                <a
                  href={`/${this.state.jurisdiction}/account/energyusage`}
                  className='ca-nav-link'
                >
                  <div className='ca-nav-link-width'>
                    <img
                      src={require("../../images/account/energyUsage.png")}
                    />
                  </div>
                  <span className='ca-link-text'>Usage</span>
                </a>
              </li>
            )}

            {/* Meter */}
            <li className='ca-nav-item'>
              <a
                href={`/${this.state.jurisdiction}/account/meterreading`}
                className='ca-nav-link'
              >
                <div className='ca-nav-link-width'>
                  <img src={require("../../images/account/meterIcon.png")} />
                </div>
                <span className='ca-link-text'>Meter</span>
              </a>
            </li>

            {/* Account */}
            <li className='ca-nav-item'>
              <a
                href={`/${this.state.jurisdiction}/account/manageaccount`}
                className='ca-nav-link'
              >
                <div className='ca-nav-link-width'>
                  <img src={require("../../images/account/accountIcon.png")} />
                </div>
                <span className='ca-link-text'>Account</span>
              </a>
            </li>

            {/* More */}
            <li className='ca-nav-item'>
              <a
                href={`/${this.state.jurisdiction}/account/more`}
                className='ca-nav-link'
              >
                <div className='ca-nav-link-width'>
                  <img src={require("../../images/account/moreIcon.png")} />
                </div>
                <span className='ca-link-text'>More</span>
              </a>
            </li>

            {/* Property selection */}
            {/* {this.state.showMultiProp?
            <li className='ca-nav-item p-2 show-for-large'>
              <Select
                onChange={this.PropertyChange}
                options={this.state.propData}
                className='multiselect multiselect-width'
                placeholder='Switch account'
                value={this.state.selectedProperty}
              />
            </li>
            :null} */}
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    error: store.signin.error,
    isLoading: store.signin.isLoading,
  };
};

const actionCreators = {
  switchAccount: signinActions.switchAccount,
};

export default connect(mapStateToProps, actionCreators)(NavigationSection);
