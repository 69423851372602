import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import NavigationSection from "../../../components/CustomerAccount/AccountNavigation";
import { customerAccountActions } from "../../../redux/actions/customerAccountActions";
import { connect } from "react-redux";
import { signinActions } from "../../../redux/actions";
import Loader from "../../../components/Shared/Loader";
import { sessionExpired } from "../../../redux/helpers/sessionExpired";

class SwitchAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      howToTip: false,
      jurisdiction: "",
      numPages: null,
      pageNumber: 1,
      bills: [],
    };
  }

  getBill = (id) => {
    this.props.getBill(id);
  };

  // Populate form on back
  async componentWillReceiveProps(nextProps) {
    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  componentDidMount() {
    sessionExpired();

    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;
    let properties = user.data.properties;

    this.setState({
      jurisdiction: jurisdiction,
      userId: user.data.authenticateModel.id,
    });

    // if (!properties.length > 1) {
    let propertyData = []; //empty array
    for (var i = 0; i < properties.length; i++) {
      var cancelledProp = false;
      try {
        cancelledProp = properties[i].cancelled;
      } catch {}

      var streetN = "";
      var prefillAdr = "";
      if (!cancelledProp) {
        if (properties[i].billingAddress.address1 != null) {
          prefillAdr = properties[i].billingAddress.address1 + ", ";
        }

        if (properties[i].billingAddress.address2 != null) {
          streetN = properties[i].billingAddress.address2;
        }

        var addr = prefillAdr + streetN + " " + properties[i].billingAddress.address3;
        if (properties[i].id === jModel.id) {
          addr += " (Active)";
        }

        propertyData.push(
          { value: properties[i].id, label: addr } //push each property to the dropdown list
        );
      }
    }

    //show dropdown
    this.setState({
      propData: propertyData,
    });

    if (propertyData.length > 1) {
      this.setState({
        showMultiProp: true,
      });
    } else {
      this.setState({
        showMultiProp: false,
      });
    }

    // }
  }

  PropertyChange = (propertyOption) => {
    this.setState({
      selectedProperty: propertyOption.value,
    });

    this.props.switchAccount(
      this.state.userId,
      propertyOption.value,
      this.state.jurisdiction
    );

    console.log("Option selected:", propertyOption);
  };

  render() {
    return (
      <div className="account-dashboard-container">
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}
        <div className="account-details-container">
          <NavigationSection />
          <div className="make-payment-background">
            <MDBContainer className="main-content payment-history">
              {/* Page Title */}
              <MDBRow>
                <MDBCol size="1">
                  <a href={`/${this.state.jurisdiction}/account/more`}>
                    <i className="fas fa-chevron-left fa-2x" />
                  </a>
                </MDBCol>
                <MDBCol size="10" className="text-center ">
                  <h1 className="mt-1">Your accounts</h1>
                </MDBCol>
                <MDBCol size="1"></MDBCol>
              </MDBRow>

              <div className="white-curved-tile">
                {this.state.propData != null
                  ? this.state.propData.map((property) => (
                      <a
                        onClick={() => {
                          this.PropertyChange(property);
                        }}
                      >
                        <MDBRow className="moreHR">
                          <MDBCol size="12" className="">
                            <h5 className="mt-3">{property.label}</h5>
                          </MDBCol>
                        </MDBRow>
                      </a>
                    ))
                  : null}
              </div>
            </MDBContainer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    error: store.signin.error,
    isLoading: store.signin.isLoading,
  };
};

const actionCreators = {
  switchAccount: signinActions.switchAccount,
};

export default connect(mapStateToProps, actionCreators)(SwitchAccount);
