import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from "mdbreact";
import { connect } from "react-redux";
import { quoteActions } from "../../../../../redux/actions/quoteActions";
const LoadConfig = require("../../../../../config");
const config = LoadConfig.default;

export class UrbanNightSaver extends Component {
  state = {
    jurisdiction: "roi",
  };

  componentDidMount() {
    switch (this.props.quoteData.paymentMethod){
      case "SetDirectDebit":
        this.setState({
            paym: "Set Direct Debit"
        });
        break;
      case "PayOnReceipt":
        this.setState({
          paym: "Pay on Receipt"
      });
      break;
      case "VariableDirectDebit":
        this.setState({
          paym: "Variable Direct Debit"
      });
      break;
    }
  }

  render() {
    return (
      <div>
        <MDBRow className='mb-3'>
          <MDBCol size='2' />
          <MDBCol lg='8' md='8' sm='12' className='text-center'>
            <MDBTable className='tarriff-table'>
              <MDBTableHead>
                <tr>
                  <th colSpan="3" className='bright'>
                  {this.state.paym}
                  </th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
              <tr>
                  <td className='center green-table'>Urban Nightsaver</td>
                  <td className='center green-table'>Excl. VAT</td>
                  <td className='center green-table'>Incl. VAT</td>
                </tr>
                
                <tr>
                  <td>Day Unit rate (c per kWh)</td>
                  <td className='cente'>{((config.urb_ns_dayunitrate / 1.135)*100).toFixed(3)}</td>
                  <td className='cente'>{(config.urb_ns_dayunitrate * 100).toFixed(3)}</td>
                </tr>
                <tr>
                  <td>Night Unit rate (c per kWh)</td>
                  <td className='cente'>{((config.urb_ns_nightunitrate / 1.135)*100).toFixed(3)}</td>
                  <td className='cente'>{(config.urb_ns_nightunitrate * 100).toFixed(3)}</td>
                </tr>
                <tr>
                  <td>Standing charge (€ per year)</td>
                  <td className='center'>
                  {(config.urbanStandingChargeExVat.toFixed(2))}
                  </td>
                  <td className='center'>
                    {config.urb_ns_standing.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>PSO charge (€ per year)</td>
                  <td className='center'>
                    {(config.urb_ns_pso / 1.135).toFixed(2)}
                  </td>
                  <td className='center'>
                    {(config.urb_ns_pso).toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td>Estimated Annual Bill (EAB)</td>
                  <td className='center'>
                  €{(config.urb_ns_eab_exvat).toFixed(0)}
                  </td>
                  <td className='center'>
                  €{(config.urb_ns_eab_incvat).toFixed(0)}
                  </td>
                </tr>
                
                {this.props.quoteData.paymentType == "PayOnReceipt" ? (
                  <tr>
                    <td>Deposit</td>
                    <td className='center'>€150</td>
                  </tr>
                ) : null}
              </MDBTableBody>
            </MDBTable>
          </MDBCol>
          <MDBCol size='2' />
        </MDBRow>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    quoteData: store.quote.quoteData,
  };
};

const actionCreators = {
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(UrbanNightSaver);
