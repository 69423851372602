import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import LegalTabs from "../../components/About/LegalTabs";
import privacyPDF from "../../documents/privacystatement.pdf";
import FixedNavDark from "../../components/Shared/FixedNavDark";
import $ from "jquery";


class PrivacyStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });



    this.help();
    window.addEventListener('scroll', this.help);
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark/> */}
        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>Policies & Terms</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow>
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <LegalTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5 pb-5'>
            <h3>Bright Privacy Notice </h3>

            {/* Security Deposit Policy */}
            <div className='links'>
              <p className="montserrat-Bold">1.Introduction</p>
              <p>
                At Bright we respect your privacy and we are committed to ensuring your personal data is protected. We collect, use and are responsible for certain personal information about you. This privacy notice sets out how and why we collect, use, store and share your personal data. It also sets out your rights with regards to your personal data and how you can exercise these rights.
              <br /><br />
             <b>We recommend that you read this privacy notice carefully,</b>  along with other information we may provide from time to time. We keep our privacy notice under regular review. This version was last updated on 14th May 2020.
              <br /><br />
              It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.
              </p>
              <br />

              <ul className='list-style-none'><li><b>1.1 Third-party links</b>

                This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.</li>

                <li><b>1.2 Controller</b>

                Bright Energy Supply ROI Limited/Bright Energy Supply NI Limited is the controller and responsible for your personal data (collectively referred to as “Bright”, “we”, “us “ or “our” in this privacy notice).
              This privacy notice sets out:</li>



                <ol>
                  <li>What data we collect about you</li>
                  <li>How we collect your data</li>
                  <li>How we use your personal data</li>
                  <li>Who we share your data with</li>
                  <li>Retention of your data</li>
                  <li>Security of your data</li>
                  <li>International Transfers</li>
                  <li>Your Rights</li>
                  <li>How to contact us</li>
                </ol></ul>

              <br />
              <p className="montserrat-Bold">2. What data we collect about you</p>
              <p>
                Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).
              <br /><br />
              We may collect, use, store and transfer the following personal information about you which we have grouped together as follows:
              </p>
              <ul className='list-style-none'>
                <li><b>2.1 Identity data:</b> which includes your name, gender, date of birth and national insurance number.</li>
                <li><b>2.2 Contact data:</b> which includes your billing address, supply address, telephone numbers, email
                    addresses and details of a secondary contact if applicable (name and phone number).</li>
                <li><b>2.3 Financial data:</b> including bank account and payment card details, details of payments made to Bright and credit history.</li>
                <li><b>2.4 Transaction data:</b> including meter point information for electricity and or gas and energy consumption information from meter readings submitted by you or obtained through other means, for example, a read provided by a meter reader.
                </li>
                <li><b>2.5 Profile data:</b> including account data such as username and password, home ownership details, permissions for contacting you, billing preferences (for example, email billing), information about other accounts you hold with us, for example, gas and electricity, details of any benefits you may be in receipt of, and any other personal requirements and lifestyle.</li>
                <li><b>2.6 Usage data:</b> which include information about how you use our website, products and services, and cookie data which is collected by our website and app.</li>
                <li><b>2.7 Marketing and communications data:</b> including your preferences in receiving marketing from us and your communication preferences and details of any referrals through our refer a friend scheme. We also monitor and record telephone calls.</li>
                <li><b>2.8 Special category data:</b> we may collect and process information about you that is more sensitive in nature (which is called special category data). This includes information about any health conditions you inform us about (including details of any medical or other electrical equipment you may use
                  or any benefits you may receive), specifically where these may entitle you to additional help and support services in relation to your energy supply or which may be required for the Medical Care, Priority Services, Special Services or Customer Care Registers (further details of which is set out below). We will use this information to protect your household, for example in the event of a power cut. We will always ask for your explicit consent to collect and process this data and will provide you with clear information about how we intend to use it, including if we need to share it with any third parties.</li>
                <li><b>2.9 Criminal conviction and offences:</b> which includes any evidence of, or if we suspect that you may be involved in any, fraud, theft or tampering with electricity.</li>
              </ul>

              <br />
              <ul className='list-style-none'><li><b>2.10 If you fail to provide personal data</b></li>

                <ul className='list-style-none'><li>2.10.1 Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with energy services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.</li>

                  <li> 2.10.2 If you fail to provide us with special category data we will not be able to add you to our customer care registers or you may not qualify for a payment plan.</li>
                </ul></ul>

              <p className="montserrat-Bold">3. How we collect your data?</p>

                The types of data we collect will vary depending on how you interact with us. These include:
              <br /><br />
              <ul className='list-style-none'><li> 3.1 Direct interactions:</li>

                <ul className='list-style-none'><li>3.1.1 When you contact us by email, phone, letter, social media or in person;</li>
                  <li>3.1.2 When you contact us with questions, queries of feedback; and</li>
                  <li>3.1.3 When you visit our website, for example to quote or switch online, to complete an online form or to
                set up your customer account.</li></ul>



              3.2 Automated technologies or interactions:
              <p>Automated technologies or interactions which we use to collect technical data about your browsing
                actions, devices and behaviours. Please view our cookie policy for full detail.
                </p>
                <p>
                  3.3 Third parties or publicly available sources
              </p>
                <ul className='list-style-none'>
                  <li>3.3.1 If you apply for our products through one of the below channels:</li>
                  <li>3.3.2  Price comparison websites;</li>
                  <li>3.3.3 From a third party if you have given them permission to share the information they hold about
                    you with us;</li>
                  <li>3.3.4 Analytics providers such as Google based outside the EU;</li>
                  <li>3.3.5 Advertising networks [such as Google Ads, YouTube, Facebook, LinkedIn, Twitter, DotDigital,
                          ContentCal and any other platform we may use from time to time];</li>
                  <li> 3.3.6 The network provider;</li>
                  <li>3.3.7 Other energy suppliers;</li>
                  <li>3.3.8 Credit reference agencies;</li></ul>
                <br />
                <li> 3.4 From your previous electricity supplier; and</li>
                <br />
                <li> 3.5 From publicly available sources or where the information is made public as a matter of law.
              </li></ul>

              <br />
              <p className="montserrat-Bold">4. How we use your personal data</p>
              <ul className='list-style-none'>We will use your personal data in the following circumstances:
              <p>
                  4.1 Where we need to perform the contract we are about to enter into or have entered into with you;
               <br></br> 4.2 Where it is necessary for our legitimate interests (or those of a third party) and your interests and
                fundamental rights do not override those interests;
                <br></br>4.3 Where we need to comply with a legal obligation; and
                <br></br>4.4 Where we have your consent to process your personal data.</p>

                <p>4.5 From time to time where we have a <b>contract</b> or if we need to take steps at your request before entering into a contract with you we will use your data for the purposes of:</p>
                <ul className='list-style-none'>
                  <li>4.5.1 Identifying you when you contact us;</li>
                  <li>4.5.2 Providing a quote or information on our products and services;</li>
                  <li>4.5.3 Providing products and services to you;</li>
                  <li>4.5.4 Responding to any queries you have;</li>
                  <li>4.5.5 Setting up your account and billing arrangements;</li>
                  <li>4.5.6 Performing general account management; and</li>
                  <li>4.5.7 Producing and sending bills.</li>
                </ul>
                <p>4.6 From time to time we have a <b>legal obligation</b> to use your data for the purposes of:</p>
                <ul className='list-style-none'>
                  <li>4.6.1 Meeting regulatory obligation requirements;</li>
                  <li>4.6.2 Detecting and preventing crime including fraud, theft of energy and tampering;</li>
                  <li>4.6.3 National security, safeguarding someones interests, responding to a statutory obligation or request
               from the courts;</li>
                  <li>4.6.4 Providing relevant information to other suppliers at time of customer switch; and</li>
                </ul>

                <p>4.7 From time to time where we have a <b>legitimate interest</b> we will use your data for the purposes of:</p>
                <ul className='list-style-none'>
                  <li>4.7.1 Making credit decisions / debt flagging;</li>
                  <li>4.7.2 Analysing account and payment activities to help identify alternative, or more appropriately tailored
                  price plans and payment schedules that could be offered;</li>
                  <li>4.7.3 Using data analytics to understand and improve the way we do things as a business;</li>
                  <li>4.7.4 Analysing account and payment activities to identify customers struggling to pay bills;</li>
                  <li>4.7.5 Maintaining and improving our products and services. For example enhancing pricing structures,
                  business operations4, and or analysing performance of advertising / marketing campaigns;</li>
                  <li>4.7.6 Market surveys, research and analytics;</li>
                  <li>4.7.7 Marketing products and services in accordance with your marketing preferences (see Direct
                  Marketing section below);</li>
                  <li>4.7.8 Developing new products and services that may be of interest to you – demographical, behaviours,
                interests and habits may be taken into consideration;</li>
                  <li>4.7.9 Monitoring product (electricity/gas or other offerings) usage;</li>
                  <li>4.7.10 Running loyalty and reward programs you have signed up to; and</li>
                  <li>4.7.11 Training our staff and improve our products and services, which may mean that we monitor and
                record communications that we have with you including phone conversations, emails, SMS and web chats.</li>
                </ul>

                <p>4.8 From time to time where we rely on <b>consent</b> we will use your data for the following purposes:</p>
                <ul className='list-style-none'>
                  <li>
                    4.8.1 Administrating customer service registers:
                  <br />We may collect certain types of special category data information so that we can provide you
                with additional services and support when you need it. We maintain a customer care register for vulnerable customers including those customers who are of pensionable age, disabled or chronically sick, in addition we provide information to Networks Operators (NIE Networks/ ESB Networks) on those customers who rely on electrical equipment for health reasons for inclusion on the Medical Care Register or Priority Services registers.
                <br /><br />4.8.2 Data collected will include age, disability or illness, electrical equipment or medical equipment used details of any nominated person you wish us to discuss your account with (we will also require the nominated person) and a password you set with us so you know its us contacting you. We will also collect any other relevant data which would help us provide appropriate assistance to you.
                <br /><br />4.8.3 We will always request your explicit consent to collect, maintain, and share your sensitive personal data in this way, and you can ask us to remove you from our registers at any point, by simply getting in touch with one of our agents. We will always explain to you what opting in and out of the registers means in terms of the services you can expect to receive from us so that you can make an informed decision.
                </li>
                  <li>
                    <br />4.8.4 Administrating payment plans
                  <br />We will monitor your account to ensure that payments are being made on time. Where we contact you in relation to billing matters, or you contact us to inform us that you are struggling to pay your bill, we may request additional personal information in order to develop a payment plan that suits your needs. This information may include details of any benefits you may receive, other household expenditure, debt advice information from third parties, or other relevant information to your finances or lifestyle that will allow us to tailor a payment plan to your needs. Please note, if you wish to develop a payment plan, a third party may be appointed to manage this on our behalf and your data will be shared with that third party.

              <br /><br />
                4.8.5 Where we receive consent to perform certain activities, you can withdraw that consent at any time by contacting us. If you withdraw your consent to us processing your data in this way, we will not be able to provide you with services such as administrating a payment plan for you and we will remove you from the Customer Service Register.
                </li>
                </ul>
                <li><b>4.9 Direct marketing</b></li>
                <ul className='list-style-none'>
                  <li>4.9.1 We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. We may use your personal information to send you updates (by email, text message, telephone or post) about our services. Where you have agreed to web advertising we may use certain limited information about you, such as your email address may be used to generate custom and lookalike audiences for advertising targeting to drive traffic to our website or promote our products and services. We may also exclude certain customers from certain digital advertising campaigns. We may also profile your data to provide you with marketing and offers that are relevant to you.</li>
                  <li>4.9.2 You can unsubscribe from direct marketing or opt out of profiling via the channel you received the communication or you can change your preferences on our website, via the app, on the customer portal or by getting in touch using the contact details below.</li>
                  <li>4.9.3 We have a legitimate interest in processing your personal information for promotional purposes (see above ‘How we use your personal data’). This means we do not usually need your consent to send you promotional communications. However, where consent is needed, we will ask for this consent separately and clearly.</li>
                  <li>4.9.4 We will always treat your personal information with the utmost respect and never share it with other organisations outside the Bright Energy Supply ROI Limited/Bright Energy Supply NI Limited and the Maxol Energy Limited group for marketing purposes.</li>
                </ul>

                <li><b>4.10 Cookies</b></li>

                <ul className='list-style-none'><li>4.10.1 You can set your browser to refuse all or some browser cookies, or to alert you when websites set or
              access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see <a href={`/${this.state.jurisdiction}/ni/ourpolicies#cookie-policy`}>Cookie Policy.</a></li>
                </ul>
                <li><b>4.11 Change of purpose</b></li>
                <ul className='list-style-none'>
                  <li>4.11.1 We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us. If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</li></ul>
                <li>4.12 Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</li>
              </ul>
              <p className="montserrat-Bold">5. Who we share your data with</p>
              <p>We may share your personal data with the parties set out below:</p>
              <ul className='list-style-none'>
                <li>
                  <b>5.1 Credit check </b>
          We may carry out a credit check on domestic customers in order to check your identity, assess creditworthiness, manage your account, trace and recover debts and prevent criminal activity. Where we consider it is necessary to perform a credit check, we will supply your personal information to credit reference agencies (also known as CRAs) and they will give us information about you, such as about your financial history. We will also continue to exchange information about you with CRAs on an on- going basis, including about your settled accounts and any debts not fully repaid on time. CRAs will share your information with other organisations. More information on CRAs and how they work can
            be found at <a href="http://www.experian.co.uk/crain/index.html">www.experian.co.uk/crain/index.html.</a> We may carry out a credit check on commercial customers in order to check your identity, assess creditworthiness, manage your account, trace and recover debts and prevent criminal activity. Where it is necessary to credit check your business, we will supply your business information to credit reference agencies (CRAs) and they will give us information about your business, such as about your business’ financial history. We will also continue to exchange information about your business with CRAs on an on-going basis. CRAs may share this information with other organisations.
                </li>
                <li>
                  <b>5.2 Fraud prevention </b>
To help us identify fraud and energy theft, we will give details of your account to fraud prevention agencies who will use the information to check public and other databases they hold and may provide information to us to help identify fraud and energy theft. Checks will be performed on a regular
basis whilst you hold an account with us. If we suspect or can confirm theft of energy has occurred,
a record will be kept by fraud prevention agencies which may include sensitive information about alleged criminal offences. The fraud prevention agencies may provide the information to other energy companies to help identify fraud and detect energy theft but only in limited circumstances where you have accounts with them. Where theft has been identified, your account terms may change, but we will notify you separately if this is the case.
                </li>
                <li>
                  <b>5.3 Third party processors </b>
All third party processors are required to respect the security of your personal data and to treat it in accordance with the law and we do not allow them to use your personal data for their own purposes. We have contractual arrangements in place with our processors which underpin their obligations with regards to how they handle your personal data on our behalf. The third party processors we may share your data with are as follows:</li>
                <br />
                <ul className='list-style-none'>

                  <li> 5.3.1 Third-party suppliers, partners, employees, agents and sub-contractors working on our behalf to provide, review and/or receive services in relation to our website, services and/or products, which include: administrating your account, payment processors; suppliers of technical, support and installation services; insurers; logistics providers; security providers; cloud services providers; research agencies; meter readers, to help detect and prevent fraud, crime and loss; if you are entitled to claim government funding for energy efficiency measures, installers, energy performance assessors and sales advisers.</li>
                  <li>5.3.2 Where appropriate, family members or cohabitants, previous tenants, landlords, or letting agents who/which may require information about you or your premises.</li>
                  <li> 5.3.3 Any third party where required to enforce or apply our terms and conditions or any other agreement or to respond to any claims, to protect our rights or the rights of a third party, to protect the safety of any person or to prevent any illegal activity.</li>
                  <li>5.3.4 Any third party to protect the rights, property or safety of Bright, our staff, customers or others.</li>
                  <li>5.3.5 Companies or partner organisations for the purposes of market research, which includes any
advertising and/or marketing companies working on our behalf.</li>
                  <li>5.3.6 Debt collection and tracing agents where we provide you with services and you fail to make
payments.</li>
                  <li>5.3.7 Authorised third parties or named account holders on any account you hold with us.</li>
                  <li>5.3.8 At your request we may share your data with third party agencies working on your behalf.</li></ul>

                <br /><br /><li>5.4 In addition to the above parties, we will also share your personal data with the following parties:</li>
                <ul className='list-style-none'> <li>5.4.1 If we stop providing services to the electricity retail market, for reasons other than a sale or
                restructuring, your personal information may be transferred to the new supplier (known as the
“Supplier of Last Resort”) directed by the CRU.</li>
                  <li>5.4.2 If we sell, merge, or perform any internal re-organisations in relation to any of our (or any third party’s)
                  business or assets, the personal information will be one of the transferred assets to the relevant buyer
and/or new data controller of such business or assets.</li>
                  <li>5.4.3 Other energy suppliers to facilitate the switch process should you chose to leave us. This information
will include energy usage and any debts on the account.</li>
                  <li>5.4.4 Government or law enforcement officials as may be required to meet national security or law
enforcement requirements or prevent illegal activity</li>
                  <li>5.4.5 Regulatory Authorities or other legally appointed bodies (such as CRU/UR) for legal or regulatory
purposes.</li>
                  <li>5.4.6 To and from the Utility Regulator, the Network Operator, the Meter Provider and the Consumer
Council,and metering agents or gas transporters.</li></ul></ul>




              <p>
                We have put in place due diligence and quality processes to ensure that the parties with whom we share data employ best practice with regards to data protection.
              </p>

              <p className="montserrat-Bold">6. Retention of your data</p>
              <ul className='list-style-none'>
                <li> 6.1 We will only retain your personal data for as long as necessary to fulfil the purposes for which it was originally collected, which may include legal, accounting, auditing, reporting and marketing purposes. In order to determine appropriate retention periods for your personal data, we take into consideration the amount, nature and sensitivity of your data. Details of retention periods for different aspects of your personal data are available in our retention policy which you can request from us by contacting us.</li>
                <li>6.2 We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</li>
                <li>6.3 In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</li>

              </ul>

              <p className="montserrat-Bold">7. Security of your data</p>
              <ul className='list-style-none'>
                <li>7.1 We want you to be confident about how we manage technical and organisational measures to make sure your data is kept secure. We have put in place policies and procedures which aim to prevent your personal data from being accidentally lost or processed, altered or disclosed in an unauthorised way. We regularly audit our processing and examine the risks, and use this to assess whether our systems are supported by an appropriate level of security.</li>
                <li> 7.2 We have trained our staff on how to protect your personal data. Our colleagues, agents, contractors and other third parties only have access to your data in as much detail as is required for them to carry out their roles and to enable us to fulfil our obligations to you and meet our regulatory and legal duties. They will only handle your personal data on our instruction and they are subject to a duty of confidentiality.</li>
                <li>  7.3 We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</li>
              </ul>

              <p className="montserrat-Bold">8. International Transfers</p><ul className='list-style-none'>
                <li>
                  8.1 We do not transfer your personal data outside the European Economic Area (EEA).</li></ul>

              <p className="montserrat-Bold">9. Know Your Rights</p>
              <p>
                In certain circumstances you have the following rights in relation to your personal data:</p>
              <ul className='list-style-none'>
                <li>9.1 Access
                The right to be provided with a copy of your personal information.</li>

                <li>9.2 Rectification
                The right to require us to correct any mistakes in your personal information.
                </li>
                <li>9.3.	To be forgotten
                The right to require us to delete your personal information in certain situations.
                </li>
                <li>9.4.	Restriction of processing
                The right to require us to restrict processing of your personal information in certain circumstances for example, if you contest the accuracy of the data.
                </li>
                <li>9.5.	Data portability
                The right to receive the personal information you provided to us, in a structured, commonly used and machine-readable format and/or transmit that data to a third party in certain situations.
                </li>
                <li>9.6.	Object
                The right to object:
                </li>
                <ul className='list-style-none'><li>9.6.1	at any time to your personal information being processed for direct marketing (including profiling);</li>
                  <li>9.6.2	in certain other situations to our continued processing of your personal information, e.g. processing carried out for the purpose of our legitimate interests.</li></ul>
                <li>9.7 Not to be subject to automated decision making
                The right not to be subject to a decision based solely on automated processing (including profiling) that produces legal effects concerning you or similarly significantly affects you.
              </li>
                <li>9.8.	If you would like to exercise any of those rights, please email, call or write to us let us know which right you want to exercise and the information to which your request relates. </li>

                <br/><br/>


                <li>9.9 No fee usually required</li>
                <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>

                <li>9.10 What we may need from you</li>
                <p>We may need to request specific information from you to help us confirm your identity and ensure
                your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>

               <li>9.11 Time limit to respond</li> 
                <p>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
              </ul>
              <p className="montserrat-Bold">10. How to contact us.</p>
              <p>
                We hope this privacy notice has been helpful in setting out the way we handle your personal data and your rights to control it.
                <br /><br />If you have any questions that haven’t been covered, please contact our Data Protection Officer (DPO) who will be pleased to help you. You can contact them by e-mailing at <a href='mailto:DPO@brightenergy.com'>DPO@brightenergy.com.</a> You can also write to us at:
                </p>

              <p className="montserrat-Bold">For Northern Ireland</p>
              <p>
                Bright,
                84-94 Great Patrick Street, Belfast,
                Northern Ireland,
                BT1 2LU
                </p>

              <p className="montserrat-Bold">For Republic of Ireland</p>
              <p>
                Bright,
                3 Custom House Plaza, IFSC, Dublin,
                Republic of Ireland, D01 VY76
                </p>

              <p className="montserrat-Bold">If you live in Northern Ireland you can complain to the following bodies:</p>
              <p>
                You also have the right to complain to the Information Commissioner’s Office (ICO) at: Information Commissioner’s Office, Third Floor 14 Cromac Place, Belfast, BT7 2JB Telephone: <a href='tel:028 9027 8757'>028 9027 8757</a> or <a href='tel:0303 123 1114'>0303 123 1114 </a>
                E-Mail: <a href='mailto:ni@ico.org.uk'>ni@ico.org.uk</a>
                <br /><br />Before contacting the ICO, please give our Data Protection Officer the opportunity to put matters right for you.
                </p>

              <p className="montserrat-Bold">If you live in the Republic of Ireland you can lodge a complaint with:</p>
              <p>
                Data Protection Commission, 21 Fitzwilliam Square South, Dublin 2, D02 RD28 Ireland
              <br />Telephone: <a href='tel:0578 684 800'>0578 684 800</a> or <a href='tel:0761 104 800'>0761 104 800</a>
                <br />Website: <a href='https://www.dataprotection.ie'>https://www.dataprotection.ie</a>
              </p>

            </div>
          </MDBCol>
        </MDBRow>
      </div >
    );
  }
}

export default PrivacyStatement;
