import React from "react";
import { MDBCol, MDBRow } from "mdbreact";

class EnergySavingTips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split('/');
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris
    });
  }


  render() {
    return (
      <div>
        <MDBRow size='12' className='energyTips-background'>
          <MDBCol size='1' />
          <MDBCol sm='12' md='5' lg='5' className='align-self-center'>
            <img
              src={require("../../images/help/bulb.png")}
              className='help-tile-icon'
            />
            <div className='energyTips-content'>
              <h4>Energy saving tips</h4>
              <p>
                Read our energy saving tips for great advice on how you can take
                small steps to reduce your energy costs and your carbon
                footprint.
              </p>
              <a href={`/${this.state.jurisdiction}/blog/post?title=Bright’s-top-tips-for-energy-saving`} className='btn'>
                Learn More
              </a>
            </div>
          </MDBCol>
          <MDBCol size='6' />
        </MDBRow>
      </div>
    );
  }
}

export default EnergySavingTips;
