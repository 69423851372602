import { locationService } from "../services";
import { locationConstants } from "../constants";

export const locationActions = {
    getJurisFromIP
};

function getJurisFromIP() {
    return dispatch => {
        locationService.getLocationJurisService().then(
        response => {
          dispatch(getJurisFromIPSuccess(response.data));
        },
        error => {
          dispatch(getJurisFromIPFailure([error.response.data.message]));
        }
      );
    };
  
    function getJurisFromIPRequest() {
      return {
        type: locationConstants.GET_JURISFROMIP_REQUEST,
        payload: {}
      };
    }
  
    function getJurisFromIPSuccess(dbData) {
      return {
        type: locationConstants.GET_JURISFROMIP_SUCCESS,
        payload: dbData
      };
    }
  
    function getJurisFromIPFailure(error) {
      return {
        type: locationConstants.GET_JURISFROMIP_FAILURE,
        payload: error
      };
    }
  }