import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import Swiper from "react-id-swiper";
import { isBrowser, isMobile } from "react-device-detect";
import NIOnAMission from "../../../components/NI/NIOnAMission";

class NIOurElectricity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split('/');
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris
    });
  }

  render() {
    // Generator Seciton Swiper https://swiperjs.com/api/
    const generatorSwiper = {
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 1000,
      loop: true,
      effect: "fade",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    return (
      <div className='default-page home-page'>
        {/* Hero with background device */}
        <MDBRow className='small-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='12' lg='12'>
                <h1>Our energy</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        {/* Text & Image Section */}
        <div>
          <MDBContainer className='text-left-image-right-section'>
            <MDBRow>
              <MDBCol size='1'></MDBCol>
              <MDBCol size='10'>
                <MDBRow className='large-padding'>
                  {isMobile &&
                    <MDBCol sm='12' md='12' lg='6'>
                      <img src={require("../../../images/our-energy/image-1-min.png")} />
                      <br /><br />
                    </MDBCol>
                  }
                  <MDBCol sm='12' md='12' lg='6' className='content'>
                    <h2>What 100% renewable energy means</h2>
                    <p>
                      At bright we want to help our customers do their bit to protect the environment, that's why we provide all our customers with 100% renewable electricity. But what
                      does 100% renewable mean and where do we get our electricity from? Here's our guide to renewable electricity.
                    </p>
                   
                  </MDBCol>
                  {isBrowser &&
                    <MDBCol sm='12' md='12' lg='6'>
                      <img src={require("../../../images/our-energy/img-1New.jpg")} />
                    </MDBCol>
                  }
                </MDBRow>
              </MDBCol>
              <MDBCol size='1'></MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol size='1'></MDBCol>
              <MDBCol size='10'>
                <MDBRow className='large-padding pt-0 pb-0'>
                  <MDBCol sm='12' md='12' lg='6'>
                    <img src={require("../../../images/our-energy/img-2New.jpg")} />
                  </MDBCol>

                  <MDBCol sm='12' md='12' lg='6' className='content future'>
                    <h2>Powering your home</h2>
                    <p>
                      Regardless of who your electricity supplier is, the electricity in your house comes from
                      the electricity grid. This electricity is generated from a range of different sources; gas,
                      renewable and fossil fuels. But, we make sure that for each unit of electricity you use,
                      we purchase locally generated renewable certificates to ensure that a unit of renewable electricity
                      has been generated to replace it. By buying enough certificates to cover the consumption of all our
                      customers we can ensure that our electricity is 100% renewable.
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol size='1'></MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol size='1'></MDBCol>
              <MDBCol size='10'>
                <MDBRow className='large-padding'>
                  {isMobile &&
                    <MDBCol sm='12' md='12' lg='6'>
                      <img src={require("../../../images/our-energy/image-1-min.png")} />
                      <br /><br />
                    </MDBCol>
                  }
                  <MDBCol sm='12' md='12' lg='6' className='content'>
                    <h2>What are renewable certificates?</h2>
                    <p>
                      When a renewable generator, that is a generator who is generating electricity from
                      renewable sources such as solar, wind, hydro or bio, puts a unit of electricity (one megawatt hour)
                      onto the grid they receive a Renewable Energy Guarantee of Origin certificate or REGO for short.
                       The REGO certification scheme is approved and run by Ofgem (the energy regulator in the UK)
                        on behalf of the Utility Regulator in Northern Ireland.
                    </p>

                  </MDBCol>
                  {isBrowser &&
                    <MDBCol sm='12' md='12' lg='6'>
                      <img src={require("../../../images/our-energy/img-3New.jpg")} />
                    </MDBCol>
                  }
                </MDBRow>
              </MDBCol>
              <MDBCol size='1'></MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol size='1'></MDBCol>
              <MDBCol size='10'>
                <MDBRow className='large-padding pt-0 pb-0'>
                  <MDBCol sm='12' md='12' lg='6'>
                    <img src={require("../../../images/our-energy/img-4New.jpg")} />
                  </MDBCol>

                  <MDBCol sm='12' md='12' lg='6' className='content future'>
                    <h2>Why do we use REGOs?</h2>
                    <p>
                    At bright, we don't generate our own electricity, 
                    so we have to purchase it in the Single Electricity 
                    Market. The REGO scheme is an accredited scheme through 
                    which we can support renewable generators. Purchasing 
                    REGOs provides an income stream to renewable generators 
                    and incentivises more generators to offer renewable forms 
                    of generation, helping to protect and increase supplies of 
                    renewable generation now and in the future. We're making 
                    sure that the certificates we purchase are from local generators. 
                    As we grow and build a sustainable customer base we will be able 
                    to purchase directly from local renewable generators.
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol size='1'></MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol size='1'></MDBCol>
              <MDBCol size='10'>
                <MDBRow className='large-padding'>
                  {isMobile &&
                    <MDBCol sm='12' md='12' lg='6'>
                      <img src={require("../../../images/our-energy/image-1-min.png")} />
                      <br /><br />
                    </MDBCol>
                  }
                  <MDBCol sm='12' md='12' lg='6' className='content'>
                    <h2>Reducing our use of electricity</h2>
                    <p>
                      We can all do our part to help the environment, not only by
                      using renewable energy but on cutting down on how
                      much electricity we use. We're committed to helping
                      customers reduce the amount of electricity they use
                      through promoting energy efficiency tips and grants, as well
                      as signposting to organisations which provide energy
                      efficiency advice. We promote paper free accounts with
                      bright and our energy specialists are trained to provide
                      energy efficiency support.
                    </p>

                  </MDBCol>
                  {isBrowser &&
                    <MDBCol sm='12' md='12' lg='6'>
                      <img src={require("../../../images/our-energy/img-5New.jpg")} />
                    </MDBCol>
                  }
                </MDBRow>
              </MDBCol>
              <MDBCol size='1'></MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol size='1'></MDBCol>
              <MDBCol size='10'>
                <MDBRow className='large-padding pt-0 pb-0'>
                  <MDBCol sm='12' md='12' lg='6'>
                    <img src={require("../../../images/our-energy/img-6New.jpg")} />
                  </MDBCol>

                  <MDBCol sm='12' md='12' lg='6' className='content future'>
                    <h2>Will renewable electricity cost me more?</h2>
                    <p>
                      At bright we don't believe that renewable electricity should
                      cost you more. That's why we only offer renewable energy
                      at competitive prices. Simple, affordable electricity at your
                      fingertips.
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol size='1'></MDBCol>
            </MDBRow>
          </MDBContainer>

          {/* Our Generators Section */}
          {/* <div className='our-generators-section'>
            <MDBContainer>
              <MDBRow className='large-padding'>
                <MDBCol
                  sm='12'
                  md='12'
                  lg='12'
                  className='text-center title-container'
                >
                  <h2>Our Generators</h2>

                  <MDBRow className='swiper-slider-container '>
                    <Swiper {...generatorSwiper}>
                      <MDBCol sm='12' className='p-0'>
                        <MDBRow className='large-padding'>
                          <MDBCol
                            sm='12'
                            md='12'
                            lg='6'
                            className='map-image-container'
                          >
                            <img
                              className='map-image'
                              src={require("../../images/generator-section/map.png")}
                            />

                            <img
                              className='wind-icon'
                              src={require("../../images/generator-section/wind-map-pin-active.png")}
                            />
                            <img
                              className='solar-icon'
                              src={require("../../images/generator-section/solar-map-pin.png")}
                            />
                            <img
                              className='hyrdo-icon'
                              src={require("../../images/generator-section/hydro-map-pin.png")}
                            />
                          </MDBCol>

                          <MDBCol sm='12' md='12' lg='6' className='content'>
                            <div className='white-curved-tile '>
                              <img
                                src={require("../../images/generator-section/wind-icon.png")}
                                className='hydro-icon'
                              />

                              <div className='name-info'>
                                <h2>Michael</h2>
                                <p className='location'>
                                  <b>Location:</b> Dublin
                                </p>
                                <p className='location'>
                                  <b>Generation type:</b> Hydro
                                </p>
                              </div>

                              <div className='main-content-container'>
                                <div className='image-container'>
                                  <div className='background-image-1'></div>
                                </div>
                                <div className='text-content'>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Nunc bibendumsodales quam
                                    mattis nulla augue et massa. Fusce molestie
                                    eros non sapien iaculis cursus in nec nibh.
                                    Suspendisse egestas congue convallis.
                                  </p>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectrum,
                                    nulla quam mattis nisi, ac sollicitudin
                                    nullolestie eros non sapien iaculis cursus
                                    in nec nibh. Suspendisse egestas congue
                                    convallis.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>

                      <MDBCol sm='12' className='p-0'>
                        <MDBRow className='large-padding'>
                          <MDBCol
                            sm='12'
                            md='12'
                            lg='6'
                            className='map-image-container'
                          >
                            <img
                              className='map-image'
                              src={require("../../images/generator-section/map.png")}
                            />

                            <img
                              className='wind-icon'
                              src={require("../../images/generator-section/wind-map-pin.png")}
                            />
                            <img
                              className='solar-icon'
                              src={require("../../images/generator-section/solar-map-pin-active.png")}
                            />
                            <img
                              className='hyrdo-icon'
                              src={require("../../images/generator-section/hydro-map-pin.png")}
                            />
                          </MDBCol>

                          <MDBCol sm='12' md='12' lg='6' className='content'>
                            <div className='white-curved-tile '>
                              <img
                                src={require("../../images/generator-section/solar-icon.png")}
                                className='hydro-icon'
                              />

                              <div className='name-info'>
                                <h2>Jim</h2>
                                <p className='location'>
                                  <b>Location:</b> Dublin
                                </p>
                                <p className='location'>
                                  <b>Generation type:</b> Hydro
                                </p>
                              </div>

                              <div className='main-content-container'>
                                <div className='image-container'>
                                  <div className='background-image-1'></div>
                                </div>
                                <div className='text-content'>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Nunc bibendumsodales quam
                                    mattis nulla augue et massa. Fusce molestie
                                    eros non sapien iaculis cursus in nec nibh.
                                    Suspendisse egestas congue convallis.
                                  </p>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectrum,
                                    nulla quam mattis nisi, ac sollicitudin
                                    nullolestie eros non sapien iaculis cursus
                                    in nec nibh. Suspendisse egestas congue
                                    convallis.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>

                      <MDBCol sm='12' className='p-0'>
                        <MDBRow className='large-padding'>
                          <MDBCol
                            sm='12'
                            md='12'
                            lg='6'
                            className='map-image-container'
                          >
                            <img
                              className='map-image'
                              src={require("../../images/generator-section/map.png")}
                            />

                            <img
                              className='wind-icon'
                              src={require("../../images/generator-section/wind-map-pin.png")}
                            />
                            <img
                              className='solar-icon'
                              src={require("../../images/generator-section/solar-map-pin.png")}
                            />
                            <img
                              className='hyrdo-icon'
                              src={require("../../images/generator-section/hydro-map-pin-active.png")}
                            />
                          </MDBCol>

                          <MDBCol sm='12' md='12' lg='6' className='content'>
                            <div className='white-curved-tile '>
                              <img
                                src={require("../../images/generator-section/hydro-icon.png")}
                                className='hydro-icon'
                              />

                              <div className='name-info'>
                                <h2>Dwight</h2>
                                <p className='location'>
                                  <b>Location:</b> Dublin
                                </p>
                                <p className='location'>
                                  <b>Generation type:</b> Hydro
                                </p>
                              </div>

                              <div className='main-content-container'>
                                <div className='image-container'>
                                  <div className='background-image-1'></div>
                                </div>
                                <div className='text-content'>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Nunc bibendumsodales quam
                                    mattis nulla augue et massa. Fusce molestie
                                    eros non sapien iaculis cursus in nec nibh.
                                    Suspendisse egestas congue convallis.
                                  </p>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectrum,
                                    nulla quam mattis nisi, ac sollicitudin
                                    nullolestie eros non sapien iaculis cursus
                                    in nec nibh. Suspendisse egestas congue
                                    convallis.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </Swiper>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div> */}
        </div>

        {/* On a mission section */}
        <NIOnAMission />
      </div>
    );
  }
}

export default NIOurElectricity;
