import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBAlert,
  MDBTable,
  MDBTableBody,
} from "mdbreact";
import $ from "jquery";
import LiveChat from "react-livechat";
import Loader from "../../../Shared/Loader";

// Redux
import { connect } from "react-redux";
import { switchActions } from "../../../../redux/actions/switchActions";
import { quoteActions } from "../../../../redux/actions/quoteActions";

// Security deposit
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { DepositDetailsForm } from "./SecurityDeposit/DepositDetailsForm";

const LoadConfig = require("../../../../config");
const config = LoadConfig.default;
const stripePromise = loadStripe(config.niStripeKey);

export class PaymentDetails extends Component {
  state = {
    accountName: "",
    bankAccountNo: "",
    bankSortCode: "",
    billingMethod: "eBilling",
    iban: "",
    DDAmount: 0,
    DDDayOfMonth: 0,
    useAccAddress: true,
    confirmDDAuth: false,

    showError: false,
    switchGot: false,
    jurisdiction: "ni",
    ibanTip: false,
    showDirectDebitDetails: false,
    showSecurityDeposit: false,
    showDepositMessage: false,
    collectionDate: "",

    // Handle billing address
    showbankAddress: false,
    bankAddressRequired: false,
    bankAddressLineOne: "",
    bankAddressLineTwo: "",
    bankAddressPostcode: "",
    bankAddressCountry: "Ireland",
    homeCheck: false,

    // Direct debit tariff tables
    tariffName: "bright future",
    meterType: "Standard 24hr",
    unitRate: config.sc_tfh_unit_incVat.toFixed(3),
    standingCharge: ((config.sc_tfh_standing_incVat / 365) * 100).toFixed(3),
    collectionDate: "15 working days",

    // Fields required
    required: false,
  };

  // Page Load
  componentDidMount() {
    // Get switch by id
    let switchID = localStorage.getItem("switchID");
    if (switchID != null) {
      this.props.getSwitch(switchID);
      this.setState({ switchGot: true });
    }

    // Get quote by id
    let quoteID = localStorage.getItem("quoteID");
    if (quoteID != null) {
      this.props.getQuote(quoteID);
    }
  }

  // If Props.SwitchData true populate form
  async componentWillReceiveProps(nextProps) {
    let sectionUpdated = JSON.parse(localStorage.getItem("paymentUpdated"));

    if (this.state.switchGot && sectionUpdated) {
      await this.setState({
        paymentMethod: nextProps.journeyData.paymentMethod,
        accountName: nextProps.journeyData.accountName,
        bankAccountNo: nextProps.journeyData.bankAccountNo,
        bankSortCode: nextProps.journeyData.bankSortCode,
        billingMethod: nextProps.journeyData.billingMethod,
        iban: nextProps.journeyData.iban,
        confirmDDAuth: nextProps.journeyData.confirmDDAuth,
        bankAddressLineOne: nextProps.journeyData.bankAddressLineOne,
        bankAddressLineTwo: nextProps.journeyData.bankAddressLineTwo,
        bankAddressPostcode: nextProps.journeyData.bankAddressPostcode,
        bankAddressCountry: nextProps.journeyData.bankAddressCountry,
      });
    }

    if (nextProps.journeyData.paymentMethod != "PayOnReceipt") {
      this.setState({ showDirectDebitDetails: true, required: false });
    }

    if (nextProps.journeyData.paymentMethod == null) {
      this.setState({ showDirectDebitDetails: false });
    }

    // Set keypad
    if (nextProps.journeyData.keypad == false) {
      localStorage.setItem("keypadCheck", false);
    } else {
      localStorage.setItem("keypadCheck", true);
    }

    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle Field Change
  handleChange = (input) => (e) => {
    var fieldValue = $("#" + input)[0].checkValidity();

    if (fieldValue) {
      $("#" + input)
        .addClass("input-valid")
        .removeClass("input-error");
    } else {
      $("#" + input)
        .addClass("input-error")
        .removeClass("input-valid");
    }

    this.setState({ [input]: e.target.value });
  };

  // Billing Method Checkbox Change
  billingMethod = (value) => {
    if (value == "eBilling") {
      this.setState({ billingMethod: "eBilling" });
    } else if (value == "Post") {
      this.setState({ billingMethod: "Post" });
    }
  };

  // Continue to next section
  nextStep = (e) => {
    // Prevent from from clearing
    e.preventDefault();

    // Validate Form
    let validation = $("#paymentDetails")[0].checkValidity();

    if (validation) {
      // Form Data
      const data = {
        // Step one - (Account details)
        accountHolder: this.props.journeyData.accountHolder,
        title: this.props.journeyData.title,
        firstName: this.props.journeyData.firstName,
        surname: this.props.journeyData.surname,
        dob: new Date(this.props.journeyData.dob).toISOString().slice(0, 19),
        email: this.props.journeyData.email,
        phone: this.props.journeyData.phone,
        postcode: this.props.journeyData.postcode,
        addrLine1: this.props.journeyData.addrLine1,
        addrLine2: this.props.journeyData.addrLine2,
        city: this.props.journeyData.city,
        county: this.props.journeyData.county,
        mprn: this.props.journeyData.mprn,
        // Account correspondance
        accountCorrespondence: this.props.journeyData.accountCorrespondence,
        accCorrTitle: this.props.journeyData.accCorrTitle,
        accCorrFirstName: this.props.journeyData.accCorrFirstName,
        accCorrSurname: this.props.journeyData.accCorrSurname,
        accCorrName: this.props.journeyData.accCorrName,
        accCorrPostcode: this.props.journeyData.accCorrPostcode,
        accCorrAddLineOne: this.props.journeyData.accCorrAddLineOne,
        accCorrAddLineTwo: this.props.journeyData.accCorrAddLineTwo,
        accCorrCity: this.props.journeyData.accCorrCity,
        accCorrCounty: this.props.journeyData.accCorrCounty,
        accCorrEmail: this.props.journeyData.accCorrEmail,
        accCorrAddPhone: this.props.journeyData.accCorrAddPhone,
        // Joint account
        jointPerson: this.props.journeyData.jointPerson,
        jointTitle: this.props.journeyData.jointTitle,
        jointFirstName: this.props.journeyData.jointFirstName,
        jointSurname: this.props.journeyData.jointSurname,
        jointEmail: this.props.journeyData.jointEmail,
        jointPhone: this.props.journeyData.jointPhone,
        // Speical services
        serviceCheck: this.props.journeyData.serviceCheck,
        specialServices: this.props.journeyData.specialServices,
        requiredSpecialServices: this.props.journeyData.requiredSpecialServices,
        medicalServices: this.props.journeyData.medicalServices,
        requiredMedicalServices: this.props.journeyData.requiredMedicalServices,
        priorityRegister: this.props.journeyData.priorityRegister,
        //Nom person
        nomPerson: this.props.journeyData.nomPerson,
        nomCorrespondance: this.props.journeyData.nomCorrespondance,
        nomTitle: this.props.journeyData.nomTitle,
        nomFirstName: this.props.journeyData.nomFirstName,
        nomSurname: this.props.journeyData.nomSurname,
        nomEmail: this.props.journeyData.nomEmail,
        nomPhone: this.props.journeyData.nomPhone,
        nomPostcode: this.props.journeyData.nomPostcode,
        nomAddrLine1: this.props.journeyData.nomAddrLine1,
        nomAddrLine2: this.props.journeyData.nomAddrLine2,
        nomCity: this.props.journeyData.nomCity,
        nomCounty: this.props.journeyData.nomCounty,
        // Marketing
        markEmail: this.props.journeyData.markEmail,
        markSMS: this.props.journeyData.markSMS,
        markPost: this.props.journeyData.markPost,
        markPhone: this.props.journeyData.markPost,

        // Step two - (Your electricity)
        newConnection: this.props.journeyData.newConnection,
        meterReading: this.props.journeyData.meterReading,
        nightMeterReading: this.props.journeyData.nightMeterReading,
        annualUsage: this.props.journeyData.annualUsage,
        consumptionOption: this.props.journeyData.electricityUsageSelect,

        // Step three - Payment Details
        paymentMethod: this.state.paymentMethod,
        accountName: this.state.accountName,
        bankAccountNo: this.state.bankAccountNo,
        bankSortCode: this.state.bankSortCode,
        billingMethod: this.state.billingMethod,
        iban: this.state.iban,
        ddAmount: this.state.ddAmount,
        DDDayOfMonth: this.state.DDDayOfMonth,
        bankAddressLineOne: this.state.bankAddressLineOne,
        bankAddressLineTwo: this.state.bankAddressLineTwo,
        bankAddressPostcode: this.state.bankAddressPostcode,
        bankAddressCountry: this.state.bankAddressCountry,
        confirmDDAuth: this.state.confirmDDAuth,

        // Step four - Switch summary
        agreeToTerms: this.props.journeyData.agreeToTerms,
        agreeToPrivacyPolicy: this.props.journeyData.agreeToPrivacyPolicy,
        switchCompleted: false,
      };

      // Hide Error Message (if visiable)
      this.setState({
        errorMessage: false,
      });

      // SwitchID
      let switchID = localStorage.getItem("switchID");

      // This Page (updates page status)
      let pageStatus = "paymentUpdated";

      // Next Page URL
      let nextPage = "summary";

      // Payment method - needs passed to take deposit for vdd
      let paymentMethod = this.state.paymentMethod;

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Database Entry
      this.props.updateSwitch(
        switchID,
        data,
        pageStatus,
        nextPage,
        paymentMethod,
        jurisdiction
      );
    } else {
      // For each form input that has the required prop add the input-error class
      $("form#paymentDetails")
        .find("input")
        .each(function () {
          if ($(this).prop("required")) {
            $(this).addClass("input-error");
          }
        });

      // Display the error message
      this.setState({
        errorMessage: true,
      });

      // Scroll to error
      window.location.href = "#errorMessage";
    }
  };

  // Handle payment type
  paymentTypeClick = (value) => {
    switch (value) {
      case "SetDirectDebit":
        this.setState({
          paymentMethod: value,
          depositMessage: false,
          phoneMessage: false,
          showDirectDebitDetails: true,
          showSecurityDeposit: false,
          require: true,
          showDepositMessage: false,
        });
        break;

      case "VariableDirectDebit":
        this.setState({
          paymentMethod: value,
          depositMessage: true,
          phoneMessage: false,
          showDirectDebitDetails: true,
          showSecurityDeposit: false,
          require: true,
          showDepositMessage: true,
        });
        localStorage.setItem("paymentMethod", "VariableDirectDebit");
        break;

      case "PayOnReceipt":
        this.setState({
          paymentMethod: value,
          depositMessage: true,
          phoneMessage: true,
          showDirectDebitDetails: false,
          showSecurityDeposit: true,
          require: false,
          showDepositMessage: false,
        });
        localStorage.setItem("paymentMethod", "PayOnReceipt");

        break;

      default:
        this.setState({
          paymentMethod: value,
          depositMessage: false,
          showDirectDebitDetails: false,
          require: false,
        });
        break;
    }
  };

  render() {
    return (
      <div className='quote-page switch-page'>
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        <form id='paymentDetails'>
          {/* Background Image */}
          <div className='device-image' />
          <div className='quote-content-container'>
            {/* Logo */}
            <MDBRow className='text-center'>
              <MDBCol lg='2' sm='12'>
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <img
                    src={require("../../../../images/global/bright-logo-dark.png")}
                    className='quote-logo mx-5 mt-5'
                  />
                </a>
              </MDBCol>
              <MDBCol size='10' />
            </MDBRow>

            {/* Home message */}
            {this.state.homeCheck ? (
              <div className='modalBG'>
                <div className='modalBox'>
                  <div className='white-curved-tile text-center'>
                    <h4 className='mt-5'>
                      All progress will be lost! <br />
                      Are you sure?
                    </h4>
                    <MDBRow>
                      <MDBCol lg='6' md='6' sm='12'>
                        <MDBBtn
                          className='cancel-button'
                          onClick={() =>
                            this.setState({ homeCheck: !this.state.homeCheck })
                          }
                        >
                          Cancel
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol lg='6' md='6' sm='12'>
                        <MDBBtn
                          onClick={() =>
                            (window.location.href = `/${this.state.jurisdiction}/`)
                          }
                        >
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            <MDBContainer className='personal-details-section'>
              <MDBRow>
                {/* Progress Bar */}
                <MDBCol size='12'>
                  <MDBContainer className='personal-details-section'>
                    <MDBRow>
                      <MDBCol md='12' className='switch-navigation-container'>
                        <div className='border-light'>
                          <img
                            className='brand-image show-for-medium'
                            src={require("../../../../images/NI Switch/ThreeStepPayment.png")}
                          />
                        </div>
                      </MDBCol>

                      {/* Error Message */}
                      <MDBCol size='12' id='errorMessage'>
                        {this.state.errorMessage == true ? (
                          <MDBAlert color='danger' className='mb-5' dismiss>
                            <strong>Oops!</strong> Please check your fields
                            below.
                          </MDBAlert>
                        ) : null}
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </MDBCol>

                {/* Page content */}
                <MDBRow>
                  <MDBCol md='12' lg='8'>
                    {/* Payment Type */}
                    <div className='text-left white-curved-tile'>
                      <MDBCol>
                        {/* Question */}
                        <h4 className='my-4'>
                          How do you want to pay for your electricity?
                        </h4>

                        {/* Deposit message */}
                        {this.state.showDepositMessage ? (
                          <MDBAlert className='blue-highlight my-3'>
                            <p className='black-text'>
                              Security Deposit: We want to keep our costs as low
                              as possible so that we can keep your costs low. To
                              reduce the risk of bad debt we require all
                              customers not paying in advance by direct debit to
                              pay <b className='font-weight-bolder'>£125</b>{" "}
                              security deposit. Details of our security deposit
                              policy can be found{" "}
                              <a
                                href={`/${this.state.jurisdiction}/ourpolicies#security-deposit-policy`}
                                target='_blank'
                              >
                                <b className='font-weight-bolder'>here</b>
                              </a>
                              <br />
                              <br />
                              You will be prompted for a deposit payment on the
                              next screen.
                            </p>
                          </MDBAlert>
                        ) : null}

                        <MDBRow>
                          <MDBCol
                            sm='12'
                            md='4'
                            lg='4'
                            className='paymentOption text-center'
                          >
                            {/* Fixed Direct Debit */}
                            <input
                              onClick={() =>
                                this.paymentTypeClick("SetDirectDebit")
                              }
                              checked={
                                this.state.paymentMethod === "SetDirectDebit"
                                  ? true
                                  : false
                              }
                              className='form-check-input'
                              id='SetDirectDebit'
                              name='paymentMethod'
                              required
                              type='radio'
                            />
                            <label for='SetDirectDebit' className='radioLabel'>
                              Monthly set direct debit
                            </label>
                          </MDBCol>
                          <MDBCol
                            sm='12'
                            md='4'
                            lg='4'
                            className='paymentOption text-center'
                          >
                            {/* Variable direct debit */}
                            <input
                              onClick={() =>
                                this.paymentTypeClick("VariableDirectDebit")
                              }
                              checked={
                                this.state.paymentMethod ===
                                "VariableDirectDebit"
                                  ? true
                                  : false
                              }
                              className='form-check-input'
                              id='VariableDirectDebit'
                              name='paymentMethod'
                              type='radio'
                            />
                            <label
                              for='VariableDirectDebit'
                              className='radioLabel'
                            >
                              Variable direct debit
                            </label>
                          </MDBCol>
                          <MDBCol
                            sm='12'
                            md='4'
                            lg='4'
                            className='paymentOption text-center'
                          >
                            {/* On receipt of bill */}
                            <input
                              onClick={() =>
                                this.paymentTypeClick("PayOnReceipt")
                              }
                              checked={
                                this.state.paymentMethod === "PayOnReceipt"
                                  ? true
                                  : false
                              }
                              className='form-check-input'
                              id='PayOnReceipt'
                              name='paymentMethod'
                              type='radio'
                            />
                            <label for='PayOnReceipt' className='radioLabel'>
                              Pay on receipt of bill
                            </label>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </div>

                    {this.state.showDirectDebitDetails ? (
                      <div>
                        {/* Direct debit table */}
                        <div className='text-left white-curved-tile white-curved-tileDD'>
                          <MDBRow>
                            <h4>Direct Debit Details</h4>
                            <MDBCol size='12'>
                              <MDBTable className='mt-4'>
                                <MDBTableBody>
                                  <tr>
                                    <td>Tariff name</td>
                                    <td className='text-center'>
                                      {this.state.tariffName}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Meter type</td>
                                    <td className='text-center'>
                                      {this.props.journeyData.meterType}
                                    </td>
                                  </tr>
                                  {this.props.journeyData.meterType ==
                                  "24hr" ? (
                                    <tr>
                                      <td>Unit rate Inc. VAT (pence per kWh)</td>
                                      <td className='text-center'>
                                        {config.sc_tfh_unit_incVat}
                                      </td>
                                    </tr>
                                  ) : (
                                    <tr>
                                      <td>Unit rate Inc. VAT (pence per kWh)</td>
                                      <td className='text-center'>
                                        Day rate -{" "}
                                        {config.sc_eco7_unitday_incVat}
                                        <br />
                                        Night rate -{" "}
                                        {config.sc_eco7_unitnight_incVat}
                                        <br />
                                        Heat rate -{" "}
                                        {config.sc_eco7_unitheat_incVat}
                                      </td>
                                    </tr>
                                  )}
                                  {this.props.journeyData.meterType ==
                                  "24hr" ? (
                                    <tr>
                                      <td>Standing charge (pence per day)</td>
                                      <td className='text-center'>
                                        {config.sc_tfh_standing_incVat.toFixed(2)}
                                      </td>
                                    </tr>
                                  ) : (
                                    <tr>
                                      <td>Standing charge (pence per day)</td>
                                      <td className='text-center'>
                                        {config.sc_eco7_standing_incVat.toFixed(2)}
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td>Direct debit amount</td>
                                    {this.state.paymentMethod ==
                                    "SetDirectDebit" ? (
                                      <td className='text-center'>
                                        £{this.props.journeyData.ddAmount}
                                      </td>
                                    ) : (
                                      <td className='text-center'>Variable</td>
                                    )}
                                  </tr>
                                  <tr>
                                    <td>Type of payment</td>
                                    <td className='text-center'>Recurrent</td>
                                  </tr>
                                  <tr>
                                    <td>Collection Date</td>
                                    <td className='text-center'>
                                      {this.state.collectionDate}
                                    </td>
                                  </tr>
                                </MDBTableBody>
                              </MDBTable>
                            </MDBCol>
                          </MDBRow>
                        </div>

                        {/* Payment form */}
                        <div className='text-left white-curved-tile'>
                          {/* Payment Details */}
                          <MDBRow className='mb-2'>
                            <MDBCol lg='12' className='text-left p-0'>
                              <label className='input-label'>
                                Account holder's name
                              </label>
                              {/* Account Holder Name */}
                              <MDBInput
                                id='accountName'
                                pattern='^[A-Z a-z]{1,60}$'
                                label='Account Holders Name'
                                outline
                                onChange={this.handleChange("accountName")}
                                required
                                value={this.state.accountName}
                              />

                              {/* Address Check */}
                              <label
                                for='useAccAddress'
                                className='input-label'
                              >
                                Would you like to use your account address?
                              </label>
                              <div className='radio-container'>
                                {/* Yes */}
                                <input
                                  id='useAccAddressYes'
                                  name='useAccAddress'
                                  type='radio'
                                  onClick={() =>
                                    this.setState({
                                      useAccAddress: true,
                                      showbankAddress: false,
                                      bankAddressRequired: false,
                                    })
                                  }
                                  required={this.state.required}
                                  className='default-radio'
                                  checked={
                                    this.state.useAccAddress ? true : false
                                  }
                                />
                                <label for='useAccAddressYes'>Yes</label>
                                {/* No */}
                                <input
                                  id='useAccAddressNo'
                                  name='useAccAddress'
                                  type='radio'
                                  onClick={() =>
                                    this.setState({
                                      useAccAddress: false,
                                      showbankAddress: true,
                                      bankAddressRequired: true,
                                    })
                                  }
                                  required={this.state.required}
                                  className='default-radio'
                                  checked={
                                    this.state.useAccAddress === false
                                      ? true
                                      : false
                                  }
                                />
                                <label for='useAccAddressNo'>No</label>
                              </div>

                              {/* Show if useAccAddress = false */}
                              {this.state.showbankAddress ? (
                                <div>
                                  {/* Address */}
                                  <label className='input-label'>Address</label>
                                  {/* bank address line one */}
                                  <MDBInput
                                    id='bankAddressLineOne'
                                    pattern='^[A-Z a-z 0-9]{1,32}$'
                                    label='Address line one'
                                    outline
                                    onChange={this.handleChange(
                                      "bankAddressLineOne"
                                    )}
                                    required={this.state.bankAddressRequired}
                                    value={this.state.bankAddressLineOne}
                                  />
                                  {/* bank address line two */}
                                  <MDBInput
                                    id='bankAddressLineTwo'
                                    pattern='^[A-Z a-z 0-9]{1,32}$'
                                    label='Address line two'
                                    outline
                                    onChange={this.handleChange(
                                      "bankAddressLineTwo"
                                    )}
                                    value={this.state.bankAddressLineTwo}
                                  />
                                  {/* bank city/postcode */}
                                  <MDBInput
                                    id='bankAddressPostcode'
                                    label='City/Postcode'
                                    outline
                                    onChange={this.handleChange(
                                      "bankAddressPostcode"
                                    )}
                                    required={this.state.bankAddressRequired}
                                    value={this.state.bankAddressPostcode}
                                    className='field-to-uppercase'
                                    pattern='([AC-FHKNPRTV-Y]\d{2}|D6W)[0-9AC-FHKNPRTV-Y]{4}'
                                  />
                                </div>
                              ) : null}

                              {/* Account number */}
                              <label className='input-label'>
                                Account number
                              </label>
                              <MDBInput
                                id='bankAccountNo'
                                label='Account number'
                                outline
                                onChange={this.handleChange("bankAccountNo")}
                                required
                                value={this.state.bankAccountNo}
                                pattern='([0-9]){8}$'
                                className='field-to-uppercase'
                              />

                              {/* Sort Code */}
                              <label className='input-label'>Sort code</label>
                              <MDBInput
                                id='bankSortCode'
                                label='Sort code'
                                outline
                                onChange={this.handleChange("bankSortCode")}
                                required
                                value={this.state.bankSortCode}
                                pattern='\b([0-9]{2})-?([0-9]{2})-?([0-9]{2})\b'
                              />

                              {/* DD Confirmation */}
                              <MDBRow>
                                <label className='input-label'>
                                  Direct debit confirmation
                                </label>
                                <div className='mt-3 ml-3'>
                               
                                  <label for='ddconfirmation' className='ml-2'>
                                  <input
                                    className='mt-1'
                                    id='ddconfirmation'
                                    name='ddconfirmation'
                                    type='checkbox'
                                    onClick={() =>
                                      this.setState({
                                        confirmDDAuth: !this.state
                                          .confirmDDAuth,
                                      })
                                    }
                                    required={this.state.required}
                                    checked={
                                      this.state.confirmDDAuth ? true : false
                                    }
                                  /> Confirm you are authorised to make direct
                                    debit payments on this account
                                  </label>
                                </div>
                              </MDBRow>
                            </MDBCol>
                          </MDBRow>

                          <p className='input-label'>
                            Our mission is to make the future brighter by the
                            use of 100% green electricity. To go that one step
                            further on our sustainability mission, we opt for
                            e-billing. Your bill will also be available in the
                            account section on app or website.
                          </p>

                          <p className='input-label'>
                            If you would like to receive your bill by post
                            please contact us at: hello@brightenergy.com
                          </p>
                        </div>

                        {/* Buttons */}
                        <MDBRow size='12'>
                          {/* Back */}
                          <MDBCol size='6'>
                            <h5>
                            <span classname='step-text'>Previous Step:</span> Additional Services
                            </h5>
                            <a
                              href={`/${this.state.jurisdiction}/switch/electricity`}
                            >
                              <MDBBtn className='btn switch-button'>
                                Back
                              </MDBBtn>
                            </a>
                          </MDBCol>
                          {/* Next */}
                          <MDBCol size='6'>
                            <h5>
                            <span className='step-text'> Next Step:</span> Switch Summary
                            </h5>
                            <MDBBtn className='btn' onClick={this.nextStep}>
                              Continue
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    ) : this.state.showSecurityDeposit ? (
                      <Elements stripe={stripePromise}>
                        <DepositDetailsForm nextStep={this.nextStep} />
                      </Elements>
                    ) : null}
                  </MDBCol>

                  {/* Right Column */}
                  <MDBCol size='4' className='show-for-large'>
                    <img
                      className='brand-image'
                      src={require("../../../../images/NI Switch/GreenElectricity.png")}
                    />

                    {/* bright legal info
                      <div className='text-left white-curved-tile mt-5'>
                        <p className='input-label ml-2'>
                          Creditor identifier: IE84ZZZ362173 <br />
                          Company name: Ever Energy Supply Limited t/a bright
                          <br />
                          Address: 3 Custom House Plaza, IFSC Dublin, D01 VY76
                        </p>

                        <hr className='switch-payment-hr ml-2' />

                       
                        <p className='input-label ml-2'>
                          By signing this mandate form, you authorise (A) Ever
                          Energy Supply Limited t/a bright to send instructions
                          to your bank to debit your account and (B) your bank
                          to debit your account in accordance with the
                          instruction from Ever Energy Supply Limited t/a
                          bright. As part of your rights, you are entitled to a
                          refund from your bank under the terms and conditions
                          of your agreement with your bank. A refund must be
                          claimed within 8 weeks starting from the date on which
                          your account was debited. Your rights are explained in
                          a statement that you can obtain from your bank.
                        </p>
                      </div>
                       */}

                    {/* Chat functionality */}
                    <div className='white-curved-tile text-left mt-3'>
                      <h5 className='mt-4 text-center'>Need some help?</h5>
                      {/* Live chat */}
                      <div className='switch-live-chat'>
                        <div data-id='VVUGGBG3WTc' class='livechat_button'>
                          <a href='https://www.livechat.com/utm-builder/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_11999496'>
                            UTM Builder
                          </a>
                        </div>
                        <LiveChat license={11999496} />
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBRow>
            </MDBContainer>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.switch.isLoading,
    journeyData: store.switch.switchData,
    quoteData: store.quote.quoteData,
  };
};

const actionCreators = {
  getSwitch: switchActions.getSwitchJourney,
  updateSwitch: switchActions.updateSwitchJourney,
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(PaymentDetails);
