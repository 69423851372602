import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from "mdbreact";
import { connect } from "react-redux";
import { quoteActions } from "../../../../../../redux/actions/quoteActions";
const LoadConfig = require("../../../../../../config");
const config = LoadConfig.default;

export class SetUrban24hr extends Component {
  state = {
    tariffName: "bright future",
    meterType: "Urban 24hr",
    unitRate: (config.TFH).toFixed(3),
    standingCharge: ((config.urbanStandingCharge/365)*100).toFixed(2),
    psoCharge: config.monthlyPsoLevy.toFixed(2),
    ddAmount: "",
    annualUsage: 0,
    collectionDate: "11 working days",
  };

  // Page load
  componentDidMount() {
    let ddAmount = localStorage.getItem("ddAmount");
    this.setState({ ddAmount: ddAmount });

    // // Calculate Direct debit amount
    // let unitRate = 0.1408;
    // let annualUsage = localStorage.getItem("units");
    // let standingCharge = 23.093;
    // let psoCharge = 3.223;
    // let ddAmount = unitRate * annualUsage;
    // ddAmount = ddAmount + standingCharge;
    // ddAmount = ddAmount + psoCharge;
    // ddAmount = ddAmount / 12;
    // ddAmount = ddAmount.toFixed(2);
    // this.setState({ ddAmount: ddAmount });
    // localStorage.setItem("amount", ddAmount);
  }

  render() {
    return (
      <div>
        <MDBRow>
          <h4>Direct Debit Details</h4>
          <MDBCol size='12'>
            <MDBTable className='mt-4'>
              <MDBTableBody>
                <tr>
                  <td>Tariff name</td>
                  <td className='text-center'>{this.state.tariffName}</td>
                </tr>
                <tr>
                  <td>Meter type</td>
                  <td className='text-center'>{this.state.meterType}</td>
                </tr>
                <tr>
                  <td>Unit rate (cent per kWh)</td>
                  <td className='text-center'>{this.state.unitRate}</td>
                </tr>
                <tr>
                  <td>Standing charge (cent per day)</td>
                  <td className='text-center'>{this.state.standingCharge}</td>
                </tr>
                <tr>
                  <td>PSO charge (€ per month)</td>
                  <td className='text-center'>{this.state.psoCharge}</td>
                </tr>
                <tr>
                  <td>Direct debit amount</td>
                  <td className='text-center'>€{parseInt(this.state.ddAmount).toFixed(0)}</td>
                </tr>
                <tr>
                  <td>Type of payment</td>
                  <td className='text-center'>Recurrent</td>
                </tr>
                <tr>
                  <td>Collection Date</td>
                  <td className='text-center'>{this.state.collectionDate}</td>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    quoteData: store.quote.quoteData,
  };
};

const actionCreators = {
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(SetUrban24hr);
