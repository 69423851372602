import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBTable } from "mdbreact";
import FAQTabs from "../../components/Help/FAQTabs";

import FixedNavDark from "../../components/Shared/FixedNavDark";
import $ from "jquery";

class FAQsGettingStarted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark/> */}

        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>FAQs</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow className="pb-5">
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <FAQTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5'>
            <h3>Getting Started</h3>

            <div id='howtoswitch' className='mt-5 mb-5'>
              <h5 className='policy-title'>How do I switch to bright? </h5>
              <p className='policy-content'>
                Switching to bright is easy peasy. You can switch today via our website, all you need are your bank details and your latest bill or if you prefer you can contact us on 01 588 1777.  Have a look for yourself at the benefits that come with being a Bright customer <a href={`/${this.state.jurisdiction}/about/switchingtobright`}>here.</a>
              </p>
            </div>

            <div id='previoussupplier' className='mb-5'>
              <h5 className='policy-title'>Do I need to contact my previous supplier? </h5>
              <p className='policy-content'>
                No you will not need to contact your current electricity supplier. We will handle the switch with your existing supplier and we will be in touch with you to advise when the switch has been completed. Your existing supplier will issue you with a final bill.  You are responsible for paying any monies owed to your existing supplier.
              </p>
            </div>

            <div id='firstbill' className='mb-5'>
              <h5 className='policy-title'>When can I expect my first bill from bright? </h5>
              <p className='policy-content'>
                We will bill you on a monthly basis so you should receive your first bill from bright approximately one month after your switch is complete and monthly thereafter. If you haven't received your bill within this time simply get in touch and our energy specialists will look into this for you.
              </p>
            </div>

            <div id='changemind' className='mb-5'>
              <h5 className='policy-title'>What should I do if I change my mind about switching to bright? </h5>
              <p className='policy-content'>
                When you sign up there is a cooling off period of 14 days in which to change your mind. At time of sign up we will tell you about the cooling off period and when it starts, to cancel your switch simply get in touch with us within this 14 period and we'll cancel your switch.
              </p>
            </div>

            <div id='mprn' className='mb-5'>
              <h5 className='policy-title'>What is my MPRN (Meter Point Reference Number)? </h5>
              <p className='policy-content'>
                A Meter Point Reference Number (or MPRN) is a unique 11 digit number starting with 10 to identify your premises meter point address. You will need your MPRN if you want to switch to bright or are getting a first time electricity connection to your home. You can find your MPRN on the top right of your electricity bill from your current electricity supplier, ask your landlord, agent or previous owner or you can call ESB Networks on 1850 372 757 or 021 2386555 to get your MPRN.
              </p>
            </div>

            <div id='debtflag' className='mb-5'>
              <h5 className='policy-title'>What is a debt flag? </h5>
              <p className='policy-content'>
                Your existing supplier may raise a debt flag on your property when you try to switch if you owe more than €225 for more than 60 days.  If a debt flag is raised we may not proceed with your switch until the debt has been cleared.  This process has been put in place by CRU to discourge customers from debt hopping.
              </p>
            </div>

            <div id='ev' className='mb-5'>
              <h5 className='policy-title'>If I have an electric vehicle, what is best for me? </h5>
              <p className='policy-content'>
              This depends on when you are likely to charge your car. If you charge overnight, the best meter for you is Day/Night. Our Day/Night tariff is a dual-rate tariff offering different unit rates for day and night. The night rate is significantly cheaper, allowing you to save money whilst charging overnight. If you plan on charging your car during the day, a standard credit meter will be most appropriate for you with a flat-rate tariff both day and night.
               If you have a smart meter, you can save money by charging your car overnight rather than during the day, or at peak times.
              </p>
            </div>

            <div id='annualbill' className='mb-5'>
              <h5 className='policy-title'>What is an Estimated Annual Bill? </h5>
              <p className='policy-content'>
                The EAB is a simple indicator for customers to help them make decisions when comparing tariffs. It calculates an annual bill based on typical consumption figures provided by the CRU and the charges associated with the tariff including the unit rate, standing charge, PSO levy and VAT. This allows you to compare one tariff against another. Please note that your usage will vary from the CRU typical consumption figure. This is not an indication of how much your electricity will cost, but a useful comparison across tariffs.
            <br />
                <br />
            We provide an EAB for our 24hr tariff, day/night tariff and standard smart tariff.  These are based on the average usage of a domestic property in Ireland.  The table below shows the profile we use to calculate the EAB for each tariff.
            </p>
            </div>

            <MDBTable bordered>
              <thead><tr class=""><th>Average usage</th><th>4200 kWh</th><th>&nbsp;</th><th colSpan="2">&nbsp;</th></tr></thead><tbody>
                <tr className="montserrat-Bold"><td>Profile</td><td>24hr</td><td>Day/Night</td><td colSpan="2">Standard Smart</td></tr>
                <tr className="montserrat-Bold"><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>Urban</td><td>Rural</td></tr>
                <tr><td>Day</td><td>100%</td><td>50%</td><td>63%</td><td>63%</td></tr>
                <tr><td>Night</td><td></td><td>50%</td><td>24%</td><td>25%</td></tr>
                <tr><td>Peak</td><td>&nbsp;</td><td>&nbsp;</td><td>13%</td><td>12%</td></tr>
              </tbody></MDBTable>
          </MDBCol>
        </MDBRow>
      </div>

      
    );
  }
}

export default FAQsGettingStarted;
