export const keypadConstants = {
  // Get All Posts
  GET_ALL_KEYPADTOPS_REQUEST: "GET_ALL_KEYPADTOPS_REQUEST",
  GET_ALL_KEYPADTOPS_REQUEST_SUCCESS: "GET_ALL_KEYPADTOPS_REQUEST_SUCCESS",
  GET_ALL_KEYPADTOPS_REQUEST_FAILURE: "GET_ALL_KEYPADTOPS_REQUEST_FAILURE",

  KEYPADVEND_INTENT_REQUEST: "KEYPADVEND_INTENT_REQUEST",
  KEYPADVEND_INTENT_REQUEST_SUCCESS: "KEYPADVEND_INTENT_SUCCESS",
  KEYPADVEND_INTENT_REQUEST_FAILURE: "KEYPADVEND_INTENT_REQUEST_FAILURE",
};
