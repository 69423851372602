import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBContainer,
  MDBAlert,
} from "mdbreact";
import $ from "jquery";
import LiveChat from "react-livechat";
import Loader from "../../../Shared/Loader";

// Redux
import { connect } from "react-redux";
import { switchActions } from "../../../../redux/actions/switchActions";
import { quoteActions } from "../../../../redux/actions/quoteActions";

export class _FormYourElectricity extends Component {
  state = {
    // Your Electricity
    newConnection: false,
    meterReading: "",
    nightMeterReading: "",
    kpn: "",
    annualUsage: "",
    loading: false,

    // Show/hide sections
    showError: false,
    knowUsage: false,
    errorMessage: false,
    meterReadingTip: false,
    useageTip: false,
    switchGot: false,
    jurisdiction: "roi",
    homeCheck: false,
    newConnectionTip: false,

    // For nightsaver meter
    showDayMeterLabel: false,
    showNightSaver: false,
  };

  // Page Load
  componentDidMount() {
    let switchID = localStorage.getItem("switchID");

    if (switchID != null) {
      this.props.getSwitch(switchID);
      this.setState({ switchGot: true });
    }

    let quoteID = localStorage.getItem("quoteID");

    if (quoteID != null) {
      this.props.getQuote(quoteID);
    }
  }

  // If Props.SwitchData true populate form
  async componentWillReceiveProps(nextProps) {
    let sectionUpdated = JSON.parse(localStorage.getItem("electricityUpdated"));

    if (this.state.switchGot && sectionUpdated) {
      await this.setState({
        newConnection: nextProps.journeyData.newConnection,
        meterReading: nextProps.journeyData.meterReading,
        kpn: nextProps.journeyData.kpn,
        annualUsage: nextProps.journeyData.annualUsage,
        nightMeterReading: nextProps.journeyData.nightMeterReading,
      });
    }

    localStorage.setItem("ddAmount", nextProps.journeyData.ddAmount);

    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle Field Change
  handleChange = (input) => (e) => {
    var fieldValue = $("#" + input)[0].checkValidity();

    if (fieldValue) {
      $("#" + input)
        .addClass("input-valid")
        .removeClass("input-error");
    } else {
      $("#" + input)
        .addClass("input-error")
        .removeClass("input-valid");
    }

    this.setState({ [input]: e.target.value });
  };

  // Continue to next section
  nextStep = (e) => {
    // Prevent from from clearing
    e.preventDefault();

    // Validate Form
    let validation = $("#yourElectricity")[0].checkValidity();

    if (validation) {
      // Form Data
      const data = {
        // Step One - (Account Details)
        accountHolder: this.props.journeyData.accountHolder,
        title: this.props.journeyData.title,
        firstName: this.props.journeyData.firstName,
        surname: this.props.journeyData.surname,
        dob: this.props.journeyData.dob,
        email: this.props.journeyData.email,
        phone: this.props.journeyData.phone,
        postcode: this.props.journeyData.postcode,
        addrLine1: this.props.journeyData.addrLine1,
        addrLine2: this.props.journeyData.addrLine2,
        city: this.props.journeyData.city,
        county: this.props.journeyData.county,
        mprn: this.props.journeyData.mprn,
        // Smart meter
        smartCustomer: this.props.journeyData.smartCustomer,
        halfHourly: this.props.journeyData.halfHourly,
        // Account Correspondance
        accountCorrespondence: this.props.journeyData.accountCorrespondence,
        accCorrTitle: this.props.journeyData.accCorrTitle,
        accCorrFirstName: this.props.journeyData.accCorrFirstName,
        accCorrSurname: this.props.journeyData.accCorrSurname,
        accCorrName: this.props.journeyData.accCorrName,
        accCorrPostcode: this.props.journeyData.accCorrPostcode,
        accCorrAddLineOne: this.props.journeyData.accCorrAddLineOne,
        accCorrAddLineTwo: this.props.journeyData.accCorrAddLineTwo,
        accCorrCity: this.props.journeyData.accCorrCity,
        accCorrCounty: this.props.journeyData.accCorrCounty,
        accCorrEmail: this.props.journeyData.accCorrEmail,
        accCorrAddPhone: this.props.journeyData.accCorrAddPhone,
        // Joint Account
        jointPerson: this.props.journeyData.jointPerson,
        jointTitle: this.props.journeyData.jointTitle,
        jointFirstName: this.props.journeyData.jointFirstName,
        jointSurname: this.props.journeyData.jointSurname,
        jointEmail: this.props.journeyData.jointEmail,
        jointPhone: this.props.journeyData.jointPhone,
        // Marketing
        markEmail: this.props.journeyData.markEmail,
        markSMS: this.props.journeyData.markSMS,
        markPost: this.props.journeyData.markPost,
        markPhone: this.props.journeyData.markPost,

        // Step Two (This Page) - Your Electricity
        newConnection: this.state.newConnection,
        meterReading: this.state.meterReading,
        nightMeterReading: this.state.nightMeterReading,

        // Step Three - Additional Services
        serviceCheck: this.props.journeyData.serviceCheck,
        PDC: this.props.journeyData.PDC,
        specialServices: this.props.journeyData.specialServices,
        requiredSpecialServices: this.props.journeyData.requiredSpecialServices,
        medicalServices: this.props.journeyData.medicalServices,
        requiredMedicalServices: this.props.journeyData.requiredMedicalServices,
        priorityRegister: this.props.journeyData.priorityRegister,
        //nom person
        nomPerson: this.props.journeyData.nomPerson,
        nomCorrespondance: this.props.journeyData.nomCorrespondance,
        nomTitle: this.props.journeyData.nomTitle,
        nomFirstName: this.props.journeyData.nomFirstName,
        nomSurname: this.props.journeyData.nomSurname,
        nomEmail: this.props.journeyData.nomEmail,
        nomPhone: this.props.journeyData.nomPhone,
        nomPostcode: this.props.journeyData.nomPostcode,
        nomAddrLine1: this.props.journeyData.nomAddrLine1,
        nomAddrLine2: this.props.journeyData.nomAddrLine2,
        nomCity: this.props.journeyData.nomCity,
        nomCounty: this.props.journeyData.nomCounty,

        // Step Four - Payment Details
        accountName: this.props.journeyData.accountName,
        bankAccountNo: this.props.journeyData.bankAccountNo,
        bankSortCode: this.props.journeyData.bankSortCode,
        billingMethod: this.props.journeyData.billingMethod,
        iban: this.props.journeyData.iban,
        DDAmount: this.props.journeyData.DDAmount,
        DDDayOfMonth: this.props.journeyData.DDDayOfMonth,
        bankAddressLineOne: this.props.journeyData.bankAddressLineOne,
        bankAddressLineTwo: this.props.journeyData.bankAddressLineTwo,
        bankAddressPostcode: this.props.journeyData.bankAddressPostcode,
        bankAddressCountry: this.props.journeyData.bankAddressCountry,
        confirmDDAuth: this.props.journeyData.confirmDDAuth,

        // Step Five - Switch Summary
        confirmDDAuth: this.props.journeyData.confirmDDAuth,
        agreeToTerms: this.props.journeyData.agreeToTerms,
        agreeToPrivacyPolicy: this.props.journeyData.agreeToPrivacyPolicy,
        switchCompleted: false,
      };

      // Hide Error Message (if visiable)
      this.setState({
        errorMessage: false,
      });

      // SwitchID
      let switchID = localStorage.getItem("switchID");

      // This Page (updates page status)
      let pageStatus = "electricityUpdated";

      // Next Page URL
      let nextPage = "services";

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Database Entry
      this.props.updateSwitch(
        switchID,
        data,
        pageStatus,
        nextPage,
        "",
        jurisdiction
      );
    } else {
      // For each form input that has the required prop add the input-error class
      $("form#yourElectricity")
        .find("input")
        .each(function () {
          if ($(this).prop("required")) {
            $(this).addClass("input-error");
          }
        });

      // Display the error message
      this.setState({
        errorMessage: true,
      });

      // Scroll to error
      window.location.href = "#errorMessage";
    }
  };

  onCompareTariffClick = (ct) => () => {
    this.props.handleChange("compareTariff", ct);
    console.log(ct);
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    return (
      <div className="quote-page switch-page">
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        <form id="yourElectricity">
          {/* Background Image */}
          <div className="device-image" />
          {/* Logo */}
          <MDBRow className="text-center">
            <MDBCol lg="2" sm="12">
              <a onClick={() => this.setState({ homeCheck: true })}>
                <img
                  src={require("../../../../images/global/bright-logo-dark.png")}
                  className="quote-logo mx-5 mt-5"
                />
              </a>
            </MDBCol>
            <MDBCol size="10" />
          </MDBRow>

          {/* Home message */}
          {this.state.homeCheck ? (
            <div className="modalBG">
              <div className="modalBox">
                <div className="white-curved-tile text-center">
                  <h4 className="mt-5">
                    All progress will be lost! <br />
                    Are you sure?
                  </h4>
                  <MDBRow>
                    <MDBCol lg="6" md="6" sm="12">
                      <MDBBtn
                        className="cancel-button"
                        onClick={() =>
                          this.setState({ homeCheck: !this.state.homeCheck })
                        }
                      >
                        Cancel
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol lg="6" md="6" sm="12">
                      <MDBBtn onClick={() => (window.location.href = "/")}>
                        Continue
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
          ) : null}

          <MDBContainer className="personal-details-section">
            <MDBRow>
              <MDBCol size="12">
                <MDBRow>
                  {/* Progress Bar */}
                  <MDBCol md="12" className="switch-navigation-container">
                    <div className="border-light">
                      <img
                        className="brand-image show-for-medium"
                        src={require("../../../../images/quote/quote-nav-3.png")}
                      />
                    </div>
                  </MDBCol>

                  {/* Error Message */}
                  <MDBCol size="12" id="errorMessage">
                    {this.state.errorMessage == true ? (
                      <MDBAlert color="danger" className="mb-5" dismiss>
                        <strong>Oops!</strong> Please check your fields below.
                      </MDBAlert>
                    ) : null}
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md="12" lg="8">
                    <div className="text-left white-curved-tile">
                      <h4>Your Electricity</h4>
                      {/* New Connection */}
                      <label className="input-label">
                        Is this a new connection at a property which hasn't
                        previously had an electricity supply?
                        <MDBBtn className="tooltip-container">
                          <MDBIcon
                            icon="info-circle"
                            className="mr-2"
                            onClick={() =>
                              this.setState({
                                newConnectionTip: !this.state.newConnectionTip,
                              })
                            }
                          />
                          {this.state.newConnectionTip == true ? (
                            <span className="tooltip-content">
                              <img
                                className="brand-image"
                                src={require("../../../../images/global/bullet-large.png")}
                              />
                              <p>
                                Only select this if your property is a new build
                                or has never had an electricity supply before.
                                If you are not sure please give us a call on 01
                                588 1777"{" "}
                              </p>
                            </span>
                          ) : null}
                        </MDBBtn>
                      </label>

                      <div className="radio-container">
                        {/* Yes */}
                        <input
                          id="newConnectionYes"
                          name="newConnection"
                          type="radio"
                          onClick={() => this.setState({ newConnection: true })}
                          className="default-radio"
                          required
                          checked={this.state.newConnection ? true : false}
                        />
                        <label for="newConnectionYes">Yes</label>
                        {/* No */}
                        <input
                          id="newConnectionNo"
                          name="newConnection"
                          type="radio"
                          onClick={() =>
                            this.setState({ newConnection: false })
                          }
                          className="default-radio"
                          checked={
                            this.state.newConnection === false ? true : false
                          }
                        />
                        <label for="newConnectionNo">No</label>
                      </div>

                      {/* Meter reading */}
                      {!this.state.newConnection ? (
                        <div>
                          <MDBRow className="mb-2">
                            <MDBCol lg="12" className="text-left p-0">
                              <label className="input-label">
                                Tell us your current meter reading
                                <MDBBtn className="tooltip-container">
                                  <MDBIcon
                                    icon="info-circle"
                                    className="mr-2"
                                    onClick={() =>
                                      this.setState({
                                        meterReadingTip: !this.state
                                          .meterReadingTip,
                                      })
                                    }
                                  />
                                  {this.state.meterReadingTip == true ? (
                                    <span className="tooltip-content">
                                      <img
                                        className="brand-image"
                                        src={require("../../../../images/global/bullet-large.png")}
                                      />
                                      <p>
                                        Need help reading you meter? Check out
                                        our helpful meter reading guide{" "}
                                        <a
                                          href="/roi/faqs/meterreadings#submitreading"
                                          className="brightGreen-text"
                                          target="_blank"
                                        >
                                          here
                                        </a>
                                      </p>
                                    </span>
                                  ) : null}
                                </MDBBtn>
                              </label>

                              {/* Meter Reading */}
                              <MDBInput
                                label="Meter Reading"
                                type="text"
                                pattern="^[_0-9]{5}"
                                id="meterReading"
                                name="meterReading"
                                className="form-control"
                                outline
                                onChange={this.handleChange("meterReading")}
                                value={this.state.meterReading}
                              />
                            </MDBCol>
                          </MDBRow>
                          {this.props.quoteData != null &&
                          this.props.quoteData.meterType == "NightSaver" ? (
                            <MDBRow className="mb-2">
                              <MDBCol lg="12" className="text-left p-0">
                                <label className="input-label">
                                  Tell us your current NIGHT meter reading
                                </label>

                                {/* Meter Reading */}
                                <MDBInput
                                  label="Meter Reading"
                                  type="text"
                                  pattern="^[_0-9]{5}"
                                  id="nightMeterReading"
                                  name="nightMeterReading"
                                  className="form-control"
                                  outline
                                  onChange={this.handleChange(
                                    "nightMeterReading"
                                  )}
                                  required
                                  value={this.state.nightMeterReading}
                                />
                              </MDBCol>
                            </MDBRow>
                          ) : null}
                        </div>
                      ) : null}
                    </div>

                    {/* Buttons */}
                    <MDBRow size="12">
                      {/* Back */}
                      <MDBCol size="6">
                        <h5>
                          Previous Step: <span>Account Details</span>
                        </h5>

                        <a href={`/${this.state.jurisdiction}/switch/account`}>
                          <MDBBtn className="btn switch-button">Back</MDBBtn>
                        </a>
                      </MDBCol>
                      {/* Next */}
                      <MDBCol size="6">
                        <h5>
                          Next Step: <span>Additional Services</span>
                        </h5>
                        <MDBBtn className="btn" onClick={this.nextStep}>
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol size="4" className="show-for-large">
                    <img
                      className="brand-image"
                      src={require("../../../../images/switch/switch-tile.png")}
                    />

                    {/* Chat functionality */}
                    <div className="white-curved-tile text-left mt-3">
                      <h5 className="mt-4 text-center">Need some help?</h5>
                      {/* Live chat */}
                      <div className="switch-live-chat">
                        <div data-id="VVUGGBG3WTc" class="livechat_button">
                          <a href="https://www.livechat.com/utm-builder/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_11999496">
                            UTM Builder
                          </a>
                        </div>
                        <LiveChat license={11999496} />
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBCol size="2" />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.switch.isLoading,
    journeyData: store.switch.switchData,
    quoteData: store.quote.quoteData,
  };
};

const actionCreators = {
  getSwitch: switchActions.getSwitchJourney,
  updateSwitch: switchActions.updateSwitchJourney,
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(_FormYourElectricity);
