import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBRow,
  MDBAlert,
  MDBContainer,
  MDBIcon,
} from "mdbreact";
import Loader from "../../../Shared/Loader";
import LiveChat from "react-livechat";
import $ from "jquery";

// Redux
import { connect } from "react-redux";
import { switchActions } from "../../../../redux/actions";

export class _FormAccountDetails extends Component {
  state = {
    jurisdiction: "roi",
    dateSubmitted: new Date().toISOString().slice(0, 19),

    // Account Holder
    accountHolder: true,
    title: "",
    firstName: "",
    surname: "",
    dob: "",
    email: "",
    phone: "",
    postcode: "",
    addrLine1: "",
    addrLine2: "",
    city: "",
    county: "",
    mprn: "",

    // Account Correspondance
    accountCorrespondence: "",
    accCorrTitle: "",
    accCorrFirstName: "",
    accCorrSurname: "",
    accCorrName: "",
    accCorrPostcode: "",
    accCorrAddLineOne: "",
    accCorrAddLineTwo: "",
    accCorrCity: "",
    accCorrCounty: "",
    accCorrEmail: "",
    accCorrAddPhone: "",

    // Joint Account
    jointPerson: "",
    jointTitle: "",
    jointFirstName: "",
    jointSurname: "",
    jointEmail: "",
    jointPhone: "",

    // Marketing
    markEmail: false,
    markSMS: false,
    markPost: false,
    markPhone: false,

    errorMessage: false,
    tooltip: false,
    accountDetailsRequired: true,
    jointDetailsRequired: false,
    accountCorrespondanceRequired: false,
    switchGot: false,
    mprnTip: false,
    maxDob: "",
    homeCheck: false,

    // Smart meters
    smartMeter: false,
    smartTariff: false,
    smartTariffRequired: false,
    smartDataRequired: false,
    halfHourly: false,
  };

  // Page Load
  componentDidMount() {
    let switchID = localStorage.getItem("switchID");

    if (switchID != null) {
      this.props.getSwitch(switchID);
      this.setState({ switchGot: true });
    }

    try {
      let smartSelected = localStorage.getItem("SmartCustomer");
      if (smartSelected === "false") {
        this.setState({ smartMeter: false });
      }
    } catch {}
  }

  // If Props.journeyData true populate form
  async componentWillReceiveProps(nextProps) {
    let sectionUpdated = JSON.parse(localStorage.getItem("accountUpdated"));
    let mprn = localStorage.getItem("mprn");

    if (this.state.switchGot && sectionUpdated) {
      await this.setState({
        title: nextProps.journeyData.title,
        firstName: nextProps.journeyData.firstName,
        surname: nextProps.journeyData.surname,
        dob: nextProps.journeyData.dob.toString().slice(0, 10),
        email: nextProps.journeyData.email,
        phone: nextProps.journeyData.phone,
        postcode: nextProps.journeyData.postcode,
        addrLine1: nextProps.journeyData.addrLine1,
        addrLine2: nextProps.journeyData.addrLine2,
        city: nextProps.journeyData.city,
        county: nextProps.journeyData.county,
        mprn: mprn,

        // Smart meter
        smartTariff: nextProps.journeyData.smartCustomer,
        halfHourly: nextProps.journeyData.halfHourly,

        // Account Correspondance
        accountCorrespondence: nextProps.journeyData.accountCorrespondence,
        accCorrTitle: nextProps.journeyData.accCorrTitle,
        accCorrFirstName: nextProps.journeyData.accCorrFirstName,
        accCorrSurname: nextProps.journeyData.accCorrSurname,
        accCorrName: nextProps.journeyData.accCorrName,
        accCorrPostcode: nextProps.journeyData.accCorrPostcode,
        accCorrAddLineOne: nextProps.journeyData.accCorrAddLineOne,
        accCorrAddLineTwo: nextProps.journeyData.accCorrAddLineTwo,
        accCorrCity: nextProps.journeyData.accCorrCity,
        accCorrCounty: nextProps.journeyData.accCorrCounty,
        accCorrEmail: nextProps.journeyData.accCorrEmail,
        accCorrAddPhone: nextProps.journeyData.accCorrAddPhone,
        // Joint Account
        jointPerson: nextProps.journeyData.jointPerson,
        jointTitle: nextProps.journeyData.jointTitle,
        jointFirstName: nextProps.journeyData.jointFirstName,
        jointSurname: nextProps.journeyData.jointSurname,
        jointEmail: nextProps.journeyData.jointEmail,
        jointPhone: nextProps.journeyData.jointPhone,
        // Marketing
        markEmail: nextProps.journeyData.markEmail,
        markSMS: nextProps.journeyData.markSMS,
        markPost: nextProps.journeyData.markPost,
        markPhone: nextProps.journeyData.markPhone,
      });
    }

    if (nextProps.addressLookupData != null) {
      let houseNo = "";

      if (nextProps.addressLookupData.houseNo != null) {
        houseNo = nextProps.addressLookupData.houseNo;
      }

      if (nextProps.addressLookupData.meterCategory != null) {
        this.handleMRSO(nextProps.addressLookupData.meterCategory);
      }

      this.setState({
        mprn: this.state.mprn,
        addrLine1: houseNo,
        addrLine2: nextProps.addressLookupData.street1,
        city: nextProps.addressLookupData.city,
        county: nextProps.addressLookupData.region,
        postcode: nextProps.addressLookupData.postalCode,
        smartTariffRequired: true,
      });
    }

    if (nextProps.addressLookupError != "") {
      this.setState({
        postcode: "",
        addrLine1: "",
        addrLine2: "",
        city: "",
        county: "",
        mprn: mprn,
        smartTariffRequired: false,
        smartMeter: false,
      });
    }

    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle MRSO
  handleMRSO = (meterCategory) => {
    // Remove 'RM'
    let mCatNumber = meterCategory.slice(2);

    // Check if code is smart meter
    switch (mCatNumber) {
      case "100":
        this.setState({ smartMeter: true });
        break;
      case "101":
        this.setState({ smartMeter: true });
        break;
      case "102":
        this.setState({ smartMeter: true });
        break;
      case "103":
        this.setState({ smartMeter: true });
        break;
      case "104":
        this.setState({ smartMeter: true });
        break;
      case "105":
        this.setState({ smartMeter: true });
        break;
      case "106":
        this.setState({ smartMeter: true });
        break;
      case "300":
        this.setState({ smartMeter: true });
        break;
      case "301":
        this.setState({ smartMeter: true });
        break;
      case "302":
        this.setState({ smartMeter: true });
        break;
      case "303":
        this.setState({ smartMeter: true });
        break;
      case "304":
        this.setState({ smartMeter: true });
        break;
      case "350":
        this.setState({ smartMeter: true });
        break;
      case "351":
        this.setState({ smartMeter: true });
        break;
      case "352":
        this.setState({ smartMeter: true });
        break;
      case "353":
        this.setState({ smartMeter: true });
        break;
      case "354":
        this.setState({ smartMeter: true });
        break;
      default:
        this.setState({ smartMeter: false });
        break;
    }
  };

  // Handle Field Change
  handleChange = (input) => (e) => {
    // MPRN Check
    if (input == "mprn") {
      localStorage.setItem("mprn", e.target.value);
    }

    var fieldValue = $("#" + input)[0].checkValidity();

    //mprn check
    if (input.name == "mprn") {
      localStorage.setItem("mprn", e.target.value);
    }

    if (fieldValue) {
      $("#" + input)
        .addClass("input-valid")
        .removeClass("input-error");
    } else {
      $("#" + input)
        .addClass("input-error")
        .removeClass("input-valid");
    }

    this.setState({ [input]: e.target.value });
  };

  // If Account Correspondance YES set all fields to required
  accountCorrespondenceRequired = (required) => {
    if (required) {
      this.setState({
        accountCorrespondanceRequired: true,
      });
    } else {
      this.setState({
        accountCorrespondanceRequired: false,
      });
    }
  };

  // If Joint Account YES set all fields to required
  jointDetailsRequired = (required) => {
    if (required) {
      this.setState({
        jointDetailsRequired: true,
      });
    } else {
      this.setState({
        jointDetailsRequired: false,
      });
    }
  };

  // Check Switch Journey Call Type
  actionType = () => {
    let accountUpdated = JSON.parse(localStorage.getItem("accountUpdated"));

    if (accountUpdated) {
      this.updateSwitchJourney();
    } else {
      this.createSwitchJourney();
    }
  };

  // Updates excisting switch journey
  updateSwitchJourney = (e) => {
    // Validate Form
    let validation = $("#accountDetails")[0].checkValidity();

    if (validation) {
      // Form Data
      const updateData = {
        // Section One (This Page) - Account Details
        // Account Holder
        accountHolder: this.state.accountHolder,
        title: this.state.title,
        firstName: this.state.firstName,
        surname: this.state.surname,
        dob: new Date(this.state.dob).toISOString().slice(0, 19),
        email: this.state.email,
        phone: this.state.phone,
        postcode: this.state.postcode,
        addrLine1: this.state.addrLine1,
        addrLine2: this.state.addrLine2,
        city: this.state.city,
        county: this.state.county,
        mprn: this.state.mprn,
        // Smart meter
        smartCustomer: this.state.smartTariff,
        halfHourly: this.state.halfHourly,
        // Account Correspondance,
        accountCorrespondence: this.state.accountCorrespondence,
        accCorrTitle: this.state.accCorrTitle,
        accCorrFirstName: this.state.accCorrFirstName,
        accCorrSurname: this.state.accCorrSurname,
        accCorrName: this.state.accCorrName,
        accCorrPostcode: this.state.accCorrPostcode,
        accCorrAddLineOne: this.state.accCorrAddLineOne,
        accCorrAddLineTwo: this.state.accCorrAddLineTwo,
        accCorrCity: this.state.accCorrCity,
        accCorrCounty: this.state.accCorrCounty,
        accCorrEmail: this.state.accCorrEmail,
        accCorrAddPhone: this.state.accCorrAddPhone,
        // Joint Account
        jointPerson: this.state.jointPerson,
        jointTitle: this.state.jointTitle,
        jointFirstName: this.state.jointFirstName,
        jointSurname: this.state.jointSurname,
        jointEmail: this.state.jointEmail,
        jointPhone: this.state.jointPhone,
        // Marketing
        markEmail: this.state.markEmail,
        markSMS: this.state.markSMS,
        markPost: this.state.markPost,
        markPhone: this.state.markPhone,

        // Step Two - Your Electricity
        newConnection: this.props.journeyData.newConnection,
        meterReading: this.props.journeyData.meterReading,
        nightMeterReading: this.props.journeyData.nightMeterReading,

        // Step Three - Additional Services
        serviceCheck: this.props.journeyData.serviceCheck,
        PDC: this.props.journeyData.PDC,
        specialServices: this.props.journeyData.specialServices,
        requiredSpecialServices: this.props.journeyData.requiredSpecialServices,
        medicalServices: this.props.journeyData.medicalServices,
        requiredMedicalServices: this.props.journeyData.requiredMedicalServices,
        priorityRegister: this.props.journeyData.priorityRegister,
        //nom person
        nomPerson: this.props.journeyData.nomPerson,
        nomCorrespondance: this.props.journeyData.nomCorrespondance,
        nomTitle: this.props.journeyData.nomTitle,
        nomFirstName: this.props.journeyData.nomFirstName,
        nomSurname: this.props.journeyData.nomSurname,
        nomEmail: this.props.journeyData.nomEmail,
        nomPhone: this.props.journeyData.nomPhone,
        nomPostcode: this.props.journeyData.nomPostcode,
        nomAddrLine1: this.props.journeyData.nomAddrLine1,
        nomAddrLine2: this.props.journeyData.nomAddrLine2,
        nomCity: this.props.journeyData.nomCity,
        nomCounty: this.props.journeyData.nomCounty,

        // Step Four - Payment Details
        accountName: this.props.journeyData.accountName,
        bankAccountNo: this.props.journeyData.bankAccountNo,
        bankSortCode: this.props.journeyData.bankSortCode,
        billingMethod: this.props.journeyData.billingMethod,
        iban: this.props.journeyData.iban,
        DDAmount: this.props.journeyData.DDAmount,
        DDDayOfMonth: this.props.journeyData.DDDayOfMonth,
        bankAddressLineOne: this.props.journeyData.bankAddressLineOne,
        bankAddressLineTwo: this.props.journeyData.bankAddressLineTwo,
        bankAddressPostcode: this.props.journeyData.bankAddressPostcode,
        bankAddressCountry: this.props.journeyData.bankAddressCountry,
        confirmDDAuth: this.props.journeyData.confirmDDAuth,

        // Step Five - Switch Summary
        confirmDDAuth: this.props.journeyData.confirmDDAuth,
        agreeToTerms: this.props.journeyData.agreeToTerms,
        agreeToPrivacyPolicy: this.props.journeyData.agreeToPrivacyPolicy,
        switchCompleted: false,
      };

      // Hide Error Message (if visiable)
      this.setState({
        errorMessage: false,
      });

      // SwitchID
      let switchID = localStorage.getItem("switchID");

      // This Page (updates page status)
      let pageStatus = "electricityUpdated";

      // Next Page URL
      let nextPage = "";

      // Smart customer
      if (this.state.smartTariff) {
        localStorage.setItem("SmartCustomer", true);
        nextPage = "services";
      } else {
        localStorage.setItem("SmartCustomer", false);
        nextPage = "electricity";
      }

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Database Entry
      this.props.updateSwitch(
        switchID,
        updateData,
        pageStatus,
        nextPage,
        "",
        jurisdiction
      );
    } else {
      // For each form input that has the required prop add the input-error class
      $("form#accountDetails")
        .find("input")
        .each(function () {
          if ($(this).prop("required")) {
            $(this).addClass("input-error");
          }
        });

      // Scroll to error
      window.location.href = "#errorMessage";

      // Display the error message
      this.setState({
        errorMessage: true,
      });
    }
  };

  // Creates new switch journey
  createSwitchJourney = (e) => {
    // Validate Form
    let validation = $("#accountDetails")[0].checkValidity();

    let quoteID = localStorage.getItem("quoteID").toString();

    if (validation) {
      // Form Data
      const data = {
        jurisdiction: this.state.jurisdiction,
        dateSubmitted: this.state.dateSubmitted,
        quoteJourneyID: quoteID,
        switchCompleted: false,

        // Account Holder
        accountHolder: this.state.accountHolder,
        title: this.state.title,
        firstName: this.state.firstName,
        surname: this.state.surname,
        dob: new Date(this.state.dob).toISOString().slice(0, 19),
        email: this.state.email,
        phone: this.state.phone,
        postcode: this.state.postcode,
        addrLine1: this.state.addrLine1,
        addrLine2: this.state.addrLine2,
        city: this.state.city,
        county: this.state.county,
        mprn: this.state.mprn,

        // Smart meter
        smartCustomer: this.state.smartTariff,
        halfHourly: this.state.halfHourly,

        // Account Correspondance,
        accountCorrespondence: this.state.accountCorrespondence,
        accCorrTitle: this.state.accCorrTitle,
        accCorrFirstName: this.state.accCorrFirstName,
        accCorrSurname: this.state.accCorrSurname,
        accCorrName: this.state.accCorrName,
        accCorrPostcode: this.state.accCorrPostcode,
        accCorrAddLineOne: this.state.accCorrAddLineOne,
        accCorrAddLineTwo: this.state.accCorrAddLineTwo,
        accCorrCity: this.state.accCorrCity,
        accCorrCounty: this.state.accCorrCounty,
        accCorrEmail: this.state.accCorrEmail,
        accCorrAddPhone: this.state.accCorrAddPhone,

        // Joint Account
        jointPerson: this.state.jointPerson,
        jointTitle: this.state.jointTitle,
        jointFirstName: this.state.jointFirstName,
        jointSurname: this.state.jointSurname,
        jointEmail: this.state.jointEmail,
        jointPhone: this.state.jointPhone,

        // Marketing
        markEmail: this.state.markEmail,
        markSMS: this.state.markSMS,
        markPost: this.state.markPost,
        markPhone: this.state.markPhone,
      };

      // Hide Error Message (if visiable)
      this.setState({
        errorMessage: false,
      });

      // This Page (updates page status)
      let pageStatus = "accountUpdated";

      // Next Page URL
      let nextPage = "";

      // Smart customer
      if (this.state.smartTariff) {
        localStorage.setItem("SmartCustomer", true);
        nextPage = "services";
      } else {
        localStorage.setItem("SmartCustomer", false);
        nextPage = "electricity";
      }

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Create Database Entry
      this.props.createSwitch(data, pageStatus, nextPage, jurisdiction);
    } else {
      // For each form input that has the required prop add the input-error class
      $("form#accountDetails")
        .find("input")
        .each(function () {
          if ($(this).prop("required")) {
            $(this).addClass("input-error");
          }
        });

      // Display the error message
      this.setState({
        errorMessage: true,
      });

      // Scroll to error
      window.location.href = "#errorMessage";
    }
  };

  // Address lookup
  addressLookup = () => {
    let mprn = this.state.mprn;
    localStorage.setItem("mprn", mprn);
    this.props.addressLookup(mprn);
  };

  render() {
    return (
      <div className="quote-page switch-page">
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        <form id="accountDetails">
          {/* Background Image */}
          <div className="device-image" />
          <div className="quote-content-container">
            {/* Logo */}
            <MDBRow className="text-center">
              <MDBCol lg="2" sm="12">
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <img
                    src={require("../../../../images/global/bright-logo-dark.png")}
                    className="quote-logo mx-5 mt-5"
                  />
                </a>
              </MDBCol>
              <MDBCol size="10" />
            </MDBRow>

            {/* Home message */}
            {this.state.homeCheck ? (
              <div className="modalBG">
                <div className="modalBox">
                  <div className="white-curved-tile text-center">
                    <h4 className="mt-5">
                      All progress will be lost! <br />
                      Are you sure?
                    </h4>
                    <MDBRow>
                      <MDBCol lg="6" md="6" sm="12">
                        <MDBBtn
                          className="cancel-button"
                          onClick={() =>
                            this.setState({ homeCheck: !this.state.homeCheck })
                          }
                        >
                          Cancel
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol lg="6" md="6" sm="12">
                        <MDBBtn onClick={() => (window.location.href = "/")}>
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            <MDBContainer className="personal-details-section">
              <MDBRow>
                {/* Progress Bar */}
                <MDBCol size="12">
                  <MDBContainer className="personal-details-section">
                    <MDBRow>
                      <MDBCol md="12" className="switch-navigation-container">
                        <div className="border-light">
                          <img
                            className="brand-image show-for-medium"
                            src={require("../../../../images/quote/quote-nav-2.png")}
                          />
                          <img
                            className="brand-image hide-for-medium"
                            src={require("../../../../images/quote/progress-bar-2.png")}
                          />
                        </div>
                      </MDBCol>

                      {/* Error Message */}
                      <MDBCol size="12" id="errorMessage">
                        {this.state.errorMessage == true ? (
                          <MDBAlert color="danger" className="mb-5" dismiss>
                            <strong>Oops!</strong> Please check your fields
                            below.
                          </MDBAlert>
                        ) : null}
                      </MDBCol>

                      <MDBCol md="12" lg="8">
                        {/* Account Holder  Radio Buttons */}
                        <div className="white-curved-tile text-left">
                          <div>
                            <h4>Account Holder Details</h4>
                            {/* Acc Holder Title */}
                            <MDBRow>
                              <MDBCol lg="12" className="p-0">
                                <label className="input-label">Title</label>
                                <select
                                  id="title"
                                  pattern="[A-Za-z]{1,10}"
                                  className="browser-default custom-select mt-3"
                                  onChange={this.handleChange("title")}
                                  required={this.state.accountDetailsRequired}
                                  value={this.state.title}
                                >
                                  <option>Title</option>
                                  <option value="Mr">Mr</option>
                                  <option value="Mrs">Mrs</option>
                                  <option value="Miss">Miss</option>
                                  <option value="Ms">Ms</option>
                                  <option value="Dr">Dr</option>
                                  <option value="Prof">Prof</option>
                                </select>
                              </MDBCol>
                            </MDBRow>

                            <MDBRow className="mb-2">
                              <MDBCol lg="12" className="text-left p-0">
                                {/* First Name */}
                                <label className="input-label">
                                  First Name
                                </label>
                                <MDBInput
                                  id="firstName"
                                  pattern="^[A-Z a-z]{1,32}$"
                                  label="FirstName"
                                  outline
                                  required={this.state.accountDetailsRequired}
                                  onChange={this.handleChange("firstName")}
                                  value={this.state.firstName}
                                />
                                {/* Surname */}
                                <label className="input-label">Surname</label>
                                <MDBInput
                                  id="surname"
                                  pattern="^[A-Z a-z]{1,32}$"
                                  label="Surname"
                                  outline
                                  required={this.state.accountDetailsRequired}
                                  onChange={this.handleChange("surname")}
                                  value={this.state.surname}
                                />
                                {/* DOB */}
                                <label className="input-label">
                                  Date of Birth
                                </label>
                                <MDBInput
                                  id="dob"
                                  outline
                                  type="date"
                                  required={this.state.accountDetailsRequired}
                                  onChange={this.handleChange("dob")}
                                  value={this.state.dob}
                                  min={"1900-01-01"}
                                  max={
                                    new Date(
                                      new Date().setDate(
                                        new Date().getDate() - 365 * 18
                                      )
                                    )
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                />
                                {/* Email */}
                                <label className="input-label">
                                  Email Address
                                </label>
                                <MDBInput
                                  id="email"
                                  pattern="^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                                  label="Email Address"
                                  outline
                                  required={this.state.accountDetailsRequired}
                                  onChange={this.handleChange("email")}
                                  value={this.state.email}
                                />
                                {/* Phone Number */}
                                <label className="input-label">
                                  Phone Number
                                </label>
                                <MDBInput
                                  id="phone"
                                  pattern="^[0-9]{10,15}$"
                                  label="Phone number"
                                  outline
                                  required={this.state.accountDetailsRequired}
                                  onChange={this.handleChange("phone")}
                                  value={this.state.phone}
                                />
                              </MDBCol>
                            </MDBRow>

                            <h4>Account Holder Address</h4>
                            <MDBRow className="mb-2">
                              <MDBCol size="12" className="text-left p-0">
                                {/* MPRN  */}
                                <label className="input-label">MPRN</label>
                                <MDBBtn className="tooltip-container">
                                  <MDBIcon
                                    icon="info-circle"
                                    onClick={() =>
                                      this.setState({
                                        mprnTip: !this.state.mprnTip,
                                      })
                                    }
                                  />
                                  {this.state.mprnTip == true ? (
                                    <span className="tooltip-content">
                                      <img
                                        className="brand-image"
                                        src={require("../../../../images/global/bullet-large.png")}
                                      />
                                      <p>
                                        Please enter the{" "}
                                        <b className="brightGreen-text">11</b>{" "}
                                        digits as they appear on your most
                                        recent bill or statement.
                                      </p>
                                    </span>
                                  ) : null}
                                </MDBBtn>

                                <MDBRow className="align-items-center">
                                  {/* MPRN Error Message */}
                                  <MDBRow size="12" id="errorMessage">
                                    {this.props.addressLookupError != "" ? (
                                      <MDBAlert color="danger">
                                        {this.props.addressLookupError}
                                      </MDBAlert>
                                    ) : null}
                                  </MDBRow>
                                  <MDBCol lg="8" md="8" sm="12" className="p-0">
                                    <MDBInput
                                      id="mprn"
                                      pattern="^(?=\d{11}$)(10)\d+"
                                      label="MPRN"
                                      outline
                                      required={
                                        this.state.accountDetailsRequired
                                      }
                                      onChange={this.handleChange("mprn")}
                                      value={this.state.mprn}
                                    />
                                  </MDBCol>
                                  <MDBCol lg="2" md="2" sm="12">
                                    <MDBBtn
                                      className="m-0 find-address-btn"
                                      onClick={() => this.addressLookup()}
                                    >
                                      Find Address
                                    </MDBBtn>
                                  </MDBCol>
                                  <MDBCol lg="2" md="2" />
                                </MDBRow>

                                {/* Postcode */}
                                <label className="input-label">Eircode</label>
                                <MDBInput
                                  id="postcode"
                                  label="Eircode"
                                  outline
                                  onChange={this.handleChange("postcode")}
                                  value={this.state.postcode}
                                  className="field-to-uppercase"
                                  pattern="([AC-FHKNPRTV-Y]\d{2}|D6W)[0-9AC-FHKNPRTV-Y]{4}"
                                  disabled
                                />
                                {/* Address Line One */}
                                <label className="input-label">Address</label>
                                <MDBInput
                                  id="addrLine1"
                                  pattern="^[A-Z a-z 0-9]{1,100}$"
                                  label="Building Name/Number"
                                  outline
                                  onChange={this.handleChange("addrLine1")}
                                  value={this.state.addrLine1}
                                  disabled
                                />
                                {/* Address Line Two */}
                                <MDBInput
                                  id="addrLine2"
                                  pattern="^[A-Z a-z 0-9]{1,100}$"
                                  label="Street"
                                  outline
                                  required={this.state.accountDetailsRequired}
                                  onChange={this.handleChange("addrLine2")}
                                  value={this.state.addrLine2}
                                  disabled
                                />
                                {/* City  */}
                                <MDBInput
                                  id="city"
                                  pattern="^[A-Z a-z 0-9]{1,100}$"
                                  label="Town"
                                  outline
                                  onChange={this.handleChange("city")}
                                  value={this.state.city}
                                  disabled
                                />
                                {/* County  */}
                                <MDBInput
                                  id="county"
                                  pattern="^[A-Z a-z 0-9]{1,100}$"
                                  label="County"
                                  outline
                                  onChange={this.handleChange("county")}
                                  value={this.state.county}
                                  disabled
                                />
                              </MDBCol>
                            </MDBRow>
                          </div>
                        </div>

                        {/* Smart meters */}
                        {this.state.smartMeter ? (
                          <div className="white-curved-tile text-left">
                            <h4>Smart meter</h4>
                            <p className="input-label">
                              Looks like you have a meter that supports smart
                              services. Do you want to sign up to our smart
                              tariff?
                              <MDBBtn className="tooltip-container">
                                <MDBIcon
                                  icon="info-circle"
                                  onClick={() =>
                                    this.setState({
                                      smartMeterTip: !this.state.smartMeterTip,
                                    })
                                  }
                                />
                                {this.state.smartMeterTip == true ? (
                                  <span className="tooltip-content">
                                    <img
                                      className="brand-image"
                                      src={require("../../../../images/global/bullet-large.png")}
                                    />
                                    <p>
                                      <b className="brightGreen-text">
                                        Attention! <br />
                                      </b>
                                      Once smart services have been activated on
                                      your meter you will no longer be able to
                                      access non smart tariffs.
                                    </p>
                                  </span>
                                ) : null}
                              </MDBBtn>
                            </p>
                            <div className="radio-container">
                              {/* Yes */}
                              <input
                                id="smartTariff"
                                name="smartTariffYes"
                                type="radio"
                                onClick={() => {
                                  this.setState({
                                    smartTariff: true,
                                  });
                                }}
                                className="default-radio"
                                checked={this.state.smartTariff ? true : false}
                              />
                              <label for="smartTariffYes">Yes</label>
                              {/* No */}
                              <input
                                id="smartTariff"
                                name="smartTariffNo"
                                type="radio"
                                onClick={() => {
                                  this.setState({
                                    smartTariff: false,
                                  });
                                }}
                                className="default-radio"
                                checked={
                                  this.state.smartTariff === false
                                    ? true
                                    : false
                                }
                              />
                              <label htmlFor="smartTariffNo">No</label>
                            </div>

                            {this.state.smartTariff ? (
                              <div>
                                <p className="input-label">
                                  How would you like us to collect your data for
                                  billing purposes? Daily or every two months.
                                  <MDBBtn className="tooltip-container">
                                    <MDBIcon
                                      icon="info-circle"
                                      onClick={() =>
                                        this.setState({
                                          smartMeterTipTwo: !this.state
                                            .smartMeterTipTwo,
                                        })
                                      }
                                    />
                                    {this.state.smartMeterTipTwo == true ? (
                                      <span className="tooltip-content">
                                        <img
                                          className="brand-image"
                                          src={require("../../../../images/global/bullet-large.png")}
                                        />
                                        <p>
                                          <b className="brightGreen-text">
                                            Smart meters <br />
                                          </b>
                                          Allowing us to collect your data daily
                                          means that we are able to provide you
                                          with much more information about when
                                          you use electricity, and how you could
                                          make savings. If you select every two
                                          months this will limit the amount of
                                          up to date information we can provide.
                                          You can change this preference at any
                                          time by getting in touch with our
                                          energy specialists.
                                        </p>
                                      </span>
                                    ) : null}
                                  </MDBBtn>
                                </p>

                                <div className="radio-container">
                                  {/* Yes */}
                                  <input
                                    id="smartMeter"
                                    name="halfHourlyYes"
                                    type="radio"
                                    onClick={() => {
                                      this.setState({
                                        halfHourly: true,
                                      });
                                    }}
                                    className="default-radio"
                                    checked={
                                      this.state.halfHourly ? true : false
                                    }
                                  />
                                  <label for="halfHourlyYes">Daily</label>
                                  {/* No */}
                                  <input
                                    id="smartMeter"
                                    name="halfHourlyNo"
                                    type="radio"
                                    onClick={() => {
                                      this.setState({
                                        halfHourly: false,
                                      });
                                    }}
                                    className="default-radio"
                                    checked={
                                      this.state.halfHourly === false
                                        ? true
                                        : false
                                    }
                                  />
                                  <label htmlFor="halfHourlyNo">
                                    Every two months
                                  </label>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ) : null}

                        {/* Acount Correspondence Radio Buttons */}
                        <div className="white-curved-tile text-left">
                          <div>
                            <h4>Account Correspondence</h4>
                            <p className="input-label">
                              Would you like to add a different correspondence
                              address?
                            </p>
                            <div className="radio-container">
                              {/* Yes */}
                              <input
                                id="accountCorrespondenceYes"
                                name="accountCorrespondence"
                                type="radio"
                                onClick={() => {
                                  this.setState({
                                    accountCorrespondence: true,
                                  });
                                  this.accountCorrespondenceRequired(true);
                                }}
                                required
                                className="default-radio"
                                checked={
                                  this.state.accountCorrespondence
                                    ? true
                                    : false
                                }
                              />
                              <label for="accountCorrespondenceYes">Yes</label>
                              {/* No */}
                              <input
                                id="accountCorrespondenceNo"
                                name="accountCorrespondence"
                                type="radio"
                                onClick={() => {
                                  this.setState({
                                    accountCorrespondence: false,
                                  });
                                  this.accountCorrespondenceRequired(false);
                                }}
                                className="default-radio"
                                checked={
                                  this.state.accountCorrespondence === false
                                    ? true
                                    : false
                                }
                              />
                              <label htmlFor="accountCorrespondenceNo">
                                No
                              </label>
                            </div>
                          </div>
                          {/* Account Correspondance Information */}
                          {this.state.accountCorrespondence != false ? (
                            <div>
                              <h4>Account Correspondance Information</h4>
                              <MDBRow className="mb-2">
                                <MDBCol lg="12" className="text-left p-0">
                                  {/* Acc Holder Title */}
                                  <MDBRow>
                                    <MDBCol lg="12" className="p-0">
                                      <label className="input-label">
                                        Title
                                      </label>
                                      <select
                                        id="accCorrTitle"
                                        pattern="[A-Za-z]{1,10}"
                                        className="browser-default custom-select mt-3"
                                        onChange={this.handleChange(
                                          "accCorrTitle"
                                        )}
                                        required={
                                          this.state
                                            .accountCorrespondanceRequired
                                        }
                                        value={this.state.accCorrTitle}
                                      >
                                        <option>Title</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Ms">Ms</option>
                                        <option value="Dr">Dr</option>
                                        <option value="Prof">Prof</option>
                                      </select>
                                    </MDBCol>
                                  </MDBRow>
                                  {/* Account Correspondance First Name */}
                                  <label className="input-label">
                                    First Name
                                  </label>
                                  <MDBInput
                                    id="accCorrFirstName"
                                    pattern="^[A-Z a-z]{1,32}$"
                                    label="First Name"
                                    outline
                                    required={
                                      this.state.accountCorrespondanceRequired
                                    }
                                    onChange={this.handleChange(
                                      "accCorrFirstName"
                                    )}
                                    value={this.state.accCorrFirstName}
                                  />
                                  {/* Account Correspondance Surname */}
                                  <label className="input-label">Surname</label>
                                  <MDBInput
                                    id="accCorrSurname"
                                    pattern="^[A-Z a-z]{1,32}$"
                                    label="Surname"
                                    outline
                                    required={
                                      this.state.accountCorrespondanceRequired
                                    }
                                    onChange={this.handleChange(
                                      "accCorrSurname"
                                    )}
                                    value={this.state.accCorrSurname}
                                  />
                                  {/* Account Correspondance Postcode */}
                                  <label className="input-label">Eircode</label>
                                  {/* Postcode */}
                                  <MDBInput
                                    id="accCorrPostcode"
                                    label="Eircode"
                                    outline
                                    onChange={this.handleChange(
                                      "accCorrPostcode"
                                    )}
                                    value={this.state.accCorrPostcode}
                                    className="field-to-uppercase"
                                    pattern="([AC-FHKNPRTV-Y]\d{2}|D6W)[0-9AC-FHKNPRTV-Y]{4}"
                                  />
                                  {/* Account Correspondance Address */}
                                  <label className="input-label">Address</label>
                                  {/* Line One */}
                                  <MDBInput
                                    id="accCorrAddLineOne"
                                    pattern="^[A-Z a-z 0-9]{1,32}$"
                                    label="Building Name/Number"
                                    outline
                                    onChange={this.handleChange(
                                      "accCorrAddLineOne"
                                    )}
                                    value={this.state.accCorrAddLineOne}
                                  />
                                  {/* Line Two */}
                                  <MDBInput
                                    id="accCorrAddLineTwo"
                                    pattern="^[A-Z a-z 0-9]{1,32}$"
                                    label="Street"
                                    outline
                                    required={
                                      this.state.accountCorrespondanceRequired
                                    }
                                    onChange={this.handleChange(
                                      "accCorrAddLineTwo"
                                    )}
                                    value={this.state.accCorrAddLineTwo}
                                  />
                                  {/* City */}
                                  <MDBInput
                                    id="accCorrCity"
                                    pattern="^[A-Z a-z 0-9]{1,32}$"
                                    label="Town"
                                    outline
                                    onChange={this.handleChange("accCorrCity")}
                                    value={this.state.accCorrCity}
                                  />
                                  {/* County */}
                                  <MDBInput
                                    id="accCorrCounty"
                                    pattern="^[A-Z a-z 0-9]{1,32}$"
                                    label="County"
                                    outline
                                    onChange={this.handleChange(
                                      "accCorrCounty"
                                    )}
                                    value={this.state.accCorrCounty}
                                  />

                                  {/* Account Correspondance Email */}
                                  <label className="input-label">
                                    Email Address
                                  </label>
                                  <MDBInput
                                    id="accCorrEmail"
                                    pattern="^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                                    label="Email Address"
                                    outline
                                    required={
                                      this.state.accountCorrespondanceRequired
                                    }
                                    onChange={this.handleChange("accCorrEmail")}
                                    value={this.state.accCorrEmail}
                                  />
                                  {/* Account Correspondance Phone */}
                                  <label className="input-label">
                                    Phone Number
                                  </label>
                                  <MDBInput
                                    id="accCorrAddPhone"
                                    pattern="[0-9]{10,15}"
                                    label="Phone number"
                                    outline
                                    required={
                                      this.state.accountCorrespondanceRequired
                                    }
                                    onChange={this.handleChange(
                                      "accCorrAddPhone"
                                    )}
                                    value={this.state.accCorrAddPhone}
                                  />
                                </MDBCol>
                              </MDBRow>
                            </div>
                          ) : null}
                        </div>

                        {/* Joint Application Radio Buttons */}
                        <div className="white-curved-tile text-left">
                          <div>
                            <h4>Joint Application</h4>
                            <p className="input-label">
                              Is this a joint application?
                            </p>
                            <div className="radio-container">
                              {/* Yes */}
                              <input
                                id="jointApplicationYes"
                                name="jointApplication"
                                type="radio"
                                onClick={() => {
                                  this.setState({ jointPerson: true });
                                  this.jointDetailsRequired(true);
                                }}
                                required
                                className="default-radio"
                                checked={this.state.jointPerson ? true : false}
                              />
                              <label for="jointApplicationYes">Yes</label>
                              {/* No */}
                              <input
                                id="jointApplicationNo"
                                name="jointApplication"
                                type="radio"
                                onClick={() => {
                                  this.setState({ jointPerson: false });
                                  this.jointDetailsRequired(false);
                                }}
                                className="default-radio"
                                checked={
                                  this.state.jointPerson === false
                                    ? true
                                    : false
                                }
                              />
                              <label htmlFor="jointApplicationNo">No</label>
                            </div>
                          </div>
                          {/* Joint Account Details */}
                          {this.state.jointPerson != false ? (
                            <div>
                              <h4>Joint Applicant Information</h4>
                              <MDBRow>
                                <MDBCol lg="12" className="p-0">
                                  <label className="input-label">Title</label>
                                  <select
                                    id="jointTitle"
                                    pattern="[A-Za-z]{1,10}"
                                    className="browser-default custom-select mt-3"
                                    required={this.state.jointDetailsRequired}
                                    onChange={this.handleChange("jointTitle")}
                                    value={this.state.jointTitle}
                                  >
                                    <option>Title</option>
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Dr">Dr</option>
                                    <option value="Prof">Prof</option>
                                  </select>
                                </MDBCol>
                              </MDBRow>
                              <MDBRow className="mb-2">
                                <MDBCol lg="12" className="text-left p-0">
                                  {/* Joint Account First Name */}
                                  <label className="input-label">
                                    First Name
                                  </label>
                                  <MDBInput
                                    id="jointFirstName"
                                    pattern="^[A-Z a-z]{1,32}$"
                                    label="FirstName"
                                    outline
                                    required={this.state.jointDetailsRequired}
                                    onChange={this.handleChange(
                                      "jointFirstName"
                                    )}
                                    value={this.state.jointFirstName}
                                  />
                                  {/* Joint Account Surname */}
                                  <label className="input-label">Surname</label>
                                  <MDBInput
                                    id="jointSurname"
                                    pattern="^[A-Z a-z]{1,32}$"
                                    label="Surname"
                                    outline
                                    required={this.state.jointDetailsRequired}
                                    onChange={this.handleChange("jointSurname")}
                                    value={this.state.jointSurname}
                                  />
                                  {/* Joint Account Email */}
                                  <label className="input-label">
                                    Email Address
                                  </label>
                                  <MDBInput
                                    id="jointEmail"
                                    pattern="^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                                    label="Email Address"
                                    outline
                                    required={this.state.jointDetailsRequired}
                                    onChange={this.handleChange("jointEmail")}
                                    value={this.state.jointEmail}
                                  />
                                  {/* Joint Account Phone */}
                                  <label className="input-label">
                                    Phone Number
                                  </label>
                                  <MDBInput
                                    id="jointPhone"
                                    pattern="^[0-9]{10,15}$"
                                    label="Phone number"
                                    outline
                                    required={this.state.jointDetailsRequired}
                                    onChange={this.handleChange("jointPhone")}
                                    value={this.state.jointPhone}
                                  />
                                </MDBCol>
                              </MDBRow>
                            </div>
                          ) : null}
                        </div>

                        {/* Marketing */}
                        <div className="white-curved-tile text-left">
                          <h4>Marketing Preferences</h4>
                          <p className="input-label">
                            We would love to keep you updated with our latest
                            product offers and news. Please select how you would
                            like to keep in touch:
                          </p>
                          <MDBRow className="checkbox-container">
                            {/* Marketing Pref Email */}
                            <MDBCol size="12">
                              <input
                                id="marketingEmail"
                                name="marketingEmail"
                                type="checkbox"
                                onChange={() =>
                                  this.setState({
                                    markEmail: !this.state.markEmail,
                                  })
                                }
                                checked={this.state.markEmail}
                              />
                              <label for="marketingEmail" className="ml-2">
                                Email
                              </label>
                            </MDBCol>
                            {/* Marketing Pref SMS */}
                            <MDBCol size="12">
                              <input
                                id="marketingSMS"
                                name="marketingSMS"
                                type="checkbox"
                                onChange={() =>
                                  this.setState({
                                    markSMS: !this.state.markSMS,
                                  })
                                }
                                checked={this.state.markSMS}
                              />
                              <label for="marketingSMS" className="ml-2">
                                SMS
                              </label>
                            </MDBCol>
                            {/* Marketing Pref Post */}
                            <MDBCol size="12">
                              <input
                                id="marketingPost"
                                name="marketingPost"
                                type="checkbox"
                                onChange={() =>
                                  this.setState({
                                    markPost: !this.state.markPost,
                                  })
                                }
                                checked={this.state.markPost}
                              />
                              <label htmlFor="marketingPost" className="ml-2">
                                Post
                              </label>
                            </MDBCol>
                            {/* Marketing Pref Phone */}
                            <MDBCol size="12">
                              <input
                                id="marketingPhone"
                                name="marketingPhone"
                                type="checkbox"
                                onChange={() =>
                                  this.setState({
                                    markPhone: !this.state.markPhone,
                                  })
                                }
                                checked={this.state.markPhone}
                              />
                              <label htmlFor="marketingPhone" className="ml-2">
                                Phone
                              </label>
                            </MDBCol>
                          </MDBRow>
                          <p className="input-label">
                            You can update your preferences at any time. For
                            details on how we use your information please view
                            our{" "}
                            <a
                              href={`/${this.state.jurisdiction}/privacystatement`}
                            >
                              Privacy Policy
                            </a>
                          </p>
                        </div>

                        {/* Continue Button */}
                        <MDBRow>
                          <MDBCol size="6">
                            <h5>
                              Next Step: <span>Your Electricity</span>
                            </h5>
                            <MDBBtn className="btn" onClick={this.actionType}>
                              Continue
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol size="4" className="show-for-large">
                        <img
                          className="brand-image"
                          src={require("../../../../images/switch/switch-tile.png")}
                        />

                        {/* Chat functionality */}
                        <div className="white-curved-tile text-left mt-3">
                          <h5 className="mt-4 text-center">Need some help?</h5>
                          {/* Live chat */}
                          <div className="switch-live-chat">
                            <div data-id="VVUGGBG3WTc" class="livechat_button">
                              <a href="https://www.livechat.com/utm-builder/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_11999496">
                                UTM Builder
                              </a>
                            </div>
                            <LiveChat license={11999496} />
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.switch.isLoading,
    journeyData: store.switch.switchData,
    addressLookupData: store.switch.addressLookupData,
    addressLookupError: store.switch.addressLookupError,
  };
};

const actionCreators = {
  getSwitch: switchActions.getSwitchJourney,
  createSwitch: switchActions.createSwitchJourney,
  updateSwitch: switchActions.updateSwitchJourney,
  addressLookup: switchActions.addressLookup,
};

export default connect(mapStateToProps, actionCreators)(_FormAccountDetails);
