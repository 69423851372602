import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import LegalTabs from "../../../components/About/LegalTabs";
import {isBrowser, isMobile} from "react-device-detect";

import prepaymentMeters from "../../../documents/prepaymentMetersNI.pdf";
import pensionableAge from "../../../documents/pensionableAgeNI.pdf";
import energyEfficiency from "../../../documents/energyEfficiencyNI.pdf";
import paymentOfBills from "../../../documents/paymentOfBillsNI.pdf";
import complaints from "../../../documents/complaintsNI.pdf";
import energyChecklist from "../../../documents/energyChecklistNI.pdf";
import ecc from "../../../documents/eccNI.pdf";

import FixedNavDark from "../../../components/Shared/FixedNavDark";
import $ from "jquery";
import EnergyEfficiency from "../../Help/EnergyEfficiency";

class NIEnergyTheft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split('/');
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris
    });

    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>Policies & Terms</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
        <MDBRow>
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <LegalTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5 pb-5'>
            
            <MDBRow>
              <MDBCol>
              <h3>Energy Theft</h3>
              {isMobile &&
                <MDBCol md="3"><br/><img width="100%" src={require("../../../images/home-page/energytheft1.png")}></img></MDBCol>
              }
                <h4 className='montserrat-Bold mt-5'>Energy theft is dangerous, illegal and paid for by you.</h4>

                <p>

                  Energy theft is when a gas or electricity meter is tampered with so that it does not accurately record the amount of energy used. This is an illegal act and can be extremely dangerous.
           <br /><br /> Energy theft increases costs, resulting in higher bills which affects everyone.
           <a href="https://www.consumercouncil.org.uk/"target="_blank"> <u>The Consumer Council</u>,</a>  with the support of the energy industry in Northern Ireland, is raising awareness about the consequences of energy theft.

            <br /><br />If you suspect energy theft is occurring, it can be reported by contacting the <a href="https://ukrpa.co.uk/"target="_blank"> <u>UK Revenue Protection Association </u></a> (UKRPA) <span className='montserrat-Bold'><a href='tel:028 9575 7206'>028 9575 7206.</a>  </span></p><br />
              </MDBCol>
              {isBrowser &&
                <MDBCol size="6"><img src={require("../../../images/home-page/energytheft1.png")}></img></MDBCol>
              }
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NIEnergyTheft;
