import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FAQTabs from "../../../components/Help/FAQTabs";

import FixedNavDark from "../../../components/Shared/FixedNavDark";
import $ from "jquery";

class NIFAQsBrightAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener("scroll", this.help);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  };

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark /> */}

        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>FAQs</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow className='pb-5'>
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <FAQTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5'>
            <h3>My bright account</h3>

            <div id='howtodownloadapp' className='mt-5 mb-5'>
              <h5 className='policy-title'>
                How do I download the bright app?{" "}
              </h5>
              <p className='policy-content'>
              Download our app (you'll love it!).  <br />
                The bright app is free to download. Android and iOS users can
                download the bright app by clicking on one of the following
                links.
                <br />
                <MDBRow className='show-for-medium'>
                  <MDBCol sm='12' md='8' lg='8'>
                    <MDBRow className='pt-4'>
                      <MDBCol sm='12'>
                        <a target="_blank" href='https://apps.apple.com/app/id1500409256'>
                          <img
                            src={require("../../../images/ios.png")}
                            // className='fullImg app-store'
                            style={{ height: 50 }}
                          />
                        </a>
                        <a target="_blank" href='https://play.google.com/store/apps/details?id=com.brightapp'>
                          <img
                            src={require("../../../images/google.png")}
                            // className='fullImg app-store'
                            style={{ height: 50 }}
                          />
                        </a>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                <br />
                You'll need to register your account on the bright website, in the  {""}<a href={`/${this.state.jurisdiction}/account/login`}> Bright Account</a>  section, then you'll be able to access your account on the app using the same user name and password.
                
              </p>
            </div>

            <div id='forgotpin' className='mb-5'>
              <h5 className='policy-title'>
                I’ve forgotten my pin login- how can I reset?
              </h5>
              <p className='policy-content'>
              If you have forgotten you bright account 
              password, simply click on the ‘forgot password’ 
              link and reset your password. Once your password 
              has been reset successfully you will be able to 
              login into your bright account. 
              </p>
            </div>

            <div id='issueswithapp' className='mb-5'>
              <h5 className='policy-title'>
                I’m experiencing issues with my app- who can I contact?
              </h5>
              <p className='policy-content'>
                If you are experiencing an issue with the app please contact our
                Energy Specialists. You can contact us free of charge:
                <ul>
                  <li>
                    Call us on <a href='tel:028 9544 2290'>028 9544 2290</a>
                  </li>
                  <li>
                    Webchat at{" "}
                    <a href='https://www.brightenergy.com'>
                      www.brightenergy.com
                    </a>
                  </li>
                  <li>
                    Email at{" "}
                    <a href='mailto:hello@brightenergy.com'>
                      hello@brightenergy.com
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div id='closedapprefund' className='mb-5'>
              <h5 className='policy-title'>
                I’ve closed my bright account- how can I get my refund?
              </h5>
              <p className='policy-content'>
                If your account has a credit balance, please contact us to
                discuss. Before we can process a refund, we will need to check
                if an estimated meter reading was used to calculate your bill,
                if so, we will need you to provide us with an actual meter
                reading. Please contact us by:
                <ul>
                  <li>
                    Call us on <a href='tel:028 9544 2290'>028 9544 2290</a>
                  </li>
                  <li>
                    Webchat at{" "}
                    <a  href='https://www.brightenergy.com'>
                      www.brightenergy.com
                    </a>
                  </li>
                  <li>
                    Email at{" "}
                    <a href='mailto:hello@brightenergy.com'>
                      hello@brightenergy.com
                    </a>
                  </li>
                </ul>
                If you have closed your account and have a credit account
                balance once your final bill is issued, we'll issue the refund
                directly to your bank account by Electronic Funds Transfer (EFT)
                within two months of the bill issue date.
              </p>
            </div>

            <div id='datacollected' className='mb-5'>
              <h5 className='policy-title'>
               How is my personal data collected, stored and processed by bright?
              </h5>
              <p className='policy-content'>
              At bright we respect your privacy and we are 
              committed to ensuring your personal data is protected.  
              For full details on how we collect, store and process 
              your personal data, please view our Privacy Policy{""}<a href={`/${this.state.jurisdiction}/privacystatement`}> here.</a> 
              </p>
            </div>

            <div id='marketingpref' className='mb-5'>
              <h5 className='policy-title'>
              How can I update my marketing preferences? 
              </h5>
              <p className='policy-content'>
              You can change your marketing preferences via the bright app, on the customer portal or by getting in touch with our energy specialists. You can also unsubscribe from direct marketing or opt out of profiling via the channel you received the communication. 
              </p>
            </div>

            <div id='jointaccount' className='mb-5'>
              <h5 className='policy-title'>
                Is it possible to set up a joint account?
              </h5>
              <p className='policy-content'>
                You can set up a joint account by providing us with the details
                of the joint party on sign up. As soon as possible after sign up
                we'll contact the additional person to make sure they are happy
                to be named on the account.
              </p>
            </div>

            <div id='deceasedperson' className='mb-5'>
              <h5 className='policy-title'>
                Can I close an account of behalf on a deceased person?
              </h5>
              <p className='policy-content'>
              To close an account on behalf of a deceased relative call us on{" "}
                <a href='tel:028 9544 2290'>028 9544 2290</a> with a final meter
                reading. We can accept details from relatives or solicitors.
              </p>
            </div>

            <div id='accountonbehalf' className='mb-5'>
              <h5 className='policy-title'>
                How do I manage an account on behalf of another person? 
              </h5>
              <p className='policy-content'>
              You can act as a nominated person for an account holder, this means we can talk to you about the account and send you bills and any correspondence related to the account.  We'll need your permission and the permission of the account holder. To set up an account on someone else's behalf you'll need to contact our energy specialists.
              </p>
            </div>

            <div id='leavebright' className='mb-5'>
              <h5 className='policy-title'>What if I want to leave bright? </h5>
              <p className='policy-content'>
                You have the right to cancel your contract with us at any time
                and we don’t charge any exit fees. Simply get in touch.
              </p>
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NIFAQsBrightAccount;
