import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FAQTabs from "../../../components/Help/FAQTabs";

import FixedNavDark from "../../../components/Shared/FixedNavDark";
import $ from "jquery";

class NIFAQsMeterReadings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }
  
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.help);
  }

  help =() =>{
    if (window.pageYOffset <50)
    {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark /> */}

        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>FAQs</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow className="pb-5">
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <FAQTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5'>
            <h3>Meter Readings</h3>

            <div id='submitreading' className='mt-5 mb-5'>
              <h5 className='policy-title'>How do I submit a meter reading? </h5>
              <p className='policy-content'>
                <MDBRow className="py-5">
                  <MDBCol>
                  <img className= 'meterImg app-store' src={require("../../../images/help/Clock_type_dial_meter.jpg")}></img>
                  
                      
                    
                  </MDBCol>
                  <MDBCol>
                    
                  <h5 className='policy-title'>Dial Clock Meter</h5>
                  <ul>
                    <li>To take your meter reading, you'll need to read the five dials on the top row.</li>
                    <li>Ignore any dials / numbers in red</li>
                    <li>Read the dials from left to right. </li>
                    <li> If the clock hand is between two numbers, round down to the lowest number. If the dial is between 9 and 0, round down to 9.</li>
                  </ul>
                  </MDBCol>
                </MDBRow>

                <MDBRow className="py-5">
                <MDBCol>
                    <img
                      src={require("../../../images/help/Digit_meter.jpg")}
                      className='meterImg app-store'
                    />
                  </MDBCol>
                  <MDBCol>
                  <h5 className='policy-title'>Digital meter</h5>
                  <ul>
                    <li>Read from left to right ignoring any numbers marked 1/10 or 1/1000 or any numbers in red. </li>
                  </ul>
                  </MDBCol>
                </MDBRow>

                <MDBRow className="py-5">
                <MDBCol>
                    <img
                      src={require("../../../images/help/Keypad meter_1.jpg")}
                      className='meterImg app-store'
                    />
                  </MDBCol>
                  <MDBCol>
                  <h5 className='policy-title'>Keypad meter</h5>
                  <ul>
                    <li>Press 9 on the Keypad and read the digital from left to right.</li>
                  </ul>
                  </MDBCol>
                </MDBRow>

                
              </p>
            </div>

            <div id='esbrequestingread' className='mb-5'>
              <h5 className='policy-title'>Why are NIE Networks still requesting my meter read?  </h5>
              <p className='policy-content'>
              NIE Networks, as the network company, is responsible for reading and maintaining the meter at each property and providing the meter read to suppliers.
              </p>
            </div>

            <div id='forgotmeterread' className='mb-5'>
              <h5 className='policy-title'>I forgot to submit my meter read- what happens now? </h5>
              <p className='policy-content'>
              If we don't have an up to date meter reading we'll use an estimate calculated by NIE Networks or bright.  If you think this estimate is not correct you can provide us with an up to date read and we will reissue your bill.
              </p>
            </div>

            <div id='howoftenmeterread' className='mb-5'>
              <h5 className='policy-title'>How often is my meter read? </h5>
              <p className='policy-content'>
              NIE Networks will make best endeavours to read your meter 4 times per year.  We will send you a bill every month, to ensure this bill is accurate you can provide a meter reading each month through the app or website.  
              </p>
            </div>

            <div id='firstbill' className='mb-5'>
              <h5 className='policy-title'>I am unable to read the electricity meter. </h5>
              <p className='policy-content'>
              Where you or anyone else in the property is unable to read the electricity meter at the premises, we will arrange to have the meter read at least once each quarter and inform you of that reading. Contact us on:
              <ul>
                  <li>Call us on <a href="tel:028 9544 2290">028 9544 2290</a></li>
                  <li>Email us at <a href="mailto:hello@brightenergy.com">hello@brightenergy.com</a> </li>
                  <li>Web Chat at <a href="https://www.brightenergy.com">www.brightenergy.com</a> </li>
                  
                </ul>

              View bright's code of practice on provision of services for vulnerable customers <a href={`/${this.state.jurisdiction}/codesofpractice`}>here.</a>

              </p>
            </div>

          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NIFAQsMeterReadings;
