import axios from "axios";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const meterReadService = {
  submitReadingService,
  getMeterReadingService,
  submitLandlordReadingService,
  getMeterStructureService,
};

// Submit Meter Reading
function submitReadingService(readingDetails) {
  let user = localStorage.getItem("user");

  if (user) {
    let parseUser = JSON.parse(user);
    let userId = parseUser.data.authenticateModel.id;
    let userToken = parseUser.data.authenticateModel.token;
    let jId = parseUser.data.jModel.id;

    return axios({
      method: "post",
      url: `${config.apiUrl}/meterreadings/submitcrmreading/${userId}/${jId}`,
      data: readingDetails,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    }).then((response) => {
      return response;
    });
  }
}

// Submit Landlord Meter Reading
function submitLandlordReadingService(readingReqDetails) {
  let user = localStorage.getItem("user");

  if (user) {
    let parseUser = JSON.parse(user);
    let userId = parseUser.data.authenticateModel.id;
    let userToken = parseUser.data.authenticateModel.token;
    let jId = readingReqDetails.jId;

    const readingDetails = {
      dateSubmitted: readingReqDetails.dateSubmitted,
      readingValue: readingReqDetails.readingValue,
      nightReadingValue: readingReqDetails.nightReadingValue,
      meterType: readingReqDetails.meterType,
      jurisdiction: readingReqDetails.jurisdiction,
    };

    return axios({
      method: "post",
      url: `${config.apiUrl}/meterreadings/submitcrmreading/${userId}/${jId}`,
      data: readingDetails,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    }).then((response) => {
      return response;
    });
  }
}

// Get Meter Reading
function getMeterReadingService() {
  let user = localStorage.getItem("user");

  if (user) {
    let parseUser = JSON.parse(user);
    let userId = parseUser.data.authenticateModel.id;
    let userToken = parseUser.data.authenticateModel.token;
    let jId = parseUser.data.jModel.id;

    return axios({
      method: "get",
      url: `${config.apiUrl}/meterreadings/${userId}/${jId}`,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    }).then((response) => {
      return response;
    });
  }
}

// Get Meter Structure
function getMeterStructureService() {
  let user = localStorage.getItem("user");

  if (user) {
    let parseUser = JSON.parse(user);
    let jId = parseUser.data.jModel.id;
    let juris = parseUser.data.authenticateModel.currentJurisdiction;

    return axios({
      method: "get",
      url: `${config.apiUrl}/meterreadings/getmeterstructure/${jId}/${juris}`,
    }).then((response) => {
      return response;
    });
  }
}
