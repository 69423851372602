import axios from "axios";
//import { userToken } from "../helpers";

const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const paymentService = {
  createPayment,
  savePaymentService,
  getPaymentHistory,
};

// Create new quote
function createPayment(details) {
  let user = localStorage.getItem("user");
  let parseUser = JSON.parse(user);
  let userId = parseUser.data.authenticateModel.id;
  let userToken = parseUser.data.authenticateModel.token;

  if (user) {
    return axios({
      method: "post",
      url: `${config.apiUrl}/payments/createcheckout`,
      data: details,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    }).then((response) => {
      return response;
    });
  }
}

// Save payment
function savePaymentService(details) {
  let user = localStorage.getItem("user");
  let parseUser = JSON.parse(user);
  let userToken = parseUser.data.authenticateModel.token;
  let jModelId = parseUser.data.jModel.id;
  let juris = parseUser.data.authenticateModel.currentJurisdiction;

  if (user) {
    return axios({
      method: "post",
      url: `${config.apiUrl}/crm/savepayment/${jModelId}/${parseUser.data.authenticateModel.username}/${juris}` ,
      data: details,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    }).then((response) => {
      return response;
    }); 
  }
}

//Get Payment History
function getPaymentHistory(id, juris) {
  let user = localStorage.getItem("user");

  if (user) {
    let parseUser = JSON.parse(user);
    let userToken = parseUser.data.authenticateModel.token;

    return axios({
      method: "get",
      url: `${config.apiUrl}/Payments/getpaymenthistory/${id}/${juris}`,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    }).then((response) => {
      return response;
    });
  }
}

