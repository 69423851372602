import React, { Component } from "react";
import { MDBRow, MDBCol, MDBContainer} from "mdbreact";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

class EAB extends React.Component {

    render() {

        return (
          <div className="grey-background">
          <MDBContainer>
            <MDBRow className="grey-background py-4 eab-saving" id="eab-save">
            {/* <MDBCol size="1"></MDBCol> */}
            <MDBCol size="12">
              <ul className="list-style-none">
                <li>
                <br/><br/>The EAB is a simple indicator for customers to help them make decisions when comparing tariffs. It calculates an annual bill based on typical consumption figures provided by the CRU (4,200 kWh, split 50/50 for day/night tariffs) and the charges associated with the tariff including the unit rate, standing charge, PSO levy and VAT. This allows you to compare one tariff against another. Please note that your usage will vary from the CRU typical consumption figure. This is not an indication of how much your electricity will cost, but a useful comparison across tariffs.
               </li>
              </ul>
            </MDBCol>
            {/* <MDBCol size="1"></MDBCol> */}
            
          </MDBRow>
          </MDBContainer>
          </div>
        );
    }
}

export default EAB;
