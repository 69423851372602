import React from "react";
import { Switch, Route } from "react-router-dom";
import Page404 from "../components/Shared/Page404.js";

import Home from "../pages/Home.js";

//movehome
import Move from "../pages/MoveIn";

// ROI Quote
// Step 1 - Property
import Quote from "../pages/Switchover/Quote/Quote";
// Step 2 - Meter
import QuoteMeter from "../pages/Switchover/Quote/QuoteMeter";
// Step 3 - Payment
import QuotePayment from "../pages/Switchover/Quote/QuotePayment";
// Step 4 - Gas
import QuoteGas from "../pages/Switchover/Quote/QuoteGas";
// Step 5 - Electric Vehicle
import QuoteElectricVehicle from "../pages/Switchover/Quote/QuoteElectricVehicle";
// Step 6 - Summary
import QuoteSummary from "../pages/Switchover/Quote/QuoteSummary";

//MultiProperty
import LandlordDashboard from "../pages/CustomerAccount/More/LandlordDashboard/LandlordDashboard";
import AgencyPropertyView from "../pages/CustomerAccount/More/LandlordDashboard/AgencyPropertyView";
import { AgencyPayment } from "../pages/CustomerAccount/More/LandlordDashboard/AgencyPayment";
import { AgencyNewTenant } from "../pages/CustomerAccount/More/LandlordDashboard/AgencyNewTenant";

// ROI Switch
// Step 1 - Getting Ready to Switch
import SwitchPage from "../pages/Switchover/Switch/Switch";
// Step 2 - Account Details
import SwitchAccount from "../pages/Switchover/Switch/SwitchAccount";
// Step 3 - Your Electricity
import SwitchElectricity from "../pages/Switchover/Switch/SwitchElectricity";
// Step 4 - Additional Services
import SwitchAdditionalServices from "../pages/Switchover/Switch/SwitchAdditionalServices";
// Step 5 - Payment Details
import SwitchPayment from "../pages/Switchover/Switch/SwitchPayment";
// Step 6 - Switch Summary
import SwitchSummary from "../pages/Switchover/Switch/SwitchSummary";
// Step 7 - Switch Confirmation
import SwitchConfirmation from "../pages/Switchover/Switch/SwitchConfirmation";

// NI Quote
// Step 1 - Meter information
import NIQuoteMeterType from "../components/Forms/Quote/NI/MeterInformation";
// Step 2 - Quote summary
import NIQuoteSummary from "../components/Forms/Quote/NI/QuoteSummary";

// NI Switch
// Step 1 - Account Details
import AccountDetails from "../components/Forms/Switch/NI/AccountDetails";
// Step 2 - Your Electricity
import YourElectricity from "../components/Forms/Switch/NI/YourElectricity";
// Step 3 - Payment Details
import PaymentDetails from "../components/Forms/Switch/NI/PaymentDetails";
import SecurityDeposit from "../components/Forms/Switch/NI/SecurityDeposit";
// Step 4 - Switch Summary
import NISwitchSummary from "../components/Forms/Switch/NI/SwitchSummary";
// Step 4 - Switch Confirmation
import NISwitchConfirmation from "../components/Forms/Switch/NI/SwitchConfirmation";

//about
import About from "../pages/About/About";
import OurMission from "../pages/About/OurMission";
import OurElectricity from "../pages/About/OurElectricity";
import OurPeople from "../pages/About/OurPeople";
import ContactUs from "../pages/About/ContactUs";
import OurTariff from "../pages/About/OurTariff";
import CodesOfPractice from "../pages/About/CodesOfPractice";
import TermsAndConditions from "../pages/About/TermsAndConditions";

import PrivacyStatement from "../pages/About/PrivacyStatement.js";
import OurPolicies from "../pages/About/OurPolicies";
import Template from "../pages/About/Template";
import SwitchingToBright from "../pages/About/SwitchingToBright";
import SmartMeters from "../pages/About/SmartMeters";

//NIHome
import NIHome from "../pages/NIHome";

//ni - about
import NIOurPolicies from "../pages/About/NI/NIOurPolicies";
import NITermsAndConditions from "../pages/About/NI/NITermsAndConditions";

import NICodesOfPractice from "../pages/About/NI/NICodesOfPractice";
import NIFuelMix from "../pages/About/NI/NIFuelMix";
import NIOurMission from "../pages/About/NI/NIOurMission";
import NIOurPeople from "../pages/About/NI/NIOurPeople";
import NIOurElectricity from "../pages/About/NI/NIOurElectricity";
import NISwitchingToBright from "../pages/About/NI/NISwitchingToBright";
import NIOurTariff from "../pages/About/NI/NIOurTariff";
import NIEnergyTheft from "../pages/About/NI/NIEnergyTheft";
import NIFAQsGettingStarted from "../pages/About/NI/NIFAQsGettingStarted.js";
import NIFAQsBrightAccount from "../pages/About/NI/NIFAQsBrightAccount.js";
import NIFAQsBillingAndPayments from "../pages/About/NI/NIFAQsBillingAndPayments.js";
import NIFAQsMeterReadings from "../pages/About/NI/NIFAQsMeterReadings.js";
import NIFAQsMovingHouse from "../pages/About/NI/NIFAQsMovingHouse.js";
import NIFAQsAdditionalHelp from "../pages/About/NI/NIFAQsAdditionalHelp.js";
import NIFAQS from "../pages/About/NI/NIFAQ";
import NIFAQSPrepaymentmeters from "../pages/About/NI/NIFAQSPrepaymentmeters.js";
import NIFAQSComplaints from "../pages/About/NI/NIFAQSComplaints.js";

//blog
import Blog from "../pages/Blog/Blog";
import BlogPost from "../pages/Blog/BlogPost";

//help
import Help from "../pages/Help/Help";
import EmergencyInfo from "../pages/Help/EmergencyInfo";
import EnergyEfficiency from "../pages/Help/EnergyEfficiency";
import FAQS from "../pages/Help/FAQ";
import MakeAComplaint from "../pages/Help/MakeAComplaint";
import MeterReadings from "../pages/Help/MeterReadings";
import AddAdditionalServices from "../pages/Help/AddAdditionalServices.js";

//My Account
// import MyAccountAll from "../pages/CustomerAccount/MyAccountAll";
import Login from "../pages/CustomerAccount/Login/Login";
import Register from "../pages/CustomerAccount/Register/Register";
import Dashboard from "../pages/CustomerAccount/Dashboard";
import { Payment } from "../pages/CustomerAccount/More/Payment";
import { MeterReading } from "../pages/CustomerAccount/MeterReading";
import EnergyUsage from "../pages/CustomerAccount/More/EnergyUsage.js";
import YourTariff from "../pages/CustomerAccount/More/YourTariff";
import BillsStatements from "../pages/CustomerAccount/More/BillsStatements";
import { TopupHistory } from "../pages/CustomerAccount/More/TopupHistory";
import SwitchActiveAccount from "../pages/CustomerAccount/More/SwitchAccount";
import BillBreakdown from "../pages/CustomerAccount/More/BillBreakdown";
import Support from "../pages/CustomerAccount/More/Support";
import ReferAFriend from "../pages/CustomerAccount/ReferAFriend";
import ManageAccount from "../pages/CustomerAccount/ManageAccount";
import More from "../pages/CustomerAccount/More";
import SignupRequired from "../pages/CustomerAccount/SignupRequired";

// Refer A Friend
import ReferAFriendPage from "../pages/ReferAFriendPage";

import { PrivateRoute } from "../redux/PrivateRouter/PrivateRoute.jsx";
import EnergySavingTips from "../components/Help/EnergySavingTips.js";
import RequestResetPassword from "../pages/CustomerAccount/ResetPassword/RequestResetPassword.js";
import SetNewPassword from "../pages/CustomerAccount/ResetPassword/SetNewPassword.js";

//competitions
import Maxol from "../pages/competitions/Maxol.js";

// FAQs
import FAQsGettingStarted from "../pages/Help/FAQsGettingStarted.js";
import FAQsBrightAccount from "../pages/Help/FAQsBrightAccount.js";
import FAQsBillingAndPayments from "../pages/Help/FAQsBillingAndPayments.js";
import FAQsMeterReadings from "../pages/Help/FAQsMeterReadings.js";
import FAQsMovingHouse from "../pages/Help/FAQsMovingHouse.js";
import FAQsAdditionalHelp from "../pages/Help/FAQsAdditionalHelp.js";
import FAQsSmartMeters from "../pages/Help/FAQsSmartMeters.js";

import NILanding from "../pages/NILanding.js";
import PaymentDetailsPage from "../pages/CustomerAccount/KeypadVend/PaymentDetailsPage.js";
import VendDetailsPage from "../pages/CustomerAccount/KeypadVend/VendDetailsPage.js";
import VendConfirmationPage from "../pages/CustomerAccount/KeypadVend/VendConfirmationPage.js";
import VendFailure from "../pages/CustomerAccount/KeypadVend/VendFailure.js";

// NI customee account
import DashboardNI from "../pages/CustomerAccount/NI/DashboardNI.js";
import YourTariffNI from "../pages/CustomerAccount/NI/YourTariffNI.js";

// Temp NI Dashboard REMOVE
import { NITempDashboard } from "../pages/CustomerAccount/NITempDashboard";
import JurisdictionRedirect from "../pages/JurisdictionRedirect.js";
import PaymentHistory from "../pages/CustomerAccount/More/PaymentHistory.js";


class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/jurisdiction" exact component={JurisdictionRedirect} />
        <Route path="/roi" exact component={Home} />
        <Route path="/ni" exact component={NIHome} />
        ////#region ROI
      

        {/* CUSTOMER ACCOUNT SIGNIN */}
        <Route path="/roi/account/login" exact component={Login} />
        <Route path="/roi/account/register" exact component={Register} />
        <Route
          path="/roi/account/requestpasswordreset"
          exact
          component={RequestResetPassword}
        />
        <Route
          path="/roi/account/passwordreset"
          exact
          component={SetNewPassword}
        />
        <PrivateRoute
          path="/roi/account/signuprequired"
          exact
          component={SignupRequired}
        />
        {/* My Account Nav bar */}
        {/* <PrivateRoute path='/roi/account/all' exact component={MyAccountAll} /> */}
        <PrivateRoute
          path="/roi/account/dashboard"
          exact
          component={Dashboard}
        />
        <PrivateRoute
          path="/roi/account/referafriend"
          exact
          component={ReferAFriend}
        />
        <PrivateRoute
          path="/roi/account/meterreading"
          exact
          component={MeterReading}
        />
        <PrivateRoute
          path="/roi/account/manageaccount"
          exact
          component={ManageAccount}
        />
        <PrivateRoute path="/roi/account/more" exact component={More} />
        {/* More pages */}
        <PrivateRoute path="/roi/account/payment" exact component={Payment} />
        <PrivateRoute
          path="/roi/account/energyusage"
          exact
          component={EnergyUsage}
        />
        <PrivateRoute
          path="/roi/account/yourtariff"
          exact
          component={YourTariff}
        />
        <PrivateRoute
          path="/roi/account/bills-statements"
          exact
          component={BillsStatements}
        />
        <PrivateRoute
          path="/roi/account/paymenthistory"
          exact
          component={PaymentHistory}
        />
        <PrivateRoute
          path="/roi/account/switchaccount"
          exact
          component={SwitchActiveAccount}
        />
        <PrivateRoute
          path='/roi/account/landlorddashboard'
          exact
          component={LandlordDashboard}
        />
        <PrivateRoute
          path='/roi/account/agencypropertyview'
          exact
          component={AgencyPropertyView}
        />
        <PrivateRoute
          path='/roi/account/agencypayment'
          exact
          component={AgencyPayment}
        />
        <PrivateRoute
          path='/roi/account/agencynewtenant'
          exact
          component={AgencyNewTenant}
        />

        <PrivateRoute
          path='/roi/account/bill'
          exact
          component={BillBreakdown}
        />
        <PrivateRoute path="/roi/account/support" exact component={Support} />
        
        {/* BLOG PAGES */}
        {/* <Route path="/ni/blog" exact component={Blog} />
        <Route path="/ni/blog/post/" exact component={BlogPost} />
        <Route path="/ni/about/ourmission" exact component={NIOurMission} />
        <Route path="/ni/about/ourpeople" exact component={NIOurPeople} />
        <Route
          path="/ni/about/ourelectricity"
          exact
          component={NIOurElectricity}/>
        <Route path='/ni/about/ourtariff' exact component={NIOurTariff} />
        <Route
          path="/ni/about/switchingtobright"
          exact
          component={NISwitchingToBright}
        />
        <Route path="/ni/energytheft" exact component={NIEnergyTheft} />
        <Route path="/ni/ourpolicies" exact component={NIOurPolicies} />
        <Route path="/ni/terms" exact component={NITermsAndConditions} />
        
        <Route path="/ni/codesofpractice" exact component={NICodesOfPractice} />
        <Route path="/ni/privacystatement" exact component={PrivacyStatement} />
        <Route path="/ni/sources" exact component={NIFuelMix} />
        <Route path="/ni/about/contactus" exact component={ContactUs} /> */}
        
        {/* CUSTOMER ACCOUNT */}
        <Route path="/ni/account/login" exact component={Login} />
        <Route path="/ni/account/register" exact component={Register} />
        <Route
          path="/ni/account/requestpasswordreset"
          exact
          component={RequestResetPassword}
        />
        <Route
          path="/ni/account/passwordreset"
          exact
          component={SetNewPassword}
        />
        {/* FAQs */}
        {/* <Route
          path="/ni/faqs/gettingstarted"
          exact
          component={NIFAQsGettingStarted}
        />
        <Route
          path="/ni/faqs/mybrightaccount"
          exact
          component={NIFAQsBrightAccount}
        />
        <Route
          path="/ni/faqs/billingandpayments"
          exact
          component={NIFAQsBillingAndPayments}
        />
        <Route
          path="/ni/faqs/meterreadings"
          exact
          component={NIFAQsMeterReadings}
        />
        <Route
          path="/ni/faqs/movinghouse"
          exact
          component={NIFAQsMovingHouse}
        />
        <Route
          path="/ni/faqs/additionalhelp"
          exact
          component={NIFAQsAdditionalHelp}
        />
        <Route path="/ni/help" exact component={Help} />
        <Route path="/ni/help/faqs" exact component={NIFAQS} />
        <Route
          path="/ni/faqs/prepaymentmeters"
          exact
          component={NIFAQSPrepaymentmeters}
        />
        <Route path="/ni/faqs/complaints" exact component={NIFAQSComplaints} />
        <Route path="/ni/keypadtopup" exact component={VendDetailsPage} />
        <Route
          path="/ni/keypadtopuppayment"
          exact
          component={PaymentDetailsPage}
        />
        <Route
          path="/ni/keypadtopupconfirmation"
          exact
          component={VendConfirmationPage}
        />
        <Route
          path="/ni/keypadtopupunsuccessful"
          exact
          component={VendFailure}
        />
        <Route path="/ni/help/emergencyinfo" exact component={EmergencyInfo} />
        <Route
          path="/ni/help/energyefficiency"
          exact
          component={EnergyEfficiency}
        />
        <Route path="/ni/help/faqs" exact component={FAQS} />
        <Route
          path="/ni/help/makeacomplaint"
          exact
          component={MakeAComplaint}
        />
        <Route
          path="/ni/help/addadditionalservices"
          exact
          component={AddAdditionalServices}
        />
        <Route path='/ni/help/meterreadings' exact component={MeterReadings} /> */}

        {/* Tempory ni dashboard */}
        <PrivateRoute
          path="/ni/account/pending"
          exact
          component={NITempDashboard}
        />
        <PrivateRoute
          path="/ni/account/signuprequired"
          exact
          component={SignupRequired}
        />
        <PrivateRoute
          path="/ni/account/dashboard"
          exact
          component={Dashboard}
        />
        <PrivateRoute path="/ni/account/payment" exact component={Payment} />
        <PrivateRoute
          path="/ni/account/meterreading"
          exact
          component={MeterReading}
        />
        <PrivateRoute
          path="/ni/account/energyusage"
          exact
          component={EnergyUsage}
        />
        <PrivateRoute
          path="/ni/account/yourtariff"
          exact
          component={YourTariffNI}
        />
        <PrivateRoute
          path="/ni/account/bills-statements"
          exact
          component={BillsStatements}
        />
        <PrivateRoute
          path='/ni/account/topuphistory'
          exact
          component={TopupHistory}
        />
        <PrivateRoute path='/ni/account/support' exact component={Support} />
        <PrivateRoute
          path="/ni/account/manageaccount"
          exact
          component={ManageAccount}
        />
        <PrivateRoute path="/ni/account/more" exact component={More} />

        <PrivateRoute
          path="/ni/account/switchaccount"
          exact
          component={SwitchActiveAccount}
        />
        <PrivateRoute
          path='/ni/account/landlorddashboard'
          exact
          component={LandlordDashboard}
        />
        <PrivateRoute
          path='/ni/account/agencypropertyview'
          exact
          component={AgencyPropertyView}
        />
        <PrivateRoute
          path='/ni/account/agencypayment'
          exact
          component={AgencyPayment}
        />
<PrivateRoute
          path="/ni/account/paymenthistory"
          exact
          component={PaymentHistory}
        />
        
        {/* Refer a Friend Page
        {/* <Route path='/ni/referafriend' exact component={ReferAFriendPage} /> */}
        ////#endregion
        {/* 404 KEEP AT BOTTOM BELOW ROUTES */}
        <Route component={Page404} />
      </Switch>
    );
  }
}

export default Routes;
