import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from "mdbreact";
import { connect } from "react-redux";
import { quoteActions } from "../../../../../../redux/actions/quoteActions";
const LoadConfig = require("../../../../../../config");
const config = LoadConfig.default;

export class SetRural24hr extends Component {
  state = {
    tariffName: "bright future",
    meterType: "Smart",
    unitRate: config.TFH.toFixed(3),
    standingChargeUrban: ((config.SMStandingCharge / 365) * 100).toFixed(2),
    standingChargeRural: ((config.SMRuralStandingCharge / 365) * 100).toFixed(
      2
    ),
    psoCharge: config.monthlyPsoLevy.toFixed(2),
    ddAmount: "",
    collectionDate: "11 working days",
  };

  // Page load
  componentDidMount() {
    let ddAmount = this.props.journeyData.ddAmount;
    this.setState({ ddAmount: ddAmount });
  }

  async componentWillReceiveProps(nextProps) {
    let ddAmount = nextProps.journeyData.ddAmount;
    await this.setState({ ddAmount: ddAmount });
  }

  render() {
    return (
      <div>
        <MDBRow>
          <h4>Direct Debit Details</h4>
          <MDBCol size="12">
            <MDBTable className="mt-4">
              <MDBTableBody>
                <tr>
                  <td>Tariff name</td>
                  <td className="text-center">{this.state.tariffName}</td>
                </tr>
                <tr>
                  <td>Meter type</td>
                  <td className="text-center">{this.state.meterType}</td>
                </tr>
                <tr>
                  <td>Unit rate day (cent per kWh)</td>
                  <td className="text-center">{config.SMDay}</td>
                </tr>
                <tr>
                  <td>Unit rate night (cent per kWh)</td>
                  <td className="text-center">{config.SMNight}</td>
                </tr>
                <tr>
                  <td>Unit rate peak (cent per kWh)</td>
                  <td className="text-center">{config.SMPeak}</td>
                </tr>
                <tr>
                  <td>Standing charge urban (cent per day)</td>
                  <td className="text-center">
                    {this.state.standingChargeUrban}
                  </td>
                </tr>
                <tr>
                  <td>Standing charge rural (cent per day)</td>
                  <td className="text-center">
                    {this.state.standingChargeRural}
                  </td>
                </tr>
                <tr>
                  <td>PSO charge (€ per month)</td>
                  <td className="text-center">{this.state.psoCharge}</td>
                </tr>
                <tr>
                  <td>Direct debit amount</td>
                  <td className="text-center">
                    €{parseInt(this.state.ddAmount).toFixed(0)}
                  </td>
                </tr>
                <tr>
                  <td>Type of payment</td>
                  <td className="text-center">Recurrent</td>
                </tr>
                <tr>
                  <td>Collection Date</td>
                  <td className="text-center">{this.state.collectionDate}</td>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    quoteData: store.quote.quoteData,
    journeyData: store.switch.switchData,
  };
};

const actionCreators = {
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(SetRural24hr);
