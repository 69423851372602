import React, { useState, useEffect } from "react";
import {
    MDBRow,
    MDBCol,
    MDBBtn
} from "mdbreact";
// import Loader from "../../../../components/Shared/Loader";
import { useForm } from "react-hook-form";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { agencyActions } from "../../../redux/actions";

export const TenantMoveOut = () => {
    const dispatch = useDispatch();
    const agencyStore = useSelector((state) => state.agency)
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => {
        let dispatchData = data;
        dispatchData.meterReading = Number(data.meterReading);
        dispatchData.moveOutEvidence = moveOutEvidenceVal;
        dispatch(agencyActions.tenantMoveOut(dispatchData));
    };
    const [mprnRequired, setMprnRequired] = useState(true);
    const [accNumRequired, setAccNumRequired] = useState(true);
    const [moveOutEvidenceVal, setMoveOutEvidenceVal] = useState();
    const moveOutEvidence = watch("moveOutEvidence", "");

    useEffect(() => {
        if (moveOutEvidence === "Yes") {
            setMoveOutEvidenceVal(true);
        } else {
            setMoveOutEvidenceVal(false);
        }
    }, [moveOutEvidence]);

    return (
        <div>
            {agencyStore.tenantOutSuccess ? (
                <div className="modalBG">
                    <div className="modalBox">
                        <div className="white-curved-tile text-center">
                            <h4>Your request has been submited successfully!</h4>
                            <MDBRow>
                                <MDBCol lg="12" md="12" sm="12">
                                    <MDBBtn onClick={() => window.location.reload()}>
                                        Ok
                        </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="formInput">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <MDBRow className='mb-6'>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Account Number</label>
                            <input {...register("accNo",
                                {
                                    required: accNumRequired,
                                    pattern: /^[0-9]{1,9}/g,
                                    onChange: () => setMprnRequired(false),
                                    maxLength: "9",
                                    minLength: "9"
                                })} />
                            {errors.accNo && <span>Please enter your 9 digit account number</span>}
                        </MDBCol>
                        <MDBCol lg='1'></MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>MPRN</label>
                            <input {...register("mprn",
                                {
                                    required: mprnRequired,
                                    pattern: {
                                        value:  /^(?=\d{11}$)(10)\d+/,
                                        message: "Digits begining with 10 expected"
                                    },
                                    onChange: () => setAccNumRequired(false)
                                })} />
                            {errors?.mprn?.message && <span>{errors?.mprn?.message}</span>}
                        </MDBCol>

                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Final Meter Read</label>
                            <input {...register("meterReading", { 
                                required: true, 
                                maxLength: 5 ,
                                pattern: /^[_0-9]{5}/g
                                })} />
                            {errors.meterReading && <span>This field is required with a max length of 5 digits</span>}
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='mb-6'>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Move Out Date</label>
                            <input
                                placeholder="01/01/1990"
                                type="date"
                                min={new Date().toISOString().slice(0, 10)}
                                {...register("moveOutDate", { required: true })}
                            />
                            {errors.moveOutDate && <span>This field is required</span>}
                        </MDBCol>
                        <MDBCol lg='1'></MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Move Out Evidence</label>
                            <select {...register("moveOutEvidence", { required: true })} >
                                <option value=""></option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            {errors.moveOutEvidence && <span>This field is required</span>}
                        </MDBCol>
                    </MDBRow>
                    <MDBRow center className="mb-2">
                        <MDBCol lg="4">
                            <MDBBtn className="my-3" type="submit">
                                Submit
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </form>
            </div>
        </div>
    )
};