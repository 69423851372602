import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  render() {
    return (
      <MDBFooter>
        <div className='white-text teal-background'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='5' lg='3' className='text-left'>
                <img
                  className='footer-logo'
                  src={require("../../images/global/bright-logo.gif")}
                />
                <p className='footer-address'>
                  Registered in Ireland:
                  <br /> No: 640097
                  <br />
                  <br />
                  Registered Address:
                  <br />3 Custom House Plaza
                  <br /> IFSC, Dublin, D01 VY76
                </p>
              </MDBCol>

            </MDBRow>

            <hr />
            <MDBRow className='grid-container copyright-container'>
              <MDBCol sm='12' md='12' lg='12'>
                <p className='text-left'>
                  &copy; bright 2020
                  <br />
                  
                  <br />
                  {config.versionNumber}
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </MDBFooter>
    );
  }
}

export default Footer;
