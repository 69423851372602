import React, { Component } from "react";

import {
  MDBTabPane,
  MDBTabContent,
  MDBNavLink,
  MDBNavItem,
  MDBNav,
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBContainer,
} from "mdbreact";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

class TariffTabs extends Component {
  state = {
    activeItem: "1",
    activeItemName: "24h",
    jurisdiction: "roi",
  };

  //   Handle tab change
  toggle = (tab, name) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
        activeItemName: name,
      });
    }
  };

  render() {
    return (
      <MDBContainer>
        <div className="contactTabs tariffTabs">
          <MDBRow>
            <MDBCol sm="12" className="pl-0 pr-0">
              <MDBNav className="nav-tabs mt-5">
                <MDBNavItem>
                  <MDBNavLink
                    className={
                      this.state.activeItemName == "24h"
                        ? "activeTabPane cocogoose mobile-tab-width"
                        : "cocogoose mobile-tab-width"
                    }
                    link
                    to="#"
                    active={
                      this.state.activeItem === "1" &&
                      this.state.activeItemName === "24h"
                    }
                    onClick={this.toggle("1", "24h")}
                    role="tab"
                  >
                    24 hour Standard Meter
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    className={
                      this.state.activeItemName == "daynight"
                        ? "activeTabPane cocogoose mobile-tab-width mobile-tab-margin"
                        : "cocogoose mobile-tab-width mobile-tab-margin"
                    }
                    link
                    to="#"
                    active={
                      this.state.activeItem === "2" &&
                      this.state.activeItemName === "daynight"
                    }
                    onClick={this.toggle("2", "daynight")}
                    role="tab"
                  >
                    Day/Night Meter
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    className={
                      this.state.activeItemName == "smartmeter"
                        ? "activeTabPane cocogoose mobile-tab-width mobile-tab-margin"
                        : "cocogoose mobile-tab-width mobile-tab-margin"
                    }
                    link
                    to="#"
                    active={
                      this.state.activeItem === "3" &&
                      this.state.activeItemName === "smartmeter"
                    }
                    onClick={this.toggle("3", "smartmeter")}
                    role="tab"
                  >
                    Smart Meter
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNav>
            </MDBCol>
          </MDBRow>

          <MDBTabContent activeItem={this.state.activeItem}>
            <MDBTabPane tabId="1" role="tabpanel">
              <MDBRow className="tabcontent">
                <MDBCol md="12" className="content p-5">
                  <MDBTable bordered>
                    <MDBTableHead>
                      <tr className="montserrat-Bold">
                        <th></th>
                        <th>Excl VAT</th>
                        <th>Incl VAT</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      <tr>
                        <td className="montserrat-Bold">
                          Unit Rate (c per kWh)
                        </td>
                        <td>{config.TFHExVat.toFixed(3)}</td>
                        <td>{config.TFH.toFixed(3)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Urban standing charge (€ per year)
                        </td>
                        <td>€{config.urbanStandingChargeExVat.toFixed(2)}</td>
                        <td>€{config.urbanStandingCharge.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Rural standing charge (€ per year)
                        </td>
                        <td>€{config.ruralStandingChargeExVat.toFixed(2)}</td>
                        <td>€{config.ruralStandingCharge.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          PSO levy (€ per year)
                        </td>
                        <td>€{config.psoLevyExVat.toFixed(2)}</td>
                        <td>€{config.psoLevy.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Urban Estimated Annual Bill (EAB¹)
                        </td>
                        <td></td>
                        <td>€{config.TFHUrbEAB}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Rural Estimated Annual Bill (EAB¹)
                        </td>
                        <td></td>
                        <td>€{config.TFHRurEAB}</td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                  <p className="smallFont mt-5">
                    <p className="montserrat-Bold">
                      ¹What is an Estimated Annual Bill (EAB)?
                    </p>
                    The EAB is a simple indicator for customers to help them
                    make decisions when comparing tariffs. It calculates an
                    annual bill based on typical consumption figures provided by
                    the CRU and the charges associated with the tariff including
                    the unit rate, standing charge, PSO levy and VAT. This
                    allows you to compare one tariff against another. Please
                    note that your usage will vary from the CRU typical
                    consumption figure. This is not an indication of how much
                    your electricity will cost, but a useful comparison across
                    tariffs.
                  </p>
                  <p className="smallFont mt-5">
                    <p className="montserrat-Bold">
                      What is a Security Deposit?
                    </p>
                    We want to keep our costs as low as possible so that we can
                    keep your costs low. To reduce the risk of bad debt we
                    require all customers not paying in advance by direct debit
                    to pay €150 security deposit. Details of our security
                    deposit policy can be found{" "}
                    <a
                      href={`/${this.state.jurisdiction}/ourpolicies#security-deposit-policy`}
                    >
                      here.
                    </a>{" "}
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBTabPane>

            <MDBTabPane tabId="2" role="tabpanel">
              <MDBRow className="tabcontent">
                <MDBCol md="12" className="content p-5">
                  <MDBTable bordered>
                    <MDBTableHead>
                      <tr className="montserrat-Bold">
                        <th></th>
                        <th>Excl VAT</th>
                        <th>Incl VAT</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      <tr>
                        <td className="montserrat-Bold">
                          Day unit rate (c per kWh)
                        </td>
                        <td>{config.NSDayExVat.toFixed(3)}</td>
                        <td>{config.NSDay.toFixed(3)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Night unit rate (c per kWh)
                        </td>
                        <td>{config.NSNightExVat.toFixed(3)}</td>
                        <td>{config.NSNight.toFixed(3)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Urban standing charge (€ per year)
                        </td>
                        <td>€{config.urbanStandingChargeExVat.toFixed(2)}</td>
                        <td>€{config.urbanStandingCharge.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Rural standing charge (€ per year)
                        </td>
                        <td>€{config.ruralStandingChargeExVat.toFixed(2)}</td>
                        <td>€{config.ruralStandingCharge.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          PSO levy (€ per year)
                        </td>
                        <td>€{config.psoLevyExVat.toFixed(2)}</td>
                        <td>€{config.psoLevy.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Urban Estimated Annual Bill (EAB¹)
                        </td>
                        <td></td>
                        <td>€{config.NSUrbEAB}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Rural Estimated Annual Bill (EAB¹)
                        </td>
                        <td></td>
                        <td>€{config.NSRurEAB}</td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>

                  <p className="smallFont mt-5">
                    <p className="montserrat-Bold">
                      ¹What is an Estimated Annual Bill (EAB)?
                    </p>
                    The EAB is a simple indicator for customers to help them
                    make decisions when comparing tariffs. It calculates an
                    annual bill based on typical consumption figures provided by
                    the CRU and the charges associated with the tariff including
                    the unit rate, standing charge, PSO levy and VAT. This
                    allows you to compare one tariff against another. Please
                    note that your usage will vary from the CRU typical
                    consumption figure. This is not an indication of how much
                    your electricity will cost, but a useful comparison across
                    tariffs.
                  </p>
                  <p className="smallFont mt-5">
                    <p className="montserrat-Bold">
                      What is a Security Deposit?
                    </p>
                    All customers not paying in advance for their electricity
                    are required to pay a security deposit of €150. You can
                    avoid a security deposit by paying in advance by direct
                    debit.{" "}
                    <a
                      href={`/${this.state.jurisdiction}/ourpolicies#security-deposit-policy`}
                    >
                      Click here
                    </a>{" "}
                    for more detail.
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBTabPane>

            <MDBTabPane tabId="3" role="tabpanel">
              <MDBRow className="tabcontent">
                <MDBCol md="12" className="content p-5">
                  <MDBTable bordered>
                    <MDBTableHead>
                      <tr className="montserrat-Bold">
                        <th></th>
                        <th>Excl VAT</th>
                        <th>Incl VAT</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      <tr>
                        <td className="montserrat-Bold">
                          Day unit rate (c per kWh)
                        </td>
                        <td>{config.SMDayExVat.toFixed(3)}</td>
                        <td>{config.SMDay.toFixed(3)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Night unit rate (c per kWh)
                        </td>
                        <td>{config.SMNightExVat.toFixed(3)}</td>
                        <td>{config.SMNight.toFixed(3)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Peak unit rate (c per kWh)
                        </td>
                        <td>{config.SMPeakExVat.toFixed(3)}</td>
                        <td>{config.SMPeak.toFixed(3)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Urban standing charge (€ per year)
                        </td>
                        <td>€{config.SMUrbanStandingChargeExVat.toFixed(2)}</td>
                        <td>€{config.SMStandingCharge.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Rural standing charge (€ per year)
                        </td>
                        <td>€{config.SMRuralStandingChargeExVat.toFixed(2)}</td>
                        <td>€{config.SMRuralStandingCharge.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          PSO levy (€ per year)
                        </td>
                        <td>€{config.psoLevyExVat.toFixed(2)}</td>
                        <td>€{config.psoLevy.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Urban Estimated Annual Bill (EAB¹)
                        </td>
                        <td></td>
                        <td>€{config.SMUrbanEAB}</td>
                      </tr>
                      <tr>
                        <td className="montserrat-Bold">
                          Rural Estimated Annual Bill (EAB¹)
                        </td>
                        <td></td>
                        <td>€{config.SMRuralEAB}</td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>

                  <p className="smallFont mt-5">
                    <p className="montserrat-Bold">
                      ¹What is an Estimated Annual Bill (EAB)?
                    </p>
                    The EAB is a simple indicator for customers to help them
                    make decisions when comparing tariffs. It calculates an
                    annual bill based on typical consumption figures provided by
                    the CRU and the charges associated with the tariff including
                    the unit rate, standing charge, PSO levy and VAT. This
                    allows you to compare one tariff against another. Please
                    note that your usage will vary from the CRU typical
                    consumption figure. This is not an indication of how much
                    your electricity will cost, but a useful comparison across
                    tariffs.
                  </p>
                  <p className="smallFont mt-5">
                    <p className="montserrat-Bold">
                      What is a Security Deposit?
                    </p>
                    All customers not paying in advance for their electricity
                    are required to pay a security deposit of €150. You can
                    avoid a security deposit by paying in advance by direct
                    debit.{" "}
                    <a
                      href={`/${this.state.jurisdiction}/ourpolicies#security-deposit-policy`}
                    >
                      Click here
                    </a>{" "}
                    for more detail.
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBTabPane>
          </MDBTabContent>
        </div>
      </MDBContainer>
    );
  }
}
export default TariffTabs;
