// Combine Reducers
import { combineReducers } from "redux";

// Imported Reducer Files
import blogReducer from "./BlogReducer";
import signinReducer from "./signinReducer";
import quoteReducer from "./quoteReducer";
import meterReadReducer from "./meterReadReducer";
import switchReducer from "./switchReducer";
import faqReducer from "./faqReducer";
import paymentReducer from "./paymentReducer";
import appPromotionsReducer from "./appPromotionsReducer";
import contactReducer from "./contactReducer";
import customerAccountReducer from "./customerAccountReducer";
import keypadVendReducer from "./keypadVendReducer";
import keypadReducer from "./keypadReducer";
import locationReducer from "./locationReducer";
import agencyReducer from "./agencyReducer";
import additionalServicesReducer from "./additionalServicesReducer";

// Import as allReducers in src/index.js
const allReducers = combineReducers({
  // objectName: reducerUsed
  blog: blogReducer,
  signin: signinReducer,
  quote: quoteReducer,
  meterRead: meterReadReducer,
  switch: switchReducer,
  faq: faqReducer,
  payment: paymentReducer,
  appPromotions: appPromotionsReducer,
  contact: contactReducer,
  customerAccount: customerAccountReducer,
  keypadVend: keypadVendReducer,
  keypad: keypadReducer,
  location: locationReducer,
  agency: agencyReducer,
  additionalServices: additionalServicesReducer,
});

export default allReducers;
