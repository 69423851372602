import React, { Component } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdbreact";
import Section1 from "../components/Home/Section1";
import TrustPilot from "../components/Shared/Trustpilot";

import Swiper from "react-id-swiper";

import { Zoom, Fade } from "react-reveal";

import ReactPlayer from "react-player";
import Partners from "../components/Home/Partners";

// PDFS
import signupPDF from "../documents/signups.pdf";
import billingPDF from "../documents/billing.pdf";
import charterPDF from "../documents/charter.pdf";
import complaintsPDF from "../documents/complaints.pdf";
import marketingPDF from "../documents/marketing.pdf";
import VulnerablePDF from "../documents/vulnerable.pdf";
import smartServicesPDF from "../documents/smartServices.pdf";
import CRUMessage from "../components/Shared/CRUMessage";
import EAB from "../components/Shared/EAB";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoadConfig = require("../config");
const config = LoadConfig.default;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postcode: "",
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];

    if (juris != "roi" && juris != "ni") {
      window.location.href = "/roi";
    } else {
      this.setState({
        jurisdiction: juris,
      });
    }

  }

  render() {
    // News Seciton Swiper https://swiperjs.com/api/
    const newsSwiper = {
      slidesPerView: 3,
      spaceBetween: 0,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1000,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
      },

      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 30,
        },

        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },

        1024: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      },
    };

    return (
      //{/*<MDBAnimation reveal type="fadeInLeft">*/}
      //{/*</MDBAnimation>*/}

      // To target specific components on a page give the page a name for example.... <div className="default-page Home-Page">
      <div className='default-page home-page'>
        {/* Covid banner */}
        <ToastContainer />

        {/* Home Page Hero */}
        <MDBRow className='home-hero home-background-image'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='9' lg='9' className='content text-center offset-md-2'>
                <h3 className='white-text'>Bright is closing and you will be transferred to Electric Ireland from 26th January</h3>



              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>


        <div className='switch-today mt-2'>
          <MDBContainer className='switch-today-container mt-2'>
            <MDBRow className='switch-today large-padding pb-0'>
              <MDBCol sm='12' md='7' lg='8' className='title-container'>
                <div className='text-center'>
                  <i>Wednesday 26th January 2022</i>
                  <h4 className="mt-4">Bright is closing and you will be transferred to Electric Ireland from 26th January</h4>

                  <p className="mt-3">
                    Bright launched its 100% renewable electricity offering in 2020.  Since then, wholesale energy prices have increased by 200% and, unfortunately, operating under the current conditions with no sign of market recovery is no longer a viable option for us.
                  </p>

                  <p>We have now made the difficult decision to exit the energy market and we would like to take this opportunity to thank all of the amazing customers who joined us on an important green energy journey. </p>

                  <h4 className="mt-5">Customers will be moved to Electric Ireland</h4>
                  <p className="mt-3">As part of the exit, Bright has entered into a process with the Commission for Regulation of Utilities (CRU) called ‘Supplier of Last Resort’ (SOLR) which ensures customers have a seamless transfer to the new supplier which is Electric Ireland.  Customers will continue to receive their electricity supply and will not experience any disruption to their service.
                  </p>

                  <br />
                  <h4>What happens next</h4>
                  <div className="text-left">

                    <ul className="mt-3 happensnext">
                      <li>You do not need to do anything as your account will seamlessly transfer to <b>Electric Ireland</b> from the <b>26th January 2022</b> and there will be no interruption to your supply.</li>
                      <li>Electric Ireland will contact you to provide details of your new account.</li>
                      <li>There are no exit or transfer fees for the transfer to your new supplier.</li>
                      <li>Once the switch to Electric Ireland is complete Bright will issue your final bill, any credit on your account will be refunded within 14 working days from the final bill to the bank details we hold.</li>
                      <li>If there is any outstanding balance due this will be collected by Bright under the terms and conditions of your contract.  Please do not cancel any Direct Debits as these will be used to collect your payment.</li>
                    </ul>
                  </div>

                  <br />
                  <h4>Will my bill be affected?</h4>
                  <p>
                    Your contract with Bright ends when the switch takes place, you will then be charged at the new lowest standard variable rate from Electric Ireland. You will find specific information on Electric Ireland tariffs on their website www.electricireland.ie. or you can <b>contact</b> one of their sales agents on <b>1800 30 50 90</b>.
                    Throughout this process you will find the latest information at www.electricireland/brightenergy.
                  </p>
                </div>
              </MDBCol>
            </MDBRow>
            
            <MDBRow className='large-padding pt-0'>

            </MDBRow>
          </MDBContainer>
        </div>

        


      </div>
    );
  }
}

export default Home;
