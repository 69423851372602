import React from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

// Redux Addins
import { createStore, compose, applyMiddleware } from "redux";
import allReducers from "./redux/reducers";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

//Google imports
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

//config
const LoadConfig = require("./config");
const config = LoadConfig.default;

const tagManagerArgs = {
  gtmId: config.googleTagManagerId,
};
TagManager.initialize(tagManagerArgs);

const trackingId = "UA-159169054-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.set({
  // userId: ID(),
  // any data that is relevant to the user session
  // that you would like to track with google analytics
});

const create_UUID = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

// Store -> Globalized State (App Data)
const store = createStore(
  allReducers,
  composeWithDevTools(applyMiddleware(thunk))
  // applyMiddleware(thunk)
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
