export const blogConstants = {
  // Get All Posts
  GET_ALL_BLOG_REQUEST: "GET_ALL_BLOG_REQUEST",
  GET_ALL_BLOG_SUCCESS: "GET_ALL_BLOG_SUCCESS",
  GET_ALL_BLOG_FAILURE: "GET_ALL_BLOG_FAILURE",

  // Get By ID
  GET_BY_ID_REQUEST: "GET_BY_ID_REQUEST",
  GET_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
  GET_BY_ID_FAILURE: "GET_BY_ID_FAILURE",

  // Create Post
  CREATE_POST_REQUEST: "CREATE_POST_REQUEST",
  CREATE_POST_SUCCESS: "CREATE_POST_SUCCESS",
  CREATE_POST_FAILURE: "CREATE_POST_FAILURE",

  // Edit Post
  EDIT_POST_REQUEST: "EDIT_POST_REQUEST",
  EDIT_POST_SUCCESS: "EDIT_POST_SUCCESS",
  EDIT_POST_FAILURE: "EDIT_POST_FAILURE",

  // Delete Post
  DELETE_POST_REQUEST: "DELETE_POST_REQUEST",
  DELETE_POST_SUCCESS: "DELETE_POST_SUCCESS",
  DELETE_POST_FAILURE: "DELETE_POST_FAILURE",

  // Get By TITLE
  GET_BY_TITLE_REQUEST: "GET_BY_TITLE_REQUEST",
  GET_BY_TITLE_SUCCESS: "GET_BY_TITLE_SUCCESS",
  GET_BY_TITLE_FAILURE: "GET_BY_TITLE_FAILURE",
};
