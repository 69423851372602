import React from "react";
import ROISwitchAccount from "../../../components/Forms/Switch/ROI/_FormAccountDetails";

class SwitchAccount extends React.Component {
  state = {
    jurisdiction: "roi",
  };

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  render() {
    return (
      <div>
        <ROISwitchAccount />
      </div>
    );
  }
}

export default SwitchAccount;
