import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FAQTabs from "../../../components/Help/FAQTabs";

import FixedNavDark from "../../../components/Shared/FixedNavDark";
import $ from "jquery";

class NIFAQsBillingAndPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark /> */}

        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>FAQs</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow className='pb-5'>
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <FAQTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5'>
            <h3>Billing and Payments</h3>

            {/* <div id='dontunderstandbill' className='mt-5 mb-5'>
              <h5 className='policy-title'>I don’t understand my bill - what do all the sections mean? </h5>
              <p className='policy-content'>
              <img src={('https://www.aluminati.net/wp-content/uploads/2016/03/img-placeholder.png')} />
              </p>
            </div> */}



            <div id='paymentreminder' className='mt-5 mb-5'>
              <h5 className='policy-title'>
                I received a payment reminder letter, but I’ve already paid.  {" "}
              </h5>
              <p className='policy-content'>
                Your reminder letter may have been issued before your payment was received.   If the date on the letter is before you made a payment you don’t need to do anything. You may wish to log in to your account and check your balance.
              </p>
            </div>

            <div id='billhigherthannormal' className='mb-5'>
              <h5 className='policy-title'>
                Why is my bill higher than normal?{" "}
              </h5>
              <p className='policy-content'>
                Your bill may be higher than normal for several reasons: <br />
                <ul>
                  <li>
                    Your current or previous bill was based on an estimated
                    meter reading.{" "}
                  </li>
                  <li>
                    An increased use of electricity since the last bill - a change in the weather or your circumstances may mean you are using heating or appliances more compared to the previous billing period.{" "}
                  </li>
                  <li>A change in the tariff since your last bill.</li>

                </ul>
              </p>
            </div>

            <div id='reducebill' className='mb-5'>
              <h5 className='policy-title'>
                How can I reduce my electricity bill?{" "}
              </h5>
              <p className='policy-content'>
                The best way to save money on energy is to use less. We have a code of practice on the efficient use of electricity which sets out lots of helpful tips and ideas for saving energy, you can find this{" "}
                <a href={`/${this.state.jurisdiction}/about/ourelectricity`}>
                  here. {""}
                </a>
                  You can also find lots more information on our website. The NI Energy Advice service offers free comprehensive advice and support on how to save energy in the home and on assistance available. You can call <a href='tel:0800 111 44 55'>0800 111 44 55</a>  during office hours or use the callback service for out of hours. You can also visit the NI Housing Executive website at  <a href='https://www.nihe.gov.uk'>www.nihe.gov.uk</a> under Community. This offers advice and a contact form for energy efficiency advice and signposting for grants.
              </p>
            </div>

            <div id='paymentoptions' className='mb-5'>
              <h5 className='policy-title'>
                What payment options are available with bright?{" "}
              </h5>
              <p className='policy-content'>
                <b> Monthly direct debit </b>
                <br />
                We will agree a monthly amount with you, based on actual or forecasted annual usage and collect that amount from you each month. We will review this amount regularly against your usage to ensure you are not over or under paying.  If this date falls on a weekend or bank holiday it will be processed on the next banking day.
                <br />
                <br />
                <b> Direct Debit in arrears </b>
                <br />
                Every month we will send you a bill for your usage that month and we will collect the amount shown on the bill within 14 days from your account using the bank details you have provided to us.
                <br />
                <br />
                <b> Payment by card </b>
                <br />
                You can make a payment with a debit card through the app or <a href="/ni/account/login/">‘my account’</a> section of the website or by contacting our energy specialists.
              </p>
            </div>

            <div id='estimatedread' className='mb-5'>
              <h5 className='policy-title'>
                How do I know if my bill is based on an estimated meter read?
              </h5>
              <p className='policy-content'>
                The letter 'E' will be shown beside your meter reading in your
                electricity usage section of your bill. If you think the
                estimate is incorrect you can submit a meter reading and we will
                reissue your bill.
              </p>
            </div>

            <div id='viewbill' className='mb-5'>
              <h5 className='policy-title'>
                Can I view my bill in my bright account?{" "}
              </h5>
              <p className='policy-content'>
                You can view or download your current or previous bills via your customer account on the bright app or online a {" "}
                <a href={`/${this.state.jurisdiction}/account/login`}>
                  here.
                </a>{" "}
                If you haven't registered your customer account be sure to register your account<a href={`/${this.state.jurisdiction}/account/register`}>
                  {" "} here.
                </a>{" "}

              </p>
            </div>

            <div id='haventreceivedbill' className='mb-5'>
              <h5 className='policy-title'>I haven’t received my bill. </h5>
              <p className='policy-content'>
                You can view/download this from your{" "}
                <a href={`/${this.state.jurisdiction}/account/login`}>
                  online account.
                </a>{" "}

                {" "}Please check we hold the correct contact information for you.
              </p>
            </div>



            <div id='nextbilldate' className='mb-5'>
              <h5 className='policy-title'>
                How can I check my next bill date?{" "}
              </h5>
              <p className='policy-content'>
                We will bill you on a monthly basis.  To find the date your next bill is due view your <a href={`/${this.state.jurisdiction}/account/login`}>
                  online account.</a>
              </p>
            </div>

            <div id='accountbalance' className='mb-5'>
              <h5 className='policy-title'>
                Where can I find my account balance?
              </h5>
              <p className='policy-content'>
                You can find this on your previous bill or from your <a href={`/${this.state.jurisdiction}/account/login`}>
                  online account.
                </a>
              </p>
            </div>

            <div id='billcalculated' className='mb-5'>
              <h5 className='policy-title'>
                How has my bill been calculated?
              </h5>
              <p className='policy-content'>
                Your bill is worked out by using your present meter reading, calculating how many units of electricity you have used since your previous meter reading and multiplying this by the tariff rate. A standing charge is also included for each day in the billing period. This can be found on page 3 of your bill.
              </p>
            </div>




            <div id='issuewithpayment' className='mb-5'>
              <h5 className='policy-title'>
                I have an issue with my payment- who should I contact?  {" "}
              </h5>
              <p className='policy-content'>
                <b>Insufficient funds in bank account</b>
                <br />
                If you know you do not have enough funds to cover your direct debit, please give us a call on <a href='tel:028 9544 2290.'>028 9544 2290.</a>  and we can discuss it with you.
                <br />
                <br />
                <b>Direct debit unsuccessful</b>
                <br />
                If we have tried to collect your direct debit unsuccessfully, we
                will contact you to discuss.
                <br />
                <br />
                <b>Card payment unsuccessful</b>
                <br />
                If you recently made a card payment on your bill
                and are concerned that we have not received it, please check your online account,
                or give us a call on <a href='tel:028 9544 2290.'>028 9544 2290.</a>
                <br />
                <br />
                <b>Missed a payment</b>
                <br />
                If you know you have missed a payment,
                please give us a call on <a href='tel:028 9544 2290.'>028 9544 2290.</a> and we can discuss it with you.
                Otherwise we will write to you asking you to make payment.
                <br />
                <br />
                <b>Direct debit not taken</b>
                <br />
                If your direct debit was not taken on the due date
                this may be because the due date was a bank holiday
                and it will be collected on the next banking day.
                If your direct debit has not been taken at all,
                please give us a call on{" "}
                <a href='tel:028 9544 2290'>028 9544 2290</a>.
                <br />
                <br />
                <b>Reminder letter</b>
                <br />
                If we do not receive your payment by the bill due date,
                we will issue a reminder letter.
                If you receive a letter but have already paid this may
                 be because your reminder letter was issued prior to your
                  payment being received. If the date on the letter is
                  before you made a payment you do not need to do anything.
                  You may wish to log in to your account and check your balance.
              </p>
            </div>

            <div id='strugglingtopay' className='mb-5'>
              <h5 className='policy-title'>
                What options are available to me, if I’m struggling to pay my
                bill?
              </h5>
              <p className='policy-content'>
                If you are struggling to pay your bill
                please get in touch with us as soon as
              possible on <a href='tel:028 9544 2290.'>028 9544 2290,</a> the sooner you
              get in touch, the sooner we can help.
              Our energy specialists are trained to
              offer help and advice, we will work with
              you to find a solution that suits your needs.
              We have a code of practice on payment of bills
              which sets out the help that we offer. Find out more{" "}
                <a href={`/${this.state.jurisdiction}/codesofpractice`}>here</a>
              </p>
            </div>

            <div id='helppaybill' className='mb-5'>
              <h5 className='policy-title'>
                Where can I find independent help if I'm struggling to pay my bill?{" "}
              </h5>
              <p className='policy-content'>
                For free, confidential and impartial debt and money
                advice  you can contact Advice NI on their helpline
               <a href='tel:0800 951 4604'> 0800 951 4604</a> or email <a href='mailto:advice@adviceni.net'>
                  advice@adviceni.net. </a>  Check
              out the Advice NI website for the services they offer
              at <a href='https://www.advice@adviceni.net'>
                  advice@adviceni.net</a> or write to them at Advice NI,
              Forestview, Purdy's Lane, Belfast, BT8 7AR.
              You can also contact the Consumer Council for Northern
              Ireland for advice on <a href='tel:0800 121 6022'> 0800 121 6022,</a> by email at
              <a href='mailto:contact@consumercouncil.org.uk'> contact@consumercouncil.org.uk</a> visit their website
              at  <a href='www.consumercouncil.org.uk'> www.consumercouncil.org.uk</a> or write to The Consumer
              Council, Floor 3, Seatem House, 28-32 Alfred Street,
              Belfast, BT2 8EN.  We will always work with you or
              agencies working on your behalf to find a solution
              that suits your needs.
              </p>
            </div>

            <div id='standingcharge' className='mb-5'>
              <h5 className='policy-title'>
                What is a standing charge and why do I have to pay this? {" "}
              </h5>
              <p className='policy-content'>
                This is a fixed charge for supplying your house with electricity and is charged on a daily basis whether you use electricity or not (for example if you are on holiday).
              </p>
            </div>

            <div id='standingcharge' className='mb-5'>
              <h5 className='policy-title'>
                What tariff am I on?  {" "}
              </h5>
              <p className='policy-content'>
                At bright we offer one standard variable tariff - our bright future tariff.  For details of your current unit rate visit the tariff section of our website at {""}
                <a href={`/${this.state.jurisdiction}/ourtariff`}>here</a>
              </p>
            </div>


            <div id='nominatedperson' className='mb-5'>
              <h5 className='policy-title'>
                I need my bills to be sent to the nominated person on my account. {" "}
              </h5>
              <p className='policy-content'>
                We can set up a nominated person on your account
                to receive your bills and to chat to us about your account.
                We need your permission and the permission of the nominated person.
              Contact us on <a href='tel:028 9544 2290'>028 9544 2290</a> and we'll organise this for you.
              </p>
            </div>
            <div id='nominatedperson' className='mb-5'>
              <h5 className='policy-title'>
                When there is a tariff change how do you apportion my usage?
              </h5>
              <p className='policy-content'>
                When a tariff change occurs during the billing period we split your usage for the month between the old and new tariff, based on the number of days in the billing period before and after the tariff change takes effect.
            
                For example, if your billing period is 1 November to 30 November and there is a tariff change on 16 November, we will apportion your usage based on 15 days at the existing tariff and 15 days at the new tariff.  Your bill will show you the usage which has been charged at the old rate and the new rate, and the number of days charged at the existing and new standing charge.
                If you have any queries about the apportionment on your bill please contact our energy specialists.
              </p>
            </div>



          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NIFAQsBillingAndPayments;
