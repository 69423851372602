import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import NavigationSection from "../../../components/CustomerAccount/AccountNavigation";
import { customerAccountActions } from "../../../redux/actions/customerAccountActions";
import { connect } from "react-redux";
import Loader from "../../../components/Shared/Loader";
import { sessionExpired } from "../../../redux/helpers/sessionExpired";

class BillsStatementsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      howToTip: false,
      jurisdiction: "",
      numPages: null,
      pageNumber: 1,
      bills: [],
      currency: "",
      curJurisdiction: "",
    };
  }

  getBill = (id) => {
    this.props.getBill(id, this.state.jurisdiction);
  };

  // Populate form on back
  async componentWillReceiveProps(nextProps) {
    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  componentDidMount() {
    sessionExpired();

    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    this.setState({
      jurisdiction: jurisdiction,
    });

    if (jurisdiction == "roi") {
      this.setState({ currency: "€" });
    } else {
      this.setState({ currency: "£" });
    }

    // Get user info
    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;
    let curj = user.data.authenticateModel.currentJurisdiction;

    // Map customer bills
    let billsList = jModel.billsList;
    this.setState({
      curJurisdiction: curj,
      bills: billsList,
    });
  }

  render() {
    return (
      <div className="account-dashboard-container">
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}
        <div className="account-details-container">
          <NavigationSection />
          <div className="make-payment-background">
            <MDBContainer className="main-content payment-history">
              {/* Page Title */}
              <MDBRow>
                <MDBCol size="1">
                  <a href={`/${this.state.jurisdiction}/account/more`}>
                    <i className="fas fa-chevron-left fa-2x" />
                  </a>
                </MDBCol>
                <MDBCol size="10" className="text-center ">
                  <h1 className="mt-1">Bills & Statements</h1>
                </MDBCol>
                <MDBCol size="1"></MDBCol>
              </MDBRow>

              {this.state.bills.length == 0 ? (
                <MDBRow>
                  <MDBCol>
                    <div className="white-curved-tile text-center">
                      <h4>
                        Looks like you don't have any bills yet! Check back soon
                        to view your recent bills.
                      </h4>
                    </div>
                  </MDBCol>
                </MDBRow>
              ) : (
                <div className="white-curved-tile">
                  <MDBRow className="moreHR">
                    <MDBCol size="6" className="">
                      <h5 className="mt-3">Bill period to</h5>
                    </MDBCol>
                    <MDBCol size="6" className="text-right">
                      <h5 className="mt-3">
                        Amount
                        <i className="fas fa-chevron-right fa-lg pl-5 white-text" />
                      </h5>
                    </MDBCol>
                  </MDBRow>
                  {/*check jurisdiction */}
                  {this.state.curJurisdiction.toLowerCase() ==
                  this.state.jurisdiction ? (
                    <span>
                      {this.state.bills != null
                        ? this.state.bills.map((bills) =>
                            // Only show accepted bills
                            bills.status == "Accepted" &&
                            bills.supersededFl == false ? (
                              <a
                                onClick={() => {
                                  this.getBill(bills.id);
                                }}
                              >
                                <MDBRow className="moreHR">
                                  <MDBCol size="6" className="">
                                    <h5 className="mt-3">
                                      {bills.periodTo
                                        .slice(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("/")}
                                    </h5>
                                  </MDBCol>
                                  <MDBCol size="6" className="text-right">
                                    <h5 className="mt-3">
                                      {this.state.currency}
                                      {bills.grossAmount.toFixed(2)}
                                      {/* <i className='fas fa-chevron-right fa-lg pl-5' /> */}
                                      <span className="ml-4 mr-3">View</span>
                                    </h5>
                                  </MDBCol>
                                </MDBRow>
                              </a>
                            ) : null
                          )
                        : null}
                    </span>
                  ) : null}
                </div>
              )}
            </MDBContainer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    billData: store.customerAccount.latestBill,
    isLoading: store.customerAccount.isLoading,
  };
};

const actionCreators = {
  getBill: customerAccountActions.getBillById,
};

export default connect(mapStateToProps, actionCreators)(BillsStatementsSection);
