import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FixedNavDark from "../../../components/Shared/FixedNavDark";
import NITariffComparison from "../../../components/About/NITariffComparison";


// Trustpilot Component
import TrustPilot from "../../../components/Shared/Trustpilot";
import {NISubstantiation} from "../../../components/Shared/NISubstantiation";

import $ from "jquery";

class NISwitchingToBright extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener("scroll", this.help);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  };

  render() {
    return (
      <div
        className='default-page switching-to-bright-page'
        onScroll={this.help()}
      >
        {/* <FixedNavDark /> */}

        {/* Quote Block */}
        <div className='quote-section swirl-bg mt-5 pt-5'>
          <MDBRow className='large-padding'>
            <MDBCol sm='12' md='8' lg='6' className='content mt-5 pt-5 no-bg'>
              {/* Title */}
              <h2>
              Join bright today for 100% renewable electricity
              </h2>

              {/* Button */}
              <div className='text-center'>
                
              </div>
            </MDBCol>
          </MDBRow>

        </div>

        {/* Switch info block */}
        <MDBContainer className='text-left-image-right-section'>
          <MDBRow>
            <MDBCol sm='12' md='10' lg='10' className='offset-md-1'>
              <MDBRow className='large-padding'>
                <MDBCol sm='12' md='12' lg='6' className='content'>
                  <p className='sm-text'>
                    {" "}
                    Joining bright is easy, we supply 100% renewable electricity and 100% control with our app.
                  </p>
                  <p className='sm-text'>
                    We've worked hard to simplify our product to give you full
                    control. You always know you're getting our best price and
                    that's why there are no tie-ins!
                  </p>
                  <p className='sm-text'>
                    Join online for 100% renewable electricity with no disruption
                    to your supply. We will notify your current supplier, making
                    the whole process hassle free.
                  </p>
                </MDBCol>
                <MDBCol sm='12' md='12' lg='6' className='text-center'>
                  <img src={require("../../../images/switching/image-1-min.png")} />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        {/* Text with Icons Section */}
        <div>
          <MDBContainer className='text-icons-section large-padding pt-0'>
            <MDBRow>
              <MDBCol
                sm='6'
                md='3'
                lg='3'
                className='text-center icons-container'
              >
                <img
                  src={require("../../../images/home-page/clean-energy-icon.png")}
                ></img>
                <h5 className='sm-heading'>
                  100% <br /> renewable electricity
                </h5>
              </MDBCol>
              <MDBCol
                sm='6'
                md='3'
                lg='3'
                className='text-center icons-container'
              >
                <img src={require("../../../images/home-page/pound.png")}></img>
                <h5 className='sm-heading'>
                  One <br /> variable tariff
                </h5>
              </MDBCol>

              <MDBCol
                sm='6'
                md='3'
                lg='3'
                className='text-center icons-container'
              >
                <img
                  src={require("../../../images/switching/tie-ins-icon.png")}
                ></img>
                <h5 className='sm-heading'>No tie ins</h5>
              </MDBCol>

              <MDBCol
                sm='6'
                md='3'
                lg='3'
                className='text-center icons-container'
              >
                <img
                  src={require("../../../images/home-page/manage-account-icon.png")}
                ></img>
                <h5 className='sm-heading'>
                  Managing your <br /> account is simple
                </h5>
              </MDBCol>
            </MDBRow>
          </MDBContainer>

          {/* Text with background image section */}
          <div className='text-background-image-1'>
            <MDBContainer className='text-background-image-section'>
              <MDBRow className=' large-padding'>
                <MDBCol sm='12' md='9' lg='6' className='content'>
                  <h2>
                    Jump on and <br /> belong today
                  </h2>
                  <p className='montserrat-Bold'>
                    All you need to join is:
                  </p>
                  <ul className='bullet-list'>
                    <li>Your MPRN or KPN</li>
                    <li>A meter reading</li>
                    <li>Your bank details if paying monthly</li>
                  </ul>
                 
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>

          {/* Download app block */}
          <MDBContainer className='text-left-image-right-section'>
            <MDBRow>
              <MDBCol sm='12' md='10' lg='10' className='offset-md-1'>
                <MDBRow className='my-5'>
                  <MDBCol sm='12' md='12' lg='6' className='content'>
                    <h4>
                      Download the bright app and manage your account anytime,
                      anywhere
                    </h4>
                    <p className='sm-text'>
                      Make payments, submit your meter readings, access energy
                      saving tips and more all from the palm of your hand!
                      Join today and take back control.
                    </p>
                    
                    <MDBRow className='switch-download-app-container'>
                      <MDBCol sm='12' className='text-center'>
                        <a target="_blank" href='https://apps.apple.com/app/id1500409256'>
                          <img
                            src={require("../../../images/ios.png")}
                            className='app-store'
                          />
                        </a>
                        <a target="_blank" href='https://play.google.com/store/apps/details?id=com.brightapp'>
                          <img
                            src={require("../../../images/google.png")}
                            className='app-store'
                          />
                        </a>
                        {/* <a onClick={() => alert("Coming Soon")}>
                          <img
                            src={require("../../images/google.png")}
                            className='fullImg app-store'
                          />
                        </a> */}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol
                    sm='12'
                    md='12'
                    lg='6'
                    className='text-center app-image'
                  >
                    <img
                      src={require("../../../images/home-page/bright-app-phone.png")}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
      
        </div>
      </div>
    );
  }
}

export default NISwitchingToBright;
