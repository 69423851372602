import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import NavigationSection from "../../components/CustomerAccount/AccountNavigation";
import PersonalDetails from "./ManageAccount/PersonalDetails";
import CorrespondanceDetails from "./ManageAccount/CorrespondanceDetails";
import MarketingPreferences from "./ManageAccount/MarketingPreferences";
import { sessionExpired } from "../../redux/helpers/sessionExpired";

class ManageSection extends React.Component {
  componentDidMount() {
    sessionExpired();
  }

  render() {
    return (
      <div className="account-dashboard-container">
        <div className="account-details-container">
          <NavigationSection />
          <div className="make-payment-background">
            <MDBContainer className="main-content electricity-usage">
              {/* Page Title */}
              <MDBRow>
                <MDBCol size="12" className="text-center">
                  <h1>My Account</h1>
                </MDBCol>
              </MDBRow>

              {/* Personal Details */}
              <MDBRow className="mb-5">
                <PersonalDetails />
              </MDBRow>

              {/* Correspondance Details
            <MDBRow className='mb-5'>
              <CorrespondanceDetails />
            </MDBRow> */}

              {/* Marketing Preferences
            <MDBRow className='mb-5'>
              <MarketingPreferences />
            </MDBRow> */}
            </MDBContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageSection;
