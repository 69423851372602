import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from "mdbreact";
import { connect } from "react-redux";
import { quoteActions } from "../../../../../redux/actions/quoteActions";
const LoadConfig = require("../../../../../config");
const config = LoadConfig.default;

export class Urban24hrPopup extends Component {
  state = {
    tariffTip: false,
    jurisdiction: "roi",
    paym: "",
    annualCalc: 0,
  };

  // componentDidMount() {
  //   let annual = ((config.urb_tfh_unitrate * this.props.quoteData.electricityUsage).toFixed(2)
  //     + (config.urb_tfh_standing)
  //     + (config.urb_tfh_pso)).toFixed(2);
  //   this.setState({
  //     annualCalc: annual
  //   })
  // }

  render() {
    let annualCalc = ((config.urb_tfh_unitrate * this.props.quoteData.electricityUsage)
    + (config.urb_tfh_standing)
    + (config.urb_tfh_pso)
    ).toFixed(0);
    return (
      <MDBTable>
        <MDBTableBody>
          <tr>
            <td>Unit rate {(config.TFH).toFixed(3)} (c per kWh) *{" "}
              {this.props.quoteData.electricityUsage} kWh </td>
            <td>€{(config.urb_tfh_unitrate * this.props.quoteData.electricityUsage).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Standing charge {(config.urbanStandingChargeCentPerDay).toFixed(2)} (c per day) * 365 days </td>
            <td>€{(config.urb_tfh_standing.toFixed(2))}</td>
          </tr>
          <tr>
            <td>PSO levy {(config.monthlyPsoLevy).toFixed(2)} (€ per month) * 12 months </td>
            <td>€{(config.urb_tfh_pso).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Projected annual cost </td>
            {/* <td>€{parseInt(this.props.quoteData.monthlyPayment * 12).toFixed(2)}</td> */}
            <td>€{annualCalc}</td>
          </tr>
          <tr>
            <td>Projected monthly cost </td>
            <td>€{parseInt(this.props.quoteData.monthlyPayment).toFixed(0)}</td>
          </tr>
        </MDBTableBody>
      </MDBTable>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    quoteData: store.quote.quoteData,
  };
};

const actionCreators = {
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(Urban24hrPopup);
