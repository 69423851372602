import axios from "axios";
import { Debugger } from "fusioncharts";
import { VendDetailsForm } from "../../pages/CustomerAccount/KeypadVend/VendDetailsForm";
import { userToken } from "../helpers";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const keypadService = {
  getAllService,
  keypadVendIntentService,
};

// Get All
function getAllService() {
  let user = localStorage.getItem("user");
  if (user) {
    let parseUser = JSON.parse(user);
    let userId = parseUser.data.authenticateModel.id;
    let userToken = parseUser.data.authenticateModel.token;

    return axios({
      method: "get",
      url: `${config.apiUrl}/keypadvend/` + userId,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    }).then((response) => {
      return response;
    });
  }
}

function keypadVendIntentService(details) {
  let user = localStorage.getItem("user");

  if (user) {
    let parseUser = JSON.parse(user);
    let userId = parseUser.data.authenticateModel.id;
    let userToken = parseUser.data.authenticateModel.token;

    return axios({
      method: "post",
      url: `${config.apiUrl}/keypadvend/vendintent/`,
      data: details,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    }).then((response) => {
      return response;
    });
  }
}
