import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBAnimation } from "mdbreact";

class CRUMessage extends React.Component {
    render() {
        return (
            <div id="cru">
                    <MDBContainer>
                    <MDBRow className="text-center pt-3">
                        <MDBCol sm="12" md="12" lg="12">
                            <p className="smallFont">* Subject to verification by the CRU under the CRU’s Green Source Product Verification (GSPV) process</p>
                        </MDBCol>
                    </MDBRow>
                    </MDBContainer>
            </div>
        )
    };
}

export default CRUMessage;