import React, { Component } from "react";

import {
  MDBTabPane,
  MDBTabContent,
  MDBNavLink,
  MDBNavItem,
  MDBNav,
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBContainer
} from "mdbreact";

const LoadConfig = require("../../config");
const config = LoadConfig.default;


class NITariffTabs extends Component {
  state = {
    activeItem: "1",
    activeItemName: "24h",
    jurisdiction: "roi",
  };

  //   Handle tab change
  toggle = (tab, name) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
        activeItemName: name,
      });
    }
  };

  render() {
    return (
      <MDBContainer>
        <div className='contactTabs tariffTabs'>
          <MDBRow>
            <MDBCol sm='12' className='pl-0 pr-0'>
              <MDBNav className='nav-tabs mt-5'>
                <MDBNavItem>
                  <MDBNavLink
                    className={
                      this.state.activeItemName == "24h"
                        ? "activeTabPane cocogoose mobile-tab-width"
                        : "cocogoose mobile-tab-width"
                    }
                    link
                    to='#'
                    active={
                      this.state.activeItem === "1" &&
                      this.state.activeItemName === "24h"
                    }
                    onClick={this.toggle("1", "24h")}
                    role='tab'
                  >
                    Standard Credit
                </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    className={
                      this.state.activeItemName == "daynight"
                        ? "activeTabPane cocogoose mobile-tab-width mobile-tab-margin"
                        : "cocogoose mobile-tab-width mobile-tab-margin"
                    }
                    link
                    to='#'
                    active={
                      this.state.activeItem === "2" &&
                      this.state.activeItemName === "daynight"
                    }
                    onClick={this.toggle("2", "daynight")}
                    role='tab'
                  >
                    Keypad Meter
                </MDBNavLink>
                </MDBNavItem>
              </MDBNav>
            </MDBCol>
          </MDBRow>

          <MDBTabContent activeItem={this.state.activeItem}>
            <MDBTabPane tabId='1' role='tabpanel'>
              <MDBRow className='tabcontent'>
                <MDBCol md='12' className='content p-5'>
                  <MDBTable bordered className="text-center table-web">
                    <MDBTableHead><tr className='montserrat-Bold'><th className="text-left"> Standard Credit </th><th colSpan='2'>Unit Rate (pence per kWh) </th><th colSpan='2'>Standing Charge (pence per day)</th></tr></MDBTableHead><tbody>
                      <tr className='montserrat-Bold'><td>  </td><td>Excl VAT  </td><td>Incl VAT  </td><td>Excl VAT  </td><td>Incl VAT  </td></tr>
                      <tr><td className='montserrat-Bold text-left'>Bright Future 24hr </td><td>{config.sc_tfh_unit_exVat.toFixed(3)} </td><td>{config.sc_tfh_unit_incVat.toFixed(3)} </td><td>{config.sc_tfh_standing_exVat.toFixed(2)} </td><td>{config.sc_tfh_standing_incVat.toFixed(2)} </td></tr>
                      <tr><td className='montserrat-Bold text-left' colSpan='5'>Bright Future Economy 7 </td></tr>
                      <tr><td className="text-left">Day </td><td>{config.sc_eco7_unitday_exVat.toFixed(3)} </td><td>{config.sc_eco7_unitday_incVat.toFixed(3)} </td><td rowSpan='3'>{config.sc_eco7_standing_exVat.toFixed(2)} </td><td rowSpan='3'>{config.sc_eco7_standing_incVat.toFixed(2)}</td></tr>
                      <tr><td className="text-left">Night  </td><td>{config.sc_eco7_unitnight_exVat.toFixed(3)} </td><td>{config.sc_eco7_unitnight_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Heat  </td><td>{config.sc_eco7_unitheat_exVat.toFixed(3)} </td><td>{config.sc_eco7_unitheat_incVat.toFixed(3)}</td></tr>
                    </tbody>
                  </MDBTable>
                  <MDBTable bordered className="text-center table-mobile">
                    <thead><tr><th className='montserrat-Bold text-left'>Bright Future 24hr</th><th className="montserrat-Bold" >Excl VAT</th><th className="montserrat-Bold">Incl VAT</th></tr></thead><tbody>
                      <tr><td className="text-left">Unit rate (pence per kWh)</td><td>{config.sc_tfh_unit_exVat.toFixed(3)}</td><td>{config.sc_tfh_unit_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Standing charge (pence per day)</td><td>{config.sc_tfh_standing_exVat.toFixed(2)}</td><td>{config.sc_eco7_standing_incVat.toFixed(2)}</td></tr>
                      <tr><td colspan='3'>&nbsp;</td></tr>
                      <tr><td className="montserrat-Bold text-left">Bright Future Economy 7</td><td className="montserrat-Bold">Excl Vat</td><td className="montserrat-Bold">Incl VAT</td></tr>
                      <tr><td className="text-left">Unit rate day (pence per kWh)</td><td>{config.sc_eco7_unitday_exVat.toFixed(3)}</td><td>{config.sc_eco7_unitday_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Unit rate night (pence per kWh)</td><td>{config.sc_eco7_unitnight_exVat.toFixed(3)}</td><td>{config.sc_eco7_unitnight_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Unit rate heat (pence per kWh)</td><td>{config.sc_eco7_unitheat_exVat.toFixed(3)}</td><td>{config.sc_eco7_unitheat_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Standing Charge (pence per day)</td><td>{config.sc_eco7_standing_exVat.toFixed(2)}</td><td>{config.sc_eco7_standing_incVat.toFixed(2)}</td></tr>
                    </tbody>
                  </MDBTable>
                  <p className='smallFont mt-5'>
                    <p className='montserrat-Bold'>
                      What is a Security Deposit?
                  </p>
                  We want to keep our costs as low as possible so that we can keep your costs low.  To reduce the risk of bad debt we require all customers not paying in advance by direct debit or prepayment meters to pay £125 security deposit. Details of our security deposit policy can be found{" "}
                    <a
                      href={`/ni/ourpolicies#security-deposit-policy`}
                    >
                      <u> here.</u>
                    </a>{" "}
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBTabPane>
            <MDBTabPane tabId='2' role='tabpanel'>
              <MDBRow className='tabcontent'>
                <MDBCol md='12' className='content p-5'>
                  <MDBTable bordered className="text-center table-web">

                    <thead><tr className="tableizer-firstrow"><th className='montserrat-Bold text-left'> Keypad </th><th className='montserrat-Bold' colSpan='2'>Unit Rate (pence per kWh) </th><th className='montserrat-Bold' colSpan='2'>Standing Charge (pence per day) </th></tr></thead><tbody>

                      <tr><td>  </td><td className='montserrat-Bold '>Excl VAT  </td><td className='montserrat-Bold'>Incl VAT </td><td className='montserrat-Bold'>Excl VAT  </td><td className='montserrat-Bold'>Incl VAT  </td></tr>
                      <tr><td className='montserrat-Bold text-left'>Bright Future 24hr </td><td>{config.kp_tfh_unit_exVat.toFixed(3)} </td><td>{config.kp_tfh_unit_incVat.toFixed(3)} </td><td>{config.kp_tfh_standing_exVat.toFixed(2)} </td><td>{config.kp_tfh_standing_incVat.toFixed(2)} </td></tr>
                      <tr><td className='montserrat-Bold text-left' colSpan='5'>Bright Future Economy 7 </td></tr>
                      <tr><td className="text-left">Day </td><td>{config.kp_eco7_unitday_exVat.toFixed(3)} </td><td>{config.kp_eco7_unitday_incVat.toFixed(3)} </td><td rowSpan='3'>{config.kp_eco7_standing_exVat.toFixed(2)} </td><td rowSpan='3'>{config.kp_eco7_standing_incVat.toFixed(2)}</td></tr>
                      <tr><td className="text-left">Night </td><td>{config.kp_eco7_unitnight_exVat.toFixed(3)} </td><td>{config.kp_eco7_unitnight_incVat.toFixed(3)} </td></tr>
                      <tr><td className="text-left">Heat  </td><td>{config.kp_eco7_unitheat_exVat.toFixed(3)}  </td><td>{config.kp_eco7_unitheat_incVat.toFixed(3)}</td></tr>
                      <tr><td className='montserrat-Bold text-left' colSpan='5'>Bright Future Powershift </td></tr>
                      <tr><td className="text-left">Rate 1 </td><td>{config.kp_powershift_unit1_exVat.toFixed(3)}</td><td>{config.kp_powershift_unit1_incVat.toFixed(3)} </td><td rowSpan='4'>{config.kp_powershift_standing_exVat.toFixed(2)} </td><td rowSpan='4'>{config.kp_powershift_standing_incVat.toFixed(2)} </td></tr>
                      <tr><td className="text-left">Rate 2 </td><td>{config.kp_powershift_unit2_exVat.toFixed(3)}</td><td>{config.kp_powershift_unit2_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Rate 3 </td><td>{config.kp_powershift_unit3_exVat.toFixed(3)} </td><td>{config.kp_powershift_unit3_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Rate 4 </td><td>{config.kp_powershift_unit4_exVat.toFixed(3)}</td><td>{config.kp_powershift_unit4_incVat.toFixed(3)} </td></tr>
                    </tbody></MDBTable>
                  <MDBTable bordered className="text-center table-mobile">
                    <thead><tr class="tableizer-firstrow"><th className='montserrat-Bold text-left'>Bright Future 24hr</th><th className='montserrat-Bold'>Excl VAT</th><th className='montserrat-Bold'>Incl VAT</th></tr></thead><tbody>
                      <tr><td className="text-left">Unit Rate (pence per kWh)</td><td>{config.kp_tfh_unit_exVat.toFixed(3)}</td><td>{config.kp_tfh_unit_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Standing charge (pence per day)</td><td>{config.kp_tfh_standing_exVat.toFixed(2)}</td><td>{config.kp_tfh_standing_incVat.toFixed(2)}</td></tr>
                      <tr></tr>
                      <tr><td className='montserrat-Bold text-left' colspan="3">Bright Future Economy 7</td></tr>
                      <tr><td className="text-left">Day</td><td>{config.kp_eco7_unitday_exVat.toFixed(3)}</td><td>{config.kp_eco7_unitday_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Night</td><td>{config.kp_eco7_unitnight_exVat.toFixed(3)}</td><td>{config.kp_eco7_unitnight_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Heat</td><td>{config.kp_eco7_unitheat_exVat.toFixed(3)}</td><td>{config.kp_eco7_unitheat_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Standing charge (pence per day)</td><td>{config.kp_eco7_standing_exVat.toFixed(2)}</td><td>{config.kp_eco7_standing_incVat.toFixed(2)}</td></tr>
                      <tr><td className='montserrat-Bold text-left' colspan="3">Bright Future Powershift</td></tr>
                      <tr><td className="text-left">Rate 1</td><td>{config.kp_powershift_unit1_exVat.toFixed(3)}</td><td>{config.kp_powershift_unit1_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Rate 2</td><td>{config.kp_powershift_unit2_exVat.toFixed(3)}</td><td>{config.kp_powershift_unit2_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Rate 3</td><td>{config.kp_powershift_unit3_exVat.toFixed(3)}</td><td>{config.kp_powershift_unit3_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Rate 4</td><td>{config.kp_powershift_unit4_exVat.toFixed(3)}</td><td>{config.kp_powershift_unit4_incVat.toFixed(3)}</td></tr>
                      <tr><td className="text-left">Standing charge (pence per day)</td><td>{config.kp_powershift_standing_exVat.toFixed(2)} </td><td>{config.kp_powershift_standing_incVat.toFixed(2)}</td></tr>
                      
                    </tbody>


                  </MDBTable>


                 
                </MDBCol>
              </MDBRow>
            </MDBTabPane>
          </MDBTabContent>
        </div>
      </MDBContainer>
    );
  }
}
export default NITariffTabs;
