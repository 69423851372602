import axios from "axios";
import { userToken } from "../helpers/userToken";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const faqService = {
  getFAQsService,
  getFAQByIdService,
  createFAQService,
  updateFAQService,
  deleteFAQService
};

// Get All
function getFAQsService(juris) {
  return axios({
    method: "get",
    url: `${config.apiUrl}/faqs/jurisdiction/` + juris ,
    headers: userToken()
  }).then(response => {
    return response;
  });
}

// Get By ID
function getFAQByIdService(id) {
  return axios({
    method: "get",
    url: `${config.apiUrl}/faqs/` + id,
    headers: userToken()
  }).then(response => {
    return response;
  });
}

// Update
function createFAQService(formData) {
  return axios({
    method: "post",
    url: `${config.apiUrl}/faqs`,
    data: formData,
    headers: userToken()
  }).then(response => {
    return response;
  });
}

// Update
function updateFAQService(editData) {
  return axios({
    method: "put",
    url: `${config.apiUrl}/faqs/` + editData.id,
    data: editData,
    headers: userToken()
  }).then(response => {
    return response;
  });
}

// Delete
function deleteFAQService(id) {
  return axios({
    method: "delete",
    url: `${config.apiUrl}/faqs/` + id,
    headers: userToken()
  }).then(response => {
    return response;
  });
}
