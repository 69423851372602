import React from 'react';
import { MDBAnimation, MDBBtn, MDBContainer, MDBRow, MDBCol, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, a, MDBIcon, MDBInput } from 'mdbreact';
import wind4 from '../../video/wind4.mp4';

class Section1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postcode: ''
    }
  };

  onFormFill = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    return (
      <div>
        <MDBAnimation reveal type="fadeInLeft">
          <MDBRow className="planetImg white-text">
            <MDBCol sm="12">
              <div class="w-100 p-5 text-center">
                <h1 class="mt-5 ml-3">Affordable, Green Energy</h1>
                <h3 class="font-weight-bold ml-3">Thats Better. Thats <span className="black brightGreen-text">Bright.</span></h3>
                <h5 class="font-weight-bold mb-3 ml-3"></h5>

                <MDBRow>
                  <MDBCol sm="3"></MDBCol>
                  
                    <MDBCol sm="6">
                    <div className="black pr-2 pl-2 rounded">
                      <MDBRow>
                        <MDBCol sm="7">
                        <MDBInput label="Your Postcode" type="text"
                            value={this.state.postcode} onChange={this.onFormFill} pattern="^[_0-9]{4,7}"
                            id="postcode" name="postcode" className="form-control white black-text" required outline background/>
                        </MDBCol>
                        <MDBCol sm="5">
                        <div className="md-form form-lg md-outline md-bg">
                          <a href="/quote" className="btn btn-rounded brightGreen black-text Ripple-parent black-text getQuoteBtn" >Get Quote</a>
                          </div>
                        </MDBCol>
                      </MDBRow>
                          
                          
                    </div>
                    </MDBCol>
                  
                  <MDBCol sm="3"></MDBCol>
                </MDBRow>


              </div>
            </MDBCol>
          </MDBRow>
          
          {/*<MDBRow>
            <MDBCol sm="12">
              <div class="header">
                <div class="overlay"></div>
                  <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                    <source src={wind4} type="video/mp4"/>
                    
                  </video>
                  <div class="container h-100">
                    <div class="d-flex h-100 text-center align-items-center">
                      <div class="w-100 text-white">
                        
                          <h1 class="display-3 font-weight-bold">Affordable, Green Energy</h1>
                          <p class="lead mb-0">Thats Better. Thats Bright.</p>
                      </div>
                    </div>
                  </div>
                </div>
            </MDBCol>
          </MDBRow>
          
          <MDBRow className="grey lighten-5 greenImg">
            <MDBCol sm="12">
                <div class="w-100 pl-5 pr-5">
                    <h1 class="display-3 font-weight-bold mt-5 text-center">AFFORDABLE, GREEN ENERGY</h1>
                </div>
                <div className="setHeight"></div>
                <div className="text-center">
                    <h1 class="font-weight-bold ml-3">Thats Better. Thats <span className="black brightGreen-text pl-2 pr-2">Bright.</span></h1>
                </div>
            </MDBCol>
          </MDBRow>*/}

        </MDBAnimation>
      </div>

    );
  }
}

export default Section1;