import React, { useState, useEffect } from "react";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBAlert,
} from "mdbreact";

// Redux

import { AddNewProperty } from "../../../../components/Forms/Agency/AddNewProperty"
import { TenantMoveIn } from "../../../../components/Forms/Agency/TenantMoveIn";
import { TenantMoveOut } from "../../../../components/Forms/Agency/TenantMoveOut";

export const AgencyNewTenant = () => {
    const [jurisdiction, setJurisdiction] = useState();
    const [addNew, setAddNew] = useState(false);
    const [moveOut, setMoveOut] = useState(false);
    const [moveIn, setMoveIn] = useState(false);


    useEffect(() => {
        let pathArray = window.location.pathname.split("/");
        let jurisdiction = pathArray[1];
        setJurisdiction(jurisdiction);
    }, []);

    return (
        <div className="account-dashboard-container ">
            {/* API Loader */}
            {/* {loading ? <Loader /> : null} */}
            <div className="account-details-container">
                <div className="make-payment-background">
                    <MDBContainer className="main-content payment-history">
                        <MDBRow>
                            <MDBCol size="1" className="align-self-center">
                                <a href={`/${jurisdiction}/account/landlorddashboard`}>
                                    <i className="fas fa-chevron-left fa-2x" />
                                </a>
                            </MDBCol>
                            <MDBCol size="9" className="text-center pt-5" center>
                                <h3>Tenancy Form</h3>
                            </MDBCol>
                            <MDBCol size="2">
                                <img
                                    src={require("../../../../images/global/bright-logo-dark.png")}
                                    className="agency-logo mx-5 mt-4"
                                />
                            </MDBCol>
                        </MDBRow>

                        <div className="white-curved-tile text-center">
                            <p>Please Select Action :</p>
                            <MDBCol size="4" className="align-self-center" style={{ display: 'inline-grid', flexDirection: 'row' }}>
                                <MDBAlert className="grey-highlight brightTeal-text">
                                    <input
                                        type="radio"
                                        id="addNew"
                                        className="paymentRadio"
                                        onClick={() => { setAddNew(true); setMoveIn(false); setMoveOut(false); }}
                                        checked={addNew ? true : false}
                                    />
                                    Add New Property
                                </MDBAlert>
                            </MDBCol>

                            <MDBCol size="4" className="align-self-center" style={{ display: 'inline-grid', flexDirection: 'row' }}>
                                <MDBAlert className="grey-highlight brightTeal-text">
                                    <input
                                        type="radio"
                                        id="moveIn"
                                        className="paymentRadio"
                                        onClick={() => { setMoveIn(true); setMoveOut(false); setAddNew(false); }}
                                        checked={moveIn ? true : false}
                                    />
                                    Tenant Moving In
                                </MDBAlert>
                            </MDBCol>

                            <MDBCol size="4" className="align-self-center" style={{ display: 'inline-grid', flexDirection: 'row' }}>
                                <MDBAlert className="grey-highlight brightTeal-text">
                                    <input
                                        type="radio"
                                        id="moveOut"
                                        className="paymentRadio"
                                        onClick={() => { setMoveIn(false); setMoveOut(true); setAddNew(false); }}
                                        checked={moveOut ? true : false}
                                    />
                                    Tenant Moving Out
                                </MDBAlert>
                            </MDBCol>
                        </div>
                        {addNew ? (
                            <div className="text-center">
                                <h4>Add New Property</h4>
                                <div className='white-curved-tile text-left'>
                                    <AddNewProperty />
                                </div>
                            </div>
                        ) : null}

                        {/* Tenant Moving Out */}
                        {moveOut ? (
                            <div className="text-center">
                                <h4>Tenant Moving Out</h4>
                                <div className='white-curved-tile text-left'>
                                    <TenantMoveOut />
                                </div>
                            </div>
                        ) : null}

                        {/* Tenant Moving Out */}
                        {moveIn ? (
                            <div className="text-center">
                                <h4>Tenant Moving In</h4>
                                <div className='white-curved-tile text-left'>
                                    <TenantMoveIn />
                                </div>
                            </div>
                        ) : null}
                    </MDBContainer>
                </div>
            </div>
        </div>
    )
};
