import React from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";

export const PaymentSuccess = () => {
  return (
    <div>
      <MDBRow className='text-center'>
        <MDBCol>
          <img
            src={require("../../../../images/account/success.png")}
            style={{ width: "30%" }}
          />

          <p className='my-5'>Your payment has been successful.</p>
        </MDBCol>
      </MDBRow>

      <MDBRow center>
        <MDBCol size='6'>
          <MDBBtn
            className='btn'
            onClick={() => {
              window.location.reload();
            }}
          >
            Continue
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </div>
  );
};
