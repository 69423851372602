import { customerAccountConstants } from "../constants";
import { customerAccountService } from "../services";

export const customerAccountActions = {
  getBills,
  getBillById,
};

// Get customer bills
function getBills() {
  return dispatch => {

    customerAccountService.getBillsService().then(
      (response) => {
        dispatch(getBillsSuccess(response.data));
      },
      (error) => {
        dispatch(getBillsFailure([error.response.data.message]));
      }
    );
  }

  function getBillsRequest(dbData) {
    return {
      type: customerAccountConstants.GET_BILLS_REQUEST,
      payload: dbData,
    };
  }

  function getBillsSuccess(dbData) {
    return {
      type: customerAccountConstants.GET_BILLS_SUCCESS,
      payload: dbData,
    };
  }

  function getBillsFailure(error) {
    return {
      type: customerAccountConstants.GET_BILLS_FAILURE,
      payload: error,
    };
  }
}


//getBillbyId - (bill id)
function getBillById(id, jurisdiction) {
  return dispatch => {

    dispatch(getBillByIdRequest());

    customerAccountService.getBillByIdService(id, jurisdiction).then(
      (response) => {

        const byteCharacters = atob(response.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        var res = new Blob([byteArray], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(res);
        window.location.assign(fileURL, '_blank');

        dispatch(getBillByIdSuccess(byteArray));

      },
      (error) => {
        dispatch(getBillByIdFailure([error.response.data.message]));
      }
    );
  }

  function getBillByIdRequest() {
    return {
      type: customerAccountConstants.GET_BILLBYID_REQUEST,
    };
  }

  function getBillByIdSuccess(dbData) {
    return {
      type: customerAccountConstants.GET_BILLBYID_SUCCESS,
      payload: dbData,
    };
  }

  function getBillByIdFailure(error) {
    return {
      type: customerAccountConstants.GET_BILLBYID_FAILURE,
      payload: error,
    };
  }
}
