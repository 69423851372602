import { quoteConstants } from "../constants";
import { quoteService } from "../services";

export const quoteActions = {
  createQuoteJourney,
  updateQuoteJourney,
  getQuoteJourney,
  saveQuoteJourney,
};

// Create initial journey
function createQuoteJourney(details, pageStatus, nextPage, jurisdiction) {
  return (dispatch) => {
    // Request
    dispatch(createQuoteJourneyRequest(details));

    quoteService.createQuote(details).then(
      (response) => {
        dispatch(createQuoteJourneySuccess(response.data, details));
        if (response.status == 200) {
          // Update section status
          localStorage.setItem(pageStatus, true);

          // Redirect To Next Page
          window.location.href = "/" + jurisdiction + "/quote/" + nextPage;

          // Store Quote ID
          localStorage.setItem("quoteID", response.data);
        }
      },
      (error) => {
        dispatch(createQuoteJourneyFailure([error.response.data.message]));
      }
    );
  };

  function createQuoteJourneyRequest(dbData) {
    return {
      type: quoteConstants.CREATE_QUOTE_REQUEST,
      payload: dbData,
    };
  }

  function createQuoteJourneySuccess(data, values) {
    return {
      type: quoteConstants.CREATE_QUOTE_SUCCESS,
      payload: { journeyID: data, values: values },
    };
  }

  function createQuoteJourneyFailure(error) {
    return {
      type: quoteConstants.CREATE_QUOTE_FAILURE,
      payload: error,
    };
  }
}

// Update Quote Journey
function updateQuoteJourney(id, values, pageStatus, nextPage, jurisdiction) {
  return (dispatch) => {
    // Request
    dispatch(updateQuoteJourneyRequest(id, values));

    quoteService.updateQuote(id, values).then(
      (response) => {
        dispatch(updateQuoteJourneySuccess(values));
        if (response.status == 200) {
          // Update section status
          localStorage.setItem(pageStatus, true);

          // Redirect To Next Page
          window.location.href = "/" + jurisdiction + "/quote/" + nextPage;
        }
      },
      (error) => {
        dispatch(updateQuoteJourneyFailure(error));
      }
    );
  };

  function updateQuoteJourneyRequest(dbData) {
    return {
      type: quoteConstants.UPDATE_QUOTEJOURNEY_REQUEST,
      payload: dbData,
    };
  }

  function updateQuoteJourneySuccess(values) {
    return {
      type: quoteConstants.UPDATE_QUOTEJOURNEY_SUCCESS,
      payload: values,
    };
  }

  function updateQuoteJourneyFailure(error) {
    return {
      type: quoteConstants.UPDATE_QUOTEJOURNEY_FAILURE,
      payload: error,
    };
  }
}

// Get Quote Journey
function getQuoteJourney(id) {
  return (dispatch) => {
    // Request
    dispatch(getQuoteJourneyRequest());

    // dispatch(success(details));
    quoteService.getQuote(id).then(
      (response) => {
        dispatch(getQuoteJourneySuccess(response.data));
      },
      (error) => {
        dispatch(getQuoteJourneyFailure([error.response.data.message]));
      }
    );
  };
  function getQuoteJourneyRequest(dbData) {
    return {
      type: quoteConstants.GET_QUOTEJOURNEY_REQUEST,
    };
  }
  function getQuoteJourneySuccess(data) {
    return {
      type: quoteConstants.GET_QUOTEJOURNEY_SUCCESS,
      payload: data,
    };
  }
  function getQuoteJourneyFailure(error) {
    return {
      type: quoteConstants.GET_QUOTEJOURNEY_FAILURE,
      payload: error,
    };
  }
}

// Save Quote Journey
function saveQuoteJourney(jurisdiction, email, id) {
  return (dispatch) => {
    // Request
    dispatch(saveQuoteJourneyRequest(jurisdiction, email, id));

    quoteService.saveQuoteService(jurisdiction, email, id).then(
      (response) => {
        // Redirect to home
        window.location.href =
          "/" + jurisdiction + "/quote/summary?saveQuote=1";

        dispatch(saveQuoteJourneySuccess(response.data));
      },
      (error) => {
        dispatch(saveQuoteJourneyFailure(error));
      }
    );
  };

  function saveQuoteJourneyRequest(dbData) {
    return {
      type: quoteConstants.SAVE_QUOTEJOURNEY_REQUEST,
      payload: dbData,
    };
  }

  function saveQuoteJourneySuccess(dbData) {
    return {
      type: quoteConstants.SAVE_QUOTEJOURNEY_SUCCESS,
      payload: dbData,
    };
  }

  function saveQuoteJourneyFailure(error) {
    return {
      type: quoteConstants.SAVE_QUOTEJOURNEY_FAILURE,
      payload: error,
    };
  }
}
