import { contactService } from "../services";
import { contactConstants } from "../constants";
import { history } from "../helpers";

export const contactActions = {
  sendMail,
  
};

// Login
function sendMail(details, jurisdiction) {
  return (dispatch) => {
    // Request
    dispatch(request(details));

    contactService.sendMail(details, jurisdiction).then(
      (response) => {
        dispatch(success(response.data));
        alert("Thanks for contacting bright. We'll be in touch");
        var url = "/" + jurisdiction + "/about/contactus?success=true";
        window.location.href = url;
      },
      (error) => {
        dispatch(failure([error.response.data.message]));
      }
    );
  };

  function request(dbData) {
    return {
      type: contactConstants.SENDMAIL_REQUEST,
      payload: dbData,
    };
  }

  function success(dbData) {
    return {
      type: contactConstants.SENDMAIL_SUCCESS,
      payload: "Your message has been sent successfully.",
    };
  }

  function failure(error) {
    return {
      type: contactConstants.SENDMAIL_FAILURE,
      payload: error,
    };
  }
}
