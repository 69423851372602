import React from "react";
import { MDBBtn } from "mdbreact";
import { loadStripe } from "@stripe/stripe-js";

// Redux
import { connect } from "react-redux";
import { paymentActions } from "../../../redux/actions";

const LoadConfig = require("../../../config");
const config = LoadConfig.default;

class DirectDebit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sesID: "",
      nextPaymentAmount: 0,
      nextPaymentDate: "",
      jurisdiction: "",
      currency: "",
    };
  }

  componentDidMount() {
    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    this.setState({
      jurisdiction: jurisdiction,
    });

    if (jurisdiction == "roi") {
      this.setState({ currency: "€" });
    } else {
      this.setState({ currency: "£" });
    }

    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;
    let billList = jModel.billsList;

    if (jModel != null) {
      // Payment amount
      let nextPaymentAmount = parseInt(jModel.nextPaymentAmount).toFixed(2);
      let nextPaymentDate = jModel.nextPaymentDate
        .toString()
        .slice(0, 10)
        .split("-")
        .reverse()
        .join("/");

      if (nextPaymentAmount == 0 && billList.length > 0)
      {
        nextPaymentAmount = jModel.balance;
        nextPaymentDate = jModel.billsList[0].dueDt
          .toString()
          .slice(0, 10)
          .split("-")
          .reverse()
          .join("/");
      }

      // Next payment date
      

      this.setState({
        nextPaymentDate: nextPaymentDate,
        nextPaymentAmount: nextPaymentAmount,
      });
    }
  }

  stripePayment = () => {
    this.startStripePayment();
  };

  async startStripePayment() {
    await this.createCheckout();
    await this.stripeobj();
  }

  delay = (duration) => new Promise((resolve) => setTimeout(resolve, duration));

  async createCheckout() {
    const details = {
      //customer bill amount passed to page
      amount: parseInt(this.props.paymentAmount * 100),
      itemName: "Keypad topup",
      currency: "GBP",
    };

    this.props.createPaymentSession(details);
    await this.delay(2000);
    console.log("created payment session");
  }

  async stripeobj() {
    var key = (this.state.jurisdiction == "roi" ? config.stripekey : config.niStripekey);
    const stripeObj = await loadStripe(key);
    const { error } = await stripeObj.redirectToCheckout({
      sessionId: this.props.sessionId,
    });
    await this.delay(2000);
    console.log("created stripe obj");
  }

  onToken() {
    const details = {
      amount: parseInt(100),
      itemName: "Keypad topup",
      currency: "GBP",
    };

    try {
      const resp = this.props.createPaymentSession(details).then(
        (success) => {
          return this.props.sessionID;
        },
        (error) => {
          return "Error";
        }
      );
      //return this.props.sessionID;
    } catch (err) {
      console.log(err.toString());
    }
  }

  render() {
    return (
      <div>
        {(this.state.nextPaymentAmount > 0 ) ? (
        <div className='white-curved-tile text-center'>
          <h2>Your Payment</h2>
          <h2 className='price-figure'>
            {this.state.currency}
            {this.state.nextPaymentAmount}
          </h2>
          <p>
            Next Payment Due: <b>{this.state.nextPaymentDate}</b>
          </p>
          {/* <MDBBtn className='btn' onClick={() => this.stripePayment()}>
            Make Payment
          </MDBBtn> */}
        </div>
         ) : (
        <div className='white-curved-tile text-center'>
              <h4>
                Your account payments are up to date.
                <br />
                Check back soon to see when your next payment is due.
              </h4>
            </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    paymentAmount: store.payment.paymentAmount,
    paymentSession: store.payment.paymentData,
    sessionId: store.payment.sessionID,
  };
};

const actionCreators = {
  increment: paymentActions.increment,
  decrement: paymentActions.decrement,
  createPaymentSession: paymentActions.createPaymentSession,
};

export default connect(mapStateToProps, actionCreators)(DirectDebit);
