import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow, } from "mdbreact";
import TariffTabs from "../../components/About/TariffTabs";
import SwitchOnline from "../../components/Shared/SwitchOnline";
import EAB from "../../components/Shared/EAB";


class OurTariff extends Component {
  render() {
    return (
      <div className='default-page home-page'>
        {/* Hero with background device */}
        <MDBRow className='small-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='10' lg='10'>
                <h1>Our bright future tariff</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBContainer className='energy-section'>
          <MDBRow className='large-padding'>
            <MDBCol sm='12' md='12' lg='12' className='content'>
              <p>
                In a world of confusing tariff options, we bring simplicity with
                our Bright Future Tariff, that is fair for all. Bright customers
                know they are always getting our best price as we work hard to
                pass on the true cost of electricity to our customers with our
                one variable tariff. We increase the tariff slowly when
                electricity prices are on the rise and decrease them quickly to
                pass on savings when electricity prices fall. We will always
                keep you informed when the unit price is going to change. We
                reward customer loyalty by making sure our customers are paying
                the same price per unit of electricity meaning bright really can
                be your last switch!
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        <div className='grey-background tariff-table-container'>
          <TariffTabs />
        </div>

        <div className='white-background'>
          <SwitchOnline />
        </div>

        <EAB/>
      </div>
    );
  }
}

export default OurTariff;
