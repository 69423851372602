import axios from "axios";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const locationService = {
    getLocationJurisService
};

function getLocationJurisService() {
    return axios({
        method: "get",
        url: `${config.jurisChecker}`,
      }).then(response => {
        return response;
      });
  }