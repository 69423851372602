import React, { Component } from "react";
import { MDBCol, MDBRow, MDBBtn, MDBAlert } from "mdbreact";

export class JurisdictionRedirect extends Component {
  state = {
    redirectUrl:"",
    jurisdiction:"",
  };

  // Page load
  componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search);
    let redirectUrl = urlParams.get("url");
    this.setState({
      redirectUrl: redirectUrl,
    });
  }

  // Handle continue
  redirect = (jurisdiction) => {
      let url = this.state.redirectUrl.replace("{jur}", jurisdiction);
      window.location.href= url;
  };

  render() {
    return (
      <div className='quote-page'>

        {/* Background Image */}
        <div className='device-image' />
        <form id='jurisdictionDetails'>
          <MDBCol className='text-center'>
            {/* Logo */}
            <MDBRow>
              <MDBCol lg='2' sm='12'>
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <img
                    src={require("../images/global/bright-logo-dark.png")}
                    className='quote-logo mx-5 mt-5'
                  />
                </a>
              </MDBCol>
              <MDBCol size='10' />
            </MDBRow>

            {/* Error Message */}
            <MDBRow className='text-center mt-5'>
              <MDBCol size='4' />
              <MDBCol lg='4' md='4' sm='12'>
                {this.state.errorMessage == true ? (
                  <MDBAlert color='danger'>
                    <strong>Oops!</strong> Please check your fields below.
                  </MDBAlert>
                ) : null}
              </MDBCol>
              <MDBCol size='4' />
            </MDBRow>

            {/* Supply region */}
            <MDBRow className='mb-5'>
              <MDBCol size='3' />
              <MDBCol lg='6' md='6' sm='10' className='text-center'>
                {/* Question */}
                <h4 className='mb-4'>
                  Please select your jurisdiction
                </h4>
                {/* Urban */}
                <input
                  onClick={() => this.redirect("roi")}
                  className='form-check-input'
                  id='roi'
                  name='niroi'
                  required
                  type='radio'
                />
                <label for='roi' className='radioLabel'>
                  ROI
                </label>

                {/* Rural */}
                <input
                  onClick={() => this.redirect("ni")}
                  className='form-check-input'
                  id='ni'
                  name='niroi'
                  type='radio'
                />
                <label for='ni' className='radioLabel'>
                  NI
                </label>
              </MDBCol>
              <MDBCol size='3' />
            </MDBRow>

          </MDBCol>
        </form>
      </div>
    );
  }
}

export default JurisdictionRedirect;
