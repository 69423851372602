import React, { Component } from "react";
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBTable,
  MDBTableBody,
  MDBBtn,
  MDBAlert,
} from "mdbreact";
import $ from "jquery";
import Loader from "../../../Shared/Loader";
import { isBrowser, isMobile } from "react-device-detect";

// Redux
import { connect } from "react-redux";
import { switchActions } from "../../../../redux/actions/switchActions";
import { quoteActions } from "../../../../redux/actions/quoteActions";

// Tariff tables
import Keypad24hr from "./TariffTables/Keypad/Keypad24hr";
import KeypadEco7 from "./TariffTables/Keypad/KeypadEco7";
import KeypadPowershift from "./TariffTables/Keypad/KeypadPowershift";
import Standard24hr from "./TariffTables/StandardCredit/Standard24hr";
import StandardEco7 from "./TariffTables/StandardCredit/StandardEco7";

const LoadConfig = require("../../../../config");
const config = LoadConfig.default;

export class SwitchSummary extends Component {
  state = {
    confirmDDAuth: "",
    agreeToTerms: "",
    agreeToPrivacyPolicy: true,
    paymentIntent: "",

    // Direct debit tariff tables
    tariffName: "bright future",
    unitRate: config.sc_tfh_unit_incVat.toFixed(3),
    standingCharge: config.sc_tfh_standing_incVat.toFixed(2),
    collectionDate: "15 working days",

    errorMessage: false,
    switchGot: false,
    jurisdiction: "ni",
    homeCheck: false,
    hideBack: false,
  };

  // Page Load
  componentDidMount() {
    let switchID = localStorage.getItem("switchID");
    if (switchID != null) {
      this.props.getSwitch(switchID);
      this.setState({ switchGot: true });
    }

    try {
      let paymentIntent = localStorage.getItem("paymentIntent");
      if (paymentIntent != null) {
        this.setState({ paymentIntent: paymentIntent, hideBack: true });
      }
    } catch {}
  }

  async componentWillReceiveProps(nextProps) {
    let keypad = nextProps.journeyData.keypad;
    let meterType = nextProps.journeyData.meterType;
    let ecoMeterType = nextProps.journeyData.ecoMeterType;
    let paymentMethod = nextProps.journeyData.paymentMethod;
    let ddAmount = nextProps.journeyData.ddAmount;
    this.setState({
      keypad: keypad,
      meterType: meterType,
      ecoMeterType: ecoMeterType,
      paymentMethod: paymentMethod,
      ddAmount: ddAmount,
    });

    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle tariff table selection
  handleTariffTable = (keypad, meterType, ecoMeterType) => {
    // Create case for switch
    let keypadString = "";
    if (keypad) {
      keypadString = "Keypad";
    } else {
      keypadString = "Credit";
    }

    let tariffTable = keypadString + " " + meterType + " " + ecoMeterType;

    console.log(tariffTable);

    switch (tariffTable) {
      // Keypad meters
      case "Keypad 24hr ":
        return <Keypad24hr />;
        break;
      case "Keypad Eco7 Economy7DayNight":
        return <KeypadEco7 />;
        break;
      case "Keypad Eco7 Economy7DayNightHeat":
        return <KeypadEco7 />;
        break;
      case "Keypad Eco7 Powershift":
        return <KeypadPowershift />;
        break;
      // Credit meters
      case "Credit 24hr ":
        return <Standard24hr />;
        break;
      case "Credit Eco7 Economy7DayNight":
        return <StandardEco7 />;
        break;
      case "Credit Eco7 Economy7DayNightHeat":
        return <StandardEco7 />;
        break;
      case "Credit Eco7 Economy7DayNightHeat":
        return <StandardEco7 />;
        break;
      // Default
      default:
        break;
    }
  };

  // Continue to next section
  nextStep = (e) => {
    // Prevent from from clearing
    e.preventDefault();

    // Validate Form
    let validation = $("#switchSummary")[0].checkValidity();

    if (validation) {
      // Form Data
      const data = {
        // Step one - (Account details)
        accountHolder: this.props.journeyData.accountHolder,
        title: this.props.journeyData.title,
        firstName: this.props.journeyData.firstName,
        surname: this.props.journeyData.surname,
        dob: new Date(this.props.journeyData.dob).toISOString().slice(0, 19),
        email: this.props.journeyData.email,
        phone: this.props.journeyData.phone,
        postcode: this.props.journeyData.postcode,
        addrLine1: this.props.journeyData.addrLine1,
        addrLine2: this.props.journeyData.addrLine2,
        city: this.props.journeyData.city,
        county: this.props.journeyData.county,
        mprn: this.props.journeyData.mprn,
        // Account correspondance
        accountCorrespondence: this.props.journeyData.accountCorrespondence,
        accCorrTitle: this.props.journeyData.accCorrTitle,
        accCorrFirstName: this.props.journeyData.accCorrFirstName,
        accCorrSurname: this.props.journeyData.accCorrSurname,
        accCorrName: this.props.journeyData.accCorrName,
        accCorrPostcode: this.props.journeyData.accCorrPostcode,
        accCorrAddLineOne: this.props.journeyData.accCorrAddLineOne,
        accCorrAddLineTwo: this.props.journeyData.accCorrAddLineTwo,
        accCorrCity: this.props.journeyData.accCorrCity,
        accCorrCounty: this.props.journeyData.accCorrCounty,
        accCorrEmail: this.props.journeyData.accCorrEmail,
        accCorrAddPhone: this.props.journeyData.accCorrAddPhone,
        // Joint account
        jointPerson: this.props.journeyData.jointPerson,
        jointTitle: this.props.journeyData.jointTitle,
        jointFirstName: this.props.journeyData.jointFirstName,
        jointSurname: this.props.journeyData.jointSurname,
        jointEmail: this.props.journeyData.jointEmail,
        jointPhone: this.props.journeyData.jointPhone,
        // Speical services
        serviceCheck: this.props.journeyData.serviceCheck,
        specialServices: this.props.journeyData.specialServices,
        requiredSpecialServices: this.props.journeyData.requiredSpecialServices,
        medicalServices: this.props.journeyData.medicalServices,
        requiredMedicalServices: this.props.journeyData.requiredMedicalServices,
        priorityRegister: this.props.journeyData.priorityRegister,
        //Nom person
        nomPerson: this.props.journeyData.nomPerson,
        nomCorrespondance: this.props.journeyData.nomCorrespondance,
        nomTitle: this.props.journeyData.nomTitle,
        nomFirstName: this.props.journeyData.nomFirstName,
        nomSurname: this.props.journeyData.nomSurname,
        nomEmail: this.props.journeyData.nomEmail,
        nomPhone: this.props.journeyData.nomPhone,
        nomPostcode: this.props.journeyData.nomPostcode,
        nomAddrLine1: this.props.journeyData.nomAddrLine1,
        nomAddrLine2: this.props.journeyData.nomAddrLine2,
        nomCity: this.props.journeyData.nomCity,
        nomCounty: this.props.journeyData.nomCounty,
        // Marketing
        markEmail: this.props.journeyData.markEmail,
        markSMS: this.props.journeyData.markSMS,
        markPost: this.props.journeyData.markPost,
        markPhone: this.props.journeyData.markPost,

        // Step two - (Your electricity)
        newConnection: this.props.journeyData.newConnection,
        meterReading: this.props.journeyData.meterReading,
        nightMeterReading: this.props.journeyData.nightMeterReading,
        annualUsage: this.props.journeyData.annualUsage,
        consumptionOption: this.props.journeyData.electricityUsageSelect,

        // Step three - Payment details
        paymentMethod: this.props.journeyData.paymentMethod,
        accountName: this.props.journeyData.accountName,
        bankAccountNo: this.props.journeyData.bankAccountNo,
        bankSortCode: this.props.journeyData.bankSortCode,
        billingMethod: this.props.journeyData.billingMethod,
        iban: this.props.journeyData.iban,
        DDAmount: this.props.journeyData.DDAmount,
        DDDayOfMonth: this.props.journeyData.DDDayOfMonth,
        bankAddressLineOne: this.props.journeyData.bankAddressLineOne,
        bankAddressLineTwo: this.props.journeyData.bankAddressLineTwo,
        bankAddressPostcode: this.props.journeyData.bankAddressPostcode,
        bankAddressCountry: this.props.journeyData.bankAddressCountry,
        confirmDDAuth: this.props.journeyData.confirmDDAuth,

        // Step four - Switch summary
        agreeToTerms: this.state.agreeToTerms,
        agreeToPrivacyPolicy: this.state.agreeToPrivacyPolicy,
        paymentIntentId: this.state.paymentIntent,
        switchCompleted: true,
      };

      // Hide Error Message (if visiable)
      this.setState({
        errorMessage: false,
      });

      // SwitchID
      let switchID = localStorage.getItem("switchID");

      // This Page (updates page status)
      let pageStatus = "summaryUpdated";

      // Next Page URL
      let nextPage = "confirmation";

      // Payment method - needs passed to take deposit for vdd
      let paymentMethod = "";

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Database Entry
      this.props.updateSwitch(
        switchID,
        data,
        pageStatus,
        nextPage,
        paymentMethod,
        jurisdiction
      );
    } else {
      // For each form input that has the required prop add the input-error class
      $("form#switchSummary")
        .find("input")
        .each(function () {
          if ($(this).prop("required")) {
            $(this).addClass("input-error");
          }
        });

      // Display the error message
      this.setState({
        errorMessage: true,
      });

      // Scroll to error
      window.location.href = "#errorMessage";
    }
  };

  render() {
    return (
      <div className="quote-page switch-page">
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        <form id="switchSummary">
          {/* Background Image */}
          <div className="device-image" />
          <div className="quote-content-container">
            {/* Logo */}
            <MDBRow className="text-center">
              <MDBCol lg="2" md="12" sm="12">
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <img
                    src={require("../../../../images/global/bright-logo-dark.png")}
                    className="quote-logo mx-5 mt-5"
                  />
                </a>
              </MDBCol>
              <MDBCol size="10" />
            </MDBRow>

            {/* Home message */}
            {this.state.homeCheck ? (
              <div className="modalBG">
                <div className="modalBox">
                  <div className="white-curved-tile text-center">
                    <h4 className="mt-5">
                      All progress will be lost! <br />
                      Are you sure?
                    </h4>
                    <MDBRow>
                      <MDBCol lg="6" md="6" sm="12">
                        <MDBBtn
                          className="cancel-button"
                          onClick={() =>
                            this.setState({ homeCheck: !this.state.homeCheck })
                          }
                        >
                          Cancel
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol lg="6" md="6" sm="12">
                        <MDBBtn
                          onClick={() =>
                            (window.location.href = `/${this.state.jurisdiction}/`)
                          }
                        >
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            <MDBContainer className="personal-details-section">
              <MDBRow>
                {/* Error Message */}
                <MDBCol size="2" />
                <MDBCol size="8" id="errorMessage">
                  {this.state.errorMessage == true ? (
                    <MDBAlert color="danger" dismiss>
                      <strong>Oops!</strong> Please check your fields below.
                    </MDBAlert>
                  ) : null}
                </MDBCol>
                <MDBCol size="2" />
              </MDBRow>
            </MDBContainer>

            <MDBRow className="my-5">
              <MDBCol lg="2" />
              <MDBCol lg="8" md="12" sm="12">
                <div className="white-curved-tile switch-ready">
                  {/* Title */}
                  <h3 className="text-center">Your Switch Summary</h3>

                  {/* Tariff Details */}
                  <h4 className="text-center">Principal terms of contract</h4>
                  <p className="text-center">
                    Below we've set out the principal terms of our contract to
                    provide electricity to you, based on the information you
                    have provided. Please check these carefully and amend your
                    details if there are any errors. Please note that prices are
                    subject to change. If having completed the switching
                    process, you change your mind a cooling-off period of 10
                    working days applies, in which you can cancel your contract
                    by contacting us on 028 9544 2290.
                  </p>

                  {/* Contract info / DD info */}
                  {!this.state.keypad ? (
                    <MDBCol size="12">
                      <MDBTable className="mt-4">
                        <MDBTableBody>
                          <tr>
                            <td>Tariff name</td>
                            <td className="text-center">
                              {this.state.tariffName}
                            </td>
                          </tr>
                          <tr>
                            <td>Meter type</td>
                            <td className="text-center">
                              {this.state.meterType}
                            </td>
                          </tr>
                          {this.state.meterType == "24hr" ? (
                            <tr>
                              <td>Unit rate (pence per kWh)</td>
                              <td className="text-center">
                                {config.sc_tfh_unit_incVat}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td>Unit rate (pence per kWh)</td>
                              <td className="text-center">
                                Day rate - {config.sc_eco7_unitday_incVat}
                                <br />
                                Night rate - {config.sc_eco7_unitnight_incVat}
                                <br />
                                Heat rate - {config.sc_eco7_unitheat_incVat}
                              </td>
                            </tr>
                          )}
                          {this.state.meterType == "24hr" ? (
                            <tr>
                              <td>Standing charge (pence per day)</td>
                              <td className="text-center">
                                {config.sc_tfh_standing_incVat}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td>Standing charge (pence per day)</td>
                              <td className="text-center">
                                {config.sc_eco7_standing_incVat}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>Direct debit amount</td>
                            {this.state.paymentMethod == "SetDirectDebit" ? (
                              <td className="text-center">
                                £{this.state.ddAmount}
                              </td>
                            ) : (
                              <td className="text-center">Variable</td>
                            )}
                          </tr>
                          <tr>
                            <td>Type of payment</td>
                            <td className="text-center">Recurrent</td>
                          </tr>
                          <tr>
                            <td>Collection Date</td>
                            <td className="text-center">
                              {this.state.collectionDate}
                            </td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>
                    </MDBCol>
                  ) : null}

                  {/* Tariff info */}
                  <MDBCol size="12" className="mt-5">
                    <h4 className="text-center">Tariff summary</h4>
                    <p className="text-center">
                      The table below contains all available bright tariffs.
                      Please refer to the relevant tariff rates and charges for
                      your meter.
                    </p>

                    <div>
                      {this.handleTariffTable(
                        this.state.keypad,
                        this.state.meterType,
                        this.state.ecoMeterType
                      )}
                    </div>
                  </MDBCol>

                  {/* Terms & Conditions */}
                  <MDBRow className="text-center">
                    <MDBCol lg="12" md="12" sm="12">
                      <h4>Terms & Conditions</h4>
                      <label for="tcconfirmation" className="ml-2">
                        <ul className="tcList pl-0">
                          <li className="mb-2">
                            Please confirm that you have read and understood the
                            following terms:
                          </li>
                          <li className="mb-2">
                            You understand that a debt flag may be raised on
                            your account if you have an outstanding amount owing
                            to your existing supplier of more than £100 for more
                            than 42 days from the date of the bill, or 28 days
                            from the due date (whichever is earlier). In this
                            case the switch may not process until the arrears
                            are cleared with your existing supplier.
                            <br />
                            You have read and understood the:
                            <br />
                            <div>
                              <ul className="w-50 m-center">
                                <li>
                                  <a
                                    className="blue-text"
                                    href="https://brightenergy.com/static/media/eccNI.e2fce655.pdf"
                                  >
                                    energy consumer checklist
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="blue-text"
                                    href="https://brightenergy.com/static/media/energyChecklistNI.75b1f976.pdf"
                                  >
                                    bright sales checklist
                                  </a>
                                </li>
                                <li>
                                  {" "}
                                  <a
                                    href="https://brightenergy.com/ni/terms"
                                    className="blue-text"
                                  >
                                    terms and conditions
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {/* <MDBRow center>
                              <MDBCol sm="12" md="6" lg="6" xl="4">
                                <ul>
                                  <li className='mb-2 text-left'>

                                    <a
                                      className='blue-text'
                                      href='https://brightenergy.com/static/media/eccNI.e2fce655.pdf'
                                    >
                                      energy consumer checklist
                           </a>{" "}
                                  </li>
                                  <li className='mb-2 text-left'>

                                    <a
                                      className='blue-text'
                                      href='https://brightenergy.com/static/media/energyChecklistNI.75b1f976.pdf'
                                    >
                                      bright sales checklist
                           </a>
                                  </li>
                                  <li className='text-left'>

                                    <a
                                      href='https://brightenergy.com/ni/terms'
                                      className='blue-text'
                                    >
                                      terms and conditions
                           </a>
                                  </li>
                                </ul>
                              </MDBCol>
                            </MDBRow> */}
                          </li>
                        </ul>
                      </label>

                      <label className="ml-3 montserrat-Bold">
                        <input
                          id="tcconfirmation"
                          name="tcconfirmation"
                          type="checkbox"
                          onClick={() =>
                            this.setState({
                              agreeToTerms: !this.state.agreeToTerms,
                            })
                          }
                          required
                          checked={this.state.agreeToTerms ? true : false}
                        />{" "}
                        I confirm that I have read and understood the above
                        terms
                      </label>
                    </MDBCol>
                  </MDBRow>

                  {/* Buttons */}
                  <MDBRow className="text-center">
                    <p className="my-5">
                      By clicking complete below you understand that you are
                      entering into a legal and binding contract with bright for
                      the supply of electricity.
                    </p>
                    {/* Back */}
                    {isBrowser && !this.state.hideBack ? (
                      <MDBCol
                        lg="6"
                        md="12"
                        sm="12"
                        className="text-center mb-2"
                      >
                        <h5>
                          Previous Step: <span>Payment Details</span>
                        </h5>
                        <a href={`/${this.state.jurisdiction}/switch/payment`}>
                          <MDBBtn className="btn switch-button">Back</MDBBtn>
                        </a>
                      </MDBCol>
                    ) : null}

                    {/* Continue */}
                    {this.state.hideBack ? (
                      <MDBCol size="12" className="text-center">
                        <h5>
                          Next Step: <span>Switch Confirmation</span>
                        </h5>
                        <MDBBtn
                          className="btn btn-bright"
                          onClick={this.nextStep}
                        >
                          Complete
                        </MDBBtn>
                      </MDBCol>
                    ) : (
                      <MDBCol lg="6" md="12" sm="12" className="text-center">
                        <h5>
                          Next Step: <span>Switch Confirmation</span>
                        </h5>
                        <MDBBtn
                          className="btn btn-bright"
                          onClick={this.nextStep}
                        >
                          Complete
                        </MDBBtn>
                      </MDBCol>
                    )}

                    {/* Back */}
                    {isMobile && !this.state.hideBack ? (
                      <MDBCol
                        lg="6"
                        md="12"
                        sm="12"
                        className="text-center mb-2"
                      >
                        <h5>
                          Previous Step: <span>Payment Details</span>
                        </h5>
                        <a href={`/${this.state.jurisdiction}/switch/payment`}>
                          <MDBBtn className="btn switch-button">Back</MDBBtn>
                        </a>
                      </MDBCol>
                    ) : null}
                  </MDBRow>
                </div>
              </MDBCol>
              <MDBCol lg="2" />
            </MDBRow>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.switch.isLoading,
    journeyData: store.switch.switchData,
  };
};

const actionCreators = {
  getSwitch: switchActions.getSwitchJourney,
  updateSwitch: switchActions.updateSwitchJourney,
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(SwitchSummary);
