import React, { useState, useEffect } from "react";
import {
    MDBRow,
    MDBCol,
    MDBBtn
} from "mdbreact";
// import Loader from "../../../../components/Shared/Loader";
import { useForm } from "react-hook-form";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { agencyActions } from "../../../redux/actions";

export const AddNewProperty = () => {
    const dispatch = useDispatch();
    const agencyStore = useSelector((state) => state.agency)
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => {
        var dispatchData = data;
        dispatchData.meterReading = Number(data.meterReading);
        dispatch(agencyActions.addNewProperty(dispatchData))
    };

    return (
        <div>
            {agencyStore.addPropertySuccess ? (
                <div className="modalBG">
                    <div className="modalBox">
                        <div className="white-curved-tile text-center">
                            <h4>Your request has been submited successfully!</h4>
                            <MDBRow>
                                <MDBCol lg="12" md="12" sm="12">
                                    <MDBBtn onClick={() => window.location.reload()}>
                                        Ok
                        </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="formInput">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <MDBRow className='mb-6'>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Apartment Number</label>
                            <input {...register("aptNum", {
                                required: "This field is required",
                                pattern: {
                                    value: /^[0-9]+$/i,
                                    message: "Please use numbers only"
                                }
                            })} />
                            {errors?.aptNum?.message && <span>{errors?.aptNum?.message}</span>}
                        </MDBCol>
                        <MDBCol lg='1'></MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Address</label>
                            <input {...register("address", {
                                required: true,
                                pattern:'^[A-Z a-z 0-9]{1,100}$'
                            })} />
                            {errors.address && <span>This field is required</span>}
                        </MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>MPRN</label>
                            <input {...register("mprn",                                
                                {
                                    required: "Mprn is required",
                                    pattern: {
                                        value:  /^(?=\d{11}$)(10)\d+/,
                                        message: "Digits begining with 10 expected"
                                    }
                                })} />
                            {errors.mprn && <span>This field is required with a max length of 11 digits</span>}
                        </MDBCol>
                        <MDBCol lg='1'></MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Reading Date</label>
                            <input
                                type="date"
                                min={new Date().toISOString().slice(0, 10)}
                                {...register("readingDate", { required: true })}
                            />
                            {errors.readingDate && <span>This field is required</span>}
                        </MDBCol>
                        <MDBCol lg='5' className='text-left p-0'>
                            <label>Meter Reading</label>
                            <input {...register("meterReading",
                                {
                                    required: true,
                                    maxLength: 5,
                                    pattern: '^[_0-9]{5}'
                                })} />
                            {errors.meterReading && <span>This field is required with a max length of 5 digits</span>}
                        </MDBCol>
                    </MDBRow>

                    <MDBRow center className="mb-2">
                        <MDBCol lg="4">
                            <MDBBtn className="my-3" type="submit">
                                Submit
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>

                </form>
            </div>
        </div>
    )
};