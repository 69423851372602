import { signinService } from "../services";
import { signinConstants } from "../constants";
import { history } from "../helpers";

export const signinActions = {
  login,
  logout,
  register,
  requestPasswordReset,
  setNewPassword,
  getUserByResetId,
  switchAccount,
};

// Login
function login(details, jurisdiction) {
  return (dispatch) => {
    // Request
    dispatch(request(details));

    signinService.loginService(details).then(
      (response) => {
        dispatch(success(response.data));

        let url = "";
        if (response.data.jModel == null) {
          url =
            "/" +
            response.data.authenticateModel.currentJurisdiction.toLowerCase() +
            "/account/signuprequired";
        } else {
            url =
              "/" +
              response.data.authenticateModel.currentJurisdiction.toLowerCase() +
              "/account/dashboard";
        }
        history.push(url);
      },
      (error) => {
        dispatch(failure([error.response.data.message]));
      }
    );
  };

  function request(dbData) {
    return {
      type: signinConstants.LOGIN_REQUEST,
      payload: dbData,
    };
  }

  function success(dbData) {
    return {
      type: signinConstants.LOGIN_SUCCESS,
      payload: dbData,
    };
  }

  function failure(error) {
    return {
      type: signinConstants.LOGIN_FAILURE,
      payload: error,
    };
  }
}

// Logout
function logout(jurisdiction) {
  signinService.logoutService();
  history.push(`/${jurisdiction}`);
  return { type: signinConstants.LOGOUT };
}

// Register
function register(details, jurisdiction) {
  return (dispatch) => {
    // Request
    dispatch(request(details));

    signinService.registerService(details, jurisdiction).then(
      (response) => {
        dispatch(success(response));
        alert("Registration successful... Please login to continue.");
        var url = "/" + jurisdiction + "/account/login";
        history.push(url);
      },
      (error) => {
        dispatch(failure([error.response.data.message]));
      }
    );
  };

  function request(dbData) {
    return {
      type: signinConstants.REGISTER_REQUEST,
      payload: dbData,
    };
  }

  function success(dbData) {
    return {
      type: signinConstants.REGISTER_SUCCESS,
      payload: dbData,
    };
  }

  function failure(error) {
    return {
      type: signinConstants.REGISTER_FAILURE,
      payload: error,
    };
  }
}

// Request password reset
function requestPasswordReset(details, jurisdiction) {
  return (dispatch) => {
    // Request
    dispatch(request(details));

    signinService.requestPasswordResetService(details).then(
      (response) => {
        dispatch(success(response));
        alert("We have sent you a verification email");
        var url = "/" + jurisdiction + "/account/login";
        history.push(url);
      },
      (error) => {
        dispatch(failure([error.response]));
      }
    );
  };

  function request(dbData) {
    return {
      type: signinConstants.RESETPASSWORD_REQUEST,
      payload: dbData,
    };
  }

  function success(dbData) {
    return {
      type: signinConstants.RESETPASSWORD_SUCCESS,
      payload: dbData,
    };
  }

  function failure(error) {
    return {
      type: signinConstants.RESETPASSWORD_FAILURE,
      payload: error,
    };
  }
}

// Request password reset
function setNewPassword(details, resetId, jurisdiction) {
  return (dispatch) => {
    // Request
    dispatch(request(details));

    signinService.setNewPasswordService(details, resetId).then(
      (response) => {
        dispatch(success(response));
        alert("Your password has been reset successfully");
        var url = "/" + jurisdiction + "/account/login";
        history.push(url);
      },
      (error) => {
        dispatch(failure([error.response]));
      }
    );
  };

  function request(dbData) {
    return {
      type: signinConstants.SETNEWPASSWORD_REQUEST,
      payload: dbData,
    };
  }

  function success(dbData) {
    return {
      type: signinConstants.SETNEWPASSWORD_SUCCESS,
      payload: dbData,
    };
  }

  function failure(error) {
    return {
      type: signinConstants.SETNEWPASSWORD_FAILURE,
      payload: error,
    };
  }
}

// Request password reset
function getUserByResetId(resetId) {
  return (dispatch) => {
    // Request
    dispatch(request(resetId));

    signinService.getUserByResetIdService(resetId).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure([error.response]));
      }
    );
  };

  function request(dbData) {
    return {
      type: signinConstants.GETUSERBYRESETID_REQUEST,
      payload: dbData,
    };
  }

  function success(dbData) {
    return {
      type: signinConstants.GETUSERBYRESETID_SUCCESS,
      payload: dbData.data,
    };
  }

  function failure(error) {
    return {
      type: signinConstants.GETUSERBYRESETID_FAILURE,
      payload: error,
    };
  }
}

//switch account
function switchAccount(userId, propId, jurisdiction) {
  return (dispatch) => {
    // Request
    dispatch(request(userId));

    signinService.switchAccountService(userId, propId, jurisdiction).then(
      (response) => {
        dispatch(success(response.data));
        let url = "";
        if (response.data.jModel == null) {
          url = "/" + jurisdiction + "/account/signuprequired";
        } else {
          url = "/" + jurisdiction + "/account/dashboard";
        }
        window.location.href = url;
      },
      (error) => {
        dispatch(failure([error.response.data.message]));
      }
    );
  };

  function request(dbData) {
    return {
      type: signinConstants.SWITCHACCOUNT_REQUEST,
      payload: dbData,
    };
  }

  function success(dbData) {
    return {
      type: signinConstants.SWITCHACCOUNT_SUCCESS,
      payload: dbData,
    };
  }

  function failure(error) {
    return {
      type: signinConstants.SWITCHACCOUNT_FAILURE,
      payload: error,
    };
  }
}
