import React from "react";
import { MDBRow, MDBCol, MDBInput, MDBAlert } from "mdbreact";
import $ from "jquery";

import { connect } from "react-redux";

import { signinActions } from "../../../redux/actions";

import { history } from "../../../redux/helpers";
import { sessionExpired } from "../../../redux/helpers/sessionExpired";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      jurisdiction: "",
    };
  }

  componentDidMount() {
    // Check user token
    sessionExpired();

    //get jurisdiction
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    let user = localStorage.getItem("user");
    if (user != null) {
      var url = `/${juris}/account/dashboard`;
      history.push(url);
    }
  }

  // Handles Input Changes
  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  // Handle Submit
  handleSubmit = (e) => {
    e.preventDefault();
    e.target.className += " was-validated";

    var valid = $("#login")[0].checkValidity();

    console.log("Form valid: " + valid);

    if (valid) {
      const details = {
        username: this.state.username,
        password: this.state.password,
        jurisdiction: this.state.jurisdiction,
      };

      this.props.signin(details, this.state.jurisdiction);
    }
  };

  render() {
    return (
      <MDBRow className="login-form-container white-curved-tile">
        <MDBCol md="12">
          {/* Error Message */}
          {this.props.error != "" ? (
            <div>
              <MDBAlert color="danger">{this.props.error}</MDBAlert>
            </div>
          ) : null}

          <form id="login" onSubmit={this.handleSubmit}>
            <p className="h5 text-center mb-4">Sign in</p>
            <div className="grey-text">
              <MDBInput
                label="Type your email"
                icon="envelope"
                type="email"
                name="username"
                value={this.state.username}
                onChange={this.changeHandler}
                pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                className="form-control"
                required
              />
              <MDBInput
                label="Type your password"
                icon="lock"
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.changeHandler}
                className="form-control"
                required
              />
            </div>
            {/* Forgot Password Button */}
            <div className="text-right registerButton">
              <a
                href={`/${this.state.jurisdiction}/account/requestpasswordreset`}
              >
                Forgot your password?
              </a>
            </div>
            {/* Submit Button */}
            <div className="text-center">
              <button type="submit" className="btn btn-bright">
                Sign In
              </button>
            </div>
          </form>
        </MDBCol>
      </MDBRow>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    error: store.signin.error,
  };
};

const actionCreators = {
  signin: signinActions.login,
};

export default connect(mapStateToProps, actionCreators)(LoginForm);
