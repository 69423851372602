import React from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon } from "mdbreact";

const NIUserLoginDD = () => {


  return (
    <MDBDropdown className="country-dropdown">
      <MDBDropdownToggle className="signIn">
        <p>NORTHERN IRELAND</p>
        <i className="fa fa-caret-down"></i>
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        <MDBDropdownItem><a>NORTHERN IRELAND</a></MDBDropdownItem>
        <MDBDropdownItem><a href="/roi">REPUBLIC OF IRELAND</a></MDBDropdownItem>
      </MDBDropdownMenu>
    </MDBDropdown>
  );
}

export default NIUserLoginDD;
