import React from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";

import Swiper from "react-id-swiper";

// Redux
import { connect } from "react-redux";
import { appPromotionsActions } from "../../../redux/actions";

class DashboardCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appPromotionModal: false,
      title: "",
      body: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.props.getAppPromotions(juris);
  }

  promotionContent = () => {
    return (
      <div className='modalBG'>
        <div className='modalBox'>
          <div className='white-curved-tile text-center'>
            <h4>{this.state.title}</h4>
            <p>{this.state.body}</p>
            <a href={this.state.infoUrl}>Click here for more information</a>
            <MDBRow className='justify-content-center'>
              <MDBCol lg='6' md='12' sm='12'>
                <MDBBtn
                  className='cancel-button'
                  onClick={() =>
                    this.setState({
                      appPromotionModal: !this.state.appPromotionModal,
                    })
                  }
                >
                  Close
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const carouselParams = {
      // effect: "coverflow",
      slidesPerView: 1,
      spaceBetween: 10,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      speed: 1500,
      rebuildOnUpdate: true,
    };
    return (
      <div>
        {/* App promotion modal */}
        {this.state.appPromotionModal ? (
          <div> {this.promotionContent()}</div>
        ) : null}

        {/* Carousel */}
        <MDBRow>
          <MDBCol md='12'>
            <div className='white-curved-tile '>
              <Swiper {...carouselParams}>
                {this.props.appPromotions
                  ? this.props.appPromotions.map((slide) => (
                      <div key={slide.Id}>
                        <MDBRow>
                          <MDBCol sm='12'>
                            <MDBRow>
                              <MDBCol md='6'>
                                {/* Carousel Item One - Image */}
                                <div className='image-container'>
                                  <img
                                    className='slider-image'
                                    src={slide.imageUrl}
                                  />
                                </div>
                              </MDBCol>
                              <MDBCol md='6'>
                                {/* Carousel Item One - Title */}
                                <h4>{slide.title}</h4>
                                <h2>{slide.summary}</h2>
                                <a
                                  onClick={() =>
                                    this.setState({
                                      appPromotionModal: true,
                                      title: slide.title,
                                      body: slide.body,
                                      infoUrl: slide.infoUrl,
                                    })
                                  }
                                  className='grey-text'
                                >
                                  Read More
                                </a>
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    ))
                  : null}
              </Swiper>
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    appPromotions: store.appPromotions.appPromotionsData,
  };
};

const actionCreators = {
  getAppPromotions: appPromotionsActions.getAppPromotions,
};

export default connect(mapStateToProps, actionCreators)(DashboardCarousel);
