import React from "react";
import ROISwitchPayment from "../../../components/Forms/Switch/ROI/_FormPaymentDetails";

class SwitchPayment extends React.Component {
  state = {
    jurisdiction: "roi",
  };

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  render() {
    return (
      <div>
        <ROISwitchPayment />
      </div>
    );
  }
}

export default SwitchPayment;
