import React, { Component } from "react";
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBTable,
  MDBTableBody,
  MDBBtn,
  MDBAlert,
} from "mdbreact";
import $ from "jquery";
import Loader from "../../../Shared/Loader";
import { connect } from "react-redux";
import { switchActions } from "../../../../redux/actions/switchActions";
import { quoteActions } from "../../../../redux/actions/quoteActions";

const LoadConfig = require("../../../../config");
const config = LoadConfig.default;

export class _FormSwitchSummary extends Component {
  state = {
    confirmDDAuth: "",
    agreeToTerms: "",
    agreeToPrivacyPolicy: "",

    errorMessage: false,
    switchGot: false,
    jurisdiction: "roi",
    homeCheck: false,
    smartCustomer: false,
    smAmount: "",
  };

  // Page Load
  componentDidMount() {
    let switchID = localStorage.getItem("switchID");

    if (switchID != null) {
      this.props.getSwitch(switchID);
      this.setState({ switchGot: true });
    }

    // Get quote by id
    let quoteID = localStorage.getItem("quoteID");
    if (quoteID != null) {
      this.props.getQuote(quoteID);
    }
  }

  // If Props.SwitchData true populate form
  async componentWillReceiveProps(nextProps) {
    try {
      await this.setState({
        smartCustomer: nextProps.journeyData.smartCustomer,
        smAmount: nextProps.journeyData.ddAmount,
      });
    } catch {}

    let sectionUpdated = JSON.parse(localStorage.getItem("summaryUpdated"));

    if (this.state.switchGot && sectionUpdated) {
      await this.setState({
        confirmDDAuth: nextProps.journeyData.confirmDDAuth,
        agreeToTerms: nextProps.journeyData.agreeToTerms,
        agreeToPrivacyPolicy: nextProps.journeyData.agreeToPrivacyPolicy,
      });
    }

    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Continue to next section
  nextStep = (e) => {
    // Prevent from from clearing
    e.preventDefault();

    // Validate Form
    let validation = $("#switchSummary")[0].checkValidity();

    if (validation) {
      // Form Data
      const data = {
        // Step One - (Account Details)
        accountHolder: this.props.journeyData.accountHolder,
        title: this.props.journeyData.title,
        firstName: this.props.journeyData.firstName,
        surname: this.props.journeyData.surname,
        dob: this.props.journeyData.dob,
        email: this.props.journeyData.email,
        phone: this.props.journeyData.phone,
        postcode: this.props.journeyData.postcode,
        addrLine1: this.props.journeyData.addrLine1,
        addrLine2: this.props.journeyData.addrLine2,
        city: this.props.journeyData.city,
        county: this.props.journeyData.county,
        mprn: this.props.journeyData.mprn,
        // Smart meter
        smartCustomer: this.props.journeyData.smartCustomer,
        halfHourly: this.props.journeyData.halfHourly,
        // Account Correspondance
        accountCorrespondence: this.props.journeyData.accountCorrespondence,
        accCorrTitle: this.props.journeyData.accCorrTitle,
        accCorrFirstName: this.props.journeyData.accCorrFirstName,
        accCorrSurname: this.props.journeyData.accCorrSurname,
        accCorrName: this.props.journeyData.accCorrName,
        accCorrPostcode: this.props.journeyData.accCorrPostcode,
        accCorrAddLineOne: this.props.journeyData.accCorrAddLineOne,
        accCorrAddLineTwo: this.props.journeyData.accCorrAddLineTwo,
        accCorrCity: this.props.journeyData.accCorrCity,
        accCorrCounty: this.props.journeyData.accCorrCounty,
        accCorrEmail: this.props.journeyData.accCorrEmail,
        accCorrAddPhone: this.props.journeyData.accCorrAddPhone,
        // Joint Account
        jointPerson: this.props.journeyData.jointPerson,
        jointTitle: this.props.journeyData.jointTitle,
        jointFirstName: this.props.journeyData.jointFirstName,
        jointSurname: this.props.journeyData.jointSurname,
        jointEmail: this.props.journeyData.jointEmail,
        jointPhone: this.props.journeyData.jointPhone,
        // Marketing
        markEmail: this.props.journeyData.markEmail,
        markSMS: this.props.journeyData.markSMS,
        markPost: this.props.journeyData.markPost,
        markPhone: this.props.journeyData.markPost,

        // Step Two - Your Electricity
        newConnection: this.props.journeyData.newConnection,
        meterReading: this.props.journeyData.meterReading,
        nightMeterReading: this.props.journeyData.nightMeterReading,

        // Step Three - Additional Services
        serviceCheck: this.props.journeyData.serviceCheck,
        PDC: this.props.journeyData.PDC,
        specialServices: this.props.journeyData.specialServices,
        requiredSpecialServices: this.props.journeyData.requiredSpecialServices,
        medicalServices: this.props.journeyData.medicalServices,
        requiredMedicalServices: this.props.journeyData.requiredMedicalServices,
        priorityRegister: this.props.journeyData.priorityRegister,
        //nom person
        nomPerson: this.props.journeyData.nomPerson,
        nomCorrespondance: this.props.journeyData.nomCorrespondance,
        nomTitle: this.props.journeyData.nomTitle,
        nomFirstName: this.props.journeyData.nomFirstName,
        nomSurname: this.props.journeyData.nomSurname,
        nomEmail: this.props.journeyData.nomEmail,
        nomPhone: this.props.journeyData.nomPhone,
        nomPostcode: this.props.journeyData.nomPostcode,
        nomAddrLine1: this.props.journeyData.nomAddrLine1,
        nomAddrLine2: this.props.journeyData.nomAddrLine2,
        nomCity: this.props.journeyData.nomCity,
        nomCounty: this.props.journeyData.nomCounty,

        // Step Four - Payment Details
        accountName: this.props.journeyData.accountName,
        bankAccountNo: this.props.journeyData.bankAccountNo,
        bankSortCode: this.props.journeyData.bankSortCode,
        billingMethod: this.props.journeyData.billingMethod,
        iban: this.props.journeyData.iban,
        DDAmount: this.props.journeyData.DDAmount,
        DDDayOfMonth: this.props.journeyData.DDDayOfMonth,
        bankAddressLineOne: this.props.journeyData.bankAddressLineOne,
        bankAddressLineTwo: this.props.journeyData.bankAddressLineTwo,
        bankAddressPostcode: this.props.journeyData.bankAddressPostcode,
        bankAddressCountry: this.props.journeyData.bankAddressCountry,
        confirmDDAuth: this.props.journeyData.confirmDDAuth,

        // Step Five (This Page) - Switch Summary
        agreeToTerms: this.state.agreeToTerms,
        agreeToPrivacyPolicy: this.state.agreeToPrivacyPolicy,
        switchCompleted: true,
      };

      // Hide Error Message (if visiable)
      this.setState({
        errorMessage: false,
      });

      // SwitchID
      let switchID = localStorage.getItem("switchID");

      // This Page (updates page status)
      let pageStatus = "summaryUpdated";

      // Next Page URL
      let nextPage = "confirmation";

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Database Entry
      this.props.updateSwitch(
        switchID,
        data,
        pageStatus,
        nextPage,
        "",
        jurisdiction
      );
    } else {
      // For each form input that has the required prop add the input-error class
      $("form#switchSummary")
        .find("input")
        .each(function () {
          if ($(this).prop("required")) {
            $(this).addClass("input-error");
          }
        });

      // Display the error message
      this.setState({
        errorMessage: true,
      });

      // Scroll to error
      window.location.href = "#errorMessage";
    }
  };

  render() {
    return (
      <div className="quote-page switch-page">
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        <form id="switchSummary">
          {/* Background Image */}
          <div className="device-image" />
          <div className="quote-content-container">
            {/* Logo */}
            <MDBRow className="text-center">
              <MDBCol lg="2" md="12" sm="12">
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <img
                    src={require("../../../../images/global/bright-logo-dark.png")}
                    className="quote-logo mx-5 mt-5"
                  />
                </a>
              </MDBCol>
              <MDBCol size="10" />
            </MDBRow>

            {/* Home message */}
            {this.state.homeCheck ? (
              <div className="modalBG">
                <div className="modalBox">
                  <div className="white-curved-tile text-center">
                    <h4 className="mt-5">
                      All progress will be lost! <br />
                      Are you sure?
                    </h4>
                    <MDBRow>
                      <MDBCol lg="6" md="6" sm="12">
                        <MDBBtn
                          className="cancel-button"
                          onClick={() =>
                            this.setState({ homeCheck: !this.state.homeCheck })
                          }
                        >
                          Cancel
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol lg="6" md="6" sm="12">
                        <MDBBtn onClick={() => (window.location.href = "/")}>
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            <MDBContainer className="personal-details-section">
              <MDBRow>
                {/* Error Message */}
                <MDBCol size="2" />
                <MDBCol size="8" id="errorMessage">
                  {this.state.errorMessage == true ? (
                    <MDBAlert color="danger" dismiss>
                      <strong>Oops!</strong> Please check your fields below.
                    </MDBAlert>
                  ) : null}
                </MDBCol>
                <MDBCol size="2" />
              </MDBRow>
            </MDBContainer>

            <MDBRow className="my-5" center>
              <MDBCol lg="8" md="12" sm="12">
                <div className="white-curved-tile switch-ready">
                  {/* Title */}
                  <h3 className="text-center">Your Switch Summary</h3>

                  {/* Tariff Details */}
                  <h4 className="text-center">Principal terms of contract</h4>
                  <p className="text-center">
                    Below we've set out the principal terms of our contract to
                    provide electricity to you, based on the information you
                    have provided. Please check these carefully and amend your
                    details if there are any errors. Please note that prices are
                    subject to change. If having completed the switching
                    process, you change your mind a cooling-off period of 14
                    days applies, in which you can cancel your contract by
                    contacting us on 01 588 1777.
                  </p>

                  {/* Contract info */}
                  <MDBCol size="12">
                    <h4 className="text-center">
                      Your principal terms of contract information
                    </h4>
                    <MDBTable className="mt-4">
                      <MDBTableBody className="text-left">
                        <tr>
                          <td>Supplier</td>
                          <td className="text-center">bright</td>
                        </tr>
                        <tr>
                          <td>Tariff Name</td>
                          <td className="text-center">bright future</td>
                        </tr>
                        <tr>
                          <td>Tariff Type</td>
                          <td className="text-center">Variable rate tariff</td>
                        </tr>
                        <tr>
                          <td>Payment Method</td>
                          {this.props.quoteData.paymentMethod ==
                          "PayOnReceipt" ? (
                            <td className="text-center">Pay on receipt</td>
                          ) : (
                            <td className="text-center">Direct debit</td>
                          )}
                        </tr>
                        <tr>
                          <td>Payment Amount</td>
                          {localStorage.getItem("ddAmount") == 0 ? (
                            <td className="text-center">Variable</td>
                          ) : (
                            <td className="text-center">
                              {this.state.smartCustomer
                                ? `€${this.state.smAmount}`
                                : `€${localStorage.getItem("ddAmount")}`}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>Type of payment</td>
                          <td className="text-center">Recurrent</td>
                        </tr>
                        <tr>
                          <td>Collection Date</td>
                          {this.props.quoteData.paymentMethod !=
                          "PayOnReceipt" ? (
                            <td className="text-center">11 working days</td>
                          ) : null}
                        </tr>
                      </MDBTableBody>
                    </MDBTable>
                  </MDBCol>

                  {/* Tariff info */}
                  <MDBCol size="12" className="text-center">
                    <h4 className="text-center">Tariff summary</h4>
                    <p className="text-center">
                      The table below contains all available bright tariffs.
                      Please refer to the relevant tariff rates and charges for
                      your meter.
                    </p>
                    <p className="text-center">
                      All prices incl VAT. For excl VAT please refer to{" "}
                      <a
                        href={`/${this.state.jurisdiction}/about/ourtariff`}
                        target="_blank"
                      >
                        our tariff page
                      </a>
                    </p>

                    <MDBRow center>
                      <MDBCol className="p-0 tariff-sum">
                        <table className="table-border">
                          <tbody>
                            <thead>
                              <tr className="tableizer-firstrow text-center">
                                <th>&nbsp;</th>
                                <th colspan="2">24 hour Standard Meter</th>
                                <th>&nbsp;</th>
                                <th colspan="2">Day / Night Meter</th>
                                <th>&nbsp;</th>
                                <th colspan="2">Smart Meter</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>&nbsp;</td>
                                <td className="text-center">Urban</td>
                                <td className="text-center">Rural</td>
                                <td>&nbsp;</td>
                                <td className="text-center">Urban</td>
                                <td className="text-center">Rural</td>
                                <td>&nbsp;</td>
                                <td className="text-center">Urban</td>
                                <td className="text-center">Rural</td>
                              </tr>
                              <tr>
                                <td>24 hour or day unit rate (cent per kWh)</td>
                                <td className="text-center">
                                  {config.TFH.toFixed(3)}
                                </td>
                                <td className="text-center">
                                  {config.TFH.toFixed(3)}
                                </td>
                                <td>&nbsp;</td>
                                <td className="text-center">
                                  {config.NSDay.toFixed(3)}
                                </td>
                                <td className="text-center">
                                  {config.NSDay.toFixed(3)}
                                </td>
                                <td>&nbsp;</td>
                                <td className="text-center">
                                  {config.SMDay.toFixed(3)}
                                </td>
                                <td className="text-center">
                                  {config.SMDay.toFixed(3)}
                                </td>
                              </tr>
                              <tr>
                                <td>Night unit rate (cent per kWh)</td>
                                <td className="text-center">n/a</td>
                                <td className="text-center">n/a</td>
                                <td>&nbsp;</td>
                                <td className="text-center">
                                  {config.NSNight.toFixed(3)}
                                </td>
                                <td className="text-center">
                                  {config.NSNight.toFixed(3)}
                                </td>
                                <td>&nbsp;</td>
                                <td className="text-center">
                                  {config.SMNight.toFixed(3)}
                                </td>
                                <td className="text-center">
                                  {config.SMNight.toFixed(3)}
                                </td>
                              </tr>
                              <tr>
                                <td>Peak unit rate (cent per kWh)</td>
                                <td className="text-center">n/a</td>
                                <td className="text-center">n/a</td>
                                <td>&nbsp;</td>
                                <td className="text-center">n/a</td>
                                <td className="text-center">n/a</td>
                                <td>&nbsp;</td>
                                <td className="text-center">
                                  {config.SMPeak.toFixed(3)}
                                </td>
                                <td className="text-center">
                                  {config.SMPeak.toFixed(3)}
                                </td>
                              </tr>
                              <tr>
                                <td>Standing charge (€ per year)</td>
                                <td className="text-center">
                                  €{config.urbanStandingCharge.toFixed(2)}
                                </td>
                                <td className="text-center">
                                  €{config.ruralStandingCharge.toFixed(2)}
                                </td>
                                <td>&nbsp;</td>
                                <td className="text-center">
                                  €{config.urbanStandingCharge.toFixed(2)}
                                </td>
                                <td className="text-center">
                                  €{config.ruralStandingCharge.toFixed(2)}
                                </td>
                                <td>&nbsp;</td>
                                <td className="text-center">
                                  €{config.SMStandingCharge.toFixed(2)}
                                </td>
                                <td className="text-center">
                                  €{config.SMRuralStandingCharge.toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td>PSO levy (€ per year)</td>
                                <td className="text-center">
                                  €{config.psoLevy.toFixed(2)}
                                </td>
                                <td className="text-center">
                                  €{config.psoLevy.toFixed(2)}
                                </td>
                                <td>&nbsp;</td>
                                <td className="text-center">
                                  €{config.psoLevy.toFixed(2)}
                                </td>
                                <td className="text-center">
                                  €{config.psoLevy.toFixed(2)}
                                </td>
                                <td>&nbsp;</td>
                                <td className="text-center">
                                  €{config.psoLevy.toFixed(2)}
                                </td>
                                <td className="text-center">
                                  €{config.psoLevy.toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td>Estimated Annual Bill (EAB¹)</td>
                                <td className="text-center">
                                  €{config.TFHUrbEAB}
                                </td>
                                <td className="text-center">
                                  €{config.TFHRurEAB}
                                </td>
                                <td>&nbsp;</td>
                                <td className="text-center">
                                  €{config.NSUrbEAB}
                                </td>
                                <td className="text-center">
                                  €{config.NSRurEAB}
                                </td>
                                <td>&nbsp;</td>
                                <td className="text-center">
                                  €{config.SMUrbanEAB}
                                </td>
                                <td className="text-center">
                                  €{config.SMRuralEAB}
                                </td>
                              </tr>
                            </tbody>
                          </tbody>
                        </table>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>

                  {/* Terms & Conditions */}
                  <MDBRow className="text-center">
                    <MDBCol lg="12" md="12" sm="12">
                      <h4 className="mt-3">Terms & Conditions</h4>
                      {/* Terms Confirmation */}
                      <input
                        id="tcconfirmation"
                        name="tcconfirmation"
                        type="checkbox"
                        onClick={() =>
                          this.setState({
                            agreeToTerms: !this.state.agreeToTerms,
                          })
                        }
                        required
                        checked={this.state.agreeToTerms ? true : false}
                      />
                      <label for="tcconfirmation" className="ml-2">
                        Agree to our{" "}
                        <a
                          href={`/${this.state.jurisdiction}/terms`}
                          target="_blank"
                        >
                          terms and conditions
                        </a>
                      </label>
                    </MDBCol>
                    <MDBCol lg="12" md="12" sm="12">
                      {/* Privacy Policy */}
                      <input
                        id="ppconfirmation"
                        name="ppconfirmation"
                        type="checkbox"
                        onClick={() =>
                          this.setState({
                            agreeToPrivacyPolicy: !this.state
                              .agreeToPrivacyPolicy,
                          })
                        }
                        required
                        checked={this.state.agreeToPrivacyPolicy ? true : false}
                      />
                      <label for="ppconfirmation" className="ml-2">
                        Have read and understood our{" "}
                        <a
                          href={`/${this.state.jurisdiction}/privacystatement`}
                          target="_blank"
                        >
                          privacy policy
                        </a>
                      </label>
                    </MDBCol>
                  </MDBRow>

                  {/* Buttons */}
                  <MDBRow className="mt-5 text-center">
                    {/* Back */}
                    <MDBCol lg="6" md="12" sm="12" className="text-center mb-2">
                      <h5>
                        Previous Step: <span>Payment Details</span>
                      </h5>
                      <a href={`/${this.state.jurisdiction}/switch/payment`}>
                        <MDBBtn className="btn switch-button">Back</MDBBtn>
                      </a>
                    </MDBCol>

                    {/* Continue */}
                    <MDBCol lg="6" md="12" sm="12" className="text-center">
                      <h5>
                        Next Step: <span>Switch Confirmation</span>
                      </h5>
                      <MDBBtn
                        className="btn btn-bright"
                        onClick={this.nextStep}
                      >
                        Complete
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.switch.isLoading,
    journeyData: store.switch.switchData,
    quoteData: store.quote.quoteData,
  };
};

const actionCreators = {
  getSwitch: switchActions.getSwitchJourney,
  updateSwitch: switchActions.updateSwitchJourney,
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(_FormSwitchSummary);
