import React, { Component } from "react";
import { MDBRow, MDBCol, MDBAlert, MDBInput, MDBBtn, MDBIcon } from "mdbreact";
import Loader from "../../../components/Shared/Loader";
import $ from "jquery";

// Redux
import { connect } from "react-redux";
import { signinActions } from "../../../redux/actions";

class SetNewPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      passwordVerify: "",
      jurisdiction: "",
      error: "",
      userObj: {},
      resetID: "",
      loading: false,
      passwordErrorMessage: false,
    };
  }

  componentDidMount() {
    //get params from url
    let urlParams = new URLSearchParams(window.location.search);
    let resetId = urlParams.get("id");

    if (resetId != "") {
      this.setState({
        resetID: resetId,
      });
    } else {
      this.setState({
        error: "Password reset link has expired",
      });
    }

    //set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  // If Props.journeyData true populate form
  async componentWillReceiveProps(nextProps) {
    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle Field Change
  handleChange = (input) => (e) => {
    var fieldValue = $("#" + input)[0].checkValidity();

    if (fieldValue) {
      $("#" + input)
        .addClass("input-valid")
        .removeClass("input-error");
    } else {
      $("#" + input)
        .addClass("input-error")
        .removeClass("input-valid");
    }

    this.setState({ [input]: e.target.value });
  };

  // Handle Submit
  handleSubmit = (e) => {
    var valid = $("#setNewPassword")[0].checkValidity();
    let passwordValid = "";

    let password = this.state.password;

    if (this.state.password != this.state.passwordVerify) {
      passwordValid = false;
      this.setState({
        passwordErrorMessage: true,
      });
    } else {
      passwordValid = true;
      this.setState({
        passwordErrorMessage: false,
      });
    }

    if (valid && passwordValid) {
      const details = {
        password: this.state.password,
      };

      let resetID = this.state.resetID;

      // Set new password
      this.props.setNewPassword(details, resetID, this.state.jurisdiction);
    }
  };

  render() {
    return (
      <div>
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        {/* Password Error Message */}
        <MDBCol size='12' id='passwordErrorMEssage'>
          {this.state.passwordErrorMessage == true ? (
            <MDBAlert color='danger' className='my-5' dismiss>
              <strong>Oops!</strong> Both passwords must match. Please try
              again!
            </MDBAlert>
          ) : null}
        </MDBCol>

        {this.state.error != "Password reset link has expired" ? (
          <MDBRow className='login-form-container white-curved-tile'>
            <MDBCol md='12'>
              <form id='setNewPassword'>
                <div className='grey-text'>
                  <MDBRow className='align-items-baseline'>
                    <MDBCol size='10'>
                      <MDBInput
                        label='Your new password'
                        icon='lock'
                        type='password'
                        id='password'
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$"
                        value={this.state.password}
                        onChange={this.handleChange("password")}
                        className='form-control'
                        required
                      />
                    </MDBCol>

                    <MDBCol>
                      {/* <MDBBtn className='tooltip-container'> */}
                        <MDBIcon
                          icon='info-circle'
                          onClick={() =>
                            this.setState({
                              passwordTip: !this.state.passwordTip,
                            })
                          }
                        />
                      {/* </MDBBtn> */}
                    </MDBCol>
                  </MDBRow>
                  {this.state.passwordTip == true ? (
                    <div id='helpAlert'>
                      <MDBAlert className='help-alert' dismiss>
                        <MDBRow>
                          <MDBCol size='2' className='align-self-center'>
                          </MDBCol>
                          <MDBCol size='10'>
                            <p>
                              Your password must be:
                              <ul>
                                <li>Between 8-30 characters long.</li>
                                <li>At least 1 uppercase letter.</li>
                                <li>At least 1 lowercase letter.</li>
                                <li>At least 1 special character.</li>
                                <li>At least 1 number.</li>
                              </ul>
                            </p>
                          </MDBCol>
                        </MDBRow>
                      </MDBAlert>
                    </div>


                  ) : null}

                  <MDBRow>
                    <MDBCol lg="10" sm="12">
                      <MDBInput
                        label='Verify your new password'
                        icon='lock'
                        type='password'
                        id='passwordVerify'
                        value={this.state.passwordVerify}
                        onChange={this.handleChange("passwordVerify")}
                        className='form-control'
                        required
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$"
                      />
                    </MDBCol>
                  </MDBRow>
                </div>
                <MDBRow>
                  <MDBCol className='text-center'>
                    <MDBBtn onClick={() => this.handleSubmit()} className='btn'>
                      Set New Password
                </MDBBtn>
                  </MDBCol>
                </MDBRow>
                <div>
                  {/* <a href={`/${this.state.jurisdiction}/account/login`} className='forgot-password'>
                Already know your password? Sign in
              </a> */}
                </div>
              </form>
            </MDBCol>
          </MDBRow>
        ) : (
            <MDBRow className='login-form-container white-curved-tile'>
              <MDBCol md='12'>
                <h2>There was an issue with your reset ID</h2>
                <a
                  href={`/${this.state.jurisdiction}/account/requestpasswordreset`}
                >
                  Click here to request a new one
              </a>
              </MDBCol>
            </MDBRow>
          )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.signin.user,
    error: store.signin.error,
    isLoading: store.signin.isLoading,
  };
};

const actionCreators = {
  setNewPassword: signinActions.setNewPassword,
};

export default connect(mapStateToProps, actionCreators)(SetNewPasswordForm);
