import React from "react";
import { MDBCol } from "mdbreact";
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";

const loaderStyle = css`
  display: block;
  margin: 0 auto;
`;
const Loader = () => (
  <div className='loader-overlay'>
    <MDBCol className='loader-center'>
      <FadeLoader
        height={30}
        width={5}
        margin={15}
        css={loaderStyle}
        color={"#00e21a"}
        loading={true}
      />
    </MDBCol>
  </div>
);

export default Loader;
