import React from "react";
import ROIProperty from "../../../components/Forms/Quote/ROI/_FormProperty";
import { Helmet } from "react-helmet";

class Quote extends React.Component {
  state = {
    jurisdiction: "roi",
  };

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta property='og:title' content='bright' />
          <meta property='og:type' content='website' />
          <meta
            property='og:url'
            content='https://brightenergy.com/roi/quote'
          />
          <meta
            property='og:image'
            content='https://brightenergy.com/static/media/home-hero.6b27b189.jpg'
          />
        </Helmet>

        <div>
          <ROIProperty />
        </div>
      </div>
    );
  }
}

export default Quote;
