import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import LegalTabs from "../../components/About/LegalTabs";

import signupPDF from "../../documents/signups.pdf";
import billingPDF from "../../documents/billing.pdf";
import charterPDF from "../../documents/charter.pdf";
import complaintsPDF from "../../documents/complaints.pdf";
import marketingPDF from "../../documents/marketing.pdf";
import vulnerablePDF from "../../documents/vulnerable.pdf";
import smartPDF from "../../documents/smartServices.pdf";
import VulnerableFormPDF from "../../documents/BrightVulnerableCustomersForms.pdf";

import FixedNavDark from "../../components/Shared/FixedNavDark";
import $ from "jquery";

class CodesOfPractice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split('/');
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris
    });

    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.help);
  }

  help =() =>{
    if (window.pageYOffset <50)
    {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }
  
  render() {
    return (
      <div className='default-page home-page'>
        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>Policies & Terms</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
        <MDBRow>
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <LegalTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5 pb-5'>
            <h3>Codes of Practice</h3>
            <p className='mt-4'>
              These codes set out our guarantees to provide you with excellent customer service.
            </p>
            <div className='codes links'>
              <a href={signupPDF}>
                <img src={require("../../images/legals/pdf.png")}></img>
                Customer signup
              </a>
              <a href={billingPDF}>
                <img src={require("../../images/legals/pdf.png")}></img>
                Billing and disconnection
              </a>
              <a href={vulnerablePDF}>
                <img src={require("../../images/legals/pdf.png")}></img>
                Vulnerable customers
              </a>
              <a href={VulnerableFormPDF}>
                <img src={require("../../images/legals/pdf.png")}></img>
                Vulnerable customers registration form
              </a>
              <a href={marketingPDF}>
                <img src={require("../../images/legals/pdf.png")}></img>
                Marketing and advertising
              </a>
              <a href={complaintsPDF}>
                <img src={require("../../images/legals/pdf.png")}></img>
                Complaints
              </a>
              <a href={charterPDF}>
                <img src={require("../../images/legals/pdf.png")}></img>
                Our customer charter
              </a>
              <a href={smartPDF}>
                <img src={require("../../images/legals/pdf.png")}></img>
                Smart services
              </a>
              
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default CodesOfPractice;
