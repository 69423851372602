import React, { Component } from "react";
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBInput,
  MDBBtn,
  MDBAlert,
  MDBIcon,
} from "mdbreact";
import $ from "jquery";
import Loader from "../../../Shared/Loader";

// Redux
import { connect } from "react-redux";
import { signinActions } from "../../../../redux/actions";

export class _FormSwitchConfirmation extends Component {
  state = {
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    loading: false,
    jurisdiction: "roi",

    // Error Handeling
    errorMessage: false,
    homeCheck: false,
  };

  // Page Load
  componentDidMount() {
    localStorage.removeItem("switchID");
    localStorage.removeItem("quoteID");
    localStorage.removeItem("accountUpdated");
    localStorage.removeItem("electricityUpdated");
    localStorage.removeItem("servicesUpdated");
    localStorage.removeItem("paymentUpdated");
    localStorage.removeItem("summaryUpdated");
    localStorage.removeItem("ddAmount");
    localStorage.removeItem("SmartCustomer");
  }

  // Will receive props
  async componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle Field Change
  handleChange = (input) => (e) => {
    var fieldValue = $("#" + input)[0].checkValidity();

    if (fieldValue) {
      $("#" + input)
        .addClass("input-valid")
        .removeClass("input-error");
    } else {
      $("#" + input)
        .addClass("input-error")
        .removeClass("input-valid");
    }

    this.setState({ [input]: e.target.value });
  };

  // Register
  register = () => {
    const details = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      username: this.state.username,
      password: this.state.password,
    };

    // Validate Form
    let validation = $("#register")[0].checkValidity();

    if (validation) {
      // Hide Error Message (if visiable)
      this.setState({
        errorMessage: false,
      });

      let jurisdiction = "roi";

      // Register
      this.props.register(details, jurisdiction);
    } else {
      // For each form input that has the required prop add the input-error class
      $("form#register")
        .find("input")
        .each(function () {
          if ($(this).prop("required")) {
            $(this).addClass("input-error");
          }
        });

      // Display the error message
      this.setState({
        errorMessage: true,
      });
    }
  };

  render() {
    return (
      <div className="quote-page switch-page">
        {/* Background Image */}
        <div className="device-image" />
        <MDBRow>
          <MDBCol size="12" className="my-5">
            {/* Logo */}
            <MDBRow className="text-center">
              <MDBCol lg="2" sm="12">
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <img
                    src={require("../../../../images/global/bright-logo-dark.png")}
                    className="quote-logo mx-5 mt-5"
                  />
                </a>
              </MDBCol>
              <MDBCol size="10" />
            </MDBRow>
          </MDBCol>

          {/* Home message */}
          {this.state.homeCheck ? (
            <div className="modalBG">
              <div className="modalBox">
                <div className="white-curved-tile text-center">
                  <h4 className="mt-5">
                    All progress will be lost! <br />
                    Are you sure?
                  </h4>
                  <MDBRow>
                    <MDBCol lg="6" md="6" sm="12">
                      <MDBBtn
                        className="cancel-button"
                        onClick={() =>
                          this.setState({ homeCheck: !this.state.homeCheck })
                        }
                      >
                        Cancel
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol lg="6" md="6" sm="12">
                      <MDBBtn onClick={() => (window.location.href = "/")}>
                        Continue
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
          ) : null}

          <MDBCol size="12">
            <MDBContainer className="personal-details-section ready-switch-tile">
              <MDBRow>
                {/* Spacing */}
                <MDBCol size="2" />
                {/* Thank You Content */}
                <MDBCol md="12" lg="8">
                  <div className="white-curved-tile switch-ready">
                    {/* Thank You Title */}
                    <div className="text-center">
                      <h3>
                        Thanks for
                        <br />
                        switching to bright!
                      </h3>
                    </div>

                    {/* Thank You Message */}
                    <div className="text-center">
                      <p>
                        We will contact your existing supplier and handle your
                        switch from here. There's nothing more for you to do,
                        apart from setup your account.
                        <h5 className="myAccount-link mt-2">
                          <a href="#registerForm">Set up my account now!</a>
                        </h5>
                      </p>
                    </div>

                    {/* App Store Images */}
                    <div className="my-5">
                      <MDBRow>
                        {/* Apple */}
                        <MDBCol lg="6" md="6" sm="6" className="text-center">
                          <a href="https://apps.apple.com/app/id1500409256">
                            <img
                              src={require("../../../../images/ios.png")}
                              style={{ height: 65 }}
                            />
                          </a>
                        </MDBCol>
                        {/* Google */}
                        <MDBCol lg="6" md="6" sm="6" className="text-center">
                          <a href="https://play.google.com/store/apps/details?id=com.brightapp">
                            <img
                              src={require("../../../../images/google.png")}
                              style={{ height: 65 }}
                            />
                          </a>
                        </MDBCol>
                      </MDBRow>
                    </div>

                    {/* Bright Home */}
                    <div>
                      <a href="/">
                        <MDBCol>
                          <MDBRow className="justify-content-center">
                            <img
                              src={require("../../../../images/home.png")}
                              style={{ height: 60 }}
                            />

                            <h6 className="mt-4 ml-2">bright homepage</h6>
                          </MDBRow>
                        </MDBCol>
                      </a>
                    </div>
                  </div>
                </MDBCol>
                {/* Spacing */}
                <MDBCol size="2" />
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBRow>

        {/* Register */}
        <MDBRow id="registerForm">
          <MDBCol size="12">
            <MDBContainer className="personal-details-section ready-switch-tile">
              <MDBRow>
                {/* Spacing */}
                <MDBCol size="2" />
                {/* Content */}
                <MDBCol md="12" lg="8">
                  <div className="white-curved-tile switch-ready">
                    {/* Title */}
                    <div className="text-center">
                      <h3>Register your account today, it's a breeze.</h3>
                    </div>

                    {/* Register Text */}
                    <div className="text-center">
                      <p>
                        We recommend registering now, so you can access your
                        account instantly{" "}
                        <a
                          href={`/${this.state.jurisdiction}/account/login`}
                          className="myAccount-link"
                        >
                          here
                        </a>{" "}
                        or via our app.
                      </p>
                    </div>

                    {/* Register Form */}
                    <div>
                      {/* Error Message */}
                      {this.state.errorMessage != false ? (
                        <div>
                          <MDBAlert color="danger" className="my-5" dismiss>
                            <strong>Oops!</strong> Please check your fields
                            below.
                          </MDBAlert>
                        </div>
                      ) : null}

                      {/* API Loader */}
                      {this.state.loading ? <Loader /> : null}

                      <form id="register">
                        {/* First Name */}
                        <label className="input-label">First Name</label>
                        <MDBInput
                          id="firstName"
                          pattern="[A-Za-z]{1,32}"
                          label="Your first name"
                          outline
                          required
                          onChange={this.handleChange("firstName")}
                        />

                        {/* Surname */}
                        <label className="input-label">Last Name</label>
                        <MDBInput
                          id="lastName"
                          pattern="[A-Za-z]{1,32}"
                          label="Your last name"
                          outline
                          required
                          onChange={this.handleChange("lastName")}
                        />

                        {/* Username (email) */}
                        <label className="input-label">Email Address</label>
                        <MDBInput
                          id="username"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                          label="Your email address"
                          outline
                          required
                          onChange={this.handleChange("username")}
                        />

                        {/* Password */}
                        <MDBRow>
                          <MDBCol size="11" className="p-0">
                            <label className="input-label">Password</label>
                            <MDBInput
                              id="password"
                              label="Choose a password"
                              type="password"
                              outline
                              required
                              pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.€]).{8,}$"
                              onChange={this.handleChange("password")}
                            />
                          </MDBCol>
                          <MDBCol size="1" className="p-1">
                            <MDBBtn className="tooltip-container">
                              <MDBIcon
                                className="pt-5"
                                icon="info-circle"
                                onClick={() =>
                                  this.setState({
                                    passwordTip: !this.state.passwordTip,
                                  })
                                }
                              />
                              {this.state.passwordTip == true ? (
                                <span className="tooltip-content">
                                  <img
                                    className="brand-image"
                                    src={require("../../../../images/global/bullet-large.png")}
                                  />
                                  <p>
                                    Your password must be:
                                    <ul>
                                      <li>Between 8-30 characters long.</li>
                                      <li>At least 1 uppercase letter.</li>
                                      <li>At least 1 lowercase letter.</li>
                                      <li>
                                        At least 1 special character
                                        (#?!@$%^&*-.).
                                      </li>
                                      <li>At least 1 number.</li>
                                    </ul>
                                  </p>
                                </span>
                              ) : null}
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>

                        {/* Register Button */}
                        <MDBBtn className="btn" onClick={this.register}>
                          Register Now!
                        </MDBBtn>
                      </form>
                    </div>
                  </div>
                </MDBCol>
                {/* Spacing */}
                <MDBCol size="2" />
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.signin.isLoading,
    token: store.signin.token,
    error: store.signin.error,
  };
};

const actionCreators = {
  register: signinActions.register,
};

export default connect(
  mapStateToProps,
  actionCreators
)(_FormSwitchConfirmation);
