import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow, NavLink } from "mdbreact";
import LegalTabs from "../../../components/About/LegalTabs";


import FixedNavDark from "../../../components/Shared/FixedNavDark";
import $ from "jquery";


class NIFuelMix extends Component {
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.help);
  }

  help =() =>{
    if (window.pageYOffset <50)
    {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark /> */}

        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>Policies & Terms</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow>
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            {/* <LegalTabs /> */}
            <ul className='account-navigation-content ml-5'>
          {/* Dashboard */}
          <li>
            {/* <NavLink
              activeClassName='is-active'
              exact
              to={`/ni/sources`}
            >
              Fuel Mix
            </NavLink> */}
            <LegalTabs/>
          </li>
          </ul>
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5 pb-5'>
            <h3>Sources - Fuel Mix</h3>
            <p></p>
            <p>Bright intends to supply customers with 100% renewable electricity.    </p>
            <p>Electricity suppliers are required to provide their customers with information about which fuels are used to generate the electricity they use. This information, which is called the fuel mix, is displayed on your electricity bill.  The purpose of the table is to allow customers to understand the sources of their electricity supply and its impact on the environment. </p>
            <p> The fuel mix information is always displayed historically as it takes time to collect, verify and report the information.  The current fuel mix table is for the period from 1 January 2019 to 31 December 2019.  This report is published each year in September by the Utility Regulator.  Information on the bright fuel mix will be reported for the 2020 reporting period onwards.  </p>
            
            <table className="fuelmix-table">
              <colgroup>
              <col className="w-50 text-left"></col>
              <col></col>
              <col></col>
              </colgroup>
            <thead><tr><th colSpan="3" className="text-center montserrat-Bold">All Island Market Disclosure Label <br/><br/>Applicable Period: January 2019 to December 2019</th></tr></thead><tbody>
            <tr><td rowSpan="2" className="text-left">Electricity supplied has been sourced from the following fuels: </td><td colspan="2" className="text-center">% of total </td></tr>
            <tr><td className="montserrat-Bold">Bright did not supply during this period</td><td>Average for All Island Market (for comparison) </td></tr>
            <tr><td className="text-left">Coal </td><td> </td><td>2.6% </td></tr>
            <tr><td className="text-left">Natural Gas </td><td></td><td>37.9% </td></tr>
            <tr><td className="text-left">Nuclear </td><td> </td><td>0.0% </td></tr>
            <tr><td className="text-left">Renewable </td><td> </td><td>54.0% </td></tr>
            <tr><td className="text-left">Peat </td><td> </td><td>4.3% </td></tr>
            <tr><td className="text-left">Oil </td><td></td><td>0.0% </td></tr>
            <tr><td className="text-left">EU Fossil </td><td></td><td>0.0% </td></tr>
            <tr><td className="text-left">Other </td><td></td><td>1.2% </td></tr>
            <tr><td className="text-left">Total </td><td></td><td>100.0% </td></tr>
            <tr><td colSpan="3" className="montserrat-Bold text-left">Environmental Impact </td></tr>
            <tr><td className="text-left">CO2 </td><td></td><td>254 gCO2/kWh </td></tr>
           </tbody></table>
           <p></p>
           <p>*Note that bright was not an active supplier for this reporting period </p>
           <p>For more information about fuel mix in Ireland please visit <a target="_blank"  href="https://www.sem-o.com"><u>www.sem-o.com.</u></a></p>
           <p>For more information about fuel mix in Great Britain please visit <a target="_blank"  href="https://www.ofgem.gov.uk"><u>www.ofgem.gov.uk.</u></a></p>
           
            <div className='links'>
              {/* STANDARD Ts&Cs */}
              <hr id="standard"/>
              <div id="standardtsandcs">
                  </div>
                

              
              

            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NIFuelMix;
