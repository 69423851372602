import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBAlert,
} from "mdbreact";
import Loader from "../../../components/Shared/Loader";
import $ from "jquery";

// Redux
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { keypadVendActions } from "../../../redux/actions/keypadVendActions";

const LoadConfig = require("../../../config");
const config = LoadConfig.default;

export const VendDetailsForm = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const keypadVendServiceStatus = useSelector((state) => state.keypadVend);

  const [payAmount, setPayAmount] = useState(10);
  const [kpn, setKpn] = useState(null);
  const [email, setEmail] = useState();
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);

  const help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  };

  useEffect(() => {
    return () => {};
  }, [keypadVendServiceStatus]);

  useEffect(() => {
    dispatch(keypadVendActions.getKeypadStatus());
    help();
    window.addEventListener("scroll", help());

    return () => {
      window.removeEventListener("scroll", help());
    };
  }, []);

  const moveToCardPayment = () => {
    let validation = $("#vendDetails")[0].checkValidity();
    if (validation) {
      CreatePaymentIntent();
      setError(false);
    } else {
      setError(true);
    }
  };

  async function CreatePaymentIntent() {
    setLoader(true);
    var fmtKPN = "982602500" + kpn;

    const details = {
      amount: payAmount,
      kpn: fmtKPN,
      email: email,
      description: `KPN: ${fmtKPN} - Keypad Top-up (user not logged in - Web).`,
    };

    let paramsObject = {};
    await axios({
      method: "post",
      url: `${config.apiUrl}/keypadvend/vendintent`,
      data: details,
    }).then((response) => {
      console.log(response.data);
      paramsObject = {
        secret: response.data.intent.clientSecret,
        id: response.data.id,
        intentId: response.data.intent.id,
        amount: payAmount,
        kpn: fmtKPN,
        email: email,
      };
    });
    history.push({
      pathname: "/ni/keypadtopuppayment",
      state: { data: paramsObject },
    });
  }

  return (
    <div className="login-page">
      <MDBContainer className="switch-today-container">
        {/* Loader */}
        {loader ? <Loader /> : null}

        <MDBRow className="brightTeal-text">
          <MDBCol md="3" lg="3"></MDBCol>
          <MDBCol sm="12" md="6" lg="6" className="text-center">
            <div>
              {keypadVendServiceStatus.keypadServiceData ? (
                <h2>Purchase a top-up</h2>
              ) : (
                <h2>Top-up Service Unavailable</h2>
              )}
              {/* Error */}
              {error ? (
                <MDBAlert color="danger" className="my-4" dismiss>
                  <strong>Oops!</strong> Please check your fields below.
                </MDBAlert>
              ) : null}

              {keypadVendServiceStatus.keypadServiceData ? (
                //show when topup service available
                <MDBRow className="login-form-container white-curved-tile">
                  <MDBCol size="12">
                    <form id="vendDetails">
                      <div>
                        <MDBRow>
                          {/* - */}
                          <MDBCol size="2">
                            {payAmount != 5 ? (
                              <div className="text-center">
                                <i
                                  class="fas fa-minus fa-2x mt-4"
                                  onClick={() => setPayAmount(payAmount - 5)}
                                />
                              </div>
                            ) : null}
                          </MDBCol>

                          {/* Amount */}
                          <MDBCol size="8" className="text-center cocogoose">
                            <p style={{ fontSize: 25, marginTop: 20 }}>
                              £ {payAmount}
                            </p>
                          </MDBCol>

                          {/* + */}
                          <MDBCol size="2">
                            {payAmount != 100 ? (
                              <div className="text-center">
                                <i
                                  className="fas fa-plus fa-2x mt-4"
                                  onClick={() => setPayAmount(payAmount + 5)}
                                />
                              </div>
                            ) : null}
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol size="12" className="mt-4 text-center mb-0">
                            <label>
                              Please enter the last 10 digits of your KPN
                            </label>
                            <p className="mt-3">982602500</p>
                          </MDBCol>
                          <MDBCol size="12" className="mt-0">
                            <MDBInput
                              label="0123456789"
                              type="text"
                              name="kpn"
                              onChange={(e) => setKpn(e.target.value)}
                              maxlength={10}
                              className="form-control text-center"
                              required
                            />
                          </MDBCol>
                        </MDBRow>

                        <MDBRow center>
                          <MDBCol size="12">
                            <MDBInput
                              label="Email"
                              type="email"
                              name="email"
                              onChange={(e) => setEmail(e.target.value)}
                              pattern="^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                              className="form-control text-center"
                              required
                            />
                            <MDBBtn
                              className="w-100"
                              onClick={() => moveToCardPayment()}
                            >
                              PAY NOW
                            </MDBBtn>{" "}
                            {/*onClick={() => moveToCardPayment()} */}
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </form>
                  </MDBCol>
                </MDBRow>
              ) : (
                //Show when topup service unavailable
                <MDBRow className="login-form-container white-curved-tile">
                  <MDBCol size="12">
                    <form id="vendUnavailable">
                      <div>
                        <MDBRow>
                          <p className="mt-5">
                            Sorry our top-up service is temporarily unavailable.
                            <br />
                            While we're working hard on making this available
                            again, you can top-up via:
                            <br />
                          </p>
                          <MDBCol size="12" className="text-enter">
                            <ul className="bullet-list-keypad text-left">
                              <li>
                                Phone -{" "}
                                <a href="tel:028 95442290">02895442290</a>
                                <br />
                                Monday - Friday: 8am - 6pm
                              </li>
                              <li>
                                <a
                                  href="https://www.postoffice.co.uk/branch-finder"
                                  target="blank"
                                >
                                  Click here for your nearest Post Office Branch
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://storelocator.payzone.co.uk/"
                                  target="blank"
                                >
                                  Click here for your nearest Payzone Branch
                                </a>
                              </li>
                            </ul>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </form>
                  </MDBCol>
                </MDBRow>
              )}
            </div>
          </MDBCol>
          <MDBCol size="3"></MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};
