import axios from "axios";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const contactService = {
  sendMail,
};

// Login
function sendMail(details, jurisdiction) {
  return axios({
    method: "post",
    url: `${config.apiUrl}/contact/send/${jurisdiction}`,
    data: details,
  }).then((response) => {
    return response;
  });
}
