import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Loader from "../../../components/Shared/Loader";
import NavigationSection from "../../../components/CustomerAccount/AccountNavigation";
import { sessionExpired } from "../../../redux/helpers/sessionExpired";

// Redux
import { connect } from "react-redux";
import { faqActions } from "../../../redux/actions";

class SupportSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      helpful: false,
      loading: false,
      jurisdiction: "",
      phoneNo: "",
    };
  }

  componentDidMount() {
    sessionExpired();

    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    this.setState({
      jurisdiction: jurisdiction,
    });

    if (jurisdiction == "roi") {
      this.setState({ phoneNo: "" });
    } else {
      this.setState({ phoneNo: "" });
    }

    this.props.getFAQS(jurisdiction);
  }

  // API Loader
  async componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  render() {
    return (
      <div className="account-dashboard-container">
        <div className="account-details-container">
          <div className="make-payment-background">
            <NavigationSection />

            <MDBContainer className="make-payment-background">
              <MDBContainer className="main-content support">
                {/* API Loader */}
                {this.state.loading ? <Loader /> : null}

                {/* Page Title */}
                <MDBRow>
                  <MDBCol size="1">
                    <a href={`/${this.state.jurisdiction}/account/more`}>
                      <i className="fas fa-chevron-left fa-2x" />
                    </a>
                  </MDBCol>
                  <MDBCol size="10" className="text-center ">
                    <h1 className="mt-1">Support</h1>
                  </MDBCol>
                  <MDBCol size="1"></MDBCol>
                </MDBRow>

                {/* Sub Title/Text */}
                <MDBRow>
                  <MDBCol md="12">
                    <p>
                      <b>FAQs</b>
                      <br /> View our frequently asked questions below:
                    </p>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  {/* FAQ Items */}
                  <MDBCol md="12">
                    <div className="white-curved-tile ">
                      <MDBRow>
                        <MDBCol md="12">
                          {this.props.faqData && this.props.faqData[0] != null
                            ? this.props.faqData.map((faq) => (
                                <Accordion
                                  className="support-pointer"
                                  allowZeroExpanded={true}
                                >
                                  <AccordionItem>
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        <p className="faq-question">
                                          <b>+</b>
                                          {faq.title}
                                        </p>
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <p>{faq.body}</p>

                                      <a
                                        href={faq.link}
                                        className="blue-text faq-link"
                                      >
                                        {faq.link}
                                      </a>
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                </Accordion>
                              ))
                            : null}
                        </MDBCol>
                      </MDBRow>
                    </div>

                    {/* Helpful? */}
                    <MDBRow className="helpful-container">
                      <MDBCol className="text-center" md="12">
                        <p>Was this helpful?</p>
                        <MDBBtn
                          className="btn"
                          onClick={() => this.setState({ helpful: false })}
                        >
                          Yes
                        </MDBBtn>
                        <MDBBtn
                          className="btn"
                          onClick={() => this.setState({ helpful: true })}
                        >
                          No
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>

                  {/* Not Helpful Section */}
                  {this.state.helpful == true ? (
                    <MDBCol>
                      <div className="white-curved-tile text-center my-5">
                        <div className="faq-section">
                          <p>
                            <b>Contact Us</b>
                            <br />
                            <br />
                            If you haven't found the answer you were looking
                            for?
                            <br />
                            You can contact us here. We are happy to help.
                          </p>
                          <a
                            href={`/${this.state.jurisdiction}/about/contactus`}
                          >
                            <MDBBtn className="btn">Contact us</MDBBtn>
                          </a>
                        </div>
                      </div>
                    </MDBCol>
                  ) : null}
                </MDBRow>
              </MDBContainer>
            </MDBContainer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.faq.isLoading,
    faqData: store.faq.faqData,
  };
};

const actionCreators = {
  getFAQS: faqActions.getFAQs,
};

export default connect(mapStateToProps, actionCreators)(SupportSection);
