import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBRow, MDBContainer } from "mdbreact";

export class _FormGettingReadyToSwitch extends Component {
  state = {
    jurisdiction: "roi",
    homeCheck: false,
  };

  componentDidMount() {
    localStorage.setItem("accountUpdated", false);
    localStorage.setItem("electricityUpdated", false);
    localStorage.setItem("servicesUpdated", false);
    localStorage.setItem("paymentUpdated", false);
    localStorage.setItem("summaryUpdated", false);
    localStorage.removeItem("switchID");
  }

  render() {
    return (
      <div className='quote-page switch-page'>
        {/* Background Image */}
        <div className='device-image' />
        <div className='quote-content-container'>
          <MDBContainer className='personal-details-section'>
            <MDBRow className='mt-4'>
              <MDBCol size='12'>
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <div className='header-logo-icon'></div>
                </a>
              </MDBCol>

              {/* Home message */}
              {this.state.homeCheck ? (
                <div className='modalBG'>
                  <div className='modalBox'>
                    <div className='white-curved-tile text-center'>
                      <h4 className='mt-5'>
                        All progress will be lost! <br />
                        Are you sure?
                      </h4>
                      <MDBRow>
                        <MDBCol lg='6' md='6' sm='12'>
                          <MDBBtn
                            className='cancel-button'
                            onClick={() =>
                              this.setState({
                                homeCheck: !this.state.homeCheck,
                              })
                            }
                          >
                            Cancel
                          </MDBBtn>
                        </MDBCol>
                        <MDBCol lg='6' md='6' sm='12'>
                          <MDBBtn onClick={() => (window.location.href = "/")}>
                            Continue
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </div>
                </div>
              ) : null}

              <MDBCol size='12'>
                {/* Additional Services Section */}
                <MDBRow>
                  <MDBCol md='12' className='switch-navigation-container'>
                    <div className='border-light'>
                      <img
                        className='brand-image show-for-medium'
                        src={require("../../../../images/quote/quote-nav-1.png")}
                      />
                      <img
                        className='brand-image hide-for-medium'
                        src={require("../../../../images/quote/progress-bar-1.png")}
                      />
                    </div>
                  </MDBCol>
                </MDBRow>

                {/* Get Ready to Switch Container */}
                <MDBContainer className='personal-details-section ready-switch-tile'>
                  <MDBRow>
                    <MDBCol size='2' />

                    <MDBCol md='12' lg='8'>
                      <div className='white-curved-tile text-center switch-ready'>
                        <h3>Get ready to switch!</h3>
                        <p>
                          For a speedy switch and account set up here are a few
                          things you will need to speed up the process
                        </p>

                        <ul className='bullet-list text-left'>
                          <li>Your recent bill</li>
                          <li>A meter reading</li>
                          <li>Your bank details</li>
                        </ul>

                        <a href={`/${this.state.jurisdiction}/switch/account`}>
                          <MDBBtn className='btn'>Continue</MDBBtn>
                        </a>
                      </div>
                    </MDBCol>

                    <MDBCol size='2' />
                  </MDBRow>
                </MDBContainer>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
    );
  }
}

export default _FormGettingReadyToSwitch;
