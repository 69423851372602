import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBListGroup,
  MDBListGroupItem,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from "mdbreact";

// Redux Addins
import { connect } from "react-redux";
import { faqActions } from "../../../redux/actions";

class NIFAQ extends React.Component {
  state = {
    modal: false,
    modalTitle: "",
    modalBody: "",
    modalLink: ""
  };

  // Page Load
  componentDidMount() {
    this.props.getFAQs();
  }

  // Toggle Modal
  toggleModal = (title, body, link) => {
    this.setState({
      modal: !this.state.modal,
      modalTitle: title,
      modalBody: body,
      modalLink: link
    });
  };

  render() {
    return (
      <div>
        {/* FAQ Modal */}
        <MDBModal isOpen={this.state.modal} toggle={this.toggleModal}>
          <MDBModalHeader>{this.state.modalTitle}</MDBModalHeader>
          <MDBModalBody>{this.state.modalBody}</MDBModalBody>
          <MDBModalBody className='faqLink'>
            {this.state.modalLink}
          </MDBModalBody>
          <MDBBtn
            className='btn-bright mx-5 my-4'
            onClick={() => this.setState({ modal: false })}
          >
            Back to FAQ's
          </MDBBtn>
        </MDBModal>

        {/* Title */}
        <MDBRow>
          <MDBCol size='3' />
          <MDBCol size='6' className='mt-5 pl-4'>
            <h4>FAQs</h4>
            <p>View our frequently asked questions below:</p>
          </MDBCol>
          <MDBCol size='3' />
        </MDBRow>

        {/* FAQ List */}
        <MDBRow>
          <MDBCol size='3' />
          <MDBCol size='6' className='mt-2 mb-5'>
            {/* List Container */}
            <MDBCard style={{ width: "100%" }}>
              <MDBCardBody>
                {/* List */}
                <MDBListGroup className='faqText'>
                  {this.props.faqData && this.props.faqData[0] != null
                    ? this.props.faqData.map(item => (
                        <MDBListGroupItem
                          className='faqList'
                          onClick={() =>
                            this.toggleModal(item.title, item.body, item.link)
                          }
                        >
                          <i className='fas fa-plus mr-3' />
                          {item.title}
                          <hr className='faqHr' />
                        </MDBListGroupItem>
                      ))
                    : null}
                </MDBListGroup>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol size='3' />
        </MDBRow>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    faqData: store.faq.faqData,
    isLoading: store.faq.isLoading
  };
};

const actionCreators = {
  getFAQs: faqActions.getFAQs
};

export default connect(mapStateToProps, actionCreators)(NIFAQ);
