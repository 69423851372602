import React from "react";
import { MDBRow, MDBCol } from "mdbreact";

export const PaymentFailure = () => {
  const jurisdiction = "ni";

  return (
    <div>
      <MDBRow className='my-3'>
        <MDBCol>
          <h4>Payment Unsuccessful</h4>
        </MDBCol>
      </MDBRow>
      <MDBRow className='text-center'>
        <MDBCol>
          <img
            src={require("../../../../../images/account/error.png")}
            style={{ width: "30%" }}
          />

          <p className='my-5'>
            <b>Sorry!</b> There has been an issue with your payment - if you
            would like to proceed with your switch please give us a call on:
            <br />
            <b>028 95442290</b>
          </p>
        </MDBCol>
      </MDBRow>
    </div>
  );
};
