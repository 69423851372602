import React, { Component } from "react";
import { MDBRow } from "mdbreact";
import { NavLink } from "react-router-dom";

class LegalTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split('/');
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris
    });
  }

  render() {
    return (
      <MDBRow>
        <ul className='account-navigation-content ml-5'>
          {/* Dashboard */}
          <li>
            <NavLink
              activeClassName='is-active'
              exact
              to={`/${this.state.jurisdiction}/codesofpractice`}
            >
              Codes of practice
            </NavLink>
          </li>

          {/* Terms & Conditions */}
          <li>
            <NavLink
              activeClassName='is-active'
              exact
              to={`/${this.state.jurisdiction}/terms`}
            >
              Terms & conditions
            </NavLink>
          </li>

          {/* Privacy Statement */}
          <li>
            <NavLink
              activeClassName='is-active'
              exact
              to={`/${this.state.jurisdiction}/privacystatement`}
            >
              Privacy statement
            </NavLink>
          </li>

          {/* Our Policies */}
          <li>
            <NavLink activeClassName='is-active' exact to={`/${this.state.jurisdiction}/ourpolicies`}>
              Our policies
            </NavLink>
          </li>

          { this.state.jurisdiction == 'ni' ? 
           <li>
            <NavLink
              activeClassName='is-active'
              exact
              to={`/${this.state.jurisdiction}/energytheft`}
            >
              Energy Theft
            </NavLink>
          </li>
          : null }
           { this.state.jurisdiction == 'ni' ? 
          <li>
            <NavLink activeClassName='is-active' exact to={`/${this.state.jurisdiction}/sources`}>
              Sources
            </NavLink>
          </li>
          : null}
          
        </ul>
      </MDBRow>
    );
  }
}
export default LegalTabs;
