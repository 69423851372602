import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol, MDBContainer, MDBBtn, MDBAlert } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AgencyPaymentDetails } from "./AgencyPaymentDetails";
import { sessionExpired } from "../../../../redux/helpers/sessionExpired";
import axios from "axios";
import Loader from "../../../../components/Shared/Loader";
const LoadConfig = require("../../../../config");
const config = LoadConfig.default;

export const AgencyPayment = (props) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const key = config.stripeKey;
  const stripePromise = loadStripe(key);
  const [showLoader, setShowLoader] = useState(false);
  const [showPayment, setShowPayment] = useState(true);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [billAmount] = useState(props.location.state.billAmount);
  const [jurisdiction, setJurisdiction] = useState("");
  const [currency, setCurrency] = useState("");
  const [user, setUser] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [showOtherAmount, setShowOtherAmount] = useState(false);
  const [secret, setSecret] = useState("");
  const [paymentIntent, setPaymentIntent] = useState("");

  useEffect(() => {
    sessionExpired();

    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    setJurisdiction(jurisdiction);

    if (jurisdiction == "roi") {
      setCurrency("€");
    } else {
      setCurrency("£");
    }

    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;

    setUser(user.data.authenticateModel);

    setAccountNumber(jModel.number);
  }, []);

  function onSubmit(data) {
    // console.log(data);
    let amount = 0;
    if (data.amount === "otherAmount") {
      amount = parseFloat(data.otherPaymentAmount).toString();
    } else {
      amount = data.amount;
    }

    createPaymentIntent(amount);
  }

  async function createPaymentIntent(amount) {
    setShowLoader(true);

    let details = {
      jurisdiction: jurisdiction,
      amount: amount,
      email: user.username,
      description: "Jf Acc Num: " + accountNumber + " - Landlord Payment.",
    };

    await axios({
      method: "post",
      url: `${config.apiUrl}/payments/intentDesc`,
      data: details,
    }).then((response) => {
      setSecret(response.data.client_secret);
      setPaymentIntent(response.data.id);

      setShowLoader(false);
      setShowPayment(false);
      setShowPaymentDetails(true);
    });
  }

  return (
    <div className="account-dashboard-container">
      <div className="account-details-container">
        <div className="make-payment-background">
          <MDBContainer className="main-content payment-history">
            {showLoader ? <Loader /> : null}

            {/* Page title */}
            <MDBRow className="mb-5">
              <MDBCol size="1" className="align-self-center">
                <a href={`/${jurisdiction}/account/landlorddashboard`}>
                  <i className="fas fa-chevron-left fa-2x" />
                </a>
              </MDBCol>
              <MDBCol size="9" className="text-center pt-5" center>
                <h3>Make a payment</h3>
              </MDBCol>
              <MDBCol size="2">
                <img
                  src={require("../../../../images/global/bright-logo-dark.png")}
                />
              </MDBCol>
            </MDBRow>

            {/* Payment section */}
            {showPayment ? (
              <div className="white-curved-tile text-center">
                <h2>Your Total Bill Amount</h2>
                <h2 className="price-figure">
                  {currency}
                  {billAmount}
                </h2>
                <MDBRow center>
                  <MDBCol lg="6" md="6" sm="12" className="text-left">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {/* Balance */}
                      <MDBAlert className="grey-highlight brightTeal-text">
                        <input
                          {...register("amount", {
                            required: true,
                            valueAsNumber: true,
                          })}
                          type="radio"
                          value={billAmount}
                          className="paymentRadio"
                          onClick={() => setShowOtherAmount(false)}
                        />
                        Balance: {currency}
                        {billAmount}
                      </MDBAlert>

                      {/* Other amount */}
                      <MDBAlert className="grey-highlight brightTeal-text">
                        <input
                          {...register("amount", { required: true })}
                          type="radio"
                          value="otherAmount"
                          className="paymentRadio"
                          onClick={() => setShowOtherAmount(true)}
                        />
                        Pay another amount
                        {showOtherAmount ? (
                          <div>
                            <input
                              className="mx-3 my-3"
                              placeholder={`${currency}5.00`}
                              type="float"
                              {...register("otherPaymentAmount", {
                                required: showOtherAmount,
                                valueAsNumber: true,
                                min: 5,
                              })}
                            />
                            {errors.otherPaymentAmount ? (
                              <p className="red-text">
                                You must enter a minimum payment amount of{" "}
                                {currency}5
                              </p>
                            ) : null}
                          </div>
                        ) : null}
                      </MDBAlert>
                      {errors.amount ? (
                        <p className="red-text">
                          Please select a payment amount
                        </p>
                      ) : null}

                      <MDBRow center>
                        <MDBBtn type="submit">Make Payment</MDBBtn>
                      </MDBRow>
                    </form>
                  </MDBCol>
                </MDBRow>
              </div>
            ) : null}

            {showPaymentDetails ? (
              <div className="white-curved-tile text-center">
                <Elements stripe={stripePromise}>
                  <AgencyPaymentDetails
                    values={{
                      secret: secret,
                      paymentIntent: paymentIntent,
                      jurisdiction: jurisdiction,
                      name: user.firstName + " " + user.lastName,
                      email: user.username,
                    }}
                  />
                </Elements>
              </div>
            ) : null}
          </MDBContainer>
        </div>
      </div>
    </div>
  );
};
