import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import NavigationSection from "../../../components/CustomerAccount/AccountNavigation";
import { sessionExpired } from "../../../redux/helpers/sessionExpired";
const LoadConfig = require("../../../config");
const config = LoadConfig.default;

class YourTaiffSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      howToTip: false,
      jurisdiction: "",
      tariffName: "",
      meterType: "",
      standingCharge: "",
      unitRate: "",
      dayRate: "",
      nightRate: "",
      duosGroup: "",
    };
  }

  componentDidMount() {
    sessionExpired();

    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    this.setState({
      jurisdiction: jurisdiction,
    });

    // Get user data
    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;
    let electricityProduct = jModel.electricityProduct.electricity;
    let tariffRates = electricityProduct.unitRates;

    this.setState({
      tariffName: electricityProduct.display,
      meterType: electricityProduct.code,
      standingCharge: electricityProduct.standingCharge,
      unitRate: tariffRates.standard,
      dayRate: tariffRates.day,
      nightRate: tariffRates.night,
      duosGroup: electricityProduct.duosGroup,
    });
  }

  render() {
    return (
      <div className="account-dashboard-container">
        <div className="account-details-container">
          <NavigationSection />
          <div className="dark-background">
            <MDBContainer className="main-content your-tariff">
              {/* Page Title */}
              <MDBRow>
                <MDBCol size="1">
                  <a href={`/${this.state.jurisdiction}/account/more`}>
                    <i className="fas fa-chevron-left fa-2x white-text" />
                  </a>
                </MDBCol>
                <MDBCol size="10" className="text-penceer ">
                  <h1 className="mt-1 white-text">Your Tariff</h1>
                </MDBCol>
                <MDBCol size="1"></MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol md="12">
                  <div className="white-curved-tile">
                    {/* Tariff name */}
                    <div>
                      <p className="tariff-content">
                        {/* Left - Title */}
                        <span className="text-left">Your tariff</span>
                        {/* Right - Price */}
                        <b className="text-right">{this.state.tariffName}</b>
                      </p>
                    </div>

                    {/* Meter Type */}
                    <div>
                      <p className="tariff-content">
                        {/* Left - Title */}
                        <span className="text-left">Meter type</span>
                        {/* Right - Metet type */}
                        {this.state.meterType.includes("E1R-VAR") ? (
                          <b className="text-right">24 Hour</b>
                        ) : (
                          <b className="text-right">Eco Seven</b>
                        )}
                      </p>
                    </div>

                    {/* Unit rate */}
                    <div>
                      <p className="tariff-content">
                        {/* Left - Title */}
                        <span className="text-left">
                          Unit rate (pence per kwh)
                        </span>
                        {/* Right - Price */}
                        <b className="text-right">{this.state.unitRate}</b>
                      </p>
                    </div>

                    {/* Standing charge */}
                    <div>
                      <p className="tariff-content">
                        {/* Left - Title */}
                        <span className="text-left">
                          Standing charge (£ per year)
                        </span>
                        {/* Right - Price */}
                        <b className="text-right">
                          {((this.state.standingCharge * 365) / 100).toFixed(2)}
                        </b>
                      </p>
                    </div>

                    {/* VAT Disclaimer */}
                    <span className="more-info">Prices all include VAT</span>
                  </div>
                </MDBCol>
              </MDBRow>

              <MDBRow className="justify-content-penceer">
                <p className="white-text">
                  View our full tariff break down{" "}
                  <a
                    className="brightGreen-text"
                    href={`/${this.state.jurisdiction}/about/ourtariff`}
                  >
                    here
                  </a>
                </p>
              </MDBRow>
            </MDBContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default YourTaiffSection;
