import React, { useState, useEffect } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBAlert,
  MDBContainer,
  MDBInput,
} from "mdbreact";
import { PaymentDetailsForm } from "./MakePayment/PaymentDetailsForm";
import { sessionExpired } from "../../../redux/helpers/sessionExpired";

// Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/Shared/Loader";
import NavigationSection from "../../../components/CustomerAccount/AccountNavigation";

const LoadConfig = require("../../../config");
const config = LoadConfig.default;

export const Payment = (props) => {
  var key = props.location.state.jur == "roi" ? config.stripeKey : config.niStripeKey;
  const stripePromise = loadStripe(key);
  const [userEmail, setUserEmail] = useState();
  const [billAvailable, setBillAvailable] = useState();
  const [jurisdiction, setJurisdiction] = useState();
  const [nextPaymentDate, setNextPaymentDate] = useState();
  const [nextPaymentAmount, setNextPaymentAmount] = useState();
  const [userToken, setUserToken] = useState();
  const [overAllBalance, setOverAllBalance] = useState();
  const [showMinPayError, setShowMinPayError] = useState(false);
  const [accountNum, setAccountNum] = useState();
  const [directDebitCustomer, setDirectDebitCustomer] = useState(false);

  const [showPayment, setShowPayment] = useState(true);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showBalancePayment, setShowBalancePayment] = useState(false);
  const [showOtherAmountInput, setShowOtherAmountInput] = useState(false);
  const [currency, setCurrency] = useState(false);
  const [checkedCurrBalance, setCheckedCurrBalance] = useState();
  const [checkedAccBalance, setcheckedAccBalance] = useState(false);
  const [checkedOtherAmount, setCheckedOtherAmount] = useState(false);
  const [currentBillBalance, setCurrentBillBalance] = useState();

  useEffect(() => {
    sessionExpired();

    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    setJurisdiction(jurisdiction);

    if (jurisdiction == "roi") {
      setCurrency("€");
    } else {
      setCurrency("£");
    }

    // Get information
    let user = JSON.parse(localStorage.getItem("user"));
    let userEmail = user.data.authenticateModel.username;
    let overallBalanceCheck = user.data.overallAccountBalance;
    setOverAllBalance(overallBalanceCheck);

    setUserEmail(userEmail);

    let jModel = user.data.jModel;
    let accountNum = jModel.number;
    setAccountNum(accountNum);

    //check if they are a DD customer
    try {
      let dd = jModel.electricityProduct.electricity.directDebit;
      if (dd) {
        setDirectDebitCustomer(true);
      }
    }
    catch{}

    try {
      if (jModel.billsList.length > 0) {
        setBillAvailable(true);

        // Payment amount
        let bills = jModel.billsList;
        let latestBill = bills[0];
        let amount = 0;

        amount = latestBill.balance.toFixed(2);
        setCurrentBillBalance(amount);

        if (overallBalanceCheck > amount) {
          setShowBalancePayment(true);
          //handle a default selection
          handleCheckedChange("previousBalance");
          setNextPaymentAmount(overallBalanceCheck.toFixed(2));
        } else {
          setShowBalancePayment(false);
          handleCheckedChange("balance");
          setNextPaymentAmount(amount);
        }
        // Next payment date
        let nextPaymentDate = latestBill.dueDt
          .toString()
          .slice(0, 10)
          .split("-")
          .reverse()
          .join("/");

        // User token for axios request
        let userToken = user.data.authenticateModel.token;

        setNextPaymentDate(nextPaymentDate);
        // setNextPaymentAmount(amount);
        setUserToken(userToken);
      } else {
        setBillAvailable(false);
      }
    } catch { }
  }, []);

  const getBillPos = (bills) => {
    var billId = 0;
    for (var x = 0; x < bills.length; x++) {
      if (!bills[x].supersededFl) {
        billId = bills[x].id;
        return x;
      }
    }
  };

  const handleCheckedChange = (field) => {
    switch (field) {
      case "balance":
        setCheckedCurrBalance(true);
        setcheckedAccBalance(false);
        setShowOtherAmountInput(false);
        setCheckedOtherAmount(false);
        setNextPaymentAmount(currentBillBalance);
        break;
      case "previousBalance":
        setcheckedAccBalance(true);
        setShowOtherAmountInput(false);
        setCheckedCurrBalance(false);
        setCheckedOtherAmount(false);
        setNextPaymentAmount(overAllBalance);
        break;
      case "otherPayment":
        setCheckedOtherAmount(true);
        setcheckedAccBalance(false);
        setCheckedCurrBalance(false);
        setShowOtherAmountInput(true);
        break;
    }
  };

  // Stripe payment intent
  const moveToCardPayment = () => {
    if (nextPaymentAmount >= 5) {
      setShowMinPayError(false);
      CreatePaymentIntent();
    } else {
      setShowMinPayError(true);
    }
  };
  async function CreatePaymentIntent() {
    setShowLoader(true);

    let stripeMessage = userEmail;
    let amount = nextPaymentAmount;
    let details = {
      jurisdiction: jurisdiction,
      amount: amount,
      email: stripeMessage,
      description: "Jf Acc Num: " + accountNum + " - Web Payment."
    };

    await axios({
      method: "post",
      url: `${config.apiUrl}/payments/intentDesc`,
      data: details
    }).then((response) => {
      let secret = response.data.client_secret;
      let id = response.data.id;

      localStorage.setItem("secret", secret);
      localStorage.setItem("paymentIntent", id);

      setShowLoader(false);
      setShowPayment(false);
      setShowPaymentDetails(true);
    });
  }

  return (
    <div className="account-dashboard-container">
      <div className="account-details-container"> 
        <NavigationSection />
        <div className="make-payment-background">
          <MDBContainer className="main-content payment-history">
            {showLoader ? <Loader /> : null}

            {/* Page Title */}
            <MDBRow center>
              <MDBCol size="1">
                <a href={`/${jurisdiction}/account/more`}>
                  <i className="fas fa-chevron-left fa-2x" />
                </a>
              </MDBCol>
              <MDBCol size="11" className="text-center ">
                <h1 className="mt-1">Make a payment</h1>
              </MDBCol>
            </MDBRow>

            {showPayment ? (
              <div>
                {/* Your Payment Section */}
                {billAvailable ? (
                  <div className="white-curved-tile text-center">
                    <h2>Your Payment</h2>
                    {/* Error Message */}
                    <MDBCol size="12">
                      {showMinPayError == true ? (
                        <MDBAlert color="danger" className="" dismiss>
                          You must enter a minimum payment amount of {currency}5
                        </MDBAlert>
                      ) : null}
                    </MDBCol>
                    <h2 className="price-figure">
                      {currency}
                      {nextPaymentAmount}
                    </h2>
                    <p>
                      Next Payment Due: <b>{nextPaymentDate}</b>
                    </p>

                    <MDBRow center>
                      <MDBCol lg="6" md="6" sm="12" className="text-left">
                        <div className="radio-container">
                          {!directDebitCustomer ? (
                            <MDBAlert className="grey-highlight brightTeal-text">
                              <input
                                className="paymentRadio"
                                id="payCurrentBalance"
                                name="payCurrentBalance"
                                type="radio"
                                onClick={() => handleCheckedChange("balance")}
                                checked={checkedCurrBalance}
                              />
                              <label htmlFor="payCurrentBalance" className="ml-2">
                                Balance: {currency}
                                {currentBillBalance}
                              </label>
                              <br />
                            </MDBAlert>
                          ) :
                            <MDBAlert className="blue-highlight brightTeal-text text-center">
                              <p>Please note - If you pay by regular Direct Debit your agreed Direct Debit amount will be taken on your payment date as usual.
                              </p>
                            </MDBAlert>
                          }

                          {showBalancePayment ? (
                            <div>
                              <MDBAlert className="grey-highlight brightTeal-text">
                                <input
                                  className="paymentRadio"
                                  id="payOverallAccountBalance"
                                  name="payOverallAccountBalance"
                                  type="radio"
                                  onClick={() =>
                                    handleCheckedChange("previousBalance")
                                  }
                                  checked={checkedAccBalance}
                                />
                                <label
                                  htmlFor="payOverallAccountBalance"
                                  className="ml-2"
                                >
                                  Account Balance: {currency}
                                  {overAllBalance.toFixed(2)}
                                </label>
                              </MDBAlert>
                            </div>
                          ) : null}

                          <MDBAlert className="grey-highlight brightTeal-text">
                            <input
                              className="paymentRadio"
                              id="payOtherAmount"
                              name="payOtherAmount"
                              type="radio"
                              onClick={() =>
                                handleCheckedChange("otherPayment")
                              }
                              checked={checkedOtherAmount}
                            />
                            <label htmlFor="payOtherAmount" className="ml-2">
                              {!directDebitCustomer ? (<span>Other</span>) : (<span>Update Amount</span>)}
                            </label>

                            {showOtherAmountInput ? (
                              <input
                                className="ml-2"
                                id="setOtherAmount"
                                name="setOtherAmount"
                                type="text"
                                value={nextPaymentAmount}
                                onChange={(e) =>
                                  setNextPaymentAmount(e.target.value)
                                }
                              />
                            ) : null}
                          </MDBAlert>
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBBtn className="btn" onClick={() => moveToCardPayment()}>
                      Make Payment
                    </MDBBtn>
                  </div>
                ) : (
                  <div className="white-curved-tile text-center">
                    <h4>
                      Your account payments are up to date.
                      <br />
                      Check back soon to see when your next payment is due.
                    </h4>
                  </div>
                )}
              </div>
            ) : null}

            {showPaymentDetails ? (
              <div className="white-curved-tile text-center">
                <Elements stripe={stripePromise}>
                  <PaymentDetailsForm />
                </Elements>
              </div>
            ) : null}
          </MDBContainer>
        </div>
      </div>
    </div>
  );
};
