import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import UserLoginDD from "./UserLoginDD.js";
import MainMenu from "./MainMenu.js";
import NIUserLoginDD from "./NIUserLoginDD.js";
import LocationNav from "./LocationNav"
import LocationNavNI from "./LocationNavNI"

import { locationActions } from "../../redux/actions/locationActions";
import { connect } from "react-redux";

class FixedNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      jurisdiction: "",
      displayJurisModal: true,
    };
  }

  componentDidMount() {
    this.props.getLocationJuris();

    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];

    this.setState({
      jurisdiction: juris,
    });


    if(pathArray[2] != null){
      sessionStorage.setItem('displayJurisModal', false);
    };

    var getDisplayJurisModal = sessionStorage.getItem('displayJurisModal');    
    if (getDisplayJurisModal == "false") {
      this.setState({
        displayJurisModal: getDisplayJurisModal
      });
    }

    
  }

  jurisModal = (params) => {
    sessionStorage.setItem('displayJurisModal', false);
    this.setState({
      displayJurisModal: false
    })

    if (params) {
      var juris = this.state.jurisdiction;
      if (juris == "ni") {
        window.location.href = "/roi";
      } else {
        window.location.href = "/ni";
      }
    }
  }

  render() {
    return (
      <header>
        <div className='country-select-section'>
          <MDBContainer>
            <div className='row'>
              <div className='col-lg-8 col-sm-0'></div>
              <div className='col-lg-12 col-lg-4 text-right'>
                <div className="juris-select-section">
                  {this.props.locationData && this.props.locationData.country == "IE" && this.state.jurisdiction.toUpperCase() == "NI" ? (<LocationNavNI />) : null}
                  {this.props.locationData && this.props.locationData.region_code == "NIR" && this.state.jurisdiction.toUpperCase() == "ROI" ? (<LocationNav />) : null}
                </div>
                {this.state.jurisdiction.toUpperCase() == "ROI" ? (<UserLoginDD />) : (<NIUserLoginDD/>)}
              </div>
            </div>
          </MDBContainer>
        </div>
        
        <div className='header-container'>
          <div className={this.state.value}>
            {/*Sub bar (main menu) */}
            <div className='row'>
              <div className='col-lg-12'>
                <MDBRow className='py-3'>
                  <MDBCol sm='12' md='12' lg='12' className=' text-center'>
                    <MainMenu />
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    locationData: store.location.locationData,
    isLoading: store.location.isLoading
  };
};

const actionCreators = {
  getLocationJuris: locationActions.getJurisFromIP
};

export default connect(mapStateToProps, actionCreators)(FixedNav);
