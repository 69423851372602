import React, { useEffect, useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import NavigationSection from "../../../components/CustomerAccount/AccountNavigation";
import Loader from "../../../components/Shared/Loader";
import { sessionExpired } from "../../../redux/helpers/sessionExpired";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { paymentActions } from "../../../redux/actions";

export const PaymentHistory = () => {
    const dispatch = useDispatch();
    const paymentHistory = useSelector((state) => state.payment);
    const [jurisdiction, setJurisdiction] = useState();
    const [tableOutput, setTableOutput] = useState();
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        sessionExpired();
        setShowLoader(true);

        // Set jurisdiction
        var pathArray = window.location.pathname.split("/");
        var juris = pathArray[1];
        setJurisdiction(juris);

        let user = JSON.parse(localStorage.getItem("user"));
        var jModelId = user.data.jModel.id;

        if (user) {
            dispatch(paymentActions.getPaymentHistory(jModelId, juris));
        }
    }, []);

    useEffect(() => {
        let htmlOutput;
        if (paymentHistory.paymentData && paymentHistory.paymentData.results != null) {
            htmlOutput = paymentHistory.paymentData.results.map(item => {
                if(item.paymentMethodType == "API Payment")
                {
                    item.paymentMethodType = "Web/App Payment";
                };

                return <MDBRow className="moreHR">
                    <MDBCol size="4" className="">
                        {item.postedDt ? (
                        <h5 className="mt-3">
                            {item.postedDt
                                .slice(0, 10)
                                .split("-")
                                .reverse()
                                .join("/")}
                        </h5>
                        ) : (" ")}                        
                    </MDBCol>

                    <MDBCol size="4" className="text-left">
                        {item.paymentMethodType ? (
                        <h5 className="mt-3">
                            {item.paymentMethodType}
                        </h5>) : (" ")}
                    </MDBCol>
                    
                    <MDBCol size="4" className="text-centre">
                        {item.amount ? (<h5 className="mt-3">
                            {item.currency == "EUR" ? ("€") : ("£")}
                            {item.amount.toFixed(2)}
                        </h5>) : ( " ")}
                    </MDBCol>
                </MDBRow>
            });
        }
        setTableOutput(htmlOutput);
        
        if(htmlOutput){
        setShowLoader(false);
        }
    }, [paymentHistory.paymentData]);

    return (
        <div className="account-dashboard-container">
            {/* API Loader */}
            {showLoader ? <Loader /> : null}

            <div className="account-details-container">
                <NavigationSection />
                <div className="make-payment-background">
                    <MDBContainer className="main-content payment-history">
                        {/* Page Title */}
                        <MDBRow>
                            <MDBCol size="1">
                                <a href={`/${jurisdiction}/account/more`}>
                                    <i className="fas fa-chevron-left fa-2x" />
                                </a>
                            </MDBCol>
                            <MDBCol size="10" className="text-center ">
                                <h1 className="mt-1">Your Payment History</h1>
                            </MDBCol>
                            <MDBCol size="1"></MDBCol>
                        </MDBRow>
                        {paymentHistory.paymentData && paymentHistory.paymentData.results == null ? (
                            <MDBRow>
                                <MDBCol>
                                    <div className="white-curved-tile text-center">
                                        <h4>
                                            Looks like you haven't made any payments yet! Check back soon
                                            to view your payment history.
                    </h4>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        ) : (
                                <div className="white-curved-tile">
                                    <MDBRow className="moreHR">
                                        <MDBCol size="4" className="">
                                            <h5 className="mt-3">Payment Date</h5>
                                        </MDBCol>
                                        <MDBCol size="4" className="">
                                            <h5 className="mt-3">Payment Method</h5>
                                        </MDBCol>
                                        <MDBCol size="4" className="text-left">
                                            <h5 className="mt-3">
                                                Amount
                                            <i className="fas fa-chevron-right fa-lg pl-5 white-text" />
                                            </h5>
                                        </MDBCol>
                                    </MDBRow>

                                    {tableOutput}
                                </div>
                            )}
                    </MDBContainer>
                </div>
            </div>
        </div>
    );
};

export default PaymentHistory;
