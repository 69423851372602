import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBBtn } from "mdbreact";

class AdditionalHelp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className='additionalHelp-bg'>
        <MDBRow size='12'>
          <MDBCol size='3' />
          <MDBCol size='6' className='align-self-center text-center'>
            <div className='energyTips-content'>
              <MDBRow size='12'>
                <MDBCol size='6'>
                  <h5 className='mt-4'>
                    Can't find an answer <br />
                    to your question?
                  </h5>
                </MDBCol>
                <MDBCol size='6'>
                  <a href='#' className='btn'>
                    Contact us
                  </a>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBCol>
          <MDBCol size='3' />
        </MDBRow>
      </div>
    );
  }
}

export default AdditionalHelp;
