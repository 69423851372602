import axios from "axios";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const customerAccountService = {
  getBillsService,
  getBillByIdService,
};

// Get bills
function getBillsService() {
  let user = localStorage.getItem("user");

  if (user) {
    let parseUser = JSON.parse(user);
    let userId = parseUser.data.authenticateModel.id;
    let userToken = parseUser.data.authenticateModel.token;

    return axios({
      method: "get",
      url: `${config.apiUrl}/crm/` + userId,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    }).then((response) => {
      return response;
    });
  }
}

// Get bill by Id
function getBillByIdService(billId, jurisdiction) {
  let user = localStorage.getItem("user");

  if (user) {
    let parseUser = JSON.parse(user);
    let userToken = parseUser.data.authenticateModel.token;

    return axios({
      method: "get",
      // responseType:"arraybuffer",
      url: `${config.apiUrl}/crm/getbillfile/${billId}/${jurisdiction}`,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    }).then((response) => {
      return response;
    });
  }
}

