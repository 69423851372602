import React from "react";
import { MDBRow, MDBCol, MDBListGroup, MDBListGroupItem } from "mdbreact";
import { connect } from "react-redux";
import { switchActions } from "../../../redux/actions";

class PersonalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      address: "",
      MPRN: "",
      email: "",
      accountNumber: "",
    };
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    let userModel = user.data.authenticateModel;
    let jModel = user.data.jModel;

    if (jModel != null) {
      // Name
      let firstName = userModel.firstName;
      let lastName = userModel.lastName;
      let name = firstName + " " + lastName;

      // Address
      let houseNo = jModel.billingAddress.address2;
      // Handel no house number
      if (houseNo == null) {
        houseNo = "";
      } else {
        houseNo = houseNo;
      }
      let street = jModel.billingAddress.address3;
      let postcode = jModel.billingAddress.postcode;
      // Handel no postcode
      if (postcode == null) {
        postcode = "";
      } else {
        postcode = postcode;
      }
      let city = jModel.billingAddress.address4;
      let county = jModel.billingAddress.address5;

      let address =
        houseNo + " " + street + " " + postcode + " " + city + " " + county;

      let mprn = jModel.mprn;

      // Email
      let email = userModel.username;

      // Account no
      let accountNo = jModel.number;

      this.setState({
        name: name,
        address: address,
        MPRN: mprn,
        email: email,
        accountNumber: accountNo,
      });
    }
  }

  render() {
    return (
      <MDBCol size="12">
        {/* Title */}
        <MDBCol>
          <h5>Account Details</h5>
        </MDBCol>

        <div className="white-curved-tile">
          {/* Name */}
          <MDBRow className="manageAccountHR mt-4 mb-4">
            <MDBCol size="3" className="text-left">
              <p>Name</p>
            </MDBCol>
            <MDBCol size="9" className="text-right">
              <p>
                <b>{this.state.name}</b>
              </p>
            </MDBCol>
          </MDBRow>

          {/* Account number */}
          <MDBRow className="manageAccountHR mt-4 mb-4">
            <MDBCol size="3" className="text-left">
              <p>Account number</p>
            </MDBCol>
            <MDBCol size="9" className="text-right">
              <p>
                <b>{this.state.accountNumber}</b>
              </p>
            </MDBCol>
          </MDBRow>

          {/* Address */}
          <MDBRow className="manageAccountHR mb-4">
            <MDBCol size="3" className="text-left">
              <p>Address</p>
            </MDBCol>
            <MDBCol size="9" className="text-right">
              <p>
                <b>{this.state.address}</b>
              </p>
            </MDBCol>
          </MDBRow>

          {/* MPRN */}
          <MDBRow className="manageAccountHR mb-4">
            <MDBCol size="3" className="text-left">
              <p>MPRN</p>
            </MDBCol>
            <MDBCol size="9" className="text-right">
              <p>
                <b>{this.state.MPRN}</b>
              </p>
            </MDBCol>
          </MDBRow>

          {/* Email */}
          <MDBRow className="manageAccountHR mb-4">
            <MDBCol size="3" className="text-left">
              <p>Email</p>
            </MDBCol>
            <MDBCol size="9" className="text-right">
              <p>
                <b>{this.state.email}</b>
              </p>
            </MDBCol>
          </MDBRow>
        </div>

        {/* Personal Details */}
        {/* <div>
          <MDBListGroup className='ml-3'> */}
        {/* Name */}
        {/* <MDBListGroupItem className='pl-3 d-flex justify-content-between align-items-center'>
              Name
              <div className='d-flex align-items-center'>
                <p className='account-details-item'>{this.state.name}</p>
              </div>
            </MDBListGroupItem> */}

        {/* Address */}
        {/* <MDBListGroupItem className='pl-3 d-flex justify-content-between align-items-center'>
              Address
              <div className='d-flex align-items-center'>
                <p className='account-details-item'>{this.state.address}</p>
              </div>
            </MDBListGroupItem> */}

        {/* MPRN */}
        {/* <MDBListGroupItem className='pl-3 d-flex justify-content-between align-items-center'>
              MPRN
              <div className='d-flex align-items-center'>
                <p className='account-details-item'>{this.state.MPRN}</p>
              </div>
            </MDBListGroupItem> */}

        {/* Email */}
        {/* <MDBListGroupItem className='pl-3 d-flex justify-content-between align-items-center'>
              Email
              <div className='d-flex align-items-center'>
                <p className='account-details-item'>{this.state.email}</p>
              </div>
            </MDBListGroupItem>
          </MDBListGroup>
        </div> */}
      </MDBCol>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    switchData: store.switch.switchData,
  };
};

const actionCreators = {
  getSwitch: switchActions.getSwitch,
};

export default connect(mapStateToProps, actionCreators)(PersonalDetails);
