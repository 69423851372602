export const SpecialServiceData = [
  { value: "0001", label: "Visually Impaired" },
  { value: "0002", label: "Speech Impaired" },
  { value: "0003", label: "Hearing Impaired" },
  { value: "0004", label: "Elderly (60+)" },
  { value: "0005", label: "Language Difficulty" },
  { value: "0006", label: "Learning Difficulty" },
  { value: "0007", label: "Mobility Impaired" },
  { value: "0008", label: "Mental Health" },
  { value: "0009", label: "Dexterity Impaired" },
];

export const MedicalEquipmentData = [
  { value: "01", label: "Oxygen Concentrator " },
  { value: "02", label: "Personal Suction Pump " },
  { value: "03", label: "Home Dialysis " },
  { value: "04", label: "Ventilator " },
  { value: "05", label: "Electric Hoist " },
  { value: "06", label: "Nebuliser " },
  { value: "07", label: "Other " },
];

export const RegistrationDetailsData = [
  { value: "01", label: "Mental Health " },
  { value: "02", label: "Deaf of hard of hearing  " },
  { value: "03", label: "Blind or Partially sighted  " },
  { value: "04", label: "Elderly (aged 66 or over)  " },
  { value: "05", label: "Mobility impaired  " },
  { value: "06", label: "Dexterity Impaired " },
  { value: "07", label: "Language difficulty  " },
  { value: "08", label: "Speech Difficulty   " },
  { value: "09", label: "Learning Difficulty   " },
  { value: "10", label: "Other (please Specify)   " },
];

export const NISpecialServiceData = [
  { value: "0001", label: "Visually Impaired" },
  { value: "0002", label: "Speech Impaired" },
  { value: "0003", label: "Hearing Impaired" },
  { value: "0004", label: "Elderly" },
  { value: "0005", label: "Language Difficulty" },
  { value: "0006", label: "Learning Difficulty" },
  { value: "0007", label: "Mobility Impaired" },
  { value: "0008", label: "Dexterity Impaired" },
];

export const NIMedicalEquipmentData = [
  { value: "CL", label: "Electric Chair Lift" },
  { value: "EH", label: "Electric Hoist" },
  { value: "EM", label: "Electric Mattress/Bed" },
  { value: "FR", label: "Vital Medicine Requiring Refrigeration" },
  { value: "HD", label: "Home Dialysis" },
  { value: "MS", label: "Multiple Sclerosis" },
  { value: "NB", label: "Nebuliser" },
  { value: "NP", label: "Peg Feeding Tube" },
  { value: "OC", label: "Oxygen Concentrator" },
  { value: "PN", label: "Total Parental Nutrition Machine" },
  { value: "PV", label: "Patient Vital Signs Monitoring" },
  { value: "SL", label: "Electric Pressure Stair Lift" },
  { value: "SP", label: "Suction Pump" },
  { value: "VT", label: "Ventilator" },
];
