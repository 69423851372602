import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import NavigationSection from "../../../components/CustomerAccount/AccountNavigation";
import { sessionExpired } from "../../../redux/helpers/sessionExpired";

class BillBreakdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
      bills: [],
      billID: "",
      currentBill: [],
    };
  }

  componentDidMount() {
    sessionExpired();

    // Get jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];

    // Get bill id
    let urlParams = new URLSearchParams(window.location.search);
    let billID = urlParams.get("id");

    // Set jurisdiction and billID
    this.setState({
      jurisdiction: jurisdiction,
      billID: billID,
    });

    // Get user info
    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;

    // Map customer bills
    let billsList = jModel.billsList;
    this.state.bills.push(billsList);

    let currentBill = this.state.bills[0].find((item) => item.id == billID);
    this.state.currentBill.push(currentBill);

    let paymentDate = currentBill.acceptedDttm;
  }

  render() {
    return (
      <div className="account-dashboard-container">
        <div className="account-details-container">
          <NavigationSection />
          <div className="make-payment-background">
            <MDBContainer className="main-content make-payment">
              {/* Page Title */}
              <MDBRow>
                <MDBCol size="1">
                  <a href={`/${this.state.jurisdiction}/account/more`}>
                    <i className="fas fa-chevron-left fa-2x" />
                  </a>
                </MDBCol>
                <MDBCol size="10" className="text-center">
                  <h1 className="mt-1 mr-5">Bill id: {this.state.billID} </h1>
                </MDBCol>
                <MDBCol size="1"></MDBCol>
              </MDBRow>

              {this.state.currentBill != null
                ? this.state.currentBill.map((bill) => (
                    <div className="white-curved-tile">
                      <MDBRow>
                        <MDBCol className="text-center">
                          {/* Payment success message */}
                          <p className="text-center">
                            Your payment of <b>€{bill.grossAmount}</b> <br />
                            was successfully taken on: .
                          </p>

                          {/* Success image */}
                          <img
                            src={require("../../../images/account/success.png")}
                            style={{ width: "20%" }}
                          />

                          {/* Payment amount */}
                          <h3 className="mt-5">€{this.state.paymentAmount}</h3>
                        </MDBCol>
                      </MDBRow>

                      <MDBContainer>
                        {/* Payment type */}
                        <MDBRow className="paymentHR">
                          <MDBCol size="6">
                            <p>Payment method</p>
                          </MDBCol>
                          <MDBCol size="6">
                            <p className="text-right">
                              <b>{this.state.paymentMethod}</b>
                            </p>
                          </MDBCol>
                        </MDBRow>

                        {/* Payment referance */}
                        <MDBRow className="mt-3">
                          <MDBCol size="6">
                            <p>Payment reference</p>
                          </MDBCol>
                          <MDBCol size="6">
                            <p className="text-right">
                              <b>{this.state.paymentReferance}</b>
                            </p>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>

                      {/* View payment history */}
                      <MDBRow className="justify-content-center">
                        <a
                          href={`/${this.state.jurisdiction}/account/bills-statements`}
                        >
                          <MDBBtn className="btn-teal">
                            View payment history
                          </MDBBtn>
                        </a>
                      </MDBRow>
                    </div>
                  ))
                : null}
            </MDBContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default BillBreakdown;
