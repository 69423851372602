import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBRow, MDBContainer, MDBAlert } from "mdbreact";
import LiveChat from "react-livechat";
import Loader from "../../../Shared/Loader";

// Redux
import { connect } from "react-redux";
import { switchActions } from "../../../../redux/actions/switchActions";
import { quoteActions } from "../../../../redux/actions/quoteActions";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { DepositDetailsForm } from "./SecurityDeposit/DepositDetailsForm";

const LoadConfig = require("../../../../config");
const config = LoadConfig.default;
const stripePromise = loadStripe(config.niStripeKey);

export class YourElectricity extends Component {
  state = {};

  render() {
    return (
      <div className='quote-page switch-page'>
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        {/* Background Image */}
        <div className='device-image' />
        {/* Logo */}
        <MDBRow className='text-center'>
          <MDBCol lg='2' sm='12'>
            <a onClick={() => this.setState({ homeCheck: true })}>
              <img
                src={require("../../../../images/global/bright-logo-dark.png")}
                className='quote-logo mx-5 mt-5'
              />
            </a>
          </MDBCol>
          <MDBCol size='10' />
        </MDBRow>

        {/* Home message */}
        {this.state.homeCheck ? (
          <div className='modalBG'>
            <div className='modalBox'>
              <div className='white-curved-tile text-center'>
                <h4 className='mt-5'>
                  All progress will be lost! <br />
                  Are you sure?
                </h4>
                <MDBRow>
                  <MDBCol lg='6' md='6' sm='12'>
                    <MDBBtn
                      className='cancel-button'
                      onClick={() =>
                        this.setState({ homeCheck: !this.state.homeCheck })
                      }
                    >
                      Cancel
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol lg='6' md='6' sm='12'>
                    <MDBBtn
                      onClick={() =>
                        (window.location.href = `/${this.state.jurisdiction}/`)
                      }
                    >
                      Continue
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        ) : null}

        <MDBContainer className='personal-details-section'>
          <MDBRow>
            <MDBCol size='12'>
              <MDBRow>
                {/* Progress Bar */}
                <MDBCol md='12' className='switch-navigation-container'>
                  <div className='border-light'>
                    <img
                      className='brand-image show-for-medium'
                      src={require("../../../../images/NI Switch/ThreeStepPayment.png")}
                    />
                  </div>
                </MDBCol>

                {/* Error Message */}
                <MDBCol size='12' id='errorMessage'>
                  {this.state.errorMessage == true ? (
                    <MDBAlert color='danger' className='mb-5' dismiss>
                      <strong>Oops!</strong> Please check your fields below.
                    </MDBAlert>
                  ) : null}
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol md='12' lg='8'>
                  <Elements stripe={stripePromise}>
                    <DepositDetailsForm />
                  </Elements>
                </MDBCol>
                <MDBCol size='4' className='show-for-large'>
                  <img
                    className='brand-image'
                    src={require("../../../../images/NI Switch/GreenElectricity.png")}
                  />

                  {/* Chat functionality */}
                  <div className='white-curved-tile text-left mt-3'>
                    <h5 className='mt-4 text-center'>Need some help?</h5>
                    {/* Live chat */}
                    <div className='switch-live-chat'>
                      <div data-id='VVUGGBG3WTc' class='livechat_button'>
                        <a href='https://www.livechat.com/utm-builder/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_11999496'>
                          UTM Builder
                        </a>
                      </div>
                      <LiveChat license={11999496} />
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBCol size='2' />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.switch.isLoading,
    journeyData: store.switch.switchData,
  };
};

const actionCreators = {
  getSwitch: switchActions.getSwitchJourney,
  updateSwitch: switchActions.updateSwitchJourney,
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(YourElectricity);
