import React from "react";
import { MDBCol, MDBListGroup, MDBListGroupItem } from "mdbreact";

class MarketingPreferences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailPref: false,
      postPref: false,
      smsPref: false,
      phonePref: false,
    };
  }
  render() {
    return (
      <MDBCol size='12'>
        {/* Title */}
        <MDBCol className='mb-4'>
          <h5>Marketing Preferences</h5>
          <p>
            You have agreed to us contacting you in relation to additional
            Bright products and services via the below methods. <br />
            You can amend your preferences at any time:
          </p>
        </MDBCol>

        {/* Marketing Preferences */}
        <MDBListGroup className='ml-3'>
          {/* Email */}
          <MDBListGroupItem className='pl-3 d-flex justify-content-between align-items-center'>
            Email
            <div className='d-flex align-items-center mr-5'>
              <div class='custom-control custom-checkbox'>
                <input
                  type='checkbox'
                  class='custom-control-input'
                  id='email'
                />
                <label class='custom-control-label' for='email'>
                  {" "}
                </label>
              </div>
            </div>
          </MDBListGroupItem>

          {/* Post */}
          <MDBListGroupItem className='pl-3 d-flex justify-content-between align-items-center'>
            SMS
            <div className='d-flex align-items-center mr-5'>
              <div class='custom-control custom-checkbox'>
                <input type='checkbox' class='custom-control-input' id='post' />
                <label class='custom-control-label' for='post'>
                  {" "}
                </label>
              </div>
            </div>
          </MDBListGroupItem>

          {/* SMS */}
          <MDBListGroupItem className='pl-3 d-flex justify-content-between align-items-center'>
            SMS
            <div className='d-flex align-items-center mr-5'>
              <div class='custom-control custom-checkbox'>
                <input type='checkbox' class='custom-control-input' id='sms' />
                <label class='custom-control-label' for='sms'>
                  {" "}
                </label>
              </div>
            </div>
          </MDBListGroupItem>

          {/* Phone */}
          <MDBListGroupItem className='pl-3 d-flex justify-content-between align-items-center'>
            Phone
            <div className='d-flex align-items-center mr-5'>
              <div class='custom-control custom-checkbox'>
                <input
                  type='checkbox'
                  class='custom-control-input'
                  id='phone'
                />
                <label class='custom-control-label' for='phone'>
                  {" "}
                </label>
              </div>
            </div>
          </MDBListGroupItem>
        </MDBListGroup>
      </MDBCol>
    );
  }
}

export default MarketingPreferences;
