import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FAQTabs from "../../components/Help/FAQTabs";

import FixedNavDark from "../../components/Shared/FixedNavDark";
import $ from "jquery";

class FAQsAdditionalHelp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }
  
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.help);
  }

  help =() =>{
    if (window.pageYOffset <50)
    {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark/> */}
        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>FAQs</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow className="pb-5">
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <FAQTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5'>
            <h3>Additional Help</h3>

            <div id='makeacomplaint' className='mt-5 mb-5'>
              <h5 className='policy-title'>How do I make a complaint? </h5>
              <p className='policy-content'>
                <b>Our complaints process</b><br />
                At bright we want to get it right first time, every time.  However, sometimes we make mistakes.  So, if you have a complaint or are unhappy with our services we want to know about it.  Our complaints process is free to access and fair for all.  We promise to investigate your complaint fully and work with you to find a resolution.
                To find out more about how we handle complaints see our codes of practice on complaint handling <a href={`/${this.state.jurisdiction}/codesofpractice`}>here.</a>

                <br /><br />
                <b>Making a complaint</b><br />
                    You can make a complaint by
                    <ul>
                  <li>Calling us on our freephone number  1800 832 288</li>
                  <li>Emailing <a href="mailto:complaints@brightenergy.com">complaints@brightenergy.com</a></li>
                  <li>Write to Bright at:<br />3 Custom House Plaza, IFSC, Dublin, D01 VY76</li>
                </ul>

                <br />
                <b>How long will it take to resolve?</b><br />
                    We’ll try to resolve any issues as quickly as possible. If you phone us we will aim to deal with the issue there and then.  If it’s not possible to deal with the matter immediately or if you contact us by any other method we will fully investigate and aim to get back to you within 5 working days.  But if we can’t we’ll keep in touch to let you know we’re working on your issue.

                    <br /><br />
                <b>Escalating your complaint</b><br />
                    If you aren’t satisfied with our response you can ask to have it reviewed by a Team Lead.  The Team Lead will review the complaint, our response, and why you are unhappy with our initial response. The Team Lead will provide you with a resolution within 10 working days and if that’s not possible we’ll be in touch to let you know we’re working on your issue.
                    <br /><br />
                    If you remain unhappy you can escalate your complaint to our Head of Customer Services who will fully investigate your complaint and provide you with our final resolution within 10 working days.  If there's any delay we'll be in touch to let you know we're working on your issue.
                    <br /><br />
                    If, after we’ve done all we can to help, you are still not satisfied you can take your complaint to the Customer Care Team at the Commission for Regulation of Utilities (CRU).  Please note that the CRU will only review once you have completed our complaints process.
                    <br /><br />
                    Contact CRU at: <br />
                    Customer Care Team, The Grain House, The Exchange, Belgard Suare North, Dublin 24, D24 PXWO.
              </p>
            </div>

            <div id='cantresolvecomplaint' className='mb-5'>
              <h5 className='policy-title'>What happens if bright can't resolve my complaint? </h5>
              <p className='policy-content'>
              If we are unable to resolve your complaint to your satisfaction you can contact the CRU Customer Care Team for an independent review. 
              Please note that the CRU will only review once you have completed our complaints process. <br/><br/>
              Contact CRU at:
              <ul>
                <li>By Post: Customer Care Team, Commission for Regulation of Utilities, The Grain House, The Exhange, Belgard Square North, Dublin 24, D24 PXWO</li>
                <li>Phone: <a href="tel:1800 404 404">1800 404 404</a></li>
                <li>E-mail: <a href="mailto:customercare@cru.ie">customercare@cru.ie</a></li>
                <li>Online: <a href="https://www.cru.ie/home/complaint-form">CRU Complaints Form</a></li>
              </ul>
              </p>
            </div>

            <div id='personaldata' className='mb-5'>
              <h5 className='policy-title'>How is my personal data collected, stored and processed by bright? </h5>
              <p className='policy-content'>
              At bright we respect your privacy and we are committed to ensuring your personal data is protected.  For full details on how we collect, store and process your personal data, please view our <a href={`/${this.state.jurisdiction}/privacystatement`}>Privacy Policy.</a>  
              </p>
            </div>

            <div id='marketingpreferences' className='mb-5'>
              <h5 className='policy-title'>How can I update my marketing preferences?  </h5>
              <p className='policy-content'>
              You can change your marketing preferences on our website at [LINK], via the app, on the customer portal or by getting in touch on <a href="tel:01 588 1777">01 588 1777</a>.  You can also unsubscribe from direct marketing or opt out of profiling via the channel you received the communication. 
              <br/><br/>
              <ul>
                <li>Call us on <a href="tel:01 588 1777">01 588 1777</a></li>
                <li>Email us at <a href="mailto:hello@brightenergy.com">hello@brightenergy.com</a> </li>
                <li>Write to us at Bright, 3 Custom House Plaza IFCS, Dublin, D01 VY76</li>
              </ul> 
              </p>
            </div>

            <div id='powercut' className='mb-5'>
              <h5 className='policy-title'>What should I do if there is a power cut? </h5>
              <p className='policy-content'>
              If you experience a power cut, you should contact ESB Networks. If you've lost your electricity supply, first check if it's just your home that's without power or if there is a fault in the general area. You can check ESB Networks PowerCheck to get realtime information on faults and planned outages. 
              <br/><br/>
              If your fault is not listed report it to ESB Networks online at <a href="https://www.esbnetworks.ie/apps/faultlogging">https://www.esbnetworks.ie/apps/faultlogging</a> or call ESB Networks 24-hour Emergency Line on <a href="tel:1800 372 999">1800 372 999</a> or <a href="tel:021 2382410">021 2382410</a>. 
              <br/><br/>
              Safety first - if you see any damaged electricity poles or wires DO NOT TOUCH or DO NOT GO NEAR.
              </p>
            </div>

            <div id='emergencycontact' className='mb-5'>
              <h5 className='policy-title'>Who do I contact in an emergency? </h5>
              <p className='policy-content'>
              Call ESB Networks on <a href="tel:1800 372 999">1800 372 999</a>.
              </p>
            </div>

            <div id='vulnerablecustomer' className='mb-5'>
              <h5 className='policy-title'>What does vulnerable customer mean?  </h5>
              <p className='policy-content'>
              You can register with us as a vulnerable customer if you, or a member of your household is: 
              <ul>
                <li>Critically dependant on electrically powered equipment, which includes, but is not limited to, life protecting devices, assistive technologies to support independent living and medical equipment </li>
                <li>Particularly vulnerable to disconnection during winter months for reasons of advanced age or physical, sensory, intellectual or mental health. View bright’s code of practice on the provision of services for vulnerable customers <a href={`/${this.state.jurisdiction}/codesofpractice`}>here.</a></li>
              </ul>
              </p>
            </div>

            <div id='priorityservices' className='mb-5'>
              <h5 className='policy-title'>What is a Priority Services Register? </h5>
              <p className='policy-content'>
              The Priority Services Register is for those customers who are critically dependent on electrically powered home medical equipment such as an oxygen concentrator, suction pump, stair lift, home dialysis etc. 
              <br/><br/>
              We will inform ESB Networks that you have essential medical equipment that relies on electricity. ESB Networks will include your details on an industry register to ensure that you’re notified in advance of any planned interruption to your electricity supply and if there is an unexpected power cut, they will prioritise your reconnection. 
              <br/><br/>
              View bright's ode of practice on provision of services for vulnerable customers <a href={`/${this.state.jurisdiction}/codesofpractice`}>here.</a>
              </p>
            </div>
            
            <div id='specialservices' className='mb-5'>
              <h5 className='policy-title'>What is a Special Services Register?  </h5>
              <p className='policy-content'>
              Our Special Services Register is for those household customers who; 
              <ul>
                <li>Are of pensionable age (66 years or above) living alone, with another vulnerable person or with minors</li>
                <li>Have a physical, sensory, intellectual or mental health disability. </li>
              </ul>

              View bright's code of practice on provision of services for vulnerable customers <a href={`/${this.state.jurisdiction}/codesofpractice`}>here.</a>

              </p>
            </div>

            <div id='visuallyimpaired' className='mb-5'>
              <h5 className='policy-title'>I am blind or visually impaired. How can I receive my bill? </h5>
              <p className='policy-content'>
              If you are blind or visually impaired, we are happy to provide: 
              <ul>
                <li>Braille Bills </li>
                <li>Talking Bills Service – one of our energy specialists can give you a call to chat through your bill with you</li>
                <li>Large print – we can provide our communications to you in large print format</li>
              </ul>
              </p>
            </div>

            <div id='hearingimpaired' className='mb-5'>
              <h5 className='policy-title'>I am deaf or hearing impaired. How can I contact you? </h5>
              <p className='policy-content'>
              If you are deaf or are hearing impaired you can contact our Energy Specialists via email on <a href="mailto:hello@brightenergy.com">hello@brightenergy.com</a> or online via web chat at <a href="www.brightenergy.com">https://www.brightenergy.com</a> 
              </p>
            </div>

            <div id='passwordscheme' className='mb-5'>
              <h5 className='policy-title'>Do you provide a Password Scheme? </h5>
              <p className='policy-content'>
              You can set up a password which our representatives will use when contacting you so you can always be sure it's a call from bright.
              </p>
            </div>

            <div id='medicalequipment' className='mb-5'>
              <h5 className='policy-title'>I am critically dependent on medical equipment. How can I ensure my supply is not switched off? </h5>
              <p className='policy-content'>
              When you sign up with us, or if your circumstances change let us know and we will register you on the Priority Services Register.  
              This register is for those customers who are critically dependent on electrically powered home medical equipment such as an oxygen concentrator, suction pump, stair lift, home dialysis etc. 
              We will inform ESB Networks that you have essential medical equipment that relies on electricity.   ESB Networks will include your details on an industry register to ensure that you’re notified in advance of any planned interruption to your electricity supply and if there is an unexpected power cut, they will prioritise your reconnection. 
              View bright's code of practice on provision of services for vulnerable customers <a href={`/${this.state.jurisdiction}/codesofpractice`}>here</a> - We won't disconnect customers for non payment who are on the Priority Services Register.
              </p>
            </div>

            <div id='elderly' className='mb-5'>
              <h5 className='policy-title'>I am elderly and worried about my power supply being disconnected in winter. What can I do? </h5>
              <p className='policy-content'>
              When you sign up with us we'll let you know about our Special Services Register.  The register is for those household customers who; 
              <ul>
                <li>Are of pensionable age (66 years or above) living alone, with another vulnerable person or with minors</li>
                <li>Have a physical, sensory, intellectual or mental health disability.</li>
                <li>View bright's code of practice on provision of services for vulnerable customers <a href={`/${this.state.jurisdiction}/codesofpractice`}>here</a> - We won't disconnect customers for non payment during the winter months who are on the Special Services Register. </li>
              </ul>
              </p>
            </div>

            <div id='nominatedperson' className='mb-5'>
              <h5 className='policy-title'>I have a carer/relative/friend who normally looks after my bills. Can you send my bill to them? </h5>
              <p className='policy-content'>
              You can provide us with the name and number of a relative, carer or friend who we can contact about your account on your behalf. We can send the nominated person copies of any correspondence that is sent to you, including bills. However, we will need your permission and the permission of the nominated person to do this.
              </p>
            </div>

            <div id='greenelectricity' className='mb-5'>
              <h5 className='policy-title'>What does 100% green electricity<a href='#cru'>*</a> mean? </h5>
              <p className='policy-content'>
              Our electricity is 100% green<a href='#cru'>*</a> and powered only by the good stuff - wind, hydro, bio and solar. To make this possible we ensure that every unit of electricity supplied to bright customers is replaced on the grid by a unit from a renewable source.  This is something we’re incredibly proud of and it’s not only making a difference now but it will benefit the children of today who are the future.
              </p>
              </div>

              <div id='electricvehicle' className='mb-5'>
              <h5 className='policy-title'>Do you offer an Electric Vehicle tariff? </h5>
              <p className='policy-content'>
               We do not currently offer an electric vehicle tariff but this is part of the bright plan and something that will be available for our customers in the future.
              </p>
            </div>

          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default FAQsAdditionalHelp;
