import React, { Component } from "react";
import { MDBRow } from "mdbreact";
import { NavLink } from "react-router-dom";

class FAQTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split('/');
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris
    });
  }

  render() {
    return (
      <MDBRow>
        <ul className='account-navigation-content ml-5'>
          {/* Getting Started */}
          <li>
            <NavLink activeClassName='is-active' exact to={`/${this.state.jurisdiction}/faqs/gettingstarted`} >
              Getting started
            </NavLink>
          </li>

          {/* My Bright Account */}
          <li>
            <NavLink activeClassName='is-active' exact to={`/${this.state.jurisdiction}/faqs/mybrightaccount`} >
              My bright account
            </NavLink>
          </li>

          {/* Billing and Payments */}
          <li>
            <NavLink activeClassName='is-active' exact to={`/${this.state.jurisdiction}/faqs/billingandpayments`} >
              Billing and payments
            </NavLink>
          </li>

          {/* Meter Readings */}
          <li>
            <NavLink activeClassName='is-active' exact to={`/${this.state.jurisdiction}/faqs/meterreadings`} >
              Meter readings
            </NavLink>
          </li>

          {/* Moving House */}
          <li>
            <NavLink activeClassName='is-active' exact to={`/${this.state.jurisdiction}/faqs/movinghouse`}>
              Moving house
            </NavLink>
          </li>

          {/* Additional Help */}
          <li>
            <NavLink activeClassName='is-active' exact to={`/${this.state.jurisdiction}/faqs/additionalhelp`}>
              Additional help
            </NavLink>
          </li>

           {/* Smart Meters */}
           <li>
            <NavLink activeClassName='is-active' exact to={`/${this.state.jurisdiction}/faqs/smartmeters`} >
              Smart Meters
            </NavLink>
          </li>

        

          {/* Prepayment Meters */}
          {this.state.jurisdiction == "ni" ?
          <span>
          <li>
          <NavLink activeClassName='is-active' exact to={`/${this.state.jurisdiction}/faqs/prepaymentmeters`}>
            Prepayment Meters
          </NavLink>
          </li>

          
          <li>
          <NavLink activeClassName='is-active' exact to={`/${this.state.jurisdiction}/faqs/complaints`}>
            Complaints
          </NavLink>
          
          </li> </span>: null }


        </ul>
      </MDBRow>
    );
  }
}
export default FAQTabs;
