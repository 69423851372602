import React, { Component } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdbreact";
import Swiper from "react-id-swiper";
import NIOnAMission from "../../../components/NI/NIOnAMission";

// Redux
import { connect } from "react-redux";
import { blogActions } from "../../../redux/actions";

class NIOurMission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postcode: "",
      activeItem: "1",
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
    
    this.props.getBlogPosts(juris);
  }

  toggle = (tab) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    const generatorSwiper = {
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 1000,
      loop: true,
      effect: "fade",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    // News Seciton Swiper https://swiperjs.com/api/
    const newsSwiper = {
      slidesPerView: 3,
      spaceBetween: 0,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1000,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
      },

      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 30,
        },

        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },

        1024: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      },
    };

    // Trust Pilot Seciton Swiper https://swiperjs.com/api/
    const trustpilotSwiper = {
      slidesPerView: 3,
      spaceBetween: 0,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1000,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
      },

      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 30,
        },

        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },

        1024: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      },
    };

    return (
      <div className='default-page'>
        {/* Hero with background device */}
        <MDBRow className='small-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='12' lg='12'>
                <h1>Our bright mission</h1>
                {/* <h3 className='white-text'>
                  Hi, we're bright & we're on a <br />
                  mission to bring{" "}
                  <span className='mt-megan brightGreen-text'>
                    (Renewable)
                  </span>{" "}
                  power
                  <br />
                  to the people with clean energy
                  <br />
                  that's fair for all{" "}
                </h3> */}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBContainer className='energy-section'>
          <MDBRow className='large-padding pb-3'>
            {/* Welcome Text */}
            <MDBCol sm='12' md='8' lg='7' className='content'>
              <p>
                At bright we're on a mission to bring{" "}
                <span className='underline'>renewable</span>{" "}
                {/* mt-megan brightGreen-text */}
                power to the people with affordable energy that's fair for all.{" "}
                <br />
                <br />
                We offer one simple, variable rate tariff to everyone. We always
                give you our best price, meaning when you sign up with bright
                you will never be moved on to another, more expensive tariff.
                {/* Our offering is focused on three key attributes that are
                designed to save you time, money and help you do your bit for
                mankind. */}
              </p>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            {/* Our Energy Text */}
            <MDBCol
              sm='6'
              md='4'
              lg='4'
              className='text-center icons-container'
            >
              <img
                src={require("../../../images/home-page/clean-energy-icon.png")}
              ></img>
              <h5>Our electricity</h5>
              <p>
                Our energy comes only from the good stuff - wind, solar, bio and
                hydro. With bright you can power your home with renewable energy
                without additional cost or hassle.
              </p>
            </MDBCol>

            {/* App Store Text */}
            <MDBCol
              sm='6'
              md='4'
              lg='4'
              className='text-center icons-container'
            >
              <img
                src={require("../../../images/home-page/manage-account-icon.png")}
              ></img>
              <h5>Cool technology</h5>
              <p className='mb-4'>
                The bright app has been designed to make life simple for
                our customers. Manage payments, submit meter readings, and
                access energy saving advice, offers and more all from the palm
                of your hand.
              </p>
            </MDBCol>

            {/* Great Value Text */}
            <MDBCol
              sm='6'
              md='4'
              lg='4'
              className='text-center icons-container'
            >
              <img src={require("../../../images/home-page/pound.png")}></img>
              <h5>Great value</h5>
              <p>
                Say hello to the fair for one, fair for all bright future
                tariff. Just one simple tariff so you know you are always
                getting our best price. We're on a mission to make green energy
                fair for all.
                {/*Through strength in numbers, we can make a difference. By joining bright you can be part of real change. */}
              </p>
            </MDBCol>
          </MDBRow>

          {/* Buttons */}
          <MDBRow className='d-flex align-items-baseline'>
            {/* Our Energy */}
            <MDBCol sm='6' md='4' lg='4' className='text-center'>
              <div>
                <a
                  href={`/${this.state.jurisdiction}/about/ourelectricity`}
                  className='btn'
                >
                  Our electricity
                </a>
              </div>
            </MDBCol>

            {/* App Stores */}
            <MDBCol sm='6' md='4' lg='4'>
              <MDBRow className="switch-download-app-container">
                          <MDBCol>
                            <div>    
                            <a href='https://apps.apple.com/app/id1500409256' target="_blank"><img src={require('../../../images/ios.png')} className="fullImg app-store" /></a>
                            <a href='https://play.google.com/store/apps/details?id=com.brightapp' target="_blank">
                              <img
                                src={require("../../../images/google.png")}
                                className='fullImg app-store'
                              />
                            </a></div>
                          </MDBCol>
                        </MDBRow>
            </MDBCol>

            {/*  Great Value */}
            <MDBCol sm='6' md='4' lg='4' className='text-center'>
              <div>
                <a href={`/${this.state.jurisdiction}/join`} className='btn'>
                  Join Now
                </a>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        {/* Social section */}
        <MDBContainer className='social socialBorder mt-4 p-4 text-center'>
          <MDBRow>
            <MDBCol sm='12'>
              <p className='social followUs'>
                <span className='mt-megan brightTeal-text mr-3'>
                  Follow us on our journey
                </span>

                <a
                  className='Footer-Link-Facebook'
                  href='https://www.facebook.com/BrightEnergySupplier/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fab fa-facebook fa-3x pr-2' />
                </a>
                <a
                  className='Footer-Link-Twitter'
                  href='https://twitter.com/bright_Ireland'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fab fa-twitter fa-3x pr-2' />
                </a>
                <a
                  className='Footer-Link-Instagram'
                  href='https://www.instagram.com/brightenergysupplier/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fab fa-instagram fa-3x pr-2' />
                </a>
                {/* <a
                  className='Footer-Link-Youtube'
                  href='https://youtube.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fab fa-youtube fa-3x pr-2' />
                </a> */}
                <a
                  className='Footer-Link-LinkedIn'
                  href='https://www.linkedin.com/company/brightenergysupplier'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fab fa-linkedin fa-3x pr-2' />
                </a>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        {/* Latest News Section */}
        <div className='latest-news-section'>
          <MDBContainer className='large-padding'>
            <MDBRow>
              <MDBCol sm='12' md='6' lg='8' className='text-left'>
                <div className='w-100 p-2'>
                  <h2>Latest News</h2>
                </div>
              </MDBCol>
              {/* <MDBCol
                sm='12'
                md='6'
                lg='4'
                className='text-right show-for-medium'
              >
                <a
                  href={`/${this.state.jurisdiction}/blog`}
                  className='btn more-news'
                >
                  View all latest news
                </a>
              </MDBCol> */}
            </MDBRow>

            <MDBRow className='swiper-slider-container '>
              <Swiper {...newsSwiper}>
                {this.props.blogData && this.props.blogData[0] != null
                  ? this.props.blogData.map((post) => (
                      <MDBCol sm='12' md='6' lg='4' className='p-0'>
                        <MDBCard>
                          <MDBCardBody>
                            <div className='card-image-container'>
                              <img className='card-image' src={post.imageUrl} />
                              <span>{post.categories}</span>
                            </div>

                            <div className='card-content'>
                              <MDBCardTitle>{post.title}</MDBCardTitle>
                              <MDBCardText>
                                {post.summary.slice(0, 150)}....
                              </MDBCardText>
                              <div>
                                <a
                                  className='btn'
                                  href={
                                    `/${this.state.jurisdiction}/blog/post?title=` +
                                    post.title.replace(/ /g, "-")
                                  }
                                >
                                  Read more
                                </a>
                              </div>
                            </div>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    ))
                  : null}
              </Swiper>
            </MDBRow>

            <MDBRow>
              <MDBCol
                sm='12'
                md='6'
                lg='4'
                className='text-right hide-for-medium'
              >
                {/* <a href='/quote' className='btn more-news'>
                  View all latest news
                </a> */}
              </MDBCol>
            </MDBRow>
          </MDBContainer>

          {/* On a mission */}
          <NIOnAMission />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    blogData: store.blog.postData,
  };
};

const actionCreators = {
  getBlogPosts: blogActions.getAll,
};

export default connect(mapStateToProps, actionCreators)(NIOurMission);
