import { switchConstants } from "../constants";
import { switchService } from "../services";

export const switchActions = {
  getSwitchJourney,
  createSwitchJourney,
  updateSwitchJourney,
  addressLookup,
  createNISwitchJourney,
  addressLookupNI,
};

// Get Switch Journey
function getSwitchJourney(id) {
  return (dispatch) => {
    // Request
    dispatch(getSwitchJourneyRequest());

    // dispatch(success(details));
    switchService.getSwitch(id).then(
      (response) => {
        dispatch(getSwitchJourneySuccess(response.data));
      },
      (error) => {
        dispatch(getSwitchJourneyFailure([error.response.data.message]));
      }
    );
  };
  function getSwitchJourneyRequest(dbData) {
    return {
      type: switchConstants.GET_SWITCHJOURNEY_REQUEST,
      payload: dbData,
    };
  }
  function getSwitchJourneySuccess(data) {
    return {
      type: switchConstants.GET_SWITCHJOURNEY_SUCCESS,
      payload: data,
    };
  }
  function getSwitchJourneyFailure(error) {
    return {
      type: switchConstants.GET_SWITCHJOURNEY_FAILURE,
      payload: error,
    };
  }
}

// Create initial journey
function createSwitchJourney(values, pageStatus, nextPage, jurisdiction) {
  return (dispatch) => {
    // Request
    dispatch(createSwitchJourneyRequest(values));

    // dispatch(success(details));
    switchService.createSwitch(values).then(
      (response) => {
        dispatch(createSwitchJourneySuccess(response.data, values));
        if (response.status == 200) {
          // Save Switch ID to Local Storage
          localStorage.setItem("switchID", response.data);

          // Update section status
          localStorage.setItem(pageStatus, true);

          // Redirect To Next Page
          window.location.href = "/" + jurisdiction + "/switch/" + nextPage;
        }
      },
      (error) => {
        dispatch(createSwitchJourneyFailure([error.response.data.message]));
      }
    );
  };

  function createSwitchJourneyRequest(dbData) {
    return {
      type: switchConstants.CREATE_SWITCHJOURNEY_REQUEST,
      payload: dbData,
    };
  }

  function createSwitchJourneySuccess(data, values) {
    return {
      type: switchConstants.CREATE_SWITCHJOURNEY_SUCCESS,
      payload: { journeyID: data, values: values },
    };
  }

  function createSwitchJourneyFailure(error) {
    return {
      type: switchConstants.CREATE_SWITCHJOURNEY_FAILURE,
      payload: error,
    };
  }
}

// Update Switch Journey
function updateSwitchJourney(
  id,
  values,
  pageStatus,
  nextPage,
  paymentMethod,
  jurisdiction
) {
  return (dispatch) => {
    // Request
    dispatch(updateSwitchJourneyRequest(id, values));

    // dispatch(success(details));
    switchService.updateSwitch(id, values).then(
      (response) => {
        dispatch(updateSwitchJourneySuccess(id, values));
        if (response.status == 200) {
          // Update section status
          localStorage.setItem(pageStatus, true);

          // if (keypad && nextPage == "payment" && jurisdiction == "ni") {
          //   window.location.href = "/" + jurisdiction + "/switch/summary";
          // } else

          if (paymentMethod == "VariableDirectDebit" && nextPage == "summary") {
            window.location.href =
              "/" + jurisdiction + "/switch/securitydeposit";
          } else {
            // Redirect To Next Page
            window.location.href = "/" + jurisdiction + "/switch/" + nextPage;
          }

          // if (paymentMethod == "VariableDirectDebit" && nextPage == "summary") {
          //   window.location.href =
          //     "/" + jurisdiction + "/switch/securitydeposit";
          // } else {
          //   window.location.href = "/" + jurisdiction + "/switch/" + nextPage;
          // }
        }
      },
      (error) => {
        dispatch(updateSwitchJourneyFailure([error.response.data.message]));
      }
    );
  };

  function updateSwitchJourneyRequest(dbData) {
    return {
      type: switchConstants.UPDATE_SWITCHJOURNEY_REQUEST,
      payload: dbData,
    };
  }

  function updateSwitchJourneySuccess(val) {
    return {
      type: switchConstants.UPDATE_SWITCHJOURNEY_SUCCESS,
      payload: val,
    };
  }

  function updateSwitchJourneyFailure(error) {
    return {
      type: switchConstants.UPDATE_SWITCHJOURNEY_FAILURE,
      payload: error,
    };
  }
}

// Address lookup ROI
function addressLookup(mprn) {
  return (dispatch) => {
    // Request
    dispatch(addressLookupRequest(mprn));

    // dispatch(success(details));
    switchService.addressLookupService(mprn).then(
      (response) => {
        dispatch(addressLookupSuccess(response.data, mprn));
      },
      (error) => {
        var message = "Oops! looks like that MPRN does not match an address. \nPlease check your MPRN and try again. \n Or call us on: 015881777";

        try {
          if ((error.response.data).includes("Commercial")) {
            message = error.response.data;
          }
        }
        catch{}

        dispatch(addressLookupFailure(message));
      }
    );
  };

  function addressLookupRequest(dbData) {
    return {
      type: switchConstants.ADDRESS_LOOKUP_REQUEST,
      payload: dbData,
    };
  }

  function addressLookupSuccess(data, mprn) {
    return {
      type: switchConstants.ADDRESS_LOOKUP_SUCCESS,
      payload: data,
      mprn: mprn,
    };
  }

  function addressLookupFailure(message) {
    return {
      type: switchConstants.ADDRESS_LOOKUP_FAILURE,
      payload:
        message
    };
  }
}

// Create NI initial journey
function createNISwitchJourney(values, pageStatus, nextPage, jurisdiction) {
  return (dispatch) => {
    // Request
    dispatch(createSwitchJourneyRequest(values));

    // dispatch(success(details));
    switchService.createNISwitch(values).then(
      (response) => {
        dispatch(createSwitchJourneySuccess(response.data, values));
        if (response.status == 200) {
          // Save Switch ID to Local Storage
          localStorage.setItem("switchID", response.data);

          if (values.keypad) {
            localStorage.setItem("keypad", true);
          }

          // Update section status
          localStorage.setItem(pageStatus, true);

          // Redirect To Next Page
          window.location.href = "/" + jurisdiction + "/join/" + nextPage;
        }
      },
      (error) => {
        dispatch(createSwitchJourneyFailure([error.response.data.message]));
      }
    );
  };

  function createSwitchJourneyRequest(dbData) {
    return {
      type: switchConstants.CREATE_SWITCHJOURNEY_REQUEST,
      payload: dbData,
    };
  }

  function createSwitchJourneySuccess(data, values) {
    return {
      type: switchConstants.CREATE_SWITCHJOURNEY_SUCCESS,
      payload: { journeyID: data, values: values },
    };
  }

  function createSwitchJourneyFailure(error) {
    return {
      type: switchConstants.CREATE_SWITCHJOURNEY_FAILURE,
      payload: error,
    };
  }
}

// Address lookup NI
function addressLookupNI(postcode) {
  return (dispatch) => {
    // Request
    dispatch(addressLookupNIRequest(postcode));

    // dispatch(success(details));
    switchService.addressLookupNIService(postcode).then(
      (response) => {
        dispatch(addressLookupNISuccess(response.data, postcode));
        // if (response.status == 200) {
        //   console.log(response.data);
        // }
      },
      (error) => {
        dispatch(addressLookupNIFailure([error.response]));
      }
    );
  };

  function addressLookupNIRequest(dbData) {
    return {
      type: switchConstants.ADDRESS_LOOKUP_NI_REQUEST,
      payload: dbData,
    };
  }

  function addressLookupNISuccess(data, postcode) {
    return {
      type: switchConstants.ADDRESS_LOOKUP_NI_SUCCESS,
      payload: data,
      postcode: postcode,
    };
  }

  function addressLookupNIFailure() {
    return {
      type: switchConstants.ADDRESS_LOOKUP_NI_FAILURE,
      payload:
        "Oops! looks like that postcode does not match an address. \nPlease check your postcode and try again. \n Or call us on: 015881777",
    };
  }
}
