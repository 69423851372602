import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBInput, MDBAlert } from "mdbreact";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentSuccess } from "./PaymentSuccess";
import { PaymentFailure } from "./PaymentFailure";
import Loader from "../../../../Shared/Loader";
const LoadConfig = require("../../../../../config");
const config = LoadConfig.default;

// Custom styling can be passed to options when creating an Element.
const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#31e51a",
      color: "#001d28",
      // fontWeight: 500,
      fontFamily: "Montserrat, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#757575" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

export const PaymentDetailsForm = (props) => {
  const jurisdiction = "ni";
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [postcode, setPostcode] = useState();
  const [clSec, setClSec] = useState();
  const [clIntent, setClIntent] = useState();
  const [error, setError] = useState();
  const [showPaymentDetails, setShowPaymentDetails] = useState(true);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showPaymentFailure, setShowPaymentFailure] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    let secret = localStorage.getItem("secret");
    console.log("secret: " + secret);
    setClSec(secret);

    let intent = localStorage.getItem("paymentIntent");
    setClIntent(intent);

    console.log(props.values);
  }, []);

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    setShowLoader(true);
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const billingAddress = {
      line1: address,
      postal_code: postcode,
    };

    const result = await stripe.confirmCardPayment(clSec, {
      payment_method: {
        card,
        billing_details: {
          name,
          address: billingAddress,
          email: email,
        },
      },
    });
    if (result.error) {
      // Inform the user if there was an error.
      setShowPaymentDetails(false);
      setShowPaymentSuccess(false);
      setShowPaymentFailure(true);
      setShowLoader(false);
    } else {
      setError(null);
      setShowPaymentDetails(false);
      setShowPaymentSuccess(true);
      setShowPaymentFailure(false);
      setShowLoader(false);
    }
  };

  const { nextStep } = props;

  return (
    <div>
      {showLoader ? <Loader /> : null}

      <MDBRow className='my-3'>
        <h4>Security Deposit</h4>
      </MDBRow>

      {showPaymentDetails ? (
        <div>
          <MDBRow className='my-3'>
            <MDBCol>
              {/* Deposit message */}
              <MDBAlert className='blue-highlight'>
                <p className='black-text'>
                  Security Deposit: We want to keep our costs as low as possible
                  so that we can keep your costs low. To reduce the risk of bad
                  debt we require all customers not paying in advance by direct
                  debit to pay <b className='font-weight-bolder'>£125</b>{" "}
                  security deposit. Details of our security deposit policy can
                  be found{" "}
                  <a
                    href={`/${jurisdiction}/ourpolicies#security-deposit-policy`}
                    target='_blank'
                  >
                    <b className='font-weight-bolder'>here</b>
                  </a>
                </p>
              </MDBAlert>
            </MDBCol>
          </MDBRow>
          <form onSubmit={handleSubmit}>
            <MDBRow center>
              <MDBCol size='9'>
                <MDBInput
                  label='Name'
                  type='text'
                  name='name'
                  onChange={(e) => setName(e.target.value)}
                  outline
                  required
                />
                <MDBInput
                  label='Email'
                  type='email'
                  name='email'
                  onChange={(e) => setEmail(e.target.value)}
                  outline
                  required
                />
                <MDBInput
                  label='Address'
                  type='text'
                  name='address'
                  onChange={(e) => setAddress(e.target.value)}
                  outline
                  required
                />
                <MDBInput
                  label='City'
                  type='text'
                  name='city'
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  outline
                  required
                />
                <MDBInput
                  label='Postcode'
                  type='text'
                  name='postcode'
                  onChange={(e) => setPostcode(e.target.value)}
                  outline
                  required
                />
                <div class='form-row'>
                  <CardElement
                    id='card-element'
                    options={CARD_OPTIONS}
                    onChange={handleChange}
                    className='w-100 card-form-control mt-4'
                  />
                  <div className='card-errors' role='alert'>
                    {error}
                  </div>
                </div>
                <br />
                <button type='submit' className='btn w-100'>
                  Confirm payment
                </button>
              </MDBCol>
            </MDBRow>
          </form>
        </div>
      ) : null}

      {/* Payment success */}
      {showPaymentSuccess ? <PaymentSuccess nextStep={nextStep} /> : null}

      {/* Payment failure  */}
      {showPaymentFailure ? <PaymentFailure /> : null}
    </div>
  );
};
