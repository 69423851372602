import { keypadVendService } from "../services";
import { keypadServiceConstants } from "../constants";

export const keypadVendActions = {
    getKeypadStatus
};

function getKeypadStatus() {
    return dispatch => {
        dispatch(getKeypadServiceRequest());
        keypadVendService.getKeypadVendService().then(
            response => {
                dispatch(getKeypadServiceSuccess(response.data));
            },
            error => {
                dispatch(getKeypadServiceFailure(error));
            }
        )
    };

    function getKeypadServiceRequest() {
        return {
            type: keypadServiceConstants.GET_KEYPADSERVICE_REQUEST,
        };
    }

    function getKeypadServiceSuccess(dbData) {
        return {
            type: keypadServiceConstants.GET_KEYPADSERVICE_SUCCESS,
            payload: dbData
        };
    }

    function getKeypadServiceFailure(error) {
        return {
            type: keypadServiceConstants.GET_KEYPADSERVICE_FAILURE,
            payload: error
        };
    }
}