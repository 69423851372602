import { agencyConstants } from "../constants";

let defaultState = {
  data: {},
  electricityProduct: [],
  error: "",
  isLoading: false,
  tenantInSuccess: "",
  tenantOutSuccess: "",
  addPropertySuccess: "",
};

const agencyReducer = (state = defaultState, action) => {
  switch (action.type) {
    //Get Product Ref
    case agencyConstants.PRODUCT_REF_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case agencyConstants.PRODUCT_REF_SUCCESS:
      return {
        ...state,
        electricityProduct: action.payload,
        isLoading: false,
      };

    case agencyConstants.PRODUCT_REF_FAILURE:
      return {
        error: action.payload,
        isLoading: false,
      };

    case agencyConstants.ADD_PROPERTY_REQUEST:
      return {
        ...state,
        isLoading: true,
        addPropertySuccess: "",
      };

    case agencyConstants.ADD_PROPERTY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        addPropertySuccess: true,
      };

    case agencyConstants.ADD_PROPERTY_FAILURE:
      return {
        error: action.payload,
        isLoading: false,
        addPropertySuccess: false,
      };

    case agencyConstants.TENANT_MOVEIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        tenantInSuccess: "",
      };

    case agencyConstants.TENANT_MOVEIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        tenantInSuccess: true,
      };

    case agencyConstants.TENANT_MOVEIN_FAILURE:
      return {
        error: action.payload,
        isLoading: false,
        tenantInSuccess: false,
      };

      case agencyConstants.TENANT_MOVEOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        tenantOutSuccess: "",
      };

    case agencyConstants.TENANT_MOVEOUT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        tenantOutSuccess: true,
      };

    case agencyConstants.TENANT_MOVEOUT_FAILURE:
      return {
        error: action.payload,
        isLoading: false,
        tenantOutSuccess: false,
      };

    // Default
    default:
      return state;
  }
};

export default agencyReducer;
