import React, { Component } from "react";
import { MDBCol, MDBRow, MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import { connect } from "react-redux";
import { quoteActions } from "../../../../../../redux/actions/quoteActions";
const LoadConfig = require("../../../../../../config");
const config = LoadConfig.default;

export class KeypadPowershift extends Component {
  state = {
    jurisdiction: "ni",
  };

  render() {
    return (
      <div>
        <MDBRow className="mb-3">
          <MDBCol size="2" />
          <MDBCol lg="8" md="8" sm="12" className="text-center">
            <MDBTable className="tarriff-table">
              {/* <MDBTableHead>
                <tr>
                  <th colSpan='5' className='bright'>
                    Bright economy 7 powershift
                  </th>
                </tr>
              </MDBTableHead> */}
              <MDBTableBody>
                <tr>
                  <td></td>
                  <td colspan="2" width="189">
                    <p>Unit Rate (pence per kWh)</p>
                  </td>
                  <td colspan="2" width="195">
                    <p>Standing Charge (pence per day)</p>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <p>Excl VAT</p>
                  </td>
                  <td>
                    <p>Incl VAT</p>
                  </td>
                  <td>
                    <p>Excl VAT</p>
                  </td>
                  <td>
                    <p>Incl VAT</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Rate one</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_unit1_exVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_unit1_incVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_standing_exVat.toFixed(2)}</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_standing_incVat.toFixed(2)}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Rate two</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_unit2_exVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_unit2_incVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_standing_exVat.toFixed(2)}</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_standing_incVat.toFixed(2)}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Rate three</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_unit3_exVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_unit3_incVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_standing_exVat.toFixed(2)}</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_standing_incVat.toFixed(2)}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Rate four</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_unit4_exVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_unit4_incVat.toFixed(3)}</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_standing_exVat.toFixed(2)}</p>
                  </td>
                  <td>
                    <p>{config.kp_powershift_standing_incVat.toFixed(2)}</p>
                  </td>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBCol>
          <MDBCol size="2" />
        </MDBRow>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    quoteData: store.quote.quoteData,
  };
};

const actionCreators = {
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(KeypadPowershift);
