import axios from "axios";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const appPromotionsService = {
  getAppPromotionsService,
};

// Get Meter Reading
function getAppPromotionsService(jurisdiction) {
  return axios({
    method: "get",
    url: `${config.apiUrl}/apppromotions/web/${jurisdiction}`,
  }).then((response) => {
    return response;
  });
}
