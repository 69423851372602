import React, { Component } from "react";
import { MDBAnimation, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBRow, } from "mdbreact";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import Swiper from 'react-id-swiper';
import ReactPlayer from 'react-player'


class Template extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postcode: ''
        }
    };

    // Accordion State https://www.npmjs.com/package/react-accessible-accordion
    state = {
        activeItem: "1"
    };
    toggle = tab => e => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
        }
    };

    render() {

        // Generator Seciton Swiper https://swiperjs.com/api/
        const generatorSwiper = {
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 1000,
            loop: true,
            effect: 'fade',
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

        }

        // News Seciton Swiper https://swiperjs.com/api/
        const newsSwiper = {
            slidesPerView: 3,
            spaceBetween: 0,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            speed: 1000,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
            },

            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                },

                768: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },

               1024: {
                    slidesPerView: 3,
                   spaceBetween: 0,
                }
            }



        }


        // Trust Pilot Seciton Swiper https://swiperjs.com/api/
        const  trustpilotSwiper = {
            slidesPerView: 3,
            spaceBetween: 0,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            speed: 1000,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
            },

            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                },

                768: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },

                1024: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                }
            }


        }


        return (

            // To target specific components on a page give the page a name for example.... <div className="default-page Home-Page">
            <div className="default-page">

                {/* Home Page Hero */}
                <MDBRow className="home-hero home-background-image">

                    <MDBContainer>
                        <MDBRow>
                            <MDBCol sm="12" md="9" lg="6" className="text-left content">
                                <h1 className="white-text">Be Futurekind.</h1>
                                <p className="white-text">This the new energy generation. 100% green electricity powered by only the good stuff</p>

                                <div className="quote-section">
                                    <div className="content">
                                        <div className="input-container">
                                            <input className="quote-input" placeholder="Postcode" />
                                            <a href="" className="btn">Get a Quote</a>
                                        </div>
                                    </div>
                                </div>

                            </MDBCol>

                        </MDBRow>
                    </MDBContainer>


                    <MDBContainer>
                        <MDBRow className="hero-icons-container">
                            <MDBCol sm="6" md="6" lg="3" className="text-left icons-container">
                                <div className="image-container">
                                    <img src={require('../../images/home-page/clean-energy-icon.png')}></img>
                                </div>
                                <h3>100% Clean Energy</h3>

                            </MDBCol>
                            <MDBCol sm="6" md="6" lg="3" className="text-left icons-container">
                                <div className="image-container">
                                    <img src={require('../../images/home-page/easy-to-switch-icon.png')}></img>
                                </div>


                                <h3>We make it  easy to switch</h3>

                            </MDBCol>
                            <MDBCol sm="6" md="6" lg="3" className="text-left icons-container">
                                <div className="image-container">
                                    <img src={require('../../images/home-page/transparent-pricing-icon.png')}></img>
                                </div>


                                <h3>Transparent  pricing for all</h3>

                            </MDBCol>
                            <MDBCol sm="6" md="6" lg="3" className="text-left icons-container">
                                <div className="image-container">
                                    <img src={require('../../images/home-page/manage-account-icon.png')}></img>
                                </div>


                                <h3>Managing your account is simple</h3>

                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>

                </MDBRow>


                {/* Hero with background image */}
                <MDBRow className="small-hero-with-background-image small-hero-background-image-1">
                    <div className="gradient-background"></div>
                    <MDBContainer>
                        <MDBRow >
                            <MDBCol sm="12" md="12" lg="12">
                                <h1>Hero Text</h1>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBRow>

                {/* Hero with background device */}
                <MDBRow className="small-hero">
                    <MDBContainer>
                        <MDBRow >
                            <MDBCol sm="12" md="8" lg="8">
                                <h1>Hero Text</h1>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBRow>


                {/* Quote Block */}
                <MDBContainer className="quote-section">
                    <MDBRow className="large-padding">

                        <MDBCol sm="12" md="8" lg="5" className="content">
                            <h2>This is a quote content block</h2>

                            <div className="input-container">
                                <input className="quote-input" placeholder="Postcode" />
                                <a href="" className="btn">Get a Quote</a>
                            </div>


                        </MDBCol>
                    </MDBRow>
                </MDBContainer>



                {/* Text & Image Section */}
                <div className="grey-background">
                    <MDBContainer className="text-left-image-right-section">
                        <MDBRow className="large-padding">
                            <MDBCol sm="12" md="12" lg="6" className="content">
                                <h2>This is a large, <br /> bold heading</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum ullamcorper mi vel feugiat. Sed sodales, magna faucibus pellentesque rutrum, nulla quam mattis nisi, ac sollicitudin nulla augue et massa. Fusce molestie eros non sapien iaculis cursus in nec nibh. Suspendisse egestas congue convallis.</p>
                                <a href="" className="btn">Call to Action</a>
                            </MDBCol>

                            <MDBCol sm="12" md="12" lg="6">
                                <img src={('https://www.aluminati.net/wp-content/uploads/2016/03/img-placeholder.png')}  />
                            </MDBCol>

                        </MDBRow>
                    </MDBContainer>
                </div>


                {/* Text & Image Section */}
                <div>
                    <MDBContainer className="text-right-image-left-section">
                        <MDBRow className="large-padding">

                            <MDBCol sm="12" md="12" lg="6" className="show-for-large">
                                <img src={('https://www.aluminati.net/wp-content/uploads/2016/03/img-placeholder.png')}  />
                            </MDBCol>

                            <MDBCol sm="12" md="12" lg="6" className="content">
                                <h2>This is a large, <br /> bold heading</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum ullamcorper mi vel feugiat. Sed sodales, magna faucibus pellentesque rutrum, nulla quam mattis nisi, ac sollicitudin nulla augue et massa. Fusce molestie eros non sapien iaculis cursus in nec nibh. Suspendisse egestas congue convallis.</p>
                                <a href="" className="btn">Call to Action</a>
                            </MDBCol>

                            <MDBCol sm="12" md="12" lg="6" className="hide-for-large">
                                <img src={('https://www.aluminati.net/wp-content/uploads/2016/03/img-placeholder.png')}  />
                            </MDBCol>

                        </MDBRow>
                    </MDBContainer>
                </div>


                {/* Text with Icons Section */}
                <div className="grey-background">
                    <MDBContainer className="text-icons-section large-padding">

                        <MDBRow>
                            <MDBCol sm="12" md="12" lg="8" className="content">
                                <h2>This is a large, bold heading</h2>
                                <h3>This a small bold heading</h3>
                                <p>This is a regular heading</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol sm="6" md="3" lg="3" className="text-center icons-container">
                                <img src={require('../../images/home-page/clean-energy-icon.png')}></img>
                                <h5>100% <br /> Clean Energy</h5>

                            </MDBCol>
                            <MDBCol sm="6" md="3" lg="3" className="text-center icons-container">
                                <img src={require('../../images/home-page/easy-to-switch-icon.png')}></img>
                                <h5>We make it <br /> easy to switch</h5>

                            </MDBCol>
                            <MDBCol sm="6" md="3" lg="3" className="text-center icons-container">
                                <img src={require('../../images/home-page/transparent-pricing-icon.png')}></img>
                                <h5>Transparent <br /> pricing for all</h5>

                            </MDBCol>
                            <MDBCol sm="6" md="3" lg="3" className="text-center icons-container">
                                <img src={require('../../images/home-page/manage-account-icon.png')}></img>
                                <h5>Managing your <br /> account is simple</h5>

                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>


                {/* Video Block*/}
                <div className="video-container">
                    <MDBContainer className="video-section">
                        <MDBRow className="large-padding">
                            <MDBCol sm="12" md="12" lg="12" className="text-center video-title" >
                                <h2>This is the new<br /> energy generation</h2>
                            </MDBCol>

                            <MDBCol sm="12">

                                <div className="video-container">
                                    <div className='player-wrapper'>

                                        {/* https://www.npmjs.com/package/react-player */}
                                        <ReactPlayer
                                            ref='player'
                                            url='https://vimeo.com/235215203'
                                            playing={this.state.playing}
                                            width='100%'
                                            height='100%'
                                            className='react-player'
                                        />

                                    </div>
                                    <button className="play-button" onClick={() => this.setState({ playing: true , opacity: '0', events: 'none' })} style={{opacity:this.state.opacity, pointerEvents:this.state.events}}>
                                        {/* The video placeholder will need declared via css*/}
                                        <div className="video-placeholder-image"></div>
                                    </button>

                                </div>

                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>


                {/* Accordion Section */}
                <div className="grey-background">
                    <MDBContainer className="accordion-section large-padding">

                        <MDBRow>
                            <MDBCol md="12" className="content" >
                                <AccordionItemButton>
                                    <p className="faq-question"><span className="plus-icon ">+</span> I'm moving house - what do I need to do?</p>
                                </AccordionItemButton>

                                <div className="white-curved-tile ">
                                    <MDBRow>
                                        <MDBCol md="12" >
                                            <Accordion>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            <p className="faq-question"><span className="plus-icon ">+</span> I'm moving house - what do I need to do?</p>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>
                                                            Exercitation in fugiat est ut ad ea cupidatat ut in
                                                            cupidatat occaecat ut occaecat consequat est minim minim
                                                            esse tempor laborum consequat esse adipisicing eu
                                                            reprehenderit enim.
                                                        </p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            <p className="faq-question"><span className="plus-icon ">+</span> I'm moving house - what do I need to do?</p>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>
                                                            In ad velit in ex nostrud dolore cupidatat consectetur
                                                            ea in ut nostrud velit in irure cillum tempor laboris
                                                            sed adipisicing eu esse duis nulla non.
                                                        </p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            </Accordion>



                                        </MDBCol>

                                    </MDBRow>
                                </div>

                            </MDBCol>
                        </MDBRow>

                    </MDBContainer>

                </div>




                {/* Text with background image section */}
                <div className=" text-background-image-1" >
                    <MDBContainer className="text-background-image-section">
                        <MDBRow className=" large-padding">
                            <MDBCol sm="12" md="9" lg="6" className="content">
                                <h2>This is a large, <br /> bold heading</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum ullamcorper mi vel feugiat. Sed sodales, magna faucibus pellentesque rutrum, nulla quam mattis nisi, ac sollicitudin nulla augue et massa. Fusce molestie eros non sapien iaculis cursus in nec nibh. Suspendisse egestas congue convallis.</p>
                                <a href="" className="btn">Call to Action</a>
                            </MDBCol>


                        </MDBRow>
                    </MDBContainer>
                </div>


                {/* Two Blocks Section */}
                <div className=" grey-background" >
                    <MDBContainer className="two-block-section">
                        <MDBRow className="large-padding">

                            <MDBCol sm="12" md="12" lg="6">
                                <div className="content background-image-1 dark-text">
                                    <h2>Our Tariff</h2>
                                    <p>Lorem ipsum dolor sit ame lestie eros non sapien iaculis cursus in nec nibh. Suspendisse egestas congue convallis.</p>
                                    <a href="" className="btn">Discover More</a>
                                </div>
                            </MDBCol>

                            <MDBCol sm="12" md="12" lg="6">
                                <div className="content background-image-2 light-text">
                                    <h2>Our Energy</h2>
                                    <p>Lorem ipsum dolor sit ame lestie eros non sapien iaculis cursus in nec nibh. Suspendisse egestas congue convallis.</p>
                                    <a href="" className="btn">Discover More</a>

                                    <img className="tariff-quote" src={require('../../images/home-page/wholesale-costs.gif')}  />
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>


                {/* Our Generators Section */}
                <div className="our-generators-section" >
                    <MDBContainer>
                        <MDBRow className="large-padding">

                            <MDBCol sm="12" md="12" lg="12" className="text-center title-container">
                                <h2>Our Generators</h2>


                                <MDBRow className="swiper-slider-container ">


                                    <Swiper {...generatorSwiper}>

                                        {/* Swiper Slide */}
                                        <MDBCol sm="12" className="p-0">

                                            <MDBRow className="large-padding">
                                                <MDBCol sm="12" md="12" lg="6" className="map-image-container">
                                                    <img className="map-image" src={require('../../images/generator-section/map.png')}  />

                                                    <img className="wind-icon" src={require('../../images/generator-section/wind-map-pin-active.png')}  />
                                                    <img className="solar-icon" src={require('../../images/generator-section/solar-map-pin.png')}  />
                                                    <img className="hyrdo-icon" src={require('../../images/generator-section/hydro-map-pin.png')}  />
                                                </MDBCol>

                                                <MDBCol sm="12" md="12" lg="6" className="content">
                                                    <div className="white-curved-tile ">
                                                        <img src={require('../../images/generator-section/wind-icon.png')} className="hydro-icon" />

                                                        <div className="name-info">
                                                            <h2>Michael</h2>
                                                            <p className="location"><b>Location:</b> Dublin</p>
                                                            <p className="location"><b>Generation type:</b> Hydro</p>
                                                        </div>


                                                        <div className="main-content-container">
                                                            <div className="image-container">
                                                                <div className="background-image-1"></div>
                                                            </div>
                                                            <div className="text-content">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendumsodales quam mattis nulla augue et massa. Fusce molestie eros non sapien iaculis cursus in nec nibh. Suspendisse egestas congue convallis.</p>
                                                                <p>Lorem ipsum dolor sit amet, consectrum, nulla quam mattis nisi, ac sollicitudin nullolestie eros non sapien iaculis cursus in nec nibh. Suspendisse egestas congue convallis.</p>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </MDBCol>
                                            </MDBRow>

                                        </MDBCol>

                                        {/* Swiper Slide */}
                                        <MDBCol sm="12" className="p-0">

                                            <MDBRow className="large-padding">
                                                <MDBCol sm="12" md="12" lg="6" className="map-image-container">
                                                    <img className="map-image" src={require('../../images/generator-section/map.png')}  />

                                                    <img className="wind-icon" src={require('../../images/generator-section/wind-map-pin.png')}  />
                                                    <img className="solar-icon" src={require('../../images/generator-section/solar-map-pin-active.png')}  />
                                                    <img className="hyrdo-icon" src={require('../../images/generator-section/hydro-map-pin.png')}  />
                                                </MDBCol>

                                                <MDBCol sm="12" md="12" lg="6" className="content">
                                                    <div className="white-curved-tile ">
                                                        <img src={require('../../images/generator-section/solar-icon.png')} className="hydro-icon" />

                                                        <div className="name-info">
                                                            <h2>Jim</h2>
                                                            <p className="location"><b>Location:</b> Dublin</p>
                                                            <p className="location"><b>Generation type:</b> Hydro</p>
                                                        </div>


                                                        <div className="main-content-container">
                                                            <div className="image-container">
                                                                <div className="background-image-1"></div>
                                                            </div>
                                                            <div className="text-content">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendumsodales quam mattis nulla augue et massa. Fusce molestie eros non sapien iaculis cursus in nec nibh. Suspendisse egestas congue convallis.</p>
                                                                <p>Lorem ipsum dolor sit amet, consectrum, nulla quam mattis nisi, ac sollicitudin nullolestie eros non sapien iaculis cursus in nec nibh. Suspendisse egestas congue convallis.</p>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </MDBCol>
                                            </MDBRow>

                                        </MDBCol>


                                        {/* Swiper Slide */}
                                        <MDBCol sm="12" className="p-0">

                                            <MDBRow className="large-padding">
                                                <MDBCol sm="12" md="12" lg="6" className="map-image-container">
                                                    <img className="map-image" src={require('../../images/generator-section/map.png')}  />

                                                    <img className="wind-icon" src={require('../../images/generator-section/wind-map-pin.png')}  />
                                                    <img className="solar-icon" src={require('../../images/generator-section/solar-map-pin.png')}  />
                                                    <img className="hyrdo-icon" src={require('../../images/generator-section/hydro-map-pin-active.png')}  />
                                                </MDBCol>

                                                <MDBCol sm="12" md="12" lg="6" className="content">
                                                    <div className="white-curved-tile ">
                                                        <img src={require('../../images/generator-section/hydro-icon.png')} className="hydro-icon" />

                                                        <div className="name-info">
                                                            <h2>Dwight</h2>
                                                            <p className="location"><b>Location:</b> Dublin</p>
                                                            <p className="location"><b>Generation type:</b> Hydro</p>
                                                        </div>


                                                        <div className="main-content-container">
                                                            <div className="image-container">
                                                                <div className="background-image-1"></div>
                                                            </div>
                                                            <div className="text-content">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendumsodales quam mattis nulla augue et massa. Fusce molestie eros non sapien iaculis cursus in nec nibh. Suspendisse egestas congue convallis.</p>
                                                                <p>Lorem ipsum dolor sit amet, consectrum, nulla quam mattis nisi, ac sollicitudin nullolestie eros non sapien iaculis cursus in nec nibh. Suspendisse egestas congue convallis.</p>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </Swiper>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>






                {/* Switch Today Section */}
                <div className="switch-today grey-background">
                    <MDBContainer className="switch-today-container">
                        <MDBRow className="switch-today large-padding">
                            <MDBCol  sm="12" md="7" lg="5" className="title-container">
                                <div className="text-center">
                                    <h2 >Switch today... </h2>
                                    <p className="mt-megan brightGreen-text sub-heading-text">We handle the rest</p>
                                </div>
                            </MDBCol>


                            <MDBCol sm="12">
                                <MDBRow>
                                    <MDBCol sm="12" md="6" lg="4" className="step-container" >
                                        <MDBCard>
                                            <MDBCardBody className="text-center">
                                                <MDBCardTitle>Step 01</MDBCardTitle>
                                                <img src={require('../../images/home-page/switch-arrow-right.png')} className="switch-arrow-right" />
                                                <p className="switch-subheading">Register Online</p>
                                                <MDBCardText>
                                                    We have just one simple tariff that follows wholesale costs. Simply complete your switch online to
                                                    get our best possible price.
                                                </MDBCardText>
                                                <a href="/help/switchingtobright" className="btn" >Get Started</a>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>

                                    <MDBCol sm="12" md="6" lg="4" className="step-container" >
                                        <MDBCard>
                                            <MDBCardBody className="text-center">
                                                <MDBCardTitle>Step 02</MDBCardTitle>
                                                <img src={require('../../images/home-page/switch-arrow-two.png')} className="switch-arrow-left show-for-large" />
                                                <p className="switch-subheading">Switch to us</p>
                                                <MDBCardText>
                                                    We’ll inform your old supplier, there’s no engineer or installer visits and absolutely no disruption to your electricity supply.
                                                </MDBCardText>
                                                <a href="/help/switchingtobright" className="btn"  >About switching</a>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>

                                    <MDBCol sm="12" md="8" lg="4" className="step-container" >
                                        <MDBCard>
                                            <MDBCardBody className="text-center">
                                                <MDBCardTitle>Step 03</MDBCardTitle>
                                                <img src={require('../../images/home-page/switch-arrow-two.png')} className="switch-arrow-left hide-for-large" />
                                                <p className="switch-subheading">Join the Bright Community</p>
                                                <MDBCardText>
                                                    We are not just a supplier - we’re a community, standing together, prepared to change the game for the better.
                                                </MDBCardText>
                                                <MDBRow className="switch-download-app-container">
                                                    <MDBCol sm="12">
                                                        <a href=""><img src={require('../../images/ios.png')} className="fullImg app-store" /></a>
                                                        <a href=""><img src={require('../../images/google.png')} className="fullImg app-store" /></a>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>




                {/* Codes of Practice Section */}
                <div className="codes-of-practice">
                    <MDBContainer className="codes-of-practice-container">
                        <MDBRow className="large-padding">
                            <MDBCol sm="12" md="9" lg="5" className="title-container">
                                <div className="text-center">
                                    <h2>Codes of Practice</h2>
                                    <p>We offer a range of additional services, from help to pay your bills, to special medical assistance.</p>
                                </div>
                            </MDBCol>

                            <MDBCol sm="12">
                                <MDBRow>
                                    <MDBCol sm="12" md="6" lg="3" >
                                        <div className="codes-of-practice-tile text-center">
                                            <img src={require('../../images/stock/stock5.jpg')}></img>
                                            <div className="content">
                                                <h3>Complaints</h3>
                                                <a href="" download><i className="fa fa-download" aria-hidden="true"></i> <b>Download</b> (PDF, 354kb)</a>
                                            </div>

                                        </div>
                                    </MDBCol>

                                    <MDBCol sm="12" md="6" lg="3" >
                                        <div className="codes-of-practice-tile text-center">
                                            <img src={require('../../images/stock/stock5.jpg')}></img>
                                            <div className="content">
                                                <h3>Energy efficiency</h3>
                                                <a href="" download><i className="fa fa-download" aria-hidden="true"></i> <b>Download</b> (PDF, 354kb)</a>
                                            </div>
                                        </div>
                                    </MDBCol>

                                    <MDBCol sm="12" md="6" lg="3" >
                                        <div className="codes-of-practice-tile text-center">
                                            <img src={require('../../images/stock/stock5.jpg')}></img>
                                            <div className="content">
                                                <h3>Payment of bills</h3>
                                                <a href="" download><i className="fa fa-download" aria-hidden="true"></i> <b>Download</b> (PDF, 354kb)</a>
                                            </div>


                                        </div>
                                    </MDBCol>

                                    <MDBCol sm="12" md="6" lg="3" >
                                        <div className="codes-of-practice-tile text-center">
                                            <img src={require('../../images/stock/stock5.jpg')}></img>
                                            <div className="content">
                                                <h3>Provision of services</h3>
                                                <a href="" download><i className="fa fa-download" aria-hidden="true"></i> <b>Download</b> (PDF, 354kb)</a>
                                            </div>
                                        </div>
                                    </MDBCol>

                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>



                {/* Setup your account section */}
                <div className="setup-account-section">
                    <MDBRow className="brightTeal your-account-section">
                        <MDBContainer>
                            <MDBRow className="large-padding">
                                <MDBCol sm="12" md="12" lg="5" >
                                    <div className="w-100 p-2">
                                        <h2 className=" white-text">Set up your account</h2>
                                        <p className=" brightGreen-text">Download our app and join our bright community</p>
                                        <p className=" white-text">
                                            Setting up an account with Bright is really easy- you can do it on your app or on-line.
                                            Complete your switch, download our app and your good to go.
                                        </p>
                                        <ul className="white-text bullet-list">
                                            <li>View and make payments on the go</li>
                                            <li>Submit your meter reads anytime</li>
                                            <li>Full visibility of your usage</li>
                                            <li>Access your documents quickly & easily</li>
                                            <li>Read about our energy saving tips</li>
                                        </ul>
                                    </div>

                                    <MDBRow className="show-for-medium">
                                        <MDBCol sm="12" md="8" lg="8">
                                            <MDBRow>
                                                <MDBCol sm="12">
                                                    <a href=""><img src={require('../../images/ios.png')} className="fullImg app-store" /></a>
                                                    <a href=""><img src={require('../../images/google.png')} className="fullImg app-store" /></a>
                                                </MDBCol>
                                            </MDBRow>

                                        </MDBCol>

                                    </MDBRow>
                                </MDBCol>
                                <MDBCol sm="12" md="12" lg="7" className=" text-center">
                                    <img className="iphone-animation" src={require('../../images/home-page/bright-app-phone@2x.png')}  />

                                    <MDBRow className="hide-for-medium">
                                        <MDBCol sm="12" md="8" lg="8">
                                            <MDBRow>
                                                <MDBCol sm="12">
                                                    <a href=""><img src={require('../../images/ios.png')} className="fullImg app-store" /></a>
                                                    <a href=""><img src={require('../../images/google.png')} className="fullImg app-store" /></a>
                                                </MDBCol>
                                            </MDBRow>

                                        </MDBCol>

                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBRow>
                </div>


                {/* Latest News Section */}
                <div className="latest-news-section">
                    <MDBContainer className="large-padding">

                        <MDBRow>
                            <MDBCol sm="12" md="6" lg="8" className="text-left">
                                <div className="w-100 p-2">
                                    <h2>Latest News</h2>
                                </div>
                            </MDBCol>
                            <MDBCol sm="12" md="6" lg="4" className="text-right show-for-medium">
                                <a href="/quote" className="btn more-news" >View all latest news</a>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow className="swiper-slider-container ">


                            <Swiper {...newsSwiper}>

                                <MDBCol sm="12" md="6" lg="4" className="p-0">
                                    <MDBCard>
                                        <MDBCardBody>
                                            <div className="card-image-container">
                                                <img className="card-image" src={require('../../images/stock/stock5.jpg')}></img>
                                                <span>Category</span>
                                            </div>


                                            <div className="card-content">
                                                <p className="date">Date 1</p>
                                                <MDBCardTitle>News Heading 1</MDBCardTitle>
                                                <MDBCardText>
                                                    We're on a mission we can all get behind and be part of and we know its not always easy to see
                                                    how we can effect real change!!
                                                </MDBCardText>
                                                <a href="/help/switchingtobright" className="btn"  >Read more</a>
                                            </div>


                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg="4" className="p-0">
                                    <MDBCard>
                                        <MDBCardBody>
                                            <div className="card-image-container">
                                                <img className="card-image" src={require('../../images/stock/stock2.jpg')}></img>
                                                <span>Category</span>
                                            </div>


                                            <div className="card-content">
                                                <p className="date">Date 2</p>
                                                <MDBCardTitle>News Heading 2</MDBCardTitle>
                                                <MDBCardText>
                                                    We're on a mission we can all get behind and be part of and we know its not always easy to see
                                                    how we can effect real change!!
                                                </MDBCardText>
                                                <a href="/help/switchingtobright" className="btn"  >Read more</a>
                                            </div>


                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>


                            </Swiper>

                        </MDBRow>

                        <MDBRow>
                            <MDBCol sm="12" md="6" lg="4" className="text-right hide-for-medium">
                                <a href="/quote" className="btn more-news" >View all latest news</a>
                            </MDBCol>
                        </MDBRow>

                    </MDBContainer>

                </div>





                {/* Trust Pilot Section */}
                <div className="trustpilot-section grey-background">
                    <MDBContainer className="large-padding">
                        <MDBRow>
                            <MDBCol sm="12" className="text-center">
                                <h2>Renewable power to the people</h2>
                                <p className="mb-4">See what our Bright Community has to say</p>
                                <img className="trust-pilot-lovely-bunch" src={require('../../images/home-page/lovely-bunch.png')}  />

                            </MDBCol>
                        </MDBRow>

                        <MDBRow className="text-left swiper-slider-container trust-pilot-container">
                            <Swiper {...trustpilotSwiper}>

                                <MDBCol sm="12" md="6" lg="4">
                                    <MDBCard>
                                        <MDBCardBody>

                                            <div className="card-content trust-pilot-card">
                                                <div className="star-container">
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                </div>
                                                <MDBCardTitle>This is good.</MDBCardTitle>
                                                <MDBCardText>
                                                    We're on a mission we can all get behind and be part of and we know its!!
                                                </MDBCardText>
                                                <p className="name">- John Doe</p>
                                            </div>

                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg="4">
                                    <MDBCard>
                                        <MDBCardBody>

                                            <div className="card-content trust-pilot-card">
                                                <div className="star-container">
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                </div>
                                                <MDBCardTitle>This is amazing.</MDBCardTitle>
                                                <MDBCardText>
                                                    We're on a mission we can all get behind and be part of and we know!!
                                                </MDBCardText>
                                                <p className="name">- John Doe</p>
                                            </div>

                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg="4">
                                    <MDBCard>
                                        <MDBCardBody>

                                            <div className="card-content trust-pilot-card">
                                                <div className="star-container">
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                </div>
                                                <MDBCardTitle>Very Good Service.</MDBCardTitle>
                                                <MDBCardText>
                                                    We're on a mission we can all get behind and be part!!
                                                </MDBCardText>
                                                <p className="name">- John Doe</p>
                                            </div>

                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg="4">
                                    <MDBCard>
                                        <MDBCardBody>

                                            <div className="card-content trust-pilot-card">
                                                <div className="star-container">
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                    <img src={require('../../images/global/trust-pilot-star.png')}  />
                                                </div>
                                                <MDBCardTitle>Would highly reccomend.</MDBCardTitle>
                                                <MDBCardText>
                                                    We're on a mission we can all get behind and be part of and we know!!
                                                </MDBCardText>
                                                <p className="name">- John Doe</p>
                                            </div>

                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>


                            </Swiper>
                        </MDBRow>

                        <MDBRow className="trust-pilot-more-container">
                            <MDBCol sm="12" className="text-center">
                                <p>Rated <b>4.4</b> out of 5 based on <b>1,200</b> reviews on  <img src={require('../../images/global/trust-pilot-logo.png')}  /></p>
                                <p><b><a href="">Read more customer reviews...</a> </b></p>
                            </MDBCol>
                        </MDBRow>


                    </MDBContainer>
                </div>




            </div>




        )
    }
}

export default Template;
