import React, { Component } from "react";
import { MDBCol, MDBRow, MDBBtn, MDBAlert } from "mdbreact";
import { connect } from "react-redux";
import { quoteActions } from "../../../../redux/actions/quoteActions";
import Loader from "../../../Shared/Loader";

import $ from "jquery";

export class _FormMeter extends Component {
  state = {
    urbanRural: "Urban",
    meterType: "24hr",
    errorMessage: false,
    jurisdiction: "roi",
    homeCheck: false,
  };

  // Page load
  componentDidMount() {
    // Check for a quoteID in local storage. If not null get that quote by ID
    if (localStorage.getItem("quoteID") != null) {
      let quoteID = localStorage.getItem("quoteID");
      this.props.getQuote(quoteID);
    }
  }

  // Populate form on back
  async componentWillReceiveProps(nextProps) {
    // Get page status from local storage
    let pageStatus = JSON.parse(localStorage.getItem("meterUpdated"));

    // If page status e.g. pageUpdated = true -> populate the form
    if (pageStatus) {
      await this.setState({
        urbanRural: nextProps.quoteData.urbanRural,
        meterType: nextProps.quoteData.meterType,
      });
    }

    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle continue
  nextStep = (e) => {
    // Validate Form
    let validation = $("#meterDetails")[0].checkValidity();

    if (validation) {
      this.setState({ errorMessage: false });

      // Form data
      const data = {
        // Step one - Property
        propertyType: this.props.quoteData.propertyType,
        residents: this.props.quoteData.residents,

        // Step two (this step) - Meter
        urbanRural: this.state.urbanRural,
        meterType: this.state.meterType,
        electricityUsage: this.props.quoteData.electricityUsage,

        // Step three - Payment
        paymentMethod: this.props.quoteData.paymentMethod,
        monthlyPayment: this.props.quoteData.monthlyPayment,

        // Step four - Gas
        onGasTariff: this.props.quoteData.onGasTariff,
        interestedInBrightGas: this.props.quoteData.interestedInBrightGas,

        // Step four - Ev
        hasElectricVehicle: this.props.quoteData.hasElectricVehicle,
        vehicleMake: this.props.quoteData.vehicleMake,
      };

      // Quote id from local storage
      let id = localStorage.getItem("quoteID");

      // This Page (updates page status)
      let pageStatus = "meterUpdated";

      // Next Page URL
      let nextPage = "payment";

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Quote Journey
      this.props.updateQuote(id, data, pageStatus, nextPage, jurisdiction);
    } else {
      this.setState({ errorMessage: true });
    }
  };

  // Handle back button
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    return (
      <div className='quote-page'>
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        {/* Background Image */}
        <div className='device-image' />
        <form id='meterDetails'>
          <MDBCol className='text-center'>
            {/* Logo */}
            <MDBRow>
              <MDBCol lg='2' sm='12'>
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <img
                    src={require("../../../../images/global/bright-logo-dark.png")}
                    className='quote-logo mx-5 mt-5'
                  />
                </a>
              </MDBCol>
              <MDBCol size='10' />
            </MDBRow>

            {/* Home message */}
            {this.state.homeCheck ? (
              <div className='modalBG'>
                <div className='modalBox'>
                  <div className='white-curved-tile text-center'>
                    <h4>
                      All progress will be lost! <br />
                      Are you sure?
                    </h4>
                    <MDBRow>
                      <MDBCol lg='6' md='6' sm='12'>
                        <MDBBtn
                          className='cancel-button'
                          onClick={() =>
                            this.setState({ homeCheck: !this.state.homeCheck })
                          }
                        >
                          Cancel
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol lg='6' md='6' sm='12'>
                        <MDBBtn onClick={() => (window.location.href = "/")}>
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Error Message */}
            <MDBRow className='text-center mt-5'>
              <MDBCol size='4' />
              <MDBCol lg='4' md='4' sm='12'>
                {this.state.errorMessage == true ? (
                  <MDBAlert color='danger'>
                    <strong>Oops!</strong> Please check your fields below.
                  </MDBAlert>
                ) : null}
              </MDBCol>
              <MDBCol size='4' />
            </MDBRow>

            {/* Supply region */}
            <MDBRow className='mb-5'>
              <MDBCol size='3' />
              <MDBCol lg='6' md='6' sm='10' className='text-center'>
                {/* Question */}
                <h4 className='mb-4'>
                  What is your electricity supply region?
                </h4>
                {/* Urban */}
                <input
                  onClick={() => this.setState({ urbanRural: "Urban" })}
                  checked={this.state.urbanRural === "Urban" ? true : false}
                  className='form-check-input'
                  id='urban'
                  name='urbanRural'
                  required
                  type='radio'
                />
                <label for='urban' className='radioLabel'>
                  Urban
                </label>

                {/* Rural */}
                <input
                  onClick={() => this.setState({ urbanRural: "Rural" })}
                  checked={this.state.urbanRural === "Rural" ? true : false}
                  className='form-check-input'
                  id='rural'
                  name='urbanRural'
                  type='radio'
                />
                <label for='rural' className='radioLabel'>
                  Rural
                </label>
              </MDBCol>
              <MDBCol size='3' />
            </MDBRow>

            {/* Meter type */}
            <MDBRow className='mb-4'>
              <MDBCol size='3' />
              <MDBCol lg='6' md='6' sm='10' className='text-center'>
                {/* Question */}
                <h4 className='mb-4'>
                  Do you have an electricity NightSaver meter?
                </h4>
                {/* Urban */}
                <input
                  onClick={() => this.setState({ meterType: "NightSaver" })}
                  checked={this.state.meterType === "NightSaver" ? true : false}
                  className='form-check-input'
                  id='NightSaver'
                  name='meterType'
                  required
                  type='radio'
                />
                <label for='NightSaver' className='radioLabel'>
                  Yes
                </label>

                {/* Rural */}
                <input
                  onClick={() => this.setState({ meterType: "24hr" })}
                  checked={this.state.meterType === "24hr" ? true : false}
                  className='form-check-input'
                  id='24hr'
                  name='meterType'
                  type='radio'
                />
                <label for='24hr' className='radioLabel'>
                  No
                </label>
              </MDBCol>
              <MDBCol size='3' />
            </MDBRow>

            {/* Buttons */}
            <MDBRow className='mb-5'>
              <MDBCol size='3' />
              <MDBCol lg='6' md='6' sm='10' className='text-center'>
                {/* Back */}
                <a href={`/${this.state.jurisdiction}/quote`} className='mr-3'>
                  <MDBBtn className='btn switch-button'>Back</MDBBtn>
                </a>
                {/* Next Step */}
                <MDBBtn onClick={this.nextStep} className='btn'>
                  Continue
                </MDBBtn>
              </MDBCol>
              <MDBCol size='3' />
            </MDBRow>
          </MDBCol>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    quoteData: store.quote.quoteData,
    isLoading: store.quote.isLoading,
  };
};

const actionCreators = {
  updateQuote: quoteActions.updateQuoteJourney,
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(_FormMeter);
