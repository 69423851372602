import React from "react";
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
} from "mdbreact";

class SignupRequired extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
      firstName: "",
    };
  }

  // Page load
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    this.setState({
      jurisdiction: jurisdiction,
    });

    // Parse user object in local storage
    let user = JSON.parse(localStorage.getItem("user"));

    // If user existes set variables from parsed user object
    if (user) {
      let firstName = user.data.authenticateModel.firstName;

      this.setState({
        firstName: firstName,
      });
    }
  }

  render() {
    return (
      <div
        className='default-page home-page'
        style={{ backgroundColor: "#001d28" }}
      >
        {/* Hero with background device */}
        <MDBRow className='small-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='12' lg='12'>
                <h1>Hi {this.state.firstName},</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBContainer>
          {/* No Account */}
          <MDBRow className='justify-content-center text-center white-text'>
            <MDBCol size='6'>
              <h3 className='mt-5'>
              Looks like you have not switched yet or your switch is currently in progress to{" "}
                <b className='signup-required-megan'>
                  green
                  <a className='brightGreen-text' href='#cru'>
                    *
                  </a>
                </b>
                , affordable electricity on one simple tariff.
              </h3>
            </MDBCol>
          </MDBRow>

          {/* Buttons */}
          <MDBRow className='justify-content-center mt-5 text-center white-text pb-5'>
            <MDBCol lg='3' md='0' sm='0' />
            {/* Find out more */}
            <MDBCol lg='3' md='12' sm='12'>
              <a
                href={`/${this.state.jurisdiction}/about/switchingtobright`}
                className='btn'
              >
                Find our more
              </a>
            </MDBCol>
            {/* Get a quote */}
            <MDBCol lg='3' md='12' sm='12'>
              {this.state.jurisdiction=="roi" ? 
              <a href={`/roi/quote`} className='btn'>
                Get a quote
              </a>
              : 
              <a href={`/ni/join`} className='btn'>
                Join now
              </a>
              }
            </MDBCol>
            <MDBCol lg='3' md='0' sm='0' />
          </MDBRow>
        </MDBContainer>

        
      </div>
    );
  }
}

export default SignupRequired;
