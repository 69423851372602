import axios from "axios";
const LoadConfig = require("../../config");
const config = LoadConfig.default;


export const signinService = {
  loginService,
  logoutService,
  registerService,
  requestPasswordResetService,
  setNewPasswordService,
  getUserByResetIdService,
  switchAccountService,
};

// Login
function loginService(details) {
  return axios({
    method: "post",
    url: `${config.apiUrl}/users/authenticate`,
    data: details,
  }).then((response) => {
    localStorage.setItem("user", JSON.stringify(response));
    return response;
  });
}

// Logout Service
function logoutService() {
  // remove user from local storage to log user out
  localStorage.clear("user");
}

// Register
function registerService(details) {
  return axios({
    method: "post",
    url: `${config.apiUrl}/users/register`,
    data: details,
  }).then((response) => {
    return response;
  });
}

// Reset Password request
function requestPasswordResetService(details) {
  return axios({
    method: "put",
    url: `${config.apiUrl}/users/passwordreset`,
    data: details,
  }).then((response) => {
    return response;
  });
}

// Set new password
function setNewPasswordService(details, resetID) {
  return axios({
    method: "put",
    url: `${config.apiUrl}/users/setnewpassword/${resetID}`,
    data: details,
  }).then((response) => {
    return response;
  });
}

// Set new password
function getUserByResetIdService(resetID) {
  return axios({
    method: "get",
    url: `${config.apiUrl}/users/getbyresetid/${resetID}`,
  }).then((response) => {
    return response;
  });
}


// Switch account
function switchAccountService(userId, propId, jurisdiction) {
  let user = localStorage.getItem("user");

  if (user) {
    let parseUser = JSON.parse(user);
    let userId = parseUser.data.authenticateModel.id;
    let userToken = parseUser.data.authenticateModel.token;
  

  return axios({
    method: "post",
    url: `${config.apiUrl}/users/switchaccount/${userId}/${propId}/${jurisdiction}`,
    headers: {
      Authorization: "Bearer " + userToken,
    },
  }).then((response) => {
    localStorage.setItem("user", JSON.stringify(response));
    return response;
  });
}
}
