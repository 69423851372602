import { keypadService } from "../services";
import { keypadConstants } from "../constants";

export const keypadActions = {
  getAll,
  keypadVendIntent,
};

// Get All (READ)
function getAll() {
  return (dispatch) => {
    // Request
    dispatch(getAllRequest());
    keypadService.getAllService().then(
      (response) => {
        dispatch(getAllSuccess(response.data));
      },
      (error) => {
        dispatch(getAllFailure(error));
      }
    );
  };

  function getAllRequest() {
    return { type: keypadConstants.GET_ALL_KEYPADTOPS_REQUEST };
  }

  function getAllSuccess(dbData) {
    return {
      type: keypadConstants.GET_ALL_KEYPADTOPS_REQUEST_SUCCESS,
      payload: dbData,
    };
  }

  function getAllFailure(error) {
    return { type: keypadConstants.GET_ALL_KEYPADTOPS_REQUEST_FAILURE, payload: error };
  }
}

function keypadVendIntent(details) {
  return (dispatch) => {
    // Request
    dispatch(keypadVendIntentRequest());
    keypadService.keypadVendIntentService(details).then(
      (response) => {
        dispatch(keypadVendIntentSuccess(response.data, details.email, details.payAmount, details.KPN));
      },
      (error) => {
        dispatch(keypadVendIntentFailure(error));
      }
    );
  };

  function keypadVendIntentRequest() {
    return { type: keypadConstants.KEYPADVEND_INTENT_REQUEST };
  }

  function keypadVendIntentSuccess(dbData, email, amount, kpn) {
    return {
      type: keypadConstants.KEYPADVEND_INTENT_REQUEST_SUCCESS,
      payload: {dbData: dbData, email: email, amount: amount, kpn: kpn}
    };
  }

  function keypadVendIntentFailure(error) {
    return { type: keypadConstants.KEYPADVEND_INTENT_REQUEST_FAILURE, payload: error };
  }
}

