export const switchConstants = {
  // Get Switch Journey
  GET_SWITCHJOURNEY_REQUEST: "GET_SWITCHJOURNEY_REQUEST",
  GET_SWITCHJOURNEY_SUCCESS: "GET_SWITCHJOURNEY_SUCCESS",
  GET_SWITCHJOURNEY_FAILURE: "GET_SWITCHJOURNEY_FAILURE",

  // Create Switch Journey
  CREATE_SWITCHJOURNEY_REQUEST: "CREATE_SWITCHJOURNEY_REQUEST",
  CREATE_SWITCHJOURNEY_SUCCESS: "CREATE_SWITCHJOURNEY_SUCCESS",
  CREATE_SWITCHJOURNEY_FAILURE: "CREATE_SWITCHJOURNEY_FAILURE",

  // Update Switch Journey
  UPDATE_SWITCHJOURNEY_REQUEST: "UPDATE_SWITCHJOURNEY_REQUEST",
  UPDATE_SWITCHJOURNEY_SUCCESS: "UPDATE_SWITCHJOURNEY_SUCCESS",
  UPDATE_SWITCHJOURNEY_FAILURE: "UPDATE_SWITCHJOURNEY_FAILURE",

  // Address lookup ROI
  ADDRESS_LOOKUP_REQUEST: "ADDRESS_LOOKUP_REQUEST",
  ADDRESS_LOOKUP_SUCCESS: "ADDRESS_LOOKUP_SUCCESS",
  ADDRESS_LOOKUP_FAILURE: "ADDRESS_LOOKUP_FAILURE",

  // Address lookup NI
  ADDRESS_LOOKUP_NI_REQUEST: "ADDRESS_LOOKUP_NI_REQUEST",
  ADDRESS_LOOKUP_NI_SUCCESS: "ADDRESS_LOOKUP_NI_SUCCESS",
  ADDRESS_LOOKUP_NI_FAILURE: "ADDRESS_LOOKUP_NI_FAILURE",
};
