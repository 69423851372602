import axios from "axios";
import { post } from "jquery";
const LoadConfig = require("../../config");
const config = LoadConfig.default;

export const switchService = {
  getSwitch,
  createSwitch,
  updateSwitch,
  addressLookupService,
  createNISwitch,
  addressLookupNIService,
};

function getSwitch(id) {
  return axios({
    method: "get",
    url: `${config.apiUrl}/switchjourneys/` + id,
  }).then((response) => {
    return response;
  });
}

// Create new switch
function createSwitch(details) {
  return axios({
    method: "post",
    url: `${config.apiUrl}/switchjourneys/create`,
    data: details,
  }).then((response) => {
    //store id in local storage?
    return response;
  });
}
// Create NI switch
function createNISwitch(details) {
  return axios({
    method: "post",
    url: `${config.apiUrl}/switchjourneys/createni`,
    data: details,
  }).then((response) => {
    //store id in local storage?
    return response;
  });
}

// Edit existing switch
function updateSwitch(id, data) {
  return axios({
    method: "put",
    url: `${config.apiUrl}/switchjourneys/` + id,
    data: data,
  }).then((response) => {
    //if (email is true and response.status == 200)
    //mail and redirect

    return response;
  });
}

// Address lookup ROI
function addressLookupService(mprn) {
  return axios({
    method: "get",
    url: `${config.apiUrl}/SwitchJourneys/addresslookup/` + mprn,
  }).then((response) => {
    return response;
  });
}

// Address lookup ROI
function addressLookupNIService(postcode) {
  return axios({
    method: "get",
    url: `${config.apiUrl}/SwitchJourneys/niaddresslookup/` + postcode,
  }).then((response) => {
    return response;
  });
}
