import React, { Component } from "react";
import { MDBRow, MDBCol, MDBContainer, MDBInput, MDBBtn } from "mdbreact";
import $ from "jquery";

// Redux
import { connect } from "react-redux";
// Signin Actions
import { signinActions } from "../../../redux/actions";

import { history } from "../../../redux/helpers";

class RequestResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      jurisdiction:""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split('/');
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris
    });

  }

  // Handles Input Changes
  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  // Handle Submit
  handleSubmit = (e) => {
    e.preventDefault();

    var valid = $("#requestResetPassword")[0].checkValidity();

    console.log("Form valid: " + valid);

    if (valid) {
      const details = {
        Username: this.state.username,
        jurisdiction: this.state.jurisdiction
      };

      this.props.requestPasswordReset(details, this.state.jurisdiction);
    }
  };

  render() {
    return (
      <MDBRow className='login-form-container white-curved-tile'>
        <MDBCol md='12'>
          <form id='requestResetPassword' onSubmit={this.handleSubmit}>
            <div className='grey-text'>
              <MDBInput
                label='Email'
                group
                type='email'
                validate
                name='username'
                value={this.state.username}
                onChange={this.changeHandler}
                required
              />
            </div>
            <MDBBtn type='submit' className='btn'>
              Send reset link
            </MDBBtn>
            <div>
              <a href={`/${this.state.jurisdiction}/account/login`} className='forgot-password'>
                Already know your password? Sign in
              </a>
            </div>
          </form>
        </MDBCol>
      </MDBRow>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    token: store.signin.token,
    error: store.signin.error,
  };
};

const actionCreators = {
  requestPasswordReset: signinActions.requestPasswordReset,
};

export default connect(mapStateToProps, actionCreators)(RequestResetPasswordForm);
