import React from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBCardText,
} from "mdbreact";

class ReferAFriend extends React.Component {
  render() {
    return (
      <div className='default-page home-page'>
        {/* Hero with background device */}
        <MDBRow className='small-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>Refer a Friend</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        {/* Text & Image Section */}
        <div>
          <MDBContainer className='text-left-image-right-section'>
            <MDBRow>
              <MDBCol size='1'></MDBCol>
              <MDBCol size='10'>
                <MDBRow className='large-padding'>
                  <MDBCol sm='12' md='12' lg='6' className='content'>
                    <h2>Bright Fans</h2>
                    <p>
                      We’re delighted to have you as one of our bright fans and
                      we’ve invited you to help us on our mssion to become
                      Ireland’s largest community of green energy users by
                      referring us to your friends and family! As a thank you,
                      we will credit your account with{" "}
                      <span className='underline'>€25</span> for each referral
                      you make which results in a confimed switch to bright. We
                      will credit your account for up to a maximum of 5
                      switches.
                      <br />
                      Interested in getting up to{" "}
                      <span className='underline'>€125</span> in credit? Simply
                      click to receive your unique link and get sharing today!
                    </p>
                    <a href='/switch' className='btn'>
                      Refer a Friend Today
                    </a>
                  </MDBCol>

                  <MDBCol sm='12' md='12' lg='6'>
                    <img src={require("../images/our-energy/image-1.png")} />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol size='1'></MDBCol>
            </MDBRow>
          </MDBContainer>

          {/* Step Counter */}
          <div className='switch-today grey-background'>
            <MDBContainer className='switch-today-container'>
              <MDBRow className='switch-today large-padding'>
                <MDBCol sm='12' md='7' lg='5' className='title-container'>
                  <div className='text-center'>
                    <h2>Start referring today!</h2>
                    <p className='mt-megan brightGreen-text sub-heading-text'>
                      We handle the rest
                    </p>
                  </div>
                </MDBCol>

                {/* Step One */}
                <MDBCol sm='12'>
                  <MDBRow>
                    <MDBCol sm='12' md='6' lg='4' className='step-container'>
                      <MDBCard>
                        <MDBCardBody className='text-center'>
                          <MDBCardTitle>Step 01</MDBCardTitle>
                          <img
                            src={require("../images/home-page/switch-arrow-right.png")}
                            className='switch-arrow-right'
                          />
                          <p className='mt-2'>
                            <span className='underline'>Share your link</span>
                          </p>
                          <MDBCardText>
                            Share your link via social or email to encourage
                            friends and family to join bright! They will also
                            get access to this fantastic offer when they switch!
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>

                    {/* Step Two */}
                    <MDBCol sm='12' md='6' lg='4' className='step-container'>
                      <MDBCard>
                        <MDBCardBody className='text-center'>
                          <MDBCardTitle>Step 02</MDBCardTitle>
                          <img
                            src={require("../images/home-page/switch-arrow-two.png")}
                            className='switch-arrow-left show-for-large'
                          />
                          <p className='mt-2'>
                            <span className='underline'>We'll keep watch</span>
                          </p>
                          <MDBCardText>
                            We will keep an eye for anyone who switches to
                            bright that has come via the unique link you shared.
                            Make sure you tell them to switch by clicking on
                            your unique link!
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>

                    {/* Step Three */}
                    <MDBCol sm='12' md='8' lg='4' className='step-container'>
                      <MDBCard>
                        <MDBCardBody className='text-center'>
                          <MDBCardTitle>Step 03</MDBCardTitle>
                          <img
                            src={require("../images/home-page/switch-arrow-two.png")}
                            className='switch-arrow-left hide-for-large'
                          />
                          <p className='mt-2'>
                            <span className='underline'>
                              Receive your credit
                            </span>
                          </p>
                          <MDBCardText>
                            We will credit your account with{" "}
                            <span className='underline'>€25</span> for a maximum
                            of 5 switches that complete via your unique link.
                            Yes that means you could get up to{" "}
                            <span className='underline'>€125</span> in credit on
                            your account -easy peasy!
                          </MDBCardText>
                          <MDBRow className='switch-download-app-container'></MDBRow>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>

            {/* Terms */}
            <MDBContainer className='text-center whiteBG'>
              <MDBRow>
                <MDBCol size='2'></MDBCol>
                <MDBCol size='8'>
                  <p className='my-5'>
                    This offer is available to every customer who switches to
                    bright and sets up an online account. Upon completing your
                    switch you will get instant access to directly share your
                    unique link via social or email with your friends and family
                    or you can also view this offer in the ‘My Account’ section
                    of our website. You can share this link with as many people
                    as you like, however we will only credit you for a maximum
                    of 5 switches via the link. For anyone who clicks and
                    proceeds to switch via the link, we will record them as
                    switch for you and once there switch completes, we will
                    credit your account with €25. You can earn up to a maximum
                    of €125 credit on your account. The switching process can
                    take up to 20days to complete and you will not be issued any
                    credit for any switches referred until this point. You can
                    avail of this offer once only and it will be retricted to
                    household and therefore; if you leave bright and join at a
                    later stage, the offer will not be applicable to you or to
                    another person in the same household. We will notify you by
                    email or SMS when one of your referrals switches.
                  </p>
                </MDBCol>
                <MDBCol size='2'></MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default ReferAFriend;
