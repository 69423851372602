import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { sessionExpired } from "../../../../redux/helpers/sessionExpired";
import { paymentActions } from "../../../../redux/actions";

export const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const [paymentId, setPaymentId] = useState();

  useEffect(() => {
    sessionExpired();
    let captureData = JSON.parse(localStorage.getItem("captureData"));
    dispatch(paymentActions.savePayment(captureData));
    localStorage.removeItem("captureData"); 

    let paymentId = localStorage.getItem("paymentIntent");
    setPaymentId(paymentId);
  }, []);

  return (
    <div>
      <MDBRow center className="text-center">
        <MDBCol>
          <img
            src={require("../../../../images/account/success.png")}
            style={{ width: "30%" }}
          />

          <p className="my-5">Your payment has been successful.</p>
          <p>
            Payment ID: <b>{paymentId}</b>
          </p>
        </MDBCol>
      </MDBRow>

      <MDBRow center>
        <MDBCol size="6">
          <MDBBtn
            className="btn"
            onClick={() => {
              window.location.reload();
            }}
          >
            Continue
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </div>
  );
};
