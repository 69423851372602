import React from "react";
import { Route, Redirect } from "react-router-dom";

// Check for jurisdiction
// var pathArray = window.location.pathname.split("/");
// var juris = pathArray[1];
// const jurisdiction = juris,

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("user") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/roi/account/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
