export const paymentConstants = {
  // INCREMENT TOPUP
  INCREMENT_TOPUP: "INCREMENT_TOPUP",
  // DECCREMENT TOPUP
  DECCREMENT_TOPUP: "DECCREMENT_TOPUP",

  CREATE_PAYMENTSESSION_REQUEST: "CREATE_PAYMENTSESSION_REQUEST",
  CREATE_PAYMENTSESSION_SUCCESS: "CREATE_PAYMENTSESSION_SUCCESS",
  CREATE_PAYMENTSESSION_FAILURE: "CREATE_PAYMENTSESSION_FAILURE",

  SAVE_PAYMENT_REQUEST: "SAVE_PAYMENT_REQUEST",
  SAVE_PAYMENT_SUCCESS: "SAVE_PAYMENT_SUCCESS",
  SAVE_PAYMENT_FAILURE: "SAVE_PAYMENT_FAILURE",

  GET_PAYMENTHISTORY_REQUEST: "GET_PAYMENTHISTORY_REQUEST",
  GET_PAYMENTHISTORY_SUCCESS: "GET_PAYMENTHISTORY_SUCCESS",
  GET_PAYMENTHISTORY_FAILURE: "GET_PAYMENTHISTORY_FAILURE",
};
