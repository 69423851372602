import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FAQTabs from "../../components/Help/FAQTabs";

import FixedNavDark from "../../components/Shared/FixedNavDark";
import $ from "jquery";

class FAQsMeterReadings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }
  
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.help);
  }

  help =() =>{
    if (window.pageYOffset <50)
    {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark /> */}

        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>FAQs</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow className="pb-5">
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <FAQTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5'>
            <h3>Meter Readings</h3>

            <div id='submitreading' className='mt-5 mb-5'>
              <h5 className='policy-title'>How do I submit a meter reading? </h5>
              <p className='policy-content'>
                <MDBRow className="py-5">
                  <MDBCol>
                    <img
                      src={require("../../images/help/24h-meter.jpeg")}
                      className='meterImg app-store'
                    />
                  </MDBCol>
                  <MDBCol>
                  <h5 className='policy-title'>24 hour electric meter</h5>
                  <ul>
                    <li>Record the number on the display from left to right</li>
                    <li>Ignore any figures in red or any figures surrounded by a red box</li>
                  </ul>
                  </MDBCol>
                </MDBRow>

                <MDBRow className="py-5">
                <MDBCol>
                    <img
                      src={require("../../images/help/digital-meter.png")}
                      className='meterImg app-store'
                    />
                  </MDBCol>
                  <MDBCol>
                  <h5 className='policy-title'>Digital meter</h5>
                  <ul>
                    <li>Read from left to right ignoring any numbers marked 1/10 or 1/1000 or any numbers in red. </li>
                  </ul>
                  </MDBCol>
                </MDBRow>

                <MDBRow className="py-5">
                <MDBCol>
                    <img
                      src={require("../../images/help/Keypad-Meter.jpg")}
                      className='meterImg app-store'
                    />
                  </MDBCol>
                  <MDBCol>
                  <h5 className='policy-title'>Keypad meter</h5>
                  <ul>
                    <li>Press 9 on the Keypad and read the digital from left to right.</li>
                  </ul>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                <MDBCol>
                    <img
                      src={require("../../images/help/daynight-meter.png")}
                      className='meterImg app-store'
                    />
                  </MDBCol>
                  <MDBCol>
                  <h5 className='policy-title'>Day/Night meter</h5>
                  <p>Your Economy 7 meter will have two rows of numbers which separately record the electricity you use during the day and night.  The rows may be labelled in one of the following ways to help you identify which reading is for day or night:</p>
                  <ul>
                    <li>Day and Night</li>
                    <li>1/I (Night) or 2/II (Day)</li>
                    <li>Low (Night) or Normal (Day)</li>
                  </ul>
                  <p>You can ignore any numbers in red or surrounded by a red box and simply read the digits from left to right including any zeros.</p>
                  </MDBCol>
                </MDBRow>
              </p>
            </div>

            <div id='esbrequestingread' className='mb-5'>
              <h5 className='policy-title'>Why are ESB Networks still requesting my meter read? </h5>
              <p className='policy-content'>
                ESB Networks, as the network company, is responsible for reading and maintaining the meter at each property and providing the meter read to suppliers.
              </p>
            </div>

            <div id='forgotmeterread' className='mb-5'>
              <h5 className='policy-title'>I forgot to submit my meter read- what happens now? </h5>
              <p className='policy-content'>
                If we don't have an up to date meter reading we'll use an estimate caluculated by ESB Networks or bright.  If you think this estimate is not correct you can provide us with an up to date read and we will reissue your bill.
              </p>
            </div>

            <div id='howoftenmeterread' className='mb-5'>
              <h5 className='policy-title'>How often is my meter read? </h5>
              <p className='policy-content'>
                ESB Networks will make best endeavours to read your meter 4 times per year.  We will send you a bill every month, to ensure this bill is accurate you can provide a meter readng each month through the app or website.
              </p>
            </div>

            <div id='firstbill' className='mb-5'>
              <h5 className='policy-title'>I am unable to read the electricity meter. </h5>
              <p className='policy-content'>
                Where you or anyone else in the property is unable to read the electricity meter at the premises we will arrange to have the meter read at least once each quarter and inform you of that reading. Contact us on:
              <ul>
                  <li>Call us on <a href="tel:01 588 1777">01 588 1777</a></li>
                  <li>Email us at <a href="mailto:hello@brightenergy.com">hello@brightenergy.com</a> </li>
                  <li>Web Chat at <a href="https://www.brightenergy.com">www.brightenergy.com</a> </li>
                  <li>Write to us at Bright, 3 Custom House Plaza IFCS, Dublin, D01 VY76</li>
                </ul>

              View bright's code of practice on provision of services for vulnerable customers <a href={`/${this.state.jurisdiction}/codesofpractice`}>here.</a>

              </p>
            </div>

            <div id='firstbill' className='mb-5'>
              <h5 className='policy-title'>I've been advised of an issue with my timeclock.  What happens now?</h5>
              <p className='policy-content'>
              Where ESB Networks identify a Day/Night meter where the timeclock is not 
              operating correctly, they will arrange to exchange that meter with a like 
              for like meter.  A timeclock not operating correctly means that the day or 
              night registers may be stuck, or that the time may be running slow or fast, 
              so that while the amount of electricity you use is being recorded, it is 
              not being recorded at the correct time, and therefore charged at the correct 
              rate.  When the meter is exchanged ESB Networks will provide your supplier 
              with an adjusted reading for the meter, where you have overpaid a credit will 
              be applied to your account. For more information please visit the  <a href="https://www.esbnetworks.ie/existing-connections/meters-and-readings/traditional-meter-timeclock-issues-resolution.">ESB website.</a>
            
      
           

              </p>
            </div>

          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default FAQsMeterReadings;
