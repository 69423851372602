import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow, MDBBtn, MDBAlert } from "mdbreact";
import { connect } from "react-redux";
import { quoteActions } from "../../../../redux/actions/quoteActions";
import Loader from "../../../Shared/Loader";
import $ from "jquery";

export class _FormGas extends Component {
  state = {
    onGasTariff: "",
    interestedInBrightGas: "",
    isRequired: false,
    errorMessage: false,
    jurisdiction: "roi",
    homeCheck: false,
  };

  // Page load
  componentDidMount() {
    // Check for a quoteID in local storage. If not null get that quote by ID
    if (localStorage.getItem("quoteID") != null) {
      let quoteID = localStorage.getItem("quoteID");
      this.props.getQuote(quoteID);
    }
  }

  // Populate form on back
  async componentWillReceiveProps(nextProps) {
    // Get page status from local storage
    let pageStatus = JSON.parse(localStorage.getItem("gasUpdated"));

    // If page status e.g. pageUpdated = true -> populate the form
    if (pageStatus) {
      await this.setState({
        onGasTariff: nextProps.quoteData.onGasTariff,
        interestedInBrightGas: nextProps.quoteData.interestedInBrightGas,
      });
    }

    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle continue
  nextStep = (e) => {
    // Validate Form
    let validation = $("#gasDetails")[0].checkValidity();

    if (validation) {
      this.setState({ errorMessage: false });

      // Form data
      const data = {
        // Step one - Property
        propertyType: this.props.quoteData.propertyType,
        residents: this.props.quoteData.residents,

        // Step two - Meter
        urbanRural: this.props.quoteData.urbanRural,
        meterType: this.props.quoteData.meterType,
        electricityUsage: this.props.quoteData.electricityUsage,

        // Step three - Payment
        paymentMethod: this.props.quoteData.paymentMethod,
        monthlyPayment: this.props.quoteData.monthlyPayment,

        // Step four (this step) - Gas
        onGasTariff: this.state.onGasTariff,
        interestedInBrightGas: this.state.interestedInBrightGas,

        // Step four - Ev
        hasElectricVehicle: this.props.quoteData.hasElectricVehicle,
        vehicleMake: this.props.quoteData.vehicleMake,
      };

      // Quote id from local storage
      let id = localStorage.getItem("quoteID");

      // This Page (updates page status)
      let pageStatus = "gasUpdated";

      // Next Page URL
      let nextPage = "ev";

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Quote Journey
      this.props.updateQuote(id, data, pageStatus, nextPage, jurisdiction);
    } else {
      this.setState({ errorMessage: true });
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    return (
      <div className='quote-page'>
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        {/* Background Image */}
        <div className='device-image' />
        <form id='gasDetails'>
          <MDBCol className='text-center'>
            {/* Logo */}
            <MDBRow>
              <MDBCol lg='2' sm='12'>
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <img
                    src={require("../../../../images/global/bright-logo-dark.png")}
                    className='quote-logo mx-5 mt-5'
                  />
                </a>
              </MDBCol>
              <MDBCol size='10' />
            </MDBRow>

            {/* Home message */}
            {this.state.homeCheck ? (
              <div className='modalBG'>
                <div className='modalBox'>
                  <div className='white-curved-tile text-center'>
                    <h4>
                      All progress will be lost! <br />
                      Are you sure?
                    </h4>
                    <MDBRow>
                      <MDBCol lg='6' md='6' sm='12'>
                        <MDBBtn
                          className='cancel-button'
                          onClick={() =>
                            this.setState({ homeCheck: !this.state.homeCheck })
                          }
                        >
                          Cancel
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol lg='6' md='6' sm='12'>
                        <MDBBtn onClick={() => (window.location.href = "/")}>
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Error Message */}
            <MDBRow className='text-center mt-5'>
              <MDBCol size='4' />
              <MDBCol lg='4' md='4' sm='12'>
                {this.state.errorMessage == true ? (
                  <MDBAlert color='danger'>
                    <strong>Oops!</strong> Please check your fields below.
                  </MDBAlert>
                ) : null}
              </MDBCol>
              <MDBCol size='4' />
            </MDBRow>
            {/* Gas tariff with another provider? */}
            <MDBRow className='mb-5'>
              <MDBCol size='3' />
              <MDBCol lg='6' md='6' sm='10' className='text-center'>
                {/* Question */}
                <h4 className='mb-4'>
                  We’re launching our Gas tariff soon. <br /> Are you currently
                  on a gas tariff with another provider?
                </h4>
                {/* Yes */}
                <input
                  onClick={() =>
                    this.setState({ onGasTariff: true, isRequired: true })
                  }
                  checked={this.state.onGasTariff ? true : false}
                  className='form-check-input'
                  id='gasYes'
                  name='gas'
                  required
                  type='radio'
                />
                <label for='gasYes' className='radioLabel'>
                  Yes
                </label>

                {/* No */}
                <input
                  onClick={() => this.setState({ onGasTariff: false })}
                  checked={this.state.onGasTariff === false ? true : false}
                  className='form-check-input'
                  id='gasNo'
                  name='gas'
                  type='radio'
                />
                <label for='gasNo' className='radioLabel'>
                  No
                </label>
              </MDBCol>
              <MDBCol size='3' />
            </MDBRow>

            {/* Intrested in bright gas */}
            {/* {this.state.onGasTariff ? ( */}
            <MDBRow className='mb-5'>
              <MDBCol size='3' />
              <MDBCol lg='6' md='6' sm='10' className='text-center'>
                {/* Question */}
                <h4 className='mb-4'>
                  Are you interested in hearing more about our bright gas
                  tariff?
                </h4>
                {/* Yes */}
                <input
                  onClick={() => this.setState({ interestedInBrightGas: true })}
                  checked={this.state.interestedInBrightGas ? true : false}
                  className='form-check-input'
                  id='brightGasYes'
                  name='brightGas'
                  required
                  type='radio'
                />
                <label for='brightGasYes' className='radioLabel'>
                  Yes
                </label>

                {/* No */}
                <input
                  onClick={() =>
                    this.setState({ interestedInBrightGas: false })
                  }
                  checked={
                    this.state.interestedInBrightGas === false ? true : false
                  }
                  className='form-check-input'
                  id='brightGasNo'
                  name='brightGas'
                  type='radio'
                />
                <label for='brightGasNo' className='radioLabel'>
                  No
                </label>
              </MDBCol>
              <MDBCol size='3' />
            </MDBRow>
            {/* ) : null} */}
            {/* Buttons */}
            <MDBRow className='my-5'>
              <MDBCol size='3' />
              <MDBCol lg='6' md='6' sm='10' className='text-center'>
                {/* Back */}
                <a
                  href={`/${this.state.jurisdiction}/quote/payment`}
                  className='mr-3'
                >
                  <MDBBtn className='btn switch-button'>Back</MDBBtn>
                </a>
                {/* Next Step */}
                <MDBBtn onClick={this.nextStep} className='btn'>
                  Continue
                </MDBBtn>
              </MDBCol>
              <MDBCol size='3' />
            </MDBRow>
          </MDBCol>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    quoteData: store.quote.quoteData,
    isLoading: store.quote.isLoading,
  };
};

const actionCreators = {
  updateQuote: quoteActions.updateQuoteJourney,
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(_FormGas);
