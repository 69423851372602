import React from "react";
import { MDBRow, MDBCol, MDBCard } from "mdbreact";

class HelpTiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "roi",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  render() {
    return (
      <div className='my-5'>
        {/* Row one */}
        <MDBRow>
          <MDBCol lg='2' />
          <MDBCol lg='4' sm='3'>
            <MDBCard className='help-tile'>
              <a href={`/${this.state.jurisdiction}/faqs/gettingstarted`}>
                <img
                  src={require("../../images/help/gettingStarted.png")}
                  className='help-tile-icon'
                />
                <h4>Getting Started</h4>
              </a>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' sm='3'>
            <MDBCard className='help-tile'>
              <a href={`/${this.state.jurisdiction}/faqs/mybrightaccount`}>
                <img
                  src={require("../../images/help/myAccount.png")}
                  className='help-tile-icon'
                />
                <h4>My bright account</h4>
              </a>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='2' />
        </MDBRow>

        {/* Row two */}
        <MDBRow>
          <MDBCol lg='2' />
          <MDBCol lg='4' sm='3'>
            <MDBCard className='help-tile'>
              <a href={`/${this.state.jurisdiction}/faqs/billingandpayments`}>
                <img
                  src={require("../../images/help/payments.png")}
                  className='help-tile-icon'
                />
                <h4>Billing and payments</h4>
              </a>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' sm='3'>
            <MDBCard className='help-tile'>
              <a href={`/${this.state.jurisdiction}/faqs/meterreadings`}>
                <img
                  src={require("../../images/help/meterReading.png")}
                  className='help-tile-icon'
                />
                <h4>Meter readings</h4>
              </a>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='2' />
        </MDBRow>

        {/* Row three */}
        <MDBRow>
          <MDBCol lg='2' />
          <MDBCol lg='4' sm='3'>
            <MDBCard className='help-tile'>
              <a href={`/${this.state.jurisdiction}/faqs/movinghouse`}>
                <img
                  src={require("../../images/help/home.png")}
                  className='help-tile-icon'
                />
                <h4>Moving house</h4>
              </a>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='4' sm='3'>
            <MDBCard className='help-tile'>
              <a href={`/${this.state.jurisdiction}/faqs/additionalhelp`}>
                <img
                  src={require("../../images/help/information.png")}
                  className='help-tile-icon'
                />
                <h4>Additional help</h4>
              </a>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='2' />     
          </MDBRow>
          
          {/* Row four */}
          {this.state.jurisdiction == "ni" ?
            <MDBRow>
            <MDBCol lg='2' />
          <MDBCol lg='4' sm='3'>
            <MDBCard className='help-tile'>
              <a href={`/${this.state.jurisdiction}/faqs/prepaymentmeters`}>
                <img
                  src={require("../../images/help/prepaymenticon.png")}
                  className='help-tile-icon'
                />
                <h4>Prepayment Meters</h4>
              </a>
            </MDBCard>
          </MDBCol>

          <MDBCol lg='4' sm='3'>
            <MDBCard className='help-tile'>
              <a href={`/${this.state.jurisdiction}/faqs/complaints`}>
                <img
                  src={require("../../images/help/complaints.jpg")}
                  className='help-tile-icon'
                />
                <h4>Complaints</h4>
              </a>
            </MDBCard>
          </MDBCol>
          <MDBCol lg='2' />   
          </MDBRow>

          

           

          :

        null}


          
          

        <MDBRow size='12'>
          <MDBCol size='3' />
          <MDBCol size='6' className='text-center mt-5'>
            <img
              src={require("../../images/help/Phone.png")}
              className='help-emergency-icon'
            />
            <h4>Need more help?</h4>
            <p>
              If you still can't find what you are looking for you can contact
              us{" "}
              <a
                className='help-emergency-link'
                href={`/${this.state.jurisdiction}/about/contactus`}
              >
                here
              </a>
            </p>
          </MDBCol>
          <MDBCol size='3' />
        </MDBRow>

        {/* ESB */}
        {this.state.jurisdiction == "roi" ?
        <MDBRow size='12'>
          <MDBCol size='3' />
          <MDBCol size='6' className='text-center my-5'>
            <img
              src={require("../../images/help/emergency.png")}
              className='help-emergency-icon'
            />
            <h4>What to do in an emergency</h4>
            <p>
              In the event of a power cut you can report it online <br /> with{" "}
              <a className='help-emergency-link' href="https://www.esbnetworks.ie/">ESB Networks</a> or by calling
              them directly.
            </p>
            <div className='help-emergency-phone'>
              <MDBRow size='12'>
                <MDBCol size='1' />
                <MDBCol size='5'>
                  <p className='mt-3'>ESB Networks</p>
                </MDBCol>
                <MDBCol size='5'>
                  <p className='mt-3 help-emergency-link'><a href="tel:1800 372 999">1800 372 999</a></p>
                </MDBCol>
                <MDBCol size='1' />
              </MDBRow>
            </div>
          </MDBCol>
          <MDBCol size='3' />
        </MDBRow>

        :

        <MDBRow size='12'>
          <MDBCol size='3' />
          <MDBCol size='6' className='text-center my-5'>
            <img
              src={require("../../images/help/emergency.png")}
              className='help-emergency-icon'
            />
            <h4>What to do in an emergency NI</h4>
            <p>
              In the event of a power cut you can report it online <br /> with{" "}
              <a className='help-emergency-link'  target="_blank"  href="https://www.nienetworks.co.uk/">NIE Networks</a> or by calling
              them directly.
            </p>
            <div className='help-emergency-phone'>
              <MDBRow className="p-3" center>
                <MDBCol sm='12' md='5' lg='5'>
                  <p>NIE Networks</p>
                </MDBCol>
                <MDBCol sm='12' md='5' lg='5'>
                  <p className='help-emergency-link'><a href="tel:03457643643">03457 643 643</a></p>
                </MDBCol>
              </MDBRow>
              <MDBRow className="p-3" center>
                <MDBCol sm='12' md='5' lg='5'>
                  <p>Report energy theft</p>
                </MDBCol>
                <MDBCol sm='12' md='5' lg='5'>
                  <p className='mt-3 help-emergency-link'> <a href="tel:02895757206">028 9575 7206</a></p>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBCol>
          <MDBCol size='3' />
        </MDBRow>
        }
      </div>
      
      
    );
  }
}

export default HelpTiles;
