import React, { Component } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdbreact";
import Swiper from "react-id-swiper";
import OnAMission from "../../components/Shared/OnAMission";

// Redux
import { connect } from "react-redux";
import { blogActions } from "../../redux/actions";

class Maxol extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postcode: "",
      activeItem: "1",
      jurisdiction: "",
    };
  }

  componentDidMount() {
    this.props.getBlogPosts();

    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  toggle = (tab) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    const generatorSwiper = {
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 1000,
      loop: true,
      effect: "fade",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    // News Seciton Swiper https://swiperjs.com/api/
    const newsSwiper = {
      slidesPerView: 3,
      spaceBetween: 0,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1000,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
      },

      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 30,
        },

        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },

        1024: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      },
    };

    // Trust Pilot Seciton Swiper https://swiperjs.com/api/
    const trustpilotSwiper = {
      slidesPerView: 3,
      spaceBetween: 0,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1000,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
      },

      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 30,
        },

        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },

        1024: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      },
    };

    return (
      <div className='default-page'>
        {/* Hero with background device */}
        <MDBRow className='small-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='12' lg='12'>
                <h1>Energy That's FutureKind</h1>
                {/* <h3 className='white-text'>
                  Hi, we're bright & we're on a <br />
                  mission to bring{" "}
                  <span className='mt-megan brightGreen-text'>
                    (Renewable)
                  </span>{" "}
                  power
                  <br />
                  to the people with clean energy
                  <br />
                  that's fair for all{" "}
                </h3> */}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBContainer className='energy-section'>
          <MDBRow className='large-padding pb-3'>
            {/* Welcome Text */}
            <MDBCol md='2'></MDBCol>
            <MDBCol sm='12' md='8' lg='8' className='content'>

              <h4 className='montserrat-Bold '>You’ve been referred by Maxol Station (station name pull from URL)</h4>
              <p>In July 2020 Maxol, together with Evermore Energy, launched bright a
              new digital energy supply company.
              <br /><br />
              At bright we want to simplify the energy market by bringing green*,
              affordable electricity to homes across Ireland.
              <br /><br />
              Join the 1000’s of customers who have already switched and see for
              yourself how switching to bright could reduce your home energy costs.
              </p>
              <MDBRow className='large-padding pt-0'>
                <MDBCol className='text-center'>
                  <a href={`/${this.state.jurisdiction}/quote`} className='btn'>
                    Get a quote
                </a>
                </MDBCol>
              </MDBRow>

            </MDBCol>
            <MDBCol md='2'></MDBCol>
          </MDBRow>
        </MDBContainer>
        {/* Text with Icons Section */}
        <div>
          <MDBContainer className='text-icons-section large-padding pt-0'>
            <MDBRow>
              <MDBCol
                sm='6'
                md='3'
                lg='3'
                className='text-center icons-container'
              >
                <img
                  src={require("../../images/home-page/clean-energy-icon.png")}
                ></img>
                <h5 className='sm-heading'>
                  100% <br /> green electricity <a href='#cru'>*</a>
                </h5>
              </MDBCol>
              <MDBCol
                sm='6'
                md='3'
                lg='3'
                className='text-center icons-container'
              >
                <img src={require("../../images/home-page/euro.png")}></img>
                <h5 className='sm-heading'>
                  One <br /> variable tariff
                </h5>
              </MDBCol>

              <MDBCol
                sm='6'
                md='3'
                lg='3'
                className='text-center icons-container'
              >
                <img
                  src={require("../../images/switching/tie-ins-icon.png")}
                ></img>
                <h5 className='sm-heading'>No tie ins</h5>
              </MDBCol>

              <MDBCol
                sm='6'
                md='3'
                lg='3'
                className='text-center icons-container'
              >
                <img
                  src={require("../../images/home-page/manage-account-icon.png")}
                ></img>
                <h5 className='sm-heading'>
                  Managing your <br /> account is simple
                </h5>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>



        {/* Switch Today Section */}
        <div className='switch-today mt-5'>
          <MDBContainer className='switch-today-container mt-5'>
            <MDBRow className='switch-today large-padding pb-0'>
              <MDBCol sm='12' md='7' lg='8' className='title-container'>
                <div className='text-center'>
                  <h2>Switch in minutes </h2>
                  <p className='mt-megan brightGreen-text sub-heading-text'>
                    We handle the rest
                  </p>
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className='switch-today large-padding pt-0 pb-0'>
              <MDBCol sm='12'>
                <MDBRow>
                  <MDBCol sm='12' md='4' lg='4' className='step-container'>
                    <MDBCard>
                      <MDBCardBody className='text-center'>
                        <MDBCardTitle>Step 1</MDBCardTitle>
                        <img
                          src={require("../../images/home-page/switch-arrow-right.png")}
                          className='switch-arrow-right'
                        />
                        <p className='switch-subheading'>Switch in minutes</p>
                        <MDBCardText>
                          Switch online in minutes and get our best possible
                          price.
                        </MDBCardText>
                        {/* <a href="/quote" className="btn" >Get Started</a> */}
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>

                  <MDBCol sm='12' md='4' lg='4' className='step-container'>
                    <MDBCard>
                      <MDBCardBody className='text-center'>
                        <MDBCardTitle>Step 2</MDBCardTitle>
                        <img
                          src={require("../../images/home-page/switch-arrow-two.png")}
                          className='switch-arrow-left show-for-large'
                        />
                        <p className='switch-subheading'>We handle the rest</p>
                        <MDBCardText>
                          No disruption to your supply and we manage your switch
                          with your existing supplier.
                        </MDBCardText>
                        {/* <a href="/about/switchingtobright" className="btn"  >About switching</a> */}
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>

                  <MDBCol sm='12' md='4' lg='4' className='step-container'>
                    <MDBCard>
                      <MDBCardBody className='text-center'>
                        <MDBCardTitle>Step 3</MDBCardTitle>
                        <img
                          src={require("../../images/home-page/switch-arrow-two.png")}
                          className='switch-arrow-left hide-for-large'
                        />
                        <p className='switch-subheading'>
                          You're one of us now
                        </p>
                        <MDBCardText>
                          We're now supplying your electricity. Be sure to
                          download our app.
                        </MDBCardText>
                        <MDBRow className='switch-download-app-container'>
                          <MDBCol sm='12'>
                            <a href='https://apps.apple.com/app/id1500409256'>
                              <img
                                src={require("../../images/ios.png")}
                                className='fullImg app-store'
                              />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.brightapp'>
                              <img
                                src={require("../../images/google.png")}
                                className='fullImg app-store'
                              />
                            </a>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow className='large-padding pt-0'>
              <MDBCol className='text-center'>
                <a href={`/${this.state.jurisdiction}/quote`} className='btn'>
                  Get a quote
                </a>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>



      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    blogData: store.blog.postData,
  };
};

const actionCreators = {
  getBlogPosts: blogActions.getAll,
};

export default connect(mapStateToProps, actionCreators)(Maxol);
