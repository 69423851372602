import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import NavigationSection from "../../../components/CustomerAccount/AccountNavigation";
import { sessionExpired } from "../../../redux/helpers/sessionExpired";

// Fusion Charts
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
// import FusionTheme from "fusioncharts/themes/fusioncharts.theme.candy";
import "../../../assets/fusioncharts.theme.bright";
// Redux
import { keypadActions } from "../../../redux/actions/keypadActions";
import { useSelector, useDispatch } from "react-redux";

ReactFC.fcRoot(FusionCharts, Column2D);

const LoadConfig = require("../../../config");
const config = LoadConfig.default;

const EnergyUsage = (props) => {
  //redux
  const dispatch = useDispatch();
  const keypadTopups = useSelector((state) => state.keypad);

  //state
  const [howToTip, sethowToTip] = useState(false);
  const [jurisdiction, setJurisdiction] = useState();
  const [tonnesSaved, setTonnesSaved] = useState();
  const [treesPlanted, setTreesPlanted] = useState();
  const [actualUsage, setActualUsage] = useState([]);
  const [chartConfig, setChartConfig] = useState();

  const runConfig = (data) => {
    const chartConfig = {
      type: "mscombi2d",
      dataFormat: "json",
      dataSource: data,
      height: 450,
      width: "100%",
    };

    setChartConfig(chartConfig);
  };

  // Page load
  useEffect(() => {
    sessionExpired();

    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    setJurisdiction(jurisdiction);

    //get local storage vals
    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;
    let productCode = jModel.electricityProduct.electricity.prepay;
    if (productCode) {
      dispatch(keypadActions.getAll());
    }

    // Tonnes saved calculation
    let tonnesSaved = ((jModel.annualUsage / 1000) * 0.291).toFixed(2);

    // Trees calculation
    // tree carbon = CO2 in kilograms
    let treeCarbon = 2;
    // Convert tonnesSaved to kilograms
    let tonnesToKG = tonnesSaved * 1000;
    let treesPlanted = (tonnesToKG / treeCarbon).toFixed(0);

    setTonnesSaved(tonnesSaved);
    setTreesPlanted(treesPlanted);

    let data = null;
    if (productCode) {
      data = setKeypadChartData(keypadTopups.keypadData);
      runConfig(data);
    } else {
      data = setChartData();
      runConfig(data);
    }
  }, []);

  useEffect(() => {
    // adding listeners everytime props changes
    if (keypadTopups.keypadData.length > 0) {
      let data = setKeypadChartData(keypadTopups.keypadData);
      runConfig(data);
    }
    return () => {};
  }, [keypadTopups.keypadData]);

  const setChartData = () => {
    let actualUsageArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    // Get jModel
    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;

    // Set annualUsage
    let estimateUsage = jModel.annualUsage;

    // Set chart yAxis height
    let yAxis = (12 * estimateUsage) / 100;

    // Set load profile precentages based on meter type
    let lpPrecentage = "";
    let duosGroup = jModel.electricityProduct.electricity.duosGroup;

    if (jModel.productRef.includes("E1R")) {
      if (duosGroup == "DG1") {
        lpPrecentage = config.urban24LoadProfile;
      } else {
        lpPrecentage = config.rural24LoadProfile;
      }
    } else {
      if (duosGroup == "DG1") {
        lpPrecentage = config.urbanNSLoadProfile;
      } else {
        lpPrecentage = config.ruralNSLoadProfile;
      }
    }

    try {
      // Actual usage
      // Determine unit rate
      let unitRates = jModel.electricityProduct.electricity.unitRates;
      let standardRate = unitRates.standard / 100;
      let dayUnitRate = unitRates.day;
      let nightUnitRate = unitRates.night;

      // REMOVE
      //let standardRate = 0.148;

      // Loop through each bills
      let billsList = jModel.billsList;
      console.log(billsList);

      for (var i = 0; i < billsList.length; i++) {
        let paymentAmount = billsList[i].grossAmount;
        let monthlyPSO =
          jurisdiction == "roi" ? config.monthlyPsoLevy.toFixed(2) : 0;
        let monthlyUrbanSC =
          jurisdiction == "roi"
            ? config.monthlyUrbanStandingCharge.toFixed(2)
            : config.sc_tfh_standing_incVat.toFixed(2);
        let monthlyRuralSC =
          jurisdiction == "roi"
            ? config.monthlyRuralStandingCharge.toFixed(2)
            : config.sc_tfh_standing_incVat.toFixed(2);
        let actualUsageAmt = 0;

        if (jModel.productRef.includes("E1R")) {
          //  Standard/urban
          if (duosGroup == "DG1") {
            actualUsageAmt = paymentAmount - monthlyPSO;
            actualUsageAmt = actualUsageAmt - monthlyUrbanSC;
            actualUsageAmt = actualUsageAmt / standardRate;
          }
          // Standard/rural
          else {
            actualUsageAmt = paymentAmount - monthlyPSO;
            actualUsageAmt = actualUsageAmt - monthlyRuralSC;
            actualUsageAmt = actualUsageAmt / standardRate;
          }
        } else {
          // Nightsaver/urban
          if (duosGroup == "DG1") {
            let halfPaymentAmount = paymentAmount / 2;
            let halfMonthlyPSO = jurisdiction == "roi" ? monthlyPSO / 2 : 0;
            let halfMonthlyUrbanSC = monthlyUrbanSC / 2;

            let actualUsageOne = actualUsageAmt / 2;
            let actualUsageTwo = actualUsageAmt / 2;

            actualUsageOne = halfPaymentAmount - halfMonthlyPSO;
            actualUsageOne = actualUsageOne - halfMonthlyUrbanSC;
            actualUsageOne = actualUsageOne / dayUnitRate;

            actualUsageTwo = halfPaymentAmount - halfMonthlyPSO;
            actualUsageTwo = actualUsageTwo - halfMonthlyUrbanSC;
            actualUsageTwo = actualUsageTwo / nightUnitRate;

            actualUsage = actualUsageOne + actualUsageTwo;
          }
          // Nightsaver/rural
          else {
            let halfPaymentAmount = paymentAmount / 2;
            let halfMonthlyPSO = jurisdiction == "roi" ? monthlyPSO / 2 : 0;
            let halfMonthlyRuralSC = monthlyRuralSC / 2;

            let actualUsageOne = actualUsageAmt / 2;
            let actualUsageTwo = actualUsageAmt / 2;

            actualUsageOne = halfPaymentAmount - halfMonthlyPSO;
            actualUsageOne = actualUsageOne - halfMonthlyRuralSC;
            actualUsageOne = actualUsageOne / dayUnitRate;

            actualUsageTwo = halfPaymentAmount - halfMonthlyPSO;
            actualUsageTwo = actualUsageTwo - halfMonthlyRuralSC;
            actualUsageTwo = actualUsageTwo / nightUnitRate;

            actualUsageAmt = actualUsageOne + actualUsageTwo;
          }
        }

        let date = new Date(billsList[i].periodFrom);

        let year = date.getFullYear();

        if (year === 2021) {
          let month = date.getMonth() + 1;

          switch (month) {
            case 1:
              actualUsageArray[0] = actualUsageAmt;
              break;
            case 2:
              actualUsageArray[1] = actualUsageAmt;
              break;
            case 3:
              actualUsageArray[2] = actualUsageAmt;
              break;
            case 4:
              actualUsageArray[3] = actualUsageAmt;
              break;
            case 5:
              actualUsageArray[4] = actualUsageAmt;
              break;
            case 6:
              actualUsageArray[5] = actualUsageAmt;
              break;
            case 7:
              actualUsageArray[6] = actualUsageAmt;
              break;
            case 8:
              actualUsageArray[7] = actualUsageAmt;
              break;
            case 9:
              actualUsageArray[8] = actualUsageAmt;
              break;
            case 10:
              actualUsageArray[9] = actualUsageAmt;
              break;
            case 11:
              actualUsageArray[10] = actualUsageAmt;
              break;
            case 12:
              actualUsageArray[11] = actualUsageAmt;
              break;
          }
        }
      }
      setActualUsage(actualUsageArray);
    } catch (err) {
      console.log(err);
    }

    const chartData = {
      chart: {
        theme: "bright",
        yAxisMaxValue: yAxis,
        yAxisName: "Usage (kWh)",
      },
      categories: [
        {
          category: [
            {
              label: "Jan",
            },
            {
              label: "Feb",
            },
            {
              label: "Mar",
            },
            {
              label: "Apr",
            },
            {
              label: "May",
            },
            {
              label: "Jun",
            },
            {
              label: "Jul",
            },
            {
              label: "Aug",
            },
            {
              label: "Sep",
            },
            {
              label: "Oct",
            },
            {
              label: "Nov",
            },
            {
              label: "Dec",
            },
          ],
        },
      ],
      dataset: [
        {
          seriesName: "Estimate",
          showValues: "0",
          data: [
            {
              value: (lpPrecentage[0] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[1] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[2] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[3] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[4] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[5] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[6] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[7] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[8] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[9] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[10] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[11] * estimateUsage) / 100,
            },
          ],
        },
        {
          seriesName: "Actual",
          renderAs: "line",
          showValues: "0",
          data: [
            {
              value: actualUsageArray[0],
            },
            {
              value: actualUsageArray[1],
            },
            {
              value: actualUsageArray[2],
            },
            {
              value: actualUsageArray[3],
            },
            {
              value: actualUsageArray[4],
            },
            {
              value: actualUsageArray[5],
            },
            {
              value: actualUsageArray[6],
            },
            {
              value: actualUsageArray[7],
            },
            {
              value: actualUsageArray[8],
            },
            {
              value: actualUsageArray[9],
            },
            {
              value: actualUsageArray[10],
            },
            {
              value: actualUsageArray[11],
            },
          ],
        },
      ],
    };

    return chartData;
  };

  const setKeypadChartData = (topups) => {
    let actualUsageArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    // Get jModel
    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;

    // Set annualUsage
    let estimateUsage = jModel.annualUsage;

    // Set chart yAxis height
    let yAxis = (12 * estimateUsage) / 100;

    // Set load profile precentages based on meter type
    let lpPrecentage = "";
    let duosGroup = jModel.electricityProduct.electricity.duosGroup;

    if (jModel.productRef.includes("E1R")) {
      lpPrecentage = config.ni24hourLoadProfile;
    } else {
      lpPrecentage = config.niEco7LoadProfile;
    }

    try {
      // Actual usage
      // Determine unit rate
      let unitRates = jModel.electricityProduct.electricity.unitRates;
      let standardRate = unitRates.standard / 100;
      let dayUnitRate = unitRates.day;
      let nightUnitRate = unitRates.night;

      // Loop through each topup
      let billsList = topups;

      let monthlyAmts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      //need to loop through each topup as there may be more than one per month
      //add each to the monthly total amount
      for (var z = 0; z < billsList.length; z++) {
        let TopupDate = new Date(billsList[z].dateSubmitted);

        let year = TopupDate.getFullYear();

        if (year === 2021) {
          let TopupMonth = TopupDate.getMonth() + 1;
          switch (TopupMonth) {
            case 1:
              monthlyAmts[0] += billsList[z].amount;
              break;
            case 2:
              monthlyAmts[1] += billsList[z].amount;
              break;
            case 3:
              monthlyAmts[2] += billsList[z].amount;
              break;
            case 4:
              monthlyAmts[3] += billsList[z].amount;
              break;
            case 5:
              monthlyAmts[4] += billsList[z].amount;
              break;
            case 6:
              monthlyAmts[5] += billsList[z].amount;
              break;
            case 7:
              monthlyAmts[6] += billsList[z].amount;
              break;
            case 8:
              monthlyAmts[7] += billsList[z].amount;
              break;
            case 9:
              monthlyAmts[8] += billsList[z].amount;
              break;
            case 10:
              monthlyAmts[9] += billsList[z].amount;
              break;
            case 11:
              monthlyAmts[10] += billsList[z].amount;
              break;
            case 12:
              monthlyAmts[11] += billsList[z].amount;
              break;
          }
        }
      }

      for (var i = 0; i < monthlyAmts.length; i++) {
        let paymentAmount = monthlyAmts[i];
        let monthlySC = config.kp_tfh_monthly_standing_incVat.toFixed(2);
        let monthlyDNSC = config.kp_eco7_monthly_standing_incVat.toFixed(2);
        let actualUsageAmt = 0;

        if (jModel.productRef.includes("E1R")) {
          //  Standard
          actualUsageAmt = paymentAmount;
          actualUsageAmt = actualUsageAmt - monthlySC;
          actualUsageAmt = actualUsageAmt / standardRate;
        } else {
          // Nightsaver
          actualUsageAmt = paymentAmount;
          let halfPaymentAmount = paymentAmount / 2;
          let halfMonthlySC = monthlyDNSC / 2;

          let actualUsageOne = actualUsageAmt / 2;
          let actualUsageTwo = actualUsageAmt / 2;

          actualUsageOne = halfPaymentAmount;
          actualUsageOne = actualUsageOne - halfMonthlySC;
          actualUsageOne = actualUsageOne / dayUnitRate;

          actualUsageTwo = halfPaymentAmount;
          actualUsageTwo = actualUsageTwo - halfMonthlySC;
          actualUsageTwo = actualUsageTwo / nightUnitRate;

          actualUsageAmt = actualUsageOne + actualUsageTwo;
        }

        if (actualUsageAmt > 0) {
          actualUsageArray[i] = actualUsageAmt;
        } else {
          actualUsageArray[i] = 0;
        }
      }

      setActualUsage(actualUsageArray);
    } catch (err) {
      console.log(err);
    }

    const chartData = {
      chart: {
        theme: "bright",
        yAxisMaxValue: yAxis,
        yAxisName: "Usage (kWh)",
      },
      categories: [
        {
          category: [
            {
              label: "Jan",
            },
            {
              label: "Feb",
            },
            {
              label: "March",
            },
            {
              label: "April",
            },
            {
              label: "May",
            },
            {
              label: "Jun",
            },
            {
              label: "Jul",
            },
            {
              label: "Aug",
            },
            {
              label: "Sep",
            },
            {
              label: "Oct",
            },
            {
              label: "Nov",
            },
            {
              label: "Dec",
            },
          ],
        },
      ],
      dataset: [
        {
          seriesName: "Estimate",
          showValues: "0",
          data: [
            {
              value: (lpPrecentage[0] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[1] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[2] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[3] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[4] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[5] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[6] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[7] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[8] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[9] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[10] * estimateUsage) / 100,
            },
            {
              value: (lpPrecentage[11] * estimateUsage) / 100,
            },
          ],
        },
        {
          seriesName: "Actual",
          renderAs: "line",
          showValues: "0",
          data: [
            {
              value: actualUsageArray[0],
            },
            {
              value: actualUsageArray[1],
            },
            {
              value: actualUsageArray[2],
            },
            {
              value: actualUsageArray[3],
            },
            {
              value: actualUsageArray[4],
            },
            {
              value: actualUsageArray[5],
            },
            {
              value: actualUsageArray[6],
            },
            {
              value: actualUsageArray[7],
            },
            {
              value: actualUsageArray[8],
            },
            {
              value: actualUsageArray[9],
            },
            {
              value: actualUsageArray[10],
            },
            {
              value: actualUsageArray[11],
            },
          ],
        },
      ],
    };
    return chartData;
  };

  return (
    <div className="account-dashboard-container">
      <div className="account-details-container">
        <NavigationSection />
        <div className="make-payment-background">
          <MDBContainer className="main-content electricity-usage">
            {/* Page Title */}
            <MDBRow>
              <MDBCol size="1">
                <a href={`/${jurisdiction}/account/more`}>
                  <i className="fas fa-chevron-left fa-2x" />
                </a>
              </MDBCol>
              <MDBCol size="10" className="text-center ">
                <h1 className="mt-1"> Your Electricity Usage </h1>
              </MDBCol>
              <MDBCol size="1"></MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="12">
                <div className="white-curved-tile text-left">
                  {/* Savings Text */}
                  <p>
                    As a bright customer, you could save approximately{" "}
                    <b>{tonnesSaved}</b> tonnes of CO2 this year. That's
                    equivalent of planting <b>{treesPlanted}</b> trees!
                  </p>

                  {/* Title */}
                  <p>
                    <b>Current Usage</b>
                  </p>

                  {/* Chart */}
                  <MDBRow className="justify-content-center">
                    <MDBCol lg="10" md="8" sm="12">
                      <div className="d-flex justify-content-center">
                        <ReactFC {...chartConfig} />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  {/* Summary Text */}
                  <MDBRow className="content mt-5">
                    <MDBCol lg="8" md="8" sm="12" className="text-center">
                      {/* Energy Saving Tips Button */}
                      <a
                        href={`/${jurisdiction}/blog/post?title=Bright’s-top-tips-for-energy-saving`}
                      >
                        <MDBBtn className="btn btn-teal white-text">
                          Energy savings tips
                        </MDBBtn>
                      </a>
                    </MDBCol>
                    <MDBCol lg="4" md="4" sm="12" className="show-for-medium">
                      {/* Arrow Image */}
                      <img
                        src={require("../../../images/account/good-for-the-planet.png")}
                      />
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
    </div>
  );
};

export default EnergyUsage;
