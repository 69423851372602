import React, { Component } from "react";
import { MDBCol, MDBRow, MDBBtn, MDBAlert, MDBInput } from "mdbreact";
import { connect } from "react-redux";
import { quoteActions } from "../../../../redux/actions/quoteActions";
import Loader from "../../../Shared/Loader";
import $ from "jquery";

export class _FormPayment extends Component {
  state = {
    paymentMethod: "SetDirectDebit",
    monthlyPaymentPos: 1,
    monthlyPaymentValue: 0,
    electricityUsage: "",
    electricityGuidance: "",
    electricityUsageSelect: "",
    guidanceRequired: false,
    jurisdiction: "roi",

    // Show/Hide sections
    errorMessage: false,
    depositMessage: false,
    showUsageInput: false,
    showUsageGuidance: false,
    homeCheck: false,
    phoneMessage: false,
  };

  // Page load
  componentDidMount() {
    // Check for a quoteID in local storage. If not null get that quote by ID
    if (localStorage.getItem("quoteID") != null) {
      let quoteID = localStorage.getItem("quoteID");
      this.props.getQuote(quoteID);
    }

    this.setState({
      electricityUsageSelect: "Option",
    });
  }

  // Populate form on back
  async componentWillReceiveProps(nextProps) {
    // Get page status from local storage
    let pageStatus = JSON.parse(localStorage.getItem("paymentUpdated"));

    // If page status e.g. pageUpdated = true -> populate the form
    if (pageStatus) {
      await this.setState({
        paymentMethod: nextProps.quoteData.paymentMethod,
        electricityUsageSelect: nextProps.quoteData.consumptionOption,
        electricityUsage: nextProps.quoteData.electricityUsage,
      });

      if (nextProps.quoteData.consumptionOption == "Actual") {
        this.setState({ showUsageInput: true });
      } else {
        this.setState({ showUsageInput: false });
      }
    }

    console.log(nextProps.quoteData.consumptionOption);

    // If page status = true and consumptionOption = guidance -> call populateGuidance
    if (nextProps.quoteData.consumptionOption === "Guidance") {
      this.populateGuidance(nextProps.quoteData.electricityUsage);
    }

    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Populate guidance on back
  populateGuidance = (usage) => {
    this.setState({
      showUsageGuidance: true,
    });

    switch (usage) {
      case 2100:
        this.setState({
          electricityGuidance: "lowUsage",
        });

      case 4200:
        this.setState({
          electricityGuidance: "mediumUsage",
        });
        break;

      case 8000:
        this.setState({
          electricityGuidance: "highUsage",
        });
        break;

      default:
        break;
    }
  };

  // Handle continue
  nextStep = (e) => {
    let usageSelect = this.state.electricityUsageSelect;
    let validation = "";

    if (usageSelect == "Option") {
      validation = false;
    } else {
      validation = $("#paymentDetails")[0].checkValidity();
    }

    if (validation) {
      this.setState({ errorMessage: false });

      // Form data
      const data = {
        // Step one - Property
        propertyType: this.props.quoteData.propertyType,
        residents: this.props.quoteData.residents,

        // Step two - Meter
        urbanRural: this.props.quoteData.urbanRural,
        meterType: this.props.quoteData.meterType,

        // Step three (this step) - Payment
        paymentMethod: this.state.paymentMethod,
        consumptionOption: this.state.electricityUsageSelect,
        electricityUsage: parseInt(this.state.electricityUsage),

        // Step four - Gas
        onGasTariff: this.props.quoteData.onGasTariff,
        interestedInBrightGas: this.props.quoteData.interestedInBrightGas,

        // Step four - Ev
        hasElectricVehicle: this.props.quoteData.hasElectricVehicle,
        vehicleMake: this.props.quoteData.vehicleMake,
      };

      // Quote id from local storage
      let id = localStorage.getItem("quoteID");

      // This Page (updates page status)
      let pageStatus = "paymentUpdated";

      // Next Page URL
      let nextPage = "gas";

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Quote Journey
      this.props.updateQuote(id, data, pageStatus, nextPage, jurisdiction);
    } else {
      // For each form input that has the required prop add the input-error class
      $("form#paymentDetails")
        .find("input")
        .each(function () {
          if ($(this).prop("required")) {
            $(this).addClass("input-error");
          }
        });

      // Show error
      this.setState({ errorMessage: true });

      // Scroll to error
      window.location.href = "#errorMessage";
    }
  };

  // Handle payment type
  paymentTypeClick = (value) => {
    switch (value) {
      case "SetDirectDebit":
        this.setState({
          paymentMethod: value,
          depositMessage: false,
          phoneMessage: false,
        });
        break;

      case "VariableDirectDebit":
        this.setState({
          paymentMethod: value,
          depositMessage: true,
          phoneMessage: false,
        });
        break;

      case "PayOnReceipt":
        this.setState({
          paymentMethod: value,
          depositMessage: true,
          phoneMessage: true,
        });
        break;

      default:
        this.setState({
          paymentMethod: value,
          depositMessage: false,
        });
        break;
    }
  };

  // Handle select change
  handleUsageChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });

    console.log(e.target.value);

    switch (e.target.value) {
      case "Option":
        this.setState({
          showUsageGuidance: false,
          showUsageInput: false,
          guidanceRequired: false,
          electricityUsage: "",
        });
        break;

      case "NationalAvg":
        this.setState({
          electricityUsage: 4200,
          showUsageInput: false,
          showUsageGuidance: false,
          guidanceRequired: false,
        });
        break;

      case "Guidance":
        this.setState({
          showUsageGuidance: true,
          showUsageInput: false,
          guidanceRequired: true,
          electricityUsage: "",
        });
        break;

      case "Actual":
        this.setState({
          showUsageInput: true,
          showUsageGuidance: false,
          guidanceRequired: false,
          electricityUsage: "",
        });
        break;

      default:
        this.setState({
          showUsageGuidance: false,
          showUsageInput: false,
          guidanceRequired: false,
          electricityUsage: "",
        });
        break;
    }
  };

  // Handle usage change
  handleChange = (input) => (e) => {
    var fieldValue = $("#" + input)[0].checkValidity();

    if (fieldValue) {
      $("#" + input)
        .addClass("input-valid")
        .removeClass("input-error");
    } else {
      $("#" + input)
        .addClass("input-error")
        .removeClass("input-valid");
    }

    let newInput = e.target.value;

    if (newInput != "") {
      newInput = parseInt(e.target.value);
      this.setState({ [input]: newInput });
    } else {
      this.setState({ [input]: e.target.value });
    }
  };

  // Handle guidance select
  handleUsageGuidance = (value) => {
    switch (value) {
      case "lowUsage":
        this.setState({
          electricityUsage: 2100,
          electricityGuidance: "lowUsage",
        });
        break;

      case "mediumUsage":
        this.setState({
          electricityUsage: 4200,
          electricityGuidance: "mediumUsage",
        });
        break;

      case "highUsage":
        this.setState({
          electricityUsage: 8000,
          electricityGuidance: "highUsage",
        });
        break;

      default:
        break;
    }
  };

  // Back button
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    return (
      <div className='quote-page'>
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        {/* Background Image */}
        <div className='device-image' />
        <form id='paymentDetails'>
          <MDBCol className='text-center'>
            {/* Logo */}
            <MDBRow>
              <MDBCol lg='2' sm='12'>
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <img
                    src={require("../../../../images/global/bright-logo-dark.png")}
                    className='quote-logo mx-5 mt-5'
                  />
                </a>
              </MDBCol>
              <MDBCol size='10' />
            </MDBRow>

            {/* Home message */}
            {this.state.homeCheck ? (
              <div className='modalBG'>
                <div className='modalBox'>
                  <div className='white-curved-tile text-center'>
                    <h4>
                      All progress will be lost! <br />
                      Are you sure?
                    </h4>
                    <MDBRow>
                      <MDBCol lg='6' md='6' sm='12'>
                        <MDBBtn
                          className='cancel-button'
                          onClick={() =>
                            this.setState({ homeCheck: !this.state.homeCheck })
                          }
                        >
                          Cancel
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol lg='6' md='6' sm='12'>
                        <MDBBtn onClick={() => (window.location.href = "/")}>
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Error Message */}
            <MDBRow className='text-center my-5' id='errorMessage'>
              <MDBCol size='4' />
              <MDBCol lg='4' md='4' sm='12'>
                {this.state.errorMessage == true ? (
                  <MDBAlert color='danger'>
                    <strong>Oops!</strong> Please check your fields below.
                  </MDBAlert>
                ) : null}
              </MDBCol>
              <MDBCol size='4' />
            </MDBRow>

            {/* Payment type */}
            <MDBRow className='mb-5'>
              <MDBCol size='2' />
              <MDBCol lg='8' md='8' sm='12' className='text-center'>
                {/* Question */}
                <h4 className='mb-4'>
                  How do you want to pay for your electricity?
                </h4>

                {/* Pre-pay message */}
                <MDBRow className='mb-3'>
                  <MDBCol size='2' />
                  <MDBCol lg='8' md='8' sm='12'>
                  {this.state.phoneMessage ?(
                      <MDBAlert className="blue-highlight">
                      <p className="black-text"> 
                        In order to switch to bright as a Pay on Receipt customer please give us a call on 
                        <b className='font-weight-bolder'><a href="tel:01 588 1777"> 01 588 1777</a></b>
                         {" "} and our energy specialists will be happy to help. You'll need a recent bill, meter read and a
                         payment method to pay the security deposit.
                      </p>
                    </MDBAlert>
                    ) : null}
                    {this.state.depositMessage == true ? (
                      <MDBAlert className="blue-highlight">
                         <p className="black-text"> 
                          Security Deposit: We want to keep our costs as low as
                          possible so that we can keep your costs low. To reduce
                          the risk of bad debt we require all customers not
                          paying in advance by direct debit to pay{" "}
                          <b className='font-weight-bolder'>€150</b> security
                          deposit. Details of our security deposit policy can be
                          found{" "}
                          <a
                            href={`/${this.state.jurisdiction}/ourpolicies#security-deposit-policy`}
                            target='_blank'
                          >
                            <b className='font-weight-bolder'>here</b>
                          </a>
                        </p>
                      </MDBAlert>
                    ) : null}
                    
                  </MDBCol>
                  <MDBCol size='2' />
                </MDBRow>

                <MDBRow>
                  <MDBCol sm='12' md='4' lg='4' className='paymentOption'>
                    {/* Fixed Direct Debit */}
                    <input
                      onClick={() => this.paymentTypeClick("SetDirectDebit")}
                      checked={
                        this.state.paymentMethod === "SetDirectDebit"
                          ? true
                          : false
                      }
                      className='form-check-input'
                      id='SetDirectDebit'
                      name='paymentMethod'
                      required
                      type='radio'
                    />
                    <label for='SetDirectDebit' className='radioLabel'>
                      Monthly set direct debit
                    </label>
                  </MDBCol>
                  <MDBCol sm='12' md='4' lg='4' className='paymentOption'>
                    {/* Variable direct debit */}
                    <input
                      onClick={() =>
                        this.paymentTypeClick("VariableDirectDebit")
                      }
                      checked={
                        this.state.paymentMethod === "VariableDirectDebit"
                          ? true
                          : false
                      }
                      className='form-check-input'
                      id='VariableDirectDebit'
                      name='paymentMethod'
                      type='radio'
                    />
                    <label for='VariableDirectDebit' className='radioLabel'>
                      Variable direct debit
                    </label>
                  </MDBCol>
                  <MDBCol sm='12' md='4' lg='4' className='paymentOption'>
                    {/* On receipt of bill */}
                    <input
                      onClick={() => this.paymentTypeClick("PayOnReceipt")}
                      checked={
                        this.state.paymentMethod === "PayOnReceipt"
                          ? true
                          : false
                      }
                      className='form-check-input'
                      id='PayOnReceipt'
                      name='paymentMethod'
                      type='radio'
                    />
                    <label for='PayOnReceipt' className='radioLabel'>
                      Pay on receipt of bill
                    </label>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol size='2' />
            </MDBRow>

            {/* if customers has selected pay on receipt then dont show this section*/}
           {!this.state.phoneMessage? [(
             <div>

            <MDBRow>
              <MDBCol size='3' />
              <MDBCol lg='6' md='6' sm='12' className='text-center'>
                <h4>How much electricity do you currently use?</h4>
                <select
                  id='electricityUsageSelect'
                  className='browser-default custom-select'
                  onChange={this.handleUsageChange("electricityUsageSelect")}
                  value={this.state.electricityUsageSelect}
                  required
                >
                  <option value='Option'>Please select an option</option>
                  <option value='Actual'>
                    I know how much I consume (kWh)
                  </option>
                  <option value='Guidance'>Not sure - use guidance</option>
                  <option value='NationalAvg'>
                    Not sure - use national average (4200kWh)
                  </option>
                </select>

                {this.state.showUsageInput ? (
                  <div>
                    <label for='electricityUsage'>
                      <h4>Forecast annual electricity usage (kWh)</h4>
                    </label>
                    <MDBInput
                      outline
                      className='quote-input mb-3'
                      type='text'
                      id='electricityUsage'
                      value={this.state.electricityUsage}
                      onChange={this.handleChange("electricityUsage")}
                      pattern='[0-9]{1,10}'
                      required
                    />
                  </div>
                ) : null}
              </MDBCol>
            </MDBRow>

            {this.state.showUsageGuidance ? (
              <MDBRow className='mt-3 mb-5'>
                <MDBCol size='2' />
                <MDBCol lg='8' md='12' sm='12' className='text-center'>
                  {/* Title */}
                  <h4 className='mb-4'>Electricity usage guidance</h4>

                  <MDBRow>
                    <MDBCol sm='4' md='4' lg='4' className='paymentOption'>
                      <input
                        onClick={() => this.handleUsageGuidance("lowUsage")}
                        checked={
                          this.state.electricityGuidance === "lowUsage"
                            ? true
                            : false
                        }
                        className='low-usage-check'
                        id='lowUsage'
                        name='electricityGuidance'
                        required={this.state.guidanceRequired}
                        type='radio'
                      />
                      <label for='lowUsage' className='usageLabel'>
                        <h5>Low</h5>
                        <ul className='list-unstyled ml-3 lead'>
                          <li>1-2 bedrooms</li>
                          <li>Quiet daytime</li>
                          <li>Newer (1970+)</li>
                        </ul>
                        <p>Approximate usage:</p>
                        <p>
                          <b>2100 kWh</b>
                        </p>
                      </label>
                    </MDBCol>

                    <MDBCol sm='4' md='4' lg='4' className='paymentOption'>
                      <input
                        onClick={() => this.handleUsageGuidance("mediumUsage")}
                        checked={
                          this.state.electricityGuidance === "mediumUsage"
                            ? true
                            : false
                        }
                        className='medium-usage-check'
                        id='mediumUsage'
                        name='electricityGuidance'
                        required={this.state.guidanceRequired}
                        type='radio'
                      />
                      <label for='mediumUsage' className='usageLabel'>
                        <h5>Medium</h5>
                        <ul className='list-unstyled ml-3 lead'>
                          <li>3 bedrooms</li>
                          <li>3-5 occupants</li>
                          <li>Busy daytime</li>
                        </ul>
                        <p className='font-weight-bold'>Approximate usage:</p>
                        <p>
                          <b>4200 kWh</b>
                        </p>
                      </label>
                    </MDBCol>

                    <MDBCol sm='4' md='4' lg='4' className='paymentOption'>
                      <input
                        onClick={() => this.handleUsageGuidance("highUsage")}
                        checked={
                          this.state.electricityGuidance === "highUsage"
                            ? true
                            : false
                        }
                        className='high-usage-check'
                        id='highUsage'
                        name='electricityGuidance'
                        required={this.state.guidanceRequired}
                        type='radio'
                      />
                      <label for='highUsage' className='usageLabel'>
                        <h5>High</h5>
                        <ul className='list-unstyled ml-3 lead'>
                          <li>4+ bedrooms</li>
                          <li>Older (pre 1970s)</li>
                          <li>Busy all times</li>
                        </ul>
                        <p>Approximate usage:</p>
                        <p>
                          <b>8000 kWh</b>
                        </p>
                      </label>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol size='2' />
              </MDBRow>
            ) : null}

            {/* Buttons */}
            <MDBRow className='mt-2 mb-5'>
              <MDBCol size='3' />
              <MDBCol lg='6' md='6' sm='12' className='text-center'>
                {/* Back */}
                <a
                  href={`/${this.state.jurisdiction}/quote/meter`}
                  className='mr-3'
                >
                  <MDBBtn className='btn switch-button'>Back</MDBBtn>
                </a>
                {/* Next Step */}
                <MDBBtn onClick={this.nextStep} className='btn'>
                  Continue
                </MDBBtn>
              </MDBCol>
              <MDBCol size='3' />
            </MDBRow>

            </div>)]: null}
          </MDBCol>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    quoteData: store.quote.quoteData,
    isLoading: store.quote.isLoading,
  };
};

const actionCreators = {
  updateQuote: quoteActions.updateQuoteJourney,
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(_FormPayment);
