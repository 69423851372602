import { meterReadService } from "../services";
import { meterReadConstants } from "../constants";

export const meterReadActions = {
  submitReading,
  getMeterReading,
  submitLandlordReading,
  getMeterStructure,
};

// Submit Meter Reading
function submitReading(readingDetails) {
  return (dispatch) => {
    // Request
    dispatch(submitReadingRequest(readingDetails));

    meterReadService.submitReadingService(readingDetails).then(
      (response) => {
        dispatch(submitReadingSuccess(response.data));
        // alert("Your meter read has been submited successfully!");
        // window.location.reload();
      },
      (error) => {
        dispatch(submitReadingFailure(error));
      }
    );
  };

  function submitReadingRequest(dbData) {
    return {
      type: meterReadConstants.METER_READ_REQUEST,
      payload: dbData,
    };
  }

  function submitReadingSuccess(dbData) {
    return {
      type: meterReadConstants.METER_READ_SUCCESS,
      payload: dbData,
    };
  }

  function submitReadingFailure(error) {
    return {
      type: meterReadConstants.METER_READ_FAILURE,
      payload: error,
    };
  }
}

// Submit Landlord Meter Reading
function submitLandlordReading(readingDetails) {
  return (dispatch) => {
    // Request
    dispatch(submitLandlordReadingRequest(readingDetails));

    meterReadService.submitLandlordReadingService(readingDetails).then(
      (response) => {
        dispatch(submitLandlordReadingSuccess(response.data));
      },
      (error) => {
        dispatch(
          submitLandlordReadingFailure(
            "Oops! Look's like something went wrong. Please try again."
          )
        );
      }
    );
  };

  function submitLandlordReadingRequest(dbData) {
    return {
      type: meterReadConstants.LANDLORD_METER_READ_REQUEST,
      payload: dbData,
    };
  }

  function submitLandlordReadingSuccess(dbData) {
    return {
      type: meterReadConstants.LANDLORD_METER_READ_SUCCESS,
      payload: dbData,
    };
  }

  function submitLandlordReadingFailure(error) {
    return {
      type: meterReadConstants.LANDLORD_METER_READ_FAILURE,
      payload: error,
    };
  }
}

// Get Meter Reading
function getMeterReading() {
  return (dispatch) => {
    // Request
    dispatch(getMeterReadingRequest());

    meterReadService.getMeterReadingService().then(
      (response) => {
        dispatch(getMeterReadingSuccess(response.data));
      },
      (error) => {
        alert(error);
        // dispatch(getMeterReadingFailure([error.response.data.message]));
      }
    );
  };

  function getMeterReadingRequest(dbData) {
    return {
      type: meterReadConstants.GET_METER_READ_REQUEST,
      payload: dbData,
    };
  }

  function getMeterReadingSuccess(dbData) {
    return {
      type: meterReadConstants.GET_METER_READ_SUCCESS,
      payload: dbData,
    };
  }

  function getMeterReadingFailure(error) {
    return {
      type: meterReadConstants.GET_METER_READ_FAILURE,
      payload: error,
    };
  }
}

// Get Meter Structure
function getMeterStructure() {
  return (dispatch) => {
    // Request
    dispatch(getMeterStructureRequest());

    meterReadService.getMeterStructureService().then(
      (response) => {
        dispatch(getMeterStructureSuccess(response.data));
      },
      (error) => {
        dispatch(getMeterStructureFailure(error));
      }
    );
  };

  function getMeterStructureRequest(dbData) {
    return {
      type: meterReadConstants.GET_METER_STRUCTURE_REQUEST,
      payload: dbData,
    };
  }

  function getMeterStructureSuccess(dbData) {
    return {
      type: meterReadConstants.GET_METER_STRUCTURE_SUCCESS,
      payload: dbData,
    };
  }

  function getMeterStructureFailure(error) {
    return {
      type: meterReadConstants.GET_METER_STRUCTURE_FAILURE,
      payload: error,
    };
  }
}
