import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBDataTable, MDBBtn } from "mdbreact";
import { connect } from "react-redux";
import { signinActions } from "../../../../redux/actions";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { sessionExpired } from "../../../../redux/helpers/sessionExpired";
import Loader from "../../../../components/Shared/Loader";

class LandlordDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      howToTip: false,
      jurisdiction: "",
      numPages: null,
      pageNumber: 1,
      bills: [],
      csvData: [],
    };
  }

  getBill = (id) => {
    this.props.getBill(id);
  };

  async componentWillReceiveProps(nextProps) {
    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  componentDidMount() {
    sessionExpired();

    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;
    let properties = user.data.properties;

    this.setState({
      jurisdiction: jurisdiction,
      userId: user.data.authenticateModel.id,
    });

    // if (!properties.length > 1) {
    let propertyData = []; //empty array
    var totalBill = 0;
    var datefmt = "";
    let csvData = [];
    const rows = [];
    for (var i = 0; i < properties.length; i++) {
      var cancelledProp = false;
      try {
        cancelledProp = properties[i].cancelled;
      } catch {}

      var streetN = "";
      let address1 = "";
      if (properties[i].billingAddress.address1 != null) {
        address1 = properties[i].billingAddress.address1 + ", ";
      } else {
        address1 = "";
      }

      if (!cancelledProp) {
        if (properties[i].billingAddress.address2 != null) {
          streetN = address1 + properties[i].billingAddress.address2;
        }

        var addr = streetN + " " + properties[i].billingAddress.address3;
        if (properties[i].id === jModel.id) {
          addr += " (Active)";
        }

        var options = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        var dt = new Date("2021-04-30");
        datefmt = dt.toLocaleDateString();

        totalBill += properties[i].balance;

        rows.push({
          address: addr,
          accno: properties[i].number,
          balance: properties[i].balance,
          view: (
            <a
              href={`/roi/account/AgencyPropertyView?acNo=${properties[i].number}`}
            >
              View
            </a>
          ),
        });

        propertyData.push(
          {
            value: properties[i].id,
            label: addr,
            accno: properties[i].number,
            balance: properties[i].balance,
            nextPaymentDate: properties[i].nextPaymentDate,
          } //push each property to the dropdown list
        );

        csvData.push({
          Address: addr,
          AccountNumber: properties[i].number,
          BillAmount: properties[i].balance,
        });
      }
    }

    const data = {
      columns: [
        {
          label: "Property Address",
          field: "address",
          sort: "asc",
          width: 150,
        },
        {
          label: "Account Number",
          field: "accno",
          sort: "asc",
          width: 150,
        },
        {
          label: "Bill Amount",
          field: "balance",
          sort: "asc",
          width: 150,
        },
        {
          label: "View Property",
          field: "view",
          sort: "asc",
          width: 150,
        },
      ],
      rows: rows,
    };

    //show dropdown
    this.setState({
      propData: propertyData,
      totalBillAmount: totalBill.toFixed(2),
      dueDate: datefmt,
      data: data,
      csvData: csvData,
    });

    if (propertyData.length > 1) {
      this.setState({
        showMultiProp: true,
      });
    } else {
      this.setState({
        showMultiProp: false,
      });
    }
  }

  PropertyChange = (propertyOption) => {
    this.setState({
      selectedProperty: propertyOption.value,
    });

    this.props.switchAccount(
      this.state.userId,
      propertyOption.value,
      this.state.jurisdiction
    );
  };

  render() {
    return (
      <div className="account-dashboard-container">
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}
        <div className="account-details-container">
          <div className="make-payment-background">
            <MDBContainer className="main-content payment-history">
              {/* Page Title */}
              <MDBRow>
                <MDBCol size="1">
                  <img
                    src={require("../../../../images/global/bright-logo-dark.png")}
                  />
                </MDBCol>
                <MDBCol size="10" className="text-center pt-5">
                  <h3>Landlord Summary (BETA)</h3>
                </MDBCol>
                <MDBCol size="1"></MDBCol>
              </MDBRow>

              <MDBRow className="mt-5">
                <MDBCol>
                  <div className="white-curved-tile text-center">
                    <h4>Num of properties</h4>
                    {this.state.propData != null
                      ? this.state.propData.length
                      : 0}
                  </div>
                </MDBCol>
                <MDBCol>
                  <div className="white-curved-tile text-center">
                    <h4>Total bill balance</h4>€{this.state.totalBillAmount}
                  </div>
                </MDBCol>
                <MDBCol>
                  <div className="white-curved-tile text-center">
                    <h4>Next payment due</h4>
                    {this.state.dueDate}
                  </div>
                </MDBCol>
              </MDBRow>
              <div className="white-curved-tile">
                <MDBCol md="12" className="p-5">
                  {/* Export to CSV */}
                  <MDBRow className="justify-content-end mb-3 mr-1">
                    <a>
                      <h6>
                        <CSVLink
                          data={this.state.csvData}
                          filename={"PropertyData.csv"}
                          target="_blank"
                          onClick={() => console.log(this.state.csvData)}
                        >
                          <i class="fas fa-download fa-md mr-2" />
                          Export to CSV
                        </CSVLink>
                      </h6>
                    </a>
                  </MDBRow>

                  {/* Table */}
                  <MDBDataTable
                    id="propertyTable"
                    striped
                    bordered
                    small
                    data={this.state.data}
                  />
                </MDBCol>
              </div>

              {/* Account actions */}
              <div className="white-curved-tile text-center">
                <MDBRow className="justify-content-start">
                  <h4>Account Actions</h4>
                </MDBRow>
                {/* Make A Payment Button */}
                <MDBRow className="justify-content-start">
                  <Link
                    to={{
                      pathname: "/roi/account/agencypayment",
                      state: {
                        billAmount: this.state.totalBillAmount,
                      },
                    }}
                    className="editBtn"
                  >
                    <MDBBtn>Make a payment</MDBBtn>
                  </Link>
                  <div class="divider"/>
                  {/* New Property */}
                  <Link
                    to={{
                      pathname: "/roi/account/agencynewtenant"
                    }}
                    className="editBtn"
                  >
                    <MDBBtn>Tenancy Form</MDBBtn>
                  </Link>
                </MDBRow>
                
              </div>
            </MDBContainer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    error: store.signin.error,
    isLoading: store.signin.isLoading,
  };
};

const actionCreators = {
  switchAccount: signinActions.switchAccount,
};

export default connect(mapStateToProps, actionCreators)(LandlordDashboard);
