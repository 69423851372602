import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBAlert,
} from "mdbreact";
import ReactCodeInput from "react-code-input";
import Loader from "../../components/Shared/Loader";
import NavigationSection from "../../components/CustomerAccount/AccountNavigation";
import { sessionExpired } from "../../redux/helpers/sessionExpired";

// Redux
import { meterReadActions } from "../../redux/actions";

export const MeterReading = () => {
  const dispatch = useDispatch();
  const meterReadingStore = useSelector((state) => state.meterRead);

  const [productRef, setProductRef] = useState("");
  const [meterReading1, setMeterReading] = useState();
  const [nightReadingValue, setNightReadingValue] = useState(0);
  const [nshReadingValue, setNshReadingValue] = useState(0);
  const [mprn, setMprn] = useState();
  const [loading, setLoading] = useState(false);
  const [meterReadingError, setMeterReadingError] = useState(false);
  const [howToTip, setHowToTip] = useState(false);
  const [jurisdiction, setJurisdiction] = useState();
  const [supplier, setSupplier] = useState();
  const [meterCount, setMeterCount] = useState(0);
  const [showNSHSection, setShowNSHSection] = useState(false);

  useEffect(() => {
    sessionExpired();
    dispatch(meterReadActions.getMeterReading());
    dispatch(meterReadActions.getMeterStructure());
    setLoading(true);

    // Set jurisdiction
    var pathArray = window.location.pathname.split("/");
    var jurisdiction = pathArray[1];
    setJurisdiction(jurisdiction);

    if (jurisdiction == "roi") {
      setSupplier("ESB Networks");
    } else {
      setSupplier("NIE Networks");
    }

    let user = JSON.parse(localStorage.getItem("user"));
    let jModel = user.data.jModel;

    if (jModel != null) {
      // MPRN
      setMprn(jModel.mprn);

      // Product referance
      setProductRef(jModel.productRef);
    }
  }, []);

  // API Loader
  useEffect(() => {
    if (meterReadingStore.isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [meterReadingStore.isLoading]);

  useEffect(() => {
    let count = 0;
    try {
      if (meterReadingStore.meterStructure && meterReadingStore.meterStructure != null) {
        meterReadingStore.meterStructure.meters.map(meter => {
          count += 1;
        });
      }
    } catch { }
    setLoading(false);
    setMeterCount(count);


    switch (productRef) {
      case "E1R-VAR":
        if (count > 1) {
          setShowNSHSection(true);
        }
        break;

      case "E2R-VAR":
        if (count > 1) {
          setShowNSHSection(true);
        }
        break;

      case "E3R-VAR":
        setShowNSHSection(true);
        break;
    }

  }, [meterReadingStore.meterStructure]);

  const handleMeterReading = () => {
    let date = new Date().toISOString().slice(0, 19);

    let meterReading = meterReading1;
    console.log("Meter Reading: " + meterReading);

    let readingRegex = /^[0-9]/;

    if (!meterReading.match(readingRegex)) {
      setMeterReadingError(true);
    } else {
      setMeterReadingError(false);

      let meterType = "";
      if (productRef.includes("E1R")) {
        if (meterCount > 1) {
          meterType = "NSH";
        } else {
          meterType = "Standard";
        }
      }

      if (productRef.includes("E2R")) {
        if (meterCount > 1) {
          meterType = "NSH";
        } else {
          meterType = "NightSaver";
        }
      }

      if (productRef.includes("E3R")) {
        meterType = "Heat";
      }
      
      if (productRef.includes("PPM")) {
        meterType = "Keypad";
      }

      const readingDetails = {
        dateSubmitted: date,
        readingValue: parseInt(meterReading),
        nightReadingValue: parseInt(nightReadingValue),
        nshReadingValue: parseInt(nshReadingValue),
        meterType: meterType,
        jurisdiction: jurisdiction,
      };

      dispatch(meterReadActions.submitReading(readingDetails));
    }
  };

  const padLeadingZeros = (num) => {
    var s = num + "";
    while (s.length < 5) s = "0" + s;
    return s;
  }

  return (
    <div className="account-dashboard-container">
      <div className="account-details-container">
        <NavigationSection />
        <div className="meter-readings-background">
          {/* API Loader */}
          {loading ? <Loader /> : null}

          {/* Reading success/failure alert */}
          {meterReadingStore.submitReadingSuccess ? (
            <div className="modalBG">
              <div className="modalBox">
                <div className="white-curved-tile text-center">
                  <h4>Your meter read has been submited successfully!</h4>
                  <p className="meter-readings-message">
                    * Please note this reading is subject to verification by{" "}
                    {supplier}
                  </p>
                  <MDBRow>
                    <MDBCol lg="12" md="12" sm="12">
                      <MDBBtn onClick={() => window.location.reload()}>
                        Ok
                        </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
          ) : null}

          <MDBContainer className="main-content meter-readings">
            {/* Page Title */}
            <MDBRow>
              <MDBCol md="12" className="text-center">
                <h1>Your Meter Readings</h1>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="12">
                <div className="white-curved-tile text-left">
                  {/* Help Section */}
                  <div className="faq-section">
                    <img
                      src={require("../../images/account/bulb-icon.png")}
                    />
                    <p>
                      <b>Here's a Bright idea!</b>
                      <br /> Submitting a meter reading regularly means you
                        only pay for energy that you use! It only takes a couple
                        of minutes and our torch tool may come in handy 🔦
                      </p>
                  </div>

                  {/* Error Message */}
                  <MDBCol size="12">
                    {meterReadingError == true ? (
                      <MDBAlert color="danger" className="mb-5" dismiss>
                        <strong>Oops!</strong> Please check your fields below.
                      </MDBAlert>
                    ) : null}
                  </MDBCol>

                  {/* MPRN */}
                  <p>
                    <b>
                      Your MPRN:{" "}
                      <span className="underline">{mprn}</span>
                    </b>
                  </p>

                  {/* 24h meter */}
                  {productRef.includes("E1R") ? (
                    <div>
                      <div>
                        <div>
                          {/* Previous Reading Date */}
                          <p>
                            <b>Previous electricity reading on: </b>
                            {meterReadingStore.previousReading &&
                              meterReadingStore.previousReading[0] != null ? (
                                <span className="underline">
                                  {meterReadingStore.previousReading[0].dateSubmitted
                                    .split("T")[0]
                                    .split("-")
                                    .reverse()
                                    .join("/")}
                                </span>
                              ) : null}
                          </p>
                          {meterReadingStore.previousReading &&
                            meterReadingStore.previousReading[0] != null ? (
                              <div>
                                <p>
                                  <b>Previous meter reading:</b>
                                </p>
                                <ReactCodeInput
                                  fields={5}
                                  value={padLeadingZeros(
                                    JSON.parse(
                                      meterReadingStore.previousReading[0].readingValue
                                    ).toString()
                                  )}
                                  disabled
                                />
                              </div>
                            ) : null}
                        </div>

                        {/* New Meter Reading */}
                        <div>
                          <p>
                            <b>New meter reading:</b>
                          </p>
                          <input
                            type="text"
                            // pattern='^[_0-9]{5}'
                            id="meterReading"
                            name="meterReading"
                            className={"meterRead"}
                            outline
                            onChange={(e) => setMeterReading(e.target.value)}
                            placeholder={"00000"}
                            maxLength="5"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* Nightsaver meter */}
                  {productRef.includes("E2R") ? (
                    <div>
                      <div>
                        <div>
                          {/* Previous Reading Date */}
                          <p>
                            <b>Previous electricity reading on: </b>
                            {meterReadingStore.previousReading &&
                              meterReadingStore.previousReading[0] != null ? (
                                <span className="underline">
                                  {meterReadingStore.previousReading[0].dateSubmitted
                                    .split("T")[0]
                                    .split("-")
                                    .reverse()
                                    .join("/")}
                                </span>
                              ) : null}
                          </p>
                          {/* Previous day reading */}
                          {meterReadingStore.previousReading &&
                            meterReadingStore.previousReading[0] != null ? (
                              <div>
                                <p>
                                  <b>
                                    Previous{" "}
                                    <span className="underline">day</span> meter
                                  reading:
                                </b>
                                </p>
                                <ReactCodeInput
                                  fields={5}
                                  value={JSON.parse(
                                    meterReadingStore.previousReading[0].readingValue
                                  ).toString()}
                                  disabled
                                />
                              </div>
                            ) : null}

                          {/* Previous night reading */}
                          {meterReadingStore.previousReading &&
                            meterReadingStore.previousReading[0] != null ? (
                              <div>
                                <p>
                                  <b>
                                    Previous{" "}
                                    <span className="underline">night</span> meter
                                  reading:
                                </b>
                                </p>
                                <ReactCodeInput
                                  fields={5}
                                  value={JSON.parse(
                                    meterReadingStore.previousReading[0]
                                      .nightReadingValue
                                  ).toString()}
                                  disabled
                                />
                              </div>
                            ) : null}
                        </div>

                        {/* New day meter reading */}
                        <div>
                          <p>
                            <b>
                              New <span className="underline">day</span> meter
                              reading:
                            </b>
                          </p>
                          <ReactCodeInput
                            fields={5}
                            onChange={(meterReading) =>
                              setMeterReading(meterReading)
                            }
                          />
                        </div>

                        {/* New night meter reading */}
                        <div>
                          <p>
                            <b>
                              New <span className="underline">night</span> meter
                              reading:
                            </b>
                          </p>
                          <ReactCodeInput
                            fields={5}
                            onChange={(nightReadingValue) =>
                              setNightReadingValue(nightReadingValue)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* Nightsaver meter */}
                  {productRef.includes("E3R") ? (
                    <div>
                      <div>
                        {/* Previous Reading Date */}
                        <p>
                          <b>Previous electricity reading on: </b>
                          {meterReadingStore.previousReading &&
                            meterReadingStore.previousReading[0] != null ? (
                              <span className="underline">
                                {meterReadingStore.previousReading[0].dateSubmitted
                                  .split("T")[0]
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                              </span>
                            ) : null}
                        </p>
                        {/* Previous day reading */}
                        {meterReadingStore.previousReading &&
                          meterReadingStore.previousReading[0] != null ? (
                            <div>
                              <p>
                                <b>
                                  Previous{" "}
                                  <span className="underline">day</span> meter
                                  reading:
                                </b>
                              </p>
                              <ReactCodeInput
                                fields={5}
                                value={JSON.parse(
                                  meterReadingStore.previousReading[0].readingValue
                                ).toString()}
                                disabled
                              />
                            </div>
                          ) : null}

                        {/* Previous night reading */}
                        {meterReadingStore.previousReading &&
                          meterReadingStore.previousReading[0] != null ? (
                            <div>
                              <p>
                                <b>
                                  Previous{" "}
                                  <span className="underline">night</span> meter
                                  reading:
                                </b>
                              </p>
                              <ReactCodeInput
                                fields={5}
                                value={JSON.parse(
                                  meterReadingStore.previousReading[0]
                                    .nightReadingValue
                                ).toString()}
                                disabled
                              />
                            </div>
                          ) : null}
                      </div>

                      {/* New 24hr meter reading */}
                      <div>
                        <p>
                          <b>
                            New <span className="underline">day</span> meter
                              reading:
                            </b>
                        </p>
                        <ReactCodeInput
                          fields={5}
                          onChange={(meterReading) =>
                            setMeterReading(meterReading)
                          }
                        />
                      </div>

                      {/* New night meter reading */}
                      <div>
                        <p>
                          <b>
                            New <span className="underline">night</span> meter
                              reading:
                            </b>
                        </p>
                        <ReactCodeInput
                          fields={5}
                          onChange={(nightReadingValue) =>
                            setNightReadingValue(nightReadingValue)
                          }
                        />
                      </div>
                    </div>
                  ) : null}

                  {showNSHSection ? (
                    <div>
                      <p>
                        <b>
                          It looks like you have a heat meter:
                        </b>
                      </p>
                      {/* Previous NSH reading */}
                      {meterReadingStore.previousReading &&
                        meterReadingStore.previousReading[0] != null ? (
                          <div>
                            <p>
                              <b>
                                Previous{" "}
                                <span className="underline">heat</span> meter
                                  reading:
                                </b>
                            </p>
                            <ReactCodeInput
                              fields={5}
                              value={JSON.parse(
                                meterReadingStore.previousReading[0]
                                  .nshReadingValue
                              ).toString()}
                              disabled
                            />
                          </div>
                        ) : null}

                      {/* New NSH meter reading */}
                      <div>
                        <p>
                          <b>
                            New <span className="underline">heat</span> meter
                              reading:
                            </b>
                        </p>
                        <ReactCodeInput
                          fields={5}
                          onChange={(nshReadingValue) =>
                            setNshReadingValue(nshReadingValue)
                          }
                        />
                      </div>
                    </div>
                  ) : null}

                  {/* How to submit a reading? */}
                  <div>
                    <p>
                      How do I read my meter?
                        <MDBIcon
                        icon="info-circle"
                        className="ml-2"
                        onClick={() =>
                          setHowToTip(!howToTip)
                        }
                      />
                    </p>
                    {howToTip == true ? (
                      <div>
                        {/* Digital meter */}
                        <div className="faq-section">
                          <h4
                            style={{
                              marginTop: 20,
                              marginLeft: 15,
                            }}
                          >
                            Digital Meter
                            </h4>
                          <img
                            src={require("../../images/account/digitalMeter.png")}
                            style={{
                              width: "50%",
                              height: "50%",
                              marginTop: 10,
                              marginLeft: 15,
                            }}
                          />
                          <p>
                            <b>How to read a digital meter:</b>
                            <br /> Read from left to right ignoring any
                              numbers marked 1/10 or 1/1000 or any numbers in
                              red.
                            </p>
                        </div>

                        {/* Clock meter */}
                        <div className="faq-section">
                          <h4
                            style={{
                              marginTop: 20,
                              marginLeft: 15,
                            }}
                          >
                            Clock Meter
                            </h4>
                          <img
                            src={require("../../images/account/clockMeter.png")}
                            style={{
                              width: "50%",
                              height: "50%",
                              marginTop: 10,
                              marginLeft: 15,
                            }}
                          />
                          <p>
                            <b>How to read a clock meter:</b>
                            <br /> Read from left to right. Ignoring any
                              numbers marked 1/10 or 1/1000. Ignore any numbers
                              in red. If the clock hand is pointing between two
                              numbers, read the lower number.
                            </p>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {/* Submit Meter Reading Button */}
                  <MDBBtn
                    onClick={() => handleMeterReading()}
                    className="btn"
                  >
                    Submit Meter Reading
                    </MDBBtn>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
    </div>
  );
};