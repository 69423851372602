import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import LegalTabs from "../../../components/About/LegalTabs";
import MaxolIncentiveTsCs from "../../../documents/MaxolIncentiveTsCs.pdf";
import MeterReadPrizeDraw from "../../../documents/MeterReadPrizeDraw.pdf";
import IncentiveTsCs1 from "../../../documents/bonusScheme.pdf";
import IncentiveTsCs2 from "../../../documents/bonusScheme.pdf";
import IncentiveTsCs3 from "../../../documents/bonusScheme.pdf";



import $ from "jquery";

const LoadConfig = require("../../../config");
const config = LoadConfig.default;


class NITermsAndConditions extends Component {
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark /> */}

        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>Policies & Terms</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow>
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <LegalTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5 pb-5'>
            <h3>Terms & Conditions</h3>
            <div className="policy-links py-4">
              <a className="montserrat-Bold" href="#standard">Standard Ts&Cs</a>
              <a className="montserrat-Bold" href="#deemedcontract">Deemed Contract</a>
            </div>

            <hr />
            <div className='codes links'>

            <a href={MaxolIncentiveTsCs}>
                  <img src={require("../../../images/legals/pdf.png")}></img>
                Maxol Incentive Terms and Conditions
              </a>
              <br/>

              <a href={MeterReadPrizeDraw}>
                <img src={require("../../../images/legals/pdf.png")}></img>
                Meter Read Prize Terms & Conditions
              </a>
              <br/>

              <a href={IncentiveTsCs2}>
                <img src={require("../../../images/legals/pdf.png")}></img>
                Switching Bonus Scheme valid from 2nd-14th August 2021
              </a>
              <br/>

              <a href={IncentiveTsCs1}>
                <img src={require("../../../images/legals/pdf.png")}></img>
                Switching Bonus Scheme valid from 15th-18th August 2021
              </a>
              <br/>

                <a href={IncentiveTsCs3}>
                <img src={require("../../../images/legals/pdf.png")}></img>
                Switching Bonus Scheme valid from 19th August 2021
              </a>


              <br/>
            </div>
            <hr /><br />


            <div className='links'>
              {/* STANDARD Ts&Cs */}
              <hr id="standard" />
              <div id="standardtsandcs">
                <p className="montserrat-Bold">Standard Terms & Conditions</p>
                <p>
                  The following are the standard terms and conditions for the supply of electricity by Bright to any domestic customer (“Terms”).  These Terms are in addition to the prevailing Tariff and any terms and conditions specific to the service you have chosen (as may be amended from time to time).  These Terms also apply to domestic customers who are supplied electricity under a Deemed Contract.
                  <br /><br />
                  This is an important document so take time to read it and if you have any questions, or there is anything you are not clear about, please get in touch with our energy specialists on <a href='tel:028 9544 2290'>028 9544 2290</a> who will be pleased to help you.
                </p>
                <p className="montserrat-Bold">1. Definitions and interpretation</p>
                <p>1.1. The defined terms used in these Terms shall have the following meanings: </p>

                <ul className="list-style-none definitions">
                  <li><span className="montserrat-Bold">“Agreement”</span> means your application to us for supply of electricity (if any) which you have either signed or agreed on the telephone or online, together with these Terms and the relevant Tariff, as may be amended from time to time; </li>
                  <li><span className="montserrat-Bold">“Bright”, “Company”, “we”, “us”, “our”</span> means  Bright Energy Supply NI Limited with company Number NI655169 and registered address at 84-94 Great Patrick Street, Belfast, BT1 2LU, trading as Bright; </li>
                  <li><span className="montserrat-Bold">“Commencement Date”</span> means the date on which we will commence to supply electricity to the Supply Address; </li>
                  <li><span className="montserrat-Bold">“Connection Agreement” </span> means the agreement in place with the Network Operator in respect of the connection of the Supply Address to the local electricity distribution network; </li>
                  <li><span className="montserrat-Bold">“Consumer Council” </span> means the Consumer Council for Northern Ireland, a free and independent body with the power to settle customer disputes, whose registered office as at the date of these Terms is Seatem House, 28-32 Alfred Street, Belfast, BT2 8EN (contactable by calling <a href='tel:0800 121 6022'>0800 121 6022</a>, emailing <a href="mailto:info@consumercouncil.org.uk">info@consumercouncil.org.uk</a>, visiting <a href="https://www.consumercouncil.org.uk">www.consumercouncil.org.uk</a> or visiting its registered office);</li>
                  <li><span className="montserrat-Bold">“Customer”, “you”, “your” </span> means (i) any person who enters into the Agreement with Bright for the supply of electricity to the [Supply Address]; or (ii) any person who uses electricity supplied by us.  If you have registered as a customer jointly with other people, these expressions shall apply to each of you;</li>
                  <li><span className="montserrat-Bold">“Deemed Contract” </span> means a contract between Bright and a consumer for the supply of electricity deemed to have been made until paragraph 3 of Schedule 6 of the Order; </li>
                  <li><span className="montserrat-Bold">“Domestic Customer” </span> means a customer who uses electricity in their domestic property for domestic purposes.  Types of domestic property include (i) a flat or self-contained dwelling, (ii) a house, (iii) certain types of residential accommodation such as children’s home, hospice or care home, (iv) a caravan or houseboat, and (v) a self-catering caravan; </li>
                  <li><span className="montserrat-Bold">“Electricity Charges” </span> mean the amounts payable by you for the supply of electricity by us to the Supply Address, including all Pass Through Charges, together with any costs the supply of electricity by us;</li>
                  <li><span className="montserrat-Bold">“Equipment” </span> means the Meters, pipes, conduits, electrical plant, electrical lines and all other apparatus at the Supply Address to deliver, measure and control electricity at the Supply Point; </li>
                  <li><span className="montserrat-Bold">“Equipment Charges” </span> means charges that we are required to pay to the Meter Provider or incur ourselves where work is carried out on the Equipment and include, but are not limited to the following: (i) damage to the Equipment (including your Meter) if the damage is your fault, (ii) carrying out a Meter accuracy test if you ask for this and we do not find a fault with the Meter, (iii) warrants of entry that may have to be applied for, (iv) visits to the Address relating to inspecting or changing the Meter, and (v) any costs associated with changing the Meter; </li>
                  <li><span className="montserrat-Bold">“Force Majeure” </span> has the meaning given to it at clause 16.1;</li>
                  <li><span className="montserrat-Bold">“Meter” </span> means the electricity meter installed by the <span className="montserrat-Bold">Meter Provider</span> for the purpose of measuring the quantity of electricity used at the Supply Address and includes any such Meter or Meters of any type supplied to You at any time at the Supply Address; </li>
                  <li><span className="montserrat-Bold">“Meter Provider”  </span> means Northern Ireland Electricity Networks Limited (company number NI026041) or such other company as may be appointed to provide, install, maintain and administer the Equipment;</li>
                  <li><span className="montserrat-Bold">“Network Operator” </span> means the licensed network operator appointed to provide, install, maintain and administer the entire electricity infrastructure, transmission and distribution system in Northern Ireland, being Northern Ireland Electricity Limited as at the date of these Terms; </li>
                  <li><span className="montserrat-Bold">“Order” </span> means the Electricity (Northern Ireland) Order 1992;</li>
                  <li><span className="montserrat-Bold">“Pass Through Charges” </span> means any applicable third party costs, charges, tax, duty, levy, tariff or any government imposed charge, relating to the supply and distribution of electricity to the Supply Point including, but not limited to, transportation and use of system charges by the Network Operator, Equipment Charges and VAT;  </li>
                  <li><span className="montserrat-Bold">“Prices” </span> means the current prices for the supply of electricity, the current prices for any Pass Through Charges and the current prices for any other standing charges, as such prices may be amended from time to time in accordance with the Agreement; </li>
                  <li><span className="montserrat-Bold">“Supply Address” </span> means the address(es) at which your Supply Point is located; </li>
                  <li><span className="montserrat-Bold">“Supply Point” </span> means the point(s) at the Supply Address at which the flow of electricity supplied by us is metered; </li>
                  <li><span className="montserrat-Bold">“Utility Regulator” </span> means the Northern Ireland Authority for Utility Regulation, its successors and assigns; and </li>
                  <li><span className="montserrat-Bold">“Website” </span> means <a href='https://www.brightenergy.com'>www.brightenergy.com</a></li>
                </ul>

                <p>1.2. The headings used in these Terms are for convenience only and will not affect the interpretation of the Terms.</p>
                <p>1.3. Reference to a person includes that party’s personal representatives, successors and permitted assigns (where applicable).</p>
                <p>1.4. A reference to a statute or statutory provision is a reference to such statute or statutory provision as amended or re-enacted and includes any subordinate legislation made under that statute or statutory provision, as amended or re-enacted from time to time. </p>
                <p>1.5. Any phrase introduced by the words “including”, “include”, “in particular”, or any similar expression shall be construed as illustrative and shall not limit the sense of the words preceding those terms. </p>
                <p>1.6. Bright shall not be required to keep or preserve the original documentation pertaining to or arising out of any agreement (including the Agreement itself) but may keep copies of the same by using any electronic method of storing information and a legible copy or a recorded electronic audio file (as appropriate) of any such information so preserved shall be considered a true copy of it. </p>
                <p>1.7. A reference to writing shall include email. </p>
                <p className="montserrat-Bold">2. Agreement to Supply</p>

                <p>2.1. We will supply you with electricity and provide (or procure that a third party provides) any services associated with the supply of electricity, to the Supply Point at the Supply Address subject to the terms and conditions of the Agreement. </p>
                <p>2.2. Our obligation to supply electricity to you is conditional upon the following: </p>
                <ul className="list-style-none definitions">
                  <li>2.2.1. Bright having the requisite authority to supply electricity to the Supply Address; </li>
                  <li>2.2.2. Bright agreeing to accept your request for supply whether such supply is (i) set out in an application form or written contract or by telephone or online application; or (ii) evidenced by your use of electricity supplied by us at the Supply Address;  </li>
                  <li>2.2.3. the transfer of your supply to us being successfully completed; and </li>
                  <li>2.2.4. all necessary Equipment being installed or available at the Supply Address. </li>
                </ul>

                <p>2.3. Save where you are being supplied electricity by way of Deemed Contract, you agree that on entering into the Agreement we have provided you with and made you aware of; </p>
                <ul className="list-style-none definitions">
                  <li>2.3.1. the Commencement Date </li>
                  <li>2.3.2. the principal terms of the Agreement; </li>
                  <li>2.3.3. the Energy Consumer Checklist and where to get a copy of this checklist free of charge; and </li>
                  <li>2.3.4. the contents of these Terms, including by providing a written copy of these Terms, in each case by provision of the Welcome Pack. </li>
                </ul>

                <p>2.4. At the time of entering into the Agreement you warrant that you are the owner or occupier of the property at the Supply Address and have the power and authority to enter into the Agreement and, where applicable, grant permission to Bright to terminate any electricity supply that you have with another electricity supplier. </p>
                <p>2.5. Ownership, risk and title of the electricity passes to you at the Supply Point.</p>
                <p>2.6. Save as otherwise provided by law, the electricity supplied by us may not be resold by you in any form and you shall not use the electricity for any improper purpose. </p>
                <p>2.7. The Agreement will start on the date that you choose Bright to be your electricity supplier, or the date you start to take a supply with us, whichever is first. </p>
                <p>2.8. Where a supply of electricity has at any time previously been taken at the premises, a cooling off period will apply for 10 Working Days from the day you entered into the agreement.  You can let us know that you wish to cancel the Agreement at any stage during this period by contacting us on <a href='tel:028 9544 2290'>028 9544 2290.</a> </p>
                <p>2.9. Where a supply of electricity has not previously been taken at the premises, a cooling off period will apply for 10 Working Days from the day you entered into the agreement or any time up to 10 Working Days prior to the premises being connected to a distribution system, whichever is the later.  You can let us know that you wish to cancel the Agreement at any stage during this period by contacting us on <a href='tel:028 9544 2290'>028 9544 2290.</a> </p>
                <p>2.10. If you have already started to use electricity and choose to cancel during the cooling off period, you may be liable for all Electricity Charges and other charges incurred under the Agreement during this time.</p>
                <p>2.11. We will aim to start supplying electricity to you within 10 working days and no later than 15 working days after the cooling off period, unless:</p>

                <ul className="list-style-none definitions">
                  <li>2.11.1. there are circumstances beyond our control which prevent us from starting to supply electricity to the Supply Address by that date;  </li>
                  <li>2.11.2. you request that supply starts from a later date;   </li>
                  <li>2.11.3 a third-party supplier that is registered to the Supply Address objects to the transfer of the Supply Address to Bright; or </li>
                  <li>2.11.4. the Supply Address is not connected to the electricity distribution system. </li>
                </ul>
                <p className="montserrat-Bold">3. Change of Supplier</p>
                <p>3.1. Where we take over the supply of electricity to the Supply Address from another electricity supplier, you shall (i) allow us to request information about your previous electricity supply and disclose this information to relevant parties in order to fulfil our responsibilities; and (ii) provide us with an electricity Meter reading for the Commencement Date so that we can obtain an actual Meter reading at the Supply Address.  </p>
                <p>3.2. We may share the relevant information we have about you with relevant industry organisations based on agreed industry processes. In order to transfer your electricity supply, we may need to share information with and obtain information from your current supplier to establish details of any outstanding debt or Meter tampering at the Supply Address. If you attempt to leave our supply of electricity, you allow us to provide information about your account with us and disclose all information to relevant parties which may include the electricity suppliers, debt collection agencies and any other relevant third party agencies. </p>
                <p className="montserrat-Bold">4. Pricing and Payment</p>
                <p>4.1. You agree to take and pay for the supply of electricity at the Supply Address in accordance with the Prices until termination of the Agreement or until you stop using services provided by us, whichever is later.  You shall also pay any debt accrued which remains outstanding on termination of the Agreement. </p>
                <p>4.2. When you switch to Bright we will provide you with a Welcome Pack which will include our details, the name of your tariff, the unit rate and charges applicable to your tariff, the payment method selected, and other payment options available to you. </p>
                <p>4.3. The Electricity Charges payable by you will be based on: </p>
                <ul className="list-style-none definitions">
                  <li>4.3.1. the electricity you have used in kilowatt hours (kWh), obtained through a Meter read either provided by the Meter Provider, or by you, or an estimated read (estimated using industry standard methods); </li>
                  <li>4.3.2. the unit price that applied to your account for the period in which you used the electricity;  </li>
                  <li>4.3.3. any standing charge applicable to the property for the relevant period of the bill; and </li>
                  <li>4.3.4. any Pass Through Charges; and </li>
                  <li>4.3.5. any other costs associated with the supply of electricity by us. </li>
                </ul>
                <p>4.4. We will apply VAT at the appropriate rate to all Electricity Charges. </p>
                <p>4.5. Our latest tariff information is available on our website at brightenergy.com.  Our tariff information is expressed in pence per kWh. This information will be displayed on your latest bill If you would like a copy of our tariffs contact us on 028 9544 2290. </p>
                <p>4.6. We will send you regular bills or statements which will set out the Electricity Charges and the amount payable by you, which must be paid within 14 days of the issue date set out on your bill or statement without set-off or deduction.  Part payment will not release you from your obligation to pay the full amount due to us.  You should allow 3 Working Days for your payment to be received. Any amount paid by you will be applied in the order in which they become due and we will credit or debit your account with the balance (if any) in your next bill or statement. </p>
                <p>4.7. If you have a prepayment Meter, you must top up your Meter in advance in order to receive the supply.  We will deduct the charges for electricity from the amount you top up.  We will send you a statement at least once a year showing your top ups against the amount of electricity used and the total charges for that electricity. </p>
                <p>4.8. You must allow the Network Operator, Bright, or a third-party service provider acting on our behalf, access to the Meter (including prepayment Meters) at all reasonable times to enable the Meter to be read. </p>
                <p>4.9. You agree to provide accurate information regarding the premises, and all details we request to ensure we can provide you with the most suitable tariff. </p>
                <p>4.10. You can pay for your electricity either by; </p>
                <ul className="list-style-none definitions">
                  <li>4.10.1. Direct Debit – which means paying a regular amount for your supply in advance each month, where we set the amount of your Direct Debit based on estimated annual usage; </li>
                  <li>4.10.2. Direct debit in arrears – once we issue your monthly bill we will take the direct debit for the full amount from your account within 14 days of the bill being issued; </li>
                  <li>4.10.3. Prepayment Meter – where you can top up your energy as you go; </li>
                  <li>4.10.4. Standard credit – paying in arrears by debit or credit card by phone on 028 9544 2290 or through the Website www.brightenergy.com. </li>
                </ul>
                <p>4.11. Paying by direct debit means; </p>
                <ul className="list-style-none definitions">
                  <li>4.11.1. we will abide by the Direct Debit guarantee; </li>
                  <li>4.11.2. we will inform you of your payment amounts and payment dates when you set up your account with us; </li>
                  <li>4.11.3. if you think your bill is incorrect you should contact us before we take your direct debit;  </li>
                  <li>4.11.4. you must provide us with up to date Meter readings at least every 30 days; </li>
                  <li>4.11.5. we will review your Direct Debit at least twice a year, to make sure you are paying enough to cover your energy usage or to offer to reduce your payment if we think you are paying too much; </li>
                  <li>4.11.6. We will review your Direct Debit within 30 days of the Commencement Date and then at least twice a year after that; </li>
                  <li>4.11.7. Your first direct debit is due when your switch to Bright is confirmed with additional payments made per month as per your agreement with us. </li>
                </ul>
                <p>4.12. Your bill is due on the date shown and must be paid in full by that date unless you have arranged a regular amount direct debit or payment plan with us. </p>
                <p>4.13. If you are having difficulty paying your bill you should contact us straight away, so that we can arrange a suitable payment plan and discuss your payment options.  Full details of our commitments to those having difficulty paying their bill can be found in our code of practice on payment of bills on our Website. </p>
                <p>4.14. If for any reason the Meter does not register electricity usage accurately, you agree to pay an amount that reasonably reflects the amount of electricity you have used. </p>
                <p>4.15. You agree to pay our other charges relating to your supply, including: </p>
                <ul className="list-style-none definitions">
                  <li>4.15.1. reasonable charges for providing copy bills where requested by you; </li>
                  <li>4.15.2. payment for work to be carried out at your Meter as a result of you tampering or interfering with the Meter; or  </li>
                  <li>4.15.3. other reasonable costs that arise if we must act because you break the terms of this agreement. </li>
                </ul>
                <p>4.16. The Meter Provider is responsible for reading your Meter at the Supply Address on our behalf.  Meter readings will normally be assumed to be correct.  However, where: </p>
                <ul className="list-style-none definitions">
                  <li>4.16.1. we believe that the Meter reading may be inaccurate; </li>
                  <li>4.16.2. Meter readings are not available; or </li>
                  <li>4.16.3. we have not been able to gain access to your Meter, </li>
                  <li>we will make a reasonable estimate of your Meter reading and include this in your bill or statement.  Where you submit a Meter reading your next bill or statement will be adjusted accordingly.</li>
                </ul>
                <p>4.17. The Meter Provider will attempt to seek access to the Supply Address at least once a year to take an actual Meter reading. </p>
                <p>4.18. Where a Meter reading has been inaccurate or omitted or the Electricity Charges have been calculated incorrectly, we shall debit or credit your account (as appropriate) and reflect this in your next bill or statement. </p>
                <p>4.19. If any payments are late, we reserve the right to charge you any relevant costs in relation to the late payment.  See clause 6. </p>
                <p>4.20. If you have an account with us at any other premises, we may transfer any credit or debt between your accounts to receive any amount owed by you to us. </p>
                <p>4.21. If any amount payable by you is in dispute, you must pay to us the amount that is not in dispute. When the matter has been settled, we will adjust the amount owed by you as a result. </p>
                <p>4.22. For advice on reducing the amount of electricity you use we have a code of practice on the efficient use of electricity, our website also has helpful tips and advice. </p>
                <p>4.23. Organisations such as the Consumer Council www.consumercouncil.org.uk and NI Direct (www.nidirect.gov.uk/campaigns/energy-wise) offer free comprehensive advice and support on how to save energy in the home and on assistance available.   </p>
                <p>4.24. The NI Energy Advice service offers free comprehensive advice and support on how to save energy in the home and on assistance available. You can call 0800 111 44 55 during office hours or use the callback service for out of hours. You can also visit the NI Housing Executive website at www.nihe.gov.uk under Community. This offers advice and a contact form for energy efficiency advice and signposting for grants.  </p>
                <p className="montserrat-Bold">5. Security Deposit</p>
                <p>5.1. In line with our licence, we may request a security deposit of £125 from you prior to supplying electricity to the Supply Address as security against payment for your electricity. </p>
                <p>5.2. During the term of the Agreement, we may ask you to pay a security deposit against payment for your electricity in the following circumstances; </p>
                <ul className="list-style-none definitions">
                  <li>5.2.1. you do not pay for your electricity in advance by Direct Debit;</li>
                  <li>5.2.2. you pay us late;  </li>
                  <li>5.2.3. you fail to adhere to payment arrangements agreed with us; or </li>
                  <li>5.2.4. you fail to adhere to any of the terms of the Agreement. </li>
                </ul>
                <p>5.3. You may be able to avoid paying a security deposit to us by paying for your electricity by monthly Direct Debit or having a prepayment Meter installed, so long as it is reasonably safe and practicable for a prepayment Meter to be installed at your property. </p>
                <p>5.4. We may refuse to supply electricity to you until the security deposit has been paid to us in full. </p>
                <p>5.5. Save as otherwise provided at clause 5.8, we will repay any security deposit where, for a period of 12 months, you have paid all charges for electricity requested by us in full and on time, or as otherwise specified in our licence.  This repayment will be made within 28 days of the date on which you become eligible for the security deposit to be repaid.   </p>
                <p>5.6. If you end the Agreement and have paid all outstanding amounts due to us, we will repay any security deposit as soon as reasonably practicable, and in any event, within one month, provided that you have provided us with the address to which cheques should be sent or details of the bank account to which payment should be made. </p>
                <p>5.7. If you are ending the Agreement and you owe us money, you agree that we may keep any deposit you have paid and use this to reduce any debt you owe us. The amount we keep will not be more than the amount that you owe to us. Any money received from you as a deposit and not used to reduce any debt you owe us will be returned within one month of you ending the Agreement.   </p>
                <p>5.8. Bright shall not be obliged to repay any security deposit where it is reasonable in all the circumstances for us to retain that deposit.  </p>
                <p className="montserrat-Bold">6. Late Payments</p>
                <p>6.1. If you do not pay your bill on or prior to the date stated on the bill we may: </p>
                <ul className="list-style-none definitions">
                  <li>6.1.1. add the amount of your debt to your next bill; </li>
                  <li>6.1.2. charge you interest (using the rate that we apply at the time); </li>
                  <li>6.1.3. take steps to recover the money you owe us, which may include by appointing third party debt collection agents; and </li>
                  <li>6.1.4. charge you reasonable costs of trying to recover any overdue payment, which may include (but shall not be limited to) costs related to visiting the Supply Address, obtaining and exercising a warrant, tracing costs. </li>
                </ul>
                <p>6.2. If your account is in debt and you do not repay us, or if you do not comply with the conditions for your chosen payment method we can, where safe and practicable to do so, install a prepayment Meter at the Supply Address.  If required this Meter can also be used to collect any debt owed to us, at a maximum rate of 40% per top up. </p>
                <p>6.3. We will not recover or seek to recover any charges for the supply of electricity to the Supply Address more than 13 months after the date on which those charges were incurred, except where: </p>
                <ul className="list-style-none definitions">
                  <li>6.3.1. we have already requested payment for those units within the 13 months either in a bill or other demand for payment; or </li>
                  <li>6.3.2. we have taken other steps to recover the charges, the effect of which will be reflected in the next statement we send to you after those steps were taken. </li>
                </ul>
                <p>6.4. we have taken other steps to recover the charges, the effect of which will be reflected in the next statement we send to you after those steps were taken. </p>
                <ul className="list-style-none definitions">
                  <li>6.4.1. we have been unable to obtain an actual Meter reading in spite of having taken all reasonable steps to do so; or </li>
                  <li>6.4.2. you, or any other person in occupation of the Supply Address has unlawfully taken a supply of electricity or interfered with the Meter or the Equipment. </li>
                </ul>
                <p className="montserrat-Bold">7. Prepayment Meter Customers</p>
                <p>7.1. You may request a prepayment Meter, and we will arrange for one to be installed provided it is safe and practical to do so. </p>
                <p>7.2. Installation of a prepayment Meter is subject to a site survey by the Meter Provider to ensure your home wiring is suitable. </p>
                <p>7.3. In using a prepayment Meter, you accept that you will top up the Meter in order to receive electricity.  If you fail to do so, this could cause inconvenience, loss, damage and injury to yourself, other occupiers and visitors to the premises for which you agree that we shall not be responsible. </p>
                <p>7.4. We may install a prepayment Meter, with or without your consent, where: </p>
                <ul className="list-style-none definitions">
                  <li>7.4.1. you fail to pay, or are late in paying, any amount due and owing to us; </li>
                  <li>7.4.2. we reasonably suspect that the Meter at the Supply Address has been tampered with; or </li>
                  <li>7.4.3. you breach any of the terms of the Agreement. </li>
                </ul>
                <p>7.5. If using a prepayment Meter, it is your responsibility to look after the plastic card or other device provided to you to make payment, keeping it clean, safe and free from damage.  You may be charged for the replacement of any Meter card if lost, stolen or damaged.   </p>
                <p>7.6. Minimum top up on your prepayment Meter is £5 through our app or Website and £10 via Post Office, Payzone or by telephone.   </p>
                <p>7.7. Emergency credit of £1 is available on prepayment Meters to allow you time to top up.   </p>
                <p>7.8. Friendly credit applies to our prepayment Meter plans.  If credit runs out on the Meter after 4pm the electricity will not be cut off until 11am the following Working Day. </p>
                <p>7.9. Any emergency credit or friendly credit used will be automatically deducted from your next top up. </p>
                <p>7.10. Any credit contained on a lost, damaged or stolen Meter card is like losing cash, it will not be refunded and we accept no liability for this. </p>
                <p>7.11. Should you wish to reclaim any credit on a prepayment Meter, a meter exchange or meter reset will be required to process a refund.  A cost will be incurred for any Meter exchange or Meter reset and it will be deducted from the credit remaining on the Meter. </p>
                <p>7.12. A Code of Practice, setting out the services we offer to prepayment Meter customers, is available on our Website or on request (see clause 14). </p>
                <p className="montserrat-Bold">8. Moving House</p>
                <p>8.1. If you are moving house, you should let us know at least two working days before you move and provide us with an accurate Meter reading.  We will estimate your final read if you do not provide one.  If you are moving, the Agreement will end on the first of the following events:</p>
                <ul className="list-style-none definitions">
                  <li>8.1.1. on the date we agree; or </li>
                  <li>8.1.2. when someone takes over the supply of electricity to the Supply Address. </li>
                </ul>
                <p>8.2. You are responsible for all Electricity Charges and other charges incurred in accordance with the Agreement until the date on which the Agreement ends. </p>
                <p>8.3. If you would like us to continue to supply electricity at your new premises, please contact us.  You will need to tell us your new address and the date you wish the supply to commence. </p>
                <p className="montserrat-Bold">9. Variations to the Agreement</p>
                <p>9.1. From time to time we may change the terms and conditions of the Agreement by providing you with at least 21 days’ written notice (which shall include placing the updated Terms on our Website).  Where the price of your electricity changes, we will provide you the new tariff, setting out the new and existing unit rate for comparison. </p>
                <p>9.2. Where the change to the Agreement is material, you may end the Agreement as described in clause 10 if you do not accept any variation to the Agreement. </p>
                <p>9.3. Where you do not notify us within 21 days of your intention to terminate the Agreement, the change contained in the amended Terms will be applied to the Agreement without further notice. </p>
                <p className="montserrat-Bold">10. Ending the Agreement</p>
                <p>10.1. The Agreement will continue unless terminated in accordance with this clause 10. </p>
                <p>10.2.  You may terminate the Agreement:  </p>
                <ul className="list-style-none definitions">
                  <li>10.2.1. where there has previously been a supply of electricity at the Supply Address, within 10 Working Days of entering into the Agreement; or </li>
                  <li>10.2.2. where the Supply Address has not previously been connected to the electricity distribution network, within 10 Working Days of entering into the Agreement, or at any time up to 10 Working Days prior to the Supply Address being connected to the electricity distribution network, whichever date is later. </li>
                </ul>
                <p>10.3. The supply of electricity to the Supply Address may be discontinued by you on the day requested by you, provided that: </p>
                <ul className="list-style-none definitions">
                  <li>10.3.1. on the day of termination, either another supplier has already commenced supplying electricity to the Supply Address or the Supply Point has been disconnected; and </li>
                  <li>10.3.2. we agree (if permitted) that no amounts remain due and owing from you in connection with the Agreement. </li>
                </ul>
                <p>10.4. You may terminate the Agreement on the date you no longer own or occupy the Supply Address, provided that you have provided us with not less than 2 working days’ notice together with a Meter reading as at the date of termination. </p>
                <p>10.5. If you fail to provide a Meter reading in accordance with clause 10.4,  the Agreement will terminate on the earlier of: </p>
                <ul className="list-style-none definitions">
                  <li>10.5.1. the second working day after you have provided an appropriate Meter reading; </li>
                  <li>10.5.2. the date that the Meter at the Supply Address is next read; or </li>
                  <li>10.5.3. the date on which electricity is supplied to the Supply Address by another supplier. </li>
                </ul>
                <p>10.6. You may terminate the Agreement within 21 days where we have proposed a variation to the Agreement and you do not wish to accept the variation.  However, if you continue to use electricity supplied by us following termination, the Agreement (as amended) shall continue to apply. </p>
                <p>10.7. If you switch to another supplier, the Agreement will terminate only when the new supplier starts to supply electricity to the Supply Address. You must still pay Bright for any electricity used before and until the date of the new supplier starting to supply you, along with any debt accrued.  If you owe us money, we may let your new supplier know, and agree to transfer the debt from us to your new supplier. They will then collect the debt from you, and we will give them full details of the amount you owe. </p>
                <p>10.8. If you wish to end your supply by having it disconnected, you should contact us, and we will arrange for your supply to be disconnected. You must pay for all electricity consumed until that disconnection takes place. </p>
                <p>10.9. In addition to any other rights we may end the Agreement immediately and additionally may request that the Network Operator withdraws your electricity supply if: </p>
                <ul className="list-style-none definitions">
                  <li>10.9.1. you do not pay any security deposit or all or any part of our changes (that are not genuinely in dispute) when due under these Terms, or it is not safe or reasonably practicable to install a prepayment Meter to collect the debt and future charges; </li>
                  <li>10.9.2. you repeatedly breach or commit a serious breach of the Agreement (for example, if we reasonably believe you have stolen electricity or interfered with a Meter or any Equipment or part thereof); </li>
                  <li>10.9.3. you are declared bankrupt, or any formal steps are taken to have you declared bankrupt, or any other form of insolvency proceeding is initiated against you; </li>
                  <li>10.9.4. you no longer own, rent or use the premises; or </li>
                  <li>10.9.5. there is a risk of danger to you or other members of the public if we continue the supply of electricity to the Supply Address.</li>
                  <li>When this Agreement terminates, you are still liable to pay any amounts you owe us.  </li>
                </ul>
                <p>10.10. In order to supply electricity, we require an electricity supply licence. If our licence is removed for any reason either you or we may end the Agreement.  In that event the Utility Regulator may issue a Last Resort Supply Direction to another supplier to supply you with electricity, and the Agreement will end automatically. </p>
                <p className="montserrat-Bold">11. Your Electricity Meter</p>
                <p>11.1. Your Meter is owned and serviced by the Meter Provider.   The Meter Provider is also responsible for reading your Meter.  Bright are not responsible for any damage caused by third parties, including the Meter Provider and its representatives who may attend at your premises in connection with the meter.  In the event of such damage you should contact the Meter Provider. </p>
                <p>11.2. The Meter Provider must have easy access to read your Meter. If the Meter is difficult to access or is not in a safe, secure and weatherproof position you may have to arrange with the Meter Provider to move the Meter to a suitable location. </p>
                <p>11.3. We presume that the Meter and any relevant Equipment at the Supply Address is accurate.  If you consider that there is a problem with the accuracy of the Meter you should contact Bright or the Meter Provider.  In such circumstances the Meter will be examined as soon as practicable.  </p>
                <p>11.4. Where you believe that there has been damage to or interference with the Equipment you must contact the Meter Provider and Bright without delay. You agree to provide us with all the information which we may reasonably require.  </p>
                <p>11.5. In the event of an emergency you must allow the Meter Provider safe access to the Meter. You must also provide access to the Meter (including prepayment Meter) at all reasonable times to enable the Meter Provider to: </p>
                <ul className="list-style-none definitions">
                  <li>11.5.1. install, repair, remove or replace the Meter; </li>
                  <li>11.5.2. read, test or inspect the Meter; and </li>
                  <li>11.5.3. disconnect or reconnect the electricity supply to the Supply Address. </li>
                </ul>
                <p>11.6. We reserve the right to pass on to you those charges that we are required to pay to the Meter Provider or incur ourselves where work is carried out at the Meter, including but not limited to charges relating to: </p>
                <ul className="list-style-none definitions">
                  <li>11.6.1. damage to a Meter or the Equipment; </li>
                  <li>11.6.2. carrying out a Meter accuracy test if you ask for this and we do not find fault with the Meter; </li>
                  <li>11.6.3. any steps taken in respect of warrant of entry to gain access to the Supply Address; </li>
                  <li>11.6.4. any visit to the Supply Address relating to inspecting or changing your Meter or any Equipment; and </li>
                  <li>11.6.5. any applicable fieldwork charges where you miss or cancel an appointment arranged with the Meter Provider. </li>
                </ul>
                <p className="montserrat-Bold">12. Disconnection of Electricity Supply</p>
                <p>12.1. If you have difficulty paying your bill we have a code of practice (see clause 14) which sets out our minimum standards on the services we will offer you.  We will aim to avoid disconnecting the Supply Address and will work with you to arrange a payment plan.  Nonetheless, we reserve the right to disconnect your supply in the following circumstances: </p>
                <ul className="list-style-none definitions">
                  <li>12.1.1. you have breached the terms of the Agreement; </li>
                  <li>12.1.2. you refuse or fail to provide an appropriate security deposit when we ask you to or do not allow us to install a prepayment Meter, or a prepayment Meter cannot be installed in a safe or practicable manner; </li>
                  <li>12.1.3. it is not reasonable, in all the circumstances, for us to supply you; or  </li>
                  <li>12.1.4. you refuse or fail to provide us with all the details that we require to register you for a supply of electricity at the Supply Address. </li>
                </ul>
                <p>12.2. You agree to pay any reasonable costs we ask for if we disconnect the supply of electricity to the Supply Address for any of these reasons. Costs may include the cost of any visit to the Supply Address and charges which we incur from the Network Operator. Once the matter has been resolved and provided it is reasonable for us to supply electricity to the Supply Address, we will reconnect your supply. You must pay us any reasonable costs for the reconnection. </p>
                <p>12.3. We have the right to cut off your electricity supply in an emergency or if we are allowed to do so under any relevant legislation or by the Utility Regulator or any other regulatory body prohibiting or restricting the supply of electricity to any property or person, we are entitled to discontinue the supply of electricity to the Supply Address. </p>
                <p>12.4. Your supply of electricity to the Supply Address may also be discontinued by the Network Operator from time to time, for example, to carry out repairs or maintenance to the electricity network.  </p>
                <p className="montserrat-Bold">13. Interference with Electrical Equipment, Energy Theft and Fraud Protection</p>
                <p>13.1. Energy theft is tampering with a Meter or its connections so that the Meter does not accurately record the amount of electricity consumed.  Such theft is dangerous and illegal.  The theft of electricity in any form is a crime, which can be prosecuted and may lead to a criminal record.</p>
                <p>13.2. You shall and ensure that each person at the Supply Address shall, at all times use electricity in a safe, proper and sensible way in accordance with all relevant health and safety legislation. </p>
                <p>13.3. You must not, and ensure that no other person does, in any way interfere with, misuse, damage or neglect the Equipment or the Meter cabinet.  You shall be responsible for any theft or damage to the Equipment (save in respect of natural wear and tear) and maybe liable for criminal or civil proceedings in addition to the costs incurred by us or the Network Operator for repairing or replacing the Equipment or the Meter cabinet (as applicable). </p>
                <p>13.4. If there is any damage, a fault or a problem with the Equipment or the Meter, or if you think the Equipment or the Meter has been tampered with, you must inform us or the Network Operator immediately. </p>
                <p>13.5. Where we suspect fraud or theft of electricity Bright will take all reasonable steps to investigate and prevent the theft and recover any charges due from you. </p>
                <p>13.6. In addition to the charges set out elsewhere in the Agreement, you agree to pay the following in relation to the electricity supply at the Supply Address: </p>
                <ul className="list-style-none definitions">
                  <li>13.6.1. any additional charges relating to the maintenance of the Meter; </li>
                  <li>13.6.2. all costs in relation to the theft, loss, damage or other interference to the Equipment including, but not limited to, the costs of repairing or replacing the Meter and the cost of all electricity obtained from us without charge as a result, all internal and external costs associated with the theft, loss, damage or other interference (whether by you or another person), any costs incurred in respect of a new Meter or other Equipment together with all installation and connection costs; and </li>
                  <li>13.6.3. a minimum charge, where applicable, if the Equipment has been installed but you have used less electricity at the Supply Address than the minimum usage set out in the relevant tariff. </li>
                </ul>
                <p>13.7. The Network Operator will investigate any reported or suspected theft or interference with or damage to any Network Operator equipment and, where appropriate, will cooperate with Bright and the PSNI (Police Service of Northern Ireland) in relation to such investigation.  </p>
                <p>13.8. If there is any damage, a fault or a problem with the Meter or you suspect that a Meter has been tampered with you should report it immediately to us and the Network Operator. </p>
                <p className="montserrat-Bold">14. Standards of Service and Complaints</p>
                <p>14.1. In line with our licence to supply electricity, Bright has in place Codes of Practice which set out our commitments to you in respect of service levels and provide guidance as to the services we offer.  All of our Codes of Practice have been approved by the Utility Regulator. As at the date of these Terms, the Codes of Practice cover the following areas: </p>
                <ul className="list-style-none definitions">
                  <li>14.1.1. Complaint handling procedures; </li>
                  <li>14.1.2. Customers having difficulty paying bills; </li>
                  <li>14.1.3. Energy Efficiency; </li>
                  <li>14.1.4. Prepayment Meter customers; and </li>
                  <li>14.1.5. Provision of services for persons who are of pensionable age, disabled or chronically sick. </li>
                </ul>
                <p>14.2. If you are unhappy with our service you can contact us to make a complaint.  We have a code of practice which sets out how we will deal with your complaint, and how you can take your complaint further if you are not happy with the outcome. </p>
                <p>14.3. Our complaints process is free of charge.  You can make a complaint by contacting us on 0808 164 4790, email complaints@brightenergy.com or write to us at Bright, 84-94 Great Patrick Street, Belfast BT1 2LU. </p>
                <p>14.4. If we fail to meet the timelines set out in our code of practice on complaint handling we will pay you £25 as a credit in your account.  This amount will not be paid where the circumstances of the delay were outside of our control, such as reliance on a third party, disruption to the network or failure to gain access to your property where required. If you fail to engage with us or we consider your complaint to be vexatious we will not make a payment   Any payments are made without any admission of legal liability on the part of Bright. If there is any inconsistency or conflict between the Bright terms and conditions of supply and the code of practice, the terms and conditions of supply shall prevail. The terms and conditions of supply can be found on our website at www.brightenergy.com </p>
                <p>14.5. If, having followed our complaint handling procedures, we are unable to resolve your complaint to your satisfaction you can contact the Consumer Council for further help and advice. You can contact the Consumer Council at Floor 3, Seatem House, 28-32 Alfred Street, Belfast BT2 8EN or by calling 0800 121 6022. </p>
                <p>14.6. Notwithstanding anything else in this clause 14, nothing shall prevent you or us from referring any complaint to the courts of Northern Ireland. </p>
                <p className="montserrat-Bold">15. Legal Liability and Insurance</p>
                <p>15.1. Nothing in these terms will exclude or limit our liability to each other for causing the death or injury of someone as a result of negligence. Also, nothing in these Terms will limit your legal rights as a consumer. </p>
                <p>15.2. We will only be liable to you for direct losses which are reasonably foreseeable resulting from us breaching the terms of the Agreement.  You will only be liable to us for direct losses resulting from you breaching the terms of the Agreement.  The standard of care governing our respective legal rights and obligation shall be solely determined by the terms of the Agreement. </p>
                <p>15.3. Subject to clause 15.1. neither of us will be liable to the other whatsoever and howsoever arising whether in contract, tort (including negligence) or otherwise for any direct, indirect or consequential or economic loss arising from: </p>
                <ul className="list-style-none definitions">
                  <li>15.3.1. breach of the Agreement; </li>
                  <li>15.3.2. any failure of the transportation services provided by the Network Operator; or </li>
                  <li>15.3.3. any interruption or variation in the supply of electricity to the Supply Address arising as a result of works being carried out at the Supply Address, maintenance or in the event of an emergency. </li>
                  <li>However, this does not affect our right to charge you as described in the Agreement. </li>
                </ul>
                <p>15.4. Neither party shall be liable to the other for any indirect, consequential or unforeseeable loss, nor for business losses including any loss of profit, loss of goodwill or loss of opportunity. However, this does not affect our right to charge you as described in the Agreement. </p>
                <p>15.5. We will not have liability whatsoever arising in respect of, or in connection with, any failure of the services provided by, or any acts or omissions of the Network Operator.  </p>
                <p>15.6. Our liability to you shall be limited to twice your annual electricity charges payable to us in the preceding 12 month period, or if you have not been supplied by us for 12 months an estimate of such charges.  </p>
                <p>15.7. Subject to clause 15.1, all warranties, conditions and other terms implied by statute or common law are, to the fullest extent permitted by law, excluded from the Agreement. </p>
                <p className="montserrat-Bold">16. Circumstances outside our control</p>
                <p>16.1. Neither Party shall be liable to the other for failure to fulfil any of the obligations contained in the Agreement because of any occurrence or circumstance outside the reasonable control of that party which has resulted in the failure to comply with the Agreement (“Force Majeure”), provided that: </p>
                <ul className="list-style-none definitions">
                  <li>16.1.1. the Party seeking relief has notified the other party as soon as reasonably practicable of the existence of the Force Majeure and its estimate of the likely effect of the Force Majeure on its ability to perform its obligations and of the timeframe of such Force Majeure; and </li>
                  <li>16.1.2. the Party affected shall use all reasonable endeavours to terminate the circumstance of Force Majeure if and to the extent reasonably practicable and with all reasonable speed and at a reasonable cost, provided that nothing shall limit the discretion of Bright in relation to the settlement of any labour dispute constituting circumstances of Force Majeure. </li>
                </ul>
                <p>16.2. The parties acknowledge that Force Majeure shall not release any Party from any obligation to pay any amount arising under the Agreement. </p>
                <p>16.3. The Network Operator is responsible for the quality of the supply of electricity to you and its continuity. The quality and continuity of your supply are outside our reasonable control.  </p>
                <p className="montserrat-Bold">17. General</p>
                <p>17.1. The Agreement is governed by the laws of Northern Ireland. Each Party agrees that the courts of Northern Ireland shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with the Agreement or its subject matter or formation (including non-contractual disputes or claims). </p>
                <p>17.2. If any clause or part clause of the Agreement is found to be, or becomes invalid, illegal or unenforceable the validity and enforceability of the Agreement shall not be affected. </p>
                <p>17.3. Our conduct as a supplier of electricity is governed by our electricity supply licence and applicable legislation and regulations. We reserve and maintain all our rights set out in law and our licence.  </p>
                <p>17.4. The Agreement is personal to you and you may only transfer it to someone else with our prior written consent. </p>
                <p>17.5. We may, with or without your consent, assign or transfer any of our rights under the Agreement to another party that holds the requisite authorisations, subject to the terms of our licence.  </p>
                <p>17.6. We may also subcontract anything we have agreed to do under the Agreement, but we will still be responsible for anything carried out by our subcontractors.  We will tell you as soon as we reasonably can if we transfer any rights or obligations.  </p>
                <p>17.7. If we need to contact you, we will use the contact details you have given to us or made available to us through industry processes. Our contact details are listed below if you need to contact us. </p>
                <p>17.8. Where there may be conflicting terms and conditions specific to your tariff and your [Customer Agreement Form], the terms and conditions specific to your tariff take preference. </p>
                <p className="montserrat-Bold">18. Terms of Connection</p>
                <p>18.1. The Network Operator operates the electricity distribution system that delivers electricity to the Supply Address and has appointed us to act as its agent to enter into a Connection Agreement with you on standard terms. You agree that, by entering into the Agreement with us, you are also entering into a Connection Agreement with the Network Operator for connection of your premises to its network. The terms of that Connection Agreement are the Network Operator’s Standard Connection Terms and Conditions, a copy of which can be obtained by contacting the Network operator. </p>
                <p>18.2. You are entitled to negotiate terms of connection of the Supply Address to the electricity network that vary from the Network Operator’s Standard Connection Terms and Conditions. To do so you must negotiate with the Network Operator about those terms but your supply cannot commence until you have provided us with evidence that there is a Connection Agreement in force for your premises with the Network Operator and you must inform us immediately if that Connection Agreement terminates.  </p>
                <p>18.3. As your supplier it is our responsibility to arrange the delivery of electricity to the Supply Address. The Network Operator is responsible for the quality and continuity of your electricity supply. The Network Operator must comply with certain service standards, including statutory standards in relation to the delivery of the range of services they provide. If they fail to meet these statutory standards you are entitled to a payment from the Network Operator. You can obtain a copy of these standards from the Network Operator. </p>
                <p className="montserrat-Bold">19. Notices</p>
                <p>19.1. Any notice or other communication required under the Agreement will be writing and will be sent by post or by email and will also be published on our Website. Any notices sent by post will be sent to the billing address or any other addresses (as the case may be) notified by you to us. Any notices sent by email will be sent to the e-mail address notified by you to us (if any).  We’ll assume you’ve received notices within three Working Days after posting and electronic notices on the same Day we send it unless we receive evidence to the contrary. </p>



              </div> {/* end standardtsandcs */}



              {/* DEEMED CONTRACT */}
              <br /><hr /><br />
              <div id="deemedcontract">
                <p className="montserrat-Bold">Deemed Contract</p>

                <p className="montserrat-Bold">1. Background</p>
                <p>Pursuant to paragraph 3(6) of Schedule 6 of the Electricity (Northern Ireland) Order 1992 (the “Order”) and condition 28 of its Licence, Bright Bright Energy Supply NI Limited (“bright”) is required to make a scheme for determining the terms and conditions which are to be incorporated into contracts which are deemed to have been made <span className="montserrat-Bold">("Deemed Contract Scheme").</span></p>
                <p className="montserrat-Bold">2. Deemed contract for Supply</p>
                <p>This Deemed Contract Scheme will apply to any customer who is supplied electricity by bright other than pursuant to an Agreement. This situation can happen where you move into a new property or a vacant property and bright is already the existing electricity supplier at that property <span className="montserrat-Bold">("Property").</span></p>
                <p className="montserrat-Bold">3. Terms and Conditions</p>
                <p>The terms and conditions of supply to domestic customers under a Deemed Contract shall be the same as those applicable to an Agreement agreed between bright and the customer for the same product.  These are the Standard Terms and Conditions for Supply for Domestic Customers of Bright Energy Supply NI Limited Terms (as amended) <span className="montserrat-Bold">("Terms") </span>together with the terms of the applicable tariff from time to time.  You can find a current copy of our Terms and applicable tariff information on our Website www.brightenergy.com. </p>
                <p>The Deemed Contract will start as soon as you begin using electricity at that property which is supplied by bright. </p>
                <p>Where a Customer is being supplied electricity by bright under a Deemed Contract as a result of a Last Resort Supply Direction, Condition 28, paragraph 3 of the Licence shall not apply under the last Resort Supply Direction ceases to have effect. </p>
                <p className="montserrat-Bold">4. Commencement Date</p>
                <p>This Scheme and the Deemed Contract will start as soon as the Property is supplied with electricity by bright otherwise than pursuant to an Agreement. </p>
                <p>Where a customer intends the property to be supplied with electricity under a contract agreed with bright or any other electricity supplier, the Deemed Contract shall continue to have effect until bright or such other electricity supplier commences the supply of electricity to the Property under such a contract. </p>
                <p className="montserrat-Bold">5. Estimating how much electricty has been used</p>
                <p>Where we do not have a Meter reading for the Property immediately before the customer takes responsibility for the Property, bright reserves the right to estimate the quantity of electricity supplied.  This estimation shall be from the point the customer takes responsibility for the Property until either the Meter is read or bright ceases to supply the Property, whichever is earliest.  The estimation will take into account available electricity consumption for the Property and any relevant factor and bright will act reasonably in calculating the amount of electricity used. </p>
                <p className="montserrat-Bold">6. Termination</p>
                <p>Unless otherwise provided in the Terms, a Deemed Contract shall terminate on the earlier of the date on which:  </p>
                <ul className="list-style-none definitions">
                  <li>(a) the Customer enters into a contract (other than a Deemed Contract) with bright or any other electricity supplier for the supply of electricity to the Property; or </li>
                  <li>(b) a Last Resort Supply Direction, given to an alternate Supplier, takes effect in relation to the Property, </li>
                  <li>(the <span className="montserrat-Bold">("Termination Date").</span></li>
                </ul>
                <p>In accordance with our Licence, where we supply electricity to a Customer at the Property under a Deemed Contract, no termination fee or other compensation will apply solely as a result of the Customer terminating the Deemed Contract.  However, the Customer shall remain liable for all consumption and other charges outstanding in respect of the Property in accordance with the Deemed Contract and the Terms until such charges are paid in full. </p>
                <p>Termination of a Deemed Contract, however arising, will be without prejudice to the rights and duties of the Customer and bright accrued prior to the Termination Date. </p>
                <p className="montserrat-Bold">7. Provision of Information</p>
                <p>Where bright supplies electricity under a Deemed Contract we will take all reasonable steps to provide the customer with a notice setting out the principal terms of the Deemed Contract and inform the customer that other contracts with terms and conditions different to those in the Deemed Contract may be available.  </p>
                <p>We will take all reasonable steps to enter into a contract with the Customer as soon as reasonably practicable. </p>
                <p className="montserrat-Bold">8. Governing Law and Jurisdiction</p>
                <p>This Scheme is governed by the law of Northern Ireland, and the courts of Northern Ireland shall have exclusive jurisdiction in relation to this Scheme. </p>
                <p className="montserrat-Bold">9. Contact us</p>
                <p>This Deemed Contract Scheme is available on our Website www.brightenergy.com or you can request a copy free of charge by contacting our energy specialists on 028 9544 2290. </p>
                <p>If you have any questions about the document, or anything you are not clear about, please get in touch with our energy specialists who will be pleased to help you. </p>
                <p className="montserrat-Bold">10. Terms</p>
                <p><span className="montserrat-Bold">Agreement</span> means an application to us for supply of electricity, either signed or agreed on the telephone or online, together with the Terms and the relevant Tariff, as may be amended from time to time</p>
                <p><span className="montserrat-Bold">Deemed Contract</span> means a contract between bright and a consumer for the supply of electricity deemed to have been made under paragraph 3 of Schedule 6 of the Order, as amended</p>
                <p><span className="montserrat-Bold">Equipment</span> has the meaning given to it in the Terms</p>
                <p><span className="montserrat-Bold">Last Resort Supply Direction</span> means a direction given by the Utility Regulator in accordance with condition 22 of the Licence </p>
                <p><span className="montserrat-Bold">Licence</span> means the licence of for the supply of electricity granted by the Utility Regulator, as may be amended from time to time </p>
                <p><span className="montserrat-Bold">Meter</span> means the electricity meter installed by the Meter Provider for the purpose of measuring the quantity of electricity used at the Property and includes any such Meter or Meters of any type supplied at any time at the Property </p>
                <p><span className="montserrat-Bold">Meter Provider</span> means Northern Ireland Electricity Networks Limited (company number NI026041) or such other company as may be appointed to provide, install, maintain and administer the Equipment </p>
                <p><span className="montserrat-Bold">Order</span> has the meaning given to it at paragraph 1 of this Deemed Contract Scheme </p>
                <p><span className="montserrat-Bold">Property</span> has the meaning given to it at paragraph 2 of this Deemed Contract Scheme</p>
                <p><span className="montserrat-Bold">Term</span> has the meaning given to it at paragraph 3 of this Deemed Contract Scheme</p>
                <p><span className="montserrat-Bold">Utility Regulator</span> means the Northern Ireland Authority for Utility Regulation, its successors and assigns</p>
                <p><span className="montserrat-Bold">Website</span> www.brightenergy.com </p>

              </div>

              <br /><hr /><br />
              {/* <div id="referafriend">
                <p className="montserrat-Bold">Refer a Friend Terms & Conditions</p>
                <p>Terms and Conditions – Refer a Friend 
                  <br/>
                  The following are the Terms and Conditions of our ‘Refer a Friend’ programme. 
                  </p>
                  <p className="montserrat-Bold">1. Definitions and interpretation </p>
                  <ul className="list-style-none">
                    <li>1.1. Unless otherwise specified, the defined terms used in these ‘Refer a Friend’ Terms and Conditions shall have the same meanings given to them in our Standard Terms and Conditions for Supply for Residential Customers, which are accessible from our website www.brightenergy.com or in hard copy by contacting our energy specialists. </li>
                    <li>1.2. In these Terms and Conditions:  
                      <br/>
                     <b> “Bright”, “Company”, “we”, “us”, “our”</b> means Bright Energy Supply NI Limited with company Number NI655169 and registered address at 84-94 Great Patrick Street, Belfast, BT1 2LU, trading as Bright ; 
                      <br/>
                      <b>“Customer”</b> means any person who has entered into an Agreement with Bright for the supply of electricity;   
                      <br/>
                      <b>“Programme”</b> means Bright’s ‘Refer a Friend’ referrals programme; 
                      <br/>
                     <b> “Referrer”, “you”, “your”</b> means an existing Customer of Bright who is eligible to participate in the Programme; 
                      <br/>
                      <b>“Recruit”</b> means the new customer being referred; 
                      <br/>
                     <b> “Reward”</b> means the reward amount of £25 per Recruit offered to the Referrer by us in accordance with these Terms and Conditions; 
                      <br/>
                     <b> “Sign Up Date”</b> means the date on which the Recruit informs Bright, via enrolment on our website, that they want to switch their energy supply to Bright; 
                      <br/>
                      <b>“Switch Confirmation Date”</b> means for standard credit customers the date on which the Recruit receives their first bill from Bright, having successfully switched their energy supply to Bright, for keypad customers the date on which the Recruit completes their switch to Bright; 
                      <br/>
                      <b>“Unique Link”</b> means a URL unique to each Referrer. </li>
                    <li>1.3. The headings used in these Terms and Conditions are for convenience only and will not affect their interpretation. </li>
                    <li>1.4. Reference to a person includes that party’s personal representatives, successors and permitted assigns (where applicable). </li>
                    <li>1.5. Unless otherwise specified, the singular includes the plural and the plural includes the singular. </li>
                  </ul>

                  <p className="montserrat-Bold">2. Programme eligibility</p>
                  <ul className="list-style-none">
                    <li>2.1. The Programme is open to Referrers and Recruits who are over 18 and are residents of Northern Ireland. The Referrer and the Recruit must not live at the same address or have the same email address. </li>
                    <li>2.2. Referrers must be existing Bright Customers with a standard credit meter, who have completed their switch, are taking their energy supply from Bright and have set up an online account with us.       </li>  
                    <li>2.3. Keypad customers are not eligible to participate as a Referrer in this Programme.    </li>  
                    <li>2.4. A Recruit must not, under any email address or alias, have already signed up with Bright, or be an existing Customer of Bright, or have been a Customer of Bright in the preceding 12 months. In addition, a Recruit must not be resident at a Supply Address that is already receiving its electricity supply from Bright. </li>  
                    <li>2.5. The Programme will be valid from {config.ValidFrom} until such time as it is withdrawn by Bright. Bright will provide 30 days’ notice of withdrawal of the Programme.   </li>  
                    <li>2.6. The Recruit must use the Referrer's Unique Link to switch energy supply to Bright on our website. Referrals claimed retrospectively are not eligible and will not be rewarded. Bright is not responsible for failure to issue Rewards resulting from an incorrectly quoted Unique Link.  </li>  
                    <li>2.7. A Referrer will not receive more than one Reward per Supply Address for which electricity supply switches to Bright as the result of a referral.  </li>  
                    <li>2.8. If the Recruit cancels their Agreement with Bright before the Switch Confirmation Date, the Referrer will not be eligible to receive a Reward.  </li>  
                    <li>2.9. The Referrer will be notified by email once a Recruit has signed up, both on the Sign Up Date, and again on the Switch Confirmation Date.  </li>  
                    <li>2.10. The maximum number of Rewards payable to an individual Referrer is five. The Referrer may provide the Unique Link to more than five potential Recruits but will only be rewarded for the first five Recruits that switch to Bright.  </li>  
                    <li>2.11. A Customer is eligible for the Programme on signing up to Bright for the first time only. If you leave Bright and re-join us at a later date, you will not be eligible to participate in the Programme again.  </li>  
                    <li>2.12. Once the Recruit has completed their switch, they will be eligible to act as a Referrer, with the exception of keypad customers as per 2.3. </li>
                  </ul>

                  <p className="montserrat-Bold">3. Programme delivery and Rewards</p>
                  <ul className="list-style-none">
                    <li>3.1. Subject to these Terms and Conditions, the Referrer will become eligible to receive one Reward on the Switch Confirmation Date of a Recruit. The Reward will be credited to the Referrer within 14 days of the Switch Confirmation Date.    </li>
                    <li>3.2. Rewards are provided as credit to the Referrer’s account.  There is no cash alternative.   </li>  
                    <li>3.3. You are allowed to redeem a maximum of one Reward (£25) per month against your Electricity Charges.    </li>  
                    <li>3.4. If you have an electricity credit meter, any Rewards will be delivered to you as credit into your account.   </li>  
                    <li>3.5. Rewards are not transferable and may not be auctioned, traded, bartered or sold.   </li>  
                    <li>3.6. If your Electricity Charges for a given month are less than the Reward amount credited to you, the remainder of the Reward amount will be carried over and used towards the next month’s payment.  If your Electricity Charges for a given month are more than the Reward amount, the remainder of the costs will be charged via your chosen payment method.  </li>  
                    <li>3.7. If you leave Bright for any reason and still have an unused Reward amount credited to you, then, subject to clause 3.3, this will be deducted from any outstanding payments. There is no cash alternative where unredeemed Reward amounts exceed your outstanding payments and any such Reward amounts will be forfeited.     </li>  
                    <li>3.8. Upon termination of the Programme or any portion thereof for any reason, or upon cancellation of a Referrer’s account with Bright for any reason, the Referrer’s right to refer Recruits using the Unique Link and to receive future Rewards shall be forfeited.    </li>  
                    <li>3.9. Bright reserves the right to: (a) verify whether the Referrer and the Recruit qualify for the Programme; and (b) reject any application from a potential Recruit that appears to contain materially incorrect information or to be fraudulent.     </li>  
                    <li>3.10. Any applications to Bright from Recruits that are found to have been made fraudulently will not be eligible for a Reward and may be referred to the appropriate authorities for further investigation. </li>  
                    <li>3.11. Bright shall not be required to keep or preserve the original documentation pertaining to or arising out of any agreement (including the Agreement itself) but may keep copies of the same by using any electronic method of storing information and a legible copy or a recorded electronic audio file (as appropriate) of any such information so preserved shall be considered a true copy of it. Bright shall carry out any such storage in compliance with all applicable data protection laws. 

</li>  
                  </ul>
                  
              </div>
               */}

            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NITermsAndConditions;
