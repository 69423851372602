import { appPromotionsConstants } from "../constants";

let defaultState = {
  appPromotionsData: [],
  isLoading: false
};

const appPromotionsReducer = (state = defaultState, action) => {
  switch (action.type) {
    // Get FAQ's
    case appPromotionsConstants.GET_APP_PROMOTIONS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case appPromotionsConstants.GET_APP_PROMOTIONS_SUCCESS:
      return {
        ...state,
        appPromotionsData: action.payload,
        isLoading: false
      };
    case appPromotionsConstants.GET_APP_PROMOTIONS_FAILURE:
      return {
        error: action.payload
      };

    // Default
    default:
      return state;
  }
};

export default appPromotionsReducer;
