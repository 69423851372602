import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBInput, MDBRow, MDBAlert, MDBContainer } from "mdbreact";
import { connect } from "react-redux";
import { switchActions } from "../../../../redux/actions/switchActions";
import Loader from "../../../Shared/Loader";
import $ from "jquery";

// Tariff tables
import Keypad24hr from "./TariffTables/Keypad/Keypad24hr";
import KeypadEco7 from "./TariffTables/Keypad/KeypadEco7";
import KeypadPowershift from "./TariffTables/Keypad/KeypadPowershift";
import Standard24hr from "./TariffTables/StandardCredit/Standard24hr";
import StandardEco7 from "./TariffTables/StandardCredit/StandardEco7";

const LoadConfig = require("../../../../config");
const config = LoadConfig.default;

export class QuoteSummary extends Component {
  state = {
    switchID: "",
    email: "",
    jurisdiction: "ni",
    errorMessage: false,
    hideSaveQuote: true,
    isLoading: false,
    showCalculation: false,
    homeCheck: false,
  };

  // Page load
  componentDidMount() {
    // Get quote ID
    let switchID = localStorage.getItem("switchID").toString();
    this.setState({ switchID: switchID });

    if (switchID != null) {
      this.props.getSwitch(switchID);
    }
  }

  // Handle tariff table selection
  handleTariffTable = (keypad, meterType, ecoMeterType) => {
    // Create case for switch
    let keypadString = "";
    if (keypad) {
      keypadString = "Keypad";
    } else {
      keypadString = "Credit";
    }

    let tariffTable = keypadString + " " + meterType + " " + ecoMeterType;

    console.log(tariffTable);

    switch (tariffTable) {
      // Keypad meters
      case "Keypad 24hr ":
        return <Keypad24hr />;
        break;
      case "Keypad Eco7 Economy7DayNight":
        return <KeypadEco7 />;
        break;
      case "Keypad Eco7 Economy7DayNightHeat":
        return <KeypadEco7 />;
        break;
      case "Keypad Eco7 Powershift":
        return <KeypadPowershift />;
        break;
      // Credit meters
      case "Credit 24hr ":
        return <Standard24hr />;
        break;
      case "Credit Eco7 Economy7DayNight":
        return <StandardEco7 />;
        break;
      case "Credit Eco7 Economy7DayNightHeat":
        return <StandardEco7 />;
        break;
      case "Credit Eco7 Economy7DayNightHeat":
        return <StandardEco7 />;
        break;
      // Default
      default:
        break;
    }
  };

  render() {
    return (
      <div className='quote-page'>
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        {/* Background Image */}
        <div className='device-image' />
        <MDBCol className='text-center'>
          {/* Logo */}
          <MDBRow>
            <MDBCol lg='2' sm='12'>
              <a onClick={() => this.setState({ homeCheck: true })}>
                <img
                  src={require("../../../../images/global/bright-logo-dark.png")}
                  className='quote-logo mx-5 mt-5'
                />
              </a>
            </MDBCol>
            <MDBCol size='10' />
          </MDBRow>

          {/* Home message */}
          {this.state.homeCheck ? (
            <div className='modalBG'>
              <div className='modalBox'>
                <div className='white-curved-tile text-center'>
                  <h4>
                    All progress will be lost! <br />
                    Are you sure?
                  </h4>
                  <MDBRow>
                    <MDBCol lg='6' md='6' sm='12'>
                      <MDBBtn
                        className='cancel-button'
                        onClick={() =>
                          this.setState({ homeCheck: !this.state.homeCheck })
                        }
                      >
                        Cancel
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol lg='6' md='6' sm='12'>
                      <MDBBtn
                        onClick={() =>
                          (window.location.href = `/${this.state.jurisdiction}/`)
                        }
                      >
                        Continue
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
          ) : null}

          {/* Switch and save box */}
          <MDBRow className='my-5'>
            <MDBCol size='2' />
            <MDBCol lg='8' md='8' sm='12' className='text-center'>
              <div className='white-curved-tile'>
                <h4 className='mb-3'>
                  Join bright in 2 minutes.
                </h4>

                <p>
                  Join bright today for 100% green* electricity and 100% control with our app.<br/>
                  We guarantee fair pricing for your renewable electricity always.<br/>
                  We'll handle the switch with your current supplier.
                </p>                
                <MDBBtn
                  href={`/${this.state.jurisdiction}/switch`}
                  className='btn'
                >
                  Switch now
                </MDBBtn>
                <p>Not in Northern Ireland? <a href='/roi/quote'><u>Click Here to visit ROI</u></a></p>
                
              </div>
            </MDBCol>
            <MDBCol size='2' />
          </MDBRow>

          {/* Tariff tables */}
          <MDBRow>
          <MDBCol>
          <h3 className='mt-3 mb-2'>Bright Future Tariff</h3>
            {this.handleTariffTable(
              this.props.switchData.keypad,
              this.props.switchData.meterType,
              this.props.switchData.ecoMeterType
            )}
          </MDBCol>
          </MDBRow>

          {/* Marketing */}
          <MDBRow className='mb-5'>
            <MDBCol lg='2' md='2' sm='12' />
            <MDBCol lg='8' md='8' sm='12' className='mt-5'>
              <h3 className='mb-5'>Reasons to switch to bright</h3>
              <MDBRow className='hero-icons-container'>
                <MDBCol
                  sm='6'
                  md='6'
                  lg='3'
                  className='text-left icons-container'
                >
                  <div className='image-container'>
                    <img
                      src={require("../../../../images/home-page/clean-energy-icon.png")}
                    ></img>
                  </div>
                  <h3>100% green electricity supplier</h3>
                </MDBCol>
                <MDBCol
                  sm='6'
                  md='6'
                  lg='3'
                  className='text-left icons-container'
                >
                  <div className='image-container'>
                    <img
                      src={require("../../../../images/home-page/switch.png")}
                    ></img>
                  </div>
                  <h3>We make it easy to switch</h3>
                </MDBCol>
                <MDBCol
                  sm='6'
                  md='6'
                  lg='3'
                  className='text-left icons-container'
                >
                  <div className='image-container'>
                    <img
                      src={require("../../../../images/home-page/transparent-pricing-icon.png")}
                    ></img>
                  </div>
                  <h3>Transparent pricing for all</h3>
                </MDBCol>
                <MDBCol
                  sm='6'
                  md='6'
                  lg='3'
                  className='text-left icons-container'
                >
                  <div className='image-container'>
                    <img
                      src={require("../../../../images/home-page/manage-account-icon.png")}
                    ></img>
                  </div>
                  <h3>Managing your account is simple</h3>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol lg='2' md='2' sm='12' />
          </MDBRow>

          {/* Featured in */}

          <MDBRow className='mb-5 pb-5'>
            <MDBCol lg='2' md='2' sm='12' />
            <MDBCol lg='8' md='8' sm='12' className='mt-5'>
              <h3 className='my-5'>As seen in </h3>
              <MDBRow className='hero-icons-container featured-in'>
                <MDBCol
                  sm='4'
                  md='4'
                  lg='4'
                  className='text-center feat-icons-container'
                >
                  <img
                    className='w-60'
                    src={require("../../../../images/partners/bbc-logo.png")}
                  ></img>
                </MDBCol>

                <MDBCol
                  sm='4'
                  md='4'
                  lg='4'
                  className='text-center feat-icons-container'
                >
                  <img
                    className='w-80'
                    src={require("../../../../images/partners/independent.png")}
                  ></img>
                </MDBCol>
                <MDBCol
                  sm='4'
                  md='4'
                  lg='4'
                  className='text-center feat-icons-container'
                >
                  <img
                    className='w-80'
                    src={require("../../../../images/partners/sundaytimes.png")}
                  ></img>
                </MDBCol>
                
              </MDBRow>
            </MDBCol>
            <MDBCol lg='2' md='2' sm='12' />
          </MDBRow>

          {/* Switch Now */}
          <MDBRow center>
            <MDBCol lg='8' md='8' sm='12' className='text-center my-5'>
              <div className='white-curved-tile'>
                <h4>Start your switch now!</h4>
                <p>It only takes 2 minutes</p>
                {/* <ul className='bullet-list'>
                  <li className='centerBullet'>Your recent bill</li>
                  <br />
                  <li className='centerBullet'>A meter reading</li>
                  <br />
                  <li className='centerBullet'>Your bank details</li>
                  <br />
                  <li className='centerBullet'>Your MPRN or KPN</li>
                  <br />
                </ul> */}
                <MDBRow center>
                  <MDBCol lg="4" md="6" sm="12">
                  <ul className='bullet-list'>
                          <li>Your recent bill</li>
                          <li>A meter reading</li>
                          <li>Your bank details</li>
                          <li>Your MPRN or KPN</li>
                        </ul>
                  </MDBCol>
                </MDBRow>
                
                <a href={`/${this.state.jurisdiction}/switch`}>
                  <MDBBtn className='btn'>Switch now</MDBBtn>
                </a>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    switchData: store.switch.switchData,
    isLoading: store.quote.isLoading,
  };
};

const actionCreators = {
  getSwitch: switchActions.getSwitchJourney,
};

export default connect(mapStateToProps, actionCreators)(QuoteSummary);
