import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
  MDBAlert,
} from "mdbreact";
import Loader from "../../../../components/Shared/Loader";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  agencyActions,
  meterReadActions,
  customerAccountActions,
} from "../../../../redux/actions";
import $ from "jquery";

const AgencyPropertyView = (props) => {
  // Redux
  const dispatch = useDispatch();
  const agencyDetails = useSelector((state) => state.agency);
  const meterReadDetails = useSelector((state) => state.meterRead);
  const customerAccountDetails = useSelector((state) => state.customerAccount);

  // State
  const [jurisdiction, setJurisdiction] = useState("");
  const [user, setUser] = useState([]);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountId, setAccountId] = useState("");
  const [accountAddress, setAccountAddress] = useState([]);
  const [billAmount, setBillAmount] = useState("");
  const [productCode, setProductCode] = useState("");
  const [mprn, setMPRN] = useState("");
  const [meterType, setMeterType] = useState("");
  const [showMeterRead, setShowMeterRead] = useState(false);
  const [readingError, setReadingError] = useState(false);
  const [meterReading, setMeterReading] = useState(0);
  const [nghtMeterReading, setNightMeterReading] = useState(0);
  const [billList, setBillList] = useState(null);

  useEffect(() => {
    let pathArray = window.location.pathname.split("/");
    let jurisdiction = pathArray[1];
    setJurisdiction(jurisdiction);

    let user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    let properties = user.data.properties;

    let urlParams = new URLSearchParams(window.location.search);
    let accountNumber = urlParams.get("acNo");
    setAccountNumber(accountNumber);

    let account = properties.filter(function (id) {
      return id.number === accountNumber;
    });
    let accountId = account[0].id;
    setAccountId(accountId);
    let accountAddress = account[0].billingAddress;
    let address1 = "";

    if (accountAddress.address1 != null) {
      address1 = accountAddress.address1 + ",";
    } else {
      address1 = "";
    }

    accountAddress = `${address1} ${accountAddress.address2}, ${accountAddress.address3}, ${accountAddress.address4}`;
    setAccountAddress(accountAddress);

    let billAmount = account[0].balance;
    setBillAmount(billAmount);

    dispatch(agencyActions.getElectricityProduct(accountId, jurisdiction));
  }, []);

  useEffect(() => {
    setProductCode(agencyDetails.electricityProduct.productCode);

    if (productCode === "E1R-VAR") {
      setMeterType("Standard");
    } else {
      setMeterType("NightSaver");
    }

    setMPRN(agencyDetails.electricityProduct.mprn);
    setBillList(agencyDetails.electricityProduct.billsList);
  }, [agencyDetails]);

  function displayMeterRead() {
    return (
      <div className="modalBG">
        <div className="modalBox">
          <MDBContainer className="main-content meter-readings">
            <div className="white-curved-tile">
              {meterReadDetails.isLoading ? <Loader /> : null}
              {meterReadDetails.landlordReadingSuccess === true ? (
                <div>
                  <MDBRow className="text-center">
                    <MDBCol>
                      <img
                        src={require("../../../../images/account/success.png")}
                        style={{ width: "30%" }}
                      />
                      <h6 className="my-3">
                        Your meter reading has been submitted successfully!{" "}
                      </h6>
                      <MDBBtn
                        className="btn-teal"
                        onClick={() => readingSuccess()}
                      >
                        Close
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              ) : (
                <div>
                  <div className="faq-section">
                    <img
                      src={require("../../../../images/account/bulb-icon.png")}
                    />
                    <p>
                      <b>Here's a Bright idea!</b>
                      <br /> Submitting a meter reading regularly means you only
                      pay for energy that you use!
                    </p>
                  </div>

                  {/* Input error */}
                  {readingError === true ? (
                    <MDBAlert color="danger" className="mb-5" dismiss>
                      <strong>Oops!</strong> Please check the fields below.
                    </MDBAlert>
                  ) : null}

                  {/* Post Failure */}
                  {meterReadDetails.error !== "" ? (
                    <MDBAlert color="danger" className="mb-5" dismiss>
                      {meterReadDetails.error}
                    </MDBAlert>
                  ) : null}

                  <h6 className="mb-3">
                    You are submitting a meter reading for:{" "}
                  </h6>

                  <p className="mb-3">
                    <b>
                      MPRN: <span className="underline">{mprn}</span>
                    </b>
                  </p>
                  {productCode === "E1R-VAR" ? (
                    // Standard
                    <form id="meterForm">
                      <input
                        type="text"
                        id="meterReading"
                        name="meterReading"
                        className={"meterRead"}
                        outline
                        onChange={(e) => setMeterReading(e.target.value)}
                        placeholder={"00000"}
                        maxLength="5"
                        pattern="^[_0-9]{1,5}"
                        required
                      />
                    </form>
                  ) : (
                    <form id="nightMeterForm">
                      <div>
                        {/* Day */}
                        <div className="my-3">
                          <p>
                            <b>
                              New <span className="underline">day</span>{" "}
                              reading:
                            </b>
                          </p>

                          <input
                            type="text"
                            id="meterReading"
                            name="meterReading"
                            className={"meterRead"}
                            outline
                            onChange={(e) => setMeterReading(e.target.value)}
                            placeholder={"00000"}
                            maxLength="5"
                            pattern="^[_0-9]{1,5}"
                            required
                          />
                        </div>
                        {/* Night */}
                        <div className="my-3">
                          <p>
                            <b>
                              New <span className="underline">night</span>{" "}
                              reading:
                            </b>
                          </p>

                          <input
                            type="text"
                            id="nghtMeterReading"
                            name="nghtMeterReading"
                            className={"meterRead"}
                            outline
                            onChange={(e) =>
                              setNightMeterReading(e.target.value)
                            }
                            placeholder={"00000"}
                            maxLength="5"
                            pattern="^[_0-9]{1,5}"
                            required
                          />
                        </div>
                      </div>
                    </form>
                  )}
                  <MDBRow center>
                    <div className="mr-2">
                      <MDBBtn onClick={() => submitMeterReading()}>
                        Submit Reading
                      </MDBBtn>
                    </div>
                    <div className="ml-2">
                      <MDBBtn
                        className="btn-teal"
                        onClick={() => setShowMeterRead(false)}
                      >
                        Cancel
                      </MDBBtn>
                    </div>
                  </MDBRow>
                  <MDBRow className="justify-content-center"></MDBRow>
                </div>
              )}
            </div>
          </MDBContainer>
        </div>
      </div>
    );
  }

  function submitMeterReading() {
    let date = new Date().toISOString().slice(0, 19);
    let validation = "";

    if (productCode === "E1R-VAR") {
      validation = $("#meterForm")[0].checkValidity();
    } else {
      validation = $("#nightMeterForm")[0].checkValidity();
    }

    if (validation === false) {
      setReadingError(true);
    } else {
      setReadingError(false);

      const readingDetails = {
        dateSubmitted: date,
        readingValue: parseInt(meterReading),
        nightReadingValue: parseInt(nghtMeterReading),
        meterType: meterType,
        jurisdiction: jurisdiction,
        jId: accountId,
      };

      dispatch(meterReadActions.submitLandlordReading(readingDetails));
    }
  }

  function readingSuccess() {
    setShowMeterRead(false);
    window.location.reload();
  }

  function displayBillList(bill) {
    return (
      <MDBRow className="moreHR">
        <MDBCol size="6" className="">
          <h5 className="mt-3">
            {bill.periodTo.slice(0, 10).split("-").reverse().join("/")}
          </h5>
        </MDBCol>
        <MDBCol size="6" className="text-right">
          <div className="viewBill">
            <h5 className="mt-3">
              {bill.grossAmount.toFixed(2)}
              <a
                onClick={() =>
                  dispatch(
                    customerAccountActions.getBillById(bill.id, jurisdiction)
                  )
                }
              >
                <span className="ml-4 mr-3">View</span>
              </a>
            </h5>
          </div>
        </MDBCol>
      </MDBRow>
    );
  }

  function viewLatestBill() {
    let billId = billList[0].id;
    dispatch(customerAccountActions.getBillById(billId, jurisdiction));
  }

  return (
    <div className="account-dashboard-container">
      <div className="account-details-container">
        <div className="make-payment-background">
          {agencyDetails.isLoading ? <Loader /> : null}
          {customerAccountDetails.isLoading ? <Loader /> : null}

          <MDBContainer className="main-content payment-history">
            <MDBRow>
              <MDBCol size="1" className="align-self-center">
                <a href={`/${jurisdiction}/account/landlorddashboard`}>
                  <i className="fas fa-chevron-left fa-2x" />
                </a>
              </MDBCol>
              <MDBCol size="9" className="text-center pt-5" center>
                <h3>Property View</h3>
              </MDBCol>
              <MDBCol size="2">
                <img
                  src={require("../../../../images/global/bright-logo-dark.png")}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-5">
              <MDBCol>
                <div className="white-curved-tile text-center">
                  <h4 className="text-left">
                    Property bill amount : €{billAmount}
                  </h4>
                </div>
              </MDBCol>
            </MDBRow>
            <div className="white-curved-tile">
              <MDBRow center>
                <MDBCol size="10">
                  <MDBTable>
                    <MDBTableHead></MDBTableHead>
                    <MDBTableBody>
                      <tr>
                        <td className="font-16">Address:</td>
                        <td className="font-16">{accountAddress}</td>
                      </tr>
                      <tr>
                        <td className="font-16">MPRN:</td>
                        <td className="font-16">{mprn}</td>
                      </tr>
                      <tr>
                        <td className="font-16">Account Number:</td>
                        <td className="font-16">{accountNumber}</td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </MDBCol>
              </MDBRow>
              <MDBRow center>
                <div className="mr-2">
                  <MDBBtn onClick={() => setShowMeterRead(true)}>
                    Submit Meter Read
                  </MDBBtn>
                </div>
                <div className="ml-2">
                  <MDBBtn className="btn-teal" onClick={() => viewLatestBill()}>
                    View Latest Bill
                  </MDBBtn>
                </div>
              </MDBRow>
            </div>

            <div className="white-curved-tile">
              <MDBRow center className="my-3">
                <h4>Bills & Statements</h4>
              </MDBRow>
              <MDBRow className="moreHR">
                <MDBCol size="6" className="">
                  <h5 className="mt-3">Bill period to</h5>
                </MDBCol>
                <MDBCol size="6" className="text-right">
                  <h5 className="mt-3">
                    Amount
                    <i className="fas fa-chevron-right fa-lg pl-5 white-text" />
                  </h5>
                </MDBCol>
              </MDBRow>

              {billList != null
                ? billList.map((bill) =>
                    bill.status == "Accepted" && bill.supersededFl == false ? (
                      <div>{displayBillList(bill)}</div>
                    ) : null
                  )
                : null}
            </div>
          </MDBContainer>

          {showMeterRead ? <div>{displayMeterRead()}</div> : null}
        </div>
      </div>
    </div>
  );
};
export default AgencyPropertyView;
