//CURRENT LIVE SLOT = Slot 1 (2.6.5 Release to LIVE on Friday 15th Oct 2021)
//CURRENT UAT SLOT = Slot 2 (2.7 Release to UAT on Friday 29th Oct 2021)

const currentEnv = "prod1";

// LIVE - Slot one
const prodOne = {
  apiUrl: "https://api.brightenergy.com",
  googleTagManagerId: "GTM-NWC5247",
  stripeKey: "pk_live_1vuY6br9O1Rz3LbvnMIlYLhe00xb8fXezS",
  niStripeKey: "pk_live_51HoreeJV0cXNz9IvqNYaBiOwRLUzh0vQ6VKsM0VStieTThmdNrdxFhLGa5VcPz1vM7vApRCov510x29fR2ZgczlP00j232fs7S",
};

// LIVE - Slot two
const prodTwo = {
  apiUrl: "https://api2.brightenergy.com",
  googleTagManagerId: "GTM-NWC5247",
  stripeKey: "pk_live_1vuY6br9O1Rz3LbvnMIlYLhe00xb8fXezS",
  niStripeKey: "pk_live_51HoreeJV0cXNz9IvqNYaBiOwRLUzh0vQ6VKsM0VStieTThmdNrdxFhLGa5VcPz1vM7vApRCov510x29fR2ZgczlP00j232fs7S",
};

// UAT - Slot one
const uatOne = {
  apiUrl: "https://api-uat.brightenergy.com",
  googleTagManagerId: "GTM-NKBGXS7",
  stripeKey: "pk_test_S0YuNZLUdUJX7OWdMLDM9OCO00TBjdUCfm",
  niStripeKey: "pk_test_51HoreeJV0cXNz9IvPGMThk8qQylzLNiqODQoDM6GNEu5hYrRxJudeHWfhN3jUa3MqBgBb1giEohSLPrVegKrfIA0004u09eLTc",
};

// UAT - Slot two
const uatTwo = {
  apiUrl: "https://brightweb-uat-02.azurewebsites.net",
  googleTagManagerId: "GTM-NKBGXS7",
  stripeKey: "pk_test_S0YuNZLUdUJX7OWdMLDM9OCO00TBjdUCfm",
  niStripeKey: "pk_test_51HoreeJV0cXNz9IvPGMThk8qQylzLNiqODQoDM6GNEu5hYrRxJudeHWfhN3jUa3MqBgBb1giEohSLPrVegKrfIA0004u09eLTc",
};

// DEV - Local host
const dev = {
  apiUrl: "http://localhost:5000",
  googleTagManagerId: "GTM-NKBGXS7",
  stripeKey: "pk_test_S0YuNZLUdUJX7OWdMLDM9OCO00TBjdUCfm",
  niStripeKey: "pk_test_51HoreeJV0cXNz9IvPGMThk8qQylzLNiqODQoDM6GNEu5hYrRxJudeHWfhN3jUa3MqBgBb1giEohSLPrVegKrfIA0004u09eLTc",
};

let config = "";
switch (currentEnv) {
  case "prod1":
    config = prodOne;
    break;

  case "prod2":
    config = prodTwo;
    break;

  case "uat1":
    config = uatOne;
    break;

  case "uat2":
    config = uatTwo;
    break;

  case "dev":
    config = dev;
    break;

}

export default {
  versionNumber: "3.0",

  // Jurisdiction API
  jurisChecker: "https://ipapi.co/json/?key=ylTDgX235KfOh3FGXsrNP1s3PheWworYOiKpHbWHfWXZBT9Jzd",

  // Energy usage load profile percentages
  urban24LoadProfile: [9.63, 8.55, 8.92, 7.82, 7.68, 7.23, 7.33, 7.70, 7.75, 8.56, 8.99, 9.85],
  urbanNSLoadProfile: [10.31, 9.55, 10.09, 8.46, 7.41, 6.46, 6.51, 6.68, 6.92, 8.10, 9.41, 10.12],
  rural24LoadProfile: [9.81, 8.61, 9.2, 8.04, 7.62, 6.85, 7.17, 7.62, 7.33, 8.23, 8.93, 10.58],
  ruralNSLoadProfile: [9.07, 8.93, 9.95, 8.77, 8.08, 7.5, 7.51, 7.51, 7.18, 7.85, 8.52, 9.13],

  //Energy Usage NI load profile percentages
  ni24hourLoadProfile: [10.09, 8.77, 9.44, 8.08, 7.68, 6.97, 7.08, 6.93, 7.19, 8.34, 9.21, 10.22],
  niEco7LoadProfile: [11.19, 9.88, 10.61, 8.20, 6.97, 5.83, 5.89, 5.71, 6.52, 8.26, 10.06, 10.88],

  //##################ROI TARIFF RATES#############################//
  //*URBAN 24HR
  urb_tfh_unitrate: 0.28129,
  urb_tfh_standing: 232.83,
  urb_tfh_pso: 58.57,
  urb_tfh_eab_exvat: 1298,
  urb_tfh_eab_incvat: 1473,

  //*RURAL 24HR
  rur_tfh_unitrate: 0.28129,
  rur_tfh_standing: 268.85,
  rur_tfh_pso: 58.57,
  rur_tfh_eab_exvat: 1330,
  rur_tfh_eab_incvat: 1509,

  //*URBAN NIGHTSAVER
  urb_ns_dayunitrate: 0.33692,
  urb_ns_nightunitrate: 0.16447,
  urb_ns_standing: 232.83,
  urb_ns_pso: 58.57,
  urb_ns_eab_exvat: 1184,
  urb_ns_eab_incvat: 1344,

  //*RURAL NIGHTSAVER
  rur_ns_dayunitrate: 0.33692,
  rur_ns_nightunitrate: 0.16447,
  rur_ns_standing: 268.85,
  rur_ns_pso: 58.57,
  rur_ns_eab_exvat: 1217,
  rur_ns_eab_incvat: 1381,
  //FIGURES FOR TEXT SECTIONS - diff to above figures which are to correct decimal place
  //tariff valid from
  ValidFrom: "7th October 2021",
  //unit rates
  TFHExVat: 24.783,
  TFH: 28.129,
  NSDayExVat: 29.685,
  NSDay: 33.692,
  NSNightExVat: 14.491,
  NSNight: 16.447,
  //EAB
  TFHUrbEAB: 1473,
  TFHRurEAB: 1509,
  NSUrbEAB: 1344,
  NSRurEAB: 1381,

  // PSO levy
  psoLevyExVat: 51.60,
  psoLevy: 58.57,
  monthlyPsoLevy: 4.88, //24h rate
  // Urban standing charge
  urbanStandingChargeCentPerDay: 63.790, //inc vat total/365
  urbanStandingChargeExVat: 205.13,
  urbanStandingCharge: 232.83,
  monthlyUrbanStandingCharge: 19.403,
  // Rural standing charge
  ruralStandingChargeCentPerDay: 73.660, //inc vat total/365
  ruralStandingChargeExVat: 236.88,
  ruralStandingCharge: 268.85,
  monthlyRuralStandingCharge: 22.404,
  // Smart meter
  SMDay: 33.692,
  SMNight: 16.447,
  SMPeak: 41.859,
  SMStandingCharge: 232.83,
  SMRuralStandingCharge: 268.85,
  SMUrbanEAB: 1584,
  SMRuralEAB: 1621,
  SMDayExVat: 29.685,
  SMNightExVat: 14.491,
  SMPeakExVat: 36.880,
  SMUrbanStandingChargeExVat: 205.13,
  SMRuralStandingChargeExVat: 236.88,

  //##################END ROI TARIFF RATES############################//

  //######################NI TARIFF RATES#############################//
  //STANDARD CREDIT 24HOUR
  sc_tfh_unit_exVat: 25.235,
  sc_tfh_unit_incVat: 26.497,
  sc_tfh_standing_exVat: 9.61,
  sc_tfh_standing_incVat: 10.09,

  //STANDARD CREDIT ECO7
  sc_eco7_unitday_exVat: 29.421,
  sc_eco7_unitday_incVat: 30.892,
  sc_eco7_unitnight_exVat: 15.825,
  sc_eco7_unitnight_incVat: 16.616,
  sc_eco7_unitheat_exVat: 15.825,
  sc_eco7_unitheat_incVat: 16.616,
  sc_eco7_standing_exVat: 9.61,
  sc_eco7_standing_incVat: 10.09,

  //KEYPAD
  kp_tfh_unit_exVat: 25.235,
  kp_tfh_unit_incVat: 26.497,
  kp_tfh_standing_exVat: 12.88,
  kp_tfh_standing_incVat: 13.53,
  kp_tfh_monthly_standing_incVat: 3.07,

  //KEYPAD ECO7
  kp_eco7_unitday_exVat: 29.307,
  kp_eco7_unitday_incVat: 30.772,
  kp_eco7_unitnight_exVat: 15.711,
  kp_eco7_unitnight_incVat: 16.497,
  kp_eco7_unitheat_exVat: 15.711,
  kp_eco7_unitheat_incVat: 16.497,
  kp_eco7_standing_exVat: 12.88,
  kp_eco7_standing_incVat: 13.53,
  kp_eco7_monthly_standing_incVat: 4.12,

  //KEYPAD POWERSHIFT
  kp_powershift_unit1_exVat: 35.168,
  kp_powershift_unit1_incVat: 36.927,
  kp_powershift_unit2_exVat: 27.842,
  kp_powershift_unit2_incVat: 29.234,
  kp_powershift_unit3_exVat: 15.711,
  kp_powershift_unit3_incVat: 16.497,
  kp_powershift_unit4_exVat: 15.711,
  kp_powershift_unit4_incVat: 16.497,
  kp_powershift_standing_exVat: 12.88,
  kp_powershift_standing_incVat: 13.53,
  //##################END NI TARIFF RATES#############################//

  //load in relevant env config
  ...config,
};
