import React, { Component } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdbreact";
import Section1 from "../components/Home/Section1";
import TrustPilot from "../components/Shared/Trustpilot";

import Swiper from "react-id-swiper";

import { Zoom, Fade } from "react-reveal";

import ReactPlayer from "react-player";
import Partners from "../components/Home/Partners";

// PDFS
import signupPDF from "../documents/signups.pdf";
import billingPDF from "../documents/billing.pdf";
import charterPDF from "../documents/charter.pdf";
import complaintsPDF from "../documents/complaints.pdf";
import marketingPDF from "../documents/marketing.pdf";
import VulnerablePDF from "../documents/vulnerable.pdf";
import smartServicesPDF from "../documents/smartServices.pdf";
import CRUMessage from "../components/Shared/CRUMessage";
import EAB from "../components/Shared/EAB";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoadConfig = require("../config");
const config = LoadConfig.default;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postcode: "",
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];

    if (juris != "roi" && juris != "ni") {
      window.location.href = "/roi";
    } else {
      this.setState({
        jurisdiction: juris,
      });
    }

  }

  render() {
    // News Seciton Swiper https://swiperjs.com/api/
    const newsSwiper = {
      slidesPerView: 3,
      spaceBetween: 0,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1000,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
      },

      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 30,
        },

        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },

        1024: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      },
    };

    return (
      //{/*<MDBAnimation reveal type="fadeInLeft">*/}
      //{/*</MDBAnimation>*/}

      // To target specific components on a page give the page a name for example.... <div className="default-page Home-Page">
      <div className='default-page home-page'>
        {/* Covid banner */}
        <ToastContainer />

        {/* Home Page Hero */}
        <MDBRow className='home-hero home-background-image'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='9' lg='9' className='content text-center offset-md-2'>
                <h3 className='white-text'>Bright is closing its doors and will cease trading on 13th February 2022</h3>



              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>


        <div className='switch-today mt-2'>
          <MDBContainer className='switch-today-container mt-2'>
            <MDBRow className='switch-today large-padding pb-0'>
              <MDBCol sm='12' md='7' lg='8' className='title-container'>
                <div className='text-center'>
                  <i>Monday 31st January 2022</i>
                  <h4 className="mt-4">Bright is closing its doors and will cease trading on 13th February 2022</h4>

                  <p className="mt-3">
                    Bright launched its 100% renewable electricity offering in 2020.  Since then, wholesale energy prices have increased by 200% and, unfortunately, operating under the current conditions with no sign of market recovery is no longer a viable option for us.
                  </p>

                  <p>We have made the difficult decision to leave the market and we would like to take this opportunity to thank all of the amazing customers who joined us on an important green energy journey. </p>

                  <h4 className="mt-5">Customers will be moved to Power NI</h4>
                  <p className="mt-3">Bright has entered into a process with the Utility Regulator called ‘Supplier of Last Resort’ (SOLR) which ensures customers continue to receive their electricity supply and will not experience any disruption to their service.  During the process, customers will be automatically transferred to Power NI.  Power NI have been appointed as the Supplier of Last Resort by the Utility Regulator as they are the price regulated supplier in Northern Ireland.
                  </p>

                  <br />
                  <h4>What happens next</h4>
                  <div className="text-left">

                    <p>Please do not try to switch from Bright at this time as any switches will be cancelled and the customers transferred to Power NI.
                    </p>
                    <p>Once the switch is complete Power NI will post out a welcome pack which will provide your tariff and principal terms of the contract with Power NI.</p>
                  </div>


                  <br />
                  <h4>Keypad customers</h4>
                  <div className="text-left">

                    <ul className="mt-3 happensnext">
                      <li><b>Any credit balance you had with Bright will be protected</b> and carried over to your new account with Power NI</li>
                      <li>Power NI will issue a <b>new keypad number</b> and top up card to all keypad customers</li>
                      <li>Customers will be advised by Power NI of their new Keypad Number by email on 13 February. Customers will also be able to get their new number by contacting an automated telephone service from 13 February, details of this service will be available on the Power NI website from that date.</li>
                      <li>From 13 February you will no longer be able to use your Bright keypad card to top up.</li>
                      <li>Your new top up cards will be posted to you and should arrive within 5 working days.  You can use your new keypad number to top up in the meantime.</li>
                      <li>Friendly credit arrangements remain in place so that if you run out of paid credit over the weekend you will be able to access friendly credit until 11am on Monday 14 February which you will pay back when you next top up with Power NI.</li>
                      <li>You will continue to pay the Bright tariff until you next top up with Power NI, when you will receive a 60 digit code.  Details of the Power NI keypad tariffs can be found at <a href="https://www.powerni.co.uk">www.powerni.co.uk.</a></li>
                    </ul>
                  </div>

                  <br />
                  <h4>How do I top up?</h4>
                  <div className="text-left">
                    <p>From 13 February you can top up using your new keypad number </p>

                    <ul className="mt-3 happensnext">
                      <li>You can top up with Power NI online at www.powerni.co.uk</li>
                      <li>By telephone on the Customer helpline 03457 455 455 (auto serve 24hr a day)</li>
                      <li>At any Post Office or Paypoint</li>
                      <li>Using the Power NI app</li>
                    </ul>
                  </div>

                  <br />
                  <h4>Credit Customers</h4>
                  <div>
                    <p>
                      Credit customers will receive a final bill from Bright.  To ensure the accuracy of this bill please provide a meter read to Bright before 12 February through the app, or email hello@brightenergy.com along with your MPRN.
                    </p>
                    <p>Following the transfer Bright will send you a final bill.  Any credits and security deposits will be returned to customers once the final bill is issued.  You are responsible for paying any outstanding balance owed to Bright.  Do not cancel your Direct Debit as this will be used to collect your final payment. </p>
                    <p>Bright will not transfer your financial information to Power NI, if you wish to continue to pay for your electricity by Direct Debit you should contact Power NI and set up a Direct Debit with them.  You can contact Power NI on 03457 455 455 (Monday – Friday 9am – 5pm)</p>
                    <p>You will be charged at Power NIs tariff rates from 13 February and you will find specific information on Power NI tariffs on their website www.powerni.co.uk. </p>
                  </div>

                  <br/>
                  <h4>Can customers switch from Power NI? </h4>
                  <div>
                  <p>
                    20 working days after transferring to Power NI, customers will be able to switch to a different electricity supplier if they choose to do so. If you would like further information on switching supplier, you can go to the Consumer Council’s website where you will find their energy price comparison tool – http://www.consumercouncil.org.uk/energy/electricity-gas-price-comparison/
                    <br />
                    More information on Power NI can be found at the following links:
                    <br/><br/>
                    <a href="https://www.powerni.co.uk">Power NI Home</a><br/>
                    <a href="https://www.powerni.co.uk/bright-energy">Power NI - Bright energy</a><br/>
                    <a href="https://powerni.co.uk/bright-energy-change-of-electricity-supplier">Power NI - Change of Supplier from Bright energy</a>

"
                  </p>
                  </div>


                </div>
              </MDBCol>
            </MDBRow>

            <MDBRow className='large-padding pt-0'>

            </MDBRow>
          </MDBContainer>
        </div>




      </div>
    );
  }
}

export default Home;
