import { switchConstants } from "../constants";

let defaultState = {
  switchData: [],
  addressLookupData: null,
  addressLookupMPRN: "",
  addressLookupError: "",
  isLoading: false,
};

const switchReducer = (state = defaultState, action) => {
  switch (action.type) {
    // Get switch
    case switchConstants.GET_SWITCHJOURNEY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case switchConstants.GET_SWITCHJOURNEY_SUCCESS:
      return {
        switchData: action.payload,
        addressLookupError: "",
        isLoading: false,
        addressLookupError: "",
      };
    case switchConstants.GET_SWITCHJOURNEY_FAILURE:
      return {};

    // Create switch journey
    case switchConstants.CREATE_SWITCHJOURNEY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case switchConstants.CREATE_SWITCHJOURNEY_SUCCESS:
      return {
        ...state,
        switchData: action.payload.values,
        switchJourneyID: action.payload.journeyID,
        isLoading: false,
      };

    case switchConstants.CREATE_SWITCHJOURNEY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    // Create switch journey
    case switchConstants.UPDATE_SWITCHJOURNEY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case switchConstants.UPDATE_SWITCHJOURNEY_SUCCESS:
      return {
        ...state,
        switchData: action.payload.values,
        isLoading: false,
      };

    case switchConstants.UPDATE_SWITCHJOURNEY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    // Address lookup ROI
    case switchConstants.ADDRESS_LOOKUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case switchConstants.ADDRESS_LOOKUP_SUCCESS:
      return {
        ...state,
        addressLookupData: action.payload,
        addressLookupMPRN: action.mprn,
        addressLookupError: "",
        isLoading: false,
      };

    case switchConstants.ADDRESS_LOOKUP_FAILURE:
      return {
        ...state,
        addressLookupError: action.payload,
        addressLookupData: null,
        isLoading: false,
      };

    // Address lookup NI
    case switchConstants.ADDRESS_LOOKUP_NI_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case switchConstants.ADDRESS_LOOKUP_NI_SUCCESS:
      return {
        ...state,
        addressLookupData: action.payload,
        addressLookupPostcode: action.postcode,
        addressLookupError: "",
        isLoading: false,
      };

    case switchConstants.ADDRESS_LOOKUP_NI_FAILURE:
      return {
        ...state,
        addressLookupError: action.payload,
        addressLookupData: null,
        isLoading: false,
      };

    // Default
    default:
      return state;
  }
};

export default switchReducer;
