import React from "react";
import ROIQuoteSummary from "../../../components/Forms/Quote/ROI/_FormQuoteSummary";

class QuoteSummary extends React.Component {
  state = {
    jurisdiction: "roi",
  };

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  render() {
    return (
      <div>
        <ROIQuoteSummary />
      </div>
    );
  }
}

export default QuoteSummary;
