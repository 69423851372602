import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FAQTabs from "../../../components/Help/FAQTabs";

import FixedNavDark from "../../../components/Shared/FixedNavDark";
import $ from "jquery";

class NIFAQsMovingHouse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }
  
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.help);
  }

  help =() =>{
    if (window.pageYOffset <50)
    {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }
  
  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark/> */}

        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>FAQs</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
        
        <MDBRow className="pb-5">
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <FAQTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5'>
            <h3>Moving House</h3>

            <div id='howtoswitch' className='mt-5 mb-5'>
              <h5 className='policy-title'>What should I do if I’m moving to a new house? </h5>
              <p className='policy-content'>
              Moving house is stressful enough – we want to make it as simple as possible for you.  You’ll just need to provide us with some information which we’ve set out below. 
              <br/>
              <p></p>
              <b>Moving Out</b><br/>
              Give us a call on <a href='tel:028 9544 2290'>028 9544 2290 </a>with:<br/>
              <ul>
                <li>Your final meter read</li>
                <li>Your MPRN from your electricity bill</li>
                <li>Forwarding contact details</li>
                <li>Landlord details if you are a tenant</li>
              </ul>
              Remember if you don’t provide us with accurate information you could be liable for any electricity charges that accrue before the new occupier moves in. 

              <br/><br/>
              <b>Moving In</b><br/>
              If you are moving into a property and bright is already the supplier or you want to switch to bright get in touch on <a href='tel:028 9544 2290'>028 9544 2290.</a>
              <br/>You’ll need: 
              <ul>
                <li>An up to date meter read</li>
                <li>Your MPRN (from previous occupier if possible)</li>
                <li>Your bank details</li>
                <li>Your contact details</li>
                <li>Your tenancy agreement if you are a tenant.  </li>
              </ul>
              </p>

              <p className='policy-content'>
              <b>Keypad Meters</b>
              <br/><br/>
              If you have a prepayment meter and are moving house, 
              let us know and provide a meter reading and details 
              of any credit left on the meter. If you can, try to 
              avoid topping up more than you need to avoid leaving 
              credit on the meter. If you have credit and you would 
              like a refund please contact us on <a href='tel:028 9544 2290'>028 9544 2290.</a>  You 
              should leave your card at the property as the card is 
              locked to that property and cannot be used elsewhere. 
              <br/><br/>
              When you move into a new property and bright are already 
              the electricity supplier or you would like us to be the 
              supplier, simply contact us to register and be sure to 
              have your current meter reading to hand.



              </p>

              
            </div>

          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NIFAQsMovingHouse;
