import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FAQTabs from "../../components/Help/FAQTabs";

import FixedNavDark from "../../components/Shared/FixedNavDark";
import $ from "jquery";

class FAQsBillingAndPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: "",
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark /> */}

        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>FAQs</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow className='pb-5'>
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <FAQTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5'>
            <h3>Billing and Payments</h3>
            <div id='submitreading' className='mt-5 mb-5'>
              <h5 className='policy-title'>I don’t understand my bill - what do all the sections mean?  </h5>
              <p className='policy-content'>
                <MDBRow className="py-5">
                  <MDBCol size="12">
                    <img
                      src={require("../../images/help/readbill1.png")}
                      className='mb-3 meterImg app-store'
                    />
                  </MDBCol>
                  <MDBCol size="12">
                    <b> 1. Account Number</b>
                    <p>
                      Your bright account number is unique to your account and you may be asked for it when you contact us.
                    <br />
                      <b>2. Invoice Number</b>
                      <br />
                   Each bright bill you receive will have an individual invoice number.
                   <br />
                      <b>3. Electricity emergencies</b>
                      <br />
                   Call ESB Networks on <a href="tel:1850 372 999">1850 372 999</a> to report an electricity emergency. Safety first - if you see any damaged electricity poles or wires DO NOT TOUCH or DO NOT GO NEAR.
                    <br />
                      <b>4. MPRN </b>
                      <br />
                    A Meter Point Reference Number (or MPRN) is a unique 11 digit number starting with 10 to identify your premises meter point address.
                   <br />
                      <b>5.DG / MCC / Profile </b>
                      <br />
                   This is used to identify your Meter Configuration and usage Profile.

                  DG identifies your Distribution Use of System (DUoS) profile - this is a charge that bright pays to companies who maintain the electricity network.

                  MCC is your Meter Configuration Code, which tells us what type of meter you have.

                  Profile determines what electricity tariff you pay - urban or rural.
                  <br />
                      <b>6. Bill Address </b>
                      <br />
                  The postal address you registered your bright account with. This is the property that your bill is sent to.
                  <br />
                      <b>7. Premises supplied </b>
                      <br />
                  The property that the electricity is being supplied to.

                </p>


                  </MDBCol>
                </MDBRow>

                <MDBRow className="py-5">
                  <MDBCol size="12">
                    <img
                      src={require("../../images/help/readbill2.png")}
                      className='mb-3 meterImg app-store'
                    />
                  </MDBCol>
                  <MDBCol>

                    <p>
                      <b>8. Billing Period</b>
                      <br />
                     The period for which your electricity usage is billed.
                     <br />
                      <b>9. Your energy bill summary  </b>
                      <br />
                     This shows the balance from your last bill, any recent payments, the charges for electricity this month and the amount due on the account.
                    <br />
                      <b>10. Action Required </b>
                      <br />
                    This shows you what action is required on your energy bill.







                   </p>
                  </MDBCol>
                </MDBRow>

                <MDBRow className="py-5">
                  <MDBCol size="12">
                    <img
                      src={require("../../images/help/readbill3.png")}
                      className='mb-3 meterImg app-store'
                    />
                  </MDBCol>
                  <MDBCol size="12">
                    <p>
                      <b>11. Tariff Details </b>
                      <br />
                    The name of your bright electricity tariff, payment method and unit rate and standing charge excluding VAT.
                    <br />
                      <b>12. Standing Charge</b>
                      <br />
                    This is a fixed charge for supplying your house with electricity and is charged on a daily basis whether you use electricity or not (for example if you are on holiday). Your standing charge may vary depending on whether your property is urban or rural.





                    </p>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol size="12">
                    <img
                      src={require("../../images/help/readbill4.png")}
                      className='mb-3 meterImg app-store'
                    />
                  </MDBCol>
                  <MDBCol size="12">

                    <p>
                      <b>13. Your electricity usage</b>
                      <br />
                      The electricity usage section outlines the meter readings used to calculate your bill.
                      <br />

                      Each reading corresponds to the below:
                      <br />
                      A – Actual read (provided by ESB Networks)  
                      <br/>
                      E – Estimated read
                      <br/>
                      C – Customer provided read
                      <br/>
                      Previous Reading  - This is your meter reading from the start of your billing period.  
                      <br/>
                      Present Reading - This is your meter reading at the end of your billing period. 
                      <br/>
                      Units - Amount of electricity you used during the billing period in kilowatts (kWh)
                      <br/>
                      <b>14. PSO Levy </b>
                      <br/>
                      The Public Service Obligation Levy, or PSO Levy, is a monthly charge, charged by all suppliers to domestic electricity customers.  It covers various subsidy schemes designed by the Irish Government to support its national policy objectives related to renewable energy and indigenous fuels for Ireland. 






                     




                    </p>
                    
                  </MDBCol>
                </MDBRow>
              </p>
            </div>

            {/* <div id='dontunderstandbill' className='mt-5 mb-5'>
              <h5 className='policy-title'>I don’t understand my bill - what do all the sections mean? </h5>
              <p className='policy-content'>
              <img src={('https://www.aluminati.net/wp-content/uploads/2016/03/img-placeholder.png')} />
              </p>
            </div> */}

            <div id='paymentreminder' className='mt-5 mb-5'>
              <h5 className='policy-title'>
                I received a payment reminder letter, but I’ve already paid.{" "}
              </h5>
              <p className='policy-content'>
                Your reminder letter may have been issued prior to your payment
                being received. If the date on the letter is before you made a
                payment you don’t need to do anything. You may wish to log in to
                your account and check your balance.
              </p>
            </div>

            <div id='billhigherthannormal' className='mb-5'>
              <h5 className='policy-title'>
                Why is my bill higher than normal?{" "}
              </h5>
              <p className='policy-content'>
                Your bill may be higher than normal due to: <br />
                <ul>
                  <li>
                    Your current or previous bill was based on an estimated
                    meter reading.{" "}
                  </li>
                  <li>
                    Comparing a summer to winter bill? In the winter, your bill
                    will be higher due to increased energy usage.{" "}
                  </li>
                  <li>The tariff changing since your last bill.</li>
                  <li>
                    Installation of any new appliances or increased use of your
                    current appliances.
                  </li>
                </ul>
              </p>
            </div>

            <div id='reducebill' className='mb-5'>
              <h5 className='policy-title'>
                How can I reduce my electricity bill?{" "}
              </h5>
              <p className='policy-content'>
                The best way to save money on energy is to use less. Our website
                provides lots of tips and information on energy efficiency and
                our trained energy specialists can provide even more advice!{" "}
                <a href={`/${this.state.jurisdiction}/about/ourelectricity`}>
                  Find out more here.
                </a>
              </p>
            </div>

            <div id='paymentoptions' className='mb-5'>
              <h5 className='policy-title'>
                What payment options are available with bright?{" "}
              </h5>
              <p className='policy-content'>
                <b> Monthly direct debit </b>
                <br />
                We will agree a monthly amount with you, based on actual or
                forecasted annual usage and collect that amount from you each
                month. We will review this amount regularly against your usage
                to ensure you are not over or under paying. If this date falls
                on a weekend or bank holiday it will be processed on the next
                banking day.
                <br />
                <br />
                <b> Direct Debit in arrears </b>
                <br />
                Every month we will send you a bill for your usage that month
                and we will collect the amount shown on the bill within 14 days
                from your account using the bank details you have provided to
                us.
                <br />
                <br />
                <b> Payment by card </b>
                <br />
                You can make a payment with a debit card through the app or ‘my
                account’ section of the website or by contacting our energy
                specialists on 01 588 1777.
              </p>
            </div>

            <div id='estimatedread' className='mb-5'>
              <h5 className='policy-title'>
                How do I know if my bill is based on an estimated meter read?
              </h5>
              <p className='policy-content'>
                The letter 'E' will be shown beside your meter reading in your
                electricity usage section of your bill. If you think the
                estimate is incorrect you can submit a meter reading and we will
                reissue your bill.
              </p>
            </div>

            <div id='viewbill' className='mb-5'>
              <h5 className='policy-title'>
                Can I view my bill in my bright account?{" "}
              </h5>
              <p className='policy-content'>
                Yes, you can view or download your current or previous bills via
                your customer account on the bright app or online. If you
                haven't registered your customer account be sure to register
                your account{" "}
                <a href={`/${this.state.jurisdiction}/account/register`}>
                  here.
                </a>{" "}
                Alternatively, you can get in contact with our energy
                specialists on <a href='tel:01 588 1777'>01 588 1777</a>.
              </p>
            </div>

            <div id='haventreceivedbill' className='mb-5'>
              <h5 className='policy-title'>I haven’t received my bill. </h5>
              <p className='policy-content'>
                You can view/download this from your{" "}
                <a href={`/${this.state.jurisdiction}/account/login`}>
                  online account
                </a>{" "}
                or give us a call on <a href='tel:01 588 1777'>01 588 1777</a>.
                <br />
                Please check we hold the correct email and postal address for
                you.
              </p>
            </div>

            

            <div id='nextbilldate' className='mb-5'>
              <h5 className='policy-title'>
                How can I check my next bill date?{" "}
              </h5>
              <p className='policy-content'>
                We will bill you on a monthly basis. To find the date your next
                bill is due view your online account or give us a call on{" "}
                <a href='tel:01 588 1777'>01 588 1777</a>.
              </p>
            </div>

            <div id='accountbalance' className='mb-5'>
              <h5 className='policy-title'>
                Where can I find my account balance?
              </h5>
              <p className='policy-content'>
                You can find this on your previous bill or from your online
                account or give us a call on{" "}
                <a href='tel:01 588 1777'>01 588 1777</a>.
              </p>
            </div>

            <div id='issuewithpayment' className='mb-5'>
              <h5 className='policy-title'>
                I have an issue with my payment- who should I contact?{" "}
              </h5>
              <p className='policy-content'>
                <b>Insufficient funds in bank account</b>
                <br />
                If you know you do not have enough funds to cover your direct
                debit, please give us a call on 01 588 1777 and we can discuss
                it with you.
                <br />
                <br />
                <b>Direct debit unsuccessful</b>
                <br />
                If we have tried to collect your direct debit unsuccessfully, we
                will contact you to discuss.
                <br />
                <br />
                <b>Card payment unsuccessful</b>
                <br />
                If you recently made a card payment on your bill and are
                concerned that we have not received it, please check your online
                account, or give us a call on 01 588 1777.
                <br />
                <br />
                <b>Missed a payment</b>
                <br />
                If you know you have missed a payment, please give us a call on
                01 588 1777 and we can discuss it with you. Otherwise we will
                write to you asking you to make payment.
                <br />
                <br />
                <b>Direct debit not taken</b>
                <br />
                If your direct debit was not taken on the due date this may be
                because the due date was a bank holiday and it will be collected
                on the next banking day. If your direct debit has not been taken
                at all, please give us a call on{" "}
                <a href='tel:01 588 1777'>01 588 1777</a>.
                <br />
                <br />
                <b>Reminder letter</b>
                <br />
                If we do not receive your payment by the bill due date, we will
                issue a reminder letter. If you receive a letter but have
                already paid this may be because your reminder letter was issued
                prior to your payment being received. If the date on the letter
                is before you made a payment you do not need to do anything. You
                may wish to log in to your account and check your balance.
              </p>
            </div>

            <div id='strugglingtopay' className='mb-5'>
              <h5 className='policy-title'>
                What options are available to me, if I’m struggling to pay my
                bill?
              </h5>
              <p className='policy-content'>
                If you are struggling to pay your bill then get in touch with us
                as soon as possible on 01 588 1777, the sooner you get in touch,
                the sooner we can help. Our energy specialists are trained to
                help, we will work with you to find a solution that suits your
                needs. We have a code of practice on billing and disconnection
                which sets out the help that we offer. Find out more{" "}
                <a href={`/${this.state.jurisdiction}/codesofpractice`}>here</a>
              </p>
            </div>

            <div id='psolevy' className='mb-5'>
              <h5 className='policy-title'>What is the PSO Levy? </h5>
              <p className='policy-content'>
                The Public Service Obligation Levy, or PSO Levy, is collected
                from all electricity customers in Ireland through their energy
                bills. It covers various subsidy schemes designed by the Irish
                Government to support its national policy objectives related to
                renewable energy and indigenous fuels for Ireland. The proceeds
                of the levy are used to contribute to the additional costs
                incurred by PSO-supported electricity generation which are not
                recovered in the electricity market, typically via contracts
                that suppliers have in place with electricity generators.{" "}
              </p>
            </div>

            <div id='standingcharge' className='mb-5'>
              <h5 className='policy-title'>
                What is a standing charge and why do I have to pay this?{" "}
              </h5>
              <p className='policy-content'>
                This is a fixed charge for supplying your house with electricity
                and is charged on a daily basis whether you use electricity or
                not (for example if you are on holiday). Your standing charge
                may vary depending on whether your property is urban or rural.
              </p>
            </div>

            <div id='estiamtedannualbill' className='mb-5'>
              <h5 className='policy-title'>
                What is an Estimated Annual Bill?
              </h5>
              <p className='policy-content'>
                The Estimated Annual Bill (EAB) is a simple indicator for
                customers to help them make decisions when comparing tariffs.
                <br />
                It calculates an annual bill based on typical consumption
                figures provided by the CRU and the charges associated with the
                tariff including the unit rate, standing charge, PSO levy and
                VAT.
                <br />
                This allows you to compare one tariff against another. Please
                note that your usage will vary from the CRU typical consumption
                figure. This is not an indication of how much your electricity
                will cost, but a useful comparison across tariffs.
              </p>
            </div>

            <div id='whattariff' className='mb-5'>
              <h5 className='policy-title'>What tariff am I on? </h5>
              <p className='policy-content'>
                At bright we offer one standard variable tariff - our bright
                future tariff. For details of your current unit rate visit the{" "}
                <a href={`/${this.state.jurisdiction}/about/ourtariff`}>
                  tariff
                </a>{" "}
                section of our website.
              </p>
            </div>

            <div id='nominatedperson' className='mb-5'>
              <h5 className='policy-title'>
                I need my bills to be sent to the nominated person on my
                account.
              </h5>
              <p className='policy-content'>
                We can set up a nominated person on your account to receive your
                bills and to chat to us about your account. We need your
                permission and the permission of the nominated person. Contact
                us on <a href='tel:01 588 1777'>01 588 1777</a> and we'll
                organise this for you.
              </p>
            </div>
            <div id='tariff-usage' className='mb-5'>
              <h5 className='policy-title'>
              When there is a tariff change how do you apportion my usage?
              </h5>
              <p className='policy-content'>
              When a tariff change occurs during the billing period we split your usage for the month between the old and new tariff, based on the number of days in the billing period before and after the tariff change takes effect.  
              For example, if your billing period is 1 November to 30 November and there is a tariff change on 16 November, we will apportion your usage based on 15 days at the existing tariff and 15 days at the new tariff.  Your bill will show you the usage which has been charged at the old rate and the new rate, and the number of days charged at the existing and new standing charge.
              If you have any queries about the apportionment on your bill please contact our energy specialists.

              </p>

              </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default FAQsBillingAndPayments;
