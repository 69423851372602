import React, { Component, dangerouslySetInnerHTML } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBContainer,
} from "mdbreact";
// jQuery
import $ from "jquery";

// Redux Addins
import { connect } from "react-redux";
import { blogActions } from "../../redux/actions";

class BlogPost extends React.Component {
  state = {
    jurisdiction: "",
  };
  // On Page Load
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    let urlParams = new URLSearchParams(window.location.search);
    let title = urlParams.get("title");

    this.props.getBlogPostTitle(title, juris);
  }
  render() {
    return (
      <div id='blogEntry' className='default-page home-page'>
        {/* Hero with background device */}
        <MDBRow className='small-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='12' lg='12'>
                {this.props.postData && this.props.postData.id != null ? (
                  <h1>{this.props.postData.title}</h1>
                ) : null}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBContainer className='my-5'>
          <MDBRow className='justify-content-center'>
            {this.props.postData && this.props.postData.id != null ? (
              <MDBCard style={{ width: "100%" }} id='blogPost'>
                <MDBCardImage
                  className='img-fluid'
                  src={this.props.postData.imageUrl}
                  waves
                />
                <MDBCardBody>
                  {/* <MDBCardTitle
                    dangerouslySetInnerHTML={{
                      __html: this.props.postData.title,
                    }}
                    className='mb-5'
                  /> */}
                  <MDBCardText
                    dangerouslySetInnerHTML={{
                      __html: this.props.postData.body,
                    }}
                  />
                  <MDBRow className='justify-content-center mx-5'>
                    <MDBBtn href={`/${this.state.jurisdiction}/blog`}>
                      Back
                    </MDBBtn>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            ) : null}
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    postData: store.blog.postData,
  };
};

const actionCreators = {
  getBlogPostTitle: blogActions.getBlogPostTitle,
};

export default connect(mapStateToProps, actionCreators)(BlogPost);
