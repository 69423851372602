import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBAlert,
  MDBIcon,
} from "mdbreact";
import $ from "jquery";
import LiveChat from "react-livechat";
import Loader from "../../../Shared/Loader";

// Redux
import { connect } from "react-redux";
import { switchActions } from "../../../../redux/actions/switchActions";
import { quoteActions } from "../../../../redux/actions/quoteActions";

// Direct debit tables
// Set direct debit
import SetUrban24hr from "./DirectDebitTables/SetDirectDebit/SetUrban24hr";
import SetRural24hr from "./DirectDebitTables/SetDirectDebit/SetRural24hr";
import SetUrbanNightSaver from "./DirectDebitTables/SetDirectDebit/SetUrbanNightSaver";
import SetRuralNightSaver from "./DirectDebitTables/SetDirectDebit/SetRuralNightSaver";
// Variable direct debit
import VariableUrban24hr from "./DirectDebitTables/VariableDirectDebit/VariableUrban24hr";
import VariableRural24hr from "./DirectDebitTables/VariableDirectDebit/VariableRural24hr";
import VariableUrbanNightSaver from "./DirectDebitTables/VariableDirectDebit/VariableUrbanNightSaver";
import VariableRuralNightSaver from "./DirectDebitTables/VariableDirectDebit/VariableRuralNightSaver";
// Pay on receipt
import ReceiptUrban24hr from "./DirectDebitTables/PayOnReceipt/ReceiptUrban24hr";
import ReceiptRural24hr from "./DirectDebitTables/PayOnReceipt/ReceiptRural24hr";
import ReceiptUrbanNightSaver from "./DirectDebitTables/PayOnReceipt/ReceiptUrbanNightSaver";
import ReceiptRuralNightSaver from "./DirectDebitTables/PayOnReceipt/ReceiptRuralNightSaver";
// Smart meter
import SmartMeter from "./DirectDebitTables/SmartMeter/SmartMeterTariff";

export class _FormPaymentDetails extends Component {
  state = {
    accountName: "",
    bankAccountNo: "",
    bankSortCode: "",
    billingMethod: "eBilling",
    iban: "",
    DDAmount: 0,
    DDDayOfMonth: 0,
    useAccAddress: true,
    confirmDDAuth: false,

    showError: false,
    switchGot: false,
    jurisdiction: "roi",
    ibanTip: false,

    collectionDate: "",

    // Handle billing address
    showbankAddress: false,
    bankAddressRequired: false,
    bankAddressLineOne: "",
    bankAddressLineTwo: "",
    bankAddressPostcode: "",
    bankAddressCountry: "Ireland",
    homeCheck: false,

    // Fields required
    required: true,

    smartMeter: false,
  };

  // Page Load
  componentDidMount() {
    // Get switch by id
    let switchID = localStorage.getItem("switchID");
    if (switchID != null) {
      this.props.getSwitch(switchID);
      this.setState({ switchGot: true });
    }

    // Get quote by id
    let quoteID = localStorage.getItem("quoteID");
    if (quoteID != null) {
      this.props.getQuote(quoteID);
    }
  }

  // If Props.SwitchData true populate form
  async componentWillReceiveProps(nextProps) {
    try {
      await this.setState({
        smartMeter: nextProps.journeyData.smartCustomer,
      });
    } catch {}

    let sectionUpdated = JSON.parse(localStorage.getItem("paymentUpdated"));

    if (this.state.switchGot && sectionUpdated) {
      await this.setState({
        accountName: nextProps.journeyData.accountName,
        bankAccountNo: nextProps.journeyData.bankAccountNo,
        bankSortCode: nextProps.journeyData.bankSortCode,
        billingMethod: nextProps.journeyData.billingMethod,
        iban: nextProps.journeyData.iban,
        confirmDDAuth: nextProps.journeyData.confirmDDAuth,
      });
    }

    if (nextProps.quoteData.paymentMethod == "PayOnReceipt") {
      this.setState({ required: false });
    }

    // API Loader
    if (nextProps.isLoading) {
      await this.setState({
        loading: true,
      });
    } else {
      await this.setState({
        loading: false,
      });
    }
  }

  // Handle Field Change
  handleChange = (input) => (e) => {
    var fieldValue = $("#" + input)[0].checkValidity();

    if (fieldValue) {
      $("#" + input)
        .addClass("input-valid")
        .removeClass("input-error");
    } else {
      $("#" + input)
        .addClass("input-error")
        .removeClass("input-valid");
    }

    this.setState({ [input]: e.target.value });
  };

  // Billing Method Checkbox Change
  billingMethod = (value) => {
    if (value == "eBilling") {
      this.setState({ billingMethod: "eBilling" });
    } else if (value == "Post") {
      this.setState({ billingMethod: "Post" });
    }
  };

  // Continue to next section
  nextStep = (e) => {
    // Prevent from from clearing
    e.preventDefault();

    // Validate Form
    let validation = $("#paymentDetails")[0].checkValidity();

    if (validation) {
      // Form Data
      const data = {
        // Step One - (Account Details)
        accountHolder: this.props.journeyData.accountHolder,
        title: this.props.journeyData.title,
        firstName: this.props.journeyData.firstName,
        surname: this.props.journeyData.surname,
        dob: this.props.journeyData.dob,
        email: this.props.journeyData.email,
        phone: this.props.journeyData.phone,
        postcode: this.props.journeyData.postcode,
        addrLine1: this.props.journeyData.addrLine1,
        addrLine2: this.props.journeyData.addrLine2,
        city: this.props.journeyData.city,
        county: this.props.journeyData.county,
        mprn: this.props.journeyData.mprn,
        // Smart meter
        smartCustomer: this.props.journeyData.smartCustomer,
        halfHourly: this.props.journeyData.halfHourly,
        // Account Correspondance
        accountCorrespondence: this.props.journeyData.accountCorrespondence,
        accCorrTitle: this.props.journeyData.accCorrTitle,
        accCorrFirstName: this.props.journeyData.accCorrFirstName,
        accCorrSurname: this.props.journeyData.accCorrSurname,
        accCorrName: this.props.journeyData.accCorrName,
        accCorrPostcode: this.props.journeyData.accCorrPostcode,
        accCorrAddLineOne: this.props.journeyData.accCorrAddLineOne,
        accCorrAddLineTwo: this.props.journeyData.accCorrAddLineTwo,
        accCorrCity: this.props.journeyData.accCorrCity,
        accCorrCounty: this.props.journeyData.accCorrCounty,
        accCorrEmail: this.props.journeyData.accCorrEmail,
        accCorrAddPhone: this.props.journeyData.accCorrAddPhone,
        // Joint Account
        jointPerson: this.props.journeyData.jointPerson,
        jointTitle: this.props.journeyData.jointTitle,
        jointFirstName: this.props.journeyData.jointFirstName,
        jointSurname: this.props.journeyData.jointSurname,
        jointEmail: this.props.journeyData.jointEmail,
        jointPhone: this.props.journeyData.jointPhone,
        // Marketing
        markEmail: this.props.journeyData.markEmail,
        markSMS: this.props.journeyData.markSMS,
        markPost: this.props.journeyData.markPost,
        markPhone: this.props.journeyData.markPost,

        // Step Two - Your Electricity
        newConnection: this.props.journeyData.newConnection,
        meterReading: this.props.journeyData.meterReading,
        nightMeterReading: this.props.journeyData.nightMeterReading,

        // Step Three - Additional Services
        serviceCheck: this.props.journeyData.serviceCheck,
        PDC: this.props.journeyData.PDC,
        specialServices: this.props.journeyData.specialServices,
        requiredSpecialServices: this.props.journeyData.requiredSpecialServices,
        medicalServices: this.props.journeyData.medicalServices,
        requiredMedicalServices: this.props.journeyData.requiredMedicalServices,
        priorityRegister: this.props.journeyData.priorityRegister,
        //nom person
        nomPerson: this.props.journeyData.nomPerson,
        nomCorrespondance: this.props.journeyData.nomCorrespondance,
        nomTitle: this.props.journeyData.nomTitle,
        nomFirstName: this.props.journeyData.nomFirstName,
        nomSurname: this.props.journeyData.nomSurname,
        nomEmail: this.props.journeyData.nomEmail,
        nomPhone: this.props.journeyData.nomPhone,
        nomPostcode: this.props.journeyData.nomPostcode,
        nomAddrLine1: this.props.journeyData.nomAddrLine1,
        nomAddrLine2: this.props.journeyData.nomAddrLine2,
        nomCity: this.props.journeyData.nomCity,
        nomCounty: this.props.journeyData.nomCounty,

        // Step Four (This Page) - Payment Details
        accountName: this.state.accountName,
        bankAccountNo: this.state.bankAccountNo,
        bankSortCode: this.state.bankSortCode,
        billingMethod: this.state.billingMethod,
        iban: this.state.iban,
        ddAmount: this.state.ddAmount,
        DDDayOfMonth: this.state.DDDayOfMonth,
        bankAddressLineOne: this.state.bankAddressLineOne,
        bankAddressLineTwo: this.state.bankAddressLineTwo,
        bankAddressPostcode: this.state.bankAddressPostcode,
        bankAddressCountry: this.state.bankAddressCountry,
        confirmDDAuth: this.state.confirmDDAuth,

        // Step Five - Switch Summary
        agreeToTerms: this.props.journeyData.agreeToTerms,
        agreeToPrivacyPolicy: this.props.journeyData.agreeToPrivacyPolicy,
        switchCompleted: false,
      };

      // Hide Error Message (if visiable)
      this.setState({
        errorMessage: false,
      });

      // SwitchID
      let switchID = localStorage.getItem("switchID");

      // This Page (updates page status)
      let pageStatus = "paymentUpdated";

      // Next Page URL
      let nextPage = "summary";

      // Jurisdiction
      let jurisdiction = this.state.jurisdiction;

      // Update Database Entry
      this.props.updateSwitch(
        switchID,
        data,
        pageStatus,
        nextPage,
        "",
        jurisdiction
      );
    } else {
      // For each form input that has the required prop add the input-error class
      $("form#paymentDetails")
        .find("input")
        .each(function () {
          if ($(this).prop("required")) {
            $(this).addClass("input-error");
          }
        });

      // Display the error message
      this.setState({
        errorMessage: true,
      });

      // Scroll to error
      window.location.href = "#errorMessage";
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  // Show correct direct debit table
  directDebitTable = (urbanRural, meterType, paymentMethod, smartTariff) => {
    // Create case for switch
    let ddTable = urbanRural + " " + meterType + " " + paymentMethod;

    if (smartTariff) {
      return <SmartMeter />;
    } else {
      switch (ddTable) {
        // Set direct debit
        case "Urban 24hr SetDirectDebit":
          return <SetUrban24hr />;
          break;
        case "Rural 24hr SetDirectDebit":
          return <SetRural24hr />;
          break;
        case "Urban NightSaver SetDirectDebit":
          return <SetUrbanNightSaver />;
          break;
        case "Rural NightSaver SetDirectDebit":
          return <SetRuralNightSaver />;
          break;

        // Variable direct debit
        case "Urban 24hr VariableDirectDebit":
          return <VariableUrban24hr />;
          break;
        case "Rural 24hr VariableDirectDebit":
          return <VariableRural24hr />;
          break;
        case "Urban NightSaver VariableDirectDebit":
          return <VariableUrbanNightSaver />;
          break;
        case "Rural NightSaver VariableDirectDebit":
          return <VariableRuralNightSaver />;
          break;

        // Pay on receipt
        case "Urban 24hr PayOnReceipt":
          return <ReceiptUrban24hr />;
          break;
        case "Rural 24hr PayOnReceipt":
          return <ReceiptRural24hr />;
          break;
        case "Urban NightSaver PayOnReceipt":
          return <ReceiptUrbanNightSaver />;
          break;
        case "Rural NightSaver PayOnReceipt":
          return <ReceiptRuralNightSaver />;
          break;

        default:
          break;
      }
    }
  };

  render() {
    return (
      <div className="quote-page switch-page">
        {/* API Loader */}
        {this.state.loading ? <Loader /> : null}

        <form id="paymentDetails">
          {/* Background Image */}
          <div className="device-image" />
          <div className="quote-content-container">
            {/* Logo */}
            <MDBRow className="text-center">
              <MDBCol lg="2" sm="12">
                <a onClick={() => this.setState({ homeCheck: true })}>
                  <img
                    src={require("../../../../images/global/bright-logo-dark.png")}
                    className="quote-logo mx-5 mt-5"
                  />
                </a>
              </MDBCol>
              <MDBCol size="10" />
            </MDBRow>

            {/* Home message */}
            {this.state.homeCheck ? (
              <div className="modalBG">
                <div className="modalBox">
                  <div className="white-curved-tile text-center">
                    <h4 className="mt-5">
                      All progress will be lost! <br />
                      Are you sure?
                    </h4>
                    <MDBRow>
                      <MDBCol lg="6" md="6" sm="12">
                        <MDBBtn
                          className="cancel-button"
                          onClick={() =>
                            this.setState({ homeCheck: !this.state.homeCheck })
                          }
                        >
                          Cancel
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol lg="6" md="6" sm="12">
                        <MDBBtn onClick={() => (window.location.href = "/")}>
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
            ) : null}

            <MDBContainer className="personal-details-section">
              <MDBRow>
                {/* Progress Bar */}
                <MDBCol size="12">
                  <MDBContainer className="personal-details-section">
                    <MDBRow>
                      <MDBCol md="12" className="switch-navigation-container">
                        <div className="border-light">
                          <img
                            className="brand-image show-for-medium"
                            src={require("../../../../images/quote/quote-nav-5.png")}
                          />
                          <img
                            className="brand-image hide-for-medium"
                            src={require("../../../../images/quote/progress-bar-5.png")}
                          />
                        </div>
                      </MDBCol>

                      {/* Error Message */}
                      <MDBCol size="12" id="errorMessage">
                        {this.state.errorMessage == true ? (
                          <MDBAlert color="danger" className="mb-5" dismiss>
                            <strong>Oops!</strong> Please check your fields
                            below.
                          </MDBAlert>
                        ) : null}
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </MDBCol>

                <MDBRow>
                  <MDBCol md="12" lg="8">
                    {/* Direct debit table */}
                    <div className="text-left white-curved-tile">
                      {this.directDebitTable(
                        this.props.quoteData.urbanRural,
                        this.props.quoteData.meterType,
                        this.props.quoteData.paymentMethod,
                        this.state.smartMeter
                      )}
                    </div>

                    {this.props.quoteData.paymentMethod != "PayOnReceipt" ? (
                      <div className="text-left white-curved-tile">
                        {/* Payment Details */}
                        <MDBRow className="mb-2">
                          <MDBCol lg="12" className="text-left p-0">
                            <label className="input-label">
                              Account holder's name
                            </label>
                            {/* Account Holder Name */}
                            <MDBInput
                              id="accountName"
                              pattern="^[A-Z a-z]{1,60}$"
                              label="Account Holders Name"
                              outline
                              onChange={this.handleChange("accountName")}
                              required={this.state.required}
                              value={this.state.accountName}
                            />

                            {/* Address Check */}
                            <label for="useAccAddress" className="input-label">
                              Would you like to use your account address?
                            </label>
                            <div className="radio-container">
                              {/* Yes */}
                              <input
                                id="useAccAddressYes"
                                name="useAccAddress"
                                type="radio"
                                onClick={() =>
                                  this.setState({
                                    useAccAddress: true,
                                    showbankAddress: false,
                                    bankAddressRequired: false,
                                  })
                                }
                                required={this.state.required}
                                className="default-radio"
                                checked={
                                  this.state.useAccAddress ? true : false
                                }
                              />
                              <label for="useAccAddressYes">Yes</label>
                              {/* No */}
                              <input
                                id="useAccAddressNo"
                                name="useAccAddress"
                                type="radio"
                                onClick={() =>
                                  this.setState({
                                    useAccAddress: false,
                                    showbankAddress: true,
                                    bankAddressRequired: true,
                                  })
                                }
                                required={this.state.required}
                                className="default-radio"
                                checked={
                                  this.state.useAccAddress === false
                                    ? true
                                    : false
                                }
                              />
                              <label for="useAccAddressNo">No</label>
                            </div>

                            {/* Show if useAccAddress = false */}
                            {this.state.showbankAddress ? (
                              <div>
                                {/* Address */}
                                <label className="input-label">Address</label>
                                {/* bank address line one */}
                                <MDBInput
                                  id="bankAddressLineOne"
                                  pattern="^[A-Z a-z 0-9]{1,32}$"
                                  label="Address line one"
                                  outline
                                  onChange={this.handleChange(
                                    "bankAddressLineOne"
                                  )}
                                  required={this.state.bankAddressRequired}
                                  value={this.state.bankAddressLineOne}
                                />
                                {/* bank address line two */}
                                <MDBInput
                                  id="bankAddressLineTwo"
                                  pattern="^[A-Z a-z 0-9]{1,32}$"
                                  label="Address line two"
                                  outline
                                  onChange={this.handleChange(
                                    "bankAddressLineTwo"
                                  )}
                                  value={this.state.bankAddressLineTwo}
                                />
                                {/* bank city/postcode */}
                                <MDBInput
                                  id="bankAddressPostcode"
                                  label="City/Eircode"
                                  outline
                                  onChange={this.handleChange(
                                    "bankAddressPostcode"
                                  )}
                                  required={this.state.bankAddressRequired}
                                  value={this.state.bankAddressPostcode}
                                  className="field-to-uppercase"
                                  pattern="^[A-Z a-z 0-9]{1,9}$"
                                />
                              </div>
                            ) : null}

                            {/* iban */}
                            <label className="input-label">
                              IBAN
                              <MDBBtn className="tooltip-container">
                                <MDBIcon
                                  icon="info-circle"
                                  className="mr-2"
                                  onClick={() =>
                                    this.setState({
                                      ibanTip: !this.state.ibanTip,
                                    })
                                  }
                                />
                                {this.state.ibanTip == true ? (
                                  <span className="tooltip-content">
                                    <img
                                      className="brand-image"
                                      src={require("../../../../images/global/bullet-large.png")}
                                    />
                                    <p>
                                      <br />
                                      Need help calculating your IBAN?{" "}
                                      <a
                                        href="https://www.iban.com/calculate-iban"
                                        target="_blank"
                                        className="brightGreen-text"
                                      >
                                        Click here
                                      </a>
                                    </p>
                                  </span>
                                ) : null}
                              </MDBBtn>
                            </label>
                            <MDBInput
                              id="iban"
                              label="IBAN"
                              outline
                              onChange={this.handleChange("iban")}
                              required={this.state.required}
                              value={this.state.iban}
                              pattern="([0-9a-zA-Z]?){31}$"
                              // OLD pattern='^IE([0-9a-zA-Z]\s?){20}$'
                              className="field-to-uppercase"
                            />

                            {/* Sort Code */}
                            <label className="input-label">Swift BIC</label>
                            <MDBInput
                              id="bankSortCode"
                              label="Swift BIC"
                              outline
                              onChange={this.handleChange("bankSortCode")}
                              required={this.state.required}
                              value={this.state.bankSortCode}
                              pattern="^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$"
                            />

                            {/* DD Confirmation */}
                            <MDBRow>
                              <label className="input-label">
                                Direct debit confirmation
                              </label>
                              <div className="mt-3 ml-3">
                                <input
                                  className="mt-1"
                                  id="ddconfirmation"
                                  name="ddconfirmation"
                                  type="checkbox"
                                  onClick={() =>
                                    this.setState({
                                      confirmDDAuth: !this.state.confirmDDAuth,
                                    })
                                  }
                                  required={this.state.required}
                                  checked={
                                    this.state.confirmDDAuth ? true : false
                                  }
                                />
                                <label for="ddconfirmation" className="ml-2">
                                  Confirm you are authorised to make direct
                                  debit payments on this account
                                </label>
                              </div>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>

                        <p className="input-label">
                          Our mission is to make the future brighter by the use
                          of 100% green electricity. To go that one step further
                          on our sustainability mission, we opt for e-billing.
                        </p>

                        <p className="input-label">
                          If you would like to receive your bill by post please
                          contact us at: hello@brightenergy.com
                        </p>
                      </div>
                    ) : null}

                    {/* Buttons */}
                    <MDBRow size="12">
                      {/* Back */}
                      <MDBCol size="6">
                        <h5>
                          Previous Step: <span>Additional Services</span>
                        </h5>
                        <a href={`/${this.state.jurisdiction}/switch/services`}>
                          <MDBBtn className="btn switch-button">Back</MDBBtn>
                        </a>
                      </MDBCol>
                      {/* Next */}
                      <MDBCol size="6">
                        <h5>
                          Next Step: <span>Switch Summary</span>
                        </h5>
                        <MDBBtn className="btn" onClick={this.nextStep}>
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>

                  {/* Right Column */}
                  <MDBCol size="4" className="show-for-large">
                    <img
                      className="brand-image"
                      src={require("../../../../images/switch/switch-tile.png")}
                    />

                    {/* bright legal info */}
                    {this.props.quoteData.paymentMethod != "PayOnReceipt" ? (
                      <div className="text-left white-curved-tile mt-5">
                        <p className="input-label ml-2">
                          Creditor identifier: IE84ZZZ362173 <br />
                          Company name: Ever Energy Supply Limited t/a bright
                          <br />
                          Address: 3 Custom House Plaza, IFSC Dublin, D01 VY76
                        </p>

                        {/* hr */}
                        <hr className="switch-payment-hr ml-2" />

                        {/* DD legal info */}
                        <p className="input-label ml-2">
                          By signing this mandate form, you authorise (A) Ever
                          Energy Supply Limited t/a bright to send instructions
                          to your bank to debit your account and (B) your bank
                          to debit your account in accordance with the
                          instruction from Ever Energy Supply Limited t/a
                          bright. As part of your rights, you are entitled to a
                          refund from your bank under the terms and conditions
                          of your agreement with your bank. A refund must be
                          claimed within 8 weeks starting from the date on which
                          your account was debited. Your rights are explained in
                          a statement that you can obtain from your bank.
                        </p>
                      </div>
                    ) : null}

                    {/* Chat functionality */}
                    <div className="white-curved-tile text-left mt-3">
                      <h5 className="mt-4 text-center">Need some help?</h5>
                      {/* Live chat */}
                      <div className="switch-live-chat">
                        <div data-id="VVUGGBG3WTc" class="livechat_button">
                          <a href="https://www.livechat.com/utm-builder/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_11999496">
                            UTM Builder
                          </a>
                        </div>
                        <LiveChat license={11999496} />
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBRow>
            </MDBContainer>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.switch.isLoading,
    journeyData: store.switch.switchData,
    quoteData: store.quote.quoteData,
  };
};

const actionCreators = {
  getSwitch: switchActions.getSwitchJourney,
  updateSwitch: switchActions.updateSwitchJourney,
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(_FormPaymentDetails);
