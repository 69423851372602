export const meterReadConstants = {
  // Submit Reading
  METER_READ_REQUEST: "METER_READ_REQUEST",
  METER_READ_SUCCESS: "METER_READ_SUCCESS",
  METER_READ_FAILURE: "METER_READ_FAILURE",

  // Submit LANDLORD Reading
  LANDLORD_METER_READ_REQUEST: "LANDLORD_METER_READ_REQUEST",
  LANDLORD_METER_READ_SUCCESS: "LANDLORD_METER_READ_SUCCESS",
  LANDLORD_METER_READ_FAILURE: "LANDLORD_METER_READ_FAILURE",

  // Get Reading
  GET_METER_READ_REQUEST: "GET_METER_READ_REQUEST",
  GET_METER_READ_SUCCESS: "GET_METER_READ_SUCCESS",
  GET_METER_READ_FAILURE: "GET_METER_READ_FAILURE",

  // Get Meter Structure
  GET_METER_STRUCTURE_REQUEST: "GET_METER_STRUCTURE_REQUEST",
  GET_METER_STRUCTURE_SUCCESS: "GET_METER_STRUCTURE_SUCCESS",
  GET_METER_STRUCTURE_FAILURE: "GET_METER_STRUCTUREFAILURE",
};
