import React from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon } from "mdbreact";

const UserLoginDD = () => {

  
  return (
    <MDBDropdown className="country-dropdown">
      <MDBDropdownToggle className="signIn">
          <p>REPUBLIC OF IRELAND</p>
          <i className="fa fa-caret-down"></i>
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        <MDBDropdownItem><a>REPUBLIC OF IRELAND</a></MDBDropdownItem>
        <MDBDropdownItem><a href="/ni">NORTHERN IRELAND</a></MDBDropdownItem>
      </MDBDropdownMenu>
    </MDBDropdown>
  );
}

export default UserLoginDD;
