import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from "mdbreact";
import { connect } from "react-redux";
import { quoteActions } from "../../../../../redux/actions/quoteActions";
const LoadConfig = require("../../../../../config");
const config = LoadConfig.default;

export class RuralNightSaverPopup extends Component {
  state = {
    jurisdiction: "roi",
  };


  render() {
    let annualCalc = ((config.rur_ns_dayunitrate * (this.props.quoteData.electricityUsage/2))
    +(config.rur_ns_nightunitrate * (this.props.quoteData.electricityUsage/2))
    +(config.rur_ns_standing)
    +(config.rur_ns_pso)).toFixed(0);
    return (
      <MDBTable>
        <MDBTableBody>
          <tr>
            <td>Day Unit rate {(config.NSDay).toFixed(3)} (c per kWh) *{" "}
              {this.props.quoteData.electricityUsage/2} kWh </td>
            <td>€{(config.rur_ns_dayunitrate * (this.props.quoteData.electricityUsage/2)).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Night Unit rate {(config.NSNight).toFixed(3)} (c per kWh) *{" "}
              {this.props.quoteData.electricityUsage/2} kWh </td>
            <td>€{(config.rur_ns_nightunitrate * (this.props.quoteData.electricityUsage/2)).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Standing charge {(config.ruralStandingChargeCentPerDay).toFixed(2)} (c per day) * 365 days </td>
            <td>€{(config.rur_ns_standing)}</td>
          </tr>
          <tr>
            <td>PSO levy {(config.monthlyPsoLevy).toFixed(2)} (€ per month) * 12 months </td>
            <td>€{(config.rur_ns_pso).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Projected annual cost </td>
            {/* <td>€{parseInt(this.props.quoteData.monthlyPayment * 12).toFixed(2)}</td> */}
            <td>€{annualCalc}</td>
          </tr>
          <tr>
            <td>Projected monthly cost </td>
            <td>€{parseInt(this.props.quoteData.monthlyPayment).toFixed(0)}</td>
          </tr>
        </MDBTableBody>
      </MDBTable>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    quoteData: store.quote.quoteData,
  };
};

const actionCreators = {
  getQuote: quoteActions.getQuoteJourney,
};

export default connect(mapStateToProps, actionCreators)(RuralNightSaverPopup);
