import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBContainer,
} from "mdbreact";

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      collapseID: "",
      value: 0,
      jurisdiction: "",
    };

    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
      condition: !this.state.condition,
    });
  }

  toggleSingleCollapse = (collapseId) => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId],
    });
  };

  render() {
    return (
      //the dark-header class toggles the dark version of the navigation
      
<div>
{this.state.jurisdiction == "ni" ? (
<div>
        <MDBNavbar
          id='custom-nav-expand'
          className='mainMenu m-0 navbar-expand-lg navbar-light scrolling-navbar'
          scrolling
          fixed='top'
        >
          <MDBContainer>
            <MDBNavbarBrand href={`/${this.state.jurisdiction}`}>
              {/* <img
                className='header-logo-icon'
                src={require("../../images/global/bright-logo.gif")}
              /> */}
              {/* Static logo */}
              <div className='header-logo-icon'></div>
              <div className='header-logo-text'></div>
            </MDBNavbarBrand>
            <MDBNavbarToggler
              onClick={this.onClick}
              className={this.state.condition ? "active" : "not-active"}
            >
              <span></span>
              <span></span>
              <span></span>
            </MDBNavbarToggler>
            <MDBCollapse isOpen={this.state.collapse} navbar='navbar'>
              <MDBNavbarNav
                id='custom-nav-bar-sticky'
                right='right'
                className='display-8'
              >
                {/* <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret>
                      <span className='mr-2'>About</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem className='menu-link'>
                        <a
                          href={`/${this.state.jurisdiction}/about/ourmission`}
                        >
                          <img
                            src={require("../../images/header/our-energy-icon.png")}
                          />{" "}
                          Our Mission
                        </a>
                      </MDBDropdownItem>
                      <MDBDropdownItem className='menu-link'>
                        <a
                          href={`/${this.state.jurisdiction}/about/ourelectricity`}
                        >
                          <img
                            src={require("../../images/header/our-mission-icon.png")}
                          />{" "}
                          Our Electricity
                        </a>
                      </MDBDropdownItem>
                      <MDBDropdownItem className='menu-link'>
                        <a href={`/${this.state.jurisdiction}/about/ourpeople`}>
                          <img
                            src={require("../../images/header/our-people-icon.png")}
                          />{" "}
                          Our People
                        </a>
                      </MDBDropdownItem>
    
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem> */}

                {/* <MDBNavItem>

                  <a
                    class='nav-link Ripple-parent'
                    data-test='nav-link'
                    href={`/${this.state.jurisdiction}/about/ourtariff`}
                  >
                    Our Tariff<div data-test='waves' class='Ripple '></div>
                  </a>
                </MDBNavItem> */}
                {/* <MDBNavItem>

                  <a
                    class='nav-link Ripple-parent'
                    data-test='nav-link'
                    href={`/${this.state.jurisdiction}/help`}
                  >
                    Help<div data-test='waves' class='Ripple '></div>
                  </a>
                </MDBNavItem> */}
                <MDBNavItem>

                  <a
                    class='nav-link Ripple-parent'
                    data-test='nav-link'
                    href={`/${this.state.jurisdiction}/account/login`}
                  >
                    Bright Account<div data-test='waves' class='Ripple '></div>
                  </a>
                </MDBNavItem>
                {/* <MDBNavItem>
                  <a
                    class='nav-link Ripple-parent'
                    data-test='nav-link'
                    href={`/ni/keypadtopup`}
                  >
                    Keypad top-up<div data-test='waves' class='Ripple '></div>
                  </a>
                </MDBNavItem> */}
                </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      </div>
):
<div>
        <MDBNavbar
          id='custom-nav-expand'
          className='mainMenu m-0 navbar-expand-lg navbar-light scrolling-navbar'
          scrolling
          fixed='top'
        >
          <MDBContainer>
            <MDBNavbarBrand href={`/${this.state.jurisdiction}`}>
              {/* <img
                className='header-logo-icon'
                src={require("../../images/global/bright-logo.gif")}
              /> */}
              {/* Static logo */}
              <div className='header-logo-icon'></div>
              <div className='header-logo-text'></div>
            </MDBNavbarBrand>
            <MDBNavbarToggler
              onClick={this.onClick}
              className={this.state.condition ? "active" : "not-active"}
            >
              <span></span>
              <span></span>
              <span></span>
            </MDBNavbarToggler>
            <MDBCollapse isOpen={this.state.collapse} navbar='navbar'>
              <MDBNavbarNav
                id='custom-nav-bar-sticky'
                right='right'
                className='display-8'
              >
              
                <MDBNavItem>

                  <a
                    class='nav-link Ripple-parent'
                    data-test='nav-link'
                    href={`/${this.state.jurisdiction}/account/login`}
                  >
                    Bright Account<div data-test='waves' class='Ripple '></div>
                  </a>
                </MDBNavItem>
              
                </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      </div>

}


                
</div>
             
    );
  }
}

export default MainMenu;
