import { paymentConstants } from "../constants";

let defaultState = {
  paymentAmount: 5,
  paymentData: null,
  sessionID: "",
  error: "",
  isLoading: false,
};

const paymentReducer = (state = defaultState, action) => {
  switch (action.type) {
    // Increment
    case paymentConstants.INCREMENT_TOPUP:
      return {
        ...state,
        paymentAmount: state.paymentAmount + action.payload,
      };
    // Decrement
    case paymentConstants.DECCREMENT_TOPUP:
      return {
        ...state,
        paymentAmount: state.paymentAmount - action.payload,
      };

    // Create payment session
    case paymentConstants.CREATE_PAYMENTSESSION_REQUEST:
      return {
        ...state,
      };

    case paymentConstants.CREATE_PAYMENTSESSION_SUCCESS:
      return {
        ...state,
        paymentData: action.payload,
        sessionID: action.payload.id,
      };

    case paymentConstants.CREATE_PAYMENTSESSION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    // Get Payment History
    case paymentConstants.GET_PAYMENTHISTORY_REQUEST:
      return {
        ...state,
      };

    case paymentConstants.GET_PAYMENTHISTORY_SUCCESS:
      return {
        ...state,
        paymentData: action.payload,
      };

    case paymentConstants.GET_PAYMENTHISTORY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    // Save payment
    case paymentConstants.SAVE_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case paymentConstants.SAVE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case paymentConstants.SAVE_PAYMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    // Default
    default:
      return state;
  }
};

export default paymentReducer;
