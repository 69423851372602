import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import RegisterForm from "./RegisterForm.js";
import FixedNavDark from "../../../components/Shared/FixedNavDark";
import $ from "jquery";


class Register extends React.Component {
  
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.help);
  }

  help =() =>{
    if (window.pageYOffset <50)
    {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='login-page'>
        {/* <FixedNavDark/> */}

        <MDBContainer className='switch-today-container'>
          <MDBRow className='brightTeal-text'>
            <MDBCol md='3' lg='3'></MDBCol>
            <MDBCol sm='6' md='8' lg='6'>
              <div>
                <h1>Register</h1>
                <p>Register your account today, it's a breeze.</p>
                <RegisterForm />
              </div>
            </MDBCol>
            <MDBCol size='3'></MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

export default Register;
