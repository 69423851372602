import React from "react";
import ROIQuoteMeter from "../../../components/Forms/Quote/ROI/_FormMeter";

class QuoteMeter extends React.Component {
  state = {
    jurisdiction: "roi",
  };

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  render() {
    return (
      <div>
        <ROIQuoteMeter />
      </div>
    );
  }
}

export default QuoteMeter;
