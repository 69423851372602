import React from "react";
import { MDBCol, MDBListGroup, MDBListGroupItem } from "mdbreact";

class CorrespondanceDetails extends React.Component {
  render() {
    return (
      <MDBCol size='12'>
        {/* Title */}
        <MDBCol className='mb-4'>
          <h5>Correspondance Details</h5>
        </MDBCol>

        {/* Correspondance Details */}
        <MDBListGroup className='ml-3'>
          {/* Name */}
          <MDBListGroupItem className='pl-3 d-flex justify-content-between align-items-center'>
            Name
            <div className='d-flex align-items-center'>
              <p className='mr-5'>John Doe</p>
              {/* <i class='fas fa-chevron-right' /> */}
            </div>
          </MDBListGroupItem>

          {/* Address */}
          <MDBListGroupItem className='pl-3 d-flex justify-content-between align-items-center'>
            Address
            <div className='d-flex align-items-center'>
              <p className='mr-5'>01 Electric Avenue</p>
            </div>
          </MDBListGroupItem>

          {/* MPRN */}
          <MDBListGroupItem className='pl-3 d-flex justify-content-between align-items-center'>
            MPRN
            <div className='d-flex align-items-center'>
              <p className='mr-5'>12345678901</p>
            </div>
          </MDBListGroupItem>
        </MDBListGroup>
      </MDBCol>
    );
  }
}

export default CorrespondanceDetails;
