import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBContainer,
} from "mdbreact";

class MainMenuDark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      collapseID: "",
      value: 0,
      darkNav: false,
      jurisdiction: "",
    };

    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });
  }

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
      condition: !this.state.condition,
    });
  }

  toggleSingleCollapse = (collapseId) => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId],
    });
  };

  render() {
    return (
      <div>
        <MDBNavbar
          id='custom-nav-expanddark'
          className='mainMenu m-0 navbar-expand-lg dark-header navbar-light scrolling-navbar'
          scrolling
          fixed='top'
        >
          <MDBContainer>
            <MDBNavbarBrand href='/'>
              {/* <img
                className='header-logo-icon'
                src={require("../../images/global/bright-logo.gif")}
              /> */}
              {/* Static logo */}
              <div className='header-logo-icon'></div>
              <div className='header-logo-text'></div>
            </MDBNavbarBrand>
            <MDBNavbarToggler
              onClick={this.onClick}
              className={this.state.condition ? "active" : "not-active"}
            >
              <span></span>
              <span></span>
              <span></span>
            </MDBNavbarToggler>
            <MDBCollapse isOpen={this.state.collapse} navbar='navbar'>
              <MDBNavbarNav
                id='custom-nav-bar-sticky'
                right='right'
                className='display-8'
              >
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret>
                      <span className='mr-2'>About</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem className='menu-link'>
                        <a
                          href={`/${this.state.jurisdiction}/about/ourmission`}
                        >
                          <img
                            src={require("../../images/header/our-energy-icon.png")}
                          />{" "}
                          Our Mission
                        </a>
                      </MDBDropdownItem>
                      <MDBDropdownItem className='menu-link'>
                        <a
                          href={`/${this.state.jurisdiction}/about/ourelectricity`}
                        >
                          <img
                            src={require("../../images/header/our-mission-icon.png")}
                          />{" "}
                          Our Electricity
                        </a>
                      </MDBDropdownItem>
                      <MDBDropdownItem className='menu-link'>
                        <a href={`/${this.state.jurisdiction}/about/ourpeople`}>
                          <img
                            src={require("../../images/header/our-people-icon.png")}
                          />{" "}
                          Our People
                        </a>
                      </MDBDropdownItem>
                      {/* <MDBDropdownItem className='menu-link'>
                        <a href={`/${this.state.jurisdiction}/about/contactus`}>
                          <img
                            src={require("../../images/header/contact-us-icon.png")}
                          />{" "}
                          Contact Us
                        </a>
                      </MDBDropdownItem> */}
                      {/* Page Templates */}
                      {/* <MDBDropdownItem className='menu-link'>
                        <a href={`/${this.state.jurisdiction}/about/template`}>
                          <img
                            src={require("../../images/header/contact-us-icon.png")}
                          />{" "}
                          Template
                        </a>
                      </MDBDropdownItem> */}
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    to={`/${this.state.jurisdiction}/about/switchingtobright`}
                  >
                    Switching
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    to={`/${this.state.jurisdiction}/about/ourtariff`}
                  >
                    Our Tariff
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to={`/${this.state.jurisdiction}/help`}>
                    Help
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to={`/${this.state.jurisdiction}/account/login`}>
                    Bright Account
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    to={`/${this.state.jurisdiction}/quote`}
                    className='btn'
                  >
                    Get a quote
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      </div>
    );
  }
}

export default MainMenuDark;
