import React from "react";
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
} from "mdbreact";
// jQuery
import $ from "jquery";

// Redux Addins
import { connect } from "react-redux";
import { blogActions } from "../../redux/actions";

// Dynamic Components
import DynamicComponents from "../../components/DynamicComponents.js";

class Blog extends React.Component {
  state = {
    jurisdiction: "roi",
  };

  // On Page Load
  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
      });
    this.props.getAllBlogPosts(juris);
  }
  render() {
    return (
      <div className='default-page home-page'>
        {/* Hero with background device */}
        <MDBRow className='small-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='12' lg='12'>
                <h1>Our blog</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBContainer className='my-5'>
          <MDBRow className='justify-content-center'>
            {this.props.postData && this.props.postData[0] != null
              ? this.props.postData.map((post) => (
                  <MDBCard style={{ width: "22rem" }}>
                    {/* Image */}
                    <MDBCardImage
                      src={post.imageUrl}
                      waves
                      style={{ height: "15rem" }}
                    />
                    <MDBCardBody>
                      {/* Title */}
                      <MDBCardTitle className='mb-2'>{post.title}</MDBCardTitle>
                      {/* Summary */}
                      <MDBCardText>
                        {post.summary.slice(0, 150)}....
                      </MDBCardText>
                      <MDBRow className='justify-content-center mx-5'>
                        <MDBBtn
                          href={
                            `/${this.state.jurisdiction}/blog/post?title=` +
                            post.title.replace(/ /g, "-")
                          }
                        >
                          Read more
                        </MDBBtn>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                ))
              : null}
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    postData: store.blog.postData,
  };
};

const actionCreators = {
  getAllBlogPosts: blogActions.getAll,
};

export default connect(mapStateToProps, actionCreators)(Blog);
