import { additionalServicesService } from "../services/additionalServicesService";
import { additionalServicesConstants } from "../constants/additionalServicesConstants";

export const additionalServicesActions = {
    addAdditionalServices
};

// Add Additional Services
function addAdditionalServices(details) {
  return (dispatch) => {
    dispatch(addAdditionalServicesRequest());

    additionalServicesService.addAdditionalServices(details).then(
      (response) => {
        dispatch(addAdditionalServicesSuccess(response.data));
      },
      (error) => {
        dispatch(addAdditionalServicesFailure("Error"));
      }
    );
  };
}

function addAdditionalServicesRequest() {
  return {
    type: additionalServicesConstants.ADD_ADDITIONAL_SERVICES_REQUEST,
  };
}
function addAdditionalServicesSuccess(data) {
  return {
    type: additionalServicesConstants.ADD_ADDITIONAL_SERVICES_SUCCESS,
    payload: data,
  };
}
function addAdditionalServicesFailure(error) {
  return {
    type: additionalServicesConstants.ADD_ADDITIONAL_SERVICES_FAILURE,
    payload: error,
  };
}