import React, { Component } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import Swiper from "react-id-swiper";

class OnAMission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split('/');
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris
    });
  }

  render() {
    return (
      <MDBRow className='large-padding quote-section grey-background onmission-bg'>
        <MDBCol size='1'></MDBCol>
        <MDBCol size='10'>
          <MDBRow className="mission-component">
            <MDBCol sm='12' md='6' lg='6'>
              <h4>We're on a mission to make green energy fair for all.</h4>
              <p className='mb-5'>
              Through strength in numbers, we can make a difference. By joining bright you can be part of real change.
              </p>
            </MDBCol>
            <MDBCol></MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm='12' md='8' lg='6' className='no-padding'>
              <a href={`/${this.state.jurisdiction}/quote`} className='btn w-50'>
                GET A QUOTE
              </a>
            </MDBCol>
            <MDBCol></MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size='1'></MDBCol>
      </MDBRow>
    );
  }
}

export default OnAMission;
