import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import FAQTabs from "../../../components/Help/FAQTabs";

import FixedNavDark from "../../../components/Shared/FixedNavDark";
import $ from "jquery";

class NIFAQsAdditionalHelp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: ""
    };
  }

  componentDidMount() {
    var pathArray = window.location.pathname.split("/");
    var juris = pathArray[1];
    this.setState({
      jurisdiction: juris,
    });

    this.help();
    window.addEventListener('scroll', this.help);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.help);
  }

  help = () => {
    if (window.pageYOffset < 50) {
      $("#custom-nav-expand").addClass("dark-header");
    }
  }

  render() {
    return (
      <div className='default-page home-page'>
        {/* <FixedNavDark/> */}
        {/* Hero with background device */}
        <MDBRow className='alt-hero'>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm='12' md='8' lg='8'>
                <h1>FAQs</h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow className="pb-5">
          {/* Legal Tabs */}
          <MDBCol lg='3' md='5' sm='12' className='pt-5 pl-5 pb-5 legals br-1'>
            <FAQTabs />
          </MDBCol>

          <MDBCol lg='8' md='7' sm='12' className='pt-5 pl-5'>
            <h3>Additional Help</h3>

            <div className='mt-5 mb-5'>
              <h5 id='powercut'>What should I do if there is a power cut? </h5>
              <p>
                If you experience a power cut, you should contact NIE Networks.
                If you've lost your electricity supply, first check if it's just
                your home that's without power or if there is a fault in the general
                area. You can check NIE Networks PowerCheck to get realtime information
                on faults and planned outages. If your fault is not listed report it
                to NIE Networks online at <a href="https://www.nienetworks.co.uk/power-cuts/power-cut">https://www.nienetworks.co.uk/power-cuts/power-cut </a> 
                or call NIE Networks on <a href='tel:03457 643 643'>03457 643 643.</a> Safety first - if you see any damaged
                electricity poles or wires DO NOT TOUCH or DO NOT GO NEAR.
              </p>


              <br /><br />
              <h5 id='emergency'>Who do I contact in an emergency?</h5>
              <p>Call NIE Networks on <a href='tel:03457 643 643'>03457 643 643.</a></p>

              <br></br>
              <h5 id='services'>What services do you offer for people who need additional help?</h5>
              <p>Our code of practice on the provision of services for persons who are of pensionable age, disabled or chronically sick can be found
                <a href={`/${this.state.jurisdiction}/codesofpractice`}> here.</a> It sets out the services we offer to those who need additional help.
                If you are critically dependent on electrically powered medical equipment please let us know by informing us at sign up or contact our energy specialists at
                <a href='tel:028 9544 2290'> 028 9544 2290,</a> we will add your details to the Medical Services Register which is maintained by NIE Networks.

                <br /> NIE Networks will inform you in advance of any planned power cuts or prioritise your reconnect in the event of an unplanned power cut.
                We also offer a Customer Services Register for those people who are of pensionable age, disabled or chronically sick.  We offer services such as a password
                scheme so you always know it is us contacting you.  We can arrange a nominee service so that we can discuss your account with someone you
                choose, or provide enlarged bills or talking bills for those who are blind or partially sighted.</p>


              <br /><br />
              <h5 id='medicalcare'>What is the Medical Care Register?</h5>
              <p> If anyone in your home is critically dependent on electrical equipment such as an oxygen concentrator, suction pump, peg tube feeding pump, home dialysis, electric hoist, electric chair lift, total parental nutrition machine, vital medicine requiring refrigeration, electric pressure stair lift, electric mattress/bed, nebuliser, ventilator or other critical electrically powered medical equipment, we will ensure that your details are included on the Medical Care Register, which is owned and operated by NIE Networks. NIE Networks will then be able to identify customers who are particularly vulnerable to a power cut or planned interruption.
               </p>
              <br /><br />
              <h5 id='customercare'>What is the Customer Care Register?</h5>
              <p>Our Customer Care Register is for those domestic customers who are; 
              <ul>
                <li>Of pensionable age; or</li>
                <li> Disabled (including those who are deaf, hearing impaired, blind or partially sighted);</li>
                <li>Chronically sick. </li>
              </ul></p>  
              <p>
                View bright's <a href={`/${this.state.jurisdiction}/codesofpractice`}> code of practice</a> on provision of services for persons of pensionable age, disabled or chronically sick.
                We offer services such as a password scheme, so you always know it is us contacting you.  We can arrange a nominee service so that we can discuss your account with someone you choose,
                or provide enlarged bills or talking bills for those who are blind or partially sighted.
                </p>

              <br /><br />
              <h5 id='visuallyimpaired'>I am blind or visually impaired. How can I receive my bill?</h5>
              <p>If you are blind or visually impaired, we are happy to provide: 
              <ul>
                <li>Braille Bills. </li>
                <li>Talking Bills Service – one of our energy specialists can give you a call to chat through your bill with you; </li>
                <li>Large print – we can provide our communications to you in large print format.</li>

              </ul></p>
              <br></br>
              <h5 id='hearingimpaired'>I am deaf or hearing impaired. How can I contact you?</h5>
              <p>If you are deaf or are hearing impaired, you can contact our Energy Specialists via email on <a href='mailto:hello@brightenergy.com'>hello@brightenergy.com</a> or online via web chat at <a href={`/${this.state.jurisdiction}/about/contactus`}>www.brightenergy.com</a>
              </p>

              <br></br>
              <h5 id='passwordscheme'>Do you provide a Password Scheme? </h5>
              <p>You can set up a password which our representatives will use when contacting you so you can always be sure it's a call from bright.</p>

              <br></br>
              <h5 id='medicalequipment'>I am critically dependent on medical equipment. How can I ensure my supply is not switched off?</h5>
              <p>You should inform us if anyone in your home is critically dependent on electrical equipment such as an oxygen concentrator,
              suction pump, peg tube feeding pump, home dialysis, electric hoist, electric chair lift, total parental nutrition machine,
              vital medicine requiring refrigeration, electric pressure stair lift, electric mattress/bed, nebuliser, ventilator or other
              critical electrically powered medical equipment. We will ensure that your details are included on the Medical Care Register,
              which is owned and operated by NIE Networks. NIE Networks will then be able to identify customers who are particularly vulnerable
                to a power cut or planned interruption.  We will not disconnect customers on the medical care register for reasons of non payment.</p>

              <br></br>
              <h5 id='elderlysupply'>I am elderly and worried about my power supply being disconnected in winter. What can I do?</h5>
              <p>When you sign up with us we'll let you know about our Customer Care Register. The register is for those household customers who are;
              <ul>
                <li>Of pensionable age; </li>
                <li>Disabled;</li>
                <li>Chronically sick.</li>
              </ul></p>
              <p>View bright's<a href={`/${this.state.jurisdiction}/codesofpractice`}> code of practice</a> on provision of services for persons who are of pensionable age,
                disabled or chronically sick. We won't disconnect customers for non payment during the winter months who are on the Customer Care Register.</p>

              <br></br>
              <h5 id='vulnerablebills'>I have a carer/relative/friend who normally looks after my bills. Can you send my bill to them?</h5>
              <p>You can provide us with the name and number of a relative, carer or friend who we can contact about your account on your behalf.
              We can send the nominated person copies of any correspondence that is sent to you, including bills. However, we will need your
                  permission and the permission of the nominated person to do this.</p>

            </div>



            <div id='greenelectricity' className='mb-5'>
              <h5 className='policy-title'>What does 100% green electricity<a href='#cru'></a> mean? </h5>
              <p className='policy-content'>
                Our electricity is 100% green<a href='#cru'></a> and powered only by the good stuff - wind, hydro, bio and solar. To make this possible we ensure that every unit of electricity supplied to bright customers is replaced on the grid by a unit from a renewable source.  This is something we’re incredibly proud of and it’s not only making a difference now but it will benefit the children of today who are the future.
              </p>
            </div>



          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default NIFAQsAdditionalHelp;
